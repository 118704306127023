import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorIcon from '@mui/icons-material/Error'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import WarningIcon from '@mui/icons-material/Warning'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	FormControlLabel,
	MenuItem,
	Switch,
	TextField,
	Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { connectorDataTypes } from 'data/lookup-data-sources'
import { ISession } from 'models'
import { useConnectorService } from 'services'
import { getDateInPast } from 'utils'
import { formatDateTime } from 'utils/formatDateTime'

import SessionDetails from './SessionDetails'

const startingDate = getDateInPast(3)

const SessionsTab = () => {
	const [sessions, setSessions] = useState<ISession[]>([])
	const [includeEmptySessions, setIncludeEmptySessions] = useState(false)
	const [dataLoading, setDataLoading] = useState(true)
	const [dataType, setDataType] = useState('All')
	const [dateFilter, setDateFilter] = useState(startingDate)
	const [expanded, setExpanded] = useState<string | false>(false)

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false)
	}

	const handleShowEmptySessions = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIncludeEmptySessions(event.target.checked)
	}

	const { t } = useTranslation(['synchronization', 'translation'])

	const { getSessions } = useConnectorService()

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const handleChangType = (e: any) => {
		setDataType(e.target.value)
	}

	const handleDateFilterChange = (e: any) => {
		setDateFilter(e.target.value)
	}

	const getStatusColor = (status: string) => {
		switch (status) {
			case 'Running':
				return 'warning.main'
			case 'Succes':
				return 'success.main'
			case 'Error':
				return 'error.main'
			case 'Canceled':
				return 'error.light'
			default:
				return 'text.secondary'
		}
	}

	const getStatusIcon = (status: string) => {
		switch (status) {
			case 'Running':
				return <HourglassTopIcon sx={sxStyles.statusIcon} color="warning" />
			case 'Succes':
				return <DoneAllIcon sx={sxStyles.statusIcon} color="success" />
			case 'Error':
				return <ErrorIcon sx={sxStyles.statusIcon} color="error" />
			case 'Canceled':
				return <WarningIcon sx={{ ...sxStyles.statusIcon, color: 'error.light' }} />
			default:
				return <FiberManualRecordIcon sx={{ ...sxStyles.statusIcon, color: 'text.secondary' }} />
		}
	}

	const getData = useCallback(async () => {
		const type = dataType === 'All' ? '' : dataType

		try {
			setDataLoading(true)
			const response = await getSessions(type, includeEmptySessions, dateFilter)

			setSessions(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getSessions, dataType, dateFilter, includeEmptySessions])

	useEffect(() => {
		getData()
	}, [dataType, dateFilter, includeEmptySessions])

	return (
		<Box>
			<Box sx={sxStyles.toolbar}>
				{!mobileView && (
					<Typography component={'span'} color={'primary'}>
						{t(`SessionsTabTitle`)}
					</Typography>
				)}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}>
					<FormControlLabel
						sx={{ mr: '10px' }}
						control={<Switch checked={includeEmptySessions} onChange={handleShowEmptySessions} size="small" />}
						label={<Typography fontSize={'14px'}>{t('Other.ShowEmptySessions')}</Typography>}
						labelPlacement="start"
					/>
					<TextField
						value={dateFilter}
						name="DateFilter"
						label={t('SessionLogFields.DateFilter')}
						InputLabelProps={{ shrink: true }}
						type="date"
						onChange={handleDateFilterChange}
						sx={{ width: 300, mr: '15px' }}
						size="small"
					/>
					<TextField
						select
						value={dataType}
						name="Type"
						label={t('Fields.Type')}
						onChange={handleChangType}
						sx={{ width: 300 }}
						size="small">
						{connectorDataTypes.map((el: any, index: number) => (
							<MenuItem key={index} value={el.value}>
								{t(`Fields.TypeEnums.${el.label}`, {
									defaultValue: `${el.label}`,
								})}
							</MenuItem>
						))}
					</TextField>
				</Box>
			</Box>
			{dataLoading ? (
				<Loading />
			) : sessions.length > 0 ? (
				<>
					<Box sx={sxStyles.headerContainer}>
						<Typography component={'span'} sx={sxStyles.cell}>
							{t('Fields.DateTimeStart')}{' '}
						</Typography>
						<Typography component={'span'} sx={sxStyles.cell}>
							{t('Fields.DateTimeEnd')}
						</Typography>
						<Typography component={'span'} sx={sxStyles.cell}>
							{t('Fields.Type')}
						</Typography>
						<Typography component={'span'} sx={sxStyles.cell}>
							{t('Fields.Status')}
						</Typography>
					</Box>
					{sessions.map((el, index) => {
						return (
							<Accordion
								key={index}
								sx={{ minWidth: '1000px' }}
								expanded={expanded === `group-${index}`}
								onChange={handleChange(`group-${index}`)}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />} id={`group-${index}-header`}>
									<Box sx={sxStyles.summaryContainer}>
										<Typography component={'span'} sx={sxStyles.cell} color="text.secondary">
											{formatDateTime(el.Start as string)}
										</Typography>
										<Typography component={'span'} sx={sxStyles.cell} color="text.secondary">
											{el.Status === 'Running' ? '' : formatDateTime(el.End as string)}
										</Typography>
										<Typography component={'span'} sx={sxStyles.cell} color={'info.main'}>
											{t(`Fields.TypeEnums.${el.Type}`, {
												defaultValue: `${el.Type}`,
											})}
										</Typography>
										<Typography component={'span'} sx={sxStyles.statusCell} color={() => getStatusColor(el.Status)}>
											{getStatusIcon(el.Status)}
											{t(`Fields.StatusEnums.${el.Status}`, {
												defaultValue: `${el.Status}`,
											})}
										</Typography>
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									{expanded === `group-${index}` && <SessionDetails sessionId={el.Id} />}
								</AccordionDetails>
							</Accordion>
						)
					})}
				</>
			) : (
				<NoData />
			)}
		</Box>
	)
}

export default SessionsTab

const sxStyles = {
	toolbar: {
		minWidth: '1000px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '70px',
		borderBottom: '1px solid',
		borderColor: 'border.primary',
		p: '0 15px',
	},
	headerContainer: {
		width: '100%',
		minHeight: '48px',
		minWidth: '1000px',
		p: '0 15px',
		pr: '40px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	summaryContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	cell: {
		width: '25%',
	},
	statusCell: {
		width: '25%',
		display: 'flex',
		alignItems: 'center',
	},
	statusIcon: {
		fontSize: '16px',
		mr: '5px',
	},
	summaryCell: {},
}
