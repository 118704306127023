import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { IAddress } from 'models'
import { useAddressService } from 'services'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	updateList: () => void
	projectId: string
}

const AddAddressDialog = ({ isOpen, setIsOpen, updateList, projectId }: Props) => {
	const [newAddressData, setNewAddressData] = useState<IAddress>()
	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { clearError, fieldsError, addAddress, showSuccessInfo } = useAddressService()

	const { t } = useTranslation(['dictionaries', 'translation'])

	const handleCloseDialog = () => {
		setIsOpen(false)
		setNewAddressData(undefined)
		setIsCreateButtonDisabled(true)
	}

	const handleInputChange = useCallback(
		(e: any) => {
			isCreateButtonDisabled && setIsCreateButtonDisabled(false)

			const value = e.target.value
			const fieldName = e.target.name

			if (fieldsError.includes(fieldName)) {
				clearError(fieldName)
			}

			setNewAddressData((prev: any) => {
				return { ...prev, [fieldName]: value }
			})
		},
		[isCreateButtonDisabled, setIsCreateButtonDisabled, setNewAddressData]
	)

	const handleDataSave = useCallback(async () => {
		if (newAddressData) {
			setButtonLoading(true)
			try {
				await addAddress({
					...newAddressData,
					ProjectId: projectId,
				})
				updateList()
				showSuccessInfo('saved')
				setIsCreateButtonDisabled(true)
				handleCloseDialog()
			} catch (err) {
				console.error(err)
			}
		}
		setButtonLoading(false)
	}, [projectId, newAddressData, addAddress])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Addresses.AddAddressDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '10px' }}>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							value={newAddressData?.City || ''}
							name="City"
							label={t('Addresses.City')}
							error={fieldsError.includes('City')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							value={newAddressData?.Street || ''}
							name="Street"
							label={t('Addresses.Street')}
							error={fieldsError.includes('Street')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							value={newAddressData?.StreetNumber || ''}
							name="StreetNumber"
							label={t('Addresses.StreetNumber')}
							error={fieldsError.includes('StreetNumber')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							value={newAddressData?.FlatNumber || ''}
							name="FlatNumber"
							label={t('Addresses.FlatNumber')}
							error={fieldsError.includes('FlatNumber')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							value={newAddressData?.PostalCode || ''}
							name="PostalCode"
							label={t('Addresses.PostalCode')}
							error={fieldsError.includes('PostalCode')}
							onChange={handleInputChange}
							fullWidth
							inputProps={{ maxLength: 6 }}
							placeholder="00-000"
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							value={newAddressData?.Post || ''}
							name="Post"
							label={t('Addresses.Post')}
							error={fieldsError.includes('Post')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={handleCloseDialog}>
					{t('general.Cancel', { ns: 'translation' })}
				</Button>
				<LoadingButton
					variant="contained"
					disabled={isCreateButtonDisabled}
					color="success"
					onClick={handleDataSave}
					loading={buttonLoading}>
					<span>{t('general.Add', { ns: 'translation' })}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddAddressDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 25px 20px 25px',
	},
}
