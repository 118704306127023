import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridRowModes, type GridRowModesModel, type GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, ExpandCollapseButtons, SwitchTreeData, TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'
import { v4 as uuidv4 } from 'uuid'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	setIsActionButtonDisabled: (value: boolean) => void
	isActionButtonDisabled: boolean
	setEntriesData: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
	contractId: string
	readOnly: boolean
	isTreeData: boolean
	setIsTreeData: (value: boolean) => void
}

const ContractEntriesToolbar = ({
	apiRef,
	isActionButtonDisabled,
	setIsActionButtonDisabled,
	setEntriesData,
	setRowModesModel,
	contractId,
	readOnly,
	isTreeData,
	setIsTreeData,
}: Props) => {
	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleAddEntry = () => {
		const Id = uuidv4()

		setIsActionButtonDisabled(true)

		setEntriesData((oldRows: any) => [
			...oldRows,
			{
				Id,
				isNew: true,
				ParentId: null,
				ContractId: contractId,
				TreePath: [''],
			},
		])

		setRowModesModel(oldModel => ({
			...oldModel,
			[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Name' },
		}))
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer className="flex justify-between m-1">
			<div>
				<ColumnsButton />
				<ExpandCollapseButtons apiRef={apiRef} isActionButtonDisabled={isActionButtonDisabled} />
			</div>
			<div>
				<SwitchTreeData
					isActionButtonDisabled={isActionButtonDisabled}
					isTreeData={isTreeData}
					setIsTreeData={setIsTreeData}
				/>
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.Filter"
					onClick={handleFilter}
					IconComponent={FilterListIcon}
					disabled={isActionButtonDisabled}
					fontSize="medium"
				/>
				{!readOnly && (
					<TooltipButton
						title="general.Add"
						onClick={handleAddEntry}
						IconComponent={AddIcon}
						disabled={isActionButtonDisabled}
						fontSize="medium"
					/>
				)}
			</div>
		</GridToolbarContainer>
	)
}

export default ContractEntriesToolbar
