import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { FilterPanel, SettingsMenu, TooltipButton } from 'components/shared'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	getFilteredData: (reset?: boolean) => void
	apiRef: { current: any }
	showCanceled: boolean
	setShowCanceled: (value: boolean) => void
	showCompleted: boolean
	setShowCompleted: (value: boolean) => void
	handleFilter: () => void
}

const ProcessingProtocolsToolbar = ({
	getFilteredData,
	apiRef,
	showCanceled,
	setShowCanceled,
	showCompleted,
	setShowCompleted,
	handleFilter,
}: Props) => {
	const handleUpdateList = () => {
		getFilteredData()
	}

	return (
		<GridToolbarContainer className="items-center justify-between m-1.5">
			<FilterPanel
				handleFilter={handleFilter}
				apiRef={apiRef}
				getFilteredData={getFilteredData}
				contextName={CONTEXT_STATE_NAME}
				showCanceled={showCanceled}
				setShowCanceled={setShowCanceled}
				showCompleted={showCompleted}
				setShowCompleted={setShowCompleted}
			/>
			<div>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
			</div>
		</GridToolbarContainer>
	)
}

export default ProcessingProtocolsToolbar
