export class Theme {
	static dark = 'dark'
	static light = 'light'
}

export function getCurrentTheme() {
	const theme = localStorage.getItem('theme')

	switch (theme) {
		default:
		case Theme.light:
			return Theme.light

		case Theme.dark:
			return Theme.dark
	}
}

export function switchToTheme(theme: string) {
	switch (theme) {
		default:
		case Theme.light:
			localStorage.setItem('theme', theme)
			break

		case Theme.dark:
			localStorage.setItem('theme', theme)
			break
	}
}
