import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, TooltipButton } from 'components/shared'
import { useAppSelector } from 'features'
import useDataGridState from 'hooks/UseDataGridState'
import { ICustomCostInvoice } from 'models'
import { v4 as uuidv4 } from 'uuid'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	invoiceId: string
	readOnly: boolean
	apiRef: { current: any }
	isActionButtonDisabled: boolean
	setIsActionButtonDisabled: (value: boolean) => void
	setEntriesData: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

const EntriesToolbar = ({
	invoiceId,
	readOnly,
	apiRef,
	isActionButtonDisabled,
	setIsActionButtonDisabled,
	setEntriesData,
	setRowModesModel,
}: Props) => {
	const { documentData } = useAppSelector(state => state.documentData)

	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleAddEntry = () => {
		const Id = uuidv4()

		const data = documentData as ICustomCostInvoice

		setIsActionButtonDisabled(true)

		setEntriesData((oldRows: any) => [
			...oldRows,
			{
				Id,
				CustomCostInvoiceId: invoiceId,
				ArticleType: 'Services',
				Deductions: 'Yes',
				MasterCategoryId: data?.MasterEntryDefaultCategory || null,
				MasterCategory2Id: data?.MasterEntryDefaultCategory2 || null,
				isNew: true,
			},
		])

		setRowModesModel(oldModel => ({
			...oldModel,
			[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Name' },
		}))
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer className="flex justify-between m-1">
			<div>
				<ColumnsButton />
			</div>
			<div className="mr-1">
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.Filter"
					onClick={handleFilter}
					IconComponent={FilterListIcon}
					disabled={isActionButtonDisabled}
					fontSize="medium"
				/>
				{!readOnly && (
					<TooltipButton
						title="general.Add"
						onClick={handleAddEntry}
						IconComponent={AddIcon}
						disabled={isActionButtonDisabled}
						fontSize="medium"
					/>
				)}
			</div>
		</GridToolbarContainer>
	)
}

export default EntriesToolbar
