import { useNavigate } from 'react-router-dom'

import prepareTitleString from 'utils/prepareTitleString'

const useHandleNavigate = () => {
	const navigate = useNavigate()

	const handleNavigate = (
		data: any,
		path: string,
		postfixField: string = 'Number',
		charCount: number = 20,
		idField: string = 'Id'
	) => {
		navigate(`${path}/${data[idField]}`, {
			state: { headerPostFix: ' ' + prepareTitleString(`${data[postfixField]}`, charCount) + ' ' },
		})
	}

	const handleDocumentLinkNavigate = (path: string, id: string, label: string, charCount: number = 20) => {
		navigate(`${path}/${id}`, {
			state: { headerPostFix: ' ' + prepareTitleString(label, charCount) + ' ' },
		})
	}

	const handleNavigateWithState = (path: string, id: string, label: string, state: any, charCount: number = 20) => {
		navigate(`${path}/${id}`, {
			state: { headerPostFix: ' ' + prepareTitleString(label, charCount) + ' ', ...state },
		})
	}

	return { handleNavigate, handleDocumentLinkNavigate, handleNavigateWithState }
}

export default useHandleNavigate
