import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'

import { useTabs } from 'hooks'
import { useProjectsBasicInfoService, useWorkflowService } from 'services'

interface Props {
	projectId: string
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
}

const CreateInvestorProtocolDialog = ({ projectId, isOpen, setIsOpen }: Props) => {
	const [workflowId, setWorkflowId] = useState('')
	const [workflow, setWorkflow] = useState([])

	const [buttonLoading, setButtonLoading] = useState(false)

	const navigate = useNavigate()

	const tabsHook = useTabs()

	const { t } = useTranslation('project')

	const { getWorkflowList } = useWorkflowService()
	const { createInvestorProcessingProtocol, showSuccessInfo } = useProjectsBasicInfoService()

	const onWorkflowChange = async (value: string) => {
		setWorkflowId(value)
	}

	const closeDialog = () => {
		setIsOpen(false)
	}

	const getWorkflowData = useCallback(async () => {
		try {
			const response = await getWorkflowList('InvestorProcessingProtocol')

			const prepareWorkflowData = response.data.map((el: any) => {
				return { value: el.Id, label: el.Name }
			})

			setWorkflow(prepareWorkflowData)
		} catch (err) {
			console.error(err)
		}
	}, [getWorkflowList])

	const handleCreateInvoice = async () => {
		try {
			setButtonLoading(true)
			const response = await createInvestorProcessingProtocol(projectId, workflowId)
			if (response) {
				tabsHook.closeTab(tabsHook.selectedTab?.key as string)
				navigate(`/investorProcessingProtocol/${response.documentId}`, {
					state: { headerPostFix: ` ${response.documentNumber}` },
				})
			}
			showSuccessInfo('action')
			closeDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	useEffect(() => {
		getWorkflowData()
	}, [])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Actions.CreateInvestorProcessingProtocol')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={workflowId}
					select
					name="Workflow"
					label={t('Actions.CreateInvestorProtocolDialog.WorkflowSelectLabel')}
					onChange={e => onWorkflowChange(e.target.value)}
					fullWidth
					sx={{ mt: '10px' }}>
					{workflow.map((el: any, index: number) => (
						<MenuItem key={el.label} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={!workflowId}
					loading={buttonLoading}
					onClick={handleCreateInvoice}>
					{t('general.Create', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default CreateInvestorProtocolDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
