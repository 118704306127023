import { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { CssBaseline, IconButton, ThemeProvider } from '@mui/material'

import { AttachmentsPreviewProvider } from 'Contexts/AttachmentsPreviewContext'
import { GlobalNotification } from 'components/shared'
import { AuthProvider } from 'hooks'
import { SnackbarProvider, closeSnackbar } from 'notistack'

import App from './App'
import { createMuiTheme } from './theme'

const Providers = () => {
	const [muiTheme, setTheme] = useState<string | null>('light')

	const themeMode = createMuiTheme(muiTheme)

	const setMuiTheme = (theme: string | null) => {
		setTheme(theme)
		localStorage.setItem('theme', theme || '')
		if (theme === 'dark') {
			document.documentElement.classList.add('dark')
		} else {
			document.documentElement.classList.remove('dark')
		}
	}

	useEffect(() => {
		setMuiTheme(localStorage.getItem('theme'))
	}, [])

	return (
		<Router>
			<AuthProvider>
				<ThemeProvider theme={themeMode}>
					<CssBaseline enableColorScheme />
					<AttachmentsPreviewProvider>
						<SnackbarProvider
							maxSnack={5}
							action={snackbarId => (
								<IconButton edge="start" color="inherit" onClick={() => closeSnackbar(snackbarId)}>
									<CloseIcon />
								</IconButton>
							)}>
							<GlobalNotification />
							<App setMuiTheme={setMuiTheme} />
						</SnackbarProvider>
					</AttachmentsPreviewProvider>
				</ThemeProvider>
			</AuthProvider>
		</Router>
	)
}

export default Providers
