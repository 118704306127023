import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IInvokeAction, IProcessingProtocol } from 'models'
import { PROCESSING_PROTOCOL_ENDPOINT } from 'services/data'

export interface getProcessingProtocolsProps {
	isCanceled: boolean
	isCompleted: boolean
	contractId?: string
	projectId?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useProcessingProtocolService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = PROCESSING_PROTOCOL_ENDPOINT

	const getFilteredProcessingProtocols = useCallback(
		async ({ isCanceled, isCompleted, contractId, projectId, budgetEntryId, data }: getProcessingProtocolsProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getProcessingProtocols = useCallback(
		async ({ isCanceled, isCompleted, contractId, projectId }: getProcessingProtocolsProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getProcessingProtocol = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}/${protocolId}`)
			return response
		},
		[request]
	)

	const updateProcessingProtocol = useCallback(
		async (protocolId: string, data: IProcessingProtocol) => {
			await request(`${path}/${protocolId}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}/actions/${protocolId}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}/allowedProperties/read/${protocolId}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}/allowedProperties/write/${protocolId}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getProcessingProtocols,
		getProcessingProtocol,
		updateProcessingProtocol,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredProcessingProtocols,
	}
}

export default useProcessingProtocolService
