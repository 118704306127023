import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'

import { useAttachmentsService } from 'services'

import styles from '../Styles.module.css'

interface Props {
	contextId: string
	directoryId?: string
	handleCloseDialog: () => void
	updateAttachments: React.DispatchWithoutAction
}

const AddFolder = ({ contextId, directoryId, handleCloseDialog, updateAttachments }: Props) => {
	const [folderName, setFolderName] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['attachments', 'translation'])

	const { showSuccessInfo, addFolder } = useAttachmentsService()

	const handleSetFolderName = (e: any) => {
		setFolderName(e.target.value)
	}

	const handleFolderSubmit = useCallback(async () => {
		const folder = {
			directoryId: directoryId,
			directoryName: folderName,
		}

		try {
			setButtonLoading(true)
			const response = await addFolder(contextId, folder)
			if (response) {
				updateAttachments()
				handleCloseDialog()
				setFolderName('')
			}
			showSuccessInfo('folderAdded')
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}, [contextId, folderName, directoryId])

	return (
		<div className={styles.uploadContainer}>
			<TextField
				value={folderName}
				id="folderName"
				name="folderName"
				label={t('AddAttachmentDialog.FolderInputLabel')}
				onChange={handleSetFolderName}
				fullWidth
			/>
			<div className={styles.addButtonContainer}>
				<LoadingButton
					variant="contained"
					onClick={handleFolderSubmit}
					color="success"
					disabled={!folderName}
					loading={buttonLoading}>
					{t('general.Add', { ns: 'translation' })}
				</LoadingButton>
			</div>
		</div>
	)
}

export default AddFolder
