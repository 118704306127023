import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, MenuItem, TableContainer, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { connectorDataTypes } from 'data/lookup-data-sources'
import { ITask } from 'models'
import { useConnectorService } from 'services'

import SynchronizationTasks from '../SynchronizationTasks'

const DataWaiting = () => {
	const [data, setData] = useState<ITask[]>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [dataType, setDataType] = useState('All')

	const { t } = useTranslation(['synchronization', 'translation'])

	const { getWaitingTasks } = useConnectorService()

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const handleChangType = (e: any) => {
		setDataType(e.target.value)
	}

	const getData = useCallback(async () => {
		const type = dataType === 'All' ? '' : dataType

		try {
			setDataLoading(true)
			const response = await getWaitingTasks(type)

			setData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getWaitingTasks, dataType])

	useEffect(() => {
		getData()
	}, [dataType])

	return (
		<TableContainer>
			<Box sx={sxStyles.toolbar}>
				{!mobileView && (
					<Typography component={'span'} color={'primary'}>
						{t(`DataWaitingTabTitle`)}
					</Typography>
				)}
				<TextField
					select
					value={dataType}
					name="Type"
					label={t('Fields.Type')}
					onChange={handleChangType}
					sx={{ width: 300 }}
					size="small">
					{connectorDataTypes.map((el: any, index: number) => (
						<MenuItem key={index} value={el.value}>
							{t(`Fields.TypeEnums.${el.label}`, {
								defaultValue: `${el.label}`,
							})}
						</MenuItem>
					))}
				</TextField>
			</Box>
			{dataLoading ? <Loading /> : data.length > 0 ? <SynchronizationTasks tasks={data} /> : <NoData />}
		</TableContainer>
	)
}

export default DataWaiting

const sxStyles = {
	toolbar: {
		minWidth: '650px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '70px',
		borderBottom: '1px solid',
		borderColor: 'border.primary',
		p: '0 15px',
	},
}
