import Skeleton from '@mui/material/Skeleton'

import clsx from 'clsx'

type Props = {
	className?: string
}

const InputSkeleton = ({ className }: Props) => {
	return (
		<Skeleton
			className={clsx('w-full h-14', className)}
			sx={{ bgcolor: 'background.secondary' }}
			variant="rounded"
			animation="wave"
		/>
	)
}

export default InputSkeleton
