import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IMasterRegistry } from 'models'

const useMasterRegistryService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/masterRegistry'

	const getMasterRegistryList = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const addMasterRegistry = useCallback(
		async (data: IMasterRegistry) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const getSingleMasterRegistry = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updateMasterRegistry = useCallback(
		async (id: string, body: IMasterRegistry) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deleteMasterRegistry = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getMasterRegistryList,
		addMasterRegistry,
		getSingleMasterRegistry,
		updateMasterRegistry,
		deleteMasterRegistry,
	}
}

export default useMasterRegistryService
