import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IBusinessData } from 'models'

interface BusinessDataState {
	businessData: IBusinessData | null
}

const initialState: BusinessDataState = {
	businessData: null,
}

export const businessDataSlice = createSlice({
	name: 'businessData',
	initialState,
	reducers: {
		setBusinessData: (state, action: PayloadAction<IBusinessData>) => {
			state.businessData = action.payload
		},
	},
})

export const businessDataActions = businessDataSlice.actions

export default businessDataSlice.reducer
