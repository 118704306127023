import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import Attachments from 'components/shared/attachments/Attachments'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	contextId: string
	updateAttachments: () => void
	workflowId: string
}

const AddAttachmentDialog = ({ isOpen, setIsOpen, contextId, updateAttachments, workflowId }: Props) => {
	const { t } = useTranslation()

	const handleClose = () => {
		updateAttachments()
		setIsOpen(false)
	}

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						{t('EmailParametersTab.AttachFiles')}
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '80px',
				}}>
				<Attachments contextId={contextId} isForInquiries={true} workflowId={workflowId} />
			</DialogContent>
		</Dialog>
	)
}

export default AddAttachmentDialog
