import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const BalancingDocumentIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M24,14.807c-0.003-0.117-0.04-0.227-0.102-0.317L20.514,8.01c0.25-0.03,0.465-0.218,0.514-0.48
	c0.063-0.323-0.149-0.635-0.473-0.697L12.533,5.3V2.271c0-0.33-0.268-0.596-0.597-0.596S11.34,1.941,11.34,2.271v2.8L4.752,3.813
	C4.705,3.797,4.657,3.789,4.608,3.785L3.543,3.582C3.224,3.516,2.908,3.731,2.846,4.055C2.784,4.378,2.997,4.69,3.32,4.752
	l0.347,0.066l-3.599,6.873c-0.064,0.123-0.082,0.258-0.059,0.385C0.067,14.393,1.926,16,4.569,16c2.677,0,4.549-1.646,4.562-4.011
	c0-0.014,0-0.027,0-0.041c-0.003-0.115-0.039-0.222-0.1-0.313L5.67,5.201l5.671,1.084v14.848H7.122
	c-0.329,0-0.596,0.266-0.596,0.596c0,0.328,0.267,0.597,0.596,0.597h9.631c0.329,0,0.597-0.269,0.597-0.597
	c0-0.33-0.268-0.596-0.597-0.596h-4.22V6.512l6.011,1.149l-3.606,6.888c-0.063,0.123-0.082,0.258-0.059,0.385
	c0.059,2.316,1.916,3.924,4.561,3.924c2.676,0,4.547-1.647,4.562-4.012C24,14.834,24,14.82,24,14.807z M4.569,5.666l2.979,5.704
	H1.582L4.569,5.666z M4.569,14.809c-1.497,0-3.006-0.699-3.313-2.248h6.626C7.576,14.107,6.066,14.809,4.569,14.809z M22.418,14.227
	h-5.966l2.985-5.704L22.418,14.227z M19.438,17.666c-1.496,0-3.006-0.701-3.313-2.248h6.626
	C22.445,16.965,20.936,17.666,19.438,17.666z"
			/>
		</SvgIcon>
	)
}

export default BalancingDocumentIcon
