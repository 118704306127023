import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'ProjectNumber',
			'InvestmentName',
			'ContractorId',
			'RealizationDateStart',
			'RealizationDateEnd',
			'Status',
			'ProjectValueWithAnnexes',
			'actions',
		],
		dimensions: {
			ProjectNumber: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
				flex: 0,
			},
			InvestmentName: {
				maxWidth: -1,
				minWidth: 50,
				width: 230,
				flex: 0,
			},
			ContractorId: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			RealizationDateStart: {
				maxWidth: -1,
				minWidth: 50,
				width: 160,
			},
			RealizationDateEnd: {
				maxWidth: -1,
				minWidth: 50,
				width: 160,
			},
			Status: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			ProjectValueWithAnnexes: {
				maxWidth: -1,
				minWidth: 50,
				width: 230,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
	aggregation: {
		model: { ProjectValueWithAnnexes: 'sum' },
	},
}

export const CONTEXT_STATE_NAME = 'projectsDataGridState'
