import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const ProcessingProtocolIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M17.018,7.817c-1.312,0-2.379-1.067-2.379-2.38s1.067-2.38,2.379-2.38c1.313,0,2.381,1.068,2.381,2.38
		S18.33,7.817,17.018,7.817z M17.018,4.303c-0.625,0-1.135,0.509-1.135,1.135s0.51,1.135,1.135,1.135
		c0.627,0,1.137-0.509,1.137-1.135S17.645,4.303,17.018,4.303z"
			/>
			<path
				fill="currentColor"
				d="M17.018,14.382c-1.312,0-2.379-1.067-2.379-2.382c0-1.313,1.067-2.38,2.379-2.38
		c1.313,0,2.381,1.067,2.381,2.38C19.396,13.313,18.33,14.382,17.018,14.382z M17.018,10.865c-0.625,0-1.135,0.509-1.135,1.135
		c0,0.627,0.51,1.137,1.135,1.137c0.627,0,1.137-0.51,1.137-1.137C18.152,11.374,17.645,10.865,17.018,10.865z"
			/>
			<path
				fill="currentColor"
				d="M17.018,20.736c-1.312,0-2.379-1.065-2.379-2.377c0-1.313,1.067-2.381,2.379-2.381
		c1.313,0,2.381,1.067,2.381,2.381C19.396,19.671,18.33,20.736,17.018,20.736z M17.018,17.225c-0.625,0-1.135,0.51-1.135,1.135
		s0.51,1.135,1.135,1.135c0.627,0,1.137-0.51,1.137-1.135C18.152,17.732,17.645,17.225,17.018,17.225z"
			/>
			<path
				fill="currentColor"
				d="M19.59,24H5.937c-1.061,0-1.923-0.861-1.923-1.923V1.923C4.014,0.863,4.876,0,5.937,0H19.59
		c1.061,0,1.924,0.863,1.924,1.923v20.154C21.514,23.139,20.65,24,19.59,24z M5.937,1.246c-0.374,0-0.679,0.304-0.679,0.677v20.154
		c0,0.374,0.305,0.679,0.679,0.679H19.59c0.374,0,0.678-0.305,0.678-0.679V1.923c0-0.374-0.304-0.677-0.678-0.677H5.937z"
			/>
			<path
				fill="currentColor"
				d="M13.419,4.999H6.9c-0.344,0-0.623-0.278-0.623-0.623c0-0.345,0.28-0.623,0.623-0.623h6.519
		c0.345,0,0.623,0.278,0.623,0.623C14.042,4.721,13.764,4.999,13.419,4.999z"
			/>
			<path
				fill="currentColor"
				d="M13.419,7.279H6.9C6.557,7.279,6.277,7,6.277,6.656c0-0.344,0.28-0.623,0.623-0.623h6.519
		c0.345,0,0.623,0.279,0.623,0.623C14.042,7,13.764,7.279,13.419,7.279z"
			/>
			<path
				fill="currentColor"
				d="M13.419,11.453H6.9c-0.344,0-0.623-0.279-0.623-0.623c0-0.344,0.279-0.623,0.623-0.623h6.519
		c0.345,0,0.623,0.279,0.623,0.623C14.042,11.174,13.764,11.453,13.419,11.453z"
			/>
			<path
				fill="currentColor"
				d="M13.419,13.732H6.9c-0.344,0-0.623-0.278-0.623-0.623c0-0.344,0.279-0.623,0.623-0.623h6.519
		c0.345,0,0.623,0.279,0.623,0.623C14.042,13.454,13.764,13.732,13.419,13.732z"
			/>
			<path
				fill="currentColor"
				d="M13.419,17.906H6.9c-0.344,0-0.623-0.278-0.623-0.622c0-0.345,0.279-0.622,0.623-0.622h6.519
		c0.345,0,0.623,0.277,0.623,0.622C14.042,17.628,13.764,17.906,13.419,17.906z"
			/>
			<path
				fill="currentColor"
				d="M13.419,20.188H6.9c-0.344,0-0.623-0.278-0.623-0.623c0-0.344,0.279-0.621,0.623-0.621h6.519
		c0.345,0,0.623,0.277,0.623,0.621C14.042,19.909,13.764,20.188,13.419,20.188z"
			/>
		</SvgIcon>
	)
}

export default ProcessingProtocolIcon
