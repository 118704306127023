import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { INotification } from 'models'

const useNotificationService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/notification'

	const getNotifications = useCallback(
		async (
			from: string | Date,
			to: string | Date,
			includeReaded: boolean = true,
			includeCanceled: boolean = false,
			includeEnded: boolean = false
		) => {
			const response = await request(
				`${path}?from=${from}&to=${to}&includeReaded=${includeReaded}&includeCanceled=${includeCanceled}&includeEnded=${includeEnded}`
			)
			return response
		},
		[request]
	)

	const getNotificationCount = useCallback(
		async (
			from: string | Date,
			to: string | Date,
			includeReaded: boolean = true,
			includeCanceled: boolean = false,
			includeEnded: boolean = false
		) => {
			const response = await request(
				`${path}/count?from=${from}&to=${to}&includeReaded=${includeReaded}&includeCanceled=${includeCanceled}&includeEnded=${includeEnded}`
			)
			return response
		},
		[request]
	)

	const getNewNotifications = useCallback(
		async (date: string | Date) => {
			const response = await request(
				`${path}/getNew?marker=${date}`,
				'GET',
				null,
				{ 'Content-Type': 'application/json' },
				true
			)
			return response
		},
		[request]
	)

	const updateNotification = useCallback(
		async (id: string, data: INotification) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	return {
		updateNotification,
		getNotifications,
		getNewNotifications,
		getNotificationCount,
		showSuccessInfo,
		error,
		fieldsError,
		clearError,
	}
}

export default useNotificationService
