import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { InputHint } from 'components/shared'
import { useFieldsPermissions } from 'hooks'
import { IProcessingProtocol } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	formData: IProcessingProtocol
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const FinalSettlements = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isVisible } = useFieldsPermissions()

	const { t } = useTranslation('processing-protocol')

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
	const tabletView = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<div className="w-full pb-4">
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* FinalContractValue  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
					{isVisible('FinalContractValue') && (
						<TextField
							disabled={true}
							value={formData.FinalContractValue || ''}
							name="FinalContractValue"
							label={t('Fields.FinalContractValue')}
							error={fieldErrorsList.includes('FinalContractValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.FinalContractValue" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalContractAnnexesValue  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
					{isVisible('FinalContractAnnexesValue') && (
						<TextField
							disabled={true}
							value={formData.FinalContractAnnexesValue || ''}
							name="FinalContractAnnexesValue"
							label={t('Fields.FinalContractAnnexesValue')}
							error={fieldErrorsList.includes('FinalContractAnnexesValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.FinalContractAnnexesValue" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalContractSecurityDepositAmountNWK  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 4, sm: 5, md: 3, lg: 3 }}>
					{isVisible('FinalContractSecurityDepositAmountNWK') && (
						<TextField
							disabled={true}
							value={formData.FinalContractSecurityDepositAmountNWK || ''}
							name="FinalContractSecurityDepositAmountNWK"
							label={t('Fields.FinalContractSecurityDepositAmountNWK')}
							error={fieldErrorsList.includes('FinalContractSecurityDepositAmountNWK')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.FinalContractSecurityDepositAmountNWK" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalContractSecurityDepositAmountUWIU  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 5, sm: 6, md: 4, lg: 4 }}>
					{isVisible('FinalContractSecurityDepositAmountUWIU') && (
						<TextField
							disabled={true}
							value={formData.FinalContractSecurityDepositAmountUWIU || ''}
							name="FinalContractSecurityDepositAmountUWIU"
							label={t('Fields.FinalContractSecurityDepositAmountUWIU')}
							error={fieldErrorsList.includes('FinalContractSecurityDepositAmountUWIU')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.FinalContractSecurityDepositAmountUWIU" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* FinalContractValueWithAnnexes  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 3, sm: 3, md: 5, lg: 5 }}>
					{isVisible('FinalContractValueWithAnnexes') && (
						<TextField
							disabled={true}
							value={formData.FinalContractValueWithAnnexes || ''}
							name="FinalContractValueWithAnnexes"
							label={t('Fields.FinalContractValueWithAnnexes')}
							error={fieldErrorsList.includes('FinalContractValueWithAnnexes')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.FinalContractValueWithAnnexes" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{!mobileView && (
					<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 6, sm: 4, md: 6, lg: 6 }}>
						{/* Empty Item */}
					</Grid>
				)}
				{/* FinalContractContractualDepositNWKPaymentDate */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 7, sm: 7, md: 7, lg: 7 }}>
					{isVisible('FinalContractContractualDepositNWKPaymentDate') && (
						<TextField
							disabled={true}
							value={formatDateTimeMUI(formData.FinalContractContractualDepositNWKPaymentDate) || ''}
							name="FinalContractContractualDepositNWKPaymentDate"
							label={t('Fields.FinalContractContractualDepositNWKPaymentDate')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('FinalContractContractualDepositNWKPaymentDate')}
							onChange={onInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.FinalContractContractualDepositNWKPaymentDate" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
				{/* FinalContractContractualDepositUWiUPaymentDate */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
					{isVisible('FinalContractContractualDepositUWiUPaymentDate') && (
						<TextField
							disabled={true}
							value={formatDateTimeMUI(formData.FinalContractContractualDepositUWiUPaymentDate) || ''}
							name="FinalContractContractualDepositUWiUPaymentDate"
							label={t('Fields.FinalContractContractualDepositUWiUPaymentDate')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('FinalContractContractualDepositUWiUPaymentDate')}
							onChange={onInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.FinalContractContractualDepositUWiUPaymentDate" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
				{!tabletView && (
					<Grid xs={12} sm={12} md={9} lg={9} order={{ xs: 9, sm: 9, md: 9, lg: 9 }}>
						{/* Empty Item */}
					</Grid>
				)}
				{/* FinalContractSecurityDepositAmountToSave  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
					{isVisible('FinalContractSecurityDepositAmountToSave') && (
						<TextField
							disabled={true}
							value={formData.FinalContractSecurityDepositAmountToSave || ''}
							name="FinalContractSecurityDepositAmountToSave"
							label={t('Fields.FinalContractSecurityDepositAmountToSave')}
							error={fieldErrorsList.includes('FinalContractSecurityDepositAmountToSave')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.FinalContractSecurityDepositAmountToSave" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default FinalSettlements
