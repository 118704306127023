import { DragDropContext, Draggable, type DropResult, Droppable } from 'react-beautiful-dnd'

import { Box, Tabs } from '@mui/material'

import { useTabs } from 'hooks'
import { v4 as uuidv4 } from 'uuid'

import TabHeader from './TabHeader'
import { sxStylesTabHeaderPanel } from './styles'

const TabHeadersPanel = () => {
	const { tabs, selectedTab, reorderTab, closeTab } = useTabs()

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) return
		reorderTab(result.source.index, result.destination.index)
	}

	const handleMiddleClick = (event: React.MouseEvent, tabKey: string) => {
		if (event.button === 1) {
			closeTab(tabKey)
		}
	}

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Box sx={sxStylesTabHeaderPanel.container}>
				<Tabs
					value={selectedTab?.index}
					variant="scrollable"
					scrollButtons="auto"
					sx={{
						width: '100%',
						'& .MuiTabs-indicator': {
							backgroundColor: 'transparent',
						},
					}}>
					<Droppable droppableId={uuidv4()} direction="horizontal">
						{provided => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								style={{ display: 'flex' }}
								aria-hidden={'true'}>
								{tabs.map((tab, index) => (
									<Draggable key={tab.key} draggableId={tab.key} index={index}>
										{provided => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												aria-hidden={'true'}
												tabIndex={0}
												onAuxClick={event => handleMiddleClick(event, tab.key)}>
												<TabHeader tab={tab} isActive={selectedTab?.key === tab.key} index={index} />
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</Tabs>
			</Box>
		</DragDropContext>
	)
}

export default TabHeadersPanel
