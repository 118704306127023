import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Slider, Typography } from '@mui/material'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'

const minWidth = 25 // Minimum width value in the range
const maxWidth = 65 // Maximum width value in the range

const WindowWidth = () => {
	const [width, setWidth] = useState<number>(30)

	const { updateFullWidth } = useContext(AttachmentsPreviewContext)

	const handleChangeWidth = (event: Event, newWidth: number | number[]) => {
		setWidth(newWidth as number)

		// Calculate the value based on the percentage
		const calculatePercentWidth = Math.round(minWidth + ((newWidth as number) / 100) * (maxWidth - minWidth))

		updateFullWidth(calculatePercentWidth)
	}

	const { t } = useTranslation('navigation')

	useEffect(() => {
		const savedWidth = localStorage.getItem('attachmentsPreview_config_full_width')

		if (savedWidth) {
			const res = JSON.parse(savedWidth)
			const percentage = ((res - minWidth) / (maxWidth - minWidth)) * 100
			setWidth(Math.round(percentage))
		}
	}, [])

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'space-around',
				justifyContent: 'center',
				flexDirection: 'column',
			}}>
			<Typography
				component="span"
				fontSize={'10px'}
				fontWeight={'500'}
				sx={{ pb: '5px', textTransform: 'uppercase', textAlign: 'center' }}>
				{t('user-menu.AttachmentsPreviewWidth')}
			</Typography>
			<Slider value={width} onChange={handleChangeWidth} />
		</Box>
	)
}

export default WindowWidth
