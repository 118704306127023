import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import EngineeringIcon from '@mui/icons-material/Engineering'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, MenuItem, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ContactPersons, ContractorForm, InputHint, InputLinkButton, TooltipButton } from 'components/shared'
import { PATHS } from 'data'
import { OfferStatus } from 'data/lookup-data-sources/OfferStatus'
import { useFieldsPermissions } from 'hooks'
import { IInquiriesContractor, IOffer } from 'models'
import { OFFER_ENDPOINT, useOfferService } from 'services'

import OfferParameters from './form-tabs/OfferParameters'

interface Props {
	offerId: string
	formData: IOffer
	onInputChange: (e: any) => void
	reloadFormData: () => void
	fieldErrorsList: string[]
}

const OfferForm = ({ offerId, formData, onInputChange, reloadFormData, fieldErrorsList }: Props) => {
	const [quotationContractor, setQuotationContractor] = useState<IInquiriesContractor | null>(null)
	const [isContractorInTheDictionary, setIsContractorInTheDictionary] = useState(true)
	const [selectedTab, setSelectedTab] = useState('0')

	const navigate = useNavigate()

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('offer')

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const { getQuotationContractor } = useOfferService()

	const handleAddContractor = () => {
		navigate('/dictionaries/contractors', {
			state: { params: quotationContractor, openAddDialog: true },
		})
	}

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('offerFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.OfferParameters'),
				renderValue: (
					<OfferParameters formData={formData} onInputChange={onInputChange} fieldErrorsList={fieldErrorsList} />
				),
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons documentId={offerId} contractorId={formData.Contractor || ''} endpoint={OFFER_ENDPOINT} />
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	const getQuotationRequestContractor = async () => {
		if (formData.Contractor) {
			setIsContractorInTheDictionary(true)
		} else if (formData.QuotationRequestContractor) {
			setIsContractorInTheDictionary(false)
			try {
				const response = await getQuotationContractor(formData.QuotationRequestContractor)
				setQuotationContractor(response)
			} catch (err) {
				console.error(err)
			}
		}
	}

	useEffect(() => {
		const savedTab = localStorage.getItem('offerFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	useEffect(() => {
		getQuotationRequestContractor()
	}, [formData.Contractor, formData.QuotationRequestContractor])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								id="offer-field-Number"
								name="Number"
								label={t('Fields.Number')}
								error={fieldErrorsList.includes('Number')}
								onChange={onInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="Offer.Number" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={true}
								value={formData.DocumentDate || ''}
								id="offer-field-DocumentDate"
								name="DocumentDate"
								label={t('Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldErrorsList.includes('DocumentDate')}
								onChange={onInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="Offer.DocumentDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Offer.StageId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
										<InputHint translationPath="Offer.WorkflowId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
											<InputHint translationPath="Offer.Project" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Demand */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Demand') && (
							<TextField
								disabled={true}
								value={formData.DemandLabel || ''}
								name="Demand"
								label={t('Fields.Demand')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.demand}
												documentId={formData.Demand}
												documentLabel={formData.DemandLabel}
											/>
											<InputHint translationPath="Offer.Demand" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{!mobileView && (
						<Grid xs={12} sm={6} md={3} lg={3}>
							{/* Empty Item */}
						</Grid>
					)}
					{/* OfferStatus */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('OfferStatus') && (
							<TextField
								select
								disabled={!isEditable('OfferStatus')}
								value={formData.OfferStatus || ''}
								id="offer-field-OfferStatus"
								name="OfferStatus"
								label={t('Fields.OfferStatus')}
								error={fieldErrorsList.includes('OfferStatus')}
								onChange={onInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="Offer.OfferStatus" select />
										</InputAdornment>
									),
								}}>
								{OfferStatus.map((el: any, index: number) => (
									<MenuItem key={index} value={el.value}>
										{t(`Fields.OfferStatusEnums.${el.label}`)}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
				</Grid>
			</div>
			<div className="w-full pb-4">
				{isContractorInTheDictionary ? (
					<ContractorForm
						documentId={offerId}
						contractorId={formData.Contractor}
						contractorLabel={formData.ContractorLabel}
						readOnly={!isEditable('Contractor')}
					/>
				) : (
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						{/* ContractorName */}
						<Grid xs={9} sm={10.5} md={5} lg={5}>
							{isVisible('Contractor') && (
								<TextField
									disabled={true}
									value={quotationContractor?.Name || ''}
									name="Contractor"
									label={t('Fields.Contractor')}
									fullWidth
								/>
							)}
						</Grid>
						{/* Add Contractor to the dictionary */}
						{!isContractorInTheDictionary && (
							<Grid className="flex justify-start items-center" xs={3} sm={1.5} md={1} lg={1}>
								<TooltipButton
									className="p-2"
									title="Other.AddToContractors"
									translationFile="offer"
									onClick={handleAddContractor}
									IconComponent={PersonAddAlt1Icon}
								/>
								<TooltipButton
									className="p-2"
									title="Other.ChangeToContractorFromDictionary"
									translationFile="offer"
									onClick={() => setIsContractorInTheDictionary(true)}
									IconComponent={EngineeringIcon}
								/>
							</Grid>
						)}
						{/* ContractorNIP */}
						<Grid xs={12} sm={6} md={3} lg={3}>
							{isVisible('Contractor') && (
								<TextField
									disabled={true}
									value={quotationContractor?.NIP || ''}
									name="ContractorNIP"
									label={t('Fields.ContractorFields.NIP')}
									fullWidth
								/>
							)}
						</Grid>
						{/* PhoneNumber */}
						<Grid xs={12} sm={6} md={3} lg={3}>
							{isVisible('Contractor') && (
								<TextField
									disabled={true}
									value={quotationContractor?.PhoneNumber || ''}
									name="ContractorPhoneNumber"
									label={t('Fields.ContractorFields.PhoneNumber')}
									fullWidth
								/>
							)}
						</Grid>
						{/* Email */}
						<Grid xs={12} sm={6} md={3} lg={3}>
							{isVisible('Contractor') && (
								<TextField
									disabled={true}
									value={quotationContractor?.Email || ''}
									name="ContractorEMail"
									label={t('Fields.ContractorFields.EMail')}
									fullWidth
								/>
							)}
						</Grid>
					</Grid>
				)}
			</div>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={el.label} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel className="py-2.5 px-0" key={index} value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default OfferForm
