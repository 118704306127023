import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { ICreateDemandFromProjectRequest } from 'models'

const useCreateDemandFromBudgetService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const createDemand = useCallback(
		async (body: ICreateDemandFromProjectRequest) => {
			const response = await request(`/api/Demand/fromProject`, 'POST', body)
			return response.ResultData
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		createDemand,
	}
}

export default useCreateDemandFromBudgetService
