import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AccountCircle from '@mui/icons-material/AccountCircle'
import ErrorIcon from '@mui/icons-material/Error'
import KeyIcon from '@mui/icons-material/Key'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
	Box,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Paper,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import LanguageSwitcher from 'components/layouts/main/language-switcher/LanguageSwitcher'
import { useAuth } from 'hooks'
import { useBusinessDataService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

const LoginForm = () => {
	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')
	const [rememberMe, setRememberMe] = useState(false)
	const [popoverVisible, setPopoverVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [logoUrl, setLogoUrl] = useState('')

	const { getLogoURL } = useBusinessDataService()

	const { authenticate, error, clearError } = useAuth()
	const theme = useTheme()

	const desktop = useMediaQuery(theme.breakpoints.up('md'))

	const { t } = useTranslation()

	const handleLoginChange = (e: any) => {
		error && clearError()
		setLogin(e.target.value)
	}

	const handlePasswordChange = (e: any) => {
		error && clearError()
		setPassword(e.target.value)
	}

	const handleShowPassword = () => {
		setShowPassword(prev => !prev)
	}

	const handleRememberMeValueChange = () => {
		setRememberMe(prev => !prev)
	}

	const handleOnLoginClick = useCallback(async () => {
		setLoading(true)
		setPassword('')
		try {
			await authenticate(login, password, rememberMe)
		} catch (error) {
			console.error(error)
		}
		setLoading(false)
	}, [authenticate, password, rememberMe, login])

	const getLogo = async () => {
		try {
			const logoData = await getLogoURL()
			setLogoUrl(logoData?.url || '')
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getLogo()
	}, [])

	return (
		<Box sx={{ ...sxStyles.container, ...sxStyles.flex }}>
			<Box sx={sxStyles.lang}>
				<LanguageSwitcher />
			</Box>
			<div className="w-full flex flex-col items-center ">
				<div className="mb-10">
					{logoUrl && <img src={logoUrl} alt="Company logo" className="h-20 md:h-26 lg:h-32 w-auto" />}
				</div>
				<Paper sx={{ ...sxStyles.formContainer, ...sxStyles.flex }} elevation={5}>
					<Typography component={'div'} sx={{ ...sxStyles.title, ...sxStyles.flex }}>
						<LockPersonIcon fontSize="large" />
						{t('login-page.login-title')}
					</Typography>
					{error && (
						<Box sx={sxStyles.formFieldContainer}>
							<Typography component={'div'} sx={{ ...sxStyles.errorMessage, ...sxStyles.flex }}>
								<ErrorIcon fontSize="small" color="error" sx={{ mr: '5px' }} />
								{t('login-page.error-message')}
							</Typography>
						</Box>
					)}
					<Box sx={sxStyles.formFieldContainer}>
						<TextField
							value={login}
							id="login"
							name="login"
							placeholder={t('login-page.login') as string}
							onChange={handleLoginChange}
							error={error}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Box>
					<Box sx={sxStyles.formFieldContainer}>
						<TextField
							value={password}
							id="password"
							name="password"
							placeholder={t('login-page.password') as string}
							onChange={handlePasswordChange}
							error={error}
							type={showPassword ? 'text' : 'password'}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<KeyIcon />
									</InputAdornment>
								),
								endAdornment: (
									<IconButton onClick={handleShowPassword}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								),
							}}
							fullWidth
						/>
					</Box>
					<Box sx={desktop ? sxStyles.formActionsContainerDesktop : sxStyles.formActionsContainerMobile}>
						<FormControlLabel
							sx={{
								'.css-ahj2mt-MuiTypography-root': {
									fontSize: '14px',
								},
							}}
							control={
								<Checkbox
									size="small"
									checked={rememberMe}
									onChange={handleRememberMeValueChange}
									onKeyPress={e => {
										if (e.key === 'Enter') {
											handleRememberMeValueChange()
										}
									}}
								/>
							}
							label={t('login-page.remember-me') as string}
						/>
						<LoadingButton
							disabled={!login || !password || error}
							onClick={handleOnLoginClick}
							sx={{ margin: desktop ? '0' : '10px 0' }}
							loading={loading}
							variant="contained"
							fullWidth={!desktop}
							size="large">
							<span>{t('login-page.login-btn')}</span>
						</LoadingButton>
					</Box>
					<Tooltip
						open={popoverVisible}
						onClose={() => setPopoverVisible(false)}
						onOpen={() => setPopoverVisible(true)}
						onClick={() => setPopoverVisible(true)}
						title={<TooltipTextWrapper title={t('login-page.forgot-password-tooltip')} />}
						placement="bottom"
						arrow>
						<Stack component="div" sx={sxStyles.tooltip}>
							{t('login-page.forgot-password')}
						</Stack>
					</Tooltip>
				</Paper>
			</div>
		</Box>
	)
}

export { LoginForm }

const sxStyles = {
	flex: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	container: {
		height: '100vh',
		overflowX: 'auto',
	},
	lang: {
		position: 'absolute',
		top: '20px',
		right: '20px',
	},
	formContainer: {
		flexDirection: 'column',
		minHeight: '400px',
		maxHeight: '80%',
		width: '50%',
		minWidth: '300px',
		maxWidth: '550px',
		padding: '15px',
	},
	formFieldContainer: {
		width: '100%',
		padding: '15px',
	},
	formActionsContainerDesktop: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '15px',
	},
	formActionsContainerMobile: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		padding: '0 15px',
	},
	title: {
		flexDirection: 'column',
		fontSize: '26px',
		fontWeight: '500',
		padding: '10px 0',
	},
	tooltip: {
		mt: '25px',
		cursor: 'pointer',
		fontSize: '12px',
		fontStyle: 'italic',
	},
	errorMessage: {
		width: '100%',
		color: 'error.main',
		fontSize: '15px',
	},
}
