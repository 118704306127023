import clsx from 'clsx'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

type Props = {
	sums: number[]
	valuesSum: number
}

const AggregationFooter = ({ sums, valuesSum }: Props) => {
	return (
		<div className={clsx('inline-flex min-w-full', 'sticky bottom-0 left-0 right-0 z-10')}>
			<div
				className={clsx(
					'flex w-full h-10 py-2 text-primary-main',
					'bg-background-highlighted dark:bg-background-darkSecondary',
					'border-0 border-b border-dashed dark:border-b-border-darkDivider border-b-border-divider'
				)}>
				<div
					className={clsx(
						'bg-background-highlighted dark:bg-background-darkSecondary',
						'flex w-[766px] sticky bottom-0 left-0 z-10'
					)}>
					<div className={clsx('w-9 flex items-center justify-center')} />
					<div className={clsx('w-28 flex items-center justify-center')}></div>
					<div className={clsx('w-[470px] flex items-center justify-center')}></div>
					<div
						className={clsx(
							'w-36 flex items-center justify-end pr-2',
							'border-0 border-r border-dashed dark:border-r-border-darkDivider border-r-border-divider'
						)}>
						{formatNumberDataGrid(valuesSum || '')}
					</div>
				</div>
				<div className="flex-grow flex items-center justify-start px-2">
					{sums.map((sum: any, index: number) => (
						<div key={index} className={clsx('w-40 flex flex-grow items-center justify-center')}>
							{formatNumberDataGrid(sum || '')}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default AggregationFooter
