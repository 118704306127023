import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IInvokeAction, IOrder } from 'models'
import { ORDER_ENDPOINT } from 'services/data'

export interface getOrdersListProps {
	isCanceled: boolean
	isCompleted?: boolean
	offerId?: string
	demandId?: string
	projectId?: string
	materialInvoiceId?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useOrderService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = ORDER_ENDPOINT

	const getFilteredOrders = useCallback(
		async ({
			isCanceled,
			isCompleted,
			offerId,
			demandId,
			projectId,
			materialInvoiceId,
			budgetEntryId,
			data,
		}: getOrdersListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (offerId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&offerId=${offerId}`
			}

			if (demandId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&demandId=${demandId}`
			}

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (materialInvoiceId) {
				if (isCompleted) {
					getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&materialInvoiceId=${materialInvoiceId}`
				} else {
					getListPath = `${path}/filter?includeCanceled=${isCanceled}&materialInvoiceId=${materialInvoiceId}`
				}
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getOrders = useCallback(
		async ({ isCanceled, isCompleted, offerId, demandId, projectId, materialInvoiceId }: getOrdersListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (offerId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&offerId=${offerId}`
			}

			if (demandId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&demandId=${demandId}`
			}

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (materialInvoiceId) {
				if (isCompleted) {
					getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&materialInvoiceId=${materialInvoiceId}`
				} else {
					getListPath = `${path}?isCanceled=${isCanceled}&materialInvoiceId=${materialInvoiceId}`
				}
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getOrder = useCallback(
		async (orderId: string) => {
			const response = await request(`${path}/${orderId}`)
			return response
		},
		[request]
	)

	const updateOrder = useCallback(
		async (orderId: string, data: IOrder) => {
			await request(`${path}/${orderId}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (orderId: string) => {
			const response = await request(`${path}/actions/${orderId}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (orderId: string) => {
			const response = await request(`${path}/allowedProperties/read/${orderId}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (orderId: string) => {
			const response = await request(`${path}/allowedProperties/write/${orderId}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getOrders,
		getOrder,
		updateOrder,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredOrders,
	}
}

export default useOrderService
