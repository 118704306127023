import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'Role',
			'PersonId',
			'PhoneNumber',
			'EmailAdress',
			'RoleHeaderId',
			'ParticipationStartDate',
			'ParticipationEndDate',
			'ParticipationMonths',
			'PrintOnDocs',
			'actions',
		],
		dimensions: {
			Role: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			PersonId: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			PhoneNumber: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			EmailAdress: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			RoleHeaderId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ParticipationStartDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			ParticipationEndDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			ParticipationMonths: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			PrintOnDocs: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 130,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'projectContractorRepresentativesDataGridState'