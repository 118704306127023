import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'

import { useHandleNavigate } from 'hooks'
import { IDemand } from 'models'
import { useCreateDemandFromBudgetService, useWorkflowService } from 'services'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	projectId: string
	selectedRowsIds: string[]
}

const CreateDemandDialog = ({ isOpen, setIsOpen, projectId, selectedRowsIds }: Props) => {
	const [workflowId, setWorkflowId] = useState<string>('')
	const [workflowArray, setWorkflowArray] = useState<Array<{ value: string | undefined; label: string | undefined }>>(
		[]
	)
	const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { createDemand } = useCreateDemandFromBudgetService()
	const { getWorkflowLookupList } = useWorkflowService()

	const { t } = useTranslation(['project', 'translation'])

	const { handleNavigate } = useHandleNavigate()

	const handleCloseDialog = () => {
		setIsOpen(false)
		setWorkflowId('')
	}

	const createDemandFromEntriesCallback = async () => {
		try {
			setButtonLoading(true)
			const response = await createDemand({
				demand: {
					Project: projectId,
				} as IDemand,
				workflowId: workflowId,
				budgetEntries: selectedRowsIds,
			})

			if (response) {
				handleNavigate(response, `/demand`, 'documentNumber', 20, 'documentId')
			}

			handleCloseDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	const onWorkflowChange = async (value: string) => {
		setWorkflowId(value)
		setSaveButtonEnabled(true)
	}

	useEffect(() => {
		async function getData() {
			try {
				const response = await getWorkflowLookupList('Demand')

				setWorkflowArray(response)
			} catch (err) {
				console.error(err)
			}
		}
		getData()
	}, [])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '18px',
				}}
				component="div">
				{t('BudgetTabGeneral.CreateDemandDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={workflowId}
					select
					id="Workflow"
					name="Workflow"
					label={t('BudgetTabGeneral.CreateDemandDialog.WorkflowSelectLabel')}
					onChange={e => onWorkflowChange(e.target.value)}
					fullWidth
					sx={{ mt: '10px' }}>
					{workflowArray.map((el: any, index: number) => (
						<MenuItem key={index} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions sx={{ padding: '8px 26px 16px 8px' }}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={!isSaveButtonEnabled}
					onClick={createDemandFromEntriesCallback}
					loading={buttonLoading}>
					{t('general.Create', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default CreateDemandDialog
