import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IAddress, IProjectModel } from 'models'
import { useAddressService, useProjectsListService } from 'services'
import FormatAddress from 'utils/FormatAdress'

import AddressesToolbar from './AddressesToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

const Addresses = () => {
	const [data, setData] = useState<IAddress[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

	const [dataLoading, setDataLoading] = useState(false)

	const [projectsList, setProjectsList] = useState<any[]>([])
	const [selectedProject, setSelectedProject] = useState('')

	const selectedProjectSessionStorageData = sessionStorage.getItem('addresses_selected_project')
	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [selectedEntryId, setSelectedEntryId] = useState('')
	const [selectedEntryName, setSelectedEntryName] = useState('')

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation('dictionaries')

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { deleteAddress, updateAddress, getAddresses, showSuccessInfo } = useAddressService()

	const { getListOfProjects } = useProjectsListService()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		setIsActionButtonDisabled(true)
		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = data.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setData(data.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteAddress(id as string)
			setData(data.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setSelectedEntryName(FormatAddress(params.row))
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		const updatedRow = { ...newRow, isNew: false } as IAddress
		try {
			await updateAddress(newRow.Id, newRow as IAddress)
			setData(data.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
			showSuccessInfo('saved')
			getData()
		} catch (err) {
			console.error(err)
		}
		return updatedRow
	}

	const columns: any = [
		{
			field: 'Street',
			headerName: t('Addresses.Street'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'StreetNumber',
			headerName: t('Addresses.StreetNumber'),
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			editable: true,
		},
		{
			field: 'FlatNumber',
			headerName: t('Addresses.FlatNumber'),
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			editable: true,
		},
		{
			field: 'City',
			headerName: t('Addresses.City'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'PostalCode',
			headerName: t('Addresses.PostalCode'),
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			editable: true,
		},
		{
			field: 'Post',
			headerName: t('Addresses.Post'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				}

				return [
					<TooltipButton
						title="ChangeHistory.ChangeHistory"
						onClick={handleShowHistory(params)}
						IconComponent={HistoryIcon}
						disabled={isActionButtonDisabled}
					/>,
					<TooltipButton
						title="general.Remove"
						onClick={handleDeleteClick(params.id)}
						IconComponent={DeleteIcon}
						disabled={isActionButtonDisabled}
					/>,
				]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getAddresses(selectedProject)
			setData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getAddresses, selectedProject])

	const sortProjectsByInvestmentName = (projects: IProjectModel[]) => {
		projects.sort((a, b) => {
			const investmentNameA = a.InvestmentName ? a.InvestmentName.toUpperCase() : ''
			const investmentNameB = b.InvestmentName ? b.InvestmentName.toUpperCase() : ''

			if (investmentNameA < investmentNameB) {
				return -1
			}
			if (investmentNameA > investmentNameB) {
				return 1
			}
			return 0
		})

		return projects
	}

	const getProjectsData = useCallback(async () => {
		try {
			const response = await getListOfProjects()
			const sortedProjects = sortProjectsByInvestmentName(response)

			setProjectsList(
				sortedProjects.map((el: IProjectModel) => {
					return {
						id: el.Id,
						label: `${el.InvestmentName || ''} ${el.ProjectNumber || ''}`,
					}
				})
			)
		} catch (err) {
			console.error(err)
		}
	}, [getListOfProjects])

	useEffect(() => {
		if (selectedProject) getData()
	}, [selectedProject])

	useEffect(() => {
		getProjectsData()
		getDataGridState(CONTEXT_STATE_NAME)

		if (selectedProjectSessionStorageData) {
			setSelectedProject(JSON.parse(selectedProjectSessionStorageData))
		}
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={data}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				sx={{ height: 'calc(100vh - 150px)' }}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				onCellDoubleClick={handleCellDoubleClick}
				experimentalFeatures={{ newEditingApi: true }}
				components={{
					Toolbar: AddressesToolbar,
					NoRowsOverlay: () => (
						<NoData alternativeText={!selectedProject ? (t('Addresses.ChooseProject') as string) : undefined} />
					),
				}}
				componentsProps={{
					toolbar: {
						isActionButtonDisabled,
						apiRef,
						selectedProject,
						setSelectedProject,
						projectsList,
						getData,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={selectedEntryName}
					translationFile="dictionaries"
					translationPath="Addresses"
				/>
			)}
		</>
	)
}

export default Addresses
