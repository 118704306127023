import { MutableRefObject, useCallback, useRef } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Divider } from '@mui/material'
import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import clsx from 'clsx'
import { TooltipButton } from 'components/shared/buttons'
import { usePreviousFilterStateDataActions } from 'features'
import { debounce } from 'lodash'

import { ShowCanceledButton } from '../show-canceled-button'
import { ShowCompletedButton } from '../show-completed-button'
import FilterButton from './FilterButton'

type Props = {
	handleFilter: () => void
	apiRef: MutableRefObject<GridApiPremium>
	getFilteredData: (reset?: boolean) => void
	contextName: string
	disableToggleButtons?: boolean
	showCanceled?: boolean
	setShowCanceled?: (value: boolean) => void
	showCompleted?: boolean
	setShowCompleted?: (value: boolean) => void
}

const FilterPanel = ({
	handleFilter,
	apiRef,
	getFilteredData,
	contextName,
	showCanceled,
	setShowCanceled,
	showCompleted,
	setShowCompleted,
	disableToggleButtons = false,
}: Props) => {
	const inputRef = useRef<HTMLInputElement | null>(null)
	const { setPreviousFilterState } = usePreviousFilterStateDataActions()

	const debouncedHandleFilter = useCallback(debounce(handleFilter, 500), [handleFilter])

	const handleSavePreviousFilterState = () => {
		const { filter } = apiRef.current.exportState()
		setPreviousFilterState(filter)
	}

	const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			handleBlurInput()
		}
	}

	const handleBlurInput = () => {
		if (inputRef.current) {
			inputRef.current.blur()
		}
	}

	const handleOpenFilterMenu = () => {
		handleSavePreviousFilterState()
		apiRef.current.showFilterPanel()
	}

	return (
		<GridToolbarQuickFilter
			className="min-w-[230px]"
			onBlur={debouncedHandleFilter}
			onFocus={handleSavePreviousFilterState}
			onKeyDown={handleEnterPress}
			InputProps={{
				inputRef: (ref: HTMLInputElement) => {
					inputRef.current = ref
				},
				startAdornment: <SearchIcon fontSize="small" />,
				endAdornment: (
					<>
						<Divider orientation="vertical" variant="middle" flexItem />
						<FilterButton apiRef={apiRef} handleOpenFilterMenu={handleOpenFilterMenu} />
						<TooltipButton
							className={clsx({ 'mr-2': !disableToggleButtons })}
							title="general.ClearDataGridFilters"
							onClick={() => getFilteredData(true)}
							IconComponent={ClearIcon}
							fontSize="medium"
						/>
						{!disableToggleButtons && (
							<>
								<Divider orientation="vertical" variant="middle" flexItem />
								{showCanceled !== undefined && setShowCanceled !== undefined && (
									<ShowCanceledButton
										contextName={contextName}
										showCanceled={showCanceled}
										setShowCanceled={setShowCanceled}
									/>
								)}
								{showCompleted !== undefined && setShowCompleted !== undefined && (
									<ShowCompletedButton
										contextName={contextName}
										showCompleted={showCompleted}
										setShowCompleted={setShowCompleted}
									/>
								)}
							</>
						)}
					</>
				),
			}}
			variant="outlined"
			size="small"
		/>
	)
}

export default FilterPanel
