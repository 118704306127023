import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useContractorBankAccountService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/contractorBankAccount'

	const getContractorBankAccounts = useCallback(
		async (contractorId: string) => {
			const response = await request(`${path}/${contractorId}`)
			return response
		},
		[request]
	)

	const getContractorBankAccount = useCallback(
		async (bankAccountId: string) => {
			const response = await request(`${path}/one/${bankAccountId}`)
			return response
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getContractorBankAccounts,
		getContractorBankAccount,
	}
}

export default useContractorBankAccountService
