import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IRealizationBudgetEntry } from 'models'

type InitialState = {
	budgetEntryId: string
	budgetEntryData: IRealizationBudgetEntry | null
	loading: boolean
	formLoading: boolean
}

const initialState: InitialState = {
	budgetEntryId: '',
	budgetEntryData: null,
	loading: true,
	formLoading: true,
}

export const budgetEntry = createSlice({
	name: 'budgetEntry',
	initialState,
	reducers: {
		setBudgetEntryId: (state, action: PayloadAction<string>) => {
			state.budgetEntryId = action.payload
		},
		setBudgetEntryData: (state, action: PayloadAction<IRealizationBudgetEntry>) => {
			state.budgetEntryData = action.payload
		},
		updateBudgetEntryData: (state, action: PayloadAction<{ fieldName: string; value: any }[]>) => {
			action.payload.forEach(({ fieldName, value }) => {
				state.budgetEntryData = {
					...state.budgetEntryData,
					[fieldName]: value,
				} as IRealizationBudgetEntry
			})
		},
		updateLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		updateFormLoading: (state, action: PayloadAction<boolean>) => {
			state.formLoading = action.payload
		},
	},
})

export const budgetEntryActions = budgetEntry.actions

export default budgetEntry.reducer
