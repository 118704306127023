import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { InputHint } from 'components/shared'
import { useFieldsPermissions } from 'hooks'
import { IProcessingProtocol } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

interface Props {
	formData: IProcessingProtocol
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const Settlements = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('processing-protocol')

	return (
		<div className="w-full pb-4">
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* CurrentPeriodValueNetto  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('CurrentPeriodValueNetto') && (
						<TextField
							disabled={true}
							value={formData.CurrentPeriodValueNetto || ''}
							name="CurrentPeriodValueNetto"
							label={t('Fields.CurrentPeriodValueNetto')}
							error={fieldErrorsList.includes('CurrentPeriodValueNetto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.CurrentPeriodValueNetto" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ParticipationsAndReInvoiceValueBrutto  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ParticipationsAndReInvoiceValueBrutto') && (
						<TextField
							disabled={true}
							value={formData.ParticipationsAndReInvoiceValueBrutto || ''}
							name="ParticipationsAndReInvoiceValueBrutto"
							label={t('Fields.ParticipationsAndReInvoiceValueBrutto')}
							error={fieldErrorsList.includes('ParticipationsAndReInvoiceValueBrutto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.ParticipationsAndReInvoiceValueBrutto" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ConsumptionDateTimeStart */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ConsumptionDateTimeStart') && (
						<TextField
							disabled={!isEditable('ConsumptionDateTimeStart')}
							value={formatDateTimeMUI(formData.ConsumptionDateTimeStart) || ''}
							name="ConsumptionDateTimeStart"
							label={t('Fields.ConsumptionDateTimeStart')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('ConsumptionDateTimeStart')}
							onChange={onInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.ConsumptionDateTimeStart" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
				{/* ConsumptionDateTimeEnd */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ConsumptionDateTimeEnd') && (
						<TextField
							disabled={!isEditable('ConsumptionDateTimeEnd')}
							value={formatDateTimeMUI(formData.ConsumptionDateTimeEnd) || ''}
							name="ConsumptionDateTimeEnd"
							label={t('Fields.ConsumptionDateTimeEnd')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldErrorsList.includes('ConsumptionDateTimeEnd')}
							onChange={onInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.ConsumptionDateTimeEnd" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
				{/* SecurityDepositAmountToSave  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('SecurityDepositAmountToSave') && (
						<TextField
							disabled={true}
							value={formData.SecurityDepositAmountToSave || ''}
							name="SecurityDepositAmountToSave"
							label={t('Fields.SecurityDepositAmountToSave')}
							error={fieldErrorsList.includes('SecurityDepositAmountToSave')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.SecurityDepositAmountToSave" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* SecurityDepositAmountToSaveNWK  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('SecurityDepositAmountToSaveNWK') && (
						<TextField
							disabled={true}
							value={formData.SecurityDepositAmountToSaveNWK || ''}
							name="SecurityDepositAmountToSaveNWK"
							label={t('Fields.SecurityDepositAmountToSaveNWK')}
							error={fieldErrorsList.includes('SecurityDepositAmountToSaveNWK')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.SecurityDepositAmountToSaveNWK" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* SecurityDepositAmountToSaveUWiU  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('SecurityDepositAmountToSaveUWiU') && (
						<TextField
							disabled={true}
							value={formData.SecurityDepositAmountToSaveUWiU || ''}
							name="SecurityDepositAmountToSaveUWiU"
							label={t('Fields.SecurityDepositAmountToSaveUWiU')}
							error={fieldErrorsList.includes('SecurityDepositAmountToSaveUWiU')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.SecurityDepositAmountToSaveUWiU" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* DebitNoteValue  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('DebitNoteValue') && (
						<TextField
							disabled={true}
							value={formData.DebitNoteValue || ''}
							name="DebitNoteValue"
							label={t('Fields.DebitNoteValue')}
							error={fieldErrorsList.includes('DebitNoteValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.DebitNoteValue" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* Comments */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					{isVisible('Comments') && (
						<TextField
							disabled={!isEditable('Comments')}
							value={formData.Comments || ''}
							name="Comments"
							label={t('Fields.Comments')}
							error={fieldErrorsList.includes('Comments')}
							onChange={onInputChange}
							multiline
							minRows={4}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProcessingProtocol.Comments" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Settlements
