export const sxStylesTabHeader = {
	tab: {
		height: '37.5px',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		border: 1,
		borderColor: 'border.primary',
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		borderBottom: 'none',
		p: '8px 10px',
		m: '3px',
		mb: 0,
		cursor: 'default',
		'&:hover': {
			backgroundColor: 'background.highlighted',
		},
	},
	headerName: {
		width: '100%',
		m: 'auto',
		p: '0 5px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	closeButton: { fontSize: '15px', mt: '1px' },
	activeTab: {
		border: 3,
		borderBottom: 'none',
		borderColor: 'primary.main',
		bgcolor: 'background.default',
		'&:hover': {
			backgroundColor: 'background.default',
		},
		p: '6px',
		'&::before': {
			content: '""',
			width: '10px',
			height: '5px',
			border: 3,
			borderColor: 'primary.main',
			borderTop: 'none',
			borderRight: 'none',
			position: 'absolute',
			bottom: '-2.5px',
			zIndex: 2,
			borderRadius: '0 0 0 10px',
			right: '-10px',
		},
		'&::after': {
			content: '""',
			width: '10px',
			height: '5px',
			border: 3,
			borderColor: 'primary.main',
			borderTop: 'none',
			borderLeft: 'none',
			position: 'absolute',
			bottom: '-2.5px',
			zIndex: 2,
			borderRadius: '0 0 10px 0',
			left: '-10px',
		},
	},
	activeBottomElement: {
		'&::before': {
			content: '""',
			bgcolor: 'background.default',
			width: '108%',
			height: '3px',
			position: 'absolute',
			bottom: '-3px',
			left: '-4%',
			zIndex: 1,
		},
	},
}

export const sxStylesTabHeaderPanel = {
	container: {
		height: '48px',
		display: 'flex',
		p: '5px 2px 0 2px',
		borderBottom: 3,
		borderColor: 'primary.main',
		bgcolor: 'background.secondary',
		overflow: 'visible',
	},
}
