import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'

import { BudgetNumberRegex } from 'data/valdiation'

type Props = {
	rerender: Dispatch<SetStateAction<number>>
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	api: any
	rowIdToChangeOrder: string | undefined
}

const ReorderDialog = ({ rerender, isOpen, setIsOpen, api, rowIdToChangeOrder }: Props) => {
	const [value, setValue] = useState<string | undefined>('')
	const [validationError, setValidationError] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['project', 'translation'])

	const closeDialog = () => {
		setIsOpen(false)
		setValue('')
		setValidationError(false)
	}

	const handleValueChange = (e: any) => {
		setValue(e.target.value)
		if (e.target.value?.match(BudgetNumberRegex) !== null) {
			setValidationError(false)
		} else {
			setValidationError(true)
		}
	}

	const handleSubmit = useCallback(async () => {
		try {
			setButtonLoading(true)
			await api.reorder({
				entryId: rowIdToChangeOrder as string,
				newIndex: value as string,
			})
			api.showSuccessInfo('saved')
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
		rerender(prev => prev + 1)
		closeDialog()
	}, [value, rowIdToChangeOrder])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('BudgetTabGeneral.ReorderDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={value}
					fullWidth
					onChange={handleValueChange}
					label={t('BudgetTabGeneral.ReorderDialog.InputLabel')}
					error={validationError}
					helperText={validationError ? t('BudgetTabGeneral.ReorderDialog.InputErrorMessage') : null}
					sx={{ mt: '10px' }}
				/>
			</DialogContent>
			<DialogActions sx={{ padding: '8px 26px 16px 8px' }}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.Cancel', { ns: 'translation' })}
				</Button>
				<LoadingButton
					variant="contained"
					onClick={handleSubmit}
					color="success"
					disabled={!value || validationError}
					loading={buttonLoading}>
					{t('general.Confirm', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default ReorderDialog
