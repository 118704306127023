import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'

import { Theme, getCurrentTheme, switchToTheme } from 'utils/themes'

interface Props {
	setMuiTheme: (value: string) => void
}

const ThemeSwitcher = ({ setMuiTheme }: Props) => {
	const [mode, setMode] = useState<string | null>('light')

	const { t } = useTranslation('navigation')

	const handleModeChange = (event: React.MouseEvent<HTMLElement>, newMode: string | null) => {
		setMode(newMode)
		setMuiTheme(newMode as string)
		switchToTheme(newMode === 'light' ? Theme.light : Theme.dark)
	}

	useEffect(() => {
		if (getCurrentTheme() == Theme.dark) {
			setMode('dark')
		} else {
			setMode('light')
		}
	}, [])

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'space-around',
				justifyContent: 'center',
				flexDirection: 'column',
			}}>
			<Typography
				component="span"
				fontSize={'12px'}
				fontWeight={'500'}
				sx={{ pb: '5px', textTransform: 'uppercase', textAlign: 'center' }}>
				{t('user-menu.Mode')}
			</Typography>
			<ToggleButtonGroup value={mode} exclusive onChange={handleModeChange} color="primary" size="small" fullWidth>
				<ToggleButton value="light">
					<LightModeIcon fontSize="small" />
				</ToggleButton>
				<ToggleButton value="dark">
					<DarkModeIcon fontSize="small" />
				</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	)
}

export default ThemeSwitcher
