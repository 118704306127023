import { useTranslation } from 'react-i18next'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import ClearIcon from '@mui/icons-material/Clear'
import { Avatar, Box, List, ListItem, ListItemAvatar, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { useEmailParametersService } from 'services'
import prepareTitleString from 'utils/prepareTitleString'

interface Props {
	attachments: any[]
	contextId: string
	updateAttachments: () => void
	workflowId: string
	readonly?: boolean
}

const Attachments = ({ attachments, contextId, workflowId, updateAttachments, readonly }: Props) => {
	const { t } = useTranslation()

	const { deleteAttachment, showSuccessInfo } = useEmailParametersService()

	const handleDeleteAttachment = async (attachmentId: string) => {
		try {
			await deleteAttachment(contextId, workflowId, attachmentId)
			showSuccessInfo('deleted')
			updateAttachments()
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<Box sx={sxStyles.container}>
			<Typography component="div" sx={sxStyles.title}>
				{t('EmailParametersTab.Attachments')}
			</Typography>
			<List>
				{attachments.map((item, index) => {
					return (
						<ListItem key={index} sx={sxStyles.listItem}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<ListItemAvatar sx={{ minWidth: '40px' }}>
									<Avatar sx={sxStyles.avatar}>
										<AttachFileIcon sx={{ fontSize: '16px' }} />
									</Avatar>
								</ListItemAvatar>
								{prepareTitleString(item.Name)}
							</Box>
							{!readonly && (
								<IconButton
									sx={{ padding: '2px', ml: '6px' }}
									color="primary"
									onClick={() => handleDeleteAttachment(item.Id)}>
									<ClearIcon sx={{ fontSize: '20px' }} />
								</IconButton>
							)}
						</ListItem>
					)
				})}
			</List>
		</Box>
	)
}

export default Attachments

const sxStyles = {
	container: { width: '85%', bgcolor: 'background.paper' },
	title: { textAlign: 'center', fontSize: '16px' },
	listItem: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderRadius: '5px',
		m: '5px',
		bgcolor: 'background.secondary',
		bgOpacity: '0.8',
	},
	avatar: {
		width: '22px',
		height: '22px',
		bgcolor: 'primary.main',
	},
}
