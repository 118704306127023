import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CalculateIcon from '@mui/icons-material/Calculate'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import RecommendIcon from '@mui/icons-material/Recommend'
import ReportIcon from '@mui/icons-material/Report'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, CircularProgress, IconButton, Stack, Tooltip } from '@mui/material'

import { useConnectorService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

interface Props {
	IsCanceled: boolean | undefined
	ToCalculation: boolean | undefined
	IsCompleted: boolean | undefined
	documentId?: string
}

const DocumentStatus = ({ IsCompleted, ToCalculation, IsCanceled, documentId }: Props) => {
	const [synchronizationStatus, setSynchronizationStatus] = useState<string | boolean>(false)
	const [synchronizationMessage, setSynchronizationMessage] = useState('')
	const [dataLoading, setDataLoading] = useState(true)

	const { t } = useTranslation()

	const { getSynchronizationStatusForDoc } = useConnectorService()

	const getStatusIcon = (status: string) => {
		switch (status) {
			case 'Waiting':
				return <CloudSyncIcon fontSize="medium" sx={{ color: 'text.secondary' }} />
			case 'InProgress':
				return <HourglassBottomIcon fontSize="medium" sx={{ color: 'info.main' }} />
			case 'Success':
			case 'Succes':
				return <DoneAllIcon fontSize="medium" sx={{ color: 'success.main' }} />
			case 'Warning':
				return <WarningIcon fontSize="medium" sx={{ color: 'warning.main' }} />
			case 'Error':
				return <ReportIcon fontSize="medium" sx={{ color: 'error.main' }} />
			default:
				return <CloudSyncIcon fontSize="medium" sx={{ color: 'text.secondary' }} />
		}
	}

	const syncIconToShow = synchronizationStatus && getStatusIcon(synchronizationStatus as string)

	const getSynchronizationStatus = async () => {
		try {
			setDataLoading(true)
			const response = await getSynchronizationStatusForDoc(documentId as string)

			if (response.Status && response.Status !== 'Unknown') {
				setSynchronizationStatus(response.Status)
				setSynchronizationMessage(response.Info)
			}
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}

	useEffect(() => {
		if (documentId) getSynchronizationStatus()
	}, [documentId])

	return (
		<Box sx={sxStyles.container}>
			{synchronizationStatus &&
				(dataLoading ? (
					<CircularProgress size={20} sx={{ p: '2px', mx: '8px' }} />
				) : (
					<Tooltip
						title={
							<TooltipTextWrapper
								title={`${t('Other.Synchronization', {
									ns: 'synchronization',
								})}: ${synchronizationMessage || ''} (${t('general.ClickToRefresh')})`}
							/>
						}
						placement="top"
						arrow>
						<IconButton sx={{ p: '2px', mr: '8px' }} onClick={getSynchronizationStatus}>
							{syncIconToShow}
						</IconButton>
					</Tooltip>
				))}
			{IsCanceled && (
				<Tooltip title={<TooltipTextWrapper title={t('general.DocumentStatus.IsCanceled')} />} placement="top" arrow>
					<Stack sx={{ p: '2px', mr: '8px' }}>
						<DoNotDisturbIcon fontSize="medium" color="error" />
					</Stack>
				</Tooltip>
			)}
			{ToCalculation && (
				<Tooltip title={<TooltipTextWrapper title={t('general.DocumentStatus.ToCalculation')} />} placement="top" arrow>
					<Stack sx={{ p: '2px', mr: '8px' }}>
						<CalculateIcon fontSize="medium" color="info" />
					</Stack>
				</Tooltip>
			)}
			{IsCompleted && (
				<Tooltip title={<TooltipTextWrapper title={t('general.DocumentStatus.IsCompleted')} />} placement="top" arrow>
					<Stack sx={{ p: '2px', mr: '8px' }}>
						<RecommendIcon fontSize="medium" color="success" />
					</Stack>
				</Tooltip>
			)}
		</Box>
	)
}

export default DocumentStatus

const sxStyles = {
	container: {
		display: 'flex',
		justifyContent: 'flex',
		alignItems: 'center',
	},
}
