import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IRoleHeader } from 'models'

const useRolesService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getRoles = useCallback(async () => {
		const response = await request(`/api/RoleHeader`)
		return response
	}, [request])

	//Get RoleHeaders assignable globaly
	const getRolesCanBeGlobal = useCallback(async () => {
		const response = await request(`/api/roleHeader/GetCanBeGlobal`)
		return response
	}, [request])

	//Get global roles for user
	const getUserGlobalRoles = useCallback(
		async (userId: string) => {
			const response = await request(`/api/roleGlobal?$filter=UserId eq ${userId}`)
			return response
		},
		[request]
	)

	//Add global role for user
	const addUserGlobalRole = useCallback(
		async (data: any) => {
			await request(`/odata/RoleGlobal`, 'POST', data)
		},
		[request]
	)

	//Remove global role for user
	const deleteUserGlobalRole = useCallback(
		async (roleId: string) => {
			await request(`/api/RoleGlobal/${roleId}`, 'DELETE')
		},
		[request]
	)

	//Get RoleHeaders assignable per project
	const getRolesCanBePerProject = useCallback(async () => {
		const response = await request(`/api/roleHeader/GetCanBePerProject`)
		return response
	}, [request])

	//Get per project roles for user
	const getUserPerProjectRoles = useCallback(
		async (userId: string) => {
			const response = await request(`/api/RolePerProject?$filter=UserId eq ${userId}`)
			return response
		},
		[request]
	)

	const addRole = useCallback(
		async (data: IRoleHeader) => {
			const response = await request(`/odata/RoleHeader`, 'POST', data)
			return response
		},
		[request]
	)

	const updateRole = useCallback(
		async (roleId: string, data: IRoleHeader) => {
			await request(`/odata/RoleHeader/${roleId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteRole = useCallback(
		async (roleId: string) => {
			await request(`/api/RoleHeader/${roleId}`, 'DELETE')
		},
		[request]
	)

	const getRolePermissions = useCallback(
		async (roleId: string) => {
			const response = await request(`/api/roleHeader/permissions/${roleId}`)
			return response
		},
		[request]
	)

	const updateRolePermission = useCallback(
		async (roleId: string, data: any) => {
			await request(`/api/roleHeader/permission/${roleId}`, 'PATCH', data)
		},
		[request]
	)

	const getGlobalUsersForRole = useCallback(
		async (roleId: string) => {
			const response = await request(`/api/RoleGlobal?$filter=HeaderId eq ${roleId}`)
			return response
		},
		[request]
	)

	const getPerProjectUsersForRole = useCallback(
		async (roleId: string) => {
			const response = await request(`/api/RolePerProject?$filter=HeaderId eq ${roleId}`)
			return response
		},
		[request]
	)

	const deleteGlobalUsersForRole = useCallback(
		async (roleId: string) => {
			await request(`/api/RoleGlobal/${roleId}`, 'DELETE')
		},
		[request]
	)

	const addGlobalUsersForRole = useCallback(
		async (data: any) => {
			await request(`/odata/RoleGlobal/`, 'POST', data)
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getRoles,
		addRole,
		updateRole,
		deleteRole,
		getRolePermissions,
		updateRolePermission,
		getGlobalUsersForRole,
		getPerProjectUsersForRole,
		deleteGlobalUsersForRole,
		addGlobalUsersForRole,
		getRolesCanBeGlobal,
		getUserGlobalRoles,
		addUserGlobalRole,
		deleteUserGlobalRole,
		getRolesCanBePerProject,
		getUserPerProjectRoles,
	}
}

export default useRolesService
