import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Divider } from '@mui/material'

import { IComment } from 'models/IComment'
import { useCommentsService } from 'services'

import { Loading } from '../loading'
import { NoData } from '../no-data-message'
import CommentsForm from './CommentsForm'
import SingleComment from './SingleComment'

interface Props {
	discussionId: string | undefined
	readOnly?: boolean
}

const Discussion = ({ discussionId, readOnly }: Props) => {
	const [comments, setComments] = useState<IComment[] | undefined>()
	const [loading, setLoading] = useState(true)
	const [toggle, setToggle] = useState(false)

	const { t } = useTranslation()

	const { getComments } = useCommentsService()

	const handleClick = () => {
		!toggle ? setToggle(true) : setToggle(false)
	}

	const getCommentsList = useCallback(async () => {
		try {
			const response = await getComments(discussionId)
			setComments(response)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}, [discussionId, getComments])

	useEffect(() => {
		setLoading(true)
		getCommentsList()
	}, [discussionId])

	if (loading) {
		return <Loading />
	}
	return (
		<div className="p-4">
			<div className="flex items-center justify-between">
				<span className="text-xl p-1 pb-2">{t('CommentsPage.Title')}</span>
			</div>
			<Divider />
			{readOnly || <CommentsForm discussionId={discussionId} refreshCommentsList={getCommentsList} />}
			{comments && comments.length <= 0 ? (
				<NoData />
			) : (
				<ul className="w-full text-base list-none p-0 px-2">
					{!toggle
						? comments
								?.sort((a, b) => {
									return new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime()
								})
								.slice(0, 6)
								.map((comment, index) => {
									return (
										<SingleComment
											key={index}
											Id={comment.Id}
											UserId={comment.UserId}
											UserLogin={comment.UserLogin}
											UserFullName={comment.UserFullName}
											Text={comment.Text}
											DateTime={comment.DateTime}
										/>
									)
								})
						: comments
								?.sort((a, b) => {
									return new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime()
								})
								.map((comment, index) => {
									return (
										<SingleComment
											key={index}
											Id={comment.Id}
											UserId={comment.UserId}
											UserLogin={comment.UserLogin}
											UserFullName={comment.UserFullName}
											Text={comment.Text}
											DateTime={comment.DateTime}
										/>
									)
								})}

					{comments && comments.length > 6 ? (
						<div className="flex justify-center">
							{!toggle ? (
								<Button className="w-full" onClick={handleClick} variant="text">
									{t('general.ShowMore')}
								</Button>
							) : (
								<Button className="w-full" variant="text" onClick={handleClick}>
									{t('general.ShowLess')}
								</Button>
							)}
						</div>
					) : null}
				</ul>
			)}
		</div>
	)
}

export default Discussion
