import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import KeyIcon from '@mui/icons-material/Key'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { Box, Button, InputAdornment, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { useAuth } from 'hooks'
import useValidation from 'hooks/UseValidation'
import { IUser } from 'models'
import { useUsersService } from 'services'

interface Props {
	setIsOpen?: (value: boolean) => void
	user: IUser
}

const ChangePasswordForm = ({ user, setIsOpen }: Props) => {
	const [confirmPassword, setConfirmPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const [newPasswordError, setNewPasswordError] = useState(false)
	const [confirmPasswordError, setConfirmPasswordError] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { refreshUser, logout } = useAuth()

	const { changeUserPassword, showSuccessInfo } = useUsersService()

	const { confirmPasswordErrorMessage, passwordErrorMessage, checkRegexMatch, passwordRegex } = useValidation()

	const { t } = useTranslation()

	const handleShowPassword = () => {
		setShowPassword(prev => !prev)
	}

	const handleShowConfirmPassword = () => {
		setShowConfirmPassword(prev => !prev)
	}

	const handleNewPasswordChange = (e: any) => {
		const value = e.target.value

		const isValid = checkRegexMatch(value, passwordRegex)
		if (newPasswordError && isValid) {
			setNewPasswordError(false)
		}
		setNewPassword(value)
	}

	const handleConfirmPasswordChange = (e: any) => {
		const value = e.target.value

		if (value.length > 0 && value !== newPassword) {
			setConfirmPasswordError(true)
		} else {
			setConfirmPasswordError(false)
		}
		setConfirmPassword(value)
	}

	const handleSaveNewPassword = async () => {
		if (!user) return

		if (confirmPassword !== newPassword) {
			setConfirmPasswordError(true)
			return
		}

		try {
			setButtonLoading(true)
			await changeUserPassword(user?.Id, newPassword)
			showSuccessInfo('saved')
			refreshUser()
			if (setIsOpen) {
				setIsOpen(false)
			}
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	const handleCheckForError = (e: any) => {
		const value = e.target.value
		const fieldName = e.target.name

		if (fieldName === 'newPassword') {
			const isValid = checkRegexMatch(value, passwordRegex)
			setNewPasswordError(!isValid)
		}
	}

	return (
		<Box sx={sxStyles.formContainer}>
			<Box sx={sxStyles.formFieldContainer}>
				<TextField
					value={newPassword}
					id="newPassword"
					name="newPassword"
					placeholder={t('ChangePasswordPage.NewPassword') as string}
					onChange={handleNewPasswordChange}
					onBlur={handleCheckForError}
					error={newPasswordError}
					helperText={newPasswordError && passwordErrorMessage}
					type={showConfirmPassword ? 'text' : 'password'}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<KeyIcon />
							</InputAdornment>
						),
						endAdornment: (
							<IconButton onClick={handleShowConfirmPassword}>
								{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						),
					}}
					fullWidth
				/>
			</Box>
			<Box sx={sxStyles.formFieldContainer}>
				<TextField
					value={confirmPassword}
					id="confirmPassword"
					name="confirmPassword"
					placeholder={t('ChangePasswordPage.ConfirmPassword') as string}
					onChange={handleConfirmPasswordChange}
					error={confirmPasswordError}
					helperText={confirmPasswordError && confirmPasswordErrorMessage}
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<KeyIcon />
							</InputAdornment>
						),
						endAdornment: (
							<IconButton onClick={handleShowPassword}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
						),
					}}
					fullWidth
				/>
			</Box>
			<Box sx={sxStyles.actionsContainer}>
				<Button variant="outlined" onClick={setIsOpen ? () => setIsOpen(false) : logout}>
					{t('general.Cancel')}
				</Button>
				<LoadingButton
					disabled={!newPassword || !confirmPassword || newPasswordError || confirmPasswordError}
					onClick={handleSaveNewPassword}
					loading={buttonLoading}
					variant="contained">
					<span>{t('general.Save')}</span>
				</LoadingButton>
			</Box>
		</Box>
	)
}

export default ChangePasswordForm

const sxStyles = {
	formContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	formFieldContainer: {
		width: '100%',
		padding: '15px',
	},
	actionsContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '15px',
	},
}
