import { useCallback, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Switch, Tooltip, Typography } from '@mui/material'

import { IPermission } from 'models'
import { useRolesService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

interface Props {
	permission: IPermission
	roleId: string
}

const SinglePermission = ({ permission, roleId }: Props) => {
	const { Name, IsGranted, Permission, Description } = permission

	const [value, setValue] = useState(IsGranted as boolean)

	const { updateRolePermission, showSuccessInfo } = useRolesService()

	const handleValueChange = useCallback(
		async (e: any) => {
			setValue(e.target.checked)
			try {
				const body = {
					Permission: Permission,
					IsGranted: e.target.checked,
				}
				await updateRolePermission(roleId, body)
				showSuccessInfo('saved')
			} catch (err) {
				setValue(!e.target.checked)
				console.error(err)
			}
		},
		[setValue, Permission]
	)

	return (
		<Box sx={sxStyles.container}>
			<Box sx={sxStyles.container}>
				<Typography sx={sxStyles.name}>{Name}</Typography>
				<Tooltip title={<TooltipTextWrapper title={Description} />} placement="top" arrow sx={sxStyles.infoContainer}>
					<InfoIcon sx={sxStyles.infoIcon} />
				</Tooltip>
			</Box>
			<Switch size="small" color="primary" checked={value} onChange={handleValueChange} />
		</Box>
	)
}

export default SinglePermission

const sxStyles = {
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	name: {
		fontSize: '14px',
		pr: '10px',
	},
	infoContainer: { display: 'flex', alignItems: 'center' },
	infoIcon: {
		alignItems: 'center',
		fontSize: '17px',
	},
}
