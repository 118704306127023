import { useTranslation } from 'react-i18next'

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	handleDetachRole: () => void
	roleName: string
}

const DetachRoleDialog = ({ isOpen, setIsOpen, handleDetachRole, roleName }: Props) => {
	const { t } = useTranslation(['administration', 'translation'])

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleConfirm = () => {
		handleDetachRole()
		closeDialog()
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogContent sx={sxStyles.contentContainer}>
				<Typography component="div" fontSize={16} textAlign={'center'} sx={{ width: '95%' }}>
					{`${t('Workflow.Permissions.DetachRoleDialog.TitlePart1')} ${roleName} ${t(
						'Workflow.Permissions.DetachRoleDialog.TitlePart2'
					)}`}
				</Typography>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="contained" color="error" onClick={closeDialog}>
					{t('general.No', { ns: 'translation' })}
				</Button>
				<Button variant="contained" color="success" onClick={handleConfirm}>
					{t('general.Yes', { ns: 'translation' })}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DetachRoleDialog

const sxStyles = {
	contentContainer: { display: 'flex', justifyContent: 'center', mt: '20px' },
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 26px 16px 8px',
	},
}
