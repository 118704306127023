import { forwardRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AccountCircle from '@mui/icons-material/AccountCircle'
import CloseIcon from '@mui/icons-material/Close'
import EmailIcon from '@mui/icons-material/Email'
import KeyIcon from '@mui/icons-material/Key'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PersonIcon from '@mui/icons-material/Person'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {
	AppBar,
	Dialog,
	DialogContent,
	IconButton,
	InputAdornment,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Unstable_Grid2'
import { TransitionProps } from '@mui/material/transitions'

import useValidation from 'hooks/UseValidation'
import { IUser } from 'models'
import { enqueueSnackbar } from 'notistack'
import { useUsersService } from 'services'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	updateUsersList: () => void
}

const AddUser = ({ isOpen, setIsOpen, updateUsersList }: Props) => {
	const {
		checkRegexMatch,
		pureTextRegex,
		pureTextErrorMessage,
		simpleTextErrorMessage,
		simpleTextRegex,
		passwordRegex,
		passwordErrorMessage,
		confirmPasswordErrorMessage,
		phoneNumberRegex,
		phoneNumberErrorMessage,
		emailRegex,
		emailErrorMessage,
	} = useValidation()

	const initialUserState = {
		Login: {
			value: '',
			regex: pureTextRegex,
			errorMessage: pureTextErrorMessage,
			error: false,
			requiredError: false,
		},
		EMail: {
			value: '',
			regex: emailRegex,
			errorMessage: emailErrorMessage,
			error: false,
			requiredError: false,
		},
		Password: {
			value: '',
			regex: passwordRegex,
			errorMessage: passwordErrorMessage,
			error: false,
			requiredError: false,
		},
		ConfirmPassword: {
			value: '',
			regex: passwordRegex,
			errorMessage: confirmPasswordErrorMessage,
			error: false,
			requiredError: false,
		},
		FirstName: {
			value: '',
			regex: simpleTextRegex,
			errorMessage: simpleTextErrorMessage,
			error: false,
			requiredError: false,
		},
		LastName: {
			value: '',
			regex: simpleTextRegex,
			errorMessage: simpleTextErrorMessage,
			error: false,
			requiredError: false,
		},
		Telephone: {
			value: '',
			regex: phoneNumberRegex,
			errorMessage: phoneNumberErrorMessage,
			error: false,
			requiredError: false,
		},
	}

	const [newUserData, setNewUserData] = useState<IUser | any>(initialUserState)
	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)

	const { addUser, showSuccessInfo } = useUsersService()

	const { t } = useTranslation(['administration', 'translation'])

	const handleCloseDialog = () => {
		setIsOpen(false)
		setNewUserData(initialUserState)
		setIsCreateButtonDisabled(true)
	}

	const handleShowPassword = () => {
		setShowPassword(prev => !prev)
	}

	const handleInputChange = useCallback(
		(e: any) => {
			isCreateButtonDisabled && setIsCreateButtonDisabled(false)

			const value = e.target.value
			const fieldName = e.target.name

			setNewUserData((prev: any) => ({
				...prev,
				[fieldName]: {
					...prev[fieldName],
					value: value,
				},
			}))
		},
		[isCreateButtonDisabled, setIsCreateButtonDisabled, setNewUserData]
	)

	const updateValidationState = (fieldName: string, isValid: boolean) => {
		setNewUserData((prev: any) => ({
			...prev,
			[fieldName]: {
				...prev[fieldName],
				error: !isValid,
			},
		}))
	}

	const handleInputBlur = (e: any) => {
		const value = e.target.value
		const fieldName = e.target.name

		if (!value) {
			updateValidationState(fieldName, true)
			return
		}

		const regex = newUserData[fieldName].regex
		const isValid = checkRegexMatch(value, regex)
		updateValidationState(fieldName, isValid)
	}

	const validateConfirmPassword = (e: any) => {
		const value = e.target.value

		if (value !== newUserData.Password.value) {
			setNewUserData((prevState: any) => ({
				...prevState,

				ConfirmPassword: {
					...prevState.ConfirmPassword,
					error: true,
				},
			}))
		} else {
			setNewUserData((prevState: any) => ({
				...prevState,

				ConfirmPassword: {
					...prevState.ConfirmPassword,
					error: false,
				},
			}))
		}
	}

	const validateRequiredFields = (userState: any) => {
		const { Login, EMail, Password, ConfirmPassword, FirstName } = userState

		// Check if any of the fields is empty or has an error
		if (
			!Login.value ||
			!EMail.value ||
			!Password.value ||
			!ConfirmPassword.value ||
			!FirstName.value ||
			Login.error ||
			EMail.error ||
			Password.error ||
			ConfirmPassword.error ||
			FirstName.error
		) {
			setNewUserData((prevState: any) => ({
				...prevState,
				Login: {
					...prevState.Login,
					requiredError: !Login.value,
				},
				EMail: {
					...prevState.EMail,
					requiredError: !EMail.value,
				},
				Password: {
					...prevState.Password,
					requiredError: !Password.value,
				},
				ConfirmPassword: {
					...prevState.ConfirmPassword,
					requiredError: !ConfirmPassword.value,
				},
				FirstName: {
					...prevState.FirstName,
					requiredError: !FirstName.value,
				},
			}))
			return false
		}

		// Check if Password and ConfirmPassword match
		if (Password.value !== ConfirmPassword.value) {
			setNewUserData((prevState: any) => ({
				...prevState,

				ConfirmPassword: {
					...prevState.ConfirmPassword,
					error: true,
				},
			}))
			return false
		}

		return true
	}

	const handleAddUser = useCallback(async () => {
		setButtonLoading(true)
		if (validateRequiredFields(newUserData)) {
			const data: any = {
				Login: '',
				FirstName: '',
				LastName: '',
				EMail: '',
				Telephone: '',
				Password: '',
				ConfirmPassword: '',
			}

			Object.keys(newUserData).forEach((key: string) => {
				data[key] = newUserData[key].value
			})

			try {
				await addUser(data)
				showSuccessInfo('userAdded')
				updateUsersList()
				handleCloseDialog()
			} catch (err) {
				console.error(err)
			}
		} else {
			enqueueSnackbar(t('general.ErrorMessages.FormSubmittingError', { ns: 'translation' }), {
				variant: 'error',
				autoHideDuration: 8000,
			})
		}
		setButtonLoading(false)
	}, [newUserData])

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1, fontSize: '20px', fontWeight: '500' }} component="div">
						{t('Users.AddUserDialog.Title')}
					</Typography>
					<LoadingButton
						sx={{ mr: '25px' }}
						variant="contained"
						disabled={isCreateButtonDisabled}
						color="success"
						onClick={handleAddUser}
						loading={buttonLoading}>
						<span>{t('general.Add', { ns: 'translation' })}</span>
					</LoadingButton>
					<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '80px',
				}}>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '10px' }}>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							disabled={false}
							value={newUserData.Login.value || ''}
							id="new-user-field-Login"
							name="Login"
							placeholder={`${t('Users.User.Login')} *`}
							error={newUserData.Login.requiredError || newUserData.Login.error}
							helperText={newUserData.Login.error && newUserData.Login.errorMessage}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
							required
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							disabled={false}
							value={newUserData.EMail.value || ''}
							id="new-user-field-EMail"
							name="EMail"
							placeholder={`${t('Users.User.EMail')} *`}
							error={newUserData.EMail.requiredError || newUserData.EMail.error}
							helperText={newUserData.EMail.error && newUserData.EMail.errorMessage}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EmailIcon />
									</InputAdornment>
								),
							}}
							required
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							disabled={false}
							value={newUserData.Password.value || ''}
							id="new-user-field-Password"
							name="Password"
							placeholder={`${t('Users.User.Password')} *`}
							error={newUserData.Password.requiredError || newUserData.Password.error}
							helperText={newUserData.Password.error && newUserData.Password.errorMessage}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<KeyIcon />
									</InputAdornment>
								),
								endAdornment: (
									<IconButton onClick={handleShowPassword}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								),
							}}
							type={showPassword ? 'text' : 'password'}
							required
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							disabled={false}
							value={newUserData.ConfirmPassword.value || ''}
							id="new-user-field-ConfirmPassword"
							name="ConfirmPassword"
							placeholder={`${t('Users.User.ConfirmPassword')} *`}
							error={newUserData.ConfirmPassword.requiredError || newUserData.ConfirmPassword.error}
							helperText={newUserData.ConfirmPassword.error && newUserData.ConfirmPassword.errorMessage}
							onChange={handleInputChange}
							onBlur={validateConfirmPassword}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<KeyIcon />
									</InputAdornment>
								),
								endAdornment: (
									<IconButton onClick={handleShowPassword}>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								),
							}}
							type={showPassword ? 'text' : 'password'}
							required
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							disabled={false}
							value={newUserData.FirstName.value || ''}
							id="new-user-field-FirstName"
							name="FirstName"
							placeholder={`${t('Users.User.FirstName')} *`}
							error={newUserData.FirstName.requiredError || newUserData.FirstName.error}
							helperText={newUserData.FirstName.error && newUserData.FirstName.errorMessage}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PersonIcon />
									</InputAdornment>
								),
							}}
							required
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							disabled={false}
							value={newUserData.LastName.value || ''}
							id="new-user-field-LastName"
							name="LastName"
							placeholder={t('Users.User.LastName') as string}
							error={newUserData.LastName.error}
							helperText={newUserData.LastName.error && newUserData.LastName.errorMessage}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PersonIcon />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							disabled={false}
							value={newUserData.Telephone.value || ''}
							id="new-user-field-Telephone"
							name="Telephone"
							placeholder={t('Users.User.Telephone') as string}
							error={newUserData.Telephone.error}
							helperText={newUserData.Telephone.error && newUserData.Telephone.errorMessage}
							onChange={handleInputChange}
							onBlur={handleInputBlur}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LocalPhoneIcon />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default AddUser
