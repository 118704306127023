import { Skeleton } from '@mui/material'

import clsx from 'clsx'

type Props = {
	className?: string
}

const ButtonSkeleton = ({ className }: Props) => {
	return (
		<Skeleton
			className={clsx('h-10 w-40', className)}
			sx={{ bgcolor: 'background.secondary' }}
			variant="rounded"
			animation="wave"
		/>
	)
}

export default ButtonSkeleton
