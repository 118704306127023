export const getDateInPast = (days: number) => {
	// Get the current date
	const currentDate = new Date()

	// Subtract days from the current date
	currentDate.setDate(currentDate.getDate() - days)

	// Get the year, month, and day components of the new date
	const year = currentDate.getFullYear()
	const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so add 1
	const day = String(currentDate.getDate()).padStart(2, '0')

	// Create the formatted date string in "yyyy-mm-dd" format
	const formattedDate = `${year}-${month}-${day}`

	return formattedDate
}
