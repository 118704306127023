import { AutocompleteInputOption } from 'components/shared'

export const prepareAutocompleteInputOptions = (
	data: any[],
	valueFieldName: string,
	labelFieldName: string,
	forProject: boolean
): AutocompleteInputOption[] => {
	const modifiedData = data.map(el => {
		const label = forProject ? `${el.InvestmentName || ''} ${el.ProjectNumber || ''}` : el[labelFieldName]

		return {
			value: el[valueFieldName],
			label,
			SearchItems: el.SearchItems || label,
		}
	})

	const sortedData = modifiedData.sort((a: any, b: any) => {
		const labelA = a.label || ''
		const labelB = b.label || ''
		return labelA.localeCompare(labelB)
	})

	return sortedData
}
