import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const OrderIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M20.157,18.016c-0.907,0-1.722,0.405-2.271,1.047H8.535c-0.549-0.642-1.364-1.047-2.272-1.047
                c-0.127,0-0.252,0.008-0.375,0.022l-0.182-2.246h15.009c0.305,0,0.563-0.219,0.613-0.521L22.993,5.16
                c0.03-0.18-0.021-0.365-0.142-0.504c-0.117-0.139-0.291-0.22-0.474-0.22H4.783L4.584,1.981C4.495,0.889,3.551,0,2.478,0h-1.37v1.246
                h1.37c0.432,0,0.828,0.383,0.866,0.836l1.319,16.243c0.003,0.049,0.009,0.096,0.017,0.144c-0.847,0.528-1.41,1.469-1.41,2.538
                C3.27,22.658,4.611,24,6.263,24c1.652,0,2.993-1.342,2.993-2.993c0-0.241-0.028-0.478-0.083-0.7h8.075
                c-0.055,0.225-0.083,0.459-0.083,0.7c0,1.651,1.342,2.993,2.992,2.993c1.651,0,2.993-1.342,2.993-2.993S21.809,18.016,20.157,18.016
                z M21.645,5.682l-1.459,8.866H5.604l-0.72-8.866H21.645z M6.263,22.755c-0.964,0-1.749-0.784-1.749-1.748
                c0-0.963,0.785-1.747,1.749-1.747c0.965,0,1.748,0.784,1.748,1.747C8.011,21.971,7.228,22.755,6.263,22.755z M20.157,22.755
                c-0.964,0-1.748-0.784-1.748-1.748c0-0.963,0.784-1.747,1.748-1.747c0.965,0,1.749,0.784,1.749,1.747
                C21.906,21.971,21.122,22.755,20.157,22.755z"
			/>
		</SvgIcon>
	)
}

export default OrderIcon
