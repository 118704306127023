import TaskAltIcon from '@mui/icons-material/TaskAlt'

import clsx from 'clsx'
import { ICalendarEvent } from 'models'

type Props = {
	event: ICalendarEvent
	greyedOut: boolean
}

const EventPreview = ({ event, greyedOut }: Props) => {
	return (
		<p className={clsx('flex items-center text-xs m-1 p-1 h-4 bg-primary-main/10 rounded-md')}>
			<TaskAltIcon className="text-xs mr-1" color={greyedOut ? 'disabled' : 'primary'} />
			<span
				className="truncate"
				title={`${event.ContextItemIdLabel || ''} ${event.Description || ''}`}>{`${event.ContextItemIdLabel || ''} ${event.Description || ''}`}</span>
		</p>
	)
}

export default EventPreview
