import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'Role',
			'Person_FirstName',
			'Person_LastName',
			'Person_Telephone',
			'Person_EMail',
			'PrintOnDocs',
			'actions',
		],
		dimensions: {
			Role: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Person_FirstName: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			Person_LastName: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Person_Telephone: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Person_EMail: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			PrintOnDocs: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 130,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'projectInvestorRepresentativesDataGridState'
