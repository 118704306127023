import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { FilterPanel, SettingsMenu, TooltipButton } from 'components/shared'

import CreateInvestorProtocolDialog from '../project/CreateInvestorProtocolDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	getFilteredData: (reset?: boolean) => void
	apiRef: { current: any }
	showCanceled: boolean
	setShowCanceled: (value: boolean) => void
	showCompleted: boolean
	setShowCompleted: (value: boolean) => void
	isTab?: boolean
	projectId?: string
	handleFilter: () => void
}

const InvestorProcessingProtocolsToolbar = ({
	getFilteredData,
	apiRef,
	showCanceled,
	setShowCanceled,
	showCompleted,
	setShowCompleted,
	isTab,
	projectId,
	handleFilter,
}: Props) => {
	const [isCreateInvestorProtocolDialogOpen, setIsCreateInvestorProtocolDialogOpen] = useState(false)

	const handleCloseDialog = () => {
		setIsCreateInvestorProtocolDialogOpen(false)
	}

	const handleUpdateList = () => {
		getFilteredData()
	}

	return (
		<GridToolbarContainer className="items-center justify-between m-1.5">
			<FilterPanel
				handleFilter={handleFilter}
				apiRef={apiRef}
				getFilteredData={getFilteredData}
				contextName={CONTEXT_STATE_NAME}
				showCanceled={showCanceled}
				setShowCanceled={setShowCanceled}
				showCompleted={showCompleted}
				setShowCompleted={setShowCompleted}
			/>
			<div>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
				{isTab && (
					<TooltipButton
						title="general.Add"
						onClick={() => setIsCreateInvestorProtocolDialogOpen(true)}
						IconComponent={AddIcon}
						fontSize="medium"
					/>
				)}
				{isCreateInvestorProtocolDialogOpen && (
					<CreateInvestorProtocolDialog
						isOpen={isCreateInvestorProtocolDialogOpen}
						setIsOpen={handleCloseDialog}
						projectId={projectId as string}
					/>
				)}
			</div>
		</GridToolbarContainer>
	)
}

export default InvestorProcessingProtocolsToolbar
