import React from 'react'

import { Divider, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'

import useNavigationItems from 'hooks/UseNavigationItems'

import NavigationItem from './NavigationItem'
import NavigationMenuItem from './NavigationMenuItem'

const sxStyles = {
	conteiner: {
		display: 'flex',
		alignItems: 'center',
		ml: '10px',
	},
}

const Navigation = () => {
	const { navigationItems } = useNavigationItems()

	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('lg'))

	return (
		<Box sx={sxStyles.conteiner}>
			{navigationItems.map((el: any, index: any) => {
				return el.visible ? (
					<React.Fragment key={el.id}>
						{el.childItems ? <NavigationMenuItem item={el} /> : <NavigationItem item={el} />}
						{laptopViewAndUp && (
							<Divider
								sx={{
									borderColor: 'grey.200',
									'&:last-child': { display: 'none' },
								}}
								orientation="vertical"
								variant="middle"
								flexItem
							/>
						)}
					</React.Fragment>
				) : null
			})}
		</Box>
	)
}

export default Navigation
