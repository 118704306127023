import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import { useWorkflowService } from 'services'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	stageId: string
	stageName: string
	updateStages: () => void
}

const RemoveStageDialog = ({ isOpen, setIsOpen, stageId, stageName, updateStages }: Props) => {
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { deleteStage, showSuccessInfo } = useWorkflowService()

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleConfirm = async () => {
		try {
			setButtonLoading(true)
			await deleteStage(stageId)

			updateStages()
			closeDialog()
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogContent sx={sxStyles.contentContainer}>
				<Typography component="div" fontSize={16} textAlign={'center'} sx={{ width: '95%' }}>
					{`${t('Workflow.Permissions.RemoveStageDialog.Title')} ${stageName}?`}
				</Typography>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="contained" color="error" onClick={closeDialog}>
					{t('general.No', { ns: 'translation' })}
				</Button>
				<LoadingButton variant="contained" color="success" onClick={handleConfirm} loading={buttonLoading}>
					{t('general.Yes', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default RemoveStageDialog

const sxStyles = {
	contentContainer: { display: 'flex', justifyContent: 'center', mt: '20px' },
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 26px 16px 8px',
	},
}
