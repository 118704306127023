import React from 'react'
import { useTranslation } from 'react-i18next'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'
import { GridColumnHeaderParams } from '@mui/x-data-grid-premium'

import clsx from 'clsx'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

type Props = {
	params: GridColumnHeaderParams
	translationPath: string // without field name
	className?: string
	style?: React.CSSProperties // for components (like MUI Dialog) where tailwind CSS doesn't work
}

const ColumnHeaderRender = React.memo(({ params, translationPath, className, style }: Props) => {
	const { t, i18n } = useTranslation('hints')

	const name = params.colDef.headerName

	const combinedPath = `${translationPath}.${params.field}`

	const title = t(combinedPath)

	const isTranslationExists = i18n.exists(combinedPath, { ns: 'hints' })

	return (
		<>
			<span>{name}</span>
			{isTranslationExists && (
				<Tooltip title={<TooltipTextWrapper title={title} />} placement="top" arrow>
					<span className={clsx('flex items-center pl-1', className)} style={style}>
						<InfoOutlinedIcon fontSize="small" className="cursor-help" style={{ cursor: 'help' }} color="disabled" />
					</span>
				</Tooltip>
			)}
		</>
	)
})

export default ColumnHeaderRender
