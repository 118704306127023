import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Action } from 'models'

type InitialState = {
	selectedAction: Action
	selectedDocumentType: string
	isChangeStageDialogOpen: boolean
	changeStageDialogWarningText: string
	isCreateDocumentDialogOpen: boolean
	isConfirmCancelDialogOpen: boolean
	isLinkDocumentDialogOpen: boolean
	linkDialogDocumentName: string
	isConfirmDeletingDialogOpen: boolean
	isChangeNumberDialogOpen: boolean
	isPrintDocxDialogOpen: boolean
	isCreateCorrectionInvoiceDialogOpen: boolean
	isAdditionalCostDialogOpen: boolean
}

const initialState: InitialState = {
	selectedAction: { Name: '', Enabled: false, AfterAction: '' },
	selectedDocumentType: '',
	isChangeStageDialogOpen: false,
	changeStageDialogWarningText: '',
	isCreateDocumentDialogOpen: false,
	isConfirmCancelDialogOpen: false,
	isLinkDocumentDialogOpen: false,
	linkDialogDocumentName: '',
	isConfirmDeletingDialogOpen: false,
	isChangeNumberDialogOpen: false,
	isPrintDocxDialogOpen: false,
	isCreateCorrectionInvoiceDialogOpen: false,
	isAdditionalCostDialogOpen: false,
}

export const documentActionsState = createSlice({
	name: 'documentActionsState',
	initialState,
	reducers: {
		setSelectedAction: (state, action: PayloadAction<Action>) => {
			state.selectedAction = action.payload
		},
		setSelectedDocumentType: (state, action: PayloadAction<string>) => {
			state.selectedDocumentType = action.payload
		},
		setIsChangeStageDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isChangeStageDialogOpen = action.payload
		},
		setChangeStageDialogWarningText: (state, action: PayloadAction<string>) => {
			state.changeStageDialogWarningText = action.payload
		},
		setIsCreateDocumentDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isCreateDocumentDialogOpen = action.payload
		},
		setIsConfirmCancelDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isConfirmCancelDialogOpen = action.payload
		},
		setIsLinkDocumentDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isLinkDocumentDialogOpen = action.payload
		},
		setLinkDialogDocumentName: (state, action: PayloadAction<string>) => {
			state.linkDialogDocumentName = action.payload
		},
		setIsConfirmDeletingDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isConfirmDeletingDialogOpen = action.payload
		},
		setIsChangeNumberDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isChangeNumberDialogOpen = action.payload
		},
		setIsPrintDocxDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isPrintDocxDialogOpen = action.payload
		},
		setIsCreateCorrectionInvoiceDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isCreateCorrectionInvoiceDialogOpen = action.payload
		},
		setIsAdditionalCostDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isAdditionalCostDialogOpen = action.payload
		},
	},
})

export const documentActionsStateActions = documentActionsState.actions

export default documentActionsState.reducer
