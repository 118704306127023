import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import { LoadingButton } from '@mui/lab'
import { Button, Divider, IconButton, Tooltip } from '@mui/material'
import { Box, Tab, Tabs } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { StageStatus } from 'components/shared'
import Loading from 'components/shared/loading/Loading'
import { IStage, IStageActionsPermission, IStageConfiguration, IStageFieldsPermission } from 'models'
import { useWorkflowService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import AddStageDialog from './AddStageDialog'
import EditStageDialog from './EditStageDialog'
import RemoveStageDialog from './RemoveStageDialog'
import ReorderStagesDialog from './ReorderStagesDialog'
import StageActionsPermissions from './stage-actions-permissions/StageActionsPermissions'
import StageConfiguration from './stage-configuration/StageConfiguration'
import FieldsPermissions from './stage-fields-permissions/FieldsPermissions'

interface Props {
	workflowId: string
	roleId: string
}

const Stages = ({ workflowId, roleId }: Props) => {
	const [stages, setStages] = useState<IStage[]>([])
	const [selectedStage, setSelectedStage] = useState({ id: '', index: '0' })
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)

	const [isDeleteStageDialogOpen, setIsDeleteStageDialogOpen] = useState(false)
	const [isAddStageDialogOpen, setIsAddStageDialogOpen] = useState(false)
	const [isEditStageDialogOpen, setIsEditStageDialogOpen] = useState(false)
	const [isReorderStagesDialogOpen, setIsReorderStagesDialogOpen] = useState(false)

	const [configurationPermissions, setConfigurationPermissions] = useState<IStageConfiguration[]>([])
	const [configurationPermissionsLoading, setConfigurationLoading] = useState(true)

	const [actionsPermissions, setActionsPermissions] = useState<IStageActionsPermission[]>([])
	const [actionsPermissionsLoading, setActionsPermissionsLoading] = useState(true)

	const [fieldsPermissions, setFieldsPermissions] = useState<IStageFieldsPermission[]>([])
	const [fieldsPermissionsToRender, setFieldsPermissionsToRender] = useState<IStageFieldsPermission[]>([])
	const [fieldsPermissionsLoading, setFieldsPermissionsLoading] = useState(true)
	const [fieldsPermissionsSearchValue, setFieldsPermissionsSearchValue] = useState('')

	const [loading, setLoading] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const stageIndex = parseInt(selectedStage.index)

	const isDeleteButtonDisabled = stageIndex == 0 || stageIndex == stages.length - 1

	const { t } = useTranslation(['administration', 'translation'])

	const theme = useTheme()
	const tabletView = useMediaQuery(theme.breakpoints.down('lg'))

	const {
		updateStageConfigurationPermissions,
		getStageConfigurationPermissions,
		updateStageActionsPermissions,
		getStageActionsPermissions,
		getStageFieldsPermissions,
		updateFieldsPermissions,
		getWorkflowStages,
		showSuccessInfo,
	} = useWorkflowService()

	const isStageMarkedForCalculation = configurationPermissions.find(el => el.Key === 'MarkCalculation')?.Value

	const isObjectInArray = (objToCheck: { ShowProperty: string }, dataArray: IStageFieldsPermission[]) => {
		return dataArray.some(obj => obj.ShowProperty === objToCheck.ShowProperty)
	}

	const handleTabChange = (event: any, newValue: string) => {
		setSelectedStage({ id: stages[parseInt(newValue)].Id, index: newValue })
	}

	const handleActionsPermissionsChange = useCallback(
		(e: any) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const value = e.target.checked
			const fieldName = e.target.name

			const foundObject = actionsPermissions.find(item => item.Key === fieldName)

			if (foundObject) {
				const updatedPermissions = actionsPermissions.map(item => {
					if (item.Key === fieldName) {
						return { ...item, Value: value }
					}
					return item
				})

				setActionsPermissions(updatedPermissions)
			} else {
				return
			}
		},
		[isSaveButtonDisabled, actionsPermissions]
	)

	const handleConfigurationPermissionsChange = useCallback(
		(e: any) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const value = e.target.checked
			const fieldName = e.target.name

			const foundObject = configurationPermissions.find(item => item.Key === fieldName)

			if (foundObject) {
				const updatedPermissions = configurationPermissions.map(item => {
					if (item.Key === fieldName) {
						return { ...item, Value: value }
					}
					return item
				})

				setConfigurationPermissions(updatedPermissions)
			} else {
				return
			}
		},
		[isSaveButtonDisabled, configurationPermissions]
	)

	const handleFieldsPermissionsChange = (e: any, fieldName: string) => {
		isSaveButtonDisabled && setIsSaveButtonDisabled(false)

		const value = e.target.value

		const foundObject = fieldsPermissions.find(item => item.Property === fieldName)

		if (foundObject) {
			const updatedPermissions = fieldsPermissions.map(item => {
				if (item.Property === fieldName) {
					return { ...item, Access: value }
				}
				return item
			})

			setFieldsPermissions(updatedPermissions)
			setFieldsPermissionsToRender(
				updatedPermissions.filter(el => {
					const isElementRendered = isObjectInArray(
						{ ShowProperty: el.ShowProperty as string },
						fieldsPermissionsToRender
					)

					if (isElementRendered) {
						return el
					}
				}) as IStageFieldsPermission[]
			)
		} else {
			return
		}
	}

	const handleMarkFieldsPermissions = (marker: string) => {
		isSaveButtonDisabled && setIsSaveButtonDisabled(false)

		const changedProperties = fieldsPermissions.map(el => {
			const isElementRendered = isObjectInArray({ ShowProperty: el.ShowProperty as string }, fieldsPermissionsToRender)

			if (el.IsReadonly && marker === 'ReadWrite') {
				return { ...el, Access: 'Read' }
			} else if (isElementRendered) {
				return { ...el, Access: marker }
			} else {
				return el
			}
		})

		setFieldsPermissions(changedProperties)
		setFieldsPermissionsToRender(
			changedProperties.filter(el => {
				const isElementRendered = isObjectInArray(
					{ ShowProperty: el.ShowProperty as string },
					fieldsPermissionsToRender
				)

				if (isElementRendered) {
					return el
				}
			}) as IStageFieldsPermission[]
		)
	}

	const handleSavePermissionsChanges = async () => {
		// removes translated ShowProperty key before saving data
		const updatedFieldsPermissions = fieldsPermissions.map((el: IStageFieldsPermission) => {
			const { Access, IsReadonly, Property } = el

			return { Access, IsReadonly, Property }
		})

		try {
			setButtonLoading(true)

			await updateStageActionsPermissions(selectedStage.id, roleId, actionsPermissions)

			await updateStageConfigurationPermissions(selectedStage.id, configurationPermissions)

			await updateFieldsPermissions(selectedStage.id, roleId, updatedFieldsPermissions)

			showSuccessInfo('saved')

			getStageFieldsPermissionsData()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
		setIsSaveButtonDisabled(true)
	}

	const getStagesData = useCallback(
		async (selectnewlyAddedStage?: boolean, ignoreIndexChange?: boolean) => {
			try {
				setLoading(true)
				const response = await getWorkflowStages(workflowId)

				const sortedStages = response.sort((a: IStage, b: IStage) => {
					if (a.Index === 0) return -1
					if (b.Index === 0) return 1
					return a.Index - b.Index
				})

				setStages(sortedStages)

				if (selectnewlyAddedStage && response.length) {
					setSelectedStage({
						id: sortedStages[response.length - 2].Id,
						index: (response.length - 2).toString(),
					})
				} else if (response.length && !ignoreIndexChange) {
					setSelectedStage({ id: sortedStages[0].Id, index: '0' })
				}
			} catch (err) {
				console.error(err)
			}
			setLoading(false)
		},
		[getWorkflowStages, workflowId]
	)

	const getStageActionsPermissionsData = async () => {
		try {
			setActionsPermissionsLoading(true)
			const response = await getStageActionsPermissions(selectedStage.id, roleId)

			setActionsPermissions(response)
		} catch (err) {
			console.error(err)
		}
		setActionsPermissionsLoading(false)
	}

	const getStageConfigurationPermissionsData = async () => {
		try {
			setConfigurationLoading(true)
			const response = await getStageConfigurationPermissions(selectedStage.id)

			setConfigurationPermissions(response)
		} catch (err) {
			console.error(err)
		}
		setConfigurationLoading(false)
	}

	const getStageFieldsPermissionsData = async () => {
		try {
			setFieldsPermissionsLoading(true)
			const response = await getStageFieldsPermissions(selectedStage.id, roleId)

			const translatedProperties = response.map((el: IStageFieldsPermission) => {
				return {
					...el,
					ShowProperty: t(`Workflow.Permissions.RolePermissions.Stages.FieldsPermissions.Fields.${el.Property}`, {
						defaultValue: el.Property,
					}),
				}
			})

			const sortedFieldsPermissions = translatedProperties.sort((a: any, b: any) =>
				a.ShowProperty.localeCompare(b.ShowProperty)
			)

			setFieldsPermissions(sortedFieldsPermissions)
			setFieldsPermissionsToRender(sortedFieldsPermissions)
			setFieldsPermissionsSearchValue('')
		} catch (err) {
			console.error(err)
		}
		setFieldsPermissionsLoading(false)
	}

	useEffect(() => {
		getStagesData()
	}, [workflowId])

	useEffect(() => {
		if (selectedStage.id) {
			getStageActionsPermissionsData()
			getStageFieldsPermissionsData()
		}
	}, [workflowId, roleId, selectedStage.id])

	useEffect(() => {
		if (selectedStage.id) getStageConfigurationPermissionsData()
	}, [workflowId, selectedStage.id])

	if (loading) {
		return <Loading />
	}

	return (
		<>
			{stages.length > 0 ? (
				<>
					<Box sx={sxStyles.container}>
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							value={selectedStage.index}
							onChange={handleTabChange}
							sx={sxStyles.tabsContainer}>
							{stages.map((el: IStage, index: number) => {
								return (
									<Tab
										key={el.Id}
										sx={sxStyles.tabButtonStyles}
										label={`${el.Index + 1}. ${el.Name}`}
										value={index.toString()}
										id={el.Id}
									/>
								)
							})}
						</Tabs>
						<Box sx={sxStyles.tabsToolsContainer}>
							{stages.length > 3 && (
								<Tooltip
									title={<TooltipTextWrapper title={t('general.Reorder', { ns: 'translation' })} />}
									placement="top"
									arrow>
									<span>
										<IconButton sx={{ padding: '6px' }} onClick={() => setIsReorderStagesDialogOpen(true)}>
											<FormatListNumberedIcon fontSize="medium" color={'primary'} />
										</IconButton>
									</span>
								</Tooltip>
							)}
							<Tooltip
								title={<TooltipTextWrapper title={t('general.AddStage', { ns: 'translation' })} />}
								placement="top"
								arrow>
								<span>
									<IconButton sx={{ padding: '6px' }} disabled={false} onClick={() => setIsAddStageDialogOpen(true)}>
										<AddIcon fontSize="medium" color={'primary'} />
									</IconButton>
								</span>
							</Tooltip>
						</Box>
					</Box>
					<Box sx={sxStyles.contentContainer}>
						<Divider sx={{ m: '10px 15px' }} />
						<Box sx={sxStyles.toolsContainer}>
							<div className="flex items-center">
								<StageStatus
									start={selectedStage.index === '0'}
									markCalculation={isStageMarkedForCalculation}
									completeEnabled={selectedStage.index === (stages.length - 1).toString()}
								/>
								<span className="px-1">{stages[+selectedStage.index].Description}</span>
							</div>
							<Box>
								<Tooltip
									title={<TooltipTextWrapper title={t('general.Edit', { ns: 'translation' })} />}
									placement="top"
									arrow>
									<span>
										<IconButton sx={{ padding: '6px' }} disabled={false} onClick={() => setIsEditStageDialogOpen(true)}>
											<EditIcon fontSize="medium" color={'primary'} />
										</IconButton>
									</span>
								</Tooltip>
								<Tooltip
									title={<TooltipTextWrapper title={t('general.Remove', { ns: 'translation' })} />}
									placement="top"
									arrow>
									<span>
										<IconButton
											sx={{ padding: '6px', mr: '10px' }}
											disabled={isDeleteButtonDisabled}
											onClick={() => setIsDeleteStageDialogOpen(true)}>
											<DeleteIcon fontSize="medium" color={isDeleteButtonDisabled ? 'disabled' : 'primary'} />
										</IconButton>
									</span>
								</Tooltip>
								<LoadingButton
									variant="contained"
									disabled={isSaveButtonDisabled}
									onClick={handleSavePermissionsChanges}
									loading={buttonLoading}>
									{t('general.Save', { ns: 'translation' })}
								</LoadingButton>
							</Box>
						</Box>
						<Divider sx={{ m: '10px 15px' }} />
						<StageActionsPermissions
							actionsPermissions={actionsPermissions}
							handleDataChange={handleActionsPermissionsChange}
							loading={actionsPermissionsLoading}
						/>
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'flex-start',
								flexDirection: tabletView ? 'column-reverse' : 'row',
							}}>
							<FieldsPermissions
								fieldsPermissions={fieldsPermissions}
								fieldsPermissionsToRender={fieldsPermissionsToRender}
								handleDataChange={handleFieldsPermissionsChange}
								loading={fieldsPermissionsLoading}
								handleMarkFieldsPermissions={handleMarkFieldsPermissions}
								setFieldsPermissionsToRender={setFieldsPermissionsToRender}
								searchValue={fieldsPermissionsSearchValue}
								setSearchValue={setFieldsPermissionsSearchValue}
							/>
							<StageConfiguration
								configurationPermissions={configurationPermissions}
								handleDataChange={handleConfigurationPermissionsChange}
								loading={configurationPermissionsLoading}
							/>
						</Box>
					</Box>
				</>
			) : (
				<Box sx={{ display: 'flex', justifyContent: 'center', mt: '30px' }}>
					<Button onClick={() => setIsAddStageDialogOpen(true)}>{t('general.AddStage', { ns: 'translation' })}</Button>
				</Box>
			)}
			{isDeleteStageDialogOpen && (
				<RemoveStageDialog
					isOpen={isDeleteStageDialogOpen}
					setIsOpen={setIsDeleteStageDialogOpen}
					stageId={selectedStage.id}
					stageName={stages[parseInt(selectedStage.index)].Name}
					updateStages={getStagesData}
				/>
			)}
			{isAddStageDialogOpen && (
				<AddStageDialog
					isOpen={isAddStageDialogOpen}
					setIsOpen={setIsAddStageDialogOpen}
					workflowId={workflowId}
					updateStages={getStagesData}
				/>
			)}
			{isEditStageDialogOpen && (
				<EditStageDialog
					isOpen={isEditStageDialogOpen}
					setIsOpen={setIsEditStageDialogOpen}
					stageId={stages[stageIndex].Id}
					stageData={stages[stageIndex]}
					updateStages={getStagesData}
				/>
			)}
			{isReorderStagesDialogOpen && (
				<ReorderStagesDialog
					isOpen={isReorderStagesDialogOpen}
					setIsOpen={setIsReorderStagesDialogOpen}
					updateStages={getStagesData}
					workflowId={workflowId}
					stages={stages}
				/>
			)}
		</>
	)
}

export default Stages

const sxStyles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		pt: '10px',
	},
	tabsContainer: {
		width: '85%',
		'& .MuiTabs-indicator': {
			display: 'none',
		},
		'& .Mui-selected': {
			border: '3px solid',
		},
	},
	tabsToolsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		width: '15%',
		p: '0 15px',
	},
	tabButtonStyles: {
		backgroundColor: 'action.hover',
		borderRadius: '15px',
		margin: '5px 15px 5px 0',
		padding: '9px 18px',
		minHeight: '42px',
		'& .Mui-selected': {
			border: '3px solid',
		},
		'&:hover': {
			backgroundColor: 'action.selected',
		},
	},
	toolsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		p: '0 15px',
	},
	contentContainer: {
		margin: '10px 0',
	},
}
