import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Divider, IconButton, Step, StepContent, StepLabel, Stepper, Tooltip } from '@mui/material'

import clsx from 'clsx'
import { useAppSelector, useDocumentDataActions } from 'features'
import { IWorkflowStage } from 'models'
import { useWorkflowService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import { Loading } from '../loading'
import StageStatus from './StageStatus'

const localStorageName = 'IS_WORKFLOW_STAGE_PANEL_OPEN'

type Props = {
	workflowId: string
	activeStage: string
}

const WorkflowWidget = ({ workflowId, activeStage }: Props) => {
	const [stageList, setStageList] = useState<IWorkflowStage[]>([])
	const [loading, setLoading] = useState(true)

	const { isWorkflowPanelOpen } = useAppSelector(state => state.documentData)

	const { t } = useTranslation()

	const activeStageIndex = stageList.findIndex(el => el.Id === activeStage)

	const { getWorkflowStages } = useWorkflowService()
	const { setIsWorkflowPanelOpen } = useDocumentDataActions()

	const setLocalStorageWorkflowPanelValue = (value: boolean) =>
		localStorage.setItem(localStorageName, JSON.stringify(value))

	const handleOpenPanel = () => {
		setLocalStorageWorkflowPanelValue(true)
		if (!isWorkflowPanelOpen) setIsWorkflowPanelOpen(true)
	}

	const handleClosePanel = () => {
		setLocalStorageWorkflowPanelValue(false)
		setIsWorkflowPanelOpen(false)
	}

	const getStageList = async () => {
		try {
			const response = await getWorkflowStages(workflowId)
			setStageList(response.sort((a: any, b: any) => a.Index - b.Index))
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (workflowId) {
			getStageList()
		}
	}, [workflowId])

	useEffect(() => {
		const localStorageData = localStorage.getItem(localStorageName)

		if (localStorageData) {
			setIsWorkflowPanelOpen(JSON.parse(localStorageData))
		}
	}, [])

	return (
		<div
			className={clsx(
				'flex flex-col px-3 pt-4 max-w-60',
				'border-0 border-r border-solid border-r-border-divider dark:border-r-border-darkDivider',
				{ 'min-w-12 items-center': !isWorkflowPanelOpen, 'min-w-60': isWorkflowPanelOpen }
			)}>
			{isWorkflowPanelOpen && (
				<>
					<div className="flex justify-between items-center mb-2">
						<span className={clsx('w-full pl-2 text-lg')}>{t('DocumentWorkflowPage.StageDiagram.Title')}</span>
						<IconButton className="p-1.5" onClick={handleClosePanel}>
							<CloseIcon fontSize="small" color="primary" />
						</IconButton>
					</div>
					<Divider />
				</>
			)}
			{loading ? (
				<div className="flex flex-col items-center justify-start">
					<Loading size={32} />
				</div>
			) : (
				<Stepper className="pl-1 pt-2" activeStep={activeStageIndex} orientation="vertical">
					{stageList.map(step => (
						<Step key={step.Id} expanded>
							<Tooltip
								title={isWorkflowPanelOpen ? '' : <TooltipTextWrapper title={step.Name} />}
								placement="right"
								arrow>
								<StepLabel
									className={clsx({ 'cursor-pointer': !isWorkflowPanelOpen })}
									onClick={handleOpenPanel}
									optional={
										isWorkflowPanelOpen && (
											<StageStatus
												start={undefined}
												completeEnabled={step.IsCompleted}
												markCalculation={step.IsToCalculation}
												iconSize="small"
											/>
										)
									}>
									<span className={clsx({ hidden: !isWorkflowPanelOpen })}>{step.Name}</span>
								</StepLabel>
							</Tooltip>
							<StepContent className={clsx('text-gray-500 dark:text-gray-300/70', { hidden: !isWorkflowPanelOpen })}>
								{step.Description || ''}
							</StepContent>
						</Step>
					))}
				</Stepper>
			)}
		</div>
	)
}

export default WorkflowWidget
