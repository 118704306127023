import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, MenuItem, TextField, Tooltip } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { INumerator, IWorkflow } from 'models'
import { useNumeratorService, useWorkflowService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import AddNumeratorDialog from './AddNumeratorDialog'
import EditNumeratorDialog from './EditNumeratorDialog'

interface Props {
	workflowId: string
}

const WorkflowBasicInfo = ({ workflowId }: Props) => {
	const [workflowData, setWorkflowData] = useState<IWorkflow>()
	const [numeratorList, setNumeratorList] = useState<INumerator[]>([])
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [dataLoading, setDataLoading] = useState(true)
	const [isEditNumeratorDialogOpen, setIsEditNumeratorDialogOpen] = useState(false)
	const [isAddNumeratorDialogOpen, setIsAddNumeratorDialogOpen] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { getNumeratorList } = useNumeratorService()

	const { clearError, fieldsError, showSuccessInfo, getWorkflow, updateWorkflow, updateNumerator } =
		useWorkflowService()

	const handleDataChange = useCallback(
		(e: any) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const value = e.target.value
			const fieldName = e.target.name

			if (fieldsError.includes(fieldName)) {
				clearError(fieldName)
			}
			setWorkflowData((prev: any) => {
				return { ...prev, [fieldName]: value }
			})
		},
		[fieldsError, clearError, isSaveButtonDisabled]
	)

	const handleDataSave = useCallback(async () => {
		try {
			setButtonLoading(true)
			await updateWorkflow(workflowId, workflowData as IWorkflow)
			await updateNumerator(workflowId, workflowData?.NumeratorId as string)

			getData()
			showSuccessInfo('saved')
			setIsSaveButtonDisabled(true)
		} catch (err) {
			console.error(err)
		}

		setButtonLoading(false)
	}, [workflowId, workflowData, updateWorkflow])

	const getData = useCallback(async () => {
		try {
			const response = await getWorkflow(workflowId)

			setWorkflowData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getWorkflow, workflowId])

	const getNumeratorData = useCallback(async () => {
		try {
			const response = await getNumeratorList()

			setNumeratorList(response)
		} catch (err) {
			console.error(err)
		}
	}, [getNumeratorList])

	useEffect(() => {
		getNumeratorData()
	}, [workflowId])

	useEffect(() => {
		getData()
	}, [workflowId])

	if (dataLoading) {
		return <Loading />
	} else if (!dataLoading && !workflowData) {
		return <NoData />
	}

	return (
		<>
			<Box sx={sxStyles.toolsContainer}>
				<LoadingButton
					variant="contained"
					disabled={isSaveButtonDisabled}
					onClick={handleDataSave}
					loading={buttonLoading}>
					{t('general.SaveButton', { ns: 'translation' })}
				</LoadingButton>
			</Box>
			<Box sx={sxStyles.formContainer}>
				<Box sx={sxStyles.row}>
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						<Grid xs={12} sm={6} md={6} lg={6}>
							<TextField
								disabled={false}
								value={workflowData?.Name || ''}
								name="Name"
								label={t('Workflow.Fields.Name')}
								error={fieldsError.includes('Name')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						<Grid xs={12} sm={6} md={6} lg={6}>
							<TextField
								disabled={true}
								value={t(`Workflow.Fields.DataTypeEnums.${workflowData?.DataType}`)}
								name="DataType"
								label={t('Workflow.Fields.DataType')}
								error={fieldsError.includes('DataType')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								select
								disabled={false}
								value={workflowData?.NumeratorId || ''}
								name="NumeratorId"
								label={t('Workflow.Fields.NumeratorId')}
								error={fieldsError.includes('NumeratorId')}
								onChange={handleDataChange}
								fullWidth
								InputProps={{
									style: {
										paddingRight: '40px',
									},
									endAdornment: (
										<>
											{workflowData?.NumeratorId && (
												<Tooltip
													title={
														<TooltipTextWrapper
															title={t('general.Edit', {
																ns: 'translation',
															})}
														/>
													}
													placement="top"
													arrow>
													<span>
														<IconButton onClick={() => setIsEditNumeratorDialogOpen(true)}>
															<EditIcon fontSize="medium" color="primary" />
														</IconButton>
													</span>
												</Tooltip>
											)}
											<Tooltip
												title={
													<TooltipTextWrapper
														title={t('general.Add', {
															ns: 'translation',
														})}
													/>
												}
												placement="top"
												arrow>
												<span>
													<IconButton onClick={() => setIsAddNumeratorDialogOpen(true)}>
														<AddIcon fontSize="medium" color="primary" />
													</IconButton>
												</span>
											</Tooltip>
										</>
									),
								}}>
								{numeratorList.map((el: INumerator, index: number) => (
									<MenuItem key={index} value={el.Id}>
										{`${el.Name || ''} - ${el.Format || ''}`}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
				</Box>
			</Box>
			{isEditNumeratorDialogOpen && (
				<EditNumeratorDialog
					isOpen={isEditNumeratorDialogOpen}
					setIsOpen={setIsEditNumeratorDialogOpen}
					numeratorId={workflowData?.NumeratorId as string}
					refreshNumeratorList={getNumeratorData}
				/>
			)}
			{isAddNumeratorDialogOpen && (
				<AddNumeratorDialog
					isOpen={isAddNumeratorDialogOpen}
					setIsOpen={setIsAddNumeratorDialogOpen}
					refreshNumeratorList={getNumeratorData}
					setNewNumerator={handleDataChange}
				/>
			)}
		</>
	)
}

export default WorkflowBasicInfo

const sxStyles = {
	toolsContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: '15px 15px 0 15px',
	},
	title: {
		display: 'inline-block',
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: '15px',
		pt: '0',
	},
	row: {
		width: '100%',
		pb: '15px',
	},
}
