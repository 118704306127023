import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	TextField,
} from '@mui/material'

import { useAppSelector, useDocumentActionsStateActions } from 'features'
import { Action } from 'models'

type Props = {
	isOpen: boolean
	action: Action
	invokeAction: (action: Action, parameters?: any) => Promise<void>
}

const ChangeNumberDialog = ({ isOpen, action, invokeAction }: Props) => {
	const [newNumber, setNewNumber] = useState('')

	const { documentData } = useAppSelector(state => state.documentData)

	const { setIsChangeNumberDialogOpen } = useDocumentActionsStateActions()

	const { t } = useTranslation()

	const closeDialog = () => {
		setIsChangeNumberDialogOpen(false)
		setNewNumber('')
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setNewNumber(e.target.value)
	}

	const handleActionConfirm = async () => {
		const parameters = { newNumber }
		try {
			await invokeAction(action, parameters)
			closeDialog()
		} catch (err) {
			console.error(err)
		}
	} 

	useEffect(() => {
		setNewNumber(documentData?.Number || '')
	}, [documentData])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t(`Actions.Names.${action?.Name}`)}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<FormControl fullWidth>
					<TextField
						sx={{ mt: '10px' }}
						value={newNumber}
						name="newNumber"
						label={t('Fields.Number', { ns: 'contract' })}
						onChange={handleInputChange}
						fullWidth
					/>
				</FormControl>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.Cancel')}
				</Button>
				<Button variant="contained" onClick={handleActionConfirm} color="success">
					{t('general.Confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ChangeNumberDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
