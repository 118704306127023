import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'

import { IAssignedWorkflowRole, IRoleHeader } from 'models'
import { useRolesService, useWorkflowService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	refreshRoles: (selectLastRole?: boolean) => void
	workflowId: string
	alreadyAssignedRoles: IAssignedWorkflowRole[]
}

const AddRoleDialog = ({ isOpen, setIsOpen, refreshRoles, workflowId, alreadyAssignedRoles }: Props) => {
	const [allRoles, setAllRoles] = useState<IRoleHeader[]>([])
	const [buttonLoading, setButtonLoading] = useState(false)
	const [selectedRoleId, setSelectedRoleId] = useState('')

	const { t } = useTranslation(['administration', 'translation'])

	const { getRolesCanBePerProject, showSuccessInfo } = useRolesService()
	const { assignRole } = useWorkflowService()

	const closeDialog = () => {
		setIsOpen(false)
		setSelectedRoleId('')
	}

	const handleDataSave = useCallback(async () => {
		try {
			setButtonLoading(true)
			await assignRole(workflowId, selectedRoleId)

			refreshRoles(true)
			closeDialog()
			showSuccessInfo('saved')
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}, [assignRole, workflowId, selectedRoleId])

	const getRolesData = useCallback(async () => {
		try {
			const response = await getRolesCanBePerProject()

			const filteredRoles = response.filter(
				(role: IRoleHeader) => !alreadyAssignedRoles.some(assignedRole => assignedRole.Id === role.Id)
			)

			setAllRoles(filteredRoles)
		} catch (err) {
			console.error(err)
		}
	}, [getRolesCanBePerProject])

	useEffect(() => {
		getRolesData()
	}, [])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Workflow.Permissions.AddRoleDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={selectedRoleId}
					select
					name="Role"
					label={t('Workflow.Permissions.AddRoleDialog.InputLabel')}
					onChange={e => setSelectedRoleId(e.target.value)}
					fullWidth
					sx={{ mt: '10px' }}>
					{allRoles.map(el => (
						<MenuItem key={el.Id} value={el.Id}>
							{el.Name}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={!selectedRoleId}
					loading={buttonLoading}
					onClick={handleDataSave}>
					{t('general.Save', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddRoleDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
