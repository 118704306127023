import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IBusinessData } from 'models'

const useBusinessDataService = () => {
	const { request, uploadFileRequest, getFileURL, error, clearError, fieldsError, showSuccessInfo } = useHttp()

	const getBusinessData = useCallback(async () => {
		const response = await request(`/api/businessData`)
		return response
	}, [request])

	const updateBusinessData = useCallback(
		async (data: IBusinessData) => {
			await request(`/api/businessData`, 'PATCH', data)
		},
		[request]
	)

	const addCustomLogo = useCallback(
		async (formData: any) => {
			await uploadFileRequest(`/api/businessData/logo`, 'PATCH', formData)
		},
		[uploadFileRequest]
	)

	const getLogoURL = useCallback(async () => {
		const response = await getFileURL(`/api/businessData/logo`)
		return response
	}, [getFileURL])

	return {
		error,
		clearError,
		showSuccessInfo,
		getBusinessData,
		updateBusinessData,
		fieldsError,
		addCustomLogo,
		getLogoURL,
	}
}

export default useBusinessDataService
