import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Switch, Tooltip } from '@mui/material/'

import TooltipTextWrapper from 'utils/TooltipTextWrapper'

interface SwitchTreeDataProps {
	isActionButtonDisabled: boolean
	isTreeData: boolean
	setIsTreeData: (value: boolean) => void
}

const SwitchTreeData = ({ isActionButtonDisabled, isTreeData, setIsTreeData }: SwitchTreeDataProps) => {
	const { t } = useTranslation()
	const location = useLocation()
	const localStorageKey = `listTreeData_${location.pathname}`

	const handleIsTreDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newIsTreeData = event.target.checked
		setIsTreeData(newIsTreeData)
		localStorage.setItem(localStorageKey, JSON.stringify(newIsTreeData))
	}

	return (
		<Tooltip
			title={<TooltipTextWrapper title={isTreeData ? t('general.TurnOffTreeView') : t('general.TurnOnTreeView')} />}
			placement="top"
			arrow>
			<Switch checked={isTreeData} onChange={handleIsTreDataChange} size="small" disabled={isActionButtonDisabled} />
		</Tooltip>
	)
}

export default SwitchTreeData
