import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { List, ListItem, ListItemText } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { useRolesService } from 'services'

interface Props {
	roleId: string
}

const RolePerProjectUsers = ({ roleId }: Props) => {
	const [userRoleList, setUserRoleList] = useState([])
	const [dataLoading, setDataLoading] = useState(true)

	const { t } = useTranslation('administration')

	const { getPerProjectUsersForRole } = useRolesService()

	const getUsersData = useCallback(async () => {
		try {
			const usersForRole = await getPerProjectUsersForRole(roleId)

			setUserRoleList(usersForRole)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getPerProjectUsersForRole, roleId])

	useEffect(() => {
		getUsersData()
	}, [roleId])

	return (
		<List
			sx={{
				width: '100%',
				padding: '0',
			}}>
			<ListItem
				sx={{
					borderBottom: 1,
					borderColor: 'divider',
					borderRadius: '4px 4px 0 0',
					color: 'text.secondary',
				}}>
				<ListItemText
					sx={{
						textAlign: 'left',
						width: '20%',
					}}
					primaryTypographyProps={{ fontSize: '14px' }}
					primary={t('Roles.Role.Project')}
				/>
				<ListItemText
					sx={{
						textAlign: 'left',
						width: '20%',
					}}
					primaryTypographyProps={{ fontSize: '14px' }}
					primary={t('Roles.Role.User')}
				/>
			</ListItem>
			{dataLoading ? (
				<Loading />
			) : userRoleList.length > 0 ? (
				userRoleList.map((el: any, index: number) => {
					return (
						<ListItem
							sx={{
								borderBottom: 1,
								borderColor: 'divider',
								borderRadius: '4px 4px 0 0',
								color: 'text.secondary',
							}}
							key={index}>
							<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.ProjectIdLabel || ''} />
							<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.UserIdLabel || ''} />
						</ListItem>
					)
				})
			) : (
				<ListItem>
					<NoData fontSize="18px" />
				</ListItem>
			)}
		</List>
	)
}

export default RolePerProjectUsers
