import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'

import { useAppSelector } from 'features'
import { useFieldsPermissions } from 'hooks'
import { EndpointType, useContactPersonsService } from 'services'

import { NoData } from '../no-data-message'
import { ContractorContactPersons } from './contractor-contact-persons'
import { UserContactPersons } from './user-contact-persons'

interface Props {
	documentId: string
	contractorId?: string
	endpoint: EndpointType
}

const ContactPersons = ({ documentId, contractorId, endpoint }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { businessData } = useAppSelector(state => state.businessData)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation()

	const {
		getContractorsContactPersons,
		addContractorsContactPerson,
		deleteContractorsContactPerson,
		getUsersContactPersons,
		addUsersContactPerson,
		deleteUsersContactPerson,
	} = useContactPersonsService(endpoint)

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('ContactTabs.Contractor'),
				renderValue: (
					<ContractorContactPersons
						contractorId={contractorId as string}
						contextId={documentId}
						readOnly={!isEditable('ContractorContacts')}
						getContractorsContactPersons={getContractorsContactPersons}
						addContractorsContactPerson={addContractorsContactPerson}
						deleteContractorsContactPerson={deleteContractorsContactPerson}
					/>
				),
				isVisible: contractorId && isVisible('ContractorContacts'),
			},
			{
				label: businessData?.Name,
				renderValue: (
					<UserContactPersons
						contextId={documentId}
						readOnly={!isEditable('ContactPersons')}
						getContactsFunction={getUsersContactPersons}
						addContactFunction={addUsersContactPerson}
						deleteContactFunction={deleteUsersContactPerson}
					/>
				),
				isVisible: isVisible('ContactPersons'),
			},
		],
		[t, businessData, documentId, contractorId]
	)

	const vissibleTabsItems = tabs.filter(item => item.isVisible)

	return (
		<div className="w-full mt-1">
			{vissibleTabsItems.length ? (
				<TabContext value={selectedTab}>
					<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
						{vissibleTabsItems.map((el, index) => {
							return <Tab key={index} className="text-xs" label={el.label} value={index.toString()} />
						})}
					</TabList>
					{vissibleTabsItems.map((el, index) => {
						return (
							<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
								{el.renderValue}
							</TabPanel>
						)
					})}
				</TabContext>
			) : (
				<NoData fontSize="20px" />
			)}
		</div>
	)
}

export default ContactPersons
