import { useEffect } from 'react'

import DoDisturbIcon from '@mui/icons-material/DoDisturb'

import { TooltipButton } from 'components/shared/buttons'

type Props = {
	contextName: string
	showCanceled: boolean
	setShowCanceled: (value: boolean) => void
}

const ShowCanceledButton = ({ contextName, showCanceled, setShowCanceled }: Props) => {
	const sessionStorageName = `${contextName}_SHOW_CANCELED`

	const cachedValue = sessionStorage.getItem(sessionStorageName)

	const sessionStorageValue = cachedValue ? JSON.parse(cachedValue) : false

	const handleShowCanceledChange = () => {
		setShowCanceled(!showCanceled)
		sessionStorage.setItem(sessionStorageName, JSON.stringify(!showCanceled))
	}

	useEffect(() => {
		setShowCanceled(sessionStorageValue)
	}, [contextName])

	return (
		<TooltipButton
			className="ml-2"
			title="general.ShowCanceled"
			onClick={handleShowCanceledChange}
			IconComponent={DoDisturbIcon}
			color={showCanceled ? 'error' : 'disabled'}
			fontSize="medium"
		/>
	)
}

export default ShowCanceledButton
