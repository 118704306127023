import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: ['Login', 'FirstName', 'LastName', 'EMail', 'Telephone', 'actions'],
		dimensions: {
			Login: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			FirstName: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			LastName: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			EMail: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			Telephone: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 120,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'administrationUsersDataGridState'
