import { useTranslation } from 'react-i18next'

import { Box, Divider, InputAdornment, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { InputHint } from 'components/shared'
import { IProjectModel } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'

interface Props {
	formData?: IProjectModel | null
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
	handleCalculateProjectData?: () => void
	readOnly: boolean
}

const BudgetAssumption = ({
	formData,
	onInputChange,
	fieldErrorsList,
	handleCalculateProjectData,
	readOnly,
}: Props) => {
	const { t } = useTranslation('project')
	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('md'))

	return (
		<Box
			sx={{
				...sxStyles.container,
				flexDirection: laptopViewAndUp ? 'row' : 'column',
			}}>
			<Box sx={laptopViewAndUp ? sxStyles.itemsContainerLeft : sxStyles.itemsContainerMobile}>
				<Typography fontSize={'18px'} sx={sxStyles.title}>
					{t('FormTitles.BudgetAgreementAssumptions')}
				</Typography>
				<Divider />
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Direct Costs */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.DirectCosts || ''}
							id="project-field-DirectCosts"
							name="DirectCosts"
							label={t('ProjectFields.DirectCosts')}
							error={fieldErrorsList.includes('DirectCosts')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.DirectCosts" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* General Costs */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.GeneralCosts || ''}
							id="project-field-GeneralCosts"
							name="GeneralCosts"
							label={t('ProjectFields.GeneralCosts')}
							error={fieldErrorsList.includes('GeneralCosts')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.GeneralCosts" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Direct And General Costs */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.DirectAndGeneralCosts || ''}
							id="project-field-DirectAndGeneralCosts"
							name="DirectAndGeneralCosts"
							label={t('ProjectFields.DirectAndGeneralCosts')}
							error={fieldErrorsList.includes('DirectAndGeneralCosts')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.DirectAndGeneralCosts" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Warranty Repairs Reserve */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={readOnly}
							value={formData?.WarrantyRepairsReserve || ''}
							id="project-field-WarrantyRepairsReserve"
							name="WarrantyRepairsReserve"
							label={t('ProjectFields.WarrantyRepairsReserve')}
							error={fieldErrorsList.includes('WarrantyRepairsReserve')}
							onChange={onInputChange}
							onBlur={handleCalculateProjectData}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.WarrantyRepairsReserve" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* GrossMargin */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.GrossMargin || ''}
							id="project-field-GrossMargin"
							name="GrossMargin"
							label={t('ProjectFields.GrossMargin')}
							error={fieldErrorsList.includes('GrossMargin')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.GrossMargin" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Management Costs */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={readOnly}
							value={formData?.ManagementCosts || ''}
							id="project-field-ManagementCosts"
							name="ManagementCosts"
							label={t('ProjectFields.ManagementCosts')}
							error={fieldErrorsList.includes('ManagementCosts')}
							onChange={onInputChange}
							onBlur={handleCalculateProjectData}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.ManagementCosts" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Net Margin */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={readOnly}
							value={formData?.NetMargin || ''}
							id="project-field-NetMargin"
							name="NetMargin"
							label={t('ProjectFields.NetMargin')}
							error={fieldErrorsList.includes('NetMargin')}
							onChange={onInputChange}
							onBlur={handleCalculateProjectData}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.NetMargin" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Project Value */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.ProjectValue || ''}
							id="project-field-ProjectValue"
							name="ProjectValue"
							label={t('ProjectFields.ProjectValue')}
							error={fieldErrorsList.includes('ProjectValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.ProjectValue" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Box>
			<Box sx={laptopViewAndUp ? sxStyles.itemsContainerMiddle : sxStyles.itemsContainerMobile}>
				<Typography fontSize={'18px'} sx={sxStyles.title}>
					{t('FormTitles.SaleBudgetReceipts')}
				</Typography>
				<Divider />
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* BasicContractValue */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.BasicContractValue || ''}
							id="project-field-BasicContractValue"
							name="BasicContractValue"
							label={t('ProjectFields.BasicContractValue')}
							error={fieldErrorsList.includes('BasicContractValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.BasicContractValue" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Investors Annexes Value */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.InvestorsAnnexesValue || ''}
							id="project-field-InvestorsAnnexesValue"
							name="InvestorsAnnexesValue"
							label={t('ProjectFields.InvestorsAnnexesValue')}
							error={fieldErrorsList.includes('InvestorsAnnexesValue')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.InvestorsAnnexesValue" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Budget Icomes */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.BudgetIcomes || ''}
							id="project-field-BudgetIcomes"
							name="BudgetIcomes"
							label={t('ProjectFields.BudgetIcomes')}
							error={fieldErrorsList.includes('BudgetIcomes')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.BudgetIcomes" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Total Costs */}
					<Grid xs={12} sm={6} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData?.TotalSale || ''}
							id="project-field-TotalSale"
							name="TotalSale"
							label={t('ProjectFields.TotalSale')}
							error={fieldErrorsList.includes('TotalSale')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.TotalSale" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Box>
			<Box sx={laptopViewAndUp ? sxStyles.itemsContainerRight : sxStyles.itemsContainerMobile}>
				<Typography fontSize={'18px'} sx={sxStyles.title}>
					{t('FormTitles.ContractResult')}
				</Typography>
				<Divider />
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Direct Costs */}
					<Grid xs={12} sm={6} md={6} lg={6} order={{ xs: 1, md: 1 }}>
						<TextField
							disabled={true}
							value={formData?.DirectCostsExecution || ''}
							id="project-field-DirectCostsExecution"
							name="DirectCostsExecution"
							label={t('ProjectFields.DirectCostsExecution')}
							error={fieldErrorsList.includes('DirectCostsExecution')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.DirectCostsExecution" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* General Costs */}
					<Grid xs={12} sm={6} md={6} lg={6} order={{ xs: 2, md: 2 }}>
						<TextField
							disabled={true}
							value={formData?.GeneralCostsExecution || ''}
							id="project-field-GeneralCostsExecution"
							name="GeneralCostsExecution"
							label={t('ProjectFields.GeneralCostsExecution')}
							error={fieldErrorsList.includes('GeneralCostsExecution')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.GeneralCostsExecution" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Warranty Repairs Reserve Execution */}
					<Grid xs={12} sm={6} md={6} lg={6} order={{ xs: 3, md: 3 }}>
						<TextField
							disabled={readOnly}
							value={formData?.WarrantyRepairsReserveExecution || ''}
							id="project-field-WarrantyRepairsReserveExecution"
							name="WarrantyRepairsReserveExecution"
							label={t('ProjectFields.WarrantyRepairsReserveExecution')}
							error={fieldErrorsList.includes('WarrantyRepairsReserveExecution')}
							onChange={onInputChange}
							onBlur={handleCalculateProjectData}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.WarrantyRepairsReserveExecution" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Gross Margin Execution */}
					<Grid xs={12} sm={6} md={6} lg={6} order={{ xs: 5, md: 4 }}>
						<TextField
							disabled={true}
							value={formData?.GrossMarginExecution || ''}
							id="project-field-GrossMarginExecution"
							name="GrossMarginExecution"
							label={t('ProjectFields.GrossMarginExecution')}
							error={fieldErrorsList.includes('GrossMarginExecution')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.GrossMarginExecution" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Net Margin Execution */}
					<Grid xs={12} sm={6} md={6} lg={6} order={{ xs: 4, md: 5 }}>
						<TextField
							disabled={readOnly}
							value={formData?.ManagementCostsExecution || ''}
							id="project-field-ManagementCostsExecution"
							name="ManagementCostsExecution"
							label={t('ProjectFields.ManagementCostsExecution')}
							error={fieldErrorsList.includes('ManagementCostsExecution')}
							onChange={onInputChange}
							onBlur={handleCalculateProjectData}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.ManagementCostsExecution" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Net Margin Execution */}
					<Grid xs={12} sm={6} md={6} lg={6} order={{ xs: 6, md: 6 }}>
						<TextField
							disabled={true}
							value={formData?.NetMarginExecution || ''}
							id="project-field-NetMarginExecution"
							name="NetMarginExecution"
							label={t('ProjectFields.NetMarginExecution')}
							error={fieldErrorsList.includes('NetMarginExecution')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.NetMarginExecution" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Gross Margin Improve */}
					<Grid xs={12} sm={6} md={6} lg={6} order={{ xs: 7, md: 7 }}>
						<TextField
							disabled={true}
							value={formData?.GrossMarginImprove || ''}
							id="project-field-GrossMarginImprove"
							name="GrossMarginImprove"
							label={t('ProjectFields.GrossMarginImprove')}
							error={fieldErrorsList.includes('GrossMarginImprove')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.GrossMarginImprove" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
					{/* Net Margin Improve */}
					<Grid xs={12} sm={6} md={6} lg={6} order={{ xs: 8, md: 8 }}>
						<TextField
							disabled={true}
							value={formData?.NetMarginImprove || ''}
							id="project-field-NetMarginImprove"
							name="NetMarginImprove"
							label={t('ProjectFields.NetMarginImprove')}
							error={fieldErrorsList.includes('NetMarginImprove')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Project.NetMarginImprove" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default BudgetAssumption

const sxStyles = {
	container: {
		display: 'flex',
	},
	itemsContainerLeft: {
		width: '33%',
		mr: '8px',
	},
	itemsContainerMiddle: {
		width: '33%',
		margin: '0 8px',
	},
	itemsContainerRight: {
		width: '33%',
		ml: '8px',
	},
	itemsContainerMobile: {
		width: '100%',
		mb: '10px',
	},
	title: {
		padding: '5px',
	},
}
