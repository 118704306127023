import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { LoadingButton } from '@mui/lab'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, ExpandCollapseButtons, TooltipButton } from 'components/shared'
import { useAppSelector } from 'features'
import useDataGridState from 'hooks/UseDataGridState'
import { IInvestorProcessingProtocol } from 'models'
import { useInvestorProcessingProtocolEntriesService } from 'services'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	isActionButtonDisabled: boolean
}

const InvestorProcessingProtocolEntriesToolbar = ({ apiRef, isActionButtonDisabled }: Props) => {
	const [exportLoading, setExportLoading] = useState(false)
	const { documentData } = useAppSelector(state => state.documentData)

	const { Id } = documentData as IInvestorProcessingProtocol

	const { t } = useTranslation()

	const { handleSaveStateSnapshot } = useDataGridState()

	const { exportExcel } = useInvestorProcessingProtocolEntriesService()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	const handleExport = async () => {
		setExportLoading(true)
		try {
			await exportExcel(Id)
		} catch (err) {
			console.error(err)
		} finally {
			setExportLoading(false)
		}
	}

	return (
		<GridToolbarContainer className="flex justify-between items-center m-1">
			<div className="space-x-1">
				<ColumnsButton />
				<LoadingButton
					variant="text"
					color="primary"
					onClick={handleExport}
					loading={exportLoading}
					loadingPosition="start"
					size="small"
					startIcon={<FileDownloadIcon />}>
					{t('general.Export')}
				</LoadingButton>
				<ExpandCollapseButtons apiRef={apiRef} isActionButtonDisabled={isActionButtonDisabled} />
			</div>
			<div>
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.Filter"
					onClick={handleFilter}
					IconComponent={FilterListIcon}
					disabled={isActionButtonDisabled}
					fontSize="medium"
				/>
			</div>
		</GridToolbarContainer>
	)
}

export default InvestorProcessingProtocolEntriesToolbar
