import { Suspense } from 'react'

import { Box } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import { useAuth } from 'hooks'

import { AuthenticatedContent, UnauthenticatedContent } from './Content'

interface AppProps {
	setMuiTheme: (theme: string | null) => void
}

const App: React.FC<AppProps> = ({ setMuiTheme }) => {
	const { user } = useAuth()

	return (
		<Suspense fallback={<Loading fullScreen />}>
			<Box
				sx={{
					fontFamily: 'Roboto',
					fontSize: '14px',
				}}>
				{user ? <AuthenticatedContent setMuiTheme={setMuiTheme} /> : <UnauthenticatedContent />}
			</Box>
		</Suspense>
	)
}

export default App
