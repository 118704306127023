import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, ContactPersons, ContractorForm, InputHint, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IIncomingLetter, IProjectModel } from 'models'
import { INCOMING_LETTER_ENDPOINT, useProjectsListService } from 'services'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import { Notes } from './form-tabs'

interface Props {
	documentId: string
	formData: IIncomingLetter
}

const Form = ({ documentId, formData }: Props) => {
	const [listOfProjects, setListOfProjects] = useState<IProjectModel[]>([])
	const [selectedTab, setSelectedTab] = useState('0')

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('incoming-letter')

	const { fieldsError, handleInputChange, handleAutocompleteInputChange } = useFormDataChange()
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getListOfProjects } = useProjectsListService()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem(LOCAL_STORAGE_KEY, newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.Notes'),
				renderValue: <Notes formData={formData} />,
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons
						documentId={documentId}
						contractorId={formData.Contractor || ''}
						endpoint={INCOMING_LETTER_ENDPOINT}
					/>
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	const getProjectsData = async (instanceId: string) => {
		if (listOfProjects.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getListOfProjects()

				setListOfProjects(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	useEffect(() => {
		const savedTab = localStorage.getItem(LOCAL_STORAGE_KEY)
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="IncomingLetter.Number" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								name="DocumentDate"
								label={t('Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="IncomingLetter.DocumentDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="IncomingLetter.StageId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
										<InputHint translationPath="IncomingLetter.WorkflowId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						initialValue={{
							value: formData.Project || '',
							label: formData.ProjectLabel || '',
						}}
						onOpen={() => getProjectsData('Fields.Project')}
						onChange={handleAutocompleteInputChange}
						options={listOfProjects}
						optionLabelFieldName="ProjectNumber"
						readOnly={!isEditable('Project')}
						instanceId="Fields.Project"
						inputLabel={t('Fields.Project')}
						name="Project"
						labelFieldName="ProjectLabel"
						forProject
						endAdorment={
							<InputAdornment position="end">
								<InputLinkButton
									path={PATHS.project}
									documentId={formData.Project}
									documentLabel={formData.ProjectLabel}
								/>
							</InputAdornment>
						}
						hintTranslationPath="IncomingLetter.Project"
					/>
					{/* ForeignNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ForeignNumber') && (
							<TextField
								disabled={!isEditable('ForeignNumber')}
								value={formData.ForeignNumber || ''}
								name="ForeignNumber"
								label={t('Fields.ForeignNumber')}
								error={fieldsError.includes('ForeignNumber')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="IncomingLetter.ForeignNumber" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ReceiptDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ReceiptDate') && (
							<TextField
								disabled={!isEditable('ReceiptDate')}
								value={formatDateTimeMUI(formData.ReceiptDate) || ''}
								name="ReceiptDate"
								label={t('Fields.ReceiptDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('ReceiptDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="IncomingLetter.ReceiptDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* IssueDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('IssueDate') && (
							<TextField
								disabled={!isEditable('IssueDate')}
								value={formatDateTimeMUI(formData.IssueDate) || ''}
								name="IssueDate"
								label={t('Fields.IssueDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('IssueDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="IncomingLetter.IssueDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={documentId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								}
								return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						}
						return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default Form

const LOCAL_STORAGE_KEY = 'incomingLetterFormSelectedTab'
