import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IDemandEntry } from 'models'

const useDemandEntriesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/demandEntry'
	const odataPath = '/odata/demandEntry'

	const getEntries = useCallback(
		async (demandId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&DemandId=${demandId}`)
			return response
		},
		[request]
	)

	const addEntry = useCallback(
		async (data: IDemandEntry) => {
			const response = await request(`${odataPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateEntry = useCallback(
		async (entryId: string, data: any) => {
			await request(`${odataPath}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteEntry = useCallback(
		async (entryId: string) => {
			const response = await request(`${path}/${entryId}`, 'DELETE')
			return response
		},
		[request]
	)

	const linkToBudgetEntry = useCallback(
		async (entryId: string, budgetEntryId: string) => {
			await request(`/api/demandEntry/linkToBudget/${entryId}?budgetEntryId=${budgetEntryId}`, 'PATCH')
		},
		[request]
	)

	return {
		error,
		clearError,
		showSuccessInfo,
		getEntries,
		addEntry,
		updateEntry,
		deleteEntry,
		linkToBudgetEntry,
	}
}

export default useDemandEntriesService
