import { useTranslation } from 'react-i18next'

import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'

import { Loading, NoData } from 'components/shared'
import { IWorkflowDocumentHistory } from 'models'
import { formatDateTime } from 'utils/formatDateTime'

import HistoryItemIcon from './HistoryItemIcon'

interface Props {
	documentHistoryData: IWorkflowDocumentHistory[] | undefined
}

const WorkflowDocumentHistory = ({ documentHistoryData }: Props) => {
	const { t } = useTranslation()

	return (
		<div>
			<Typography
				sx={{
					fontWeight: 500,
					fontSize: '20px',
					mb: '10px',
					textAlign: 'center',
				}}>
				{t('DocumentWorkflowPage.History.Title')}
			</Typography>
			{!documentHistoryData ? (
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Loading />
				</Box>
			) : (
				<List>
					<ListItem
						sx={{
							padding: '5px 0',
							color: 'text.secondary',
						}}>
						<ListItemText
							sx={{
								textAlign: 'left',
								width: '15%',
							}}
							primaryTypographyProps={{ fontSize: '12px' }}
							primary={t('DocumentWorkflowPage.History.Date')}
						/>
						<ListItemText
							sx={{
								textAlign: 'left',
								width: '20%',
							}}
							primaryTypographyProps={{ fontSize: '12px' }}
							primary={t('DocumentWorkflowPage.History.ChangedBy')}
						/>
						<ListItemText
							sx={{
								maxWidth: '36px',
							}}
						/>
						<ListItemText
							sx={{
								textAlign: 'left',
								width: '15%',
							}}
							primaryTypographyProps={{ fontSize: '12px' }}
							primary={t('DocumentWorkflowPage.History.TypeOfChange')}
						/>
						<ListItemText
							sx={{
								textAlign: 'left',
								width: '20%',
							}}
							primaryTypographyProps={{ fontSize: '12px' }}
							primary={t('DocumentWorkflowPage.History.NewValue')}
						/>
					</ListItem>
					{documentHistoryData.length > 0 ? (
						documentHistoryData.map((el, index) => {
							return (
								<div key={index}>
									<ListItem
										sx={{
											padding: '5px 0',
										}}>
										<ListItemText
											sx={{
												textAlign: 'left',
												width: '15%',
											}}
											primaryTypographyProps={{ fontSize: '14px' }}
											primary={formatDateTime(el.DateTime || '')}
										/>
										<ListItemText
											sx={{ textAlign: 'left', width: '20%' }}
											primaryTypographyProps={{ fontSize: '14px' }}
											primary={el.UserName}
										/>
										<HistoryItemIcon typeOfChange={el.ChangeType} />
										<ListItemText
											sx={{ textAlign: 'left', width: '15%' }}
											primaryTypographyProps={{ fontSize: '14px' }}
											primary={t(`DocumentWorkflowPage.History.ChangeTypes.${el.ChangeType}`)}
										/>
										<ListItemText
											sx={{ textAlign: 'left', width: '20%' }}
											primaryTypographyProps={{ fontSize: '14px' }}
											primary={el.ValueDescription}
										/>
									</ListItem>
									<Divider />
								</div>
							)
						})
					) : (
						<NoData fontSize="18px" />
					)}
				</List>
			)}
		</div>
	)
}

export default WorkflowDocumentHistory
