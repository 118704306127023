import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'

import { TabManager } from 'helpers/tabManager'
import type { ITab } from 'models/ITab'
import { v4 as uuidv4 } from 'uuid'

import useAppRoutes, { IRoute } from './UseAppRoutes'

interface ITabContextData {
	tabs: ITab[]
	selectedTab: ITab | null
	reorderTab: (fromIndex: number, toIndex: number) => void
	closeTab: (key: string) => void
}

const TabsContext = createContext<ITabContextData>({
	selectedTab: null,
	tabs: [],
	reorderTab: () => {},
	closeTab: () => {},
})

const useTabs = () => useContext(TabsContext) as ITabContextData

interface ITabsProviderProps {
	children: JSX.Element
}

const TabsProvider = ({ children }: ITabsProviderProps) => {
	const [tabs, setTabs] = useState<ITab[]>([])
	const [selectedTab, setSelectedTab] = useState<ITab | null>(null)

	const location = useLocation()
	const navigate = useNavigate()

	const { routes } = useAppRoutes()

	const updateData = useCallback((updateTabsToo: boolean) => {
		setSelectedTab(TabManager.getSelectedTab())
		if (updateTabsToo) setTabs([...TabManager.getTabs()])
	}, [])

	const readRouteHeaderText = (matchedRoute: IRoute): string => {
		let headerText = matchedRoute.text

		if (location != null) {
			const state = location.state as any
			if (state && state.headerPostFix) headerText += state.headerPostFix
		}

		return headerText
	}

	const reorderTab = useCallback(
		(fromIndex: number, toIndex: number): void => {
			TabManager.reorderTab(fromIndex, toIndex)
			updateData(true)
		},
		[updateData]
	)

	const closeTab = useCallback(
		(key: string): void => {
			const isClosingActiveTab = key === selectedTab?.key

			TabManager.removeTab(key)
			localStorage.removeItem(`documentSelectedTab_${location.pathname}`)
			localStorage.removeItem(`documentsTabSelectedTab_${location.pathname}`)
			updateData(true)

			if (isClosingActiveTab) {
				const newTab = TabManager.getSelectedTab()
				if (newTab != null) navigate(newTab.url, { state: newTab.state })
				else navigate('/')
			}
		},

		[updateData, selectedTab, navigate]
	)

	useEffect(() => {
		const matchedRoutes = matchRoutes(routes, location)

		if (matchedRoutes == null) return

		if (matchedRoutes.length > 1) {
			console.warn(`There was several matches for location ${JSON.stringify(location)}: ${JSON.stringify(matchRoutes)}`)
		}

		const matchedRoute = matchedRoutes[0].route as IRoute

		TabManager.selectOrAddTab({
			key: uuidv4(),
			url: matchedRoutes[0].pathname,
			name: readRouteHeaderText(matchedRoute),
			state: location.state,
			index: null,
		})
		updateData(true)
	}, [location.pathname])

	return <TabsContext.Provider value={{ selectedTab, tabs, reorderTab, closeTab }}>{children}</TabsContext.Provider>
}

export { TabsProvider, useTabs }
