import { useCallback, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { IAttachmentPreviewFile } from 'models'
import { useAttachmentsService } from 'services'

interface Props {
	file: IAttachmentPreviewFile
	fileScaling: number
	selectedPage: number
}

const ImageViewer = ({ file, fileScaling, selectedPage }: Props) => {
	const [imageUrl, setImageUrl] = useState<string>('')
	const [dataLoading, setDataLoading] = useState(true)

	const { getURL } = useAttachmentsService()

	const generateImageAttachment = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getURL(file.id)

			if (response) {
				setImageUrl(response.url)
			}
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [file])

	useEffect(() => {
		generateImageAttachment()
	}, [file.id])

	if (dataLoading) {
		return <Loading />
	} else if (imageUrl === '') {
		return <NoData />
	}

	return (
		<Box sx={sxStyles.container}>
			<Box component={'div'} sx={sxStyles.imgContainer}>
				<img
					src={imageUrl}
					alt="Attachment"
					style={{
						width: `${100 * fileScaling}%`,
						height: 'auto',
					}}
				/>
			</Box>
		</Box>
	)
}

export default ImageViewer

const sxStyles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		overflow: 'auto',
	},
	imgContainer: {
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		maxWidth: '100%',
	},
}
