import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import dayjs, { Dayjs } from 'dayjs'
import { ICalendarConvertedEvent } from 'models'

type InitialState = {
	selectedDate: string
	selectedMonthIndex: number
	selectedYear: number
	gridRowsCount: number
	calendarDataRange: { dateFrom: string | Date | Dayjs; dateTo: string | Date | Dayjs }
	calendarEvents: ICalendarConvertedEvent
}

const initialState: InitialState = {
	selectedDate: dayjs().format(),
	selectedMonthIndex: dayjs().month(),
	selectedYear: dayjs().year(),
	gridRowsCount: 5,
	calendarDataRange: { dateFrom: '', dateTo: '' },
	calendarEvents: {},
}

export const calendar = createSlice({
	name: 'calendar',
	initialState,
	reducers: {
		setSelectedMonthIndex: (state, action: PayloadAction<number>) => {
			state.selectedMonthIndex = action.payload
		},
		setSelectedDate: (state, action: PayloadAction<string>) => {
			state.selectedDate = action.payload
			state.selectedMonthIndex = dayjs(action.payload).month()
			state.selectedYear = dayjs(action.payload).year()
		},
		setGridRowsCount: (state, action: PayloadAction<number>) => {
			state.gridRowsCount = action.payload
		},
		setCalendarDataRange: (
			state,
			action: PayloadAction<{ dateFrom: string | Date | Dayjs; dateTo: string | Date | Dayjs }>
		) => {
			state.calendarDataRange = { dateFrom: action.payload.dateFrom, dateTo: action.payload.dateTo }
		},
		setCalendarEvents: (state, action: PayloadAction<ICalendarConvertedEvent>) => {
			state.calendarEvents = action.payload
		},
	},
})

export const calendarActions = calendar.actions

export default calendar.reducer
