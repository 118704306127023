import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import {
	GridRowModes,
	type GridRowModesModel,
	type GridRowsProp,
	GridSelectionModel,
	GridToolbarContainer,
} from '@mui/x-data-grid-premium'

import { OrderIcon } from 'assets'
import {
	ColumnsButton,
	CreateDocumentFromEntries,
	ExpandCollapseButtons,
	SwitchTreeData,
	TooltipButton,
} from 'components/shared'
import { useAppSelector } from 'features'
import { useHandleDocumentActions } from 'hooks'
import useDataGridState from 'hooks/UseDataGridState'
import { Action } from 'models'
import { useDemandService } from 'services'
import { v4 as uuidv4 } from 'uuid'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	setIsActionButtonDisabled: (value: boolean) => void
	isActionButtonDisabled: boolean
	setEntriesData: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
	demandId: string
	readOnly: boolean
	isTreeData: boolean
	setIsTreeData: (value: boolean) => void
	selectionModel: GridSelectionModel
	getData: () => void
}

const DemandEntriesToolbar = ({
	apiRef,
	isActionButtonDisabled,
	setIsActionButtonDisabled,
	setEntriesData,
	setRowModesModel,
	isTreeData,
	setIsTreeData,
	demandId,
	readOnly,
	selectionModel,
	getData,
}: Props) => {
	const [isCreateDocumentDialogOpen, setIsCreateDocumentDialogOpen] = useState(false)

	const { actionsList } = useAppSelector(state => state.documentData)

	const { invokeAction } = useDemandService()
	const { handleInvokeAction } = useHandleDocumentActions(invokeAction, getData)
	const { handleSaveStateSnapshot } = useDataGridState()

	const action = actionsList.find(el => el.Name === 'GenerateOrderFromDemand') as Action

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleAddEntry = () => {
		const Id = uuidv4()

		setIsActionButtonDisabled(true)

		setEntriesData((oldRows: any) => [
			...oldRows,
			{
				Id,
				DemandId: demandId,
				isNew: true,
				TreePath: [''],
			},
		])

		setRowModesModel(oldModel => ({
			...oldModel,
			[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Name' },
		}))
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<>
			<GridToolbarContainer className="flex justify-between m-1">
				<div>
					<ColumnsButton />
					<ExpandCollapseButtons apiRef={apiRef} isActionButtonDisabled={isActionButtonDisabled} />
				</div>
				<div>
					<TooltipButton
						className="p-2"
						title="general.GenerateOrderFromSelectedEntries"
						onClick={() => setIsCreateDocumentDialogOpen(true)}
						IconComponent={OrderIcon}
						disabled={!selectionModel.length}
					/>
					<SwitchTreeData
						isActionButtonDisabled={isActionButtonDisabled}
						isTreeData={isTreeData}
						setIsTreeData={setIsTreeData}
					/>
					<TooltipButton
						title="general.ResetDataGridState"
						onClick={() => handleUpdateState(true)}
						IconComponent={RotateLeftIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.SaveDataGridState"
						onClick={() => handleUpdateState()}
						IconComponent={FactCheckOutlinedIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.Filter"
						onClick={handleFilter}
						IconComponent={FilterListIcon}
						disabled={isActionButtonDisabled}
						fontSize="medium"
					/>
					{!readOnly && (
						<TooltipButton
							title="general.Add"
							onClick={handleAddEntry}
							IconComponent={AddIcon}
							disabled={isActionButtonDisabled}
							fontSize="medium"
						/>
					)}
				</div>
			</GridToolbarContainer>
			{isCreateDocumentDialogOpen && (
				<CreateDocumentFromEntries
					isOpen={isCreateDocumentDialogOpen}
					setIsOpen={setIsCreateDocumentDialogOpen}
					documentToCreateType="Order"
					entriesKey="demandEntries"
					selectedEntries={selectionModel}
					invokeAction={handleInvokeAction}
					action={action}
				/>
			)}
		</>
	)
}

export default DemandEntriesToolbar
