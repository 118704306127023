import { useEffect } from 'react'

import RecommendIcon from '@mui/icons-material/Recommend'

import { TooltipButton } from 'components/shared/buttons'

type Props = {
	contextName: string
	showCompleted: boolean
	setShowCompleted: (value: boolean) => void
}

const ShowCompletedButton = ({ contextName, showCompleted, setShowCompleted }: Props) => {
	const sessionStorageName = `${contextName}_SHOW_COMPLETED`

	const cachedValue = sessionStorage.getItem(sessionStorageName)

	const sessionStorageValue = cachedValue ? JSON.parse(cachedValue) : false

	const handleShowCompletedChange = () => {
		setShowCompleted(!showCompleted)
		sessionStorage.setItem(sessionStorageName, JSON.stringify(!showCompleted))
	}

	useEffect(() => {
		setShowCompleted(sessionStorageValue)
	}, [contextName])

	return (
		<TooltipButton
			title="general.ShowCompleted"
			onClick={handleShowCompletedChange}
			IconComponent={RecommendIcon}
			color={showCompleted ? 'success' : 'disabled'}
			fontSize="medium"
		/>
	)
}

export default ShowCompletedButton
