import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList } from '@mui/lab'
import { Box, Tab } from '@mui/material'

import Stages from './Stages'
import GeneralPermissions from './general-permissions/GeneralPermissions'

interface Props {
	workflowId: string
	roleId: string
	setIsDetachDialogOpen: (value: boolean) => void
}

const RolePermissions = ({ workflowId, roleId, setIsDetachDialogOpen }: Props) => {
	const [selectedIndex, setSelectedIndex] = useState('0')

	const { t } = useTranslation(['administration', 'translation'])

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedIndex(newValue)
	}

	const tabs = useMemo(
		() => [
			{
				id: 0,
				tabName: t('Workflow.Permissions.Tabs.General'),
				icon: null,
				component: (
					<GeneralPermissions workflowId={workflowId} roleId={roleId} setIsDetachDialogOpen={setIsDetachDialogOpen} />
				),
				disabled: false,
			},
			{
				id: 1,
				tabName: t('Workflow.Permissions.Tabs.Stages'),
				icon: null,
				component: <Stages workflowId={workflowId} roleId={roleId} />,
				disabled: false,
			},
		],
		[t, workflowId, roleId]
	)

	useEffect(() => {
		setSelectedIndex('0')
	}, [workflowId, roleId])

	return (
		<Box sx={sxStyles.container}>
			<TabContext value={selectedIndex}>
				<Box sx={sxStyles.tabsContainer}>
					<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
						{tabs.map((el, index) => {
							return <Tab key={el.id} label={el.tabName} disabled={el.disabled} value={index.toString()} />
						})}
					</TabList>
				</Box>
				<Box>
					{tabs.map((el, index) => {
						return (
							<Box
								key={el.id}
								sx={sxStyles.contentContainer}
								role="tabpanel"
								hidden={selectedIndex !== index.toString()}>
								<>{selectedIndex === index.toString() && el.component}</>
							</Box>
						)
					})}
				</Box>
			</TabContext>
		</Box>
	)
}

export default RolePermissions

const sxStyles = {
	container: {
		width: '100%',
		p: '0 15px',
		pr: 0,
	},
	tabsContainer: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'background.secondary',
		borderRadius: '5px',
	},
	contentContainer: {
		mt: '10px',
	},
}
