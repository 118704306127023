import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { LoadingButton } from '@mui/lab'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	MenuItem,
	TextField,
} from '@mui/material'

import { enqueueSnackbar } from 'notistack'

type Props = {
	projectId: string
	rerender: Dispatch<SetStateAction<number>>
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	api: any
}

const ImportExcelBudget = ({ projectId, rerender, isOpen, setIsOpen, api }: Props) => {
	const [selectedValue, setSelectedValue] = useState<string | undefined>('')
	const [selectedFile, setSelectedFile] = useState<any>(null)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['project', 'translation'])

	const selectOptions = [
		{
			value: 'addNew',
			option: t('BudgetTabGeneral.ImportExcelDialog.SelectOptions.AddNew'),
		},
		{
			value: 'addAndUpdate',
			option: t('BudgetTabGeneral.ImportExcelDialog.SelectOptions.AddAndUpdate'),
		},
	]

	const handleSelectChange = (e: any) => {
		setSelectedValue(e.target.value)
	}

	const selectFile = (e: any) => {
		const file = e.target.files[0]
		const fileMaxSizeInBytes = 100 * 1024 * 1024 // 100 MB

		if (file.size > fileMaxSizeInBytes) {
			enqueueSnackbar(t('general.FileSizeError', { ns: 'translation' }), {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} else {
			setSelectedFile(file)
		}
	}

	const closeDialog = () => {
		setIsOpen(false)
		setSelectedFile(null)
		setSelectedValue('')
	}

	const handleFileSubmit = useCallback(async () => {
		const formData = new FormData()

		formData.append('file', selectedFile)

		if (selectedValue) {
			formData.append('importTypeEnum', selectedValue)
		}
		try {
			setButtonLoading(true)
			const response = await api.addFile(projectId, formData)
			if (response.status === 204) {
				closeDialog()
				rerender(prev => prev + 1)
			}
		} catch (err) {
			setSelectedFile('')
			console.error(err)
		}
		setButtonLoading(false)
	}, [projectId, selectedFile])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('BudgetTabGeneral.ImportExcelDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<FormControl fullWidth>
					<TextField
						value={selectedValue}
						select
						id="selectFileType"
						name="selectFileType"
						label={t('BudgetTabGeneral.ImportExcelDialog.AddingMode')}
						onChange={handleSelectChange}
						fullWidth
						sx={{ mt: '10px' }}>
						{selectOptions.map((el: any, index: number) => (
							<MenuItem key={index} value={el.value}>
								{el.option}
							</MenuItem>
						))}
					</TextField>
					<Box
						sx={{
							width: '100%',
							margin: '25px 0 5px 0',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<Button
							variant="contained"
							color="primary"
							component="label"
							endIcon={<CloudUploadIcon />}
							sx={{ mb: '8px' }}>
							{t('BudgetTabGeneral.ImportExcelDialog.ChooseFile')}
							<input type="file" accept=".xlsx" name="file" hidden onChange={selectFile} />
						</Button>
						{selectedFile && <span>{selectedFile.name}</span>}
					</Box>
				</FormControl>
			</DialogContent>
			<DialogActions sx={{ padding: '8px 26px 16px 8px' }}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.Cancel', { ns: 'translation' })}
				</Button>
				<LoadingButton
					variant="contained"
					onClick={handleFileSubmit}
					color="success"
					disabled={selectedFile && selectedValue ? false : true}
					loading={buttonLoading}>
					{t('general.Add', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default ImportExcelBudget
