import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const usePrintTemplatesService = () => {
	const { request, uploadFileRequest, downloadFileRequest, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/printTemplate'

	const getPrintTemplates = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const getPrintTemplatesForDocument = useCallback(
		async (documentType: string, documentId: string) => {
			const response = await request(`/api/${documentType}/printTemplates/${documentId}`)
			return response
		},
		[request]
	)

	const addTemplate = useCallback(
		async (
			name: string,
			type:
				| 'Order'
				| 'DebitNote'
				| 'ProcessingProtocol'
				| 'InvestorProcessingProtocol'
				| 'ContractSalesInvoice'
				| 'SalesInvoice'
				| 'ProcessingProtocolFinal'
				| 'Contract'
		) => {
			const response = await request(`${path}?name=${name}&type=${type}`, 'POST')
			return response
		},
		[request]
	)

	const updateTemplate = useCallback(
		async (templateId: string, newName: string) => {
			await request(`${path}?templateId=${templateId}&newName=${newName}`, 'PATCH')
		},
		[request]
	)

	const deleteTemplate = useCallback(
		async (templateId: string) => {
			await request(`${path}?templateId=${templateId}`, 'DELETE')
		},
		[request]
	)

	const addFile = useCallback(
		async (formData: any) => {
			const response = await uploadFileRequest(`${path}/upload`, 'PATCH', formData)
			return response
		},
		[uploadFileRequest]
	)

	const downloadFile = useCallback(
		async (templateId: string, name: string) => {
			const response = await downloadFileRequest(
				`${path}/download/?templateId=${templateId}`,
				'GET',
				{
					'Content-Type': 'application/octet-stream',
				},
				name
			)
			return response
		},
		[downloadFileRequest]
	)

	const getPrintTemplateMarkups = useCallback(
		async (type: string) => {
			const response = await request(`/api/markerMap/printTemplateMap?type=${type}`)
			return response
		},
		[request]
	)

	return {
		showSuccessInfo,
		fieldsError,
		clearError,
		error,
		getPrintTemplates,
		addFile,
		addTemplate,
		updateTemplate,
		deleteTemplate,
		downloadFile,
		getPrintTemplateMarkups,
		getPrintTemplatesForDocument,
	}
}

export default usePrintTemplatesService
