import { Box } from '@mui/material'

import NoData from 'components/shared/no-data-message/NoData'
import { ITask } from 'models'

import SynchronizationTasks from '../SynchronizationTasks'

interface Props {
	tasks: ITask[]
}

const Tasks = ({ tasks }: Props) => {
	return (
		<Box sx={sxStyles.container}>
			{tasks.length > 0 ? <SynchronizationTasks tasks={tasks} sessionTab /> : <NoData fontSize="14px" />}
		</Box>
	)
}

export default Tasks

const sxStyles = {
	container: {
		width: '90%',
		backgroundColor: 'background.secondary',
		borderRadius: '5px',
	},
}
