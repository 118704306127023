import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { ICustomCostInvoice, IInvokeAction } from 'models'
import { CUSTOM_COST_INVOICE_ENDPOINT } from 'services/data'

export interface getCustomCostInvoiceListProps {
	isCanceled: boolean
	isCompleted: boolean
	incomingLetterId?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useCustomCostInvoiceService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = CUSTOM_COST_INVOICE_ENDPOINT

	const getFilteredCustomCostInvoices = useCallback(
		async ({ isCanceled, isCompleted, incomingLetterId, budgetEntryId, data }: getCustomCostInvoiceListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (incomingLetterId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getCustomCostInvoices = useCallback(
		async ({ isCanceled, isCompleted, incomingLetterId }: getCustomCostInvoiceListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (incomingLetterId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const createCustomCostInvoice = useCallback(
		async (workflowId: string, projectId: string) => {
			const response = await request(`${path}?workflowId=${workflowId}&projectId=${projectId}`, 'POST')
			return response
		},
		[request]
	)

	const getCustomCostInvoice = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updateCustomCostInvoice = useCallback(
		async (id: string, data: ICustomCostInvoice) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getCustomCostInvoices,
		createCustomCostInvoice,
		getCustomCostInvoice,
		updateCustomCostInvoice,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredCustomCostInvoices,
	}
}

export default useCustomCostInvoiceService
