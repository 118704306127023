import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import clsx from 'clsx'
import { useAppSelector } from 'features'
import { IPageTab, InvokeAction, InvokeActionResponse } from 'models'

import { WorkflowWidget } from '../document-workflow-widget'
import { DocumentSkeletonLoading } from '../loading'
import { NoAccess } from '../no-access-message'
import { DocumentActions } from './document-actions'

interface Props {
	tabs: IPageTab[]
	actionsNode?: React.ReactNode
	invokeActionFunction?: (data: InvokeAction) => Promise<InvokeActionResponse>
	getDataFunction?: () => void
	document?: boolean
	errorStatus?: number
}

const PageNavigation = ({ tabs, actionsNode, invokeActionFunction, getDataFunction, document, errorStatus }: Props) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(0)

	const { isWorkflowPanelOpen, formLoading, documentData } = useAppSelector(state => state.documentData)

	const location = useLocation()

	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('md'))

	const handleTabChange = (event: React.SyntheticEvent | null, newValue: number) => {
		setSelectedIndex(newValue)
		localStorage.setItem(`documentSelectedTab_${location.pathname}`, newValue.toString())
	}

	useEffect(() => {
		if (location.state?.tab) {
			handleTabChange(null, location.state.tab)
		} else {
			const selectedTab = localStorage.getItem(`documentSelectedTab_${location.pathname}`)
			setSelectedIndex(selectedTab ? parseInt(selectedTab) : 0)
		}
	}, [location.pathname])

	if (document && formLoading) {
		return <DocumentSkeletonLoading />
	}

	if (errorStatus === 403) {
		return <NoAccess />
	}

	return (
		<>
			<Box sx={sxStyles.container}>
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					value={selectedIndex}
					onChange={handleTabChange}
					sx={sxStyles.tabsContainer}>
					{tabs.map((el: any) => {
						return (
							<Tab
								key={el.id}
								icon={el.icon}
								iconPosition="start"
								sx={sxStyles.tabButtonStyles}
								label={el.tabName}
								disabled={el.disabled}
								id={`page-tab-${el.id}`}
							/>
						)
					})}
				</Tabs>
				{actionsNode ? (
					<Box sx={sxStyles.actionsContainer}>{actionsNode}</Box>
				) : (
					<DocumentActions
						invokeActionFunction={invokeActionFunction as (data: InvokeAction) => Promise<InvokeActionResponse>}
						getDataFunction={getDataFunction as () => void}
					/>
				)}
			</Box>
			<Box className="flex" sx={laptopViewAndUp ? sxStyles.contentContainer : sxStyles.contentContainerTablet}>
				{documentData && document && (
					<WorkflowWidget workflowId={documentData.WorkflowId} activeStage={documentData.StageId} />
				)}
				<div
					className={clsx({
						'w-full': !documentData || !document,
						'w-[calc(100%-48px)]': documentData && document,
						'w-[calc(100%-240px)]': documentData && document && isWorkflowPanelOpen,
					})}>
					{tabs[selectedIndex].component}
				</div>
			</Box>
		</>
	)
}

export default PageNavigation

const sxStyles = {
	container: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
	},
	tabsContainer: {
		width: '95%',
		'& .MuiTabs-indicator': {
			display: 'none',
		},
		'& .Mui-selected': {
			border: '3px solid',
		},
	},
	contentContainer: {
		height: '100%',
		minHeight: 'calc(100vh - 245px)',
		margin: '10px 0',
		border: '1px solid',
		borderColor: 'border.primary',
		borderRadius: '5px',
	},
	contentContainerTablet: {
		height: '100%',
		minHeight: 'calc(100vh - 235px)',
		margin: '5px 0',
	},
	actionsContainer: {
		minWidth: '50px',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	tabButtonStyles: {
		backgroundColor: 'action.hover',
		borderRadius: '15px',
		margin: '5px 15px 5px 0',
		padding: '9px 18px',
		minHeight: '42px',
		'& .Mui-selected': {
			border: '3px solid',
		},
		'&:hover': {
			backgroundColor: 'action.selected',
		},
	},
}
