import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useCalendarService = () => {
	const { request, showSuccessInfo } = useHttp()

	const path = '/api/calendar'

	const getCalendarData = useCallback(
		async (from: Date | string, to: Date | string, includeCanceled: boolean = false, includeEnded: boolean = false) => {
			const response = await request(
				`${path}?from=${from}&to=${to}&includeCanceled=${includeCanceled}&includeEnded=${includeEnded}`
			)
			return response.CalendarEntries
		},
		[request]
	)

	return { getCalendarData, showSuccessInfo }
}

export default useCalendarService
