import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IAddress } from 'models'

const useAddressService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/address'
	const odataPath = '/odata/address'

	const getAddresses = useCallback(
		async (projectId?: string) => {
			const response = await request(projectId ? `${path}?filter=ProjectId eq ${projectId}` : `${path}`)
			return response
		},
		[request]
	)

	const getAddress = useCallback(
		async (adressId: string) => {
			const response = await request(`${path}/${adressId}`)
			return response
		},
		[request]
	)

	const addAddress = useCallback(
		async (data: IAddress) => {
			const response = await request(`${odataPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateAddress = useCallback(
		async (addressId: string, data: IAddress) => {
			await request(`${odataPath}/${addressId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteAddress = useCallback(
		async (addressId: string) => {
			await request(`${path}/${addressId}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getAddresses,
		getAddress,
		addAddress,
		updateAddress,
		deleteAddress,
	}
}

export default useAddressService
