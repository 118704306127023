import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, Stack } from '@mui/material'

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton'
import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { usePrintTemplatesService } from 'services'

type Props = {
	documentType: string
	setIsOpen: (value: boolean) => void
	isOpen: boolean
}

const MarkupsDialog = ({ documentType, setIsOpen, isOpen }: Props) => {
	const [markups, setMarkups] = useState<any>()
	const [loading, setLoading] = useState(true)

	const { t } = useTranslation(['administration', 'translation'])

	const { getPrintTemplateMarkups } = usePrintTemplatesService()

	const handleCloseDialog = () => {
		setIsOpen(false)
	}

	const getMarkups = useCallback(async () => {
		try {
			const response = await getPrintTemplateMarkups(documentType)

			setMarkups(response)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}, [documentType, getPrintTemplateMarkups])

	useEffect(() => {
		getMarkups()
	}, [documentType])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{`${t('PrintTemplates.MarkupsDialog.Title')} - ${t(`PrintTemplates.Fields.TypeEnums.${documentType}`, {
					defaultValue: documentType,
				})}`}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{loading ? (
					<Loading />
				) : markups ? (
					<List>
						{markups.Fields.map((el: any) => {
							return (
								<ListItem
									key={el.Key}
									sx={{
										p: '5px 0',
									}}>
									<Stack
										sx={{
											p: '0 3px',
											color: 'primary.main',
										}}>
										<CopyToClipboardButton text={el.Key} tooltipPlacement="left" />
									</Stack>
									-
									<Stack
										sx={{
											p: '0 3px',
										}}>
										{t(`PrintTemplates.MarkupsDialog.Descriptions.${el.Key}`, {
											defaultValue: `${el.Description}`,
										})}
									</Stack>
								</ListItem>
							)
						})}
						<List>
							{markups.Tables.length > 0
								? markups.Tables.map((tableItem: any) => {
										return (
											<>
												<ListItem
													key={tableItem.Name}
													sx={{
														p: '5px 0',
													}}>
													<Stack
														sx={{
															p: '0 3px',
															color: 'primary.main',
														}}>
														<CopyToClipboardButton text={tableItem.Name} tooltipPlacement="left" />
													</Stack>
													-
													<Stack
														sx={{
															p: '0 3px',
														}}>
														{t(`PrintTemplates.MarkupsDialog.Descriptions.${tableItem.Name}`, {
															defaultValue: `${tableItem.Description}`,
														})}
													</Stack>
												</ListItem>
												{tableItem.Fields.map((el: any) => {
													return (
														<ListItem
															key={el.Key}
															sx={{
																p: '5px 0',
																pl: '15px',
															}}>
															<Stack
																sx={{
																	p: '0 3px',
																	color: 'primary.main',
																}}>
																<CopyToClipboardButton text={el.Key} tooltipPlacement="left" />
															</Stack>
															-
															<Stack
																sx={{
																	p: '0 3px',
																}}>
																{t(`PrintTemplates.MarkupsDialog.Descriptions.${el.Key}`, {
																	defaultValue: `${el.Description}`,
																})}
															</Stack>
														</ListItem>
													)
												})}
											</>
										)
									})
								: null}
						</List>
					</List>
				) : (
					<NoData />
				)}
			</DialogContent>
		</Dialog>
	)
}

export default MarkupsDialog
