import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import Loading from 'components/shared/loading/Loading'
import { IAttachmentPreviewFile } from 'models'
import { useAttachmentsService } from 'services'

import PageSwitcher from './PageSwitcher'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const options = {
	cMapUrl: 'cmaps/',
	standardFontDataUrl: 'standard_fonts/',
}

interface Props {
	file: IAttachmentPreviewFile
	selectedPage: number
	fileScaling: number
	setSelectedPage: Dispatch<SetStateAction<number>>
}

const PdfViewer = ({ file, selectedPage, fileScaling, setSelectedPage }: Props) => {
	const [pdfString, setPdfString] = useState<string | ArrayBuffer | null>()
	const [numPages, setNumPages] = useState<number>(0)
	const [loading, setLoading] = useState(true)

	const { getURL, getWordExcelPreviewPdf } = useAttachmentsService()

	const generatePdfAttachment = useCallback(async () => {
		try {
			setLoading(true)
			let response
			if (file.fileExtension === 'docx') {
				response = await getWordExcelPreviewPdf(file.id)
			} else {
				response = await getURL(file.id)
			}

			if (response) {
				let reader = new FileReader()
				reader.readAsDataURL(response.blob)
				reader.onloadend = () => {
					setPdfString(reader.result)
				}
			}
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}, [file])

	useEffect(() => {
		generatePdfAttachment()
	}, [file.id])

	if (loading) {
		return <Loading />
	} else if (!pdfString) {
		return null
	}

	return (
		<>
			<Document
				file={pdfString}
				onLoadSuccess={({ numPages }) => {
					setNumPages(numPages)
				}}
				loading={<Loading />}
				options={options}>
				<Page pageNumber={selectedPage} renderAnnotationLayer={false} renderTextLayer={false} scale={fileScaling} />
			</Document>
			<PageSwitcher file={file} selectedPage={selectedPage} numPages={numPages} setSelectedPage={setSelectedPage} />
		</>
	)
}

export default PdfViewer
