import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IBalancingDocumentEntry } from 'models'

const useBalancingDocumentEntriesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/balancingDocumentEntry'

	const getEntries = useCallback(
		async (documentId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&documentId=${documentId}`)
			return response
		},
		[request]
	)

	const addEntry = useCallback(
		async (documentId: string, budgetEntryId: string) => {
			const response = await request(`${path}?documentId=${documentId}&budgetEntryId=${budgetEntryId}`, 'POST')
			return response
		},
		[request]
	)

	const updateEntry = useCallback(
		async (entryId: string, data: IBalancingDocumentEntry) => {
			await request(`${path}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteEntry = useCallback(
		async (entryId: string) => {
			const response = await request(`${path}/${entryId}`, 'DELETE')
			return response
		},
		[request]
	)

	return { error, clearError, showSuccessInfo, getEntries, addEntry, updateEntry, deleteEntry }
}

export default useBalancingDocumentEntriesService
