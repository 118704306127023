import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'Status',
			'Number',
			'DocumentDate',
			'ReceiptDate',
			'IssueDate',
			'SaleDate',
			'PaymentDate',
			'ValueNetto',
			'ValueBrutto',
			'ValueBruttoToPay',
			'ValueBruttoPLN',
			'ValueVAT',
			'PaidValue',
			'ForeignNumber',
			'Currency',
			'Contractor',
			'StageId',
			'WorkflowIdLabel',
			'ProcessingProtocolLabel',
			'ProjectLabel',
			'OwnerIdLabel',
			'CreatorId',
			'MasterNumber',
			'IsCompleted',
			'IsCanceled',
			'actions',
		],
		dimensions: {
			Status: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
				flex: 0,
			},
			Number: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
				flex: 0,
			},
			DocumentDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
				flex: 0,
			},
			ReceiptDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			IssueDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			SaleDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			PaymentDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			ValueNetto: {
				maxWidth: -1,
				minWidth: 50,
				width: 230,
			},
			ValueBrutto: {
				maxWidth: -1,
				minWidth: 50,
				width: 230,
			},
			ValueBruttoToPay: {
				maxWidth: -1,
				minWidth: 50,
				width: 230,
			},
			ValueBruttoPLN: {
				maxWidth: -1,
				minWidth: 50,
				width: 230,
			},
			ValueVAT: {
				maxWidth: -1,
				minWidth: 50,
				width: 230,
			},
			PaidValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 230,
			},
			ForeignNumber: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			Currency: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			Contractor: {
				maxWidth: -1,
				minWidth: 50,
				width: 350,
			},
			StageId: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			WorkflowIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			ProcessingProtocolLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			ProjectLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			OwnerIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			CreatorId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MasterNumber: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
				flex: 0,
			},
			IsCompleted: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			IsCanceled: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
				flex: 0,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
	aggregation: {
		model: {
			ValueNetto: 'sum',
			ValueBrutto: 'sum',
			ValueBruttoToPay: 'sum',
			ValueBruttoPLN: 'sum',
			ValueVAT: 'sum',
			PaidValue: 'sum',
		},
	},
}

export const CONTEXT_STATE_NAME = 'costInvoicesDataGridState'
