import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IContract, IInvokeAction } from 'models'

export interface getContractsListProps {
	isCanceled: boolean
	isCompleted: boolean
	demandId?: string
	offerId?: string
	projectId?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useContractService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = '/api/contract'

	const getFilteredContracts = useCallback(
		async ({ isCanceled, isCompleted, demandId, offerId, projectId, budgetEntryId, data }: getContractsListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (demandId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&demandId=${demandId}`
			}

			if (offerId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&offerId=${offerId}`
			}

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getContracts = useCallback(
		async ({ isCanceled, isCompleted, demandId, offerId, projectId }: getContractsListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (demandId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&demandId=${demandId}`
			}

			if (offerId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&offerId=${offerId}`
			}

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getContract = useCallback(
		async (contractId: string) => {
			const response = await request(`${path}/${contractId}`)
			return response
		},
		[request]
	)

	const updateContract = useCallback(
		async (contractId: string, data: IContract) => {
			await request(`${path}/${contractId}`, 'PATCH', data)
		},
		[request]
	)

	const getVisibleProperties = useCallback(
		async (contractId: string) => {
			const response = await request(`${path}/allowedProperties/read/${contractId}`)
			return response
		},
		[request]
	)

	const getEditableProperties = useCallback(
		async (contractId: string) => {
			const response = await request(`${path}/allowedProperties/write/${contractId}`)
			return response
		},
		[request]
	)

	const getActions = useCallback(
		async (contractId: string) => {
			const response = await request(`${path}/actions/${contractId}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getContracts,
		getContract,
		updateContract,
		getVisibleProperties,
		getEditableProperties,
		getActions,
		invokeAction,
		getFilteredContracts,
	}
}

export default useContractService
