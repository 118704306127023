import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemText,
} from '@mui/material'

import { IStage } from 'models'
import useWorkflowService, { ReorderedStages } from 'services/administration/workflow/WorkflowService'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	stages: IStage[]
	updateStages: (selectnewlyAddedStage?: boolean, ignoreIndexChange?: boolean) => void
	workflowId: string
}

const ReorderStagesDialog = ({ isOpen, setIsOpen, stages, updateStages, workflowId }: Props) => {
	const [buttonLoading, setButtonLoading] = useState(false)
	const [stagesList, setStagesList] = useState<IStage[]>([])

	const { t } = useTranslation(['administration', 'translation'])

	const { reorderStages, showSuccessInfo } = useWorkflowService()

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleReorderStage = (stageId: string, direction: 'up' | 'down') => {
		const stageToReorder = stagesList.find(item => item.Id === stageId)

		if (stageToReorder) {
			const currentIndex = stageToReorder.Index
			const targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1

			const updatedStages = stagesList.map(stage => {
				if (stage.Index === currentIndex) {
					return { ...stage, Index: targetIndex }
				} else if (stage.Index === targetIndex) {
					return { ...stage, Index: currentIndex }
				} else {
					return stage
				}
			})

			setStagesList(
				updatedStages.sort((a: IStage, b: IStage) => (a.Index === 0 ? -1 : b.Index === 0 ? 1 : a.Index - b.Index))
			)
		}
	}

	const handleUpdateStages = async () => {
		const reorderedStages: ReorderedStages[] = stagesList
			.filter(stage => stage.Index !== 0 && stage.Index !== stagesList.length - 1)
			.map(el => ({ stageId: el.Id, index: el.Index }))

		try {
			setButtonLoading(true)
			await reorderStages(workflowId, reorderedStages)

			showSuccessInfo('saved')
			updateStages()
			closeDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	useEffect(() => {
		setStagesList(stages)
	}, [stages])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Workflow.Permissions.ReorderStagesDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<List>
					{stagesList.map((el: IStage, index: number) => {
						const reorderBtnDisabled = el.Index === 0 || el.Index === stagesList.length - 1

						return (
							<ListItem
								key={index}
								sx={{
									padding: '5px 0',
									color: el.Index === 0 || el.Index === stagesList.length - 1 ? 'text.secondary' : 'inherit',
								}}
								secondaryAction={
									<>
										<IconButton
											disabled={reorderBtnDisabled || el.Index === stagesList.length - 2}
											sx={{ padding: '6px', m: '0 3px' }}
											onClick={() => handleReorderStage(el.Id, 'down')}
											edge="end">
											<ExpandMoreIcon
												color={reorderBtnDisabled || el.Index === stagesList.length - 2 ? 'disabled' : 'primary'}
												fontSize="medium"
											/>
										</IconButton>
										<IconButton
											disabled={reorderBtnDisabled || el.Index === 1}
											sx={{ padding: '6px', m: '0 3px' }}
											onClick={() => handleReorderStage(el.Id, 'up')}
											edge="end">
											<ExpandLessIcon
												color={reorderBtnDisabled || el.Index === 1 ? 'disabled' : 'primary'}
												fontSize="medium"
											/>
										</IconButton>
									</>
								}>
								<ListItemText
									sx={{
										textAlign: 'left',
									}}
									primary={`${el.Index + 1}. ${el.Name}`}
								/>
							</ListItem>
						)
					})}
				</List>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={false}
					loading={buttonLoading}
					onClick={handleUpdateStages}>
					{t('general.Save', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default ReorderStagesDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
