import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, Typography } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import { ISessionLog, ITask } from 'models'
import { useConnectorService } from 'services'

import SessionLogs from './SessionLogs'
import Tasks from './Tasks'

interface Props {
	sessionId: string
}

const SessionDetails = ({ sessionId }: Props) => {
	const [logs, setLogs] = useState<ISessionLog[]>([])
	const [tasks, setTasks] = useState<ITask[]>([])
	const [dataLoading, setDataLoading] = useState(true)

	const { t } = useTranslation(['synchronization', 'translation'])

	const { getSessionLogs, getSessionTasks } = useConnectorService()

	const getLogs = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getSessionLogs(sessionId)

			setLogs(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getSessionLogs, sessionId])

	const getTasks = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getSessionTasks(sessionId)

			setTasks(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getSessionTasks, sessionId])

	useEffect(() => {
		getLogs()
		getTasks()
	}, [sessionId])

	if (dataLoading) {
		return <Loading />
	}

	return (
		<>
			<Divider sx={{ m: '6px 5px' }} />
			<Box sx={sxStyles.container}>
				<Box sx={sxStyles.logsContainer}>
					<Typography sx={sxStyles.title} component={'div'}>
						{t('SessionLogFields.Logs')}
					</Typography>
					<SessionLogs logs={logs} />
				</Box>
				<Divider sx={{ m: '6px 5px' }} />
				<Box sx={sxStyles.tasksContainer}>
					<Typography sx={sxStyles.title} component={'div'}>
						{t('SessionLogFields.Tasks')}
					</Typography>
					<Tasks tasks={tasks} />
				</Box>
			</Box>
		</>
	)
}

export default SessionDetails

const sxStyles = {
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		width: '10%',
		p: '15px 0 0 10px',
		color: 'primary.light',
	},
	logsContainer: {
		display: 'flex',
		p: '10px 5px',
	},
	tasksContainer: {
		display: 'flex',
		p: '10px 5px',
	},
}
