import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useChangeHistoryService = () => {
	const { request, error, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = '/api/changesHistory'

	const getHistory = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	return { error, clearError, fieldsError, showSuccessInfo, getHistory }
}

export default useChangeHistoryService
