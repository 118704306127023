import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	TextField,
} from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-premium'

import { useDocumentActionsStateActions } from 'features'
import { Action } from 'models'
import { useWorkflowService } from 'services'

import { styles } from './styles'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	documentToCreateType: 'Order' | 'GoodsReceivedNote' | 'Contract'
	entriesKey: 'offerEntries' | 'demandEntries' | 'orderEntries'
	selectedEntries: GridSelectionModel
	action: Action
	invokeAction: (value: Action, parameters?: any) => void
	warning?: string | undefined | null
}

const CreateDocumentFromEntries = ({
	isOpen,
	setIsOpen,
	documentToCreateType,
	entriesKey,
	selectedEntries,
	action,
	invokeAction,
	warning,
}: Props) => {
	const [workflowId, setWorkflowId] = useState<string>('')
	const [workflow, setWorkflow] = useState([])
	const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false)

	const { t } = useTranslation()

	const { getWorkflowLookupList } = useWorkflowService()
	const { setSelectedDocumentType } = useDocumentActionsStateActions()

	const getWorkflowKey = () => {
		switch (documentToCreateType) {
			case 'Contract':
				return 'contractWorkflowId'
			case 'Order':
				return 'orderWorkflowId'
			case 'GoodsReceivedNote':
				return 'grnWorkflowId'
			default:
				return 'orderWorkflowId'
		}
	}

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleWorkflowChange = (value: string) => {
		setWorkflowId(value)
		setSaveButtonEnabled(true)
	}

	const getWorkflowData = async () => {
		try {
			const response = await getWorkflowLookupList(documentToCreateType)

			setWorkflow(response)
		} catch (err) {
			console.error(err)
		}
	}

	const handleCreateDocument = async () => {
		const workflowKey = getWorkflowKey()

		const entriesString = selectedEntries.join(';')

		let parameters = {
			[workflowKey]: workflowId,
			[entriesKey]: entriesString,
		}

		invokeAction(action, parameters)
		closeDialog()
	}

	useEffect(() => {
		getWorkflowData()
		setSelectedDocumentType(documentToCreateType)
	}, [])

	return (
		<Dialog open={isOpen} fullWidth>
			<Box sx={{ border: warning ? '3px solid' : 'none', borderColor: 'warning.main' }}>
				<DialogTitle sx={styles.title} component="div">
					{t('CreateDocumentDialog.Title')}
					<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<TextField
						value={workflowId}
						select
						name="Workflow"
						label={t('CreateDocumentDialog.WorkflowSelectLabel')}
						onChange={e => handleWorkflowChange(e.target.value)}
						fullWidth
						sx={{ mt: '10px' }}>
						{workflow.map((el: any, index: number) => (
							<MenuItem key={el.label} value={el.value}>
								{el.label}
							</MenuItem>
						))}
					</TextField>
					{warning && (
						<Box sx={styles.warningMessage}>
							<WarningIcon color="warning" sx={{ pr: '5px' }} />
							{warning}
						</Box>
					)}
				</DialogContent>
				<DialogActions sx={styles.actionsContainer}>
					<Button variant="contained" color="success" disabled={!isSaveButtonEnabled} onClick={handleCreateDocument}>
						{t('general.Create')}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	)
}

export default CreateDocumentFromEntries
