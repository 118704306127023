import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IInvestorProcessingProtocol, IInvokeAction } from 'models'

import { INVESTOR_PP_ENDPOINT } from './../data/endpoints'

export interface getInvestorProtocolsListProps {
	isCanceled: boolean
	isCompleted: boolean
	projectId?: string
	data?: GridInitialState
}

const useInvestorProcessingProtocolService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = INVESTOR_PP_ENDPOINT

	const getFilteredInvestorProtocols = useCallback(
		async ({ isCanceled, isCompleted, projectId, data }: getInvestorProtocolsListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getInvestorProtocols = useCallback(
		async ({ isCanceled, isCompleted, projectId }: getInvestorProtocolsListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getInvestorProtocol = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}/${protocolId}`)
			return response
		},
		[request]
	)

	const updateInvestorProtocol = useCallback(
		async (protocolId: string, data: IInvestorProcessingProtocol) => {
			await request(`${path}/${protocolId}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}/actions/${protocolId}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}/allowedProperties/read/${protocolId}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}/allowedProperties/write/${protocolId}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getInvestorProtocols,
		getInvestorProtocol,
		updateInvestorProtocol,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredInvestorProtocols,
	}
}

export default useInvestorProcessingProtocolService
