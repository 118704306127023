import { Route, Routes } from 'react-router-dom'

import { BasicLayout, MainLayout } from 'components/layouts'
import { LoginForm } from 'components/pages'
import ChangePasswordPage from 'components/pages/change-password-form/ChangePasswordPage'
import QuotationRequestOfferDraft from 'components/pages/demand/inquiries/offer-draft/QuotationRequestOfferDraft'
import { Redirect } from 'components/shared'
import Loading from 'components/shared/loading/Loading'
import { PermissionsProvider, TabsProvider, useAuth } from 'hooks'
import useAppRoutes from 'hooks/UseAppRoutes'

import useRoutePermission from '../../hooks/UseRoutePermission'

const AuthenticatedContent = ({ setMuiTheme }: any) => {
	const { user } = useAuth()
	const { allowedRootingsList, checkRoutePermission } = useRoutePermission()
	const { routes, defaultRoute } = useAppRoutes()

	if (user?.ChangePasswordAtNextLogon) {
		return (
			<Routes>
				<Route path="/changePassword" element={<BasicLayout />}>
					<Route path="" element={<ChangePasswordPage />} />
				</Route>
				<Route path="*" element={<Redirect path="/changePassword" />} />
			</Routes>
		)
	} else if (!allowedRootingsList.length) {
		return <Loading fullScreen />
	}

	return (
		<PermissionsProvider>
			<TabsProvider>
				<MainLayout setMuiTheme={setMuiTheme}>
					<Routes>
						{routes.map(({ path, Component }) => (
							<Route key={path} path={path} element={checkRoutePermission(path, Component)} />
						))}
						<Route path="*" element={<Redirect path={defaultRoute} />} />
					</Routes>
				</MainLayout>
			</TabsProvider>
		</PermissionsProvider>
	)
}

const UnauthenticatedContent = () => {
	return (
		<Routes>
			<Route path="/login" element={<BasicLayout />}>
				<Route path="" element={<LoginForm />} />
			</Route>
			<Route path="/quotationRequestOfferDraft/:id" element={<QuotationRequestOfferDraft unauthenticated />} />
			<Route path="*" element={<Redirect path="/login" />} />
		</Routes>
	)
}

export { AuthenticatedContent, UnauthenticatedContent }
