import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { FilterPanel, SettingsMenu, TooltipButton } from 'components/shared'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	getFilteredData: (reset?: boolean) => void
	apiRef: { current: any }
	handleFilter: () => void
}

const ProjectsEditorToolbar = ({ getFilteredData, apiRef, handleFilter }: Props) => {
	const navigate = useNavigate()

	const handleAddDemand = () => {
		navigate('/project/draft')
	}

	const handleUpdateList = () => {
		getFilteredData()
	}

	return (
		<GridToolbarContainer className="flex justify-between m-1.5">
			<FilterPanel
				handleFilter={handleFilter}
				apiRef={apiRef}
				getFilteredData={getFilteredData}
				contextName={CONTEXT_STATE_NAME}
				disableToggleButtons
			/>
			<div>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				<TooltipButton title="general.Add" onClick={handleAddDemand} IconComponent={AddIcon} fontSize="medium" />
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
			</div>
		</GridToolbarContainer>
	)
}

export default ProjectsEditorToolbar
