import { useCallback, useEffect, useState } from 'react'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import { IChangeHistory } from 'models'
import { useChangeHistoryService } from 'services'

import Loading from '../loading/Loading'
import ChangeHistoryList from './ChangeHistoryList'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	contextId: string
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	title: string
	translationFile: string
	translationPath: string
}

const ChangeHistoryDialog = ({ contextId, isOpen, setIsOpen, title, translationFile, translationPath }: Props) => {
	const [data, setData] = useState<IChangeHistory[]>([])
	const [loading, setLoading] = useState(true)

	const { getHistory } = useChangeHistoryService()

	const { t } = useTranslation()

	const handleCloseDialog = () => {
		setIsOpen(false)
	}

	const prepareTitle = (str: string) => {
		const maxLength = 50

		if (str.length > maxLength) {
			return str.slice(0, maxLength - 3) + '...'
		}
		return str
	}

	const getData = useCallback(async () => {
		try {
			const response = await getHistory(contextId)
			setData(response)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}, [contextId, getHistory])

	useEffect(() => {
		getData()
	}, [contextId])

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1, fontSize: '20px', fontWeight: '500' }} component="div">
						{`${prepareTitle(title)} - ${t('ChangeHistory.ChangeHistory')}`}
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '90px',
				}}>
				{loading ? (
					<Loading />
				) : (
					<ChangeHistoryList data={data} translationFile={translationFile} translationPath={translationPath} />
				)}
			</DialogContent>
		</Dialog>
	)
}

export default ChangeHistoryDialog
