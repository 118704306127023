import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IContractEntry } from 'models'

const useContractEntriesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const getContractEntries = useCallback(
		async (contractId: string, treeData: boolean) => {
			const response = await request(`/api/contractEntry?getTree=${treeData}&contractId=${contractId}`)
			return response
		},
		[request]
	)

	const addContractEntry = useCallback(
		async (data: IContractEntry) => {
			const response = await request(`/api/contractEntry`, 'POST', data)
			return response
		},
		[request]
	)

	const updateContractEntry = useCallback(
		async (entryId: string, body: IContractEntry) => {
			await request(`/api/contractEntry/${entryId}`, 'PATCH', body)
		},
		[request]
	)

	const deleteContractEntry = useCallback(
		async (entryId: string) => {
			const response = await request(`/api/contractEntry/${entryId}`, 'DELETE')
			return response
		},
		[request]
	)

	const linkToBudgetEntry = useCallback(
		async (entryId: string, budgetEntryId: string) => {
			await request(`/api/contractEntry/linkToBudget/${entryId}?budgetEntryId=${budgetEntryId}`, 'PATCH')
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getContractEntries,
		addContractEntry,
		updateContractEntry,
		deleteContractEntry,
		linkToBudgetEntry,
	}
}

export default useContractEntriesService
