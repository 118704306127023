export const projectStatus: Array<{
	caption: string
	value: string
}> = [
	{ value: 'Draft', caption: 'Draft' },
	{ value: 'Planning', caption: 'Planning' },
	{ value: 'Execution', caption: 'Execution' },
	{ value: 'Service', caption: 'Service' },
	{ value: 'Finished', caption: 'Finished' },
]
