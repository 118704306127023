import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import TranslateIcon from '@mui/icons-material/Translate'
import { Box, IconButton, Typography } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import useLanguage from 'hooks/UseLanguge'

const languages = [
	{
		name: 'Polski',
		shortName: 'POL',
		value: 'pl',
	},
	{
		name: 'English',
		shortName: 'ENG',
		value: 'en',
	},
	{
		name: 'Українська',
		shortName: 'УКР',
		value: 'uk',
	},
]

interface Props {
	variant?: string
}

const LanguageSwitcher = ({ variant }: Props) => {
	const [lang, setLang] = useState<string | undefined | null>('pl')

	const { handleLanguageChange, getCurrentLanguage } = useLanguage()

	const handleChange = useCallback((event: React.MouseEvent<HTMLElement>, newLang: string | undefined) => {
		setLang(newLang)
		handleLanguageChange(newLang as string)
	}, [])

	useEffect(() => {
		setLang(getCurrentLanguage())
	}, [])

	if (variant === 'toggle') {
		return <LanguageSwitcherToggle lang={lang} handleChange={handleChange} />
	}

	return <LanguageSwitcherMenu lang={lang} handleChange={handleChange} />
}

interface VariantProps {
	lang: string | undefined | null
	handleChange: (e: React.MouseEvent<HTMLElement>, value: string) => void
}

const LanguageSwitcherToggle = ({ lang, handleChange }: VariantProps) => {
	const { t } = useTranslation('navigation')

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'space-around',
				justifyContent: 'center',
				flexDirection: 'column',
			}}>
			<Typography
				component="span"
				fontSize={'12px'}
				fontWeight={'500'}
				sx={{ pb: '5px', textTransform: 'uppercase', textAlign: 'center' }}>
				{t('user-menu.Language')}
			</Typography>
			<ToggleButtonGroup value={lang} exclusive onChange={handleChange} color="primary" size="small" fullWidth>
				{languages.map(el => {
					return (
						<ToggleButton key={el.name} value={el.value}>
							{el.shortName}
						</ToggleButton>
					)
				})}
			</ToggleButtonGroup>
		</Box>
	)
}

const LanguageSwitcherMenu = ({ lang, handleChange }: VariantProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const activeLanguage = localStorage.getItem('language') || 'pl'

	const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<IconButton size="large" onClick={handleOpenMenu} color="inherit">
				<TranslateIcon />
			</IconButton>
			<Menu
				id="menu-language"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}>
				{languages.map(el => {
					return (
						<MenuItem
							sx={{
								color: activeLanguage === el.value ? 'primary.main' : 'inherit',
							}}
							key={el.name}
							onClick={e => {
								handleCloseMenu()
								handleChange(e, el.value)
							}}>
							{el.name}
						</MenuItem>
					)
				})}
			</Menu>
		</>
	)
}

export default LanguageSwitcher
