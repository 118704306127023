export class ODataError extends Error {
	response: Response

	constructor(message: string, response: Response) {
		super(message)
		this.response = response
	}

	async readError() {
		var errorObject = await this.response.json()
		return errorObject.value
	}
}
