import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IInvokeAction, IOffer } from 'models'
import { OFFER_ENDPOINT } from 'services/data'

export interface getOffersListProps {
	isCanceled: boolean
	isCompleted: boolean
	demandId?: string
	data?: GridInitialState
}

const useOfferService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = OFFER_ENDPOINT

	const getFilteredOffers = useCallback(
		async ({ isCanceled, isCompleted, demandId, data }: getOffersListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (demandId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&demandId=${demandId}`
			}
			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getOffers = useCallback(
		async ({ isCanceled, isCompleted, demandId }: getOffersListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (demandId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&demandId=${demandId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getOffer = useCallback(
		async (offerId: string) => {
			const response = await request(`${path}/${offerId}`)
			return response
		},
		[request]
	)

	const updateOffer = useCallback(
		async (offerId: string, data: IOffer) => {
			await request(`${path}/${offerId}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (offerId: string) => {
			const response = await request(`${path}/actions/${offerId}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getContractorContactsIds = useCallback(
		async (offerId: string) => {
			const response = await request(`${path}/contractorContacts/${offerId}`)
			return response
		},
		[request]
	)

	const addContractorContacts = useCallback(
		async (offerId: string, contractorContactInfoId: string) => {
			await request(`${path}/contractorContacts/${offerId}?contractorContactInfoId=${contractorContactInfoId}`, 'PUT')
		},
		[request]
	)

	const deleteContractorContacts = useCallback(
		async (offerId: string, contractorContactInfoId: string) => {
			await request(
				`${path}/contractorContacts/${offerId}?contractorContactInfoId=${contractorContactInfoId}`,
				'DELETE'
			)
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (offerId: string) => {
			const response = await request(`${path}/allowedProperties/read/${offerId}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (offerId: string) => {
			const response = await request(`${path}/allowedProperties/write/${offerId}`)
			return response
		},
		[request]
	)

	// returns data of contractor from demand inquiries who is not added yet to the contractors dictionary
	const getQuotationContractor = useCallback(
		async (QuotationRequestContractorId: string) => {
			const response = await request(`/api/quotationRequestContractor/one/${QuotationRequestContractorId}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getOffers,
		getOffer,
		updateOffer,
		getActions,
		invokeAction,
		getContractorContactsIds,
		addContractorContacts,
		deleteContractorContacts,
		getPropertiesToRead,
		getPropertiesToEdit,
		getQuotationContractor,
		getFilteredOffers,
	}
}

export default useOfferService
