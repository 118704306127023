import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IEmailTemplate } from 'models'

const useEmailTemplatesService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/emailTemplate'

	const getTemplates = useCallback(
		async (emailTemplateType?: string) => {
			const getTemplatesPath = emailTemplateType ? `${path}?emailTemplateType=${emailTemplateType}` : path

			const response = await request(getTemplatesPath)
			return response
		},
		[request]
	)

	const getTemplate = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const addTemplate = useCallback(
		async (data: IEmailTemplate) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateTemplate = useCallback(
		async (id: string, data: IEmailTemplate) => {
			const response = await request(`${path}/${id}`, 'PATCH', data)
			return response
		},
		[request]
	)

	const deleteTemplate = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		showSuccessInfo,
		error,
		fieldsError,
		clearError,
		getTemplates,
		getTemplate,
		addTemplate,
		updateTemplate,
		deleteTemplate,
	}
}

export default useEmailTemplatesService
