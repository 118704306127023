import { IAttachment } from 'models/IAttachment'

export function isAttachmentsContainsFile(attachments: IAttachment[]): boolean {
	return attachments.some((attachment: IAttachment) => {
		if (!attachment.IsDirectory) {
			return true
		}
		if (attachment.SubItems && attachment.SubItems.length > 0) {
			return isAttachmentsContainsFile(attachment.SubItems)
		}
		return false
	})
}

export const fileExtensionsAvailableForPreview = ['pdf', 'xlsx', 'jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp', 'docx']

export const getFileExtension = (fileName: string) => {
	const parts = fileName.split('.')
	if (parts.length > 1) {
		return parts[parts.length - 1].toLocaleLowerCase()
	}
	return ''
}

export const checkIsFileAvailableForPreview = (fileName: string) => {
	const fileExtension = getFileExtension(fileName)
	return fileExtensionsAvailableForPreview.includes(fileExtension)
}

export const getFileType = (fileExtension: string) => {
	switch (fileExtension) {
		case 'pdf':
		case 'docx':
			return 'pdf'
		case 'xlsx':
			return 'xlsx'
		case 'jpeg':
		case 'jpg':
		case 'png':
		case 'gif':
		case 'bmp':
		case 'webp':
			return 'image'

		default:
			return 'any'
	}
}
