import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { LoadingButton } from '@mui/lab'

import { useAppSelector } from 'features'
import { IContract, ISchedule } from 'models'
import { useScheduleService } from 'services'

import { Loading } from '../loading'
import { NoData } from '../no-data-message'
import AggregationFooter from './AggregationFooter'
import Body from './Body'
import Heading from './Heading'

type Props = {
	documentType: string
	documentId: string
	readOnly: boolean
}

const ScheduleTab = ({ documentType, documentId, readOnly }: Props) => {
	const [schedule, setSchedule] = useState<ISchedule>()
	const [loading, setLoading] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [noCompletionDateSelected, setNoCompletionDateSelected] = useState(false)

	const { documentData } = useAppSelector(state => state.documentData)

	const { ConsumptionDateTimeStart, ConsumptionDateTimeEnd } = documentData as IContract

	const { getSchedule, exportScheduleToExcel } = useScheduleService()

	const { t } = useTranslation()

	const getScheduleData = async () => {
		setLoading(true)
		try {
			const response = await getSchedule(documentType, documentId)
			setSchedule(response)
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	const handleExportExcel = async () => {
		try {
			setButtonLoading(true)
			await exportScheduleToExcel(documentType, documentId)
		} catch (err) {
			console.error(err)
		} finally {
			setButtonLoading(false)
		}
	}

	useEffect(() => {
		if (!ConsumptionDateTimeStart || !ConsumptionDateTimeEnd) {
			setLoading(false)
			setNoCompletionDateSelected(true)
		} else {
			getScheduleData()
		}
	}, [documentType, documentId])

	if (loading) return <Loading />
	if (noCompletionDateSelected) return <NoData alternativeText={t('ScheduleTab.NoCompletionDateSelected') as string} />
	if (!schedule) return <NoData />

	return (
		<>
			<div className="flex items-center h-11 mx-2">
				<LoadingButton
					variant="text"
					color="primary"
					size="small"
					onClick={handleExportExcel}
					loading={buttonLoading}
					loadingPosition="start"
					startIcon={<FileDownloadIcon />}>
					{buttonLoading ? t('general.FilePreparation') : t('general.Export')}
				</LoadingButton>
			</div>
			<div className="w-full h-[calc(100vh-274px)] min-h-[500px] overflow-x-auto">
				<Heading data={schedule} />
				<Body
					data={schedule}
					documentType={documentType}
					documentId={documentId}
					refreshData={getScheduleData}
					readOnly={readOnly}
				/>
				<AggregationFooter valuesSum={schedule.SumOfRowValue} sums={schedule.Sums} />
			</div>
		</>
	)
}

export default ScheduleTab
