import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Badge, Box, Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material'

import { useAppSelector, useLinkDocumentsActions } from 'features'

import { styles } from './styles'

const Clipboard = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const { copiedDocumentData } = useAppSelector(state => state.linkDocuments)

	const { setLinkDocumentData } = useLinkDocumentsActions()

	const { t } = useTranslation()

	const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<Badge
				color="primary"
				variant="dot"
				overlap="circular"
				invisible={!copiedDocumentData.id}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}>
				<IconButton className="p-1 md:p-2" onClick={handleOpenMenu} color="inherit">
					<ContentCopyIcon />
				</IconButton>
			</Badge>
			<Menu
				id="clipboard-menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}>
				<Box sx={styles.title}>{t('HeaderClipboard.Title')}</Box>
				<Divider />
				<Box color="inherit" sx={styles.content}>
					<Box color="info.main">
						{copiedDocumentData.id ? (
							<Stack>{copiedDocumentData.name}</Stack>
						) : (
							<Stack sx={styles.noDataText}>{t('general.NoData')}</Stack>
						)}
					</Box>
				</Box>
				<Divider />
				<MenuItem
					disabled={!copiedDocumentData.id}
					onClick={() => setLinkDocumentData({ id: '', name: '', documentType: '', otherData: {} })}>
					<Stack sx={styles.clearButton}>{t('general.Clear')}</Stack>
				</MenuItem>
			</Menu>
		</>
	)
}

export default Clipboard
