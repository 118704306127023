import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IProjectModel } from 'models'

const useProjectsBasicInfoService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const createProject = useCallback(
		async (data: IProjectModel) => {
			const response = await request(`/odata/Project`, 'POST', data)
			return response
		},
		[request]
	)

	const getProjectData = useCallback(
		async (projectId: string) => {
			const response = await request(`/api/Project/${projectId}`)
			return response
		},
		[request]
	)

	const getListOfContractors = useCallback(async () => {
		const response = await request(`/api/contractor`)
		return response
	}, [request])

	const updateProjectData = useCallback(
		async (projectId: string, data: IProjectModel) => {
			await request(`/api/Project/${projectId}`, 'PATCH', data)
		},
		[request]
	)

	const calculateProjectDataAtRuntime = useCallback(
		async (projectId: string, body: IProjectModel) => {
			const response = await request(`/api/simulation/project/${projectId}`, 'PATCH', body)
			return response
		},
		[request]
	)

	const deleteProject = useCallback(
		async (projectId: string) => {
			await request(`/api/Project/${projectId}`, 'DELETE')
		},
		[request]
	)

	const createInvestorProcessingProtocol = useCallback(
		async (key: string, investorProcessingProtocolWorkflowId: string) => {
			const response = await request(
				`/api/project/createInvestorProcessingProtocol/${key}?investorProcessingProtocolWorkflowId=${investorProcessingProtocolWorkflowId}`,
				'POST'
			)
			return response.ResultData
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		createProject,
		getProjectData,
		updateProjectData,
		getListOfContractors,
		calculateProjectDataAtRuntime,
		deleteProject,
		createInvestorProcessingProtocol,
	}
}

export default useProjectsBasicInfoService
