import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const ProjectIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M20.922,24H4.529c-1.091,0-1.978-0.887-1.978-1.979V1.978C2.551,0.887,3.438,0,4.529,0h16.393
          C22.014,0,22.9,0.887,22.9,1.978v20.043C22.898,23.113,22.014,24,20.922,24z M4.529,1.261c-0.396,0-0.717,0.321-0.717,0.717v20.043
          c0,0.396,0.321,0.719,0.717,0.719h16.393c0.396,0,0.719-0.322,0.719-0.719V1.978c0-0.396-0.322-0.717-0.719-0.717H4.529z"
			/>
			<path
				fill="currentColor"
				d="M10.22,7.556H6.86c-0.595,0-1.079-0.484-1.079-1.079V3.484c0-0.595,0.484-1.079,1.079-1.079h3.36
          c0.595,0,1.079,0.484,1.079,1.079v2.993C11.299,7.072,10.815,7.556,10.22,7.556z M7.042,6.295h2.997V3.666H7.042V6.295z"
			/>
			<path
				fill="currentColor"
				d="M18.592,7.556H15.23c-0.597,0-1.078-0.484-1.078-1.079V3.484c0-0.595,0.481-1.079,1.078-1.079h3.36
          c0.595,0,1.078,0.484,1.078,1.079v2.993C19.67,7.072,19.188,7.556,18.592,7.556z M15.412,6.295h2.998V3.666h-2.998V6.295z"
			/>
			<path
				fill="currentColor"
				d="M10.22,13.604H6.86c-0.595,0-1.079-0.483-1.079-1.078V9.529c0-0.595,0.484-1.079,1.079-1.079h3.36
          c0.595,0,1.079,0.484,1.079,1.079v2.997C11.299,13.119,10.815,13.604,10.22,13.604z M7.042,12.343h2.997V9.711H7.042V12.343z"
			/>
			<path
				fill="currentColor"
				d="M18.592,13.604H15.23c-0.597,0-1.078-0.483-1.078-1.078V9.529c0-0.595,0.481-1.079,1.078-1.079h3.36
          c0.595,0,1.078,0.484,1.078,1.079v2.997C19.67,13.119,19.188,13.604,18.592,13.604z M15.412,12.343h2.998V9.711h-2.998V12.343z"
			/>
			<path
				fill="currentColor"
				d="M16.295,22.82h-1.26v-6.514h-4.619v6.514h-1.26v-6.713c0-0.586,0.476-1.062,1.062-1.062h5.017
          c0.584,0,1.062,0.476,1.062,1.062L16.295,22.82L16.295,22.82z"
			/>
		</SvgIcon>
	)
}

export default ProjectIcon
