import { useTranslation } from 'react-i18next'

import { INotification } from 'models'

const usePrepareNotificationTranslation = () => {
	const { t } = useTranslation('notifications')

	const getNotificationTranslation = (notification: INotification) => {
		const contextName =
			t(`${notification.ContextRootingPath.charAt(0).toUpperCase() + notification.ContextRootingPath.slice(1)}`, {
				ns: 'routes',
			}) || ''

		const description = notification.Description ? `[${notification.Description}]` : ''

		return t(`Type.${notification.Type}`).replace(
			'{ContextName}',
			`${contextName} ${notification.ContextItemIdLabel || ''} ${description}`
		)
	}

	return { getNotificationTranslation }
}

export default usePrepareNotificationTranslation
