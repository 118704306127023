import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { ContactPersons, ContractorForm, InputHint, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IOrder } from 'models'
import { ORDER_ENDPOINT } from 'services'

import OrderParameters from './form-tabs/OrderParameters'

interface Props {
	orderId: string
	formData: IOrder
}

const OrderForm = ({ orderId, formData }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('order')

	const { handleInputChange, fieldsError } = useFormDataChange()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('orderFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.BasicParameters'),
				renderValue: <OrderParameters formData={formData} />,
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons documentId={orderId} contractorId={formData.Contractor || ''} endpoint={ORDER_ENDPOINT} />
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	useEffect(() => {
		const savedTab = localStorage.getItem('orderFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								id="order-field-Number"
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="Order.Number" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								id="order-field-DocumentDate"
								name="DocumentDate"
								label={t('Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="Order.DocumentDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Order.StageId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
										<InputHint translationPath="Order.WorkflowId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
											<InputHint translationPath="Order.Project" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{formData.Offer ? (
						<Grid xs={12} sm={6} md={3} lg={3}>
							{isVisible('Offer') && (
								<TextField
									disabled={true}
									value={formData.OfferLabel || ''}
									name="Offer"
									label={t('Fields.Offer')}
									fullWidth
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<InputLinkButton
													path={PATHS.offer}
													documentId={formData.Offer}
													documentLabel={formData.OfferLabel}
												/>
												<InputHint translationPath="Order.Offer" />
											</InputAdornment>
										),
									}}
								/>
							)}
						</Grid>
					) : (
						<Grid xs={12} sm={6} md={3} lg={3}>
							{isVisible('Demand') && (
								<TextField
									disabled={true}
									value={formData.DemandLabel || ''}
									name="Demand"
									label={t('Fields.Demand')}
									fullWidth
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<InputLinkButton
													path={PATHS.demand}
													documentId={formData.Demand}
													documentLabel={formData.DemandLabel}
												/>
												<InputHint translationPath="Order.Demand" />
											</InputAdornment>
										),
									}}
								/>
							)}
						</Grid>
					)}
				</Grid>
			</div>
			<ContractorForm
				documentId={orderId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor') || !formData.Demand}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={el.label} label={el.label} value={index.toString()} />
								}
								return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						}
						return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default OrderForm
