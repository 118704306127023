import { IAttachment } from 'models/IAttachment'

import SingleFileAttachment from './SingleFileAttachment'
import SingleFolderAttachment from './SingleFolderAttachment'
import styles from './Styles.module.css'

interface Props {
	array: IAttachment[]
	contextId: string
	updateAttachments: React.DispatchWithoutAction
	isForInquiries?: boolean
	workflowId?: string
	project?: boolean
}

const AttachmentList = ({ array, contextId, updateAttachments, isForInquiries, workflowId, project }: Props) => {
	const itemRendering = (item: IAttachment) => {
		if (item.IsDirectory) {
			return (
				<SingleFolderAttachment
					key={item.Id}
					name={item.Name}
					subItems={item.SubItems}
					contextId={contextId}
					itemId={item.Id}
					updateAttachments={updateAttachments}
				/>
			)
		} else {
			return (
				<SingleFileAttachment
					key={item.Id}
					name={item.Name}
					attachmentId={item.Id}
					markedToDelete={item.MarkedToDeleteDate}
					updateAttachments={updateAttachments}
					contextId={contextId}
					isForInquiries={isForInquiries}
					workflowId={workflowId}
					project={project}
				/>
			)
		}
	}

	return (
		<ul className={styles.attachmentsList}>
			{array.map((item: IAttachment) => {
				return itemRendering(item)
			})}
		</ul>
	)
}

export default AttachmentList
