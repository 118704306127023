import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'

import { IWorkflow } from 'models'
import { useWorkflowService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	workflowData: IWorkflow
	getData: () => void
}

const DeleteWorkflowDialog = ({ isOpen, setIsOpen, workflowData, getData }: Props) => {
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { deleteWorkflow, showSuccessInfo } = useWorkflowService()

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleDeleteWorkflow = useCallback(async () => {
		try {
			setButtonLoading(true)
			await deleteWorkflow(workflowData.Id)
			getData()
			showSuccessInfo('deleted')
			closeDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}, [workflowData.Id])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{`${t('Workflow.DeleteWorkflowDialog.Title')} - ${workflowData.Name}`}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography sx={sxStyles.content}>{t('Workflow.DeleteWorkflowDialog.Content')}</Typography>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.No', { ns: 'translation' })}
				</Button>
				<LoadingButton variant="contained" onClick={handleDeleteWorkflow} loading={buttonLoading} color="success">
					{t('general.Yes', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default DeleteWorkflowDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	content: { fontSize: '16px', margin: '10px', textAlign: 'center' },
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
