import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { List, ListItem, ListItemText } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { useProjectsListService, useRolesService } from 'services'

interface Props {
	userId: string
}

const PerProject = ({ userId }: Props) => {
	const [roles, setRoles] = useState<any[]>([])
	const [loading, setLoading] = useState(true)

	const { t } = useTranslation('administration')

	const { getUserPerProjectRoles, getRolesCanBePerProject } = useRolesService()

	const { getListOfProjects } = useProjectsListService()

	const getData = useCallback(async () => {
		try {
			const rolesThatCanBePerProject = await getRolesCanBePerProject()
			const projectsList = await getListOfProjects()
			const userPerProjectRoles = await getUserPerProjectRoles(userId)

			const dataToRender = userPerProjectRoles.map((el: any) => {
				const project = projectsList.find((project: any) => {
					return project.Id === el.ProjectId
				})

				const role = rolesThatCanBePerProject.find((role: any) => {
					return role.Id === el.HeaderId
				})

				return {
					...el,
					Name: role.Name,
					ProjectName: `${project.InvestmentName || ''} ${project.Number || ''}`,
				}
			})

			setRoles(dataToRender)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}, [userId, getListOfProjects, getUserPerProjectRoles, getRolesCanBePerProject])

	useEffect(() => {
		getData()
	}, [userId])

	return (
		<List sx={sxStyles.listContainer}>
			<ListItem sx={sxStyles.listItem}>
				<ListItemText
					sx={sxStyles.listItemText}
					primaryTypographyProps={{ fontSize: '14px' }}
					primary={t('Users.UserPage.Project')}
				/>
				<ListItemText
					sx={sxStyles.listItemText}
					primaryTypographyProps={{ fontSize: '14px' }}
					primary={t('Users.UserPage.Role')}
				/>
			</ListItem>
			{loading ? (
				<Loading />
			) : roles.length ? (
				roles.map((el, index) => {
					return (
						<ListItem key={index} sx={sxStyles.listItem}>
							<ListItemText
								sx={sxStyles.listItemText}
								primaryTypographyProps={{ fontSize: '14px' }}
								primary={el.ProjectName}
							/>
							<ListItemText
								sx={sxStyles.listItemText}
								primaryTypographyProps={{ fontSize: '14px' }}
								primary={el.Name}
							/>
						</ListItem>
					)
				})
			) : (
				<ListItem>
					<NoData fontSize="18px" />
				</ListItem>
			)}
		</List>
	)
}

export default PerProject

const sxStyles = {
	listContainer: {
		width: '100%',
		padding: '0',
	},
	listItem: {
		borderBottom: 1,
		borderColor: 'action.disabled',
		borderRadius: '4px 4px 0 0',
		color: 'text.secondary',
	},
	listItemText: {
		textAlign: 'left',
		width: '70%',
	},
}
