import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Loading from 'components/shared/loading/Loading'
import { useTabs } from 'hooks'
import { useProjectsBasicInfoService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	projectId: string
}

const ConfirmDeleteProjectDialog = ({ isOpen, setIsOpen, projectId }: Props) => {
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation('project')

	const { showSuccessInfo, deleteProject } = useProjectsBasicInfoService()

	const mainTabs = useTabs()
	const navigate = useNavigate()

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleDeleteProject = useCallback(async () => {
		try {
			setLoading(true)
			await deleteProject(projectId)
			showSuccessInfo('custom', t('Actions.ConfirmDeleteDialog.ProjectDeletedSuccessfully'))
			mainTabs.closeTab(mainTabs.selectedTab?.key as string)
			navigate('/projects')
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}, [projectId, mainTabs])

	return (
		<Dialog open={isOpen} onClose={closeDialog} sx={sxStyles.container}>
			<Stack sx={{ overflow: 'auto' }}>
				<Typography sx={sxStyles.title}>{t('Actions.Delete')}</Typography>
				<DialogContent>
					{loading ? (
						<Loading />
					) : (
						<Typography sx={sxStyles.content}>{t('Actions.ConfirmDeleteDialog.Content')}</Typography>
					)}
				</DialogContent>
			</Stack>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button disabled={loading} variant="outlined" color="error" onClick={closeDialog} sx={{ mr: '10px' }}>
					{t('Actions.ConfirmDeleteDialog.No')}
				</Button>
				<Button
					disabled={loading}
					variant="contained"
					onClick={() => handleDeleteProject()}
					color="success"
					sx={{ ml: '10px' }}>
					{t('Actions.ConfirmDeleteDialog.Yes')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDeleteProjectDialog

const sxStyles = {
	container: {
		'& .MuiDialog-paper': { width: '80%', maxHeight: '50vh' },
	},
	title: { fontSize: '18px', margin: '10px', padding: '15px 0 0 15px' },
	content: { fontSize: '16px', margin: '10px', textAlign: 'center' },
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
