export const contractContractType: Array<{
	label: string
	value: string
}> = [
	{ value: 'Unknown', label: 'Unknown' },
	{ value: 'Full', label: 'Full' },
	{ value: 'Short', label: 'Short' },
	{ value: 'FeeForTask', label: 'FeeForTask' },
	{ value: 'Foreign', label: 'Foreign' },
]
