import InvestorBudgetColumns from 'components/pages/investor-budget/InvestorBudgetColumns'
import BudgetDataGrid from 'components/shared/budget-data-grid/BudgetDataGrid'
import { useInvestorBudgetService } from 'services'

interface Props {
	projectId: string
	readOnly: boolean
}

const InvestorBudgetTab = ({ projectId, readOnly }: Props) => {
	const investorBudgetApi = useInvestorBudgetService()

	return (
		<BudgetDataGrid
			columns={InvestorBudgetColumns}
			projectId={projectId}
			api={investorBudgetApi}
			readOnly={readOnly}
			budgetType={'investor'}
		/>
	)
}

export default InvestorBudgetTab
