import { useTranslation } from 'react-i18next'

const useValidation = () => {
	const { t } = useTranslation()

	const checkRegexMatch = (value: any, regex: any) => {
		const pattern = new RegExp(regex)

		return pattern.test(value)
	}

	const simpleTextRegex = /^[\p{L}0-9\\/|\s\.\-„”""',]*$/u
	const simpleTextErrorMessage = t('RegexErrorMessages.simpleTextErrorMessage')

	const advancedTextRegex = /^[\p{L}0-9\\/|\s\.\-„”""',;()%]*$/u
	const advancedTextErrorMessage = t('RegexErrorMessages.advancedTextErrorMessage')

	const pureTextRegex = /^[\p{L}0-9_]+$/u
	const pureTextErrorMessage = t('RegexErrorMessages.pureTextErrorMessage')

	const passwordRegex = /^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/u
	const passwordErrorMessage = t('RegexErrorMessages.passwordErrorMessage')
	const confirmPasswordErrorMessage = t('RegexErrorMessages.confirmPasswordErrorMessage')

	const numeratorRegex = /^[\p{L}0-9@\\/|\s\.\-„”""',;()%]*$/u
	const numeratorErrorMessage = t('RegexErrorMessages.numeratorErrorMessage')

	const phoneNumberRegex = /^[0-9\s+\-\(\)]*$/
	const phoneNumberErrorMessage = t('RegexErrorMessages.phoneNumberErrorMessage')

	const emailRegex =
		/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|""(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*"")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
	const emailErrorMessage = t('RegexErrorMessages.emailErrorMessage')

	const postalCodeRegex = /^[0-9]{2}-[0-9]{3}/
	const postalCodeErrorMessage = t('RegexErrorMessages.postalCodeErrorMessage')

	const budgetNumberRegex = /^[0-9]+(\.[0-9]+)*$/u
	const budgetNumberErrorMessage = t('RegexErrorMessages.budgetNumberErrorMessage')

	return {
		checkRegexMatch,
		simpleTextRegex,
		simpleTextErrorMessage,
		advancedTextRegex,
		advancedTextErrorMessage,
		pureTextRegex,
		pureTextErrorMessage,
		passwordRegex,
		passwordErrorMessage,
		confirmPasswordErrorMessage,
		numeratorRegex,
		numeratorErrorMessage,
		phoneNumberRegex,
		phoneNumberErrorMessage,
		emailRegex,
		emailErrorMessage,
		postalCodeRegex,
		postalCodeErrorMessage,
		budgetNumberRegex,
		budgetNumberErrorMessage,
	}
}

export default useValidation
