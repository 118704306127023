import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

interface Folder {
	directoryId: string | undefined
	directoryName: string
}

const useAttachmentsService = () => {
	const {
		request,
		uploadFileRequest,
		downloadFileRequest,
		showSuccessInfo,
		error,
		fieldsError,
		clearError,
		getFileURL,
	} = useHttp()

	const getAttachments = useCallback(
		async (contextId: string, showDeleted: boolean) => {
			const response = await request(`/api/attachment/${contextId}?showDeleted=${showDeleted}`)
			return response
		},
		[request]
	)

	const getSingleAttachment = useCallback(
		async (attachmentId: string) => {
			const response = await request(`/api/attachment/single/${attachmentId}`)
			return response
		},
		[request]
	)

	const setAsDefaultAttachment = useCallback(
		async (documentId: string, workflowId: string, attachmentId: string) => {
			await request(
				`/api/attachment/setDocumentDefaultAttachment?documentId=${documentId}&workflowId=${workflowId}&attachmentId=${attachmentId}`,
				'PATCH'
			)
		},
		[request]
	)

	const deleteFile = useCallback(
		async (fileId: string) => {
			await request(`/api/attachment/?attachmentId=${fileId}`, 'DELETE')
		},
		[request]
	)

	const undeleteFile = useCallback(
		async (fileId: string) => {
			await request(`/api/attachment/undelete/?attachmentId=${fileId}`, 'PATCH')
		},
		[request]
	)

	const downloadFile = useCallback(
		async (name: string, fileId: string) => {
			const response = await downloadFileRequest(
				`/api/attachment/download/?attachmentId=${fileId}`,
				'GET',
				{
					'Content-Type': 'application/octet-stream',
				},
				name
			)
			return response
		},
		[downloadFileRequest]
	)

	const getURL = useCallback(
		async (fileId: string, name?: string) => {
			const response = await getFileURL(
				`/api/attachment/download/?attachmentId=${fileId}`,
				'GET',
				{
					'Content-Type': 'application/octet-stream',
				},
				name
			)
			return response
		},
		[getFileURL]
	)

	const getWordExcelPreviewPdf = useCallback(
		async (fileId: string) => {
			const response = await getFileURL(`/api/attachment/downloadWordExcelPreviewPdf/?attachmentId=${fileId}`, 'GET', {
				'Content-Type': 'application/octet-stream',
			})
			return response
		},
		[getFileURL]
	)

	const attachFileToInquiries = useCallback(
		async (demandId: string, attachmentId: string) => {
			await request(`/api/demand/quotationRequestAttachements/${demandId}?attachmentId=${attachmentId}`, 'PUT')
		},
		[request]
	)

	const deleteFolder = useCallback(
		async (folderId: string) => {
			await request(`/api/attachment/directory/?directoryId=${folderId}`, 'DELETE')
		},
		[request]
	)

	const addFile = useCallback(
		async (contextId: string, formData: any) => {
			const response = await uploadFileRequest(`/api/attachment/upload/${contextId}`, 'POST', formData)
			return response
		},
		[uploadFileRequest]
	)

	const addFolder = useCallback(
		async (contextId: string, folder: Folder) => {
			let endpoint = folder.directoryId
				? `/api/attachment/directory/${contextId}?directoryId=${folder.directoryId}&directoryName=${folder.directoryName}`
				: `/api/attachment/directory/${contextId}?directoryName=${folder.directoryName}`

			const response = await request(endpoint, 'POST')
			return response
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getAttachments,
		deleteFile,
		undeleteFile,
		downloadFile,
		attachFileToInquiries,
		deleteFolder,
		addFile,
		addFolder,
		getURL,
		getWordExcelPreviewPdf,
		setAsDefaultAttachment,
		getSingleAttachment,
	}
}

export default useAttachmentsService
