const formatNumberDataGrid = (value: string | number | null, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
	let parsedValue = value

	if (!parsedValue && parsedValue !== 0) {
		return null
	}

	if (typeof value === 'string') {
		parsedValue = Number(value)
	}

	return parsedValue.toLocaleString('uk-UA', {
		minimumFractionDigits,
		maximumFractionDigits,
	})
}

export default formatNumberDataGrid
