import { useTranslation } from 'react-i18next'

import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { IEmailTemplate } from 'models'
import { useEmailTemplatesService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

interface Props {
	templates: IEmailTemplate[]
	setEmailSubject: (value: string) => void
	setDefaultEmailText: (value: string) => void
	setEmailText: (value: string) => void
	getDataFromHtml: (value: any) => string
	setIsSaveContentButtonDisabled: (value: boolean) => void
	handleClose: () => void
	getTemplates: () => void
}

const TemplatesList = ({
	templates,
	setEmailSubject,
	setDefaultEmailText,
	setEmailText,
	getDataFromHtml,
	setIsSaveContentButtonDisabled,
	handleClose,
	getTemplates,
}: Props) => {
	const { t } = useTranslation()

	const { deleteTemplate, showSuccessInfo } = useEmailTemplatesService()

	const handleDeleteTemplate = async (id: string) => {
		if (id)
			try {
				await deleteTemplate(id)
				showSuccessInfo('deleted')
				getTemplates()
			} catch (err) {
				console.error(err)
			}
	}

	const handleChooseTemplate = (el: IEmailTemplate) => {
		setEmailSubject(el.EmailSubject as string)
		setEmailText(el.EmailBody as string)
		setDefaultEmailText(getDataFromHtml(el.EmailBody as string))
		setIsSaveContentButtonDisabled(false)
		handleClose()
	}

	return (
		<Grid container rowSpacing={2} columnSpacing={2}>
			{templates.map((item, index) => {
				return (
					<Grid key={index} xs={12} sm={12} md={6} lg={6}>
						<Paper elevation={3} sx={sxStyles.paper}>
							<Typography variant="body1" textAlign={'center'} color="primary">
								{item.Name}
							</Typography>
							<Typography variant="body2" sx={{ padding: '5px' }}>
								{item.EmailSubject}
							</Typography>
							<Typography component={'div'} variant="body2" sx={sxStyles.body}>
								{/* TODO: SECURE JAVASCRIPT EXCECUTION */}
								<p
									dangerouslySetInnerHTML={{
										__html: item.EmailBody as string,
									}}></p>
								{/* TODO: SECURE JAVASCRIPT EXCECUTION */}
							</Typography>
							<Box sx={sxStyles.actions}>
								<Tooltip title={<TooltipTextWrapper title={t('general.Remove')} />} placement="top" arrow>
									<span>
										<IconButton sx={{ padding: '7px' }} onClick={() => handleDeleteTemplate(item.Id as string)}>
											<DeleteIcon fontSize="medium" color={'primary'} />
										</IconButton>
									</span>
								</Tooltip>
								<Tooltip title={<TooltipTextWrapper title={t('general.Choose')} />} placement="top" arrow>
									<span>
										<IconButton sx={{ padding: '7px' }} onClick={() => handleChooseTemplate(item)}>
											<CheckIcon fontSize="medium" color={'primary'} />
										</IconButton>
									</span>
								</Tooltip>
							</Box>
						</Paper>
					</Grid>
				)
			})}
		</Grid>
	)
}

export default TemplatesList

const sxStyles = {
	paper: {
		width: '100%',
		height: '550px',
		padding: '15px 10px',
		display: 'flex',
		flexDirection: 'column',
	},
	body: {
		height: '80%',
		width: '100%',
		padding: '5px',
		overflow: 'auto',
		'& h2': {
			fontSize: '22px',
		},
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
}
