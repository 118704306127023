import { ISchedule, IScheduleRow } from 'models'

import { ScheduleRow } from './schedule-row'

type Props = {
	data: ISchedule
	documentType: string
	documentId: string
	refreshData: () => void
	readOnly: boolean
}

const Body = ({ data, documentType, documentId, refreshData, readOnly }: Props) => {
	return (
		<div>
			{data.Rows.map((row: IScheduleRow, index: number) => (
				<ScheduleRow
					key={index}
					row={row}
					documentType={documentType}
					documentId={documentId}
					refreshData={refreshData}
					readOnly={readOnly}
				/>
			))}
		</div>
	)
}

export default Body
