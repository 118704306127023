import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const InvestorProcessingProtocolIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M19.48,24H5.827c-1.061,0-1.923-0.861-1.923-1.923V1.923C3.904,0.863,4.767,0,5.827,0H19.48
	c1.061,0,1.922,0.863,1.922,1.923v20.154C21.402,23.139,20.541,24,19.48,24z M5.827,1.246c-0.374,0-0.679,0.304-0.679,0.677v20.154
	c0,0.374,0.305,0.679,0.679,0.679H19.48c0.373,0,0.678-0.305,0.678-0.679V1.923c0-0.374-0.305-0.677-0.678-0.677H5.827z"
			/>
			<g>
				<path
					fill="currentColor"
					d="M7.865,7.279c-0.207,0-0.401-0.103-0.518-0.275L6.161,5.235C5.97,4.949,6.046,4.563,6.331,4.371
		C6.617,4.178,7.004,4.256,7.195,4.54L7.771,5.4l0.94-1.98c0.148-0.311,0.52-0.441,0.829-0.295c0.312,0.147,0.443,0.519,0.296,0.83
		L8.427,6.923C8.331,7.125,8.135,7.261,7.91,7.277C7.896,7.278,7.88,7.279,7.865,7.279z"
				/>
				<path
					fill="currentColor"
					d="M18.688,4.999h-6.521c-0.344,0-0.622-0.278-0.622-0.623c0-0.345,0.278-0.623,0.622-0.623h6.521
		c0.344,0,0.623,0.278,0.623,0.623C19.311,4.721,19.031,4.999,18.688,4.999z"
				/>
				<path
					fill="currentColor"
					d="M18.688,7.279h-6.521c-0.344,0-0.622-0.278-0.622-0.623c0-0.345,0.278-0.623,0.622-0.623h6.521
		c0.344,0,0.623,0.279,0.623,0.623C19.311,7,19.031,7.279,18.688,7.279z"
				/>
			</g>
			<g>
				<path
					fill="currentColor"
					d="M7.865,13.732c-0.207,0-0.401-0.103-0.518-0.275L6.16,11.688c-0.191-0.286-0.115-0.672,0.17-0.864
		c0.286-0.192,0.673-0.115,0.864,0.171l0.576,0.859l0.939-1.98c0.15-0.312,0.522-0.442,0.831-0.295
		c0.312,0.147,0.443,0.518,0.296,0.829l-1.409,2.969c-0.096,0.203-0.292,0.338-0.517,0.355C7.896,13.732,7.88,13.732,7.865,13.732z"
				/>
				<path
					fill="currentColor"
					d="M18.688,11.453h-6.521c-0.344,0-0.622-0.278-0.622-0.623c0-0.345,0.278-0.623,0.622-0.623h6.521
		c0.344,0,0.623,0.279,0.623,0.623C19.311,11.174,19.031,11.453,18.688,11.453z"
				/>
				<path
					fill="currentColor"
					d="M18.688,13.732h-6.521c-0.344,0-0.622-0.278-0.622-0.623c0-0.344,0.278-0.623,0.622-0.623h6.521
		c0.344,0,0.623,0.279,0.623,0.623C19.311,13.454,19.031,13.732,18.688,13.732z"
				/>
			</g>
			<g>
				<path
					fill="currentColor"
					d="M7.865,20.188c-0.207,0-0.401-0.103-0.518-0.274L6.16,18.143c-0.191-0.287-0.115-0.672,0.17-0.863
		c0.286-0.195,0.673-0.116,0.864,0.17l0.576,0.859l0.939-1.979c0.148-0.312,0.521-0.438,0.829-0.296
		c0.312,0.146,0.443,0.52,0.296,0.828l-1.409,2.972c-0.096,0.202-0.292,0.337-0.517,0.354C7.896,20.188,7.88,20.188,7.865,20.188z"
				/>
				<path
					fill="currentColor"
					d="M18.688,17.906h-6.521c-0.344,0-0.622-0.278-0.622-0.622c0-0.345,0.278-0.622,0.622-0.622h6.521
		c0.344,0,0.623,0.277,0.623,0.622C19.311,17.628,19.031,17.906,18.688,17.906z"
				/>
				<path
					fill="currentColor"
					d="M18.688,20.188h-6.521c-0.344,0-0.622-0.277-0.622-0.621s0.278-0.623,0.622-0.623h6.521
		c0.344,0,0.623,0.279,0.623,0.623S19.031,20.188,18.688,20.188z"
				/>
			</g>
		</SvgIcon>
	)
}

export default InvestorProcessingProtocolIcon
