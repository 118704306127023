import React from 'react'
import { NumericFormatProps, PatternFormat } from 'react-number-format'

interface Props {
	onChange: (event: { target: { name: string; value: string } }) => void
	name: string
}

export const FormatBankAccountInput = React.forwardRef<NumericFormatProps, Props>(
	function PatternFormatCustom(props, ref) {
		const { onChange, ...other } = props

		return (
			<PatternFormat
				{...other}
				getInputRef={ref}
				onValueChange={values => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					})
				}}
				valueIsNumericString
				format="## #### #### #### #### #### ####"
				mask=" "
			/>
		)
	}
)
