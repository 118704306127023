import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { ICorrectionInvoice, IInvokeAction } from 'models'
import { CORRECTION_INVOICE_ENDPOINT } from 'services/data'

export interface getCorrectionInvoiceListProps {
	isCanceled: boolean
	isCompleted: boolean
	projectId?: string
	materialInvoiceId?: string
	orderId?: string
	incomingLetterId?: string
	materialInvoiceForeignNumberLastCorrection?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useCorrectionInvoiceService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = CORRECTION_INVOICE_ENDPOINT

	const getFilteredCorrectionInvoices = useCallback(
		async ({
			isCanceled,
			isCompleted,
			projectId,
			materialInvoiceId,
			orderId,
			incomingLetterId,
			materialInvoiceForeignNumberLastCorrection,
			budgetEntryId,
			data,
		}: getCorrectionInvoiceListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (materialInvoiceId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&materialInvoiceId=${materialInvoiceId}`
			}

			if (orderId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&orderId=${orderId}`
			}

			if (incomingLetterId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			if (materialInvoiceForeignNumberLastCorrection) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&materialInvoiceForeignNumberLastCorrection=${materialInvoiceForeignNumberLastCorrection}`
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getCorrectionInvoices = useCallback(
		async ({
			isCanceled,
			isCompleted,
			projectId,
			materialInvoiceId,
			orderId,
			incomingLetterId,
			materialInvoiceForeignNumberLastCorrection,
		}: getCorrectionInvoiceListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (materialInvoiceId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&materialInvoiceId=${materialInvoiceId}`
			}

			if (orderId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&orderId=${orderId}`
			}

			if (incomingLetterId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			if (materialInvoiceForeignNumberLastCorrection) {
				getListPath = `${path}?isCanceled=${isCanceled}&materialInvoiceForeignNumberLastCorrection=${materialInvoiceForeignNumberLastCorrection}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getCorrectionInvoice = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updateCorrectionInvoice = useCallback(
		async (id: string, data: ICorrectionInvoice) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getCorrectionInvoices,
		getCorrectionInvoice,
		updateCorrectionInvoice,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredCorrectionInvoices,
	}
}

export default useCorrectionInvoiceService
