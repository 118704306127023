import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import SyncIcon from '@mui/icons-material/Sync'
import { LoadingButton } from '@mui/lab'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'
import { useDemandService, useInquiriesService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'
import { v4 as uuidv4 } from 'uuid'

import ContractorsListDialog from './ContractorsListDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	getData: () => void
	setDataLoading: (value: boolean) => void
	apiRef: { current: any }
	isActionButtonDisabled: boolean
	setIsActionButtonDisabled: (value: boolean) => void
	setData: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
	readOnly: boolean
	demandId: string
	isSendRequestActionEnabled: boolean
}

const QuotationContractorsToolbar = ({
	getData,
	setDataLoading,
	apiRef,
	isActionButtonDisabled,
	setIsActionButtonDisabled,
	setData,
	setRowModesModel,
	readOnly,
	demandId,
	isSendRequestActionEnabled,
}: Props) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [isAddFromListDialogOpen, setIsAddFromListDialogOpen] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [exportLoading, setExportLoading] = useState(false)
	const openAddMenu = Boolean(anchorEl)

	const { t } = useTranslation()

	const { handleSaveStateSnapshot } = useDataGridState()

	const { exportOfferComparison } = useInquiriesService()
	const { invokeAction, showSuccessInfo } = useDemandService()

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const handleAddButtonClick = () => {
		handleMenuClose()
		const Id = uuidv4()
		setIsActionButtonDisabled(true)

		setData((oldRows: any) => [
			...oldRows,
			{
				Id,
				isNew: true,
			},
		])
		setRowModesModel(oldModel => ({
			...oldModel,
			[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Name' },
		}))
	}

	const handleOpenContractorsList = () => {
		handleMenuClose()
		setIsAddFromListDialogOpen(true)
	}

	const handleSendRequest = useCallback(async () => {
		try {
			setButtonLoading(true)
			const actionData = {
				DocumentId: demandId,
				Action: 'SendQuotationRequest',
			}

			await invokeAction(actionData)

			showSuccessInfo('action')
			getData()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}, [invokeAction, demandId])

	const handleFilterDemand = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateList = () => {
		setDataLoading(true)
		getData()
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	const handleExport = async () => {
		setExportLoading(true)
		try {
			await exportOfferComparison(demandId)
		} catch (err) {
			console.error(err)
		} finally {
			setExportLoading(false)
		}
	}

	return (
		<GridToolbarContainer className="flex justify-between m-1">
			<div>
				<ColumnsButton />
			</div>
			<div>
				<LoadingButton
					variant="text"
					color="primary"
					onClick={handleExport}
					loading={exportLoading}
					loadingPosition="start"
					size="small"
					startIcon={<FileDownloadIcon />}>
					{t('general.DownloadComparisonTable')}
				</LoadingButton>
				{!readOnly && (
					<>
						<Tooltip
							title={
								<TooltipTextWrapper
									title={
										!isSendRequestActionEnabled
											? t('Inquiries.SendTitles.NoPermissions', { ns: 'demand' })
											: t('Inquiries.SendTitles.SendEmailToAllFull', {
													ns: 'demand',
												})
									}
								/>
							}
							placement="top"
							arrow>
							<span>
								<LoadingButton
									sx={{
										p: '3px 10px',
										pr: buttonLoading ? '30px' : '10px',
										mr: '5px',
									}}
									variant="text"
									size="small"
									onClick={handleSendRequest}
									color="primary"
									disabled={isActionButtonDisabled || !isSendRequestActionEnabled}
									loading={buttonLoading}
									loadingPosition="end">
									{t('Inquiries.SendTitles.SendEmailToAll', { ns: 'demand' })}
								</LoadingButton>
							</span>
						</Tooltip>
						<Tooltip title={<TooltipTextWrapper title={t('general.Add')} />} placement="top" arrow>
							<span>
								<IconButton
									sx={{ padding: '5px' }}
									color="primary"
									disabled={isActionButtonDisabled}
									id="menu-button"
									aria-controls={openAddMenu ? 'addContractor-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={openAddMenu ? 'true' : undefined}
									onClick={handleMenuClick}>
									<AddIcon fontSize="medium" />
								</IconButton>
							</span>
						</Tooltip>
						<Menu
							id="addContractor-menu"
							anchorEl={anchorEl}
							open={openAddMenu}
							onClose={handleMenuClose}
							MenuListProps={{
								'aria-labelledby': 'menu-button',
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}>
							<MenuItem onClick={handleAddButtonClick}>
								{t('Inquiries.QuotationContractor.Toolbar.AddManually', {
									ns: 'demand',
								})}
							</MenuItem>
							<MenuItem onClick={handleOpenContractorsList}>
								{t('Inquiries.QuotationContractor.Toolbar.ChooseFromList', {
									ns: 'demand',
								})}
							</MenuItem>
						</Menu>
					</>
				)}
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.Filter"
					onClick={handleFilterDemand}
					IconComponent={FilterListIcon}
					fontSize="medium"
				/>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				{isAddFromListDialogOpen && (
					<ContractorsListDialog
						isOpen={isAddFromListDialogOpen}
						setIsOpen={setIsAddFromListDialogOpen}
						demandId={demandId}
						getData={getData}
					/>
				)}
			</div>
		</GridToolbarContainer>
	)
}

export default QuotationContractorsToolbar
