import { IAddress } from 'models'

const FormatAddress = (address: IAddress): string => {
	if (!address) return ''

	let str = ''

	str += address.Street

	if (address.StreetNumber) {
		str += ' ' + address.StreetNumber

		if (address.FlatNumber) str += '/' + address.FlatNumber
	}

	if (address.City) str += ' ' + address.City

	if (address.PostalCode) {
		str += ', ' + address.PostalCode

		if (address.Post) str += ' ' + address.Post
	}

	return str
}

export default FormatAddress
