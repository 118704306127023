export const projectSecurityForm: Array<{
	label: string
	value: string
}> = [
	{ value: 'None', label: 'None' },
	{ value: 'BankGuarantee', label: 'BankGuarantee' },
	{ value: 'InsuranceGuarantee', label: 'InsuranceGuarantee' },
	{ value: 'InvoiceRetention', label: 'InvoiceRetention' },
	{ value: 'BailAgreement', label: 'BailAgreement' },
]
