import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import DescriptionIcon from '@mui/icons-material/Description'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

import {
	BalancingDocuments,
	Contracts,
	CorrectionInvoices,
	CostInvoices,
	CustomCostInvoices,
	MaterialInvoices,
	Orders,
	ProcessingProtocols,
	SalesInvoices,
} from 'components/pages'
import { Loading, PageNavigation } from 'components/shared'
import DocumentsTab from 'components/shared/documents-tab/DocumentsTab'
import { useAppSelector, useBudgetEntryActions, useDocumentDataActions } from 'features'
import { useRealizationBudgetService } from 'services'

import { BasicInfo } from './basic-info-tab'

const BudgetEntryTab = () => {
	const { loading, budgetEntryData } = useAppSelector(state => state.budgetEntryData)
	const { setBudgetEntryId, setBudgetEntryData, updateLoading, updateFormLoading } = useBudgetEntryActions()
	const { setActionsList } = useDocumentDataActions()

	const { t } = useTranslation()

	const params = useParams()

	const id = params.id as string

	const { getBudgetEntry } = useRealizationBudgetService()

	const getData = async () => {
		try {
			updateFormLoading(true)
			const data = await getBudgetEntry(id)
			setActionsList([])
			setBudgetEntryData(data)
		} catch (err) {
			console.error(err)
		} finally {
			updateFormLoading(false)
			updateLoading(false)
		}
	}

	const documentTabData = [
		{
			label: t('ProcessingProtocols', { ns: 'navigation' }),
			renderValue: <ProcessingProtocols budgetEntryId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('CorrectionInvoices', { ns: 'navigation' }),
			renderValue: <CorrectionInvoices budgetEntryId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('Orders', { ns: 'navigation' }),
			renderValue: <Orders budgetEntryId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('MaterialInvoices', { ns: 'navigation' }),
			renderValue: <MaterialInvoices budgetEntryId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('SalesInvoices', { ns: 'navigation' }),
			renderValue: <SalesInvoices budgetEntryId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('CustomCostInvoices', { ns: 'navigation' }),
			renderValue: <CustomCostInvoices budgetEntryId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('CostInvoices', { ns: 'navigation' }),
			renderValue: <CostInvoices budgetEntryId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('Contracts', { ns: 'navigation' }),
			renderValue: <Contracts budgetEntryId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('BalancingDocuments', { ns: 'navigation' }),
			renderValue: <BalancingDocuments budgetEntryId={id} isTab />,
			isVisible: true,
		},
	]

	const tabs = useMemo(() => {
		if (!budgetEntryData) {
			return []
		}

		return [
			{
				id: 0,
				tabName: t('BasicDocumentTabs.BasicInformation'),
				icon: <TextSnippetIcon />,
				component: <BasicInfo budgetEntryId={id} />,
				disabled: false,
			},
			{
				id: 1,
				tabName: t('BasicDocumentTabs.Documents'),
				icon: <DescriptionIcon />,
				component: <DocumentsTab tabs={documentTabData} />,
				disabled: false,
			},
		]
	}, [id, t, budgetEntryData])

	useEffect(() => {
		getData()
		setBudgetEntryId(id)
	}, [id])

	if (loading) {
		return <Loading />
	}

	return <PageNavigation tabs={tabs} />
}

export default BudgetEntryTab
