import { useTranslation } from 'react-i18next'

import { IContractorModel } from 'models'
import { enqueueSnackbar } from 'notistack'

const useCheckContractor = () => {
	const { t } = useTranslation(['dictionaries', 'translation'])

	const checkIfContractorAlreadyExists = (
		contractorsList: IContractorModel[],
		newContractorNIP: string,
		contractorId?: string
	) => {
		const normalizeNIP = (nip: string | undefined) => {
			// Remove white spaces from the NIP
			return nip ? nip.replace(/\s/g, '') : ''
		}

		const newContractorNIPNormalized = normalizeNIP(newContractorNIP)

		let contractorsToCheck = contractorsList

		if (contractorId) {
			contractorsToCheck = contractorsToCheck.filter(contractor => contractor.Id !== contractorId)
		}

		const result = contractorsToCheck.some(contractor => {
			const normalizedNIP = normalizeNIP(contractor.NIP)

			// Check if both normalized NIP values are non-empty before comparison
			if (normalizedNIP !== '' || newContractorNIPNormalized !== '') {
				return normalizedNIP === newContractorNIPNormalized
			}

			return false
		})

		if (result)
			enqueueSnackbar(
				`${t('Contractor.AddDialog.NIPErrorMessageP1')} ${newContractorNIP} ${t('Contractor.AddDialog.NIPErrorMessageP2')}`,
				{
					variant: 'error',
					autoHideDuration: 8000,
				}
			)

		return result
	}

	return { checkIfContractorAlreadyExists }
}

export default useCheckContractor
