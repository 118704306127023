import { MutableRefObject, useState } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import { useUsersService } from 'services'

const useDataGridState = () => {
	const [dataGridState, setDataGridState] = useState<GridInitialState | null>()
	const [dataGridStateLoading, setDataGridStateLoading] = useState(true)
	const { getAppState, updateAppState, showSuccessInfo } = useUsersService()

	const prepareStateObject = (stateObject: GridInitialState) => {
		const { pagination, filter, sorting, ...newStateObject } = stateObject

		return newStateObject
	}

	const getDataGridState = async (stateContextName: string) => {
		try {
			setDataGridStateLoading(true)
			const response = await getAppState(stateContextName)

			if (response[0].StateValue) {
				const parsedStateObject = JSON.parse(response[0].StateValue)

				setDataGridState(prepareStateObject(parsedStateObject))
			}
		} catch (err) {
			setDataGridState(null)
			console.error(err)
		}
		setDataGridStateLoading(false)
	}

	const updateDataGridState = async (stateContextName: string, state: any, reset?: boolean) => {
		const preparedStateObject = prepareStateObject(state)

		const stateValue = JSON.stringify({
			...preparedStateObject,
			preferencePanel: {
				open: false,
			},
		})

		try {
			await updateAppState(stateContextName, stateValue)

			if (!reset) showSuccessInfo('saved')
		} catch (err) {
			console.error(err)
		}
	}

	const handleSortColumns = (defaultColumns: any, orderedColumns: string[]) => {
		const sortedColumns = defaultColumns.sort((a: any, b: any) => {
			const indexA = orderedColumns.indexOf(a.field)
			const indexB = orderedColumns.indexOf(b.field)

			if (indexA === undefined || indexB === undefined) {
				// If either index is undefined, maintain the original order
				return defaultColumns.indexOf(a) - defaultColumns.indexOf(b)
			} else if (indexA === -1) {
				// If column A is not in the array, move it to the front
				return -1
			} else if (indexB === -1) {
				// If column B is not in the array, move it to the front
				return 1
			} else {
				// Otherwise, sort based on the order in the array
				return indexA - indexB
			}
		})

		return sortedColumns
	}

	const handleSaveStateSnapshot = (
		reset: boolean = false,
		apiRef: MutableRefObject<GridApiPremium>,
		initialState: GridInitialState,
		contextStateName: string
	) => {
		if (reset) {
			apiRef.current.restoreState(prepareStateObject(initialState))
		}

		const stateSnapshot = apiRef.current.exportState()

		updateDataGridState(contextStateName, stateSnapshot, reset)
	}

	const getSortedColumns = (columns: any) => {
		return dataGridState?.columns?.orderedFields
			? handleSortColumns(columns, dataGridState?.columns?.orderedFields)
			: columns
	}

	return {
		updateDataGridState,
		getDataGridState,
		dataGridState,
		dataGridStateLoading,
		handleSortColumns,
		handleSaveStateSnapshot,
		getSortedColumns,
	}
}

export default useDataGridState
