import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItem,
	ListItemText,
} from '@mui/material'

import NoData from 'components/shared/no-data-message/NoData'
import { IContractorsContactInfo } from 'models'

type Props = {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	contactsList: IContractorsContactInfo[]
	refreshData: () => void
	addContactFunction: (contextId: string, contactId: string) => void
	showSuccessInfo: (value: string) => void
	contextId: string
	setIsAddContactInfoDialogOpen: (value: boolean) => void
}

const AddContactDialog = ({
	isOpen,
	setIsOpen,
	contactsList,
	refreshData,
	addContactFunction,
	showSuccessInfo,
	contextId,
	setIsAddContactInfoDialogOpen,
}: Props) => {
	const { t } = useTranslation('dictionaries')

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleOpenAddContactInfoDialog = () => {
		setIsAddContactInfoDialogOpen(true)
	}

	const handleAddContact = useCallback(
		async (contactId: string) => {
			try {
				await addContactFunction(contextId, contactId)
				refreshData()
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
		},
		[addContactFunction, contextId]
	)

	return (
		<Dialog open={isOpen} fullWidth maxWidth={false}>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('Contractor.ContactInfoPerson.AddContactInfoPersonDialog.Title')}
				<Box>
					<Button sx={{ mr: '15px' }} color="primary" onClick={handleOpenAddContactInfoDialog} size="small">
						{t('general.AddNewContact', { ns: 'translation' })}
					</Button>
					<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				{contactsList.length ? (
					<List>
						{contactsList.map((el: any, index: number) => {
							return (
								<ListItem
									sx={{
										borderBottom: '1px solid',
										borderColor: 'action.disabled',
										'&:last-child': {
											border: 'none',
										},
									}}
									key={index}
									secondaryAction={
										<IconButton edge="end" onClick={() => handleAddContact(el.Id)}>
											<AddIcon sx={{ fontSize: '20px' }} color="primary" />
										</IconButton>
									}>
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.Name} />
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.PhoneNumber || ''}`} />
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.Email || ''}`} />
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.Comments || ''}`} />
								</ListItem>
							)
						})}
					</List>
				) : (
					<NoData fontSize="18px" />
				)}
			</DialogContent>
		</Dialog>
	)
}

export default AddContactDialog
