import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IInvestorRepresentative } from 'models'

const useInvestorRepresentativesService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getInvestorRepresentatives = useCallback(async (projectId: string) => {
		const response = await request(`/api/ProjectInvestorParticipant?$filter=ProjectId eq ${projectId}`)
		return response
	}, [])

	const addInvestorRepresentative = useCallback(async (body: IInvestorRepresentative) => {
		const response = await request(`/odata/ProjectInvestorParticipant`, 'POST', body)
		return response
	}, [])

	const updateInvestorRepresentative = useCallback(async (representativeId: string, body: IInvestorRepresentative) => {
		await request(`/odata/ProjectInvestorParticipant/${representativeId}`, 'PATCH', body)
	}, [])

	const deleteInvestorRepresentative = useCallback(async (representativeId: string) => {
		const response = await request(`/api/ProjectInvestorParticipant/${representativeId}`, 'DELETE')
		return response
	}, [])

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getInvestorRepresentatives,
		addInvestorRepresentative,
		updateInvestorRepresentative,
		deleteInvestorRepresentative,
	}
}

export default useInvestorRepresentativesService
