import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridSelectionModel, GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ContractIcon, OrderIcon } from 'assets'
import {
	ColumnsButton,
	CreateDocumentFromEntries,
	ExpandCollapseButtons,
	SwitchTreeData,
	TooltipButton,
} from 'components/shared'
import { useAppSelector } from 'features'
import { useHandleDocumentActions } from 'hooks'
import useDataGridState from 'hooks/UseDataGridState'
import { Action, IOfferEntry } from 'models'
import { useOfferService } from 'services'

import EntryStatusDialog from './EntryStatusDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	isActionButtonDisabled: boolean
	isTreeData: boolean
	setIsTreeData: (value: boolean) => void
	selectionModel: GridSelectionModel
	getEntriesData: () => void
	entriesData: IOfferEntry[]
}

const OfferEntriesToolbar = ({
	apiRef,
	isActionButtonDisabled,
	isTreeData,
	setIsTreeData,
	selectionModel,
	getEntriesData,
	entriesData,
}: Props) => {
	const [isCreateDocumentDialogOpen, setIsCreateDocumentDialogOpen] = useState(false)
	const [selectedAction, setSelectedAction] = useState<Action>()
	const [documentToCreateType, setDocumentToCreateType] = useState<'Contract' | 'Order' | 'GoodsReceivedNote'>(
		'Contract'
	)
	const [isEntryStatusDialogOpen, setIsEntryStatusDialogOpen] = useState(false)
	const [createDocumentDialogWarning, setCreateDocumentDialogWarning] = useState<string | null>(null)

	const { documentId } = useAppSelector(state => state.documentData)

	const { actionsList } = useAppSelector(state => state.documentData)

	const { invokeAction } = useOfferService()
	const { handleInvokeAction } = useHandleDocumentActions(invokeAction, getEntriesData)
	const { handleSaveStateSnapshot } = useDataGridState()

	const { t } = useTranslation('offer')

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	const handleCreateDocument = (documentType: 'Contract' | 'Order') => {
		const actionType = documentType === 'Contract' ? 'GenerateContractFromOffer' : 'GenerateOrderFromOffer'
		setDocumentToCreateType(documentType)

		const action = actionsList.find(el => el.Name === actionType) as Action
		setSelectedAction(action)

		setIsCreateDocumentDialogOpen(true)
	}

	const checkOfferStatus = () => {
		return entriesData.some(item => selectionModel.includes(item.Id) && item.OfferStatus !== 'Accepted')
	}

	useEffect(() => {
		const isAnyEntryNotAccepted = checkOfferStatus()
		setCreateDocumentDialogWarning(
			isAnyEntryNotAccepted ? t('OfferEntries.Warnings.NotAllSelectedEntriesAccepted') : null
		)
	}, [isCreateDocumentDialogOpen])

	return (
		<>
			<GridToolbarContainer className="flex items-center justify-between m-1 py-2">
				<div>
					<ColumnsButton />
					<ExpandCollapseButtons apiRef={apiRef} isActionButtonDisabled={isActionButtonDisabled} />
				</div>
				<div className="flex items-center">
					<TooltipButton
						className="p-2"
						title="OfferEntries.EntryStatusDialog.Title"
						translationFile="offer"
						onClick={() => setIsEntryStatusDialogOpen(true)}
						IconComponent={PublishedWithChangesIcon}
						disabled={!selectionModel.length || isActionButtonDisabled}
					/>
					<TooltipButton
						className="p-2"
						title="general.GenerateContractFromSelectedEntries"
						onClick={() => handleCreateDocument('Contract')}
						IconComponent={ContractIcon}
						disabled={!selectionModel.length}
					/>
					<TooltipButton
						className="p-2"
						title="general.GenerateOrderFromSelectedEntries"
						onClick={() => handleCreateDocument('Order')}
						IconComponent={OrderIcon}
						disabled={!selectionModel.length}
					/>
					<SwitchTreeData
						isActionButtonDisabled={isActionButtonDisabled}
						isTreeData={isTreeData}
						setIsTreeData={setIsTreeData}
					/>
					<TooltipButton
						title="general.ResetDataGridState"
						onClick={() => handleUpdateState(true)}
						IconComponent={RotateLeftIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.SaveDataGridState"
						onClick={() => handleUpdateState()}
						IconComponent={FactCheckOutlinedIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.Filter"
						onClick={handleFilter}
						IconComponent={FilterListIcon}
						disabled={isActionButtonDisabled}
						fontSize="medium"
					/>
				</div>
			</GridToolbarContainer>
			{isCreateDocumentDialogOpen && (
				<CreateDocumentFromEntries
					isOpen={isCreateDocumentDialogOpen}
					setIsOpen={setIsCreateDocumentDialogOpen}
					documentToCreateType={documentToCreateType}
					entriesKey="offerEntries"
					selectedEntries={selectionModel}
					invokeAction={handleInvokeAction}
					action={selectedAction as Action}
					warning={createDocumentDialogWarning}
				/>
			)}
			{isEntryStatusDialogOpen && (
				<EntryStatusDialog
					offerId={documentId}
					isOpen={isEntryStatusDialogOpen}
					setIsOpen={setIsEntryStatusDialogOpen}
					selectedEntries={selectionModel}
					updateData={getEntriesData}
				/>
			)}
		</>
	)
}

export default OfferEntriesToolbar
