import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	Stack,
	TextField,
} from '@mui/material'

import { IOrderEntry } from 'models'
import { useOrderEntriesService } from 'services'
import prepareTitleString from 'utils/prepareTitleString'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	entryData: IOrderEntry
	refreshEntries: () => void
}

const SeparateRemainingDialog = ({ isOpen, setIsOpen, entryData, refreshEntries }: Props) => {
	const [suffix, setSuffix] = useState('')

	const { t } = useTranslation()

	const { separateRemainig } = useOrderEntriesService()

	const closeDialog = () => {
		setIsOpen(false)
		setSuffix('')
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSuffix(e.target.value)
	}

	const handleActionConfirm = async () => {
		if (entryData.Id)
			try {
				await separateRemainig(entryData.Id, suffix)
				refreshEntries()
				closeDialog()
			} catch (err) {
				console.error(err)
			}
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				<Stack sx={{ pr: '16px' }}>
					{t('SeparateRemainingDialog.SeparateRemainig', { ns: 'order' })}{' '}
					{entryData.TreePath && ` - ${entryData.TreePath} `}
					{entryData.Name && prepareTitleString(entryData.Name, 85)}
				</Stack>
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<FormControl fullWidth>
					<TextField
						sx={{ mt: '10px' }}
						value={suffix}
						name="suffix"
						label={t('SeparateRemainingDialog.SuffixInput', { ns: 'order' })}
						onChange={handleInputChange}
						fullWidth
					/>
				</FormControl>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.Cancel')}
				</Button>
				<Button variant="contained" onClick={handleActionConfirm} color="success">
					{t('general.Confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SeparateRemainingDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
