import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, InputHint } from 'components/shared'
import { useAppSelector } from 'features'
import { useAutocompleteInputActions } from 'features/actions'
import { IMasterCategory, IMasterDocumentNumerator, IMasterRegistry, IProjectModel } from 'models'
import { useMasterCategoryService, useMasterDocumentNumeratorService, useMasterRegistryService } from 'services'

import MasterConfiguration from './MasterConfiguration'

interface Props {
	formData?: IProjectModel | null
	onInputChange: (e: any) => void
	onAutocompleteChange: (newValue: any, valueFieldName: string, labelFieldName: string) => void
	fieldErrorsList: string[]
	readOnly: boolean
	projectId: string
}

const ErpData = ({ formData, onInputChange, onAutocompleteChange, fieldErrorsList, readOnly, projectId }: Props) => {
	const [masterCategories, setMasterCategories] = useState<IMasterCategory[]>([])
	const [masterRegistryList, setMasterRegistryList] = useState<IMasterRegistry[]>([])
	const [masterDocumentNumeratorList, setMasterDocumentNumeratorList] = useState<IMasterDocumentNumerator[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()

	const { getMasterCategoryList } = useMasterCategoryService()
	const { getMasterRegistryList } = useMasterRegistryService()
	const { getMasterDocumentNumeratorList } = useMasterDocumentNumeratorService()

	const { t } = useTranslation('project')

	const getMasterCategoriesData = async (instanceId: string) => {
		if (masterCategories.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterCategoryList()

				setMasterCategories(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getMasterRegistryData = async (instanceId: string) => {
		if (masterRegistryList.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterRegistryList()

				setMasterRegistryList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getMasterDocumentNumeratorData = async (instanceId: string) => {
		if (masterDocumentNumeratorList.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterDocumentNumeratorList()

				setMasterDocumentNumeratorList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	return (
		<Box>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Divider>{t('ContractSalesInvoice', { ns: 'routes' })}</Divider>
				</Grid>
				{/* MasterContractSalesInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterContractSalesInvoiceCategoryId || '',
						label: formData?.MasterContractSalesInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterContractSalesInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForContractSalesInvoice)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterContractSalesInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterContractSalesInvoiceCategoryId')}
					name="MasterContractSalesInvoiceCategoryId"
					labelFieldName="MasterContractSalesInvoiceCategoryIdLabel"
					hintTranslationPath="Project.MasterContractSalesInvoiceCategoryId"
				/>
				{/* MasterContractSalesInvoiceDocumentSeries */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					<TextField
						disabled={readOnly}
						value={formData?.MasterContractSalesInvoiceDocumentSeries || ''}
						id="project-field-MasterContractSalesInvoiceDocumentSeries"
						name="MasterContractSalesInvoiceDocumentSeries"
						label={t('ProjectFields.MasterContractSalesInvoiceDocumentSeries')}
						error={fieldErrorsList.includes('MasterContractSalesInvoiceDocumentSeries')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.MasterContractSalesInvoiceDocumentSeries" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Divider>{t('SalesInvoice', { ns: 'routes' })}</Divider>
				</Grid>
				{/* MasterSalesInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterSalesInvoiceCategoryId || '',
						label: formData?.MasterSalesInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterSalesInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForSalesInvoice)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterSalesInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterSalesInvoiceCategoryId')}
					name="MasterSalesInvoiceCategoryId"
					labelFieldName="MasterSalesInvoiceCategoryIdLabel"
					hintTranslationPath="Project.MasterSalesInvoiceCategoryId"
				/>
				{/* MasterSalesInvoiceDocumentSeries */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					<TextField
						disabled={readOnly}
						value={formData?.MasterSalesInvoiceDocumentSeries || ''}
						id="project-field-MasterSalesInvoiceDocumentSeries"
						name="MasterSalesInvoiceDocumentSeries"
						label={t('ProjectFields.MasterSalesInvoiceDocumentSeries')}
						error={fieldErrorsList.includes('MasterSalesInvoiceDocumentSeries')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.MasterSalesInvoiceDocumentSeries" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Divider>{t('CostInvoice', { ns: 'routes' })}</Divider>
				</Grid>
				{/* MasterCostInvoiceRegistryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCostInvoiceRegistryId || '',
						label: formData?.MasterCostInvoiceRegistryIdLabel || '',
					}}
					onOpen={() => getMasterRegistryData('ProjectFields.MasterCostInvoiceRegistryId')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCostInvoiceRegistryId'}
					inputLabel={t('ProjectFields.MasterCostInvoiceRegistryId')}
					name="MasterCostInvoiceRegistryId"
					labelFieldName="MasterCostInvoiceRegistryIdLabel"
					hintTranslationPath="Project.MasterCostInvoiceRegistryId"
				/>
				{/* MasterCostInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCostInvoiceCategoryId || '',
						label: formData?.MasterCostInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCostInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCostInvoiceCategory1)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCostInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterCostInvoiceCategoryId')}
					name="MasterCostInvoiceCategoryId"
					labelFieldName="MasterCostInvoiceCategoryIdLabel"
					hintTranslationPath="Project.MasterCostInvoiceCategoryId"
				/>
				{/* MasterCostInvoiceCategory2Id */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCostInvoiceCategory2Id || '',
						label: formData?.MasterCostInvoiceCategory2IdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCostInvoiceCategory2Id')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCostInvoiceCategory2)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCostInvoiceCategory2Id'}
					inputLabel={t('ProjectFields.MasterCostInvoiceCategory2Id')}
					name="MasterCostInvoiceCategory2Id"
					labelFieldName="MasterCostInvoiceCategory2IdLabel"
					hintTranslationPath="Project.MasterCostInvoiceCategory2Id"
				/>
				<Grid xs={12} sm={6} md={3} lg={3}>
					{/* Empty Item */}
				</Grid>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Divider>{t('MaterialInvoice', { ns: 'routes' })}</Divider>
				</Grid>
				{/* MasterMaterialInvoiceRegistryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterMaterialInvoiceRegistryId || '',
						label: formData?.MasterMaterialInvoiceRegistryIdLabel || '',
					}}
					onOpen={() => getMasterRegistryData('ProjectFields.MasterMaterialInvoiceRegistryId')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterMaterialInvoiceRegistryId'}
					inputLabel={t('ProjectFields.MasterMaterialInvoiceRegistryId')}
					name="MasterMaterialInvoiceRegistryId"
					labelFieldName="MasterMaterialInvoiceRegistryIdLabel"
					hintTranslationPath="Project.MasterMaterialInvoiceRegistryId"
				/>
				{/* MasterMaterialInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterMaterialInvoiceCategoryId || '',
						label: formData?.MasterMaterialInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterMaterialInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForMaterialInvoiceCategory1)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterMaterialInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterMaterialInvoiceCategoryId')}
					name="MasterMaterialInvoiceCategoryId"
					labelFieldName="MasterMaterialInvoiceCategoryIdLabel"
					hintTranslationPath="Project.MasterMaterialInvoiceCategoryId"
				/>
				{/* MasterMaterialInvoiceCategory2Id */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterMaterialInvoiceCategory2Id || '',
						label: formData?.MasterMaterialInvoiceCategory2IdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterMaterialInvoiceCategory2Id')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForMaterialInvoiceCategory2)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterMaterialInvoiceCategory2Id'}
					inputLabel={t('ProjectFields.MasterMaterialInvoiceCategory2Id')}
					name="MasterMaterialInvoiceCategory2Id"
					labelFieldName="MasterMaterialInvoiceCategory2IdLabel"
					hintTranslationPath="Project.MasterMaterialInvoiceCategory2Id"
				/>
				<Grid xs={12} sm={6} md={3} lg={3}>
					{/* Empty Item */}
				</Grid>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Divider>{t('CustomCostInvoice', { ns: 'routes' })}</Divider>
				</Grid>
				{/* MasterCustomCostInvoiceRegistryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCustomCostInvoiceRegistryId || '',
						label: formData?.MasterCustomCostInvoiceRegistryIdLabel || '',
					}}
					onOpen={() => getMasterRegistryData('ProjectFields.MasterCustomCostInvoiceRegistryId')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCustomCostInvoiceRegistryId'}
					inputLabel={t('ProjectFields.MasterCustomCostInvoiceRegistryId')}
					name="MasterCustomCostInvoiceRegistryId"
					labelFieldName="MasterCustomCostInvoiceRegistryIdLabel"
					hintTranslationPath="Project.MasterCustomCostInvoiceRegistryId"
				/>
				{/* MasterCustomCostInvoiceCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCustomCostInvoiceCategoryId || '',
						label: formData?.MasterCustomCostInvoiceCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCustomCostInvoiceCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCustomCostInvoiceCategory1)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCustomCostInvoiceCategoryId'}
					inputLabel={t('ProjectFields.MasterCustomCostInvoiceCategoryId')}
					name="MasterCustomCostInvoiceCategoryId"
					labelFieldName="MasterCustomCostInvoiceCategoryIdLabel"
					hintTranslationPath="Project.MasterCustomCostInvoiceCategoryId"
				/>
				{/* MasterCustomCostInvoiceCategory2Id */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCustomCostInvoiceCategory2Id || '',
						label: formData?.MasterCustomCostInvoiceCategory2IdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCustomCostInvoiceCategory2Id')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCustomCostInvoiceCategory2)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCustomCostInvoiceCategory2Id'}
					inputLabel={t('ProjectFields.MasterCustomCostInvoiceCategory2Id')}
					name="MasterCustomCostInvoiceCategory2Id"
					labelFieldName="MasterCustomCostInvoiceCategory2IdLabel"
					hintTranslationPath="Project.MasterCustomCostInvoiceCategory2Id"
				/>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Divider>{t('DebitNote', { ns: 'routes' })}</Divider>
				</Grid>
				{/* MasterDebitNoteRegistryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterDebitNoteRegistryId || '',
						label: formData?.MasterDebitNoteRegistryIdLabel || '',
					}}
					onOpen={() => getMasterRegistryData('ProjectFields.MasterDebitNoteRegistryId')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterDebitNoteRegistryId'}
					inputLabel={t('ProjectFields.MasterDebitNoteRegistryId')}
					name="MasterDebitNoteRegistryId"
					labelFieldName="MasterDebitNoteRegistryIdLabel"
					hintTranslationPath="Project.MasterDebitNoteRegistryId"
				/>
				{/* MasterDebitNoteCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterDebitNoteCategoryId || '',
						label: formData?.MasterDebitNoteCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterDebitNoteCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForDebitNoteCategory)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterDebitNoteCategoryId'}
					inputLabel={t('ProjectFields.MasterDebitNoteCategoryId')}
					name="MasterDebitNoteCategoryId"
					labelFieldName="MasterDebitNoteCategoryIdLabel"
					hintTranslationPath="Project.MasterDebitNoteCategoryId"
				/>
				{/* MasterDebitNoteDocumentSeries */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					<TextField
						disabled={readOnly}
						value={formData?.MasterDebitNoteDocumentSeries || ''}
						id="project-field-MasterDebitNoteDocumentSeries"
						name="MasterDebitNoteDocumentSeries"
						label={t('ProjectFields.MasterDebitNoteDocumentSeries')}
						error={fieldErrorsList.includes('MasterDebitNoteDocumentSeries')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.MasterDebitNoteDocumentSeries" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* MasterDebitNoteDocumentNumeratorId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterDebitNoteDocumentNumeratorId || '',
						label: formData?.MasterDebitNoteDocumentNumeratorIdLabel || '',
					}}
					onOpen={() => getMasterDocumentNumeratorData('ProjectFields.MasterDebitNoteDocumentNumeratorId')}
					onChange={onAutocompleteChange}
					options={masterDocumentNumeratorList.filter(num => num.ForDebitNote)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterDebitNoteDocumentNumeratorId'}
					inputLabel={t('ProjectFields.MasterDebitNoteDocumentNumeratorId')}
					name="MasterDebitNoteDocumentNumeratorId"
					labelFieldName="MasterDebitNoteDocumentNumeratorIdLabel"
					hintTranslationPath="Project.MasterDebitNoteDocumentNumeratorId"
				/>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Divider>{t('CreditNote', { ns: 'routes' })}</Divider>
				</Grid>
				{/* MasterCreditNoteRegistryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCreditNoteRegistryId || '',
						label: formData?.MasterCreditNoteRegistryIdLabel || '',
					}}
					onOpen={() => getMasterRegistryData('ProjectFields.MasterCreditNoteRegistryId')}
					onChange={onAutocompleteChange}
					options={masterRegistryList}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCreditNoteRegistryId'}
					inputLabel={t('ProjectFields.MasterCreditNoteRegistryId')}
					name="MasterCreditNoteRegistryId"
					labelFieldName="MasterCreditNoteRegistryIdLabel"
					hintTranslationPath="Project.MasterCreditNoteRegistryId"
				/>
				{/* MasterCreditNoteCategoryId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCreditNoteCategoryId || '',
						label: formData?.MasterCreditNoteCategoryIdLabel || '',
					}}
					onOpen={() => getMasterCategoriesData('ProjectFields.MasterCreditNoteCategoryId')}
					onChange={onAutocompleteChange}
					options={masterCategories.filter(cat => cat.ForCreditNoteCategory)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCreditNoteCategoryId'}
					inputLabel={t('ProjectFields.MasterCreditNoteCategoryId')}
					name="MasterCreditNoteCategoryId"
					labelFieldName="MasterCreditNoteCategoryIdLabel"
					hintTranslationPath="Project.MasterCreditNoteCategoryId"
				/>
				{/* MasterCreditNoteDocumentSeries */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					<TextField
						disabled={readOnly}
						value={formData?.MasterCreditNoteDocumentSeries || ''}
						id="project-field-MasterCreditNoteDocumentSeries"
						name="MasterCreditNoteDocumentSeries"
						label={t('ProjectFields.MasterCreditNoteDocumentSeries')}
						error={fieldErrorsList.includes('MasterCreditNoteDocumentSeries')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.MasterCreditNoteDocumentSeries" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* MasterCreditNoteDocumentNumeratorId */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
					initialValue={{
						value: formData?.MasterCreditNoteDocumentNumeratorId || '',
						label: formData?.MasterCreditNoteDocumentNumeratorIdLabel || '',
					}}
					onOpen={() => getMasterDocumentNumeratorData('ProjectFields.MasterCreditNoteDocumentNumeratorId')}
					onChange={onAutocompleteChange}
					options={masterDocumentNumeratorList.filter(num => num.ForCreditNote)}
					readOnly={readOnly}
					instanceId={'ProjectFields.MasterCreditNoteDocumentNumeratorId'}
					inputLabel={t('ProjectFields.MasterCreditNoteDocumentNumeratorId')}
					name="MasterCreditNoteDocumentNumeratorId"
					labelFieldName="MasterCreditNoteDocumentNumeratorIdLabel"
					hintTranslationPath="Project.MasterCreditNoteDocumentNumeratorId"
				/>
			</Grid>
			{projectId !== 'draft' && (
				<Box sx={{ mt: '15px' }}>
					<MasterConfiguration projectId={projectId} readOnly={readOnly} />
				</Box>
			)}
		</Box>
	)
}

export default ErpData
