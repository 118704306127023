import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import {
	DataGridPremium,
	GridCellParams,
	GridColumnHeaderParams,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { ColumnHeaderRender, TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { contractWarrantyType } from 'data/lookup-data-sources'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IContractWarranty } from 'models'
import { useContractWarrantyService } from 'services'
import { formatDateTimeMUIGrid } from 'utils/formatDateTime'

import WarrantyToolbar from './WarrantyToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	contractId: string
	reloadFormData: () => void
	readOnly: boolean
}

const Warranty = ({ contractId, reloadFormData, readOnly }: Props) => {
	const [warrantiesData, setWarrantiesData] = useState<IContractWarranty[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

	const [dataLoading, setDataLoading] = useState(true)

	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [selectedEntryId, setSelectedEntryId] = useState('')
	const [selectedEntryName, setSelectedEntryName] = useState('')

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['contract', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const {
		getContractWarranties,
		addContractWarrantyItem,
		updateContractWarrantyItem,
		deleteContractWarrantyItem,
		showSuccessInfo,
	} = useContractWarrantyService()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		if (readOnly) {
			return
		}

		setIsActionButtonDisabled(true)
		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = warrantiesData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setWarrantiesData(warrantiesData.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteContractWarrantyItem(id as string)
			setWarrantiesData(warrantiesData.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setSelectedEntryName(`${params.row.Description}`)
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addContractWarrantyItem({
					...newRow,
					ContractId: contractId,
				} as IContractWarranty)
				createdRow = response
				setWarrantiesData(warrantiesData.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getWarrantiesData()
				if (reloadFormData) {
					reloadFormData()
				}
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateContractWarrantyItem(newRow.Id, newRow as IContractWarranty)
				setWarrantiesData(warrantiesData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getWarrantiesData()
				if (reloadFormData) {
					reloadFormData()
				}
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'ContractWarrantyType',
			headerName: t('Warranty.ContractWarrantyType'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueOptions: () =>
				contractWarrantyType.map(el => ({
					value: el.value,
					label: t(`Warranty.ContractWarrantyTypeEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = contractWarrantyType.find((opt: any) => opt.value === value)
				if (option) {
					return t(`Warranty.ContractWarrantyTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.Warranty" />
			),
		},
		{
			field: 'Description',
			headerName: t('Warranty.Description'),
			headerAlign: 'center',
			align: 'center',
			editable: true,
			sortable: true,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.Warranty" />
			),
		},
		{
			field: 'LenghtInMonths',
			headerName: t('Warranty.LenghtInMonths'),
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.Warranty" />
			),
		},
		{
			field: 'DaysForDefectRemoval',
			headerName: t('Warranty.DaysForDefectRemoval'),
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.Warranty" />
			),
		},
		{
			field: 'PenaltyPerc',
			headerName: t('Warranty.PenaltyPerc'),
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.Warranty" />
			),
		},
		{
			field: 'EndDate',
			headerName: t('Warranty.EndDate'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			type: 'date',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}
				const valueFormatted = formatDateTimeMUIGrid(value)
				return valueFormatted
			},
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.Warranty" />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="ChangeHistory.ChangeHistory"
							onClick={handleShowHistory(params)}
							IconComponent={HistoryIcon}
							disabled={isActionButtonDisabled}
						/>,
						<TooltipButton
							title="general.Remove"
							onClick={handleDeleteClick(params.id)}
							IconComponent={DeleteIcon}
							disabled={isActionButtonDisabled || readOnly}
						/>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getWarrantiesData = useCallback(async () => {
		try {
			const response = await getContractWarranties(contractId)
			setWarrantiesData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [contractId])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getWarrantiesData()
	}, [contractId])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				autoHeight
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={warrantiesData}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				onCellDoubleClick={handleCellDoubleClick}
				experimentalFeatures={{ newEditingApi: true }}
				components={{
					Toolbar: WarrantyToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						isActionButtonDisabled,
						setIsActionButtonDisabled,
						setWarrantiesData,
						setRowModesModel,
						readOnly,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={selectedEntryName}
					translationFile="contract"
					translationPath="Warranty"
				/>
			)}
		</>
	)
}

export default Warranty
