import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { AutocompleteInput, InputHint } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IContactPerson, IContractorsContactInfo, IDebitNote } from 'models'
import { useContactInfoService, useContractorsService } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'

interface Props {
	formData: IDebitNote
}

const Settlements = ({ formData }: Props) => {
	const [users, setUsers] = useState([])
	const [contractorContacts, setContractorContacts] = useState([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('debit-note')

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const { handleInputChange, handleAutocompleteInputChange, fieldsError } = useFormDataChange()
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getPersons } = useContactInfoService()
	const { getContractorContactInfo } = useContractorsService()

	const getUsers = async (instanceId: string) => {
		if (users.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const allUsers = await getPersons()

				setUsers(
					allUsers.map((el: IContactPerson) => {
						const additionalInfo = el.Telephone && el.EMail ? `(${el.Telephone || ''} ${el.EMail || ''})` : ''

						return {
							Id: el.Id,
							Name: `${el.FirstName || ''} ${el.LastName || ''} ${additionalInfo}`,
						}
					})
				)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	const getContractorContacts = async (instanceId: string) => {
		if (contractorContacts.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const allContractorPersons = await getContractorContactInfo(formData.Contractor)

				setContractorContacts(
					allContractorPersons.map((el: IContractorsContactInfo) => {
						const additionalInfo = el.PhoneNumber && el.Email ? `(${el.PhoneNumber || ''} ${el.Email || ''})` : ''

						return {
							Id: el.Id,
							Name: `${el.Name || ''} ${additionalInfo}`,
						}
					})
				)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	return (
		<div className="w-full pb-4">
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* Value  */}
				<Grid xs={12} sm={6} md={6} lg={3}>
					{isVisible('Value') && (
						<TextField
							disabled={!isEditable('Value')}
							value={formData.Value || ''}
							name="Value"
							label={t('Fields.Value')}
							error={fieldsError.includes('Value')}
							onChange={handleInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="DebitNote.Value" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{!mobileView && (
					<Grid xs={12} sm={6} md={6} lg={9}>
						{/* Empty Item */}
					</Grid>
				)}
				{/* Incriminating */}
				{isVisible('Incriminating') && (
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 6, lg: 6 }}
						initialValue={{
							value: formData.Incriminating || '',
							label: formData.IncriminatingLabel || '',
						}}
						onOpen={() => getUsers('Fields.Incriminating')}
						onChange={handleAutocompleteInputChange}
						options={users}
						readOnly={!isEditable('Incriminating')}
						instanceId="Fields.Incriminating"
						inputLabel={t('Fields.Incriminating')}
						name="Incriminating"
						labelFieldName="IncriminatingLabel"
						hintTranslationPath="DebitNote.Incriminating"
					/>
				)}
				{/* Receiving */}
				{isVisible('Receiving') && (
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 6, lg: 6 }}
						initialValue={{
							value: formData.Receiving || '',
							label: formData.ReceivingLabel || '',
						}}
						onOpen={() => getContractorContacts('Fields.Receiving')}
						onChange={handleAutocompleteInputChange}
						options={contractorContacts}
						readOnly={!isEditable('Receiving')}
						instanceId="Fields.Receiving"
						inputLabel={t('Fields.Receiving')}
						name="Receiving"
						labelFieldName="ReceivingLabel"
						hintTranslationPath="DebitNote.Receiving"
					/>
				)}
				{/* ChargeReason */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					{isVisible('ChargeReason') && (
						<TextField
							disabled={!isEditable('ChargeReason')}
							value={formData.ChargeReason || ''}
							name="ChargeReason"
							label={t('Fields.ChargeReason')}
							error={fieldsError.includes('ChargeReason')}
							onChange={handleInputChange}
							multiline
							minRows={4}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="DebitNote.ChargeReason" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Settlements
