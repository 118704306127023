import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	aggregation: {
		model: {
			ValueNetto: 'sum',
			ValueNettoPLN: 'sum',
			ValueVat: 'sum',
			ValueVatPLN: 'sum',
			ValueBrutto: 'sum',
			ValueBruttoPLN: 'sum',
		},
	},
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'Name',
			'MeasurementUnitId',
			'CurrencyId',
			'Amount',
			'PriceNetto',
			'PriceNettoPLN',
			'VatRateId',
			'ValueNetto',
			'ValueNettoPLN',
			'ValueVat',
			'ValueVatPLN',
			'ValueBrutto',
			'ValueBruttoPLN',
			'ArticleType',
			'Deductions',
			'MasterCategoryId',
			'MasterCategory2Id',
			'actions',
		],
		dimensions: {
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			CurrencyId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			PriceNetto: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			PriceNettoPLN: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			VatRateId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueNetto: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueNettoPLN: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueVat: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueVatPLN: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueBrutto: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueBruttoPLN: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ArticleType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Deductions: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MasterCategoryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MasterCategory2Id: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 100,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'customCostInvoiceEntriesDataGridState'
