import { MutableRefObject } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import { useAppSelector } from 'features'
import _ from 'lodash'

import { defaultPageSize } from '../pagination'

type Props = {
	contextName: string
	getData: (data: GridInitialState) => void
	apiRef: MutableRefObject<GridApiPremium>
}

const useDataGridFiltration = ({ contextName, getData, apiRef }: Props) => {
	const { previousFilterState } = useAppSelector(state => state.previousFilterStateData)

	const handleResetFilters = () => {
		sessionStorage.setItem(`${contextName}_FILTER`, JSON.stringify(initialFilters.filter))
		sessionStorage.setItem(`${contextName}_SORTING`, JSON.stringify(initialFilters.sorting))
		sessionStorage.setItem(`${contextName}_PAGINATION`, JSON.stringify(initialFilters.pagination))

		apiRef.current.setFilterModel({
			items: [],
			quickFilterValues: [],
		})
		apiRef.current.setSortModel([])
		getData(initialFilters)
	}

	const areFilterStatesEqual = () => {
		const { filter } = apiRef.current.exportState()

		return _.isEqual(previousFilterState, filter)
	}

	const getFilteredData = (reset?: boolean) => {
		if (reset) {
			handleResetFilters()
		} else {
			const { sorting, filter, pagination } = apiRef.current.exportState()

			const sessionStoragePagination = sessionStorage.getItem(`${contextName}_PAGINATION`)
			const paginationObject = sessionStoragePagination ? JSON.parse(sessionStoragePagination) : pagination

			getData({ sorting, filter, pagination: paginationObject })
		}
	}

	const handleFilter = () => {
		const { filter } = apiRef.current.exportState()

		if (areFilterStatesEqual()) return

		sessionStorage.setItem(`${contextName}_FILTER`, JSON.stringify(filter))
		getFilteredData()
	}

	const handleSort = (handlePageChange: (value: number, updateData: boolean) => void) => {
		const { sorting } = apiRef.current.exportState()
		const sessionStorageSorting = sessionStorage.getItem(`${contextName}_SORTING`)

		sessionStorage.setItem(`${contextName}_SORTING`, JSON.stringify(sorting))

		if (JSON.stringify(sorting) !== sessionStorageSorting) {
			handlePageChange(0, false)
			getFilteredData()
		}
	}

	return { getFilteredData, handleFilter, handleSort, areFilterStatesEqual }
}

export default useDataGridFiltration

const initialFilters = {
	sorting: {
		sortModel: [],
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	pagination: {
		pageSize: defaultPageSize,
		page: 0,
	},
}
