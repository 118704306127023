import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import DownloadIcon from '@mui/icons-material/Download'
import ReplayIcon from '@mui/icons-material/Replay'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { CircularProgress, IconButton, Tooltip, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'
import { useAppSelector, useDocumentDataActions } from 'features'
import { IDocumentData } from 'models'
import { useAttachmentsService, useEmailParametersService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'
import { formatDateTime } from 'utils/formatDateTime'

import styles from './Styles.module.css'
import { checkIsFileAvailableForPreview, getFileExtension, getFileType } from './utils'

interface Props {
	name: string
	attachmentId: string
	updateAttachments: React.DispatchWithoutAction
	markedToDelete: null | string
	contextId: string
	isForInquiries?: boolean
	workflowId?: string
	project?: boolean
}

const SingleFileAttachment = ({
	name,
	attachmentId,
	updateAttachments,
	markedToDelete,
	contextId,
	isForInquiries,
	workflowId,
	project,
}: Props) => {
	const [filePreparing, setFilePreparing] = useState(false)
	const [isPreviewButtonVisible, setIsPreviewButtonVisible] = useState(false)

	const { documentData } = useAppSelector(state => state.documentData)

	const isDefault = documentData?.DefaultAttachmentId === attachmentId

	const { t } = useTranslation(['attachments', 'translation'])

	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('md'))

	const { updateWidthState, updateSelectedFile, updateIsOpen, addFile, files } = useContext(AttachmentsPreviewContext)
	const { setDocumentData } = useDocumentDataActions()
	const { showSuccessInfo, undeleteFile, downloadFile, deleteFile, setAsDefaultAttachment } = useAttachmentsService()

	const { addAttachment } = useEmailParametersService()

	const checkIsPreviewButtonVisible = () => {
		const checkExtension = checkIsFileAvailableForPreview(name)

		if (checkExtension) {
			setIsPreviewButtonVisible(true)
		}
	}

	const handleSetAsDefault = async () => {
		try {
			await setAsDefaultAttachment(contextId, documentData?.WorkflowId as string, attachmentId)
			updateAttachments()
			showSuccessInfo('saved')
			setDocumentData({ ...(documentData as IDocumentData), DefaultAttachmentId: attachmentId })
		} catch (err) {
			console.error(err)
		}
	}

	const handleDeleteAttachment = useCallback(async () => {
		try {
			await deleteFile(attachmentId)
			updateAttachments()
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}, [contextId, attachmentId])

	const handleUndeleteAttachment = useCallback(async () => {
		try {
			await undeleteFile(attachmentId)
			updateAttachments()
			showSuccessInfo('saved')
		} catch (err) {
			console.error(err)
		}
	}, [contextId, attachmentId])

	const downloadAttachment = useCallback(async () => {
		try {
			setFilePreparing(true)
			await downloadFile(name, attachmentId)
		} catch (err) {
			console.error(err)
		}
		setFilePreparing(false)
	}, [contextId, attachmentId])

	const showAttachment = useCallback(() => {
		const isFileOpened = files.some(item => item.id === attachmentId)
		if (isFileOpened) {
			const index = files.findIndex((item: any) => item.id === attachmentId)
			updateSelectedFile(index)
		} else {
			const fileExtension = getFileExtension(name)

			addFile({
				id: attachmentId,
				fileName: name,
				fileExtension: fileExtension,
				fileType: getFileType(fileExtension),
			})
			updateSelectedFile(files.length)
		}
		updateIsOpen(true)
		updateWidthState('fullWidth')
	}, [files, attachmentId, name])

	const handleAttach = async () => {
		try {
			await addAttachment(contextId, workflowId as string, attachmentId)
			showSuccessInfo('custom', t('AttachedFileInfo'))
		} catch (err) {
			console.error(err)
		}
		setFilePreparing(false)
	}

	useEffect(() => {
		checkIsPreviewButtonVisible()
	}, [attachmentId, name])

	return (
		<li className={styles.attachmentItem}>
			<div className={styles.fileContainer}>
				<DescriptionIcon sx={{ margin: '0 5px 0 22px', fontSize: '15px' }} />
				<p className={markedToDelete ? styles.markedToDelete : styles.attachmentName}>{name}</p>
				{markedToDelete && (
					<p className={styles.deleteDate}>
						{t('MarkedToDeleteInfo')} {formatDateTime(markedToDelete)}
					</p>
				)}
			</div>
			<div className={styles.buttonsContainer}>
				{markedToDelete ? (
					<Tooltip title={<TooltipTextWrapper title={t('RestoreButton')} />} placement="top" arrow>
						<span>
							<IconButton sx={{ padding: '5px' }} color="primary" onClick={handleUndeleteAttachment}>
								<ReplayIcon fontSize="small" />
							</IconButton>
						</span>
					</Tooltip>
				) : (
					<>
						{isForInquiries && (
							<Tooltip title={<TooltipTextWrapper title={t('Attach')} />} placement="top" arrow>
								<span>
									<IconButton sx={{ padding: '5px' }} color="primary" onClick={handleAttach}>
										<AttachEmailIcon fontSize="small" />
									</IconButton>
								</span>
							</Tooltip>
						)}
						{!isForInquiries && laptopViewAndUp && isPreviewButtonVisible && (
							<>
								{!project && (
									<Tooltip
										title={<TooltipTextWrapper title={isDefault ? t('IsDefault') : t('SetAsDefault')} />}
										placement="top"
										arrow>
										<span>
											<IconButton
												sx={{ padding: '5px' }}
												color={isDefault ? 'success' : 'primary'}
												onClick={handleSetAsDefault}
												disabled={isDefault}>
												{isDefault ? (
													<CheckCircleIcon color="success" fontSize="small" />
												) : (
													<CheckCircleOutlineIcon color="disabled" fontSize="small" />
												)}
											</IconButton>
										</span>
									</Tooltip>
								)}
								<Tooltip title={<TooltipTextWrapper title={t('PreviewAttachment')} />} placement="top" arrow>
									<span>
										<IconButton
											sx={{ padding: '5px' }}
											color="primary"
											onClick={showAttachment}
											disabled={filePreparing}>
											<VisibilityIcon fontSize="small" />
										</IconButton>
									</span>
								</Tooltip>
							</>
						)}
						{filePreparing ? (
							<CircularProgress size={25} sx={{ padding: '5px' }} />
						) : (
							<Tooltip title={<TooltipTextWrapper title={t('DownloadAttachment')} />} placement="top" arrow>
								<span>
									<IconButton sx={{ padding: '5px' }} color="primary" onClick={downloadAttachment}>
										<DownloadIcon fontSize="small" />
									</IconButton>
								</span>
							</Tooltip>
						)}
						<Tooltip
							title={
								<TooltipTextWrapper title={isDefault ? t('CannotDeleteDefaultAttachment') : t('DeleteAttachment')} />
							}
							placement="top"
							arrow>
							<span>
								<IconButton
									sx={{ padding: '5px' }}
									color="primary"
									onClick={handleDeleteAttachment}
									disabled={filePreparing || isDefault}>
									<DeleteIcon fontSize="small" />
								</IconButton>
							</span>
						</Tooltip>
					</>
				)}
			</div>
		</li>
	)
}

export default SingleFileAttachment
