import { Radio, TableCell, TableRow } from '@mui/material'

import { IStageFieldsPermission } from 'models'

interface Props {
	permission: IStageFieldsPermission
	handleDataChange: (e: any, fieldName: string) => void
	index: number
}

const SinglePermission = ({ permission, handleDataChange, index }: Props) => {
	const { Access, Property, IsReadonly, ShowProperty } = permission

	return (
		<TableRow
			sx={{
				'&:last-child td, &:last-child th': { borderBottom: 0 },
				backgroundColor: index % 2 === 0 ? 'background.secondary' : 'inherit',
			}}>
			<TableCell component="th" scope="row">
				{ShowProperty}
			</TableCell>
			<TableCell align="center" sx={sxStyles.tableCell}>
				<Radio
					sx={sxStyles.radio}
					checked={Access === 'None'}
					onChange={e => handleDataChange(e, Property)}
					value="None"
					size="small"
				/>
			</TableCell>
			<TableCell align="center" sx={sxStyles.tableCell}>
				<Radio
					sx={sxStyles.radio}
					checked={Access === 'Read'}
					onChange={e => handleDataChange(e, Property)}
					value="Read"
					size="small"
				/>
			</TableCell>
			<TableCell align="center" sx={sxStyles.tableCell}>
				{IsReadonly ? null : (
					<Radio
						sx={sxStyles.radio}
						checked={Access === 'ReadWrite'}
						onChange={e => handleDataChange(e, Property)}
						value="ReadWrite"
						size="small"
					/>
				)}
			</TableCell>
		</TableRow>
	)
}

export default SinglePermission

const sxStyles = {
	tableCell: {
		width: '70px',
	},
	radio: {
		p: '2px',
	},
}
