import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { ICostInvoice, IInvokeAction } from 'models'
import { COST_INVOICE_ENDPOINT } from 'services/data'

export interface getCostInvoiceListProps {
	isCanceled: boolean
	isCompleted: boolean
	contractId?: string
	processingProtocolId?: string
	projectId?: string
	incomingLetterId?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useCostInvoiceService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = COST_INVOICE_ENDPOINT

	const getFilteredCostInvoices = useCallback(
		async ({
			isCanceled,
			isCompleted,
			contractId,
			processingProtocolId,
			projectId,
			incomingLetterId,
			budgetEntryId,
			data,
		}: getCostInvoiceListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (processingProtocolId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&processingProtocolId=${processingProtocolId}`
			}

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (incomingLetterId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getCostInvoices = useCallback(
		async ({
			isCanceled,
			isCompleted,
			contractId,
			processingProtocolId,
			projectId,
			incomingLetterId,
		}: getCostInvoiceListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (processingProtocolId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&processingProtocolId=${processingProtocolId}`
			}

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (incomingLetterId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const createCostInvoice = useCallback(
		async (workflowId: string) => {
			const response = await request(`${path}?workflowId=${workflowId}`, 'POST')
			return response
		},
		[request]
	)

	const getCostInvoice = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updateCostInvoice = useCallback(
		async (id: string, data: ICostInvoice) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getCostInvoices,
		getCostInvoice,
		createCostInvoice,
		updateCostInvoice,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredCostInvoices,
	}
}

export default useCostInvoiceService
