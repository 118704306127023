import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useInvestorProcessingProtocolEntriesService = () => {
	const { request, downloadFileRequest, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/investorProcessingProtocolEntry'

	const getEntries = useCallback(
		async (protocolId: string) => {
			const response = await request(`${path}?protocolId=${protocolId}`)
			return response
		},
		[request]
	)

	const updateEntry = useCallback(
		async (entryId: string, data: any) => {
			await request(`${path}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	const exportExcel = useCallback(
		async (protocolId: string) => {
			const response = await downloadFileRequest(`/api/investorProcessingProtocol/exportToXlsx/${protocolId}`)
			return response
		},
		[downloadFileRequest]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getEntries,
		updateEntry,
		exportExcel,
	}
}

export default useInvestorProcessingProtocolEntriesService
