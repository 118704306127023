import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import { Tooltip } from '@mui/material'

import clsx from 'clsx'
import { TooltipButton } from 'components/shared/buttons'
import { IScheduleRow } from 'models'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'
import prepareTitleString from 'utils/prepareTitleString'

import { EntryPeriods } from './entry-periods'

type Props = {
	row: IScheduleRow
	documentType: string
	documentId: string
	refreshData: () => void
	readOnly: boolean
}

const ScheduleRow = ({ row, documentType, documentId, refreshData, readOnly }: Props) => {
	const [isEditing, setIsEditing] = useState(false)

	const sessionStorageKey = `SCHEDULE_ENTRIES_STATE_${row.ScheduleEntryId}`

	const entryLocalStorageState = sessionStorage.getItem(sessionStorageKey)

	const { t } = useTranslation()

	const handleRowEditMode = () => {
		setIsEditing(prev => !prev)

		sessionStorage.setItem(sessionStorageKey, JSON.stringify(!isEditing))
	}

	useEffect(() => {
		const rowState = entryLocalStorageState

		if (rowState && !readOnly) {
			setIsEditing(JSON.parse(rowState))
		}
	}, [])

	return (
		<div className="inline-flex flex-col min-w-full">
			<div
				className={clsx(
					'flex w-full h-10',
					'border-0 border-b border-dashed dark:border-b-border-darkDivider border-b-border-divider',
					{ 'text-gray-400 dark:text-gray-500': !row.AllowedPeriods }
				)}>
				<div
					className={clsx(
						'flex w-[766px] sticky left-0 z-9',
						'bg-background-secondary dark:bg-background-darkDefault'
					)}>
					<div
						className={clsx(
							'w-9 h-10 py-2.5 flex items-center justify-center bg-background-secondary dark:bg-inherit',
							'border border-dashed dark:border-border-darkDivider border-border-divider border-l-0 border-t-0'
						)}>
						<TooltipButton
							disabled={!row.AllowedPeriods || readOnly}
							title={t(`${isEditing ? 'general.Close' : 'ScheduleTab.EditPeriods'}`)}
							IconComponent={isEditing ? CloseIcon : CalendarMonthIcon}
							onClick={handleRowEditMode}
						/>
					</div>
					<div
						className={clsx(
							'w-28 flex items-center justify-start px-2 bg-background-secondary dark:bg-inherit',
							'border-0 border-r border-dashed dark:border-r-border-darkDivider border-r-border-divider'
						)}>
						{row.GroupingColumn}
					</div>
					<div
						className={clsx(
							'w-[470px] flex items-center justify-start px-2 bg-background-secondary dark:bg-inherit',
							'border-0 border-r border-dashed dark:border-r-border-darkDivider border-r-border-divider'
						)}>
						{
							<Tooltip
								disableHoverListener={row.Name?.length < 65}
								title={<TooltipTextWrapper title={row.Name} />}
								placement="top"
								arrow>
								<span>{prepareTitleString(row.Name, 65)}</span>
							</Tooltip>
						}
					</div>
					<div
						className={clsx(
							'w-36 flex items-center justify-end px-2 bg-background-secondary dark:bg-inherit',
							'border-0 border-r border-dashed dark:border-r-border-darkDivider border-r-border-divider'
						)}>
						{row.CalculationError ? (
							<Tooltip title={<TooltipTextWrapper title={row.CalculationError} />} placement="top" arrow>
								<div className="flex items-center text-error-main">
									<ErrorIcon className="mr-1 text-base" color="error" />
									{formatNumberDataGrid(row.Value) || ''}
								</div>
							</Tooltip>
						) : (
							formatNumberDataGrid(row.Value) || ''
						)}
					</div>
				</div>
				<div className="flex-grow flex items-center justify-start px-2 bg-background-secondary dark:bg-inherit">
					{row.Columns.map((column: any, index: number) => (
						<div key={index} className="w-40 py-2.5 flex flex-grow items-center justify-center">
							<div
								className={clsx(
									'w-full h-full flex items-center justify-center rounded-full mx-4 text-xs text-text-darkPrimary',
									row.Columns[index + 1] != null && 'mr-0 rounded-r-none',
									row.Columns[index - 1] != null && 'ml-0 rounded-l-none',
									{
										'bg-indigo-500': column != null && row.AllowedPeriods,
										'bg-gray-200 dark:bg-zinc-700': column != null && !row.AllowedPeriods,
										'text-gray-400 dark:text-gray-500': !row.AllowedPeriods,
									}
								)}>
								{formatNumberDataGrid(column)}
							</div>
						</div>
					))}
				</div>
			</div>
			{isEditing && (
				<EntryPeriods
					documentId={documentId}
					documentType={documentType}
					entryData={row}
					setIsEditing={setIsEditing}
					refreshData={refreshData}
					sessionStorageKey={sessionStorageKey}
					readOnly={readOnly}
				/>
			)}
		</div>
	)
}

export default ScheduleRow
