import { Pagination } from '@mui/material'
import { GridPagination } from '@mui/x-data-grid-premium'

import clsx from 'clsx'

type Props = {
	pagesCount: number
	page: number
	handlePageChange: (newPage: number) => void
	isLoading: boolean
}

const CustomFooter = ({ pagesCount, page, handlePageChange, isLoading }: Props) => {
	return (
		<div
			className={clsx(
				'w-full flex items-center justify-between',
				'border-solid border-x-0 border-b-0 border-t border-t-border-divider dark:border-t-border-darkDivider'
			)}>
			<div>
				{pagesCount > 1 && (
					<Pagination
						className="pl-2"
						page={page + 1}
						count={pagesCount}
						onChange={(e, newPage) => handlePageChange(newPage - 1)}
						hidePrevButton
						hideNextButton
						disabled={isLoading}
						color="primary"
					/>
				)}
			</div>
			<GridPagination {...({} as any)} disabled={isLoading} />
		</div>
	)
}

export default CustomFooter
