import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const DebitNoteIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M5.482,5.207h11.153c0.346,0,0.627-0.28,0.627-0.626c0-0.346-0.281-0.626-0.627-0.626H5.482
		c-0.346,0-0.626,0.281-0.626,0.626C4.856,4.926,5.137,5.207,5.482,5.207z"
			/>
			<path
				fill="currentColor"
				d="M5.482,8.707h7.81c0.347,0,0.627-0.28,0.627-0.626c0-0.346-0.28-0.626-0.627-0.626h-7.81
		c-0.346,0-0.626,0.28-0.626,0.626C4.856,8.427,5.137,8.707,5.482,8.707z"
			/>
			<path
				fill="currentColor"
				d="M9.206,11.582c0-0.346-0.28-0.626-0.627-0.626H5.482c-0.346,0-0.626,0.28-0.626,0.626
		s0.28,0.626,0.626,0.626h3.097C8.926,12.208,9.206,11.928,9.206,11.582z"
			/>
			<path
				fill="currentColor"
				d="M22.439,7.32l-1.981-1.982V1.926C20.458,0.864,19.594,0,18.532,0H4.883C3.821,0,2.957,0.864,2.957,1.926
		v20.148C2.957,23.137,3.821,24,4.883,24h13.649c1.062,0,1.926-0.863,1.926-1.926V10.187l0.327-0.327l1.654-1.654
		c0.117-0.117,0.184-0.277,0.184-0.442C22.623,7.597,22.557,7.438,22.439,7.32z M19.205,22.074c0,0.371-0.303,0.674-0.673,0.674
		H4.883c-0.371,0-0.673-0.303-0.673-0.674V1.926c0-0.371,0.302-0.673,0.673-0.673h13.649c0.37,0,0.673,0.302,0.673,0.673v3.329
		l-1.514,1.513l0,0l0,0L6.865,17.596c-0.104,0.104-0.169,0.242-0.181,0.389l-0.205,2.412c-0.016,0.185,0.05,0.365,0.181,0.496
		c0.118,0.117,0.277,0.185,0.442,0.185c0.018,0,0.035-0.002,0.053-0.004l2.411-0.203c0.147-0.013,0.285-0.076,0.391-0.183
		l9.248-9.248V22.074z M19.457,9.417L9.233,19.641l-1.444,0.121l0.123-1.443L18.135,8.096L19.457,9.417z M20.342,8.532l-1.32-1.322
		l0.77-0.769l1.32,1.322L20.342,8.532z"
			/>
		</SvgIcon>
	)
}

export default DebitNoteIcon
