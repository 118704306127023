import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IMasterConfigurationSalesInvoice } from 'models'

const useMasterConfigurationSalesInvoiceService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/projectMasterConfigurationSalesInvoiceEntry'

	const getList = useCallback(
		async (projectId: string) => {
			const response = await request(`${path}?projectId=${projectId}`)
			return response
		},
		[request]
	)

	const addEntry = useCallback(
		async (data: IMasterConfigurationSalesInvoice) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateEntry = useCallback(
		async (entryId: string, data: IMasterConfigurationSalesInvoice) => {
			await request(`${path}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteEntry = useCallback(
		async (entryId: string) => {
			const response = await request(`${path}/${entryId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getList,
		addEntry,
		updateEntry,
		deleteEntry,
	}
}

export default useMasterConfigurationSalesInvoiceService
