import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IIncomingLetter, IInvokeAction } from 'models'
import { INCOMING_LETTER_ENDPOINT } from 'services/data'

const useIncomingLetterService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = INCOMING_LETTER_ENDPOINT

	const getFilteredIncomingLetters = useCallback(
		async (isCanceled: boolean, isCompleted: boolean, data?: GridInitialState) => {
			const response = await request(
				`${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`,
				'POST',
				data
			)
			return response
		},
		[request]
	)

	const getIncomingLetters = useCallback(
		async (isCanceled: boolean, isCompleted: boolean) => {
			const response = await request(`${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`)
			return response
		},
		[request]
	)

	const getIncomingLetter = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const createIncomingLetter = useCallback(
		async (workflowId: string) => {
			const response = await request(`${path}?workflowId=${workflowId}`, 'POST')
			return response
		},
		[request]
	)

	const updateIncomingLetter = useCallback(
		async (id: string, data: IIncomingLetter) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		errorStatus,
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getIncomingLetters,
		getIncomingLetter,
		createIncomingLetter,
		updateIncomingLetter,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredIncomingLetters,
	}
}

export default useIncomingLetterService
