import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, IconButton } from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { useAppSelector, useCalendarActions } from 'features'
import useLanguage from 'hooks/UseLanguge'

import ListSection from './ListSection'
import Month from './Month'

const Calendar = () => {
	const [monthGrid, setMonthGrid] = useState<Dayjs[][]>([])

	const { selectedMonthIndex, selectedDate, selectedYear } = useAppSelector(state => state.calendar)

	const { datePickerLanguage } = useLanguage()
	const { setSelectedDate, setGridRowsCount, setCalendarDataRange } = useCalendarActions()

	const { t } = useTranslation()

	const getCalendarGrid = (monthIndex: number = selectedMonthIndex) => {
		const firstDayOfTheMonthIndex = dayjs(new Date(selectedYear, monthIndex, 0)).day()
		const daysInSelectedMonth = dayjs(selectedDate).daysInMonth()

		const gridLength = daysInSelectedMonth + firstDayOfTheMonthIndex
		const rowsCount = gridLength > 35 ? 6 : 5

		setGridRowsCount(rowsCount)

		let counter = 0 - firstDayOfTheMonthIndex

		const daysGrid = new Array(rowsCount).fill([]).map(() => {
			return new Array(7).fill(null).map(() => {
				counter++
				return dayjs(new Date(selectedYear, monthIndex, counter))
			})
		})

		const firstDateInRange = daysGrid[0][0].format()
		const lastDateInRange = daysGrid[rowsCount - 1][daysGrid[rowsCount - 1].length - 1].format()

		setCalendarDataRange({ dateFrom: firstDateInRange, dateTo: lastDateInRange })
		setMonthGrid(daysGrid)
	}

	const handleSelectNextMonth = () => {
		setSelectedDate(dayjs(new Date(selectedYear, selectedMonthIndex + 1, 1)).format())
	}

	const handleSelectPreviousMonth = () => {
		setSelectedDate(dayjs(new Date(selectedYear, selectedMonthIndex - 1, 1)).format())
	}

	const handleResetCalendar = () => {
		setSelectedDate(dayjs().format())
	}

	const handleMiniCalendarChange = (date: Dayjs | null) => {
		if (date) setSelectedDate(date.format())
	}

	useEffect(() => {
		getCalendarGrid()
	}, [selectedDate])

	const currentMonthTitle = dayjs(selectedDate).locale(datePickerLanguage).format('MMMM YYYY')
	const currentMonthTitleCapitalized = currentMonthTitle.charAt(0).toUpperCase() + currentMonthTitle.slice(1)

	const today = useMemo(() => dayjs(), [])

	return (
		<div
			className={clsx(
				'flex flex-col h-[calc(100vh-160px)] min-h-[620px]',
				'border border-solid border-border-divider dark:border-border-darkDivider rounded'
			)}>
			<div
				className={clsx(
					'h-14 flex items-center p-3',
					'border-0 border-b border-solid border-b-border-divider dark:border-b-border-darkDivider'
				)}>
				<div className="min-w-72 hidden 2xl:block">
					<Button color="inherit" onClick={handleResetCalendar}>
						{t('Calendar.Today')}
					</Button>
				</div>
				<div className="w-full flex items-center justify-center">
					<IconButton color="inherit" onClick={handleSelectPreviousMonth}>
						<ChevronLeftIcon fontSize="medium" />
					</IconButton>
					<span className={clsx('min-w-44 text-center text-xl px-2')}>{currentMonthTitleCapitalized}</span>
					<IconButton color="inherit" onClick={handleSelectNextMonth}>
						<ChevronRightIcon fontSize="medium" />
					</IconButton>
				</div>
				<div className="min-w-72 xl:min-w-96 hidden lg:block"></div>
			</div>
			<div className={clsx('h-full flex')}>
				<div
					className={clsx(
						'min-w-72 p-2 hidden 2xl:block',
						'border-0 border-r border-solid border-r-border-divider dark:border-r-border-darkDivider'
					)}>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={datePickerLanguage}>
						<DateCalendar
							className={clsx('w-[270px]')}
							defaultValue={today}
							value={dayjs(selectedDate)}
							onChange={handleMiniCalendarChange}
							reduceAnimations
						/>
					</LocalizationProvider>
				</div>
				<div className={clsx('w-full h-full')}>
					<Month currentMonth={monthGrid} />
				</div>
				<div
					className={clsx(
						'min-w-72 w-72 xl:min-w-96 xl:w-96 hidden lg:block',
						'border-0 border-l border-solid border-l-border-divider dark:border-l-border-darkDivider'
					)}>
					<ListSection />
				</div>
			</div>
		</div>
	)
}

export default Calendar
