import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'

import { useHandleNavigate } from 'hooks'
import { useCommonTaskService, useWorkflowService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
}

const AddCommonTaskDialog = ({ isOpen, setIsOpen }: Props) => {
	const [workflowList, setWorkflowList] = useState([])
	const [selectedWorkflowId, setSelectedWorkflowId] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation('common-task')

	const { addCommonTask } = useCommonTaskService()
	const { getWorkflowLookupList } = useWorkflowService()
	const { handleNavigate } = useHandleNavigate()

	const handleCloseDialog = () => {
		setIsOpen(false)
	}

	const handleCreateDocument = async () => {
		try {
			setButtonLoading(true)
			const response = await addCommonTask(selectedWorkflowId)

			if (response) handleNavigate(response, '/commonTask')
		} catch (err) {
			console.error(err)
		} finally {
			setButtonLoading(false)
		}
	}

	const getWorkflowData = async () => {
		try {
			const response = await getWorkflowLookupList('commonTask')

			setWorkflowList(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getWorkflowData()
	}, [])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title}>
				{t('CreateCommonTaskDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={selectedWorkflowId}
					select
					name="Workflow"
					label={t('CreateCommonTaskDialog.WorkflowSelectLabel')}
					onChange={e => setSelectedWorkflowId(e.target.value)}
					fullWidth
					sx={{ mt: '10px' }}>
					{workflowList.map((el: any) => (
						<MenuItem key={el.label} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					loading={buttonLoading}
					disabled={!selectedWorkflowId}
					onClick={handleCreateDocument}>
					{t('general.Create', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddCommonTaskDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
