import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'

const SideSwitcher = () => {
	const { attachmentsPreviewSide, updateSide } = useContext(AttachmentsPreviewContext)

	const { t } = useTranslation('navigation')

	const handleChangeSide = (event: React.MouseEvent<HTMLElement>, newSide: string) => {
		updateSide(newSide)
	}

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'space-around',
				justifyContent: 'center',
				flexDirection: 'column',
			}}>
			<Typography
				component="span"
				fontSize={'10px'}
				fontWeight={'500'}
				sx={{ pb: '5px', textTransform: 'uppercase', textAlign: 'center' }}>
				{t('user-menu.AttachmentsPreviewPosition')}
			</Typography>
			<ToggleButtonGroup
				value={attachmentsPreviewSide}
				onChange={handleChangeSide}
				color="primary"
				exclusive
				size="small"
				fullWidth>
				<ToggleButton value="left">{t('user-menu.LeftSide')}</ToggleButton>
				<ToggleButton value="right">{t('user-menu.RightSide')}</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	)
}

export default SideSwitcher
