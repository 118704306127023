import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IAdvanceInvoice, IInvokeAction } from 'models'
import { ADVANCE_INVOICE_ENDPOINT } from 'services/data'

export interface getAdvanceInvoiceListProps {
	isCanceled: boolean
	isCompleted: boolean
	contractId?: string
	orderId?: string
	data?: GridInitialState
}

const useAdvanceInvoiceService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = ADVANCE_INVOICE_ENDPOINT

	const getFilteredAdvanceInvoices = useCallback(
		async ({ isCanceled, isCompleted, contractId, orderId, data }: getAdvanceInvoiceListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (orderId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&orderId=${orderId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getAdvanceInvoices = useCallback(
		async ({ isCanceled, isCompleted, contractId, orderId }: getAdvanceInvoiceListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (orderId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&orderId=${orderId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getAdvanceInvoice = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updateAdvanceInvoice = useCallback(
		async (id: string, data: IAdvanceInvoice) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getAdvanceInvoices,
		getAdvanceInvoice,
		updateAdvanceInvoice,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredAdvanceInvoices,
	}
}

export default useAdvanceInvoiceService
