import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material'

type Props = {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	handleDeleteOwner: () => void
}

const ConfirmDeleteOwnerDialog = ({ isOpen, setIsOpen, handleDeleteOwner }: Props) => {
	const { t } = useTranslation()

	const closeDialog = () => {
		setIsOpen(false)
	}

	return (
		<Dialog open={isOpen} onClose={closeDialog} sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '50vh' } }}>
			<Stack sx={{ overflow: 'auto' }}>
				<DialogContent>
					<Typography sx={{ fontSize: '16px', margin: '10px', textAlign: 'center' }}>
						{t('DocumentWorkflowPage.ConfirmDeleteOwner')}
					</Typography>
				</DialogContent>
			</Stack>
			<DialogActions
				sx={{
					display: 'flex',
					justifyContent: 'center',
					padding: '0 25px 25px 0',
				}}>
				<Button variant="outlined" color="error" onClick={closeDialog} sx={{ mr: '10px' }}>
					{t('general.No')}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						handleDeleteOwner()
						closeDialog()
					}}
					color="success"
					sx={{ ml: '10px' }}>
					{t('general.Yes')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDeleteOwnerDialog
