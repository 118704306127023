import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { Box, Button, Divider, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { InputHint } from 'components/shared'
import Loading from 'components/shared/loading/Loading'
import { useAppSelector } from 'features'
import { useBusinessDataActions } from 'features'
import { useBusinessDataService } from 'services'

import ChangeLogoDialog from './ChangeLogoDialog'

const BusinessData = () => {
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [isChangeLogoDialogOpen, setIsChangeLogoDialogOpen] = useState(false)
	const [logoUrl, setLogoUrl] = useState('')

	const { clearError, fieldsError, showSuccessInfo, updateBusinessData, getBusinessData, getLogoURL, addCustomLogo } =
		useBusinessDataService()

	const { t } = useTranslation(['administration', 'translation'])

	const { businessData } = useAppSelector(state => state.businessData)
	const { setBusinessData } = useBusinessDataActions()

	const handleDataChange = useCallback(
		(e: any) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const value = e.target.value
			const fieldName = e.target.name

			if (fieldsError.includes(fieldName)) {
				clearError(fieldName)
			}
			setBusinessData({ ...businessData, [fieldName]: value ?? '' })
		},
		[fieldsError, clearError, businessData, setBusinessData, isSaveButtonDisabled]
	)

	const getData = useCallback(async () => {
		try {
			const response = await getBusinessData()
			setBusinessData(response)
		} catch (err) {
			console.error(err)
		}
	}, [getBusinessData, setBusinessData])

	const getLogo = async () => {
		try {
			const logoData = await getLogoURL()
			setLogoUrl(logoData?.url || '')
		} catch (err) {
			console.error(err)
		}
	}

	const removeLogo = async () => {
		try {
			await addCustomLogo(null)
			setLogoUrl('')
		} catch (err) {
			console.error(err)
		}
	}

	const handleDataSave = useCallback(async () => {
		if (businessData) {
			try {
				setButtonLoading(true)
				await updateBusinessData(businessData)
				getData()
				showSuccessInfo('saved')
				setIsSaveButtonDisabled(true)
			} catch (err) {
				console.error(err)
			}
		}
		setButtonLoading(false)
	}, [businessData, updateBusinessData, getData, showSuccessInfo])

	useEffect(() => {
		getLogo()
	}, [])

	if (!businessData) {
		return <Loading />
	}

	return (
		<>
			<Box sx={sxStyles.toolsContainer}>
				<LoadingButton
					variant="contained"
					disabled={isSaveButtonDisabled}
					onClick={handleDataSave}
					loading={buttonLoading}>
					{t('general.SaveButton', { ns: 'translation' })}
				</LoadingButton>
			</Box>
			<Box sx={sxStyles.formContainer}>
				<Box sx={sxStyles.row}>
					<Typography fontSize={'18px'} sx={sxStyles.title}>
						{t('BusinessData.BasicInformation')}
					</Typography>
					<Divider />
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						{/* Name  */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Name || ''}
								name="Name"
								label={t('BusinessData.Fields.Name')}
								error={fieldsError.includes('Name')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* NIP  */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Nip || ''}
								name="Nip"
								label={t('BusinessData.Fields.Nip')}
								error={fieldsError.includes('Nip')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* Regon  */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Regon || ''}
								name="Regon"
								label={t('BusinessData.Fields.Regon')}
								error={fieldsError.includes('Regon')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* PhoneNumber */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.PhoneNumber || ''}
								name="PhoneNumber"
								label={t('BusinessData.Fields.PhoneNumber')}
								error={fieldsError.includes('PhoneNumber')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* Email */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Email || ''}
								name="Email"
								label={t('BusinessData.Fields.Email')}
								error={fieldsError.includes('Email')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
				<Box sx={sxStyles.row}>
					<Typography fontSize={'18px'} sx={sxStyles.title}>
						{t('BusinessData.Address')}
					</Typography>
					<Divider />
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						{/* City */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Address_City}
								name="Address_City"
								label={t('AddressFields.City', { ns: 'translation' })}
								error={fieldsError.includes('Address_City')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* Street */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Address_Street}
								name="Address_Street"
								label={t('AddressFields.Street', { ns: 'translation' })}
								error={fieldsError.includes('Address_Street')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* StreetNumber */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Address_StreetNumber}
								name="Address_StreetNumber"
								label={t('AddressFields.StreetNumber', { ns: 'translation' })}
								error={fieldsError.includes('Address_StreetNumber')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* FlatNumber */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Address_FlatNumber}
								name="Address_FlatNumber"
								label={t('AddressFields.FlatNumber', { ns: 'translation' })}
								error={fieldsError.includes('Address_FlatNumber')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* PostalCode */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Address_PostalCode}
								name="Address_PostalCode"
								label={t('AddressFields.PostalCode', { ns: 'translation' })}
								error={fieldsError.includes('Address_PostalCode')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
						{/* Post */}
						<Grid xs={12} sm={12} md={6} lg={4}>
							<TextField
								disabled={false}
								value={businessData?.Address_Post}
								name="Address_Post"
								label={t('AddressFields.Post', { ns: 'translation' })}
								error={fieldsError.includes('Address_Post')}
								onChange={handleDataChange}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
				<Box sx={sxStyles.row}>
					<div className="flex items-center p-1 text-lg">
						{t('BusinessData.CompanyLogo')}{' '}
						<span className="ml-2">
							<InputHint translationPath="Administration.BusinessData.CompanyLogo" />
						</span>
					</div>
					<Divider />
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						<Grid xs={12} sm={12} md={6} lg={4}>
							<div className="flex items-center">
								<span className="mr-4 flex flex-col">
									<Button variant="contained" onClick={() => setIsChangeLogoDialogOpen(true)}>
										{t('AddAttachmentDialog.ChooseFile', { ns: 'attachments' })}
									</Button>
									{logoUrl && (
										<Button className="mt-3" variant="text" onClick={removeLogo}>
											{t('general.RemoveLogo', { ns: 'translation' })}
										</Button>
									)}
								</span>
								<span className="flex items-center">
									{logoUrl && <img src={logoUrl} alt="Company logo" className="h-16 w-auto" />}
								</span>
								{isChangeLogoDialogOpen && (
									<ChangeLogoDialog
										isOpen={isChangeLogoDialogOpen}
										setIsOpen={setIsChangeLogoDialogOpen}
										getLogo={getLogo}
									/>
								)}
							</div>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	)
}

export default BusinessData

const sxStyles = {
	toolsContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '15px 15px 0 0',
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: '15px',
		pt: '0',
	},
	row: {
		width: '100%',
		pb: '15px',
	},
	title: {
		padding: '5px',
	},
}
