import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { GridFilterInitialState } from '@mui/x-data-grid-premium'

type InitialState = {
	previousFilterState: GridFilterInitialState | undefined
}

const initialState: InitialState = {
	previousFilterState: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
}

export const previousFilterStateData = createSlice({
	name: 'previousFilterStateData',
	initialState,
	reducers: {
		setPreviousFilterState: (state, action: PayloadAction<GridFilterInitialState | undefined>) => {
			state.previousFilterState = action.payload
		},
	},
})

export const previousFilterStateDataActions = previousFilterStateData.actions

export default previousFilterStateData.reducer
