import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Collapse, List, ListItemButton, ListItemText, MenuItem } from '@mui/material'

import { INavigationItem } from 'models'

interface Props {
	item: INavigationItem
	handleCloseMenu: () => void
}

const NavigationNestedMenu = ({ item, handleCloseMenu }: Props) => {
	const [open, setOpen] = useState(false)

	const toggleMenuList = () => {
		setOpen(!open)
	}

	const navigate = useNavigate()

	const handleItemClick = (link: string) => {
		handleCloseMenu()
		navigate(link)
	}

	return (
		<>
			<MenuItem key={item.id} sx={sxStyles.menuItemContainer} onClick={toggleMenuList}>
				<Box sx={sxStyles.menuItem}>
					{item.icon} {item.text}
				</Box>
				{open ? <ExpandLess /> : <ExpandMore />}
			</MenuItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding dense={true}>
					{item.childItems?.map(el => {
						return el.visible ? (
							<ListItemButton key={el.id} sx={{ pl: '40px' }} onClick={() => handleItemClick(el.linkPath as string)}>
								<ListItemText primaryTypographyProps={{ fontSize: '15px' }} primary={el.text} />
							</ListItemButton>
						) : null
					})}
				</List>
			</Collapse>
		</>
	)
}

export default NavigationNestedMenu

const sxStyles = {
	menuItemContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	menuItem: { display: 'flex', alignItems: 'center' },
}
