import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'

import { enqueueSnackbar } from 'notistack'
import { useBusinessDataService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	getLogo: () => void
}

const ChangeLogoDialog = ({ isOpen, setIsOpen, getLogo }: Props) => {
	const [selectedFile, setSelectedFile] = useState<File | null>()
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { addCustomLogo } = useBusinessDataService()

	const handleCloseDialog = () => {
		setIsOpen(false)
		setSelectedFile(null)
		getLogo()
	}

	const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		const fileMaxSizeInBytes = 100 * 1024 * 1024 // 100 MB

		if (files && files.length) {
			const isFileValid = files[0].size <= fileMaxSizeInBytes
			if (!isFileValid) {
				enqueueSnackbar(t('general.FileSizeError', { ns: 'translation' }), {
					variant: 'error',
					autoHideDuration: 8000,
				})
			}
			setSelectedFile(files[0])
		}
	}

	const handleSubmitFile = async () => {
		if (selectedFile) {
			try {
				setButtonLoading(true)
				const formData = new FormData()
				formData.append('File', selectedFile)
				await addCustomLogo(formData)

				handleCloseDialog()
			} catch (error) {
				console.error(error)
			} finally {
				setButtonLoading(false)
			}
		}
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('BusinessData.ChangeLgoDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={sxStyles.content}>
				<Button variant="contained" color="primary" component="label" endIcon={<CloudUploadIcon />} sx={{ mb: '8px' }}>
					{t('AddAttachmentDialog.ChooseFile', { ns: 'attachments' })}
					<input type="file" name="file" hidden onChange={handleFileSelect} accept="image/*" />
				</Button>
				{selectedFile && <span>{selectedFile.name}</span>}
			</DialogContent>
			<DialogActions sx={sxStyles.actions}>
				<LoadingButton
					variant="contained"
					onClick={handleSubmitFile}
					color="success"
					disabled={!selectedFile}
					loading={buttonLoading}>
					{t('general.Add', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default ChangeLogoDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '25px',
	},
	actions: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '25px',
	},
}
