import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IContractWarranty } from 'models'

const useContractWarrantyService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const getContractWarranties = useCallback(
		async (contractId: string) => {
			const response = await request(`/api/contractWarranty/${contractId}`)
			return response
		},
		[request]
	)

	const addContractWarrantyItem = useCallback(
		async (data: IContractWarranty) => {
			const response = await request(`/api/contractWarranty`, 'POST', data)
			return response
		},
		[request]
	)

	const updateContractWarrantyItem = useCallback(
		async (warrantyItemId: string, body: IContractWarranty) => {
			await request(`/api/contractWarranty/${warrantyItemId}`, 'PATCH', body)
		},
		[request]
	)

	const deleteContractWarrantyItem = useCallback(
		async (warrantyItemId: string) => {
			const response = await request(`/api/contractWarranty/${warrantyItemId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		error,
		clearError,
		showSuccessInfo,
		getContractWarranties,
		addContractWarrantyItem,
		updateContractWarrantyItem,
		deleteContractWarrantyItem,
	}
}

export default useContractWarrantyService
