import { MutableRefObject } from 'react'

import { gridPaginatedVisibleSortedGridRowEntriesSelector } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

const useDataGridRowFocus = (rows: any, apiRef: MutableRefObject<GridApiPremium>) => {
	const handleFocusOnSelectedRow = (selectedRowId: string, expandAllRows?: boolean) => {
		const expandParentAndScroll = (id: string) => {
			const parent = rows.find((row: any) => row.Id === id)

			if (parent) {
				apiRef.current.setRowChildrenExpansion(parent.Id, true)
				if (parent.ParentId !== null) {
					expandParentAndScroll(parent.ParentId)
				} else {
					if (expandAllRows) {
						const allRowIds = apiRef.current.getAllRowIds()

						allRowIds.forEach(rowId => {
							apiRef.current.setRowChildrenExpansion(rowId, true)
						})
					}

					const visibleRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef)
					const rowIndex = visibleRows.findIndex(row => row.id === selectedRowId)

					const indexToScrollTo = visibleRows.length > rowIndex + 5 ? rowIndex + 5 : visibleRows.length - 1

					setTimeout(() => {
						apiRef.current.scrollToIndexes({ rowIndex: indexToScrollTo })
						apiRef.current.selectRow(selectedRowId)
					}, 100)
				}
			}
		}

		expandParentAndScroll(selectedRowId)
	}

	return { handleFocusOnSelectedRow }
}

export default useDataGridRowFocus
