import { useTranslation } from 'react-i18next'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material'

import NoData from 'components/shared/no-data-message/NoData'
import { ISessionLog } from 'models'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'
import { formatDateTime } from 'utils/formatDateTime'
import { getCurrentTheme } from 'utils/themes'

interface Props {
	logs: ISessionLog[]
}

const SessionLogs = ({ logs }: Props) => {
	const theme = getCurrentTheme()

	const { t } = useTranslation(['synchronization', 'translation'])

	const getLogTypeColor = (type: string) => {
		switch (type) {
			case 'Info':
				return 'info.main'
			case 'Error':
				return 'error.main'
			case 'Warning':
				return 'warning.main'

			default:
				return 'text.secondary'
		}
	}

	return (
		<Box sx={sxStyles.logsContainer}>
			{logs.length > 0 ? (
				logs.map((el, index) => {
					return (
						<Box key={index}>
							<Tooltip
								title={
									<TooltipTextWrapper
										title={t(`SessionLogFields.LogTypeEnums.${el.LogType}`, {
											defaultValue: `${el.LogType}`,
										})}
									/>
								}
								placement="left"
								arrow>
								<Box sx={sxStyles.singleLog}>
									<Box sx={sxStyles.type}>
										<FiberManualRecordIcon
											sx={{
												...sxStyles.typeIcon,
												color: getLogTypeColor(el.LogType),
											}}
										/>
									</Box>
									<Box
										sx={{
											...sxStyles.descriptionContainer,
											color: theme === 'light' ? '#78909c' : '#c5e1a5',
										}}
										component={'div'}>
										<Typography
											sx={{
												fontSize: '14px',
												p: '2px',
												textAlign: 'left',
											}}
											component={'div'}>
											<Stack component={'span'} sx={{ display: 'inline-block', fontSize: '13px' }}>
												{`${formatDateTime(el.DateTime as string)} - 
								${el.Description}`}
											</Stack>
										</Typography>
										{index !== logs.length - 1 && <Divider />}
									</Box>
								</Box>
							</Tooltip>
						</Box>
					)
				})
			) : (
				<NoData fontSize="14px" />
			)}
		</Box>
	)
}

export default SessionLogs

const sxStyles = {
	logsContainer: {
		width: '90%',
		backgroundColor: 'background.secondary',
		border: '1px solid',
		borderColor: 'border.primary',
		borderRadius: '5px',
		p: '8px',
	},
	singleLog: { display: 'flex', p: '3px' },
	type: {
		minWidth: '30px',
	},
	typeIcon: { fontSize: '10px', mt: '7px', mr: '5px' },
	descriptionContainer: {
		width: '100%',
	},
}
