import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IDebitNote, IInvokeAction } from 'models'
import { DEBIT_NOTE_ENDPOINT } from 'services/data'

export interface getDebitNotesListProps {
	isCanceled: boolean
	isCompleted: boolean
	contractId?: string
	processingProtocolId?: string
	data?: GridInitialState
}

const useDebitNoteService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = DEBIT_NOTE_ENDPOINT

	const getFilteredDebitNotes = useCallback(
		async ({ isCanceled, isCompleted, contractId, processingProtocolId, data }: getDebitNotesListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (processingProtocolId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&processingProtocolId=${processingProtocolId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getListOfDebitNotes = useCallback(
		async ({ isCanceled, isCompleted, contractId, processingProtocolId }: getDebitNotesListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (processingProtocolId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&processingProtocolId=${processingProtocolId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getDebitNote = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const createDebitNote = useCallback(
		async (workflowId: string, data: IDebitNote) => {
			await request(`${path}?workflowId=${workflowId}`, 'POST', data)
		},
		[request]
	)

	const updateDebitNote = useCallback(
		async (id: string, data: IDebitNote) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		getListOfDebitNotes,
		getDebitNote,
		createDebitNote,
		updateDebitNote,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		fieldsError,
		showSuccessInfo,
		getFilteredDebitNotes,
	}
}

export default useDebitNoteService
