import { ChangeEventHandler } from 'react'

import { AutocompleteInputOption } from 'components'
import { useAppSelector, useBudgetEntryActions, useDocumentFormSaveButtonActions } from 'features'
import { useHttp } from 'hooks/http.hook'

const useBudgetEntryDataChange = () => {
	const { budgetEntryData } = useAppSelector(state => state.budgetEntryData)
	const { isSaveButtonDisabled } = useAppSelector(state => state.documentFormSaveButton)

	const { clearError, fieldsError } = useHttp()
	const { updateBudgetEntryData } = useBudgetEntryActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()

	const handleSaveButtonStateChange = (fieldName: string, value: any) => {
		if (value && (budgetEntryData as any)[fieldName] != value && isSaveButtonDisabled) {
			setIsSaveButtonDisabled(false)
		}
	}

	const handleErrorStateChange = (fieldName: string) => {
		if (fieldsError.includes(fieldName)) {
			clearError(fieldName)
		}
	}

	const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
		const target = e.target as HTMLInputElement

		const value = target.value
		const fieldName = target.name

		updateBudgetEntryData([{ fieldName, value }])

		handleSaveButtonStateChange(fieldName, value)
		handleErrorStateChange(fieldName)
	}

	const handleAutocompleteInputChange = (
		newValue: AutocompleteInputOption,
		valueFieldName: string,
		labelFieldName: string
	) => {
		updateBudgetEntryData([
			{ fieldName: valueFieldName, value: newValue.value },
			{ fieldName: labelFieldName, value: newValue.label },
		])

		handleSaveButtonStateChange(valueFieldName, newValue.value)
	}

	return { handleInputChange, handleAutocompleteInputChange, fieldsError }
}

export default useBudgetEntryDataChange
