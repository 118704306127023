import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'

import { useAppSelector, useDocumentActionsStateActions } from 'features'
import { Action } from 'models'

type Props = {
	isOpen: boolean
	action: Action
	invokeAction: (value: Action, parameters?: any) => void
}

const LinkDocumentDialog = ({ isOpen, action, invokeAction }: Props) => {
	const { copiedDocumentData } = useAppSelector(state => state.linkDocuments)
	const { linkDialogDocumentName } = useAppSelector(state => state.documentActionsState)

	const { setIsLinkDocumentDialogOpen } = useDocumentActionsStateActions()

	const { t } = useTranslation()

	const getDocumentTypeParams = (type: string) => {
		switch (type) {
			case 'ProcessingProtocol':
				return 'processingProtocolId'
			case 'GoodsReceivedNote':
				return 'goodsReceivedNoteId'
			case 'ProformaInvoice':
				return 'contractProformaInvoiceId'

			default:
				return 'processingProtocolId'
		}
	}

	const closeDialog = () => {
		setIsLinkDocumentDialogOpen(false)
	}

	const handleLink = async () => {
		const paramKey = getDocumentTypeParams(copiedDocumentData.documentType)

		let parameters = {
			[paramKey]: copiedDocumentData.id,
		}

		invokeAction(action, parameters)
		closeDialog()
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('LinkDocumentDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography sx={sxStyles.content}>
					{t('LinkDocumentDialog.Info')}
					<Typography component={'span'} sx={sxStyles.itemName}>
						{copiedDocumentData.name}
					</Typography>
					{t('LinkDocumentDialog.With')}
					<Typography component={'span'} sx={sxStyles.itemName}>
						{linkDialogDocumentName}.
					</Typography>
					{t('LinkDocumentDialog.Question')}
				</Typography>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.No')}
				</Button>
				<Button variant="contained" onClick={handleLink} color="success">
					{t('general.Yes')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default LinkDocumentDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	content: { fontSize: '16px', margin: '10px', textAlign: 'center' },
	itemName: { fontWeight: 500, padding: '0 5px' },
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
