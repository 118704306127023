export const workflowDocumentType: Array<{
	label: string
	value: string
}> = [
	{ value: 'ContractSalesInvoice', label: 'ContractSalesInvoice' },
	{ value: 'Demand', label: 'Demand' },
	{ value: 'Offer', label: 'Offer' },
	{ value: 'Contract', label: 'Contract' },
	{ value: 'Order', label: 'Order' },
	{ value: 'ProcessingProtocol', label: 'ProcessingProtocol' },
	{ value: 'DebitNote', label: 'DebitNote' },
	{ value: 'CreditNote', label: 'CreditNote' },
	{ value: 'CostInvoice', label: 'CostInvoice' },
	{ value: 'GoodsReceivedNote', label: 'GoodsReceivedNote' },
	{ value: 'MaterialInvoice', label: 'MaterialInvoice' },
	{ value: 'ContractAdvanceInvoice', label: 'ContractAdvanceInvoice' },
	{ value: 'ContractProformaInvoice', label: 'ContractProformaInvoice' },
	{ value: 'InvestorProcessingProtocol', label: 'InvestorProcessingProtocol' },
	{ value: 'SalesInvoice', label: 'SalesInvoice' },
	{ value: 'CustomCostInvoice', label: 'CustomCostInvoice' },
	{ value: 'IncomingLetter', label: 'IncomingLetter' },
	{ value: 'CorrectionInvoice', label: 'CorrectionInvoice' },
	{ value: 'BalancingDocument', label: 'BalancingDocument' },
	{ value: 'CommonTask', label: 'CommonTask' },
]
