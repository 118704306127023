import { useState } from 'react'

import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridSelectionModel, GridToolbarContainer } from '@mui/x-data-grid-premium'

import { GoodsReceivedNoteIcon } from 'assets'
import {
	ColumnsButton,
	CreateDocumentFromEntries,
	ExpandCollapseButtons,
	SwitchTreeData,
	TooltipButton,
} from 'components/shared'
import { useAppSelector } from 'features'
import { useHandleDocumentActions } from 'hooks'
import useDataGridState from 'hooks/UseDataGridState'
import { Action } from 'models'
import { useOrderService } from 'services'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	isActionButtonDisabled: boolean
	isTreeData: boolean
	setIsTreeData: (value: boolean) => void
	selectionModel: GridSelectionModel
	getData: () => void
}

const OrderEntriesToolbar = ({
	apiRef,
	isActionButtonDisabled,
	setIsTreeData,
	isTreeData,
	selectionModel,
	getData,
}: Props) => {
	const [isCreateDocumentDialogOpen, setIsCreateDocumentDialogOpen] = useState(false)

	const { actionsList } = useAppSelector(state => state.documentData)

	const { invokeAction } = useOrderService()
	const { handleInvokeAction } = useHandleDocumentActions(invokeAction, getData)
	const { handleSaveStateSnapshot } = useDataGridState()

	const action = actionsList.find(el => el.Name === 'GenerateGoodsReceivedNoteFromOrder') as Action

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<>
			<GridToolbarContainer className="flex justify-between m-1">
				<div>
					<ColumnsButton />
					<ExpandCollapseButtons apiRef={apiRef} isActionButtonDisabled={isActionButtonDisabled} />
				</div>
				<div>
					<TooltipButton
						className="p-2"
						title="general.GenerateGRNFromSelectedEntries"
						onClick={() => setIsCreateDocumentDialogOpen(true)}
						IconComponent={GoodsReceivedNoteIcon}
						disabled={!selectionModel.length}
					/>
					<SwitchTreeData
						isActionButtonDisabled={isActionButtonDisabled}
						isTreeData={isTreeData}
						setIsTreeData={setIsTreeData}
					/>
					<TooltipButton
						title="general.ResetDataGridState"
						onClick={() => handleUpdateState(true)}
						IconComponent={RotateLeftIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.SaveDataGridState"
						onClick={() => handleUpdateState()}
						IconComponent={FactCheckOutlinedIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.Filter"
						onClick={handleFilter}
						IconComponent={FilterListIcon}
						fontSize="medium"
						disabled={isActionButtonDisabled}
					/>
				</div>
			</GridToolbarContainer>
			{isCreateDocumentDialogOpen && (
				<CreateDocumentFromEntries
					isOpen={isCreateDocumentDialogOpen}
					setIsOpen={setIsCreateDocumentDialogOpen}
					documentToCreateType="GoodsReceivedNote"
					entriesKey="orderEntries"
					selectedEntries={selectionModel}
					invokeAction={handleInvokeAction}
					action={action}
				/>
			)}
		</>
	)
}

export default OrderEntriesToolbar
