import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IContractCostParameters } from 'models'

const useCostParametersService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/contractCostParameter'

	const getCostParameters = useCallback(
		async (contractId: string) => {
			const response = await request(`${path}/${contractId}`)
			return response
		},
		[request]
	)

	const addCostParameter = useCallback(
		async (data: IContractCostParameters) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateCostParameter = useCallback(
		async (id: string, body: IContractCostParameters) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deleteCostParameter = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`, 'DELETE')
			return response
		},
		[request]
	)

	const linkToBudgetEntry = useCallback(
		async (entryId: string, budgetEntryId: string) => {
			await request(`${path}/linkToBudget/${entryId}?budgetEntryId=${budgetEntryId}`, 'PATCH')
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getCostParameters,
		addCostParameter,
		updateCostParameter,
		deleteCostParameter,
		linkToBudgetEntry,
	}
}

export default useCostParametersService
