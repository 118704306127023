import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { ICurrency } from 'models'

const useCurrencyService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getCurrency = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const addCurrency = useCallback(
		async (data: ICurrency) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateCurrency = useCallback(
		async (id: string, body: ICurrency) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deleteCurrency = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	const path = '/api/currency'

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getCurrency,
		addCurrency,
		updateCurrency,
		deleteCurrency,
	}
}

export default useCurrencyService
