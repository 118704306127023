import { useEffect, useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'

import { IPermission } from 'models'

import PermissionsList from './PermissionsList'

interface Props {
	groupNames: string[]
	permissions: IPermission[]
	roleId: string
}

const RolePermissionGroups = ({ groupNames, permissions, roleId }: Props) => {
	const [renderingData, setRenderingData] = useState<any[]>([])
	const [expanded, setExpanded] = useState<string | false>(false)

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false)
	}

	useEffect(() => {
		const prepareRenderingData = groupNames.map(el => {
			const groupPermissions = permissions.filter(item => {
				return item.Group === el
			})

			return { groupName: el, permissions: [...groupPermissions] }
		})

		setRenderingData(prepareRenderingData)
	}, [groupNames, permissions])

	return (
		<>
			{renderingData.map(el => {
				return (
					<Accordion
						key={el.groupName}
						expanded={expanded === `group-${el.groupName}`}
						onChange={handleChange(`group-${el.groupName}`)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} id={`group-${el.groupName}-header`}>
							<Typography color="primary">{el.groupName}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<PermissionsList permissions={el.permissions} roleId={roleId} />
						</AccordionDetails>
					</Accordion>
				)
			})}
		</>
	)
}

export default RolePermissionGroups
