import { FetchError } from './FetchError'
import { IApiResponse, IApiTypedResponse } from './IApiResponse'
import { ODataError } from './ODataError'

export abstract class ApiBase {
	protected async CallT<T>(endpoint: string, method: string = 'GET', payload?: any): Promise<IApiTypedResponse<T>> {
		const response = await fetch(`${endpoint}`, {
			method: method,
			headers: payload
				? {
						'Content-Type': 'application/json',
					}
				: undefined,
			body: payload ? JSON.stringify(payload) : undefined,
		})

		if (!response.ok) {
			throw new FetchError(`'${method}' call on endpoint '${endpoint}' returned status '${response.status}'`, response)
		}

		const bodyText = await response.text()
		let body: any

		try {
			body = JSON.parse(bodyText)
		} catch {
			body = bodyText
		}

		return { status: response.status, data: body }
	}

	protected async Call(endpoint: string, method: string = 'GET', payload?: any): Promise<IApiResponse> {
		const response = await fetch(`${endpoint}`, {
			method: method,
			headers: payload
				? {
						'Content-Type': 'application/json',
					}
				: undefined,
			body: payload ? JSON.stringify(payload) : undefined,
		})

		if (!response.ok) {
			throw new FetchError(`'${method}' call on endpoint '${endpoint}' returned status '${response.status}'`, response)
		}

		return { status: response.status }
	}

	protected async CallWithLoadOptions<T>(
		endpoint: string,
		method: string = 'GET',
		loadOptions?: IDataSourceLoadOptions
	): Promise<IDataSourceResponse<T>> {
		const response = await fetch(`${endpoint}`, {
			method: method,
			headers: loadOptions ? { 'Content-Type': 'application/json' } : undefined,
			body: loadOptions ? JSON.stringify(loadOptions) : undefined,
		})

		if (!response.ok) {
			throw new FetchError(`'${method}' call on endpoint '${endpoint}' returned status '${response.status}'`, response)
		}

		const bodyText = await response.text()
		const body = JSON.parse(bodyText)

		return {
			status: response.status,
			data: body.data,
			summary: body.summary,
			groupCount: body.groupCount,
			totalCount: body.totalCount,
		}
	}

	protected async CallODataCreate<T = any>(rosourceAddress: string, objectToCreate: T) {
		const options = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json;odata=verbose' },
			body: JSON.stringify(objectToCreate),
		}

		const response = await fetch(rosourceAddress, options)

		if (!response.ok) throw new ODataError(`OData: Failed to create resource on '${rosourceAddress}'`, response)

		return (await response.json()) as T
	}

	protected async ApiCall(endpoint: string, method: string = 'GET', payload?: any) {
		const response = await fetch(`${endpoint}`, {
			method: method,
			headers: payload
				? {
						'Content-Type': 'application/json',
					}
				: undefined,
			body: payload ? JSON.stringify(payload) : undefined,
		})

		if (!response.ok) {
			return response.json().then(err => {
				throw { response: response, error: err }
			})
		}

		const bodyText = await response.text()

		let body: any

		try {
			body = JSON.parse(bodyText)
		} catch {
			body = bodyText
		}

		return { status: response.status, data: body }
	}
}

export interface IDataSourceLoadOptions {}

export interface IDataSourceResponse<T> {
	status: number
	data: Array<T>
	summary: any
	groupCount: number
	totalCount: number
}
