import { useState } from 'react'

import { GridSelectionModel } from '@mui/x-data-grid-premium'

const useDataGridSelection = () => {
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const handleSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
		setSelectionModel(newSelectionModel)
	}

	return { selectionModel, handleSelectionModelChange }
}

export default useDataGridSelection
