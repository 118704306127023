import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IScheduleEntryPeriod } from 'models'

const useScheduleService = () => {
	const { request, downloadFileRequest, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/documentSchedule'

	const getSchedule = useCallback(
		async (workflowDataType: string, documentId: string) => {
			const response = await request(`${path}?workflowDataType=${workflowDataType}&documentId=${documentId}`)
			return response
		},
		[request]
	)

	const getEntryPeriods = useCallback(
		async (workflowDataType: string, documentId: string, scheduleEntryId: string) => {
			const response = await request(
				`${path}/entryPeriods?workflowDataType=${workflowDataType}&documentId=${documentId}&scheduleEntryId=${scheduleEntryId}`
			)
			return response
		},
		[request]
	)

	const addEntryPeriod = useCallback(
		async (workflowDataType: string, documentId: string, scheduleEntryId: string, data: IScheduleEntryPeriod) => {
			const response = await request(
				`${path}/entryPeriods?workflowDataType=${workflowDataType}&documentId=${documentId}&scheduleEntryId=${scheduleEntryId}`,
				'POST',
				data
			)
			return response
		},
		[request]
	)

	const updateEntryPeriod = useCallback(
		async (workflowDataType: string, documentId: string, periodId: string, data: IScheduleEntryPeriod) => {
			const response = await request(
				`${path}/entryPeriods?workflowDataType=${workflowDataType}&documentId=${documentId}&periodId=${periodId}`,
				'PATCH',
				data
			)
			return response
		},
		[request]
	)

	const deleteEntryPeriod = useCallback(
		async (workflowDataType: string, documentId: string, periodId: string) => {
			await request(
				`${path}/entryPeriods?workflowDataType=${workflowDataType}&documentId=${documentId}&periodId=${periodId}`,
				'DELETE'
			)
		},
		[request]
	)

	const exportScheduleToExcel = useCallback(async (workflowDataType: string, documentId: string) => {
		const response = await downloadFileRequest(
			`${path}/exportToExcel?workflowDataType=${workflowDataType}&documentId=${documentId}`
		)
		return response
	}, [])
	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getSchedule,
		getEntryPeriods,
		addEntryPeriod,
		updateEntryPeriod,
		deleteEntryPeriod,
		exportScheduleToExcel,
	}
}
export default useScheduleService
