import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import { DataGridPremium, GridRowId, GridRowParams, useGridApiRef } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import NoData from 'components/shared/no-data-message/NoData'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IContactPerson } from 'models'
import { useContactInfoService } from 'services'

import AddUserContactDialog from './AddUserContactDialog'
import UserContactPersonsToolbar from './UserContactPersonsToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	contextId: string
	readOnly: boolean
	getContactsFunction: (contextId: string) => Promise<string[]>
	addContactFunction: (contextId: string, contactId: string) => void
	deleteContactFunction: (contextId: string, contactId: string) => void
}

const UserContactPersons = ({
	contextId,
	readOnly,
	getContactsFunction,
	addContactFunction,
	deleteContactFunction,
}: Props) => {
	const [personsDataToRender, setPersonsDataToRender] = useState<IContactPerson[]>([])
	const [personsCanBeAdded, setPersonsCanBeAdded] = useState<IContactPerson[]>([])

	const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false)
	const [dataLoading, setDataLoading] = useState(true)

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation('administration')

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { showSuccessInfo, getPersons } = useContactInfoService()

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteContactFunction(contextId, id as string)
			setPersonsDataToRender(personsDataToRender.filter((row: any) => row.Id !== id))
			getData()
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const columns: any = [
		{
			field: 'FirstName',
			headerName: t('Users.User.FirstName'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: true,
		},
		{
			field: 'LastName',
			headerName: t('Users.User.LastName'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: true,
		},
		{
			field: 'EMail',
			headerName: t('Users.User.EMail'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: true,
		},
		{
			field: 'Telephone',
			headerName: t('Users.User.Telephone'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => [
				<TooltipButton
					title="general.Remove"
					onClick={handleDeleteClick(params.id)}
					IconComponent={DeleteIcon}
					disabled={readOnly}
				/>,
			],
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = useCallback(async () => {
		try {
			const allUsersResponse = await getPersons()
			const contactPersonsIds = await getContactsFunction(contextId)

			const alreadyAddedPersons = allUsersResponse.filter((el: any) => contactPersonsIds.includes(el.Id))
			const personsThatCanBeAdded = allUsersResponse.filter((el: any) => !contactPersonsIds.includes(el.Id))

			setPersonsDataToRender(alreadyAddedPersons)
			setPersonsCanBeAdded(personsThatCanBeAdded)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [contextId, getContactsFunction, getPersons])

	useEffect(() => {
		if (contextId) {
			getData()
		}
	}, [contextId])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				autoHeight
				apiRef={apiRef}
				columns={orderedColumns}
				rows={personsDataToRender}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				components={{
					Toolbar: UserContactPersonsToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						setIsAddContactDialogOpen,
						readOnly,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isAddContactDialogOpen && (
				<AddUserContactDialog
					addContactFunction={addContactFunction}
					setIsOpen={setIsAddContactDialogOpen}
					showSuccessInfo={showSuccessInfo}
					contactsList={personsCanBeAdded}
					isOpen={isAddContactDialogOpen}
					contextId={contextId}
					refreshData={getData}
				/>
			)}
		</>
	)
}

export default UserContactPersons
