import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { ISalesInvoiceEntry } from 'models'

const useSalesInvoiceEntriesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/contractSalesInvoiceEntry'

	const getEntries = useCallback(
		async (invoiceId: string) => {
			const response = await request(`${path}?invoiceId=${invoiceId}`)
			return response
		},
		[request]
	)

	const updateEntry = useCallback(
		async (entryId: string, data: ISalesInvoiceEntry) => {
			await request(`${path}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteEntry = useCallback(
		async (entryId: string) => {
			const response = await request(`${path}/${entryId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getEntries,
		deleteEntry,
		updateEntry,
	}
}

export default useSalesInvoiceEntriesService
