import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { individualEmailConfigSecureType } from 'data/lookup-data-sources'
import { useAuth } from 'hooks'
import useValidation from 'hooks/UseValidation'
import { IIndividualEmailConfig, IUser } from 'models'
import { useUsersService } from 'services'

import ChangePasswordDialog from '../../shared/change-password-form/ChangePasswordDialog'

const MyAccount = () => {
	const [userFormData, setUserFormData] = useState<IUser>()
	const [userIndividualEmailData, setUserIndividualEmailData] = useState<IIndividualEmailConfig>()
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
	const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)

	const {
		updateMySettings,
		showSuccessInfo,
		fieldsError,
		clearError,
		getIndividualEmailConfig,
		updateIndividualEmailConfig,
	} = useUsersService()
	const { checkRegexMatch, simpleTextErrorMessage, simpleTextRegex, emailErrorMessage, emailRegex } = useValidation()

	const [validationErrorsData, setValidationErrorsData] = useState<any>({
		FirstName: {
			error: false,
			regex: simpleTextRegex,
			errorMessage: simpleTextErrorMessage,
		},
		LastName: {
			error: false,
			regex: simpleTextRegex,
			errorMessage: simpleTextErrorMessage,
		},
		EMail: {
			error: false,
			regex: emailRegex,
			errorMessage: emailErrorMessage,
		},
	})

	const hasValidationError = (validationErrorsData: any) => {
		const validationErrors = Object.values(validationErrorsData)
		return validationErrors.some((item: any) => item.error === true)
	}

	const hasError = hasValidationError(validationErrorsData)

	const { user, refreshUser } = useAuth()

	const { t } = useTranslation(['administration', 'translation', 'navigation'])

	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('md'))

	const handleDataChange = useCallback(
		(e: any, isForIndividualEmail: boolean) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const value = e.target.value
			const fieldName = e.target.name

			if (fieldsError.includes(fieldName)) {
				clearError(fieldName)
			}

			if (isForIndividualEmail) {
				setUserIndividualEmailData((prev: any) => {
					return { ...prev, [fieldName]: value }
				})
			} else {
				setUserFormData((prev: any) => {
					return { ...prev, [fieldName]: value }
				})
			}
		},
		[isSaveButtonDisabled, fieldsError, clearError]
	)

	const updateValidationState = (fieldName: string, isValid: boolean) => {
		setValidationErrorsData((prev: any) => ({
			...prev,
			[fieldName]: {
				...prev[fieldName],
				error: !isValid,
			},
		}))
	}

	const handleCheckForError = (e: any) => {
		const value = e.target.value
		const fieldName = e.target.name

		if (!value) {
			updateValidationState(fieldName, true)
			return
		}

		const regex = validationErrorsData[fieldName].regex
		const isValid = checkRegexMatch(value, regex)
		updateValidationState(fieldName, isValid)
	}

	const handleIndividualEmailCheckBoxChange = (e: SyntheticEvent<HTMLInputElement>) => {
		isSaveButtonDisabled && setIsSaveButtonDisabled(false)

		const target = e.target as HTMLInputElement
		const value = target.checked

		setUserIndividualEmailData(
			prev => ({ ...prev, UseIndividualAccountForEmailSending: value }) as IIndividualEmailConfig
		)
	}

	const updateUserData = async () => {
		setButtonLoading(true)
		const { Id, FirstName, LastName, EMail } = userFormData as IUser

		try {
			await updateMySettings({ FirstName, LastName, EMail })
			await updateIndividualEmailConfig(Id, userIndividualEmailData as IIndividualEmailConfig)
			showSuccessInfo('saved')
			refreshUser()
			getIndividualEmailConfigData()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
		setIsSaveButtonDisabled(true)
	}

	const getIndividualEmailConfigData = async () => {
		try {
			if (user?.Id) {
				const response = await getIndividualEmailConfig(user?.Id)

				setUserIndividualEmailData(response)
			}
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		setUserFormData(user as IUser)
	}, [user])

	useEffect(() => {
		getIndividualEmailConfigData()
	}, [user?.Id])

	if (!userFormData) {
		return <Loading />
	} else if (!user) {
		return <NoData />
	}
	return (
		<>
			<Box sx={laptopViewAndUp ? sxStyles.contentContainer : sxStyles.contentContainerTablet}>
				<Box sx={sxStyles.row}>
					<Box sx={sxStyles.titleBox}>
						<Typography component={'span'} fontSize={'18px'} sx={sxStyles.title}>
							{`${t('MyAccount.MyAccountTitle')} ${user.FirstName || user.Login} ${user.LastName || ''}`}
						</Typography>
						<Box sx={sxStyles.actionsContainer}>
							<Button
								sx={sxStyles.actionsContainerButton}
								variant="text"
								onClick={() => setIsChangePasswordDialogOpen(true)}>
								{t('general.ChangePassword', { ns: 'translation' })}
							</Button>
							<LoadingButton
								variant="text"
								disabled={isSaveButtonDisabled || hasError}
								onClick={updateUserData}
								loading={buttonLoading}>
								{t('general.SaveButton', { ns: 'translation' })}
							</LoadingButton>
						</Box>
					</Box>
					<Divider />
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '10px' }}>
						{/* Login  */}
						<Grid xs={12} sm={6} md={6} lg={6}>
							<TextField
								disabled={true}
								value={userFormData?.Login || ''}
								name="Login"
								label={t('Users.User.Login')}
								fullWidth
							/>
						</Grid>
						{/* FirstName  */}
						<Grid xs={12} sm={6} md={6} lg={6}>
							<TextField
								disabled={false}
								value={userFormData?.FirstName || ''}
								name="FirstName"
								label={t('Users.User.FirstName')}
								error={fieldsError.includes('FirstName') || validationErrorsData.FirstName.error}
								helperText={
									(fieldsError.includes('FirstName') || validationErrorsData.FirstName.error) &&
									validationErrorsData.FirstName.errorMessage
								}
								onChange={e => handleDataChange(e, false)}
								onBlur={handleCheckForError}
								fullWidth
							/>
						</Grid>
						{/* LastName */}
						<Grid xs={12} sm={6} md={6} lg={6}>
							<TextField
								disabled={false}
								value={userFormData?.LastName || ''}
								name="LastName"
								label={t('Users.User.LastName')}
								error={fieldsError.includes('LastName') || validationErrorsData.LastName.error}
								helperText={
									(fieldsError.includes('LastName') || validationErrorsData.LastName.error) &&
									validationErrorsData.LastName.errorMessage
								}
								onChange={e => handleDataChange(e, false)}
								onBlur={handleCheckForError}
								fullWidth
							/>
						</Grid>
						{/* EMail */}
						<Grid xs={12} sm={6} md={6} lg={6}>
							<TextField
								disabled={false}
								value={userFormData?.EMail || ''}
								name="EMail"
								label={t('Users.User.EMail')}
								error={fieldsError.includes('EMail') || validationErrorsData.EMail.error}
								helperText={
									(fieldsError.includes('EMail') || validationErrorsData.EMail.error) &&
									validationErrorsData.EMail.errorMessage
								}
								onChange={e => handleDataChange(e, false)}
								onBlur={handleCheckForError}
								fullWidth
							/>
						</Grid>
						{/* UseIndividualAccountForEmailSending */}
						<Grid xs={12} sm={6} md={6} lg={6}>
							<FormControlLabel
								control={
									<Checkbox
										checked={userIndividualEmailData?.UseIndividualAccountForEmailSending || false}
										onChange={handleIndividualEmailCheckBoxChange}
										name="UseIndividualAccountForEmailSending"
									/>
								}
								label={t('Users.User.IndividualEmailConfig.UseIndividualAccountForEmailSending')}
							/>
						</Grid>
						<Grid xs={12} sm={6} md={6} lg={6}></Grid>
						{userIndividualEmailData?.UseIndividualAccountForEmailSending && (
							<>
								{/* Login */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.Login || ''}
										name="Login"
										label={t('Users.User.IndividualEmailConfig.Login')}
										onChange={e => handleDataChange(e, true)}
										fullWidth
									/>
								</Grid>
								{/* Password */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.Password || ''}
										name="Password"
										type={showPassword ? 'text' : 'password'}
										autoComplete="new-password"
										InputProps={{
											endAdornment: (
												<IconButton onClick={() => setShowPassword(prev => !prev)}>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											),
										}}
										label={t('Users.User.IndividualEmailConfig.Password')}
										onChange={e => handleDataChange(e, true)}
										fullWidth
									/>
								</Grid>
								{/* Host */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.Host || ''}
										name="Host"
										label={t('Users.User.IndividualEmailConfig.Host')}
										onChange={e => handleDataChange(e, true)}
										fullWidth
									/>
								</Grid>
								{/* Port */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.Port || ''}
										name="Port"
										type="number"
										label={t('Users.User.IndividualEmailConfig.Port')}
										onChange={e => handleDataChange(e, true)}
										fullWidth
									/>
								</Grid>
								{/* EmailSecureType */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.EmailSecureType || ''}
										name="EmailSecureType"
										label={t('Users.User.IndividualEmailConfig.EmailSecureType')}
										onChange={e => handleDataChange(e, true)}
										select
										fullWidth>
										{individualEmailConfigSecureType.map((el: any) => (
											<MenuItem key={el.value} value={el.value}>
												{el.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>
							</>
						)}
					</Grid>
				</Box>
			</Box>
			{isChangePasswordDialogOpen && (
				<ChangePasswordDialog
					isOpen={isChangePasswordDialogOpen}
					setIsOpen={setIsChangePasswordDialogOpen}
					userData={user}
				/>
			)}
		</>
	)
}

export default MyAccount

const sxStyles = {
	contentContainer: {
		height: '100%',
		minHeight: 'calc(100vh - 170px)',
		border: '1px solid',
		borderColor: 'border.primary',
		borderRadius: '5px',
	},
	contentContainerTablet: {
		height: '100%',
		minHeight: 'calc(100vh - 235px)',
		margin: '5px 0',
	},
	row: {
		width: '100%',
		p: '15px',
	},
	titleBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '5px',
		pb: '8px',
	},
	title: {
		display: 'inline-block',
	},
	actionsContainer: {},
	actionsContainerButton: {
		margin: '0 8px',
	},
}
