import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	MenuItem,
	Tab,
	TextField,
	Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import ChangePasswordDialog from 'components/shared/change-password-form/ChangePasswordDialog'
import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { individualEmailConfigSecureType } from 'data'
import useValidation from 'hooks/UseValidation'
import { IIndividualEmailConfig, IUser } from 'models'
import { useUsersService } from 'services'

import Global from './permissions/Global'
import PerProject from './permissions/PerProject'

const User = () => {
	const [user, setUser] = useState<IUser>()
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
	const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [selectedTab, setSelectedTab] = useState('0')
	const [loading, setLoading] = useState(true)
	const [userIndividualEmailData, setUserIndividualEmailData] = useState<IIndividualEmailConfig>()
	const [showPassword, setShowPassword] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('md'))

	const params = useParams()

	const {
		getUser,
		getIndividualEmailConfig,
		updateIndividualEmailConfig,
		showSuccessInfo,
		clearError,
		updateUser,
		fieldsError,
	} = useUsersService()

	const {
		checkRegexMatch,
		pureTextErrorMessage,
		pureTextRegex,
		simpleTextErrorMessage,
		simpleTextRegex,
		phoneNumberErrorMessage,
		phoneNumberRegex,
		emailErrorMessage,
		emailRegex,
	} = useValidation()

	const [validationErrorsData, setValidationErrorsData] = useState<any>({
		Login: {
			error: false,
			regex: pureTextRegex,
			errorMessage: pureTextErrorMessage,
		},
		FirstName: {
			error: false,
			regex: simpleTextRegex,
			errorMessage: simpleTextErrorMessage,
		},
		LastName: {
			error: false,
			regex: simpleTextRegex,
			errorMessage: simpleTextErrorMessage,
		},
		EMail: {
			error: false,
			regex: emailRegex,
			errorMessage: emailErrorMessage,
		},
		Telephone: {
			error: false,
			regex: phoneNumberRegex,
			errorMessage: phoneNumberErrorMessage,
		},
	})

	const hasValidationError = (validationErrorsData: any) => {
		const validationErrors = Object.values(validationErrorsData)
		return validationErrors.some((item: any) => item.error === true)
	}

	const hasError = hasValidationError(validationErrorsData)

	const tabs = useMemo(
		() => [
			{
				label: t('Users.UserPage.Global'),
				renderValue: <Global userId={user?.Id as string} />,
				isVisible: true,
			},
			{
				label: t('Users.UserPage.IsPerProject'),
				renderValue: <PerProject userId={user?.Id as string} />,
				isVisible: true,
			},
		],
		[t, user?.Id]
	)

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
	}

	const handleUserDataChange = useCallback(
		(e: any, isForIndividualEmail: boolean) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const fieldName = e.target.name
			const value = fieldName === 'ChangePasswordAtNextLogon' ? e.target.checked : e.target.value

			if (fieldsError.includes(fieldName)) {
				clearError(fieldName)
			}

			if (isForIndividualEmail) {
				setUserIndividualEmailData((prev: any) => {
					return { ...prev, [fieldName]: value }
				})
			} else {
				setUser((prev: any) => {
					return { ...prev, [fieldName]: value }
				})
			}
		},
		[fieldsError, clearError, isSaveButtonDisabled]
	)

	const handleIndividualEmailCheckBoxChange = (e: SyntheticEvent<HTMLInputElement>) => {
		isSaveButtonDisabled && setIsSaveButtonDisabled(false)

		const target = e.target as HTMLInputElement
		const value = target.checked

		setUserIndividualEmailData(
			prev => ({ ...prev, UseIndividualAccountForEmailSending: value }) as IIndividualEmailConfig
		)
	}

	const updateValidationState = (fieldName: string, isValid: boolean) => {
		setValidationErrorsData((prev: any) => ({
			...prev,
			[fieldName]: {
				...prev[fieldName],
				error: !isValid,
			},
		}))
	}

	const handleInputBlur = (e: any) => {
		const value = e.target.value
		const fieldName = e.target.name

		if (!value) {
			updateValidationState(fieldName, true)
			return
		}

		const regex = validationErrorsData[fieldName].regex
		const isValid = checkRegexMatch(value, regex)
		updateValidationState(fieldName, isValid)
	}

	const updateUserData = async () => {
		setButtonLoading(true)
		const { Id } = user as IUser

		try {
			await updateUser(params.id as string, user as IUser)
			await updateIndividualEmailConfig(Id, userIndividualEmailData as IIndividualEmailConfig)
			showSuccessInfo('saved')
			getUserData()
			getIndividualEmailConfigData()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
		setIsSaveButtonDisabled(true)
	}

	const getUserData = useCallback(async () => {
		if (params.id) {
			try {
				const response = await getUser(params.id)
				setUser(response)
			} catch (err) {
				console.error(err)
			}
			setLoading(false)
		}
	}, [params.id, getUser])

	const getIndividualEmailConfigData = async () => {
		try {
			if (params.id) {
				const response = await getIndividualEmailConfig(params.id)

				setUserIndividualEmailData(response)
			}
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getIndividualEmailConfigData()
	}, [params.id])

	useEffect(() => {
		getUserData()
	}, [params.id])

	if (loading) {
		return <Loading />
	} else if (!user) {
		return <NoData />
	}

	return (
		<>
			<Box sx={laptopViewAndUp ? sxStyles.contentContainer : sxStyles.contentContainerTablet}>
				<Box sx={sxStyles.row}>
					<Box sx={sxStyles.titleBox}>
						<Typography component={'span'} fontSize={'18px'} sx={sxStyles.title}>
							{t('Users.UserPage.GeneralInformation')}
						</Typography>
						<Box sx={sxStyles.actionsContainer}>
							<Button
								sx={sxStyles.actionsContainerButton}
								variant="text"
								onClick={() => setIsChangePasswordDialogOpen(true)}>
								{t('general.ChangePassword', { ns: 'translation' })}
							</Button>
							<LoadingButton
								variant="text"
								disabled={isSaveButtonDisabled || hasError}
								onClick={updateUserData}
								loading={buttonLoading}>
								{t('general.SaveButton', { ns: 'translation' })}
							</LoadingButton>
						</Box>
					</Box>
					<Divider />
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '10px' }}>
						{/* Login  */}
						<Grid xs={12} sm={6} md={6} lg={4}>
							<TextField
								disabled={false}
								value={user?.Login || ''}
								id="user-field-Login"
								name="Login"
								label={t('Users.User.Login')}
								error={fieldsError.includes('Login') || validationErrorsData.Login.error}
								helperText={
									(fieldsError.includes('Login') || validationErrorsData.Login.error) &&
									validationErrorsData.Login.errorMessage
								}
								onChange={e => handleUserDataChange(e, false)}
								onBlur={handleInputBlur}
								fullWidth
							/>
						</Grid>
						{/* FirstName  */}
						<Grid xs={12} sm={6} md={6} lg={4}>
							<TextField
								disabled={false}
								value={user?.FirstName || ''}
								id="user-field-FirstName"
								name="FirstName"
								label={t('Users.User.FirstName')}
								error={fieldsError.includes('FirstName') || validationErrorsData.FirstName.error}
								helperText={
									(fieldsError.includes('FirstName') || validationErrorsData.FirstName.error) &&
									validationErrorsData.FirstName.errorMessage
								}
								onChange={e => handleUserDataChange(e, false)}
								onBlur={handleInputBlur}
								fullWidth
							/>
						</Grid>
						{/* LastName */}
						<Grid xs={12} sm={6} md={6} lg={4}>
							<TextField
								disabled={false}
								value={user?.LastName || ''}
								id="user-field-LastName"
								name="LastName"
								label={t('Users.User.LastName')}
								error={fieldsError.includes('LastName') || validationErrorsData.LastName.error}
								helperText={
									(fieldsError.includes('LastName') || validationErrorsData.LastName.error) &&
									validationErrorsData.LastName.errorMessage
								}
								onChange={e => handleUserDataChange(e, false)}
								onBlur={handleInputBlur}
								fullWidth
							/>
						</Grid>
						{/* EMail */}
						<Grid xs={12} sm={6} md={6} lg={4}>
							<TextField
								disabled={false}
								value={user?.EMail || ''}
								id="user-field-EMail"
								name="EMail"
								label={t('Users.User.EMail')}
								error={fieldsError.includes('EMail') || validationErrorsData.EMail.error}
								helperText={
									(fieldsError.includes('EMail') || validationErrorsData.EMail.error) &&
									validationErrorsData.EMail.errorMessage
								}
								onChange={e => handleUserDataChange(e, false)}
								onBlur={handleInputBlur}
								fullWidth
							/>
						</Grid>
						{/* Telephone  */}
						<Grid xs={12} sm={6} md={6} lg={4}>
							<TextField
								disabled={false}
								value={user?.Telephone || ''}
								id="user-field-Telephone"
								name="Telephone"
								label={t('Users.User.Telephone')}
								error={fieldsError.includes('Telephone') || validationErrorsData.Telephone.error}
								helperText={
									(fieldsError.includes('Telephone') || validationErrorsData.Telephone.error) &&
									validationErrorsData.Telephone.errorMessage
								}
								onChange={e => handleUserDataChange(e, false)}
								onBlur={handleInputBlur}
								fullWidth
							/>
						</Grid>
						{/* ChangePasswordAtNextLogon */}
						<Grid xs={12} sm={6} md={6} lg={4}>
							<FormControlLabel
								control={
									<Checkbox
										checked={user?.ChangePasswordAtNextLogon || false}
										id="user-field-ChangePasswordAtNextLogon"
										name="ChangePasswordAtNextLogon"
										onChange={e => handleUserDataChange(e, false)}
									/>
								}
								label={t('Users.User.ChangePasswordAtNextLogon')}
							/>
						</Grid>
						{/* UseIndividualAccountForEmailSending */}
						<Grid xs={12} sm={6} md={6} lg={6}>
							<FormControlLabel
								control={
									<Checkbox
										checked={userIndividualEmailData?.UseIndividualAccountForEmailSending || false}
										onChange={handleIndividualEmailCheckBoxChange}
										name="UseIndividualAccountForEmailSending"
									/>
								}
								label={t('Users.User.IndividualEmailConfig.UseIndividualAccountForEmailSending')}
							/>
						</Grid>
						<Grid xs={12} sm={6} md={6} lg={6}></Grid>
						{userIndividualEmailData?.UseIndividualAccountForEmailSending && (
							<>
								{/* Login */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.Login || ''}
										name="Login"
										label={t('Users.User.IndividualEmailConfig.Login')}
										onChange={e => handleUserDataChange(e, true)}
										fullWidth
									/>
								</Grid>
								{/* Password */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.Password || ''}
										name="Password"
										type={showPassword ? 'text' : 'password'}
										autoComplete="new-password"
										InputProps={{
											endAdornment: (
												<IconButton onClick={() => setShowPassword(prev => !prev)}>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											),
										}}
										label={t('Users.User.IndividualEmailConfig.Password')}
										onChange={e => handleUserDataChange(e, true)}
										fullWidth
									/>
								</Grid>
								{/* Host */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.Host || ''}
										name="Host"
										label={t('Users.User.IndividualEmailConfig.Host')}
										onChange={e => handleUserDataChange(e, true)}
										fullWidth
									/>
								</Grid>
								{/* Port */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.Port || ''}
										name="Port"
										type="number"
										label={t('Users.User.IndividualEmailConfig.Port')}
										onChange={e => handleUserDataChange(e, true)}
										fullWidth
									/>
								</Grid>
								{/* EmailSecureType */}
								<Grid xs={12} sm={6} md={6} lg={6}>
									<TextField
										value={userIndividualEmailData?.EmailSecureType || ''}
										name="EmailSecureType"
										label={t('Users.User.IndividualEmailConfig.EmailSecureType')}
										onChange={e => handleUserDataChange(e, true)}
										select
										fullWidth>
										{individualEmailConfigSecureType.map((el: any) => (
											<MenuItem key={el.value} value={el.value}>
												{el.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>
							</>
						)}
					</Grid>
				</Box>
				<Box sx={sxStyles.row}>
					<Box sx={sxStyles.titleBox}>
						<Typography component={'span'} fontSize={'18px'} sx={sxStyles.title}>
							{t('Users.UserPage.Permissions')}
						</Typography>
					</Box>
					<Divider />
					<Box sx={{ width: '100%', mt: '5px' }}>
						<TabContext value={selectedTab}>
							<Box>
								<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
									{tabs.map((el, index) => {
										if (el.isVisible) return <Tab key={el.label} label={el.label} value={index.toString()} />
									})}
								</TabList>
							</Box>
							{tabs.map((el, index) => {
								if (el.isVisible)
									return (
										<TabPanel key={index} value={index.toString()} sx={sxStyles.tabPanel}>
											{el.renderValue}
										</TabPanel>
									)
							})}
						</TabContext>
					</Box>
				</Box>
			</Box>
			{isChangePasswordDialogOpen && (
				<ChangePasswordDialog
					isOpen={isChangePasswordDialogOpen}
					setIsOpen={setIsChangePasswordDialogOpen}
					userData={user}
				/>
			)}
		</>
	)
}

export default User

const sxStyles = {
	contentContainer: {
		height: '100%',
		minHeight: 'calc(100vh - 170px)',
		border: '1px solid',
		borderColor: 'border.primary',
		borderRadius: '5px',
	},
	contentContainerTablet: {
		height: '100%',
		minHeight: 'calc(100vh - 235px)',
		margin: '5px 0',
	},
	row: {
		width: '100%',
		p: '15px',
	},
	titleBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '5px',
		pb: '8px',
	},
	title: {
		display: 'inline-block',
	},
	actionsContainer: {},
	actionsContainerButton: {
		margin: '0 8px',
	},
	tabPanel: {
		padding: '10px 0',
	},
}
