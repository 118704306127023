import { useState } from 'react'
import { useLocation } from 'react-router-dom'

function useLocalStorageState(key: string, defaultValue: any) {
	const location = useLocation()
	const localStorageKey = `${key}_${location.pathname}`
	const savedState = localStorage.getItem(localStorageKey)
	const [localStorageState, setLocalStorageState] = useState(savedState ? JSON.parse(savedState) : defaultValue)

	return [localStorageState, setLocalStorageState]
}

export default useLocalStorageState
