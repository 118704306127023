import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IGoodsReceivedNote, IInvokeAction } from 'models'
import { GRN_ENDPOINT } from 'services/data'

export interface getGoodsReceivedNoteListProps {
	isCanceled: boolean
	isCompleted: boolean
	orderId?: string
	incomingLetterId?: string
	data?: GridInitialState
}

const useGoodsReceivedNoteService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = GRN_ENDPOINT

	const getFilteredGoodsReceivedNotes = useCallback(
		async ({ isCanceled, isCompleted, orderId, incomingLetterId, data }: getGoodsReceivedNoteListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (orderId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&orderId=${orderId}`
			}

			if (incomingLetterId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getGoodsReceivedNotes = useCallback(
		async ({ isCanceled, isCompleted, orderId, incomingLetterId }: getGoodsReceivedNoteListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (orderId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&orderId=${orderId}`
			}

			if (incomingLetterId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getGoodsReceivedNote = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updateGoodsReceivedNote = useCallback(
		async (id: string, data: IGoodsReceivedNote) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getGoodsReceivedNotes,
		getGoodsReceivedNote,
		updateGoodsReceivedNote,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredGoodsReceivedNotes,
	}
}

export default useGoodsReceivedNoteService
