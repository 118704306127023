import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { BudgetEntryFieldType } from 'models'

const useBudgetEntryFieldSource = () => {
	const { request, error, clearError } = useHttp()

	const getBudgetEntryFieldSource = useCallback(
		async (budgetEntryId: string, filedName: BudgetEntryFieldType) => {
			const response = await request(`/api/budget/fieldCalculationDocuments/${budgetEntryId}?field=${filedName}`)

			return response
		},
		[request]
	)

	return { getBudgetEntryFieldSource, error, clearError }
}

export default useBudgetEntryFieldSource
