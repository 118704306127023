import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	MenuItem,
	Step,
	StepLabel,
	Stepper,
	TextField,
} from '@mui/material'

import { Loading } from 'components/shared'
import { useAppSelector, useDocumentActionsStateActions } from 'features'
import { Action, IUser, IWorkflowStage } from 'models'
import { useDocumentOwnerService, useWorkflowService } from 'services'

type Props = {
	isOpen: boolean
	action: Action
	invokeAction: (value: Action, parameters?: any) => void
}

const ChangeStageDialog = ({ isOpen, action, invokeAction }: Props) => {
	const [selectedDocumentOwner, setSelectedDocumentOwner] = useState('')
	const [documentOwnersList, setDocumentOwnersList] = useState<IUser[]>([])
	const [stageList, setStageList] = useState<IWorkflowStage[]>([])
	const [loading, setLoading] = useState(true)

	const { changeStageDialogWarningText } = useAppSelector(state => state.documentActionsState)
	const { documentId, documentData } = useAppSelector(state => state.documentData)
	const { setIsChangeStageDialogOpen, setChangeStageDialogWarningText } = useDocumentActionsStateActions()

	const activeStageIndex = stageList.findIndex(el => el.Id === (documentData?.StageId as string))

	const dialogMaxWidth = stageList.length > 5 ? 'md' : 'sm'

	const stageTitleName =
		action?.Name === 'nextStage' ? stageList[activeStageIndex + 1]?.Name : stageList[activeStageIndex - 1]?.Name

	const { t } = useTranslation()

	const { getPossibleOwners } = useDocumentOwnerService()
	const { getWorkflowStages } = useWorkflowService()

	const closeDialog = () => {
		setIsChangeStageDialogOpen(false)
		setChangeStageDialogWarningText('')
		setSelectedDocumentOwner('')
	}

	const handleActionConfirm = () => {
		const parameters = selectedDocumentOwner
			? {
					newOwnerId: selectedDocumentOwner,
				}
			: null

		invokeAction(action, parameters)
		closeDialog()
	}

	const handleInputChange = (e: any) => {
		setSelectedDocumentOwner(e.target.value)
	}

	const getPossibleOwnersData = async () => {
		try {
			const response = await getPossibleOwners(
				documentData?.WorkflowId as string,
				documentId,
				action?.Name === 'nextStage' ? 'Next' : 'Previous'
			)
			setDocumentOwnersList(response)
		} catch (err) {
			console.error(err)
		}
	}

	const getStageList = async () => {
		try {
			setLoading(true)
			const response = await getWorkflowStages(documentData?.WorkflowId as string)
			setStageList(response.sort((a: any, b: any) => a.Index - b.Index))
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}

	useEffect(() => {
		if (documentData?.WorkflowId) {
			getStageList()
		}
	}, [documentData?.WorkflowId])

	useEffect(() => {
		if (action) {
			getPossibleOwnersData()
		}
	}, [action])

	return (
		<Dialog open={isOpen} fullWidth maxWidth={dialogMaxWidth}>
			{loading ? (
				<Loading />
			) : (
				<>
					<DialogTitle sx={sxStyles.title} component="div">
						{t(`Actions.Names.${action?.Name}`)}
						{stageTitleName && ` - ${stageTitleName}`}
						<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Stepper activeStep={activeStageIndex} alternativeLabel orientation="horizontal" sx={{ py: '30px' }}>
							{stageList.map(step => (
								<Step key={step.Id}>
									<StepLabel>{step.Name}</StepLabel>
								</Step>
							))}
						</Stepper>
						<FormControl fullWidth>
							<TextField
								disabled={false}
								select
								value={selectedDocumentOwner}
								id="OwnerId"
								name="OwnerId"
								label={t('Actions.ChangeStageDialog.SelectInputLabel')}
								onChange={handleInputChange}
								fullWidth
								sx={{ mt: '10px' }}>
								{documentOwnersList.map((el: any, index: number) => (
									<MenuItem key={index} value={el.Id}>
										{el.FirstName} {el.LastName}
									</MenuItem>
								))}
							</TextField>
						</FormControl>
						{changeStageDialogWarningText && (
							<Box sx={sxStyles.warningMessage}>
								<WarningIcon color="warning" sx={{ pr: '5px' }} />
								{changeStageDialogWarningText}
							</Box>
						)}
					</DialogContent>
					<DialogActions sx={sxStyles.actionsContainer}>
						<Button variant="outlined" color="error" onClick={closeDialog}>
							{t('general.Cancel')}
						</Button>
						<Button variant="contained" onClick={handleActionConfirm} color="success">
							{t('general.Confirm')}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	)
}

export default ChangeStageDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	warningMessage: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		p: '10px',
		pt: '20px',
		color: 'warning.main',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
