import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

import { NoData } from 'components/shared'
import { useHandleNavigate } from 'hooks'
import { IDemandEntryLinkedDocument } from 'models'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	list: IDemandEntryLinkedDocument[]
	title: string
}

const LinkedDocumentsDialog = ({ isOpen, setIsOpen, list, title }: Props) => {
	const { t } = useTranslation('demand')

	const { handleDocumentLinkNavigate } = useHandleNavigate()

	const handleLinkClick = (link: IDemandEntryLinkedDocument) => {
		handleDocumentLinkNavigate(`/${link.DocumentRootingPath}`, link.Id, link.Label)
	}

	const generateLinkLabel = (path: string, label: string) => {
		const capitalized = path.charAt(0).toUpperCase() + path.slice(1)

		const documentName = t(capitalized, { ns: 'routes' })

		return `${documentName} ${label}`
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{title}
				<IconButton edge="start" color="inherit" onClick={() => setIsOpen(false)} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={{ mb: '10px' }}>
				{!list.length ? (
					<NoData />
				) : (
					list.map((link: IDemandEntryLinkedDocument) => (
						<Box sx={sxStyles.link} key={link.Id} onClick={() => handleLinkClick(link)}>
							{generateLinkLabel(link.DocumentRootingPath, link.Label)}
						</Box>
					))
				)}
			</DialogContent>
		</Dialog>
	)
}
export default LinkedDocumentsDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	link: {
		cursor: 'pointer',
		color: 'primary.main',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
}
