import { MutableRefObject } from 'react'

import { GridApi } from '@mui/x-data-grid-premium'

export const handleExpandAll = (apiRef: MutableRefObject<GridApi>) => {
	const allRowIds = apiRef.current.getAllRowIds()

	allRowIds.forEach(rowId => {
		apiRef.current.setRowChildrenExpansion(rowId, true)
	})
}

export const handleCollapseAll = (apiRef: MutableRefObject<GridApi>) => {
	const allRowIds = apiRef.current.getAllRowIds()

	allRowIds.forEach(rowId => {
		apiRef.current.setRowChildrenExpansion(rowId, false)
	})
}
