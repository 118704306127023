import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { ICustomCostInvoiceSettlement } from 'models'

const useCustomCostInvoiceSettlementService = () => {
	const { request, error, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = '/api/customCostInvoiceSettlement'

	const getSettlements = useCallback(
		async (invoiceId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&invoiceId=${invoiceId}`)
			return response
		},
		[request]
	)

	const addSettlement = useCallback(
		async (customCostInvoiceId: string, budgetEntryId: string) => {
			const response = await request(
				`${path}?customCostInvoiceId=${customCostInvoiceId}&budgetEntryId=${budgetEntryId}`,
				'POST'
			)
			return response
		},
		[request]
	)

	const updateSettlement = useCallback(
		async (settlementId: string, data: ICustomCostInvoiceSettlement) => {
			await request(`${path}/${settlementId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteSettlement = useCallback(
		async (settlementId: string) => {
			const response = await request(`${path}/${settlementId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		fieldsError,
		showSuccessInfo,
		getSettlements,
		addSettlement,
		updateSettlement,
		deleteSettlement,
	}
}

export default useCustomCostInvoiceSettlementService
