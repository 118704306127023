import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Loading from 'components/shared/loading/Loading'
import { IStageConfiguration } from 'models'

import SinglePermission from './SinglePermission'

interface Props {
	configurationPermissions: IStageConfiguration[]
	handleDataChange: (value: any) => void
	loading: boolean
}

const StageConfiguration = ({ configurationPermissions, handleDataChange, loading }: Props) => {
	const { t } = useTranslation(['administration', 'translation'])

	const theme = useTheme()
	const tabletView = useMediaQuery(theme.breakpoints.down('lg'))

	return (
		<Box sx={{ ...sxStyles.container, width: tabletView ? '100%' : '35%' }}>
			<Typography component={'div'} fontSize={'16px'} sx={sxStyles.title}>
				{t('Workflow.Permissions.RolePermissions.Stages.ConfigurationPermissions.Title')}
			</Typography>
			{loading ? (
				<Loading />
			) : (
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ m: '10px 0' }}>
					{configurationPermissions.map(el => {
						return (
							<Grid key={el.Key} xs={12} sm={12} md={12} lg={12}>
								<Box sx={sxStyles.permissionContainer}>
									<SinglePermission permission={el} handleDataChange={handleDataChange} />
								</Box>
							</Grid>
						)
					})}
				</Grid>
			)}
		</Box>
	)
}

export default StageConfiguration

const sxStyles = {
	container: {
		p: '15px',
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		textAlign: 'center',
		color: 'text.secondary',
	},
	permissionContainer: {
		width: '95%',
		padding: '5px 0',
		borderBottom: '1px dotted',
		borderColor: 'action.disabled',
	},
}
