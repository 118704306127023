import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'

import { useAppSelector, useLinkDocumentsActions } from 'features'

type Props = {
	isOpen: boolean
	linkFunction: () => void
	itemName: string
}

const LinkEntryDialog = ({ isOpen, itemName, linkFunction }: Props) => {
	const { copiedDocumentData } = useAppSelector(state => state.linkDocuments)
	const { setIsLinkEntryDialogOpen } = useLinkDocumentsActions()

	const { t } = useTranslation()

	const closeDialog = () => {
		setIsLinkEntryDialogOpen(false)
	}

	const handleLink = async () => {
		linkFunction()
		closeDialog()
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('LinkDocumentDialog.Title1')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography sx={sxStyles.content}>
					{t('LinkDocumentDialog.Info')}
					<Typography component={'span'} sx={sxStyles.itemName}>
						{copiedDocumentData.name}
					</Typography>
					{t('LinkDocumentDialog.With')}
					<Typography component={'span'} sx={sxStyles.itemName}>
						{itemName}.
					</Typography>
					{t('LinkDocumentDialog.Question')}
				</Typography>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.No')}
				</Button>
				<Button variant="contained" onClick={handleLink} color="success">
					{t('general.Yes')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default LinkEntryDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	content: { fontSize: '16px', margin: '10px', textAlign: 'center' },
	itemName: { fontWeight: 500, padding: '0 5px' },
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
