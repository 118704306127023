import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type InitialState = {
	allowedRootingsList: string[]
}

const initialState: InitialState = {
	allowedRootingsList: [],
}

export const routingListSlice = createSlice({
	name: 'routingListSlice',
	initialState,
	reducers: {
		setAllowedRootingsList: (state, action: PayloadAction<string[]>) => {
			state.allowedRootingsList = action.payload
		},
	},
})

export const routingListActions = routingListSlice.actions

export default routingListSlice.reducer
