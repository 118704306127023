import { ReactNode, useCallback, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { useAppSelector, useRoutingListActions } from 'features'
import { useRootingService } from 'services'

const useRoutePermission = () => {
	const { allowedRootingsList } = useAppSelector(state => state.routingList)

	const { setAllowedRootingsList } = useRoutingListActions()
	const { getAllowedRootings } = useRootingService()

	const isPathAllowed = useCallback(
		(path: string) => {
			return allowedRootingsList.find(el => el === path)
		},
		[allowedRootingsList]
	)

	const isAnyPathFromGroupAllowed = useCallback(
		(pathGroup: string[]) => {
			return allowedRootingsList.some(element => pathGroup.includes(element))
		},
		[allowedRootingsList]
	)

	const checkRoutePermission = (path: string, Component: ReactNode): ReactNode => {
		const hasPermission = isPathAllowed(path)

		// Check if the user has permission to access the route
		if (hasPermission) {
			return Component
		}
		// If the user lacks permission, redirect them
		return <Navigate to="/" replace={true} />
	}

	useEffect(() => {
		const getRootings = async () => {
			try {
				const response = await getAllowedRootings()
				setAllowedRootingsList(response)
			} catch (err) {
				console.error(err)
			}
		}
		getRootings()
	}, [])

	return {
		checkRoutePermission,
		allowedRootingsList,
		isPathAllowed,
		isAnyPathFromGroupAllowed,
	}
}

export default useRoutePermission
