import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IAdvanceInvoiceEntry } from 'models'

const useAdvanceInvoiceEntriesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/contractAdvanceInvoiceEntry'

	const getEntries = useCallback(
		async (invoiceId: string) => {
			const response = await request(`${path}?invoiceId=${invoiceId}`)
			return response
		},
		[request]
	)

	const addEntry = useCallback(
		async (data: IAdvanceInvoiceEntry) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateEntry = useCallback(
		async (entryId: string, data: any) => {
			await request(`${path}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteEntry = useCallback(
		async (entryId: string) => {
			const response = await request(`${path}/${entryId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getEntries,
		addEntry,
		updateEntry,
		deleteEntry,
	}
}

export default useAdvanceInvoiceEntriesService
