import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IMeasurementUnit } from 'models'

const useMeasurementUnitsService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/MeasurementUnit'
	const odataPath = '/odata/MeasurementUnit'

	const getMeasurementUnitsLookup = useCallback(async () => {
		const response = await request(`${path}/lookup`)
		return response
	}, [request])

	const getMeasurementUnits = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const addMeasurementUnit = useCallback(
		async (data: IMeasurementUnit) => {
			const response = await request(`${odataPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateMeasurementUnit = useCallback(
		async (id: string, body: IMeasurementUnit) => {
			await request(`${odataPath}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deleteMeasurementUnit = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getMeasurementUnits,
		getMeasurementUnitsLookup,
		addMeasurementUnit,
		updateMeasurementUnit,
		deleteMeasurementUnit,
	}
}

export default useMeasurementUnitsService
