import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IEmailParameters } from 'models'

const useEmailParametersService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/workflowDocumentEmailData'

	const getEmailData = useCallback(
		async (documentId: string, workflowId: string) => {
			const response = await request(`${path}?workflowId=${workflowId}&documentId=${documentId}`)
			return response
		},
		[request]
	)

	const updateEmailData = useCallback(
		async (documentId: string, workflowId: string, data: IEmailParameters) => {
			const response = await request(`${path}?workflowId=${workflowId}&documentId=${documentId}`, 'PATCH', data)
			return response
		},
		[request]
	)

	// Markups
	const getEmailMarkups = useCallback(
		async (documentType: string) => {
			const response = await request(
				`/api/markerMap/emailTemplateMap?Type=${documentType}`,
				'GET',
				null,
				{ 'Content-Type': 'application/json' },
				true
			)
			return response
		},
		[request]
	)

	// Attachments
	const getAttachments = useCallback(
		async (documentId: string, workflowId: string) => {
			const response = await request(`${path}/emailAttachments?workflowId=${workflowId}&documentId=${documentId}`)
			return response
		},
		[request]
	)

	const addAttachment = useCallback(
		async (documentId: string, workflowId: string, attachmentId: string) => {
			const response = await request(
				`${path}/emailAttachments?workflowId=${workflowId}&documentId=${documentId}&attachmentId=${attachmentId}`,
				'PUT'
			)
			return response
		},
		[request]
	)

	const deleteAttachment = useCallback(
		async (documentId: string, workflowId: string, attachmentId: string) => {
			await request(
				`${path}/emailAttachments?workflowId=${workflowId}&documentId=${documentId}&attachmentId=${attachmentId}`,
				'DELETE'
			)
		},
		[request]
	)

	return {
		showSuccessInfo,
		error,
		fieldsError,
		clearError,
		getEmailData,
		getAttachments,
		updateEmailData,
		addAttachment,
		deleteAttachment,
		getEmailMarkups,
	}
}

export default useEmailParametersService
