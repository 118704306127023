import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

interface IBudgetEntryReorder {
	entryId: string
	newIndex: string
}

const useInvestorBudgetService = () => {
	const { request, uploadFileRequest, downloadFileRequest, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getData = useCallback(async (projectId: string) => {
		const response = await request(`/api/investorbudget?projectId=${projectId}`)
		return response
	}, [])

	const getMeasurmentUnits = useCallback(async () => {
		const response = await request(`/api/MeasurementUnit/lookup`)
		return response
	}, [])

	const getVatRate = useCallback(async () => {
		const response = await request(`/api/VatRate/lookup`)
		return response
	}, [])

	const addRow = useCallback(async (body: any) => {
		const response = await request(`/api/investorBudget`, 'POST', body)
		return response
	}, [])

	const updateRow = useCallback(async (rowId: string, body: any) => {
		await request(`/api/investorBudget/${rowId}`, 'PATCH', body)
	}, [])

	const deleteRow = useCallback(async (rowId: string) => {
		await request(`/api/investorBudget/${rowId}`, 'DELETE')
	}, [])

	const importBudgetStatus = useCallback(async (projectId: string) => {
		const response = await request(`/api/project/importBudgetStatus/${projectId}`)

		return response
	}, [])

	const addFile = useCallback(async (projectId: string, formData: any) => {
		const response = await uploadFileRequest(`/api/project/importInvestorBudget/${projectId}`, 'PATCH', formData)
		return response
	}, [])

	const downloadFile = useCallback(async (projectId: string) => {
		const response = await downloadFileRequest(`/api/project/exportInvestorBudget/${projectId}`)
		return response
	}, [])

	const reorder = useCallback(async (body: IBudgetEntryReorder) => {
		await request('/api/investorBudget/reorder', 'POST', body)
	}, [])

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getData,
		getMeasurmentUnits,
		getVatRate,
		importBudgetStatus,
		updateRow,
		addFile,
		reorder,
		addRow,
		deleteRow,
		downloadFile,
	}
}

export default useInvestorBudgetService
