import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DefaultAttachmentPreviewButton, DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { useFormDataChange } from 'hooks'
import { IBalancingDocument } from 'models'
import { useBalancingDocumentService } from 'services'

import Form from './Form'

type Props = {
	documentId: string
}

const BasicInfo = ({ documentId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { handleInputChange, fieldsError } = useFormDataChange()
	const { getBalancingDocument, updateBalancingDocument } = useBalancingDocumentService()

	const { t } = useTranslation()

	const refreshDocumentData = async () => {
		try {
			const response = await getBalancingDocument(documentId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [documentId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={5} contractor={false} tabs={1} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
						documentId={documentId}
					/>
				</div>
				<div>
					<DefaultAttachmentPreviewButton attachmentId={documentData?.DefaultAttachmentId} />
					<ChangeHistory
						contextId={documentId}
						translationFile="balancing-document"
						title={`${t('BalancingDocument', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="Fields"
					/>
					<DocumentFormSaveButton
						documentId={documentId}
						data={documentData}
						updateDataFunction={updateBalancingDocument}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<Form
					formData={documentData as IBalancingDocument}
					documentId={documentId}
					onInputChange={handleInputChange}
					fieldErrorsList={fieldsError}
				/>
			</div>
		</>
	)
}
export default BasicInfo
