import { useTranslation } from 'react-i18next'

import LockPersonIcon from '@mui/icons-material/LockPerson'
import { Box, Paper, Typography } from '@mui/material'

import LanguageSwitcher from 'components/layouts/main/language-switcher/LanguageSwitcher'
import { useAuth } from 'hooks'
import { IUser } from 'models'
import { getCurrentTheme } from 'utils/themes'

import ChangePasswordForm from '../../shared/change-password-form/ChangePasswordForm'

const ChangePasswordPage = () => {
	const { user } = useAuth()

	const currentTheme = getCurrentTheme()

	const { t } = useTranslation()

	return (
		<Box sx={{ ...sxStyles.container, ...sxStyles.flex }}>
			<Box sx={sxStyles.logo}>
				<img
					src={currentTheme === 'light' ? '/logo_alfa_byte_for_light_mode.png' : '/logo_alfa_byte.png'}
					alt="AlfaByte"
					className="logo"
				/>
			</Box>
			<Box sx={sxStyles.lang}>
				<LanguageSwitcher />
			</Box>
			<Paper sx={{ ...sxStyles.formContainer, ...sxStyles.flex }} elevation={5}>
				<Typography component={'div'} sx={{ ...sxStyles.title }}>
					<LockPersonIcon fontSize="large" />
					<p>
						{t('ChangePasswordPage.ChangePasswordTitle')}
						{user?.Login}
					</p>
				</Typography>
				<ChangePasswordForm user={user as IUser} />
			</Paper>
		</Box>
	)
}

export default ChangePasswordPage

const sxStyles = {
	flex: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	container: {
		height: '100vh',
		overflowX: 'auto',
	},
	logo: {
		position: 'absolute',
		top: '20px',
		left: '20px',
		'.logo': {
			width: 'auto',
			height: '32px',
		},
	},
	lang: {
		position: 'absolute',
		top: '20px',
		right: '20px',
	},
	formContainer: {
		flexDirection: 'column',
		minHeight: '400px',
		maxHeight: '80%',
		width: '50%',
		minWidth: '300px',
		maxWidth: '550px',
		padding: '15px',
	},
	formFieldContainer: {
		width: '100%',
		padding: '15px',
	},
	actionsContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '15px',
	},
	title: {
		maxWidth: '520px',
		overflow: 'hidden',
		wordWrap: 'break-word',
		textAlign: 'center',
		fontSize: '24px',
		fontWeight: '500',
		padding: '10px 0',
	},
}
