import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useDocumentOwnerService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/workflowDocumentOwner'

	const getPossibleOwners = useCallback(
		async (workflowId: string, documentId: string, stageDirection: string) => {
			const response = await request(
				`${path}/possibleOwners?workflowId=${workflowId}&documentId=${documentId}&stageDirection=${stageDirection}`
			)
			return response
		},
		[request]
	)

	const getUserPrivilige = useCallback(
		async (workflowId: string, documentId: string) => {
			const response = await request(
				`${path}/setDocumentOwnershipPrivilige?workflowId=${workflowId}&documentId=${documentId}`
			)

			return response
		},
		[request]
	)

	const assignDocumentForLoggedUser = useCallback(
		async (workflowId: string, documentId: string) => {
			await request(`${path}/getDocumentOwnership?workflowId=${workflowId}&documentId=${documentId}`, 'PATCH')
		},
		[request]
	)

	const setUserAsOwner = useCallback(
		async (workflowId: string, documentId: string, userId: string) => {
			await request(
				`${path}/setDocumentOwnership?workflowId=${workflowId}&documentId=${documentId}&userId=${userId}`,
				'PATCH'
			)
		},
		[request]
	)

	const removeCurrentOwner = useCallback(
		async (workflowId: string, documentId: string) => {
			const response = await request(`${path}?workflowId=${workflowId}&documentId=${documentId}`, 'DELETE')
			return response
		},
		[request]
	)

	const getWorkflowDocumentHistory = useCallback(
		async (workflowId: string, documentId: string) => {
			const response = await request(`/api/workflowDocumentHistory?workflowId=${workflowId}&documentId=${documentId}`)
			return response
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		assignDocumentForLoggedUser,
		getPossibleOwners,
		getUserPrivilige,
		setUserAsOwner,
		removeCurrentOwner,
		getWorkflowDocumentHistory,
	}
}

export default useDocumentOwnerService
