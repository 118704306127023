import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useAppSelector } from 'features'
import { useAutocompleteInputActions, useDocumentContractorActions } from 'features/actions'
import { useFormDataChange } from 'hooks'
import { IContractorBankAccount } from 'models'
import { useContractorBankAccountService, useContractorsService } from 'services'

import { AutocompleteInput } from '../autocomplete-input'

type Props = {
	documentId: string
	contractorId: string | null
	valueFieldName?: string
	contractorLabel: string | null
	readOnly: boolean
	showContractorBankAccount?: boolean
	contractorBankAccountProps?: { readOnly: boolean; value: string | null; label: string | null }
}

const ContractorForm = ({
	documentId,
	contractorId,
	valueFieldName = 'Contractor',
	contractorLabel,
	readOnly,
	showContractorBankAccount = false,
	contractorBankAccountProps,
}: Props) => {
	const { contractorData, listOfContractors, listOfBankAccounts } = useAppSelector(state => state.documentContractor)
	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { handleAutocompleteInputChange } = useFormDataChange()
	const { setContractorData, setlistOfContractors, setlistOfBankAccounts } = useDocumentContractorActions()
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getContractor, getContractorsLookup } = useContractorsService()
	const { getContractorBankAccounts } = useContractorBankAccountService()

	const { t } = useTranslation('dictionaries')

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const handleContractorChange = (newValue: any, fieldName: string, labelFieldName: string) => {
		handleAutocompleteInputChange(newValue, fieldName, labelFieldName)

		if (showContractorBankAccount) {
			// clears contractor bank account field when contractor changes
			handleAutocompleteInputChange({ value: '', label: '' }, 'ContractorBankAccount', 'ContractorBankAccountLabel')
		}
	}

	const getContractorsList = async (instanceId: string) => {
		if (listOfContractors.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getContractorsLookup()

				setlistOfContractors(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	const getContractorBankAccountsList = async (instanceId: string) => {
		if (contractorId && listOfBankAccounts.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getContractorBankAccounts(contractorId)

				setlistOfBankAccounts(
					response.map((el: IContractorBankAccount) => {
						return { Id: el.Id, Name: `${el.Number}, ${el.BankName || ''}` }
					})
				)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	const getContractorData = async () => {
		if (contractorId) {
			try {
				const response = await getContractor(contractorId)
				setContractorData(response)
			} catch (err) {
				console.error(err)
			}
		} else setContractorData(null)
	}

	useEffect(() => {
		getContractorData()
		setlistOfBankAccounts([])
	}, [contractorId])

	useEffect(() => {
		setlistOfContractors([])
	}, [documentId])

	return (
		<div className="w-full pb-4">
			<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
				{/* ContractorName */}
				<AutocompleteInput
					dimensions={{ xs: 12, sm: 12, md: 6, lg: 6 }}
					order={{ xs: 0, sm: 0, md: 0, lg: 0 }}
					initialValue={{
						value: contractorId || '',
						label: contractorLabel || '',
					}}
					onOpen={() => getContractorsList('ContractorForm.ContractorName')}
					onChange={handleContractorChange}
					options={listOfContractors}
					optionLabelFieldName="label"
					optionValueFieldName="value"
					readOnly={readOnly}
					instanceId="ContractorForm.ContractorName"
					inputLabel={t('Contractor.Contractor')}
					name={valueFieldName}
					labelFieldName="ContractorLabel"
				/>
				{/* ContractorNIP */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
					<TextField
						disabled={true}
						value={contractorData?.NIP || ''}
						name="ContractorNIP"
						label={t('Contractor.Fields.NIP')}
						fullWidth
					/>
				</Grid>
				{/* REGON */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
					<TextField
						disabled={true}
						value={contractorData?.REGON || ''}
						name="ContractorRegon"
						label={t('Contractor.Fields.REGON')}
						fullWidth
					/>
				</Grid>
				{/* Street */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 5, sm: 5, md: 3, lg: 3 }}>
					<TextField
						disabled={true}
						value={contractorData?.Street || ''}
						name="ContractorStreet"
						label={t('Contractor.Fields.Street')}
						fullWidth
					/>
				</Grid>
				{/* City */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 6, sm: 6, md: 4, lg: 4 }}>
					<TextField
						disabled={true}
						value={contractorData?.City || ''}
						name="ContractorCity"
						label={t('Contractor.Fields.City')}
						fullWidth
					/>
				</Grid>
				{/* KRS */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 3, sm: 3, md: 5, lg: 5 }}>
					<TextField
						disabled={true}
						value={contractorData?.KRS || ''}
						name="ContractorKRS"
						label={t('Contractor.Fields.KRS')}
						fullWidth
					/>
				</Grid>
				{!mobileView && (
					<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 4, sm: 4, md: 6, lg: 6 }}>
						{/* Empty Item */}
					</Grid>
				)}
				{/* StreetNumber */}
				<Grid xs={6} sm={3} md={1.5} lg={1.5} order={{ xs: 7, sm: 7, md: 7, lg: 7 }}>
					<TextField
						disabled={true}
						value={contractorData?.StreetNumber || ''}
						name="ContractorStreetNumber"
						label={t('Contractor.Fields.StreetNumber', {
							ns: 'dictionaries',
						})}
						fullWidth
					/>
				</Grid>
				{/* FlatNumber */}
				<Grid xs={6} sm={3} md={1.5} lg={1.5} order={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
					<TextField
						disabled={true}
						value={contractorData?.FlatNumber || ''}
						name="ContractorFlatNumber"
						label={t('Contractor.Fields.FlatNumber', {
							ns: 'dictionaries',
						})}
						fullWidth
					/>
				</Grid>
				{/* PostalCode */}
				<Grid xs={6} sm={3} md={1.5} lg={1.5} order={{ xs: 9, sm: 9, md: 9, lg: 9 }}>
					<TextField
						disabled={true}
						value={contractorData?.PostalCode || ''}
						name="ContractorPostalCode"
						label={t('Contractor.Fields.PostalCode', {
							ns: 'dictionaries',
						})}
						fullWidth
					/>
				</Grid>
				{/* Post */}
				<Grid xs={6} sm={3} md={1.5} lg={1.5} order={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
					<TextField
						disabled={true}
						value={contractorData?.Post || ''}
						name="ContractorPost"
						label={t('Contractor.Fields.Post', {
							ns: 'dictionaries',
						})}
						fullWidth
					/>
				</Grid>
				{/* PhoneNumber */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 11, sm: 11, md: 11, lg: 11 }}>
					<TextField
						disabled={true}
						value={contractorData?.PhoneNumber || ''}
						name="ContractorPhoneNumber"
						label={t('Contractor.Fields.PhoneNumber', {
							ns: 'dictionaries',
						})}
						fullWidth
					/>
				</Grid>
				{/* Email */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
					<TextField
						disabled={true}
						value={contractorData?.EMail || ''}
						name="ContractorEMail"
						label={t('Contractor.Fields.EMail')}
						fullWidth
					/>
				</Grid>
				{/* ContractorBankAccount */}
				{showContractorBankAccount && contractorId && (
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 12, md: 6, lg: 6 }}
						order={{ xs: 13, sm: 13, md: 13, lg: 13 }}
						initialValue={{
							value: contractorBankAccountProps?.value || '',
							label: contractorBankAccountProps?.label || '',
						}}
						onOpen={() => getContractorBankAccountsList('ContractorForm.ContractorBankAccount')}
						onChange={handleAutocompleteInputChange}
						options={listOfBankAccounts}
						readOnly={contractorBankAccountProps?.readOnly !== undefined ? contractorBankAccountProps.readOnly : true}
						instanceId="ContractorForm.ContractorBankAccount"
						inputLabel={t('Contractor.Fields.ContractorBankAccount')}
						name="ContractorBankAccount"
						labelFieldName="ContractorBankAccountLabel"
					/>
				)}
			</Grid>
		</div>
	)
}

export default ContractorForm
