import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ILinkDocumentData } from 'models'

type InitialState = {
	copiedDocumentData: ILinkDocumentData
	isLinkEntryDialogOpen: boolean
}

const initialState: InitialState = {
	copiedDocumentData: { id: '', name: '', documentType: '', otherData: {} },
	isLinkEntryDialogOpen: false,
}

export const linkDocumentSlice = createSlice({
	name: 'linkDocumentSlice',
	initialState,
	reducers: {
		setLinkDocumentData: (state, action: PayloadAction<ILinkDocumentData>) => {
			state.copiedDocumentData = action.payload
		},
		setIsLinkEntryDialogOpen: (state, action: PayloadAction<boolean>) => {
			state.isLinkEntryDialogOpen = action.payload
		},
	},
})

export const linkDocumentActions = linkDocumentSlice.actions

export default linkDocumentSlice.reducer
