import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	Stack,
	TextField,
} from '@mui/material'

import { Loading } from 'components/shared/loading'
import { NoData } from 'components/shared/no-data-message'
import { PATHS } from 'data'
import { useAppSelector, useDocumentActionsStateActions } from 'features'
import { useHandleNavigate } from 'hooks'
import { Action, ICorrectionInvoice, IIncomingLetter, IMaterialInvoice } from 'models'
import { useCorrectionInvoiceService, useMaterialInvoiceService, useWorkflowService } from 'services'
import { formatDateTimeMUI } from 'utils/formatDateTime'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

type Props = {
	isOpen: boolean
	action: Action
	invokeAction: (value: Action, parameters?: any) => void
}

const CreateCorrectionInvoiceFromIncomingLetterDialog = ({ isOpen, action, invokeAction }: Props) => {
	const [workflowId, setWorkflowId] = useState('')
	const [selectedDocument, setSelectedDocument] = useState<IMaterialInvoice | ICorrectionInvoice>()
	const [invoiceType, setInvoiceType] = useState<'materialInvoice' | 'correctionInvoice'>('correctionInvoice')
	const [searchValue, setSearchValue] = useState('')
	const [workflow, setWorkflow] = useState([])
	const [documents, setDocuments] = useState<IMaterialInvoice[] | ICorrectionInvoice[] | any[]>([])

	const [notFound, setNotFound] = useState(false)
	const [documentLoading, setDocumentLoading] = useState(false)
	const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false)

	const { documentData } = useAppSelector(state => state.documentData)
	const { selectedDocumentType } = useAppSelector(state => state.documentActionsState)
	const { setIsCreateCorrectionInvoiceDialogOpen } = useDocumentActionsStateActions()
	const { handleNavigateWithState } = useHandleNavigate()

	const { getMaterialInvoices } = useMaterialInvoiceService()
	const { getWorkflowLookupList } = useWorkflowService()
	const { getCorrectionInvoices } = useCorrectionInvoiceService()

	const { Contractor } = documentData as IIncomingLetter

	const { t } = useTranslation()

	const searchView = !documents.length
	const warningView = documents.length > 1
	const workflowView = documents.length === 1

	const getDocumentTypeParams = () => {
		switch (selectedDocumentType) {
			case 'CorrectionInvoice':
				return 'correctionInvoiceWorkflowId'
			default:
				return 'correctionInvoiceWorkflowId'
		}
	}

	const closeDialog = () => {
		setIsCreateCorrectionInvoiceDialogOpen(false)
	}

	const handleLinkClick = (e: React.MouseEvent, data: { id: string; documentLabel: string }) => {
		const { id, documentLabel } = data

		closeDialog()

		const path = invoiceType === 'correctionInvoice' ? PATHS.correctionInvoice : PATHS.materialInvoice

		handleNavigateWithState(path, id, documentLabel, {})
	}

	const onWorkflowChange = (value: string) => {
		setWorkflowId(value)
		setSaveButtonEnabled(true)
	}

	const onSearchDocumentClick = async () => {
		setDocumentLoading(true)
		try {
			let response: any[] = []

			const correctionInvoiceResponse = await getCorrectionInvoices({
				isCanceled: false,
				isCompleted: true,
				materialInvoiceForeignNumberLastCorrection: searchValue,
			})

			if (!correctionInvoiceResponse.length) {
				const materialInvoiceResponse = await getMaterialInvoices({
					contractorId: Contractor,
					foreignNumber: searchValue,
				})

				setInvoiceType('materialInvoice')
				response = materialInvoiceResponse
			} else {
				setInvoiceType('correctionInvoice')
				response = correctionInvoiceResponse
			}

			if (response.length === 1) {
				setSelectedDocument(response[0])
				setNotFound(false)
			}

			if (!response.length) {
				setNotFound(true)
			}

			setDocuments(response)
		} catch (err) {
			console.error(err)
		} finally {
			setDocumentLoading(false)
		}
	}

	const handleReset = () => {
		setSelectedDocument({} as IMaterialInvoice)
		setSearchValue('')
		setDocuments([])
		setNotFound(false)
	}

	const handleCreateDocument = async () => {
		const paramKey = getDocumentTypeParams()
		const invoiceIdKey = invoiceType === 'correctionInvoice' ? 'correctionInvoiceId' : 'materialInvoiceId'

		let parameters = {
			[paramKey]: workflowId,
			[invoiceIdKey]: selectedDocument?.Id,
		}

		invokeAction(action, parameters)
		closeDialog()
	}

	const getWorkflowData = async () => {
		try {
			const response = await getWorkflowLookupList(selectedDocumentType)

			setWorkflow(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		if (workflowView) getWorkflowData()
	}, [workflowView])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('CreateCorrectionFromIncomingLetterDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{documentLoading ? (
					<Loading />
				) : (
					<>
						{searchView && (
							<TextField
								value={searchValue}
								name="Search"
								label={t('CreateCorrectionFromIncomingLetterDialog.SearchDocumentByForeignNumber')}
								onChange={e => setSearchValue(e.target.value)}
								fullWidth
								sx={{ mt: '10px' }}
							/>
						)}
						{workflowView && (
							<Box sx={sxStyles.workflowViewContainer}>
								<Box sx={sxStyles.workflowViewMessage}>
									{t('CreateCorrectionFromIncomingLetterDialog.DocumentFound')}
									{invoiceType === 'correctionInvoice' ? (
										<>
											<Stack
												sx={sxStyles.documentName}
												onClick={e =>
													handleLinkClick(e, {
														id: documents[0].Id,
														documentLabel: `${documents[0].Number}`,
													})
												}>
												{`${t('CorrectionInvoice', { ns: 'routes' })} ${documents[0].Number}`}
											</Stack>
											<Stack>{`${t('CorrectionInvoice.Fields.DocumentDate', { ns: 'invoice' })}: ${formatDateTimeMUI(documents[0].DocumentDate) || ''}`}</Stack>
											<Stack>{`${t('CorrectionInvoice.Fields.MaterialInvoice', { ns: 'invoice' })}: ${documents[0].MaterialInvoiceLabel || ''}`}</Stack>
										</>
									) : (
										<>
											<Stack
												sx={sxStyles.documentName}
												onClick={e =>
													handleLinkClick(e, {
														id: documents[0].Id,
														documentLabel: `${documents[0].Number}`,
													})
												}>
												{`${t('MaterialInvoice', { ns: 'routes' })} ${documents[0].Number}`}
											</Stack>
											<Stack>{`${t('MaterialInvoice.Fields.ForeignNumber', { ns: 'invoice' })}: ${documents[0].ForeignNumber || ''}`}</Stack>
											<Stack>{`${t('MaterialInvoice.Fields.IssueDate', { ns: 'invoice' })}: ${formatDateTimeMUI(documents[0].IssueDate) || ''}`}</Stack>
											<Stack>{`${t('MaterialInvoice.Fields.ValueBrutto', { ns: 'invoice' })}: ${formatNumberDataGrid(documents[0].ValueBrutto) || ''}`}</Stack>
										</>
									)}
								</Box>
								<TextField
									value={workflowId}
									select
									name="Workflow"
									label={t('CreateCorrectionFromIncomingLetterDialog.WorkflowSelectLabel')}
									onChange={e => onWorkflowChange(e.target.value)}
									fullWidth
									sx={{ mt: '10px' }}>
									{workflow.map((el: any, index: number) => (
										<MenuItem key={el.label} value={el.value}>
											{el.label}
										</MenuItem>
									))}
								</TextField>
							</Box>
						)}
						{notFound && <NoData fontSize="14" />}
						{warningView && (
							<Box sx={sxStyles.warningMessage}>
								<WarningIcon color="warning" sx={{ pr: '5px' }} />
								{t('CreateCorrectionFromIncomingLetterDialog.FoundMoreThanOneDocument')}
								<Box sx={{ display: 'flex', flexDirection: 'column' }}>
									{documents.map(el => {
										return (
											<Stack
												key={el.Id}
												sx={sxStyles.documentName}
												onClick={e =>
													handleLinkClick(e, {
														id: el.Id,
														documentLabel: `${el.Number}`,
													})
												}>
												{`${t('MaterialInvoice', { ns: 'routes' })} ${el.Number} (${el.ForeignNumber})`}
											</Stack>
										)
									})}
								</Box>
							</Box>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				{searchView && (
					<Button
						variant="contained"
						color="success"
						disabled={documentLoading || !searchValue}
						onClick={onSearchDocumentClick}>
						{t('general.Search')}
					</Button>
				)}
				{workflowView && (
					<>
						<Button variant="text" color="warning" onClick={handleReset}>
							{t('general.Return')}
						</Button>
						<Button variant="contained" color="success" disabled={!isSaveButtonEnabled} onClick={handleCreateDocument}>
							{t('general.Create')}
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	)
}

export default CreateCorrectionInvoiceFromIncomingLetterDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	workflowViewContainer: { display: 'flex', flexDirection: 'column' },
	workflowViewMessage: {
		width: '100%',
		p: '3px',
		mt: '6px',
		textAlign: 'center',
	},
	documentName: {
		pt: '8px',
		color: 'primary.main',
		cursor: 'pointer',
		'&:hover': { textDecoration: 'underline' },
	},
	warningMessage: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		p: '10px',
		pt: '20px',
		color: 'warning.main',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
