import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ChangeHistoryDialog from '../change-history/ChangeHistoryDialog'
import Loading from '../loading/Loading'
import DataGridComponent from './DataGridComponent'
import styles from './Styles.module.css'
import BudgetFormDialog from './budget-form-dialog/BudgetFormDialog'
import ImportExcelBudget from './import-excel-budget/ImportExcelBudget'
import ReorderDialog from './reorder-dialog/ReoderDialog'

interface Props {
	projectId: string
	columns: any
	api: any
	readOnly: boolean
	budgetType: string
}

const BudgetDataGrid = ({ projectId, columns, api, readOnly, budgetType }: Props) => {
	const [budgetData, setBudgetData] = useState<any[]>([])
	const [disabledAddButton, setDisabledAddButton] = useState(false)
	const [measurementUnits, setMeasurementUnits] = useState<any>([])
	const [industries, setIndustries] = useState<any>([])
	const [vatRate, setVatRate] = useState<any>([])
	const [dataLoading, setDataLoading] = useState(true)

	const [isFormDialogOpen, setIsFormDialogOpen] = useState(false)
	const [rowIdToChangeOrder, setRowIdToChangeOrder] = useState<string | undefined>()
	const [isReorderDialogOpen, setIsReorderDialogOpen] = useState(false)
	const [formDialogData, setFormDialogData] = useState<any>()
	const [isImportRunning, setIsImportRunning] = useState<Number | undefined>(undefined)
	const [importStatusMessage, setImportStatusMessage] = useState<string>('')
	const [importErrosList, setImportErrosList] = useState()
	const [isImportDialogVisible, setIsImportDialogVisible] = useState(false)
	const [rerender, setRerender] = useState(0)

	const [changeHistoryDialogData, setChangeHistoryDialogData] = useState<any>()
	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [selectedEntryId, setSelectedEntryId] = useState('')

	const { t } = useTranslation('project')

	const getData = useCallback(async () => {
		try {
			const response = await api.getData(projectId)
			setBudgetData(response)
			setDataLoading(false)
		} catch (err) {
			console.error(err)
		}
		try {
			const response = await api.getMeasurmentUnits()
			setMeasurementUnits(response)
		} catch (err) {
			console.error(err)
		}

		if (api.getVatRate) {
			try {
				const response = await api.getVatRate()
				setVatRate(response)
			} catch (err) {
				console.error(err)
			}
		}

		if (api.getIndustries) {
			try {
				const response = await api.getIndustries()
				setIndustries(
					response.map((el: any) => {
						return { value: el.Id, label: el.Name }
					})
				)
			} catch (err) {
				console.error(err)
			}
		}
	}, [projectId, api])

	const getImportStatus = async () => {
		const response = await api.importBudgetStatus(projectId)

		setIsImportRunning(response.IsInProgress)

		if (response.LastErrors) {
			setImportErrosList(response.LastErrors)
		}

		if (response.IsInProgress) {
			setTimeout(() => {
				setImportStatusMessage(response.Status)
				setRerender(prev => prev + 1)
			}, 1000)
		} else {
			getData()
		}
	}

	useEffect(() => {
		getImportStatus()
	}, [rerender, projectId])

	useEffect(() => {
		readOnly ? setDisabledAddButton(true) : setDisabledAddButton(false)
	}, [readOnly])

	if (isImportRunning) {
		return (
			<div className={styles.importStatusContainer}>
				<span>
					<Loading />
				</span>
				<span className={styles.importStatusMessage}>{importStatusMessage}</span>
			</div>
		)
	} else {
		return (
			<>
				<BudgetFormDialog
					isOpen={isFormDialogOpen}
					setIsFormDialogOpen={setIsFormDialogOpen}
					data={formDialogData}
					api={api}
					rerender={setRerender}
					budgetType={budgetType}
				/>
				<ImportExcelBudget
					projectId={projectId}
					rerender={setRerender}
					isOpen={isImportDialogVisible}
					setIsOpen={setIsImportDialogVisible}
					api={api}
				/>
				<ReorderDialog
					rerender={setRerender}
					isOpen={isReorderDialogOpen}
					setIsOpen={setIsReorderDialogOpen}
					rowIdToChangeOrder={rowIdToChangeOrder}
					api={api}
				/>
				<DataGridComponent
					projectId={projectId}
					columns={columns}
					api={api}
					budgetData={budgetData}
					dataLoading={dataLoading}
					setBudgetData={setBudgetData}
					setRerender={setRerender}
					disabledAddButton={disabledAddButton}
					setDisabledAddButton={setDisabledAddButton}
					setIsFormDialogOpen={setIsFormDialogOpen}
					setFormDialogData={setFormDialogData}
					setIsImportDialogVisible={setIsImportDialogVisible}
					measurementUnits={measurementUnits}
					industries={industries}
					vatRate={vatRate}
					setIsReorderDialogOpen={setIsReorderDialogOpen}
					setRowIdToChangeOrder={setRowIdToChangeOrder}
					setChangeHistoryDialogData={setChangeHistoryDialogData}
					setIsChangeHistoryDialogOpen={setIsChangeHistoryDialogOpen}
					setSelectedEntryId={setSelectedEntryId}
					importErrosList={importErrosList}
					readOnly={readOnly}
					budgetType={budgetType}
				/>
				{isChangeHistoryDialogOpen && (
					<ChangeHistoryDialog
						contextId={selectedEntryId}
						isOpen={isChangeHistoryDialogOpen}
						setIsOpen={setIsChangeHistoryDialogOpen}
						title={`${t('BudgetTabGeneral.GroupingColumn')} ${
							changeHistoryDialogData?.data.NumberString
						}, ${changeHistoryDialogData?.data.Description}`}
						translationFile="project"
						translationPath={changeHistoryDialogData.translationPath}
					/>
				)}
			</>
		)
	}
}

export default BudgetDataGrid
