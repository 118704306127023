import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'

import { useCommentsService } from 'services'

interface Props {
	discussionId: string | undefined
	refreshCommentsList: () => void
}

const CommentsForm = ({ discussionId, refreshCommentsList }: Props) => {
	const [inputValue, setInputValue] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation()

	const { addComment } = useCommentsService()

	const handleSubmitComment = useCallback(async () => {
		try {
			setButtonLoading(true)
			await addComment({ discussionId, text: inputValue })
			refreshCommentsList()
			setInputValue('')
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}, [discussionId, addComment, inputValue])

	return (
		<form className={'flex flex-col items-end mt-4'}>
			<TextField
				name="comment"
				className="mb-4"
				value={inputValue}
				onChange={e => setInputValue(e.target.value)}
				placeholder={t('CommentsPage.InputPlaceholder') as string}
				fullWidth
				multiline
				minRows={4}
			/>
			<LoadingButton disabled={!inputValue} onClick={handleSubmitComment} loading={buttonLoading} variant="contained">
				{t('general.Add')}
			</LoadingButton>
		</form>
	)
}

export default CommentsForm
