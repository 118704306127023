import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { MenuItem } from '@mui/material'
import Dialog from '@mui/material/Dialog/Dialog'
import DialogContent from '@mui/material/DialogContent/DialogContent'
import DialogTitle from '@mui/material/DialogTitle/DialogTitle'
import IconButton from '@mui/material/IconButton/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'

import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import AddFile from './AddFile'
import AddFolder from './AddFolder'

interface Props {
	contextId: string
	directoryId?: string
	updateAttachments: React.DispatchWithoutAction
	setAsDefaultFile?: boolean
}

const AddAttachment = ({ contextId, directoryId, updateAttachments, setAsDefaultFile }: Props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [selectedValue, setSelectedValue] = useState<number | string>(2)

	const { t } = useTranslation(['attachments', 'translation'])

	const selectBoxOptions = [
		{ value: 1, label: t('AddAttachmentDialog.SelectOptions.Folder') },
		{ value: 2, label: t('AddAttachmentDialog.SelectOptions.File') },
	]

	const handleOpenDialog = () => {
		setIsOpen(true)
	}

	const handleCloseDialog = () => {
		setIsOpen(false)
		setSelectedValue('')
	}

	const handleSelectValueChange = (e: any) => {
		setSelectedValue(e.target.value)
	}

	return (
		<>
			<Tooltip title={<TooltipTextWrapper title={t('AddAttachmentDialog.AddAttachment')} />} placement="top" arrow>
				<span>
					<IconButton sx={{ padding: '5px' }} color="primary" onClick={handleOpenDialog}>
						<AddIcon fontSize="small" />
					</IconButton>
				</span>
			</Tooltip>
			<Dialog open={isOpen} fullWidth>
				<DialogTitle
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						fontSize: '20px',
					}}
					component="div">
					{t('AddAttachmentDialog.Title')}
					<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<TextField
						value={selectedValue}
						select
						id="selectAttachmentType"
						name="selectAttachmentType"
						label={t('AddAttachmentDialog.TypeInputLabel')}
						onChange={handleSelectValueChange}
						fullWidth
						sx={{ mt: '10px' }}>
						{selectBoxOptions.map((el: any, index: number) => (
							<MenuItem key={index} value={el.value}>
								{el.label}
							</MenuItem>
						))}
					</TextField>
					{selectedValue === 1 ? (
						<AddFolder
							contextId={contextId}
							directoryId={directoryId}
							handleCloseDialog={handleCloseDialog}
							updateAttachments={updateAttachments}
						/>
					) : selectedValue === 2 ? (
						<AddFile
							contextId={contextId}
							directoryId={directoryId}
							handleCloseDialog={handleCloseDialog}
							updateAttachments={updateAttachments}
							setAsDefaultFile={setAsDefaultFile}
						/>
					) : null}
				</DialogContent>
			</Dialog>
		</>
	)
}

export default AddAttachment
