import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useProcessingProtocolEntriesService = () => {
	const { request, downloadFileRequest, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/processingProtocolEntry'

	const getProtocolEntries = useCallback(
		async (protocolId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&protocolId=${protocolId}`)
			return response
		},
		[request]
	)

	const updateProtocolEntry = useCallback(
		async (entryId: string, data: any) => {
			await request(`${path}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	const exportExcel = useCallback(
		async (protocolId: string) => {
			const response = await downloadFileRequest(`/api/processingProtocol/exportToXlsx/${protocolId}`)
			return response
		},
		[downloadFileRequest]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getProtocolEntries,
		updateProtocolEntry,
		exportExcel,
	}
}

export default useProcessingProtocolEntriesService
