import { useTranslation } from 'react-i18next'

import { Box, Divider, Typography } from '@mui/material'

import { IChangeHistoryField } from 'models'

interface Props {
	data: IChangeHistoryField[]
	translationFile: string
	translationPath: string
}

const ListItemDetails = ({ data, translationFile, translationPath }: Props) => {
	const { t } = useTranslation(['translation', translationFile])

	const checkFieldExistence = (fieldKey: string, namespace: string) => {
		return t(fieldKey, { ns: namespace }) !== fieldKey
	}

	const dataToRender = data.filter(el => el.FieldName !== 'DiscussionId')

	return (
		<>
			<Divider />
			<Box sx={{ display: 'flex', p: '10px 5px' }}>
				<Typography sx={sxStyles.fieldsTitles} color="inherit">
					{t('ChangeHistory.ListOfChangedFields.FieldName')}
				</Typography>
				<Typography sx={sxStyles.fieldsTitles} color="inherit">
					{t('ChangeHistory.ListOfChangedFields.OldValue')}
				</Typography>
				<Typography sx={sxStyles.fieldsTitles} color="inherit">
					{t('ChangeHistory.ListOfChangedFields.NewValue')}
				</Typography>
			</Box>
			{dataToRender.map((item, index) => {
				return (
					<Box
						key={index}
						sx={{
							display: 'flex',
							p: '5px',
							backgroundColor: index % 2 === 0 ? 'background.secondary' : 'inherit',
						}}>
						<Typography sx={sxStyles.fields} color="inherit">
							{t(`${translationPath}.${item.FieldName}`, {
								ns: translationFile,
							})}
						</Typography>
						<Typography sx={{ ...sxStyles.fields, ...sxStyles.fieldOldValue }} color="inherit">
							{checkFieldExistence(`${translationPath}.${item.FieldName}Enums`, translationFile)
								? t(`${translationPath}.${item.FieldName}Enums.${item.OldFieldValue}`, {
										ns: translationFile,
									})
								: item.OldFieldValue}
						</Typography>
						<Typography sx={{ ...sxStyles.fields, ...sxStyles.fieldNewValue }} color="inherit">
							{checkFieldExistence(`${translationPath}.${item.FieldName}Enums`, translationFile)
								? t(`${translationPath}.${item.FieldName}Enums.${item.NewFieldValue}`, {
										ns: translationFile,
									})
								: item.NewFieldValue}
						</Typography>
					</Box>
				)
			})}
		</>
	)
}

export default ListItemDetails

const sxStyles = {
	fieldsTitles: {
		width: '33%',
		fontSize: '14px',
		fontWeight: 500,
	},
	fields: {
		width: '33%',
		fontSize: '13px',
		color: 'text.secondary',
	},
	fieldNewValue: {
		color: 'info.main',
	},
	fieldOldValue: {
		color: 'warning.main',
	},
}
