import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'

import { LicenseInfo } from '@mui/x-license-pro'

import Providers from 'components/root/Providers'
import { MUI_LICENSE } from 'data'
import { store } from 'features'
import { getCurrentTheme, switchToTheme } from 'utils/themes'

import './i18n'
import './index.css'
import './polyfills'

LicenseInfo.setLicenseKey(MUI_LICENSE)

switchToTheme(getCurrentTheme())

const container = document.getElementById('root')

if (container == null) throw new Error("Cannot initialize application, div with 'root' id cannot be found in document")

const root = ReactDOMClient.createRoot(container)

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Providers />
		</Provider>
	</React.StrictMode>
)
