import CloseIcon from '@mui/icons-material/Close'
import { Box, Stack } from '@mui/material'

import { TooltipButton, usePrepareNotificationTranslation } from 'components/shared'
import { useAppSelector, useNotificationsActions } from 'features'
import { useHandleNavigate } from 'hooks'
import { INotification } from 'models'
import { useNotificationService } from 'services'
import { formatDateTime } from 'utils/formatDateTime'

import { styles } from './styles'

type Props = {
	notification: INotification
	closeMenu: () => void
}

const SingleNotification = ({ notification, closeMenu }: Props) => {
	const { notReadedNotifications, notificationsCount } = useAppSelector(state => state.notifications)

	const { getNotificationTranslation } = usePrepareNotificationTranslation()

	const date = formatDateTime(notification.DateTime.toLocaleString())
	const translation = getNotificationTranslation(notification)

	const { handleNavigate } = useHandleNavigate()
	const { setNotReadedNotifications, setNotificationsCount } = useNotificationsActions()
	const { updateNotification } = useNotificationService()

	const handleMarkAsRead = async (e?: React.MouseEvent) => {
		e?.stopPropagation()
		try {
			await updateNotification(notification.Id, { ...notification, IsReaded: true })

			setNotReadedNotifications(notReadedNotifications.filter(el => el.Id !== notification.Id))
			setNotificationsCount(notificationsCount - 1)
		} catch (err) {
			console.error(err)
		}
	}

	const handleNotificatonClick = () => {
		if (notification.ContextRootingPath) {
			handleNavigate(notification, notification.ContextRootingPath, 'ContextItemIdLabel', 20, 'ContextItemId')
			handleMarkAsRead()
			closeMenu()
		} else {
			return
		}
	}

	return (
		<Box sx={styles.singleNotification} onClick={handleNotificatonClick}>
			<Box sx={styles.singleNotification.message}>{translation}</Box>
			<Box sx={styles.singleNotification.actionContainer}>
				<TooltipButton
					fontSize="small"
					title="MarkAsRead"
					translationFile="notifications"
					onClick={handleMarkAsRead}
					IconComponent={CloseIcon}
					color="inherit"
				/>
				<Stack component="div" sx={styles.singleNotification.date}>
					{date}
				</Stack>
			</Box>
		</Box>
	)
}

export default SingleNotification
