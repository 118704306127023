import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
}

const InvestorSalesInvoiceEntriesToolbar = ({ apiRef }: Props) => {
	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer className="flex justify-between m-1">
			<div>
				<ColumnsButton />
			</div>
			<div>
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton title="general.Filter" onClick={handleFilter} IconComponent={FilterListIcon} fontSize="medium" />
			</div>
		</GridToolbarContainer>
	)
}

export default InvestorSalesInvoiceEntriesToolbar
