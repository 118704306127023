import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'Description',
			'Rate',
			'DateFrom',
			'DateTo',
			'MeasurementUnitId',
			'Amount',
			'ValueToBeSettled',
			'ValueSettled',
			'VatRateId',
			'BudgetEntryId',
			'BudgetEntryDescription',
			'MasterArticleId',
			'actions',
		],
		dimensions: {
			Description: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			Rate: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			DateFrom: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			DateTo: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			ValueToBeSettled: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueSettled: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			VatRateId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			BudgetEntryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			BudgetEntryDescription: {
				maxWidth: -1,
				minWidth: 50,
				width: 300,
			},
			MasterArticleId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 130,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'contractAdditionalCostsDataGridState'
