import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Avatar, Box, List, ListItem, ListItemAvatar, Stack, Typography } from '@mui/material'

import { IOfferDraftAttachment } from 'models'
import { useQuotationRequestOfferDraft } from 'services'
import prepareTitleString from 'utils/prepareTitleString'

interface Props {
	offerToken: string
}

const Attachments = ({ offerToken }: Props) => {
	const [attachments, setAttachments] = useState<IOfferDraftAttachment[]>([])

	const { getAttachments, downloadAttachment } = useQuotationRequestOfferDraft()

	const { t } = useTranslation(['demand', 'translation'])

	const handleDownload = async (attachmentId: string) => {
		try {
			await downloadAttachment(offerToken, attachmentId)
		} catch (err) {
			console.error(err)
		}
	}

	const getData = async () => {
		try {
			const response = await getAttachments(offerToken)

			setAttachments(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getData()
	}, [offerToken])

	return (
		<>
			{attachments.length > 0 ? (
				<Box sx={sxStyles.container}>
					<Typography component="div" sx={sxStyles.title}>
						{t('QuotationRequestOfferDraft.Fields.Attachments')}
					</Typography>
					<List>
						{attachments.map((item, index) => {
							return (
								<ListItem key={index} sx={sxStyles.listItem}>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<ListItemAvatar sx={{ minWidth: '40px' }}>
											<Avatar sx={sxStyles.avatar}>
												<AttachFileIcon sx={{ fontSize: '16px' }} />
											</Avatar>
										</ListItemAvatar>
										<Stack sx={sxStyles.attachmentLink} component="span" onClick={() => handleDownload(item.Id)}>
											{prepareTitleString(item.Name)}
										</Stack>
									</Box>
								</ListItem>
							)
						})}
					</List>
				</Box>
			) : null}
		</>
	)
}

export default Attachments

const sxStyles = {
	container: { maxWidth: '500px', bgcolor: 'background.paper' },
	title: { textAlign: 'center', fontSize: '16px' },
	listItem: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderRadius: '5px',
		m: '5px',
		ml: '0',
		bgcolor: 'background.secondary',
		bgOpacity: '0.8',
	},
	avatar: {
		width: '22px',
		height: '22px',
		bgcolor: 'primary.main',
	},
	attachmentLink: {
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
			color: 'primary.main',
		},
	},
}
