import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import { indigo } from '@mui/material/colors'
import {
	DataGridPremium,
	DataGridPremiumProps,
	GridCellParams,
	GridColumnHeaderParams,
	GridEventListener,
	GridRenderCellParams,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	GridRowTreeNodeConfig,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { ColumnHeaderRender, LinkCellRender, TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { PATHS } from 'data'
import { BudgetCostTypeDataSource } from 'data/lookup-data-sources'
import { useAppSelector } from 'features'
import { useDataGridState, useLocalStorageState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { ICostInvoiceSettlement } from 'models'
import { useCostInvoiceSettlementService, useMeasurementUnitsService } from 'services'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import SettlementToolbar from './SettlementToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	invoiceId: string
	readOnly: boolean
}

const Settlement = ({ invoiceId, readOnly }: Props) => {
	const [settlementData, setSettlementData] = useState<ICostInvoiceSettlement[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [measurementUnits, setMeasurementUnits] = useState<any>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [isTreeData, setIsTreeData] = useLocalStorageState('listTreeData', false)

	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [changeHistoryDialogData, setChangeHistoryDialogData] = useState<ICostInvoiceSettlement>()
	const [selectedEntryId, setSelectedEntryId] = useState('')

	const { documentData } = useAppSelector(state => state.documentData)

	const { t } = useTranslation(['invoice', 'translation'])

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()
	const { updateSettlement, showSuccessInfo, getSettlements } = useCostInvoiceSettlementService()
	const { getMeasurementUnitsLookup } = useMeasurementUnitsService()
	const { dataGridLanguage } = useLanguage()

	const localStorageData = localStorage.getItem('config_costInvoice_settlement')

	const apiRef = useGridApiRef()

	const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
		headerName: t('CostInvoice.Settlement.GroupingColumn') as string,
		minWidth: 130,
		width: 300,
		headerAlign: 'center',
	}

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id
		const isEditable = !readOnly

		if (isEditable)
			setRowModesModel({
				...rowModesModel,
				[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'SettlementValue' },
			})
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = settlementData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setSettlementData(settlementData.filter((row: any) => row.Id !== id))
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setChangeHistoryDialogData(params.row)
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		const updatedRow = { ...newRow, isNew: false }
		try {
			await updateSettlement(newRow.Id, newRow as ICostInvoiceSettlement)
			setSettlementData(settlementData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
			showSuccessInfo('saved')
			getData()
		} catch (err) {
			console.error(err)
		}
		return updatedRow
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('CostInvoice.Settlement.Name'),
			headerAlign: 'center',
			valueGetter: (params: any) => {
				if (params.row.CostInvoiceId) {
					return params.value
				} else {
					return params.row.BudgetDescription
				}
			},
			align: 'left',
			sortable: true,
			editable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="CostInvoice.Settlement" />
			),
		},
		{
			field: 'CostType',
			headerName: t('CostInvoice.Settlement.CostType'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.CostType || 'Undefined'
			},
			valueOptions: () =>
				BudgetCostTypeDataSource.map((el: any) => ({
					value: el.value,
					label: t(`CostInvoice.Settlement.CostTypeEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = BudgetCostTypeDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return t(`CostInvoice.Settlement.CostTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="CostInvoice.Settlement" />
			),
		},
		{
			field: 'MeasurementUnitId',
			headerName: t('CostInvoice.Settlement.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.MeasurementUnitId || ''
			},
			valueOptions: measurementUnits,
			valueFormatter: ({ value }: any) => {
				const option = measurementUnits.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="CostInvoice.Settlement" />
			),
		},
		{
			field: 'Parameters',
			headerName: t('CostInvoice.Settlement.Parameters'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="CostInvoice.Settlement" />
			),
		},
		{
			field: 'SettlementValue',
			headerName: t('CostInvoice.Settlement.SettlementValue'),
			headerAlign: 'center',
			valueGetter: (params: any) => {
				if (params.row.CostInvoiceId) {
					return params.value
				} else {
					return params.row.BudgetValue
				}
			},
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="CostInvoice.Settlement" />
			),
		},
		{
			field: 'BudgetEntryId',
			headerName: t('CostInvoice.Settlement.BudgetEntryId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => (
				<LinkCellRender
					path={PATHS.project}
					id={documentData?.Project as string}
					documentLabel={documentData?.ProjectLabel as string}
					celllabel={params.row.BudgetEntryIdLabel}
					state={{ tab: 1, rowIdToFocus: params.value }}
				/>
			),
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="CostInvoice.Settlement" />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit
				const budgetEntry = !params.row.CostInvoiceId

				if (budgetEntry) {
					return []
				}

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="ChangeHistory.ChangeHistory"
							onClick={handleShowHistory(params)}
							IconComponent={HistoryIcon}
							disabled={isActionButtonDisabled}
						/>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const isGroupExpandedByDefault: any = (node: GridRowTreeNodeConfig) => {
		if (localStorageData?.includes(node.id as string)) {
			return true
		} else {
			return false
		}
	}

	const getData = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getSettlements(invoiceId, isTreeData)
			setSettlementData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [invoiceId, getSettlements, isTreeData])

	const getMeasurementUnitsList = useCallback(async () => {
		try {
			const response = await getMeasurementUnitsLookup()
			setMeasurementUnits(response)
		} catch (err) {
			console.error(err)
		}
	}, [getMeasurementUnitsLookup])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getMeasurementUnitsList()
	}, [])

	useEffect(() => {
		getData()
	}, [isTreeData, invoiceId])

	useEffect(() => {
		let storage: any
		if (localStorageData) {
			storage = JSON.parse(localStorageData)
		} else {
			storage = { rowsExpandedByDefault: [] }
		}
		apiRef.current.subscribeEvent('rowExpansionChange', node => {
			if (node.childrenExpanded) {
				storage?.rowsExpandedByDefault.push(node.id)
			} else {
				const deleteRowIdFromStorage = storage.rowsExpandedByDefault.filter((item: any) => {
					return item !== node.id
				})
				storage = {
					rowsExpandedByDefault: [...deleteRowIdFromStorage],
				}
			}
			localStorage.setItem('config_costInvoice_settlement', JSON.stringify(storage))
		})
	}, [apiRef])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState, apiRef])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				getTreeDataPath={row => row.TreePath}
				treeData
				columns={orderedColumns}
				rows={settlementData}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				onCellDoubleClick={handleCellDoubleClick}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				experimentalFeatures={{ newEditingApi: true, aggregation: true }}
				sx={{
					minHeight: '500px',
					height: 'calc(100vh - 250px)',
					border: 'none',
					'& .rowFromBudget': {
						color: indigo[300],
					},
				}}
				getRowClassName={params => {
					if (params.id === params.row.BudgetEntryId) {
						return `rowFromBudget`
					} else {
						return 'simpleRow'
					}
				}}
				groupingColDef={groupingColDef}
				components={{
					Toolbar: SettlementToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						isActionButtonDisabled,
						isTreeData,
						setIsTreeData,
					},
				}}
				initialState={INITIALSTATE}
				getAggregationPosition={groupNode => (groupNode == null ? 'footer' : null)}
				rowHeight={35}
				isGroupExpandedByDefault={isGroupExpandedByDefault}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={`${changeHistoryDialogData?.TreePath.slice(-1)}`}
					translationFile="invoice"
					translationPath="CostInvoice.Settlement"
				/>
			)}
		</>
	)
}

export default Settlement
