import { MutableRefObject, useEffect, useState } from 'react'

import FilterListIcon from '@mui/icons-material/FilterList'
import { Badge } from '@mui/material'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import { TooltipButton } from 'components/shared/buttons'

type Props = {
	apiRef: MutableRefObject<GridApiPremium>
	handleOpenFilterMenu: () => void
}

const FilterButton = ({ apiRef, handleOpenFilterMenu }: Props) => {
    const [filterBadge, setFilterBadge] = useState<number | undefined>(undefined)
    
    const { filter } = apiRef.current.exportState()

	useEffect(() => {
		setFilterBadge(filter?.filterModel?.items.length)
	}, [filter])

	return (
		<Badge
			sx={{
				'& .MuiBadge-badge': {
					right: 26,
					top: 8,
					width: '14px',
					height: '14px',
					fontSize: '10px',
				},
			}}
			color="primary"
			badgeContent={filterBadge}
			invisible={!filterBadge}>
			<TooltipButton
				className="ml-2"
				title="general.Filter"
				onClick={handleOpenFilterMenu}
				IconComponent={FilterListIcon}
				fontSize="medium"
			/>
		</Badge>
	)
}

export default FilterButton
