import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import {
	DataGridPremium,
	GridEventListener,
	GridRenderCellParams,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { BooleanCellRender, TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import NoData from 'components/shared/no-data-message/NoData'
import { useHandleRowClick } from 'hooks'
import useDataGridState from 'hooks/UseDataGridState'
import useLanguage from 'hooks/UseLanguge'
import { IRoleHeader } from 'models'
import { useRolesService } from 'services'

import RolesToolbar from './RolesToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'
import RoleDialog from './role-dialog/RoleDialog'

export interface RoleDialogState {
	isOpen: boolean
	id: string
	name: string
	isPerProject: boolean
	isAdmin: boolean
}

const Roles = () => {
	const [rolesData, setRolesData] = useState<IRoleHeader[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [roleDialogData, setRoleDialogData] = useState<RoleDialogState>()
	const [dataLoading, setDataLoading] = useState(true)
	const [isPerProjectColumnEditable, setIsPerProjectColumnEditable] = useState(true)

	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [selectedEntryId, setSelectedEntryId] = useState('')
	const [selectedEntryName, setSelectedEntryName] = useState('')

	const { t } = useTranslation(['administration', 'translation'])

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()
	const { showSuccessInfo, getRoles, addRole, updateRole, deleteRole } = useRolesService()

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { handleDialogOpenOnRowClick } = useHandleRowClick()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleEditClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(true)
		setIsPerProjectColumnEditable(false)
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = rolesData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setRolesData(rolesData.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteRole(id as string)
			setRolesData(rolesData.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setSelectedEntryName(`${params.row.Name}`)
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addRole({
					...newRow,
				} as IRoleHeader)
				createdRow = response
				setRolesData(rolesData.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getRolesList()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateRole(newRow.Id, { Name: newRow.Name } as IRoleHeader)
				setRolesData(rolesData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getRolesList()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('Roles.Role.Name'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: true,
		},
		{
			field: 'IsPerProject',
			headerName: t('Roles.Role.IsPerProject'),
			headerAlign: 'center',
			align: 'center',
			type: 'boolean',
			sortable: true,
			editable: isPerProjectColumnEditable,
			renderCell: (params: GridRenderCellParams<any>) => {
				return <BooleanCellRender params={params} />
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			align: 'right',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit
				const isAdmin = params.row.IsAdministrator

				if (isAdmin) {
					return []
				} else if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="general.Edit"
							onClick={handleEditClick(params.id)}
							IconComponent={EditIcon}
							disabled={isActionButtonDisabled}
						/>,
						<TooltipButton
							title="ChangeHistory.ChangeHistory"
							onClick={handleShowHistory(params)}
							IconComponent={HistoryIcon}
						/>,
						<TooltipButton
							title="general.Remove"
							onClick={handleDeleteClick(params.id)}
							IconComponent={DeleteIcon}
							disabled={isActionButtonDisabled}
						/>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getRolesList = useCallback(async () => {
		try {
			const response = await getRoles()
			setRolesData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getRolesList()
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState, apiRef])

	return (
		<>
			<DataGridPremium
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={rolesData}
				getRowId={row => row.Id}
				onRowClick={params => handleDialogOpenOnRowClick(params, setRoleDialogData)}
				loading={dataLoading || dataGridStateLoading}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				experimentalFeatures={{ newEditingApi: true }}
				sx={{ minHeight: '500px', height: 'calc(100vh - 230px)', border: 'none' }}
				components={{
					Toolbar: RolesToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						isActionButtonDisabled,
						setIsActionButtonDisabled,
						setRolesData,
						setRowModesModel,
						setIsPerProjectColumnEditable,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{roleDialogData?.isOpen && (
				<RoleDialog roleData={roleDialogData as RoleDialogState} setIsOpen={setRoleDialogData} />
			)}
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={selectedEntryName}
					translationFile="administration"
					translationPath="Roles.Role"
				/>
			)}
		</>
	)
}

export default Roles
