import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'

import { IStage } from 'models'
import { useWorkflowService } from 'services'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	stageId: string
	updateStages: (selectnewlyAddedStage?: boolean, ignoreIndexChange?: boolean) => void
	stageData: IStage
}

const EditStageDialog = ({ isOpen, setIsOpen, stageId, updateStages, stageData }: Props) => {
	const [formData, setFormData] = useState<IStage>()
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { updateStage, showSuccessInfo } = useWorkflowService()

	const handleInputChange = (e: any) => {
		setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }) as IStage)
	}

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleUpdateStage = async () => {
		try {
			setButtonLoading(true)
			await updateStage(stageId, formData)

			showSuccessInfo('saved')
			updateStages(false, true)
			closeDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	useEffect(() => {
		setFormData(stageData)
	}, [stageData])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Workflow.Permissions.EditStageDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					sx={{ mt: '10px' }}
					disabled={false}
					value={formData?.Name || ''}
					name="Name"
					label={t('Workflow.Permissions.EditStageDialog.StageName')}
					onChange={handleInputChange}
					fullWidth
				/>
				<TextField
					sx={{ mt: '15px' }}
					disabled={false}
					value={formData?.Description || ''}
					name="Description"
					label={t('Workflow.Permissions.EditStageDialog.Description')}
					onChange={handleInputChange}
					fullWidth
					multiline
					minRows={3}
				/>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={!formData?.Name}
					loading={buttonLoading}
					onClick={handleUpdateStage}>
					{t('general.Save', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default EditStageDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
