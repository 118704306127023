import { useTranslation } from 'react-i18next'

import CalculateIcon from '@mui/icons-material/Calculate'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import RecommendIcon from '@mui/icons-material/Recommend'
import { Tooltip } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

import TooltipTextWrapper from 'utils/TooltipTextWrapper'

type Props = {
	params: GridRenderCellParams<any>
}

const DocumentStatus = ({ params }: Props) => {
	const { IsCanceled, IsCompleted, ToCalculation } = params.row

	const { t } = useTranslation()

	if (IsCanceled) {
		return (
			<Tooltip title={<TooltipTextWrapper title={t('general.DocumentStatus.IsCanceled')} />} placement="top" arrow>
				<span className="flex items-center">
					<DoNotDisturbIcon fontSize="medium" color="error" />
				</span>
			</Tooltip>
		)
	} else if (IsCompleted) {
		return (
			<Tooltip title={<TooltipTextWrapper title={t('general.DocumentStatus.IsCompleted')} />} placement="top" arrow>
				<span className="flex items-center">
					<RecommendIcon fontSize="medium" color="success" />
				</span>
			</Tooltip>
		)
	} else if (ToCalculation) {
		return (
			<Tooltip title={<TooltipTextWrapper title={t('general.DocumentStatus.ToCalculation')} />} placement="top" arrow>
				<span className="flex items-center">
					<CalculateIcon fontSize="medium" color="info" />
				</span>
			</Tooltip>
		)
	} else return null
}

export default DocumentStatus
