import { DateTime } from 'luxon'

export const formatDateTime = (date: string) => {
	return DateTime.fromISO(date).setLocale('pl').toFormat('dd.MM.yyyy HH:mm')
}

export const formatDateTimeMUI = (date: any) => {
	return DateTime.fromISO(date).setLocale('pl').toFormat('yyyy-MM-dd')
}

export const formatDateTimeMUIGrid = (date: any) => {
	if (typeof date !== 'string') {
		const getYear = date.toLocaleString('default', { year: 'numeric' })
		const getMonth = date.toLocaleString('default', { month: '2-digit' })
		const getDay = date.toLocaleString('default', { day: '2-digit' })

		const dateResult = `${getDay}.${getMonth}.${getYear}`
		return dateResult
	}

	return DateTime.fromISO(date).setLocale('pl').toFormat('dd.MM.yyyy')
}

export const formatDateMuiDatePicker = (date: any) => {
	if (typeof date !== 'string') {
		const getYear = date.toLocaleString('default', { year: 'numeric' })
		const getMonth = date.toLocaleString('default', { month: '2-digit' })
		const getDay = date.toLocaleString('default', { day: '2-digit' })

		const dateResult = `${getYear}-${getMonth}-${getDay}`
		return dateResult
	}

	return DateTime.fromISO(date).setLocale('pl').toFormat('yyyy-MM-dd')
}
