import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useConnectorService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/connector/session'

	const getSessions = useCallback(
		async (taskType: string, includeEmpty: boolean, fromDate: string | Date) => {
			const response = await request(
				`${path}/getSessions?taskType=${taskType}&includeEmpty=${includeEmpty}&fromDate=${fromDate}`
			)
			return response
		},
		[request]
	)

	const getWaitingTasks = useCallback(
		async (taskType: string = '') => {
			const response = await request(`${path}/getWaitingTasks?taskType=${taskType}`)
			return response
		},
		[request]
	)

	const getSessionTasks = useCallback(
		async (sessionId: string) => {
			const response = await request(`${path}/getSessionTasks?sessionId=${sessionId}`)
			return response
		},
		[request]
	)

	const getSessionLogs = useCallback(
		async (sessionId: string) => {
			const response = await request(`${path}/getSessionLogs?sessionId=${sessionId}`)
			return response
		},
		[request]
	)

	const getTaskData = useCallback(
		async (taskId: string) => {
			const response = await request(`${path}/getTaskData?taskId=${taskId}`)
			return response
		},
		[request]
	)

	const getSynchronizationStatusForDoc = useCallback(
		async (entryId: string) => {
			const response = await request(
				`/api/connector/synchronizationStatus?entryId=${entryId}`,
				'GET',
				null,
				{ 'Content-Type': 'application/json' },
				true
			)
			return response
		},
		[request]
	)

	return {
		showSuccessInfo,
		error,
		fieldsError,
		clearError,
		getSessions,
		getWaitingTasks,
		getSessionTasks,
		getSessionLogs,
		getTaskData,
		getSynchronizationStatusForDoc,
	}
}

export default useConnectorService
