import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const OfferIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M23.928,7.287c-0.136-0.475-0.447-0.865-0.875-1.104l-9.568-5.307c-0.609-0.34-1.611-0.585-2.382-0.585
		c-0.084,0-0.167,0.003-0.244,0.009L3.066,0.887C2.184,0.953,1.193,1.588,0.764,2.362L0.712,2.456
		c-0.513,0.62-0.801,1.558-0.688,2.32l1.152,7.727c0.116,0.778,0.618,1.849,1.143,2.435l7.293,8.158
		c0.348,0.389,0.849,0.611,1.373,0.611c0.454,0,0.89-0.166,1.227-0.467l4.039-3.612c0.112,0.021,0.228,0.032,0.343,0.032
		c0.672,0,1.289-0.363,1.613-0.947l0.919-1.656l1.633-1.461c0.64-0.57,0.785-1.49,0.408-2.219l2.603-4.691
		C24.007,8.258,24.063,7.76,23.928,7.287z M19.928,14.666l-8.548,7.645c-0.107,0.097-0.248,0.149-0.395,0.149
		c-0.17,0-0.331-0.071-0.442-0.196L3.25,14.105c-0.366-0.408-0.758-1.244-0.839-1.787L1.259,4.592
		C1.19,4.137,1.441,3.441,1.785,3.134l0.157-0.142C2.2,2.762,2.699,2.596,3.13,2.596c0.118,0,0.229,0.012,0.319,0.036L11,4.64
		c0.531,0.142,1.317,0.624,1.684,1.032l7.291,8.156C20.191,14.072,20.171,14.447,19.928,14.666z M22.678,8.081l-2.361,4.257
		l-6.703-7.497c-0.523-0.587-1.53-1.205-2.292-1.407L5.703,1.939l5.248-0.395c0.046-0.004,0.097-0.006,0.15-0.006
		c0.564,0,1.346,0.188,1.778,0.428l9.566,5.308c0.138,0.076,0.236,0.202,0.281,0.356C22.771,7.783,22.754,7.943,22.678,8.081z"
			/>
			<path
				fill="currentColor"
				d="M4.359,4.096c-0.47,0-0.912,0.184-1.242,0.514C2.784,4.941,2.601,5.383,2.601,5.854
		C2.6,6.325,2.783,6.768,3.115,7.1C3.447,7.433,3.89,7.615,4.359,7.615c0.469,0,0.912-0.182,1.245-0.515
		c0.686-0.687,0.686-1.803,0-2.489C5.271,4.279,4.829,4.096,4.359,4.096z M4.723,6.218c-0.097,0.097-0.226,0.15-0.363,0.15
		c-0.137,0-0.265-0.054-0.361-0.149c-0.097-0.098-0.15-0.227-0.15-0.363c0-0.136,0.052-0.266,0.15-0.363
		c0.096-0.097,0.224-0.149,0.36-0.149c0.138,0,0.267,0.053,0.362,0.149C4.922,5.693,4.922,6.019,4.723,6.218z"
			/>
		</SvgIcon>
	)
}

export default OfferIcon
