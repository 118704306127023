import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'

import { useContractorsService } from 'services'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	getContacts?: () => void
	contractorId: string
}

const AddContactInfoDialog = ({ isOpen, setIsOpen, getContacts, contractorId }: Props) => {
	const [formData, setFormData] = useState<any>({})
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['dictionaries', 'translation'])

	const { addContractorContactInfo, showSuccessInfo } = useContractorsService()

	const handleInputChange = (e: any) => {
		if (formData) {
			setFormData((prev: any) => {
				return { ...prev, [e.target.name]: e.target.value }
			})
		} else {
			setFormData({ [e.target.name]: e.target.value })
		}
	}

	const handleAddContact = async () => {
		if (formData && contractorId) {
			try {
				setButtonLoading(true)
				await addContractorContactInfo({
					...formData,
					ContractorId: contractorId,
				})
				getContacts && getContacts()
				showSuccessInfo('saved')
				setIsOpen(false)
			} catch (err) {
				console.error(err)
			}
			setButtonLoading(false)
		}
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('general.AddContactInfoPerson', { ns: 'translation' })}
				<IconButton edge="start" color="inherit" onClick={() => setIsOpen(false)} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container rowSpacing={2} columnSpacing={2} sx={sxStyles.gridItem}>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<TextField
							value={formData?.Name || ''}
							id="Name"
							name="Name"
							label={t('Contractor.ContactInfoPerson.Name')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<TextField
							value={formData?.PhoneNumber || ''}
							id="PhoneNumber"
							name="PhoneNumber"
							label={t('Contractor.ContactInfoPerson.PhoneNumber')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<TextField
							value={formData?.Email || ''}
							id="Email"
							name="Email"
							label={t('Contractor.ContactInfoPerson.Email')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={6} md={6} sm={12} xs={12}>
						<TextField
							value={formData?.Comments || ''}
							id="Comments"
							name="Comments"
							label={t('Contractor.ContactInfoPerson.Comments')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="error" onClick={() => setIsOpen(false)}>
					{t('general.Cancel', { ns: 'translation' })}
				</Button>
				<LoadingButton variant="contained" onClick={handleAddContact} color="success" loading={buttonLoading}>
					{t('general.Save', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddContactInfoDialog

const sxStyles = {
	gridItem: {
		p: '10px 10px',
	},
}
