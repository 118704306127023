import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { Box, Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { IRoleGeneralPermission } from 'models'
import { useWorkflowService } from 'services'

import SinglePermission from './SinglePermission'

interface Props {
	workflowId: string
	roleId: string
	setIsDetachDialogOpen: (value: boolean) => void
}

const GeneralPermissions = ({ workflowId, roleId, setIsDetachDialogOpen }: Props) => {
	const [roleGeneralPermissions, setRoleGeneralPermissions] = useState<IRoleGeneralPermission[]>([])

	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
	const [permissionsLoading, setPermissionsLoading] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { getWorkflowPermissions, updateWorkflowPermissions, showSuccessInfo } = useWorkflowService()

	const handleGeneralPermissionsChange = useCallback(
		(e: any) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const value = e.target.checked
			const fieldName = e.target.name

			const foundObject = roleGeneralPermissions.find(item => item.Key === fieldName)

			if (foundObject) {
				const updatedPermissions = roleGeneralPermissions.map(item => {
					if (item.Key === fieldName) {
						return { ...item, Value: value }
					}
					return item
				})

				setRoleGeneralPermissions(updatedPermissions)
			} else {
				return
			}
		},
		[isSaveButtonDisabled, roleGeneralPermissions]
	)

	const handleSavePermissionsChanges = async () => {
		try {
			setButtonLoading(true)

			const permissions = roleGeneralPermissions

			await updateWorkflowPermissions(workflowId, roleId, permissions)

			showSuccessInfo('saved')
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
		setIsSaveButtonDisabled(true)
	}

	const getGeneralPermissionsData = useCallback(async () => {
		try {
			setPermissionsLoading(true)
			const response = await getWorkflowPermissions(workflowId, roleId)

			setRoleGeneralPermissions(response)
		} catch (err) {
			console.error(err)
		}
		setPermissionsLoading(false)
	}, [getWorkflowPermissions, workflowId, roleId])

	useEffect(() => {
		getGeneralPermissionsData()
	}, [workflowId, roleId])

	if (permissionsLoading) {
		return <Loading />
	} else if (roleGeneralPermissions.length < 1) {
		return <NoData />
	}

	return (
		<Box sx={sxStyles.container}>
			<Box sx={sxStyles.tools}>
				<Button variant="text" onClick={() => setIsDetachDialogOpen(true)} sx={{ mr: '10px' }}>
					{t('general.RemoveRoleFromWorkflow', { ns: 'translation' })}
				</Button>
				<LoadingButton
					variant="contained"
					disabled={isSaveButtonDisabled}
					onClick={handleSavePermissionsChanges}
					loading={buttonLoading}>
					{t('general.Save', { ns: 'translation' })}
				</LoadingButton>
			</Box>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ m: '10px 0' }}>
				{roleGeneralPermissions.map(el => {
					return (
						<Grid key={el.Key} xs={12} sm={12} md={6} lg={4}>
							<Box sx={sxStyles.permissionContainer}>
								<SinglePermission permission={el} handleDataChange={handleGeneralPermissionsChange} />
							</Box>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)
}

export default GeneralPermissions

const sxStyles = {
	container: {
		p: '15px',
	},
	permissionContainer: {
		width: '95%',
		padding: '5px 0',
		borderBottom: '1px dotted',
		borderColor: 'action.disabled',
	},
	tools: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
}
