import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const SalesInvoiceIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M19.852,24H6.185c-1.055,0-1.912-0.857-1.912-1.912V1.913C4.272,0.858,5.13,0,6.185,0h8.663
   c0.87,0,2.036,0.483,2.652,1.1l3.164,3.164c0.617,0.616,1.1,1.783,1.1,2.654v15.17C21.764,23.143,20.906,24,19.852,24z M6.185,1.223
   c-0.38,0-0.69,0.31-0.69,0.69v20.175c0,0.379,0.311,0.689,0.69,0.689h13.667c0.381,0,0.69-0.312,0.69-0.689V6.918
   c0-0.559-0.349-1.395-0.741-1.79l-3.164-3.164c-0.396-0.395-1.232-0.742-1.789-0.742L6.185,1.223L6.185,1.223z"
			/>
			<path
				fill="currentColor"
				d="M20.848,6.229h-4.699c-0.338,0-0.61-0.273-0.61-0.611V1.272c0-0.338,0.272-0.611,0.61-0.611
   s0.61,0.273,0.61,0.611v3.734h4.089c0.338,0,0.61,0.273,0.61,0.611C21.458,5.955,21.186,6.229,20.848,6.229z"
			/>
			<path
				fill="currentColor"
				d="M13.02,6.229H6.785c-0.338,0-0.611-0.273-0.611-0.611c0-0.338,0.273-0.611,0.611-0.611h6.232
   c0.338,0,0.611,0.273,0.611,0.611C13.629,5.956,13.355,6.229,13.02,6.229z"
			/>
			<path
				fill="currentColor"
				d="M13.02,3.776H6.785c-0.338,0-0.611-0.273-0.611-0.611s0.273-0.611,0.611-0.611h6.232
   c0.338,0,0.611,0.273,0.611,0.611S13.355,3.776,13.02,3.776z"
			/>
			<g>
				<path
					fill="currentColor"
					d="M13.281,20.914h-1.049v-1.633c-1.175-0.127-2.163-0.564-2.966-1.311l0.606-1.216
       c0.954,0.826,1.896,1.24,2.823,1.24c0.533,0,0.927-0.103,1.18-0.306c0.255-0.203,0.383-0.45,0.383-0.746
       c0-0.403-0.188-0.704-0.561-0.905c-0.184-0.099-0.373-0.183-0.571-0.254c-0.198-0.072-0.44-0.146-0.728-0.224
       c-0.747-0.213-1.263-0.406-1.548-0.582c-0.397-0.246-0.664-0.479-0.798-0.703c-0.2-0.332-0.299-0.723-0.299-1.166
       c0-0.457,0.105-0.849,0.315-1.171c0.211-0.323,0.502-0.581,0.873-0.771c0.372-0.191,0.802-0.314,1.289-0.37V9.292h1.049v1.495
       c0.953,0.111,1.763,0.394,2.43,0.846l-0.583,1.239c-0.897-0.517-1.711-0.775-2.44-0.775c-0.391,0-0.715,0.071-0.973,0.209
       c-0.259,0.138-0.387,0.366-0.387,0.685s0.141,0.583,0.423,0.795c0.281,0.211,0.752,0.403,1.412,0.573
       c0.961,0.255,1.657,0.589,2.095,1c0.335,0.334,0.521,0.839,0.561,1.515c0,0.481-0.11,0.892-0.332,1.222
       c-0.453,0.655-1.188,1.045-2.205,1.174v1.645H13.281z"
				/>
			</g>
		</SvgIcon>
	)
}

export default SalesInvoiceIcon
