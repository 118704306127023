const formatAmountNumberDataGrid = (value: string | number) => {
	let parsedValue = value

	if (typeof value === 'string') {
		parsedValue = Number(value)
	}

	return parsedValue.toLocaleString('uk-UA', {
		minimumFractionDigits: 5,
		maximumFractionDigits: 5,
	})
}

export default formatAmountNumberDataGrid
