import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { LoadingButton } from '@mui/lab'
import { Box, List, ListItem, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import LanguageSwitcher from 'components/layouts/main/language-switcher/LanguageSwitcher'
import Loading from 'components/shared/loading/Loading'
import { IBusinessData, IOfferDraft } from 'models'
import { useBusinessDataService, useQuotationRequestOfferDraft } from 'services'
import FormatAddress from 'utils/FormatAdress'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import Attachments from './Attachments'
import Entries from './Entries'

interface Props {
	unauthenticated?: boolean
}

const QuotationRequestOfferDraft = ({ unauthenticated }: Props) => {
	const [data, setData] = useState<IOfferDraft>()
	const [confirmationCodeValue, setConfirmationCodeValue] = useState('')
	const [businessData, setBusinessData] = useState<IBusinessData>()
	const [buttonLoading, setButtonLoading] = useState(false)
	const [isEntriesSaved, setIsEntriesSaved] = useState(true)
	const [logoUrl, setLogoUrl] = useState('')

	const [isErrorMessageVissible, setIsErrorMessageVissible] = useState(false)
	const [isSubmitedMessageVissible, setIsSubmitedMessageVissible] = useState(false)

	const { t } = useTranslation(['demand', 'translation'])

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const params = useParams()
	const token = params.id as string

	const documentHeight = unauthenticated ? '100vh' : 'calc(100vh - 150px)'

	const { getQuotationRequestOffer, updateDeadlines, submitOffer } = useQuotationRequestOfferDraft()

	const { getBusinessData, getLogoURL } = useBusinessDataService()

	const handleInputChange = (e: any) => {
		setData(prev => {
			return { ...prev, [e.target.name]: e.target.value }
		})
	}

	const handleSaveDeadlines = async () => {
		try {
			await updateDeadlines(token, data as IOfferDraft)
		} catch (err) {
			console.error(err)
		}
	}

	const handleSubmitOffer = async () => {
		try {
			setButtonLoading(true)
			unauthenticated ? await submitOffer(token, confirmationCodeValue) : await submitOffer(token)

			setIsSubmitedMessageVissible(true)
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	const getData = useCallback(async () => {
		try {
			isErrorMessageVissible && setIsErrorMessageVissible(false)
			const response = await getQuotationRequestOffer(token)

			setData(response)
		} catch (err) {
			setIsErrorMessageVissible(true)
			console.error(err)
		}
	}, [getQuotationRequestOffer, token])

	const getBusinessInformation = useCallback(async () => {
		try {
			const response = await getBusinessData()
			setBusinessData(response)
		} catch (err) {
			console.error(err)
		}
	}, [getBusinessData])

	const getLogo = async () => {
		try {
			const logoData = await getLogoURL()
			setLogoUrl(logoData?.url || '')
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getBusinessInformation()
		getLogo()
	}, [])

	useEffect(() => {
		getData()
	}, [token])

	if (!data && !isErrorMessageVissible && !isSubmitedMessageVissible) {
		return (
			<Box
				sx={{
					height: documentHeight,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<Loading />
			</Box>
		)
	} else if (isErrorMessageVissible || isSubmitedMessageVissible) {
		return (
			<Box
				sx={{
					height: documentHeight,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				{isErrorMessageVissible ? (
					<>
						<ReportProblemIcon color="primary" sx={{ fontSize: '92px', mb: '10px' }} />
						<Typography fontSize={'22px'} sx={{ textAlign: 'center' }}>
							{t('QuotationRequestOfferDraft.ErrorMessage')}
						</Typography>
					</>
				) : (
					<>
						<CheckCircleIcon color="primary" sx={{ fontSize: '92px', mb: '10px' }} />
						<Typography fontSize={'22px'} sx={{ textAlign: 'center' }}>
							{t('QuotationRequestOfferDraft.OfferSubmittedMessage')}
						</Typography>
					</>
				)}
			</Box>
		)
	}

	const isSaveButtonDisabled = isEntriesSaved ? (unauthenticated ? confirmationCodeValue.length < 4 : false) : true

	return (
		<Box
			sx={{
				height: documentHeight,
				overflow: unauthenticated ? 'auto' : 'visible',
				p: mobileView || !unauthenticated ? '0' : '20px',
			}}>
			<Box
				sx={{
					border: mobileView || !unauthenticated ? 'none' : '1px solid',
					borderColor: 'border.primary',
					borderRadius: '5px',
					padding: '20px 20px 35px 20px',
				}}>
				<Box sx={sxStyles.headerContainer}>
					<Box component="span" sx={sxStyles.logo}>
						{logoUrl && <img src={logoUrl} alt="Company logo" className="h-14 w-auto" />}
					</Box>
					{unauthenticated && (
						<Box sx={sxStyles.lang}>
							<LanguageSwitcher />
						</Box>
					)}
				</Box>
				<Typography
					fontSize={'22px'}
					component="div"
					sx={{ ...sxStyles.title, mt: mobileView ? '74px' : '18px' }}
					color="primary">
					{t('QuotationRequestOfferDraft.Title')}
				</Typography>
				<Grid container rowSpacing={3} columnSpacing={2}>
					<Grid xs={12} sm={4} md={4} lg={4} order={{ xs: 0, sm: 0, md: 0, lg: 0 }}>
						<TextField
							variant="outlined"
							disabled={true}
							value={data?.DemandNumber || ''}
							name="DemandNumber"
							label={t('QuotationRequestOfferDraft.Fields.DemandNumber')}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={4} md={4} lg={4} order={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
						<TextField
							variant="outlined"
							disabled={true}
							InputLabelProps={{ shrink: true }}
							type="date"
							value={formatDateTimeMUI(data?.DeliveryDateTime) || ''}
							name="DeliveryDateTime"
							label={t('QuotationRequestOfferDraft.Fields.DeliveryDateTime')}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={4} md={4} lg={4} order={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
						<TextField
							variant="outlined"
							disabled={true}
							value={data?.OfferDeadline || ''}
							name="OfferDeadline"
							label={t('QuotationRequestOfferDraft.Fields.OfferDeadline')}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={5} md={4} lg={4} order={{ xs: 3, sm: 3, md: 3, lg: 3 }}>
						<TextField
							variant="outlined"
							disabled={true}
							value={data?.ContractorName || ''}
							name="ContractorName"
							label={t('QuotationRequestOfferDraft.Fields.ContractorName')}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={7} md={8} lg={8} order={{ xs: 5, sm: 4, md: 4, lg: 4 }}>
						<TextField
							variant="outlined"
							disabled={true}
							value={data?.ProjectInvestmentName || ''}
							name="ProjectInvestmentName"
							label={t('QuotationRequestOfferDraft.Fields.ProjectInvestmentName')}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={4} md={4} lg={4} order={{ xs: 4, sm: 5, md: 5, lg: 5 }}>
						<TextField
							variant="outlined"
							disabled={true}
							value={data?.ContractorNip || ''}
							name="ContractorNip"
							label={t('QuotationRequestOfferDraft.Fields.ContractorNip')}
							fullWidth
						/>
					</Grid>
					{!mobileView && <Grid xs={12} sm={8} md={8} lg={8} order={{ xs: 6, sm: 6, md: 6, lg: 6 }}></Grid>}
					<Grid xs={12} sm={4} md={4} lg={4} order={{ xs: 7, sm: 7, md: 7, lg: 7 }}>
						<TextField
							variant="outlined"
							disabled={data?.IsReadOnly}
							InputLabelProps={{ shrink: true }}
							type="date"
							value={formatDateTimeMUI(data?.ValidToDate) || ''}
							name="ValidToDate"
							label={t('QuotationRequestOfferDraft.Fields.ValidToDate')}
							onChange={handleInputChange}
							onBlur={handleSaveDeadlines}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={4} md={4} lg={4} order={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
						<TextField
							variant="outlined"
							disabled={data?.IsReadOnly}
							InputLabelProps={{ shrink: true }}
							type="date"
							value={formatDateTimeMUI(data?.PriceGuarantee) || ''}
							name="PriceGuarantee"
							label={t('QuotationRequestOfferDraft.Fields.PriceGuarantee')}
							onChange={handleInputChange}
							onBlur={handleSaveDeadlines}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={4} md={4} lg={4} order={{ xs: 9, sm: 9, md: 9, lg: 9 }}>
						<TextField
							variant="outlined"
							disabled={data?.IsReadOnly}
							type="number"
							value={data?.PaymentTimeDays || ''}
							name="PaymentTimeDays"
							label={t('QuotationRequestOfferDraft.Fields.PaymentTimeDays')}
							onChange={handleInputChange}
							onBlur={handleSaveDeadlines}
							fullWidth
						/>
					</Grid>
					<Grid xs={12} sm={12} md={12} lg={12} order={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
						<Entries offerToken={token} readOnly={data?.IsReadOnly as boolean} setIsEntriesSaved={setIsEntriesSaved} />
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6} order={{ xs: 11, sm: 11, md: 11, lg: 11 }}>
						<TextField
							variant="outlined"
							disabled={true}
							value={data?.ContractorComments || ''}
							name="ContractorComments"
							label={`${t('QuotationRequestOfferDraft.Fields.ContractorComments')} ${businessData?.Name || ''}`}
							fullWidth
							multiline
							minRows={5}
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6} order={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
						<TextField
							variant="outlined"
							disabled={data?.IsReadOnly}
							value={data?.CommentFromContractor || ''}
							name="CommentFromContractor"
							label={`${t('QuotationRequestOfferDraft.Fields.CommentFromContractor')} ${data?.ContractorName || ''}`}
							onChange={handleInputChange}
							onBlur={handleSaveDeadlines}
							fullWidth
							multiline
							minRows={5}
						/>
					</Grid>
					<Grid lg={7} md={7} sm={12} xs={12} order={{ xs: 13, sm: 13, md: 13, lg: 13 }}>
						<Attachments offerToken={token} />
					</Grid>
					<Grid lg={5} md={5} sm={12} xs={12} order={{ xs: 14, sm: 14, md: 14, lg: 14 }}>
						<Typography sx={sxStyles.listItem} component={'p'} color="primary" fontSize={'16px'}>
							{businessData?.Name || ''}
						</Typography>
						<List dense>
							<ListItem sx={sxStyles.listItem}>
								<span>{`NIP: ${businessData?.Nip || ''}`}</span>
							</ListItem>
							<ListItem sx={sxStyles.listItem}>
								<span>{`REGON: ${businessData?.Regon || ''}`}</span>
							</ListItem>
							<ListItem sx={sxStyles.listItem}>
								<LocalPhoneIcon sx={sxStyles.listIcon} color="primary" />
								<span>{businessData?.PhoneNumber || ''}</span>
							</ListItem>
							<ListItem sx={sxStyles.listItem}>
								<EmailIcon sx={sxStyles.listIcon} color="primary" />
								<span>{businessData?.Email || ''}</span>
							</ListItem>
							<ListItem sx={sxStyles.listItem}>
								<HomeIcon sx={sxStyles.listIcon} color="primary" />
								<span>
									{FormatAddress({
										Id: businessData?.Id as string,
										City: businessData?.Address_City,
										PostalCode: businessData?.Address_PostalCode,
										Street: businessData?.Address_Street,
										StreetNumber: businessData?.Address_StreetNumber as string,
										FlatNumber: businessData?.Address_FlatNumber as string,
									})}
								</span>
							</ListItem>
						</List>
					</Grid>
					{data?.IsReadOnly ? null : (
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
							order={{ xs: 15, sm: 15, md: 15, lg: 15 }}>
							{unauthenticated && (
								<TextField
									value={confirmationCodeValue}
									id="ConfirmationCode"
									name="ConfirmationCode"
									label={t('QuotationRequestOfferDraft.Fields.ConfirmationCode')}
									size="small"
									onChange={e => setConfirmationCodeValue(e.target.value)}
								/>
							)}
							<LoadingButton
								variant="contained"
								sx={{ margin: '0 0 0 15px', height: '40px' }}
								disabled={isSaveButtonDisabled}
								onClick={handleSubmitOffer}
								loading={buttonLoading}>
								{t('general.Send', { ns: 'translation' })}
							</LoadingButton>
						</Grid>
					)}
				</Grid>
			</Box>
		</Box>
	)
}

export default QuotationRequestOfferDraft

const sxStyles = {
	title: {
		width: '100%',
		textAlign: 'center',
		fontWeight: '500',
		margin: '18px 0 40px',
	},
	headerContainer: {
		position: 'relative',
	},
	logo: {
		position: 'absolute',
		top: '6px',
		left: '2px',
	},
	lang: {
		position: 'absolute',
		top: '10px',
		right: '2px',
	},
	listItem: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		m: 0,
		p: '2px',
		textAlign: 'right',
	},
	listIcon: { fontSize: '20px', pr: '4px' },
}
