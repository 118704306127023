import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	aggregation: {
		model: { Value: 'sum', BudgetValue: 'sum' },
	},
	rowGrouping: {
		model: [],
	},
	pinnedColumns: { left: ['__check__'], right: ['actions'] },
	columns: {
		orderedFields: [
			'__check__',
			'__tree_data_group__',
			'Name',
			'OfferStatus',
			'CostType',
			'MeasurementUnitId',
			'Amount',
			'Price',
			'PriceAfterNegotiation',
			'Value',
			'ValueAfterNegotiation',
			'BudgetValue',
			'Parameters',
			'Comments',
			'OrderId',
			'BudgetEntryId',
			'actions',
		],
		dimensions: {
			__tree_data_group__: { maxWidth: -1, minWidth: 130, width: 300, flex: 0 },
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			OfferStatus: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			PriceAfterNegotiation: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Value: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			ValueAfterNegotiation: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			BudgetValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			Parameters: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			OrderId: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			Comments: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			BudgetEntryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 100,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'offerEntriesDataGridState'
