import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import CommentIcon from '@mui/icons-material/Comment'
import DescriptionIcon from '@mui/icons-material/Description'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'

import { Loading } from 'components/shared'
import Attachments from 'components/shared/attachments/Attachments'
import Discussion from 'components/shared/comments/Discussion'
import DocumentWorkflowTab from 'components/shared/document-workflow-tab/DocumentWorkflowTab'
import DocumentsTab from 'components/shared/documents-tab/DocumentsTab'
import PageNavigation from 'components/shared/page-navigation/PageNavigation'
import { useAppSelector, useDocumentDataActions } from 'features'
import { useFieldsPermissions } from 'hooks'
import { useGoodsReceivedNoteService } from 'services'

import MaterialInvoices from '../invoices/material-invoices/MaterialInvoices'
import GoodsReceivedNoteBasicInfo from './basic-info-tab/GoodsReceivedNoteBasicInfo'
import GoodsReceivedNoteEntries from './entries/GoodsReceivedNoteEntries'

const GoodsReceivedNote = () => {
	const { loading, documentData, visibleProperties, editableProperties } = useAppSelector(state => state.documentData)

	const { isEditable, isVisible } = useFieldsPermissions()

	const params = useParams()

	const id = params.id as string

	const { t } = useTranslation('goods-received-note')

	const { getGoodsReceivedNote, getPropertiesToRead, getPropertiesToEdit, getActions, invokeAction, errorStatus } =
		useGoodsReceivedNoteService()
	const {
		setDocumentId,
		setDocumentData,
		setActionsList,
		updateLoading,
		updateFormLoading,
		setEditableProperties,
		setVisibleProperties,
	} = useDocumentDataActions()

	const getData = async () => {
		try {
			updateFormLoading(true)
			const data = await getGoodsReceivedNote(id)
			const actions = await getActions(id)
			const visiblePropertiesData = await getPropertiesToRead(id)
			const editablePropertiesData = await getPropertiesToEdit(id)

			setDocumentData(data)
			setActionsList(actions)
			setVisibleProperties(visiblePropertiesData)
			setEditableProperties(editablePropertiesData)
		} catch (err) {
			console.error(err)
		} finally {
			updateFormLoading(false)
			updateLoading(false)
		}
	}

	const documentTabData = [
		{
			label: t('MaterialInvoices', { ns: 'navigation' }),
			renderValue: <MaterialInvoices goodsReceivedNoteId={id} isTab />,
			isVisible: true,
		},
	]

	const tabs = useMemo(() => {
		if (documentData == null || editableProperties == null || visibleProperties == null) {
			return []
		}

		return [
			{
				id: 0,
				tabName: t('Tabs.BasicInformation'),
				icon: <TextSnippetIcon />,
				component: <GoodsReceivedNoteBasicInfo id={id} />,
				disabled: false,
			},
			{
				id: 1,
				tabName: t('Tabs.Positions'),
				icon: <WysiwygIcon />,
				component: <GoodsReceivedNoteEntries goodsReceivedNoteId={id} readOnly={!isEditable('Entries')} />,
				disabled: !isVisible('Entries'),
			},
			{
				id: 2,
				tabName: t('Tabs.Documents'),
				icon: <DescriptionIcon />,
				component: <DocumentsTab tabs={documentTabData} />,
				disabled: false,
			},
			{
				id: 3,
				tabName: t('Tabs.Workflow'),
				icon: <PublishedWithChangesIcon />,
				component: (
					<DocumentWorkflowTab workflowId={documentData.WorkflowId as string} documentId={id} refreshData={getData} />
				),
				disabled: false,
			},
			{
				id: 4,
				tabName: t('Tabs.Comments'),
				icon: <CommentIcon />,
				component: <Discussion discussionId={documentData.DiscussionId} readOnly={!isEditable('DiscussionId')} />,
				disabled: !isVisible('DiscussionId'),
			},
			{
				id: 5,
				tabName: t('Tabs.Attachments'),
				icon: <AttachFileIcon />,
				component: <Attachments contextId={id} />,
				disabled: false,
			},
		]
	}, [id, t, documentData])

	useEffect(() => {
		getData()
		setDocumentId(id)
	}, [id])

	if (loading) {
		return <Loading />
	}

	return (
		<PageNavigation
			tabs={tabs}
			invokeActionFunction={invokeAction}
			getDataFunction={getData}
			document
			errorStatus={errorStatus}
		/>
	)
}

export default GoodsReceivedNote
