import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	aggregation: {
		model: { OfferSum: 'sum' },
	},
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: [],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'Name',
			'MeasurementUnitId',
			'OfferAmount',
			'OfferPrice',
			'OfferSum',
			'OfferParameters',
			'OfferComment',
		],
		dimensions: {
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 400,
				flex: 0,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			OfferAmount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			OfferPrice: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			OfferSum: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			OfferParameters: {
				maxWidth: -1,
				minWidth: 50,
				width: 300,
				flex: 0,
			},
			OfferComment: {
				maxWidth: -1,
				minWidth: 50,
				width: 300,
				flex: 0,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'quotationRequestDataGridState'
