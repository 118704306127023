import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	MenuItem,
	TextField,
} from '@mui/material'

import { useAppSelector, useDocumentActionsStateActions } from 'features'
import { Action } from 'models'
import { usePrintTemplatesService } from 'services'

type Template = {
	value: string
	label: string | null
	SearchItems: string | null
}

type Props = {
	isOpen: boolean
	action: Action
	invokeAction: (value: Action, parameters?: any) => void
}
const PrintDocxDialog = ({ isOpen, action, invokeAction }: Props) => {
	const [selectedTemplateId, setSelectedTemplateId] = useState('')
	const [templates, setTemplates] = useState<Template[]>([])
	const [printEmptyLines, setPrintEmptyLines] = useState(false)

	const { documentId } = useAppSelector(state => state.documentData)
	const { setIsPrintDocxDialogOpen } = useDocumentActionsStateActions()
	const { getPrintTemplatesForDocument } = usePrintTemplatesService()

	const { t } = useTranslation()

	const location = useLocation()

	const documentPath = location.pathname.split('/')[1]

	const showPrintEmptyLinesParam = documentPath === 'processingProtocol'

	const closeDialog = () => {
		setIsPrintDocxDialogOpen(false)
		setSelectedTemplateId('')
	}

	const handleActionConfirm = () => {
		let parameters

		if (showPrintEmptyLinesParam) {
			parameters = { templateId: selectedTemplateId, printEmptyLines: printEmptyLines.toString() }
		} else {
			parameters = { templateId: selectedTemplateId }
		}

		invokeAction(action, parameters)
		closeDialog()
	}

	const handleInputChange = (e: any) => {
		setSelectedTemplateId(e.target.value)
	}

	const handlePrintEmptyLinesChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPrintEmptyLines(e.target.checked)
	}

	const getTemplatesData = async () => {
		try {
			const response = await getPrintTemplatesForDocument(documentPath, documentId)
			setTemplates(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		if (action) {
			getTemplatesData()
		}
	}, [action])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t(`Actions.Names.${action?.Name}`)}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<FormControl fullWidth>
					<TextField
						disabled={false}
						select
						value={selectedTemplateId}
						id="TemplateId"
						name="TemplateId"
						label={t('Actions.PrintDocxDialog.SelectInputLabel')}
						onChange={handleInputChange}
						fullWidth
						sx={{ mt: '10px' }}>
						{templates.map((el: Template) => (
							<MenuItem key={el.value} value={el.value}>
								{el.label || t('Actions.PrintDocxDialog.NoName')}
							</MenuItem>
						))}
					</TextField>
				</FormControl>
				{showPrintEmptyLinesParam ? (
					<FormControlLabel
						sx={{ display: 'flex', justifyContent: 'center', mt: '10px' }}
						control={<Checkbox checked={printEmptyLines} onChange={handlePrintEmptyLinesChange} />}
						label={t('Actions.PrintDocxDialog.PrintEmptyLines')}
					/>
				) : null}
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.Cancel')}
				</Button>
				<Button variant="contained" onClick={handleActionConfirm} color="success" disabled={!selectedTemplateId}>
					{t('general.Confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default PrintDocxDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
