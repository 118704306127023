import { GridColumnHeaderParams, GridRowParams } from '@mui/x-data-grid-premium'

import { ColumnHeaderRender, RenderProgress } from 'components/shared'
import { InvestorBudgetEntryCategory } from 'data'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import i18next from '../../../i18n'
import Actions from '../../shared/budget-data-grid/Actions'

const InvestorBudgetColumns = (actionsProps?: any, measurementUnits?: any, vatRate?: any, industries?: any) => {
	const columns = [
		{
			field: 'Description',
			headerName: i18next.t('project:InvestorBudgetTab.Columns.Description'),
			headerAlign: 'center',
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},
		{
			field: 'MeasurementUnitId',
			headerName: i18next.t('project:InvestorBudgetTab.Columns.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.MeasurementUnitId || ''
			},
			valueOptions: measurementUnits,
			valueFormatter: ({ value }: any) => {
				const option = measurementUnits.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},
		{
			field: 'Category',
			headerName: i18next.t('project:InvestorBudgetTab.Columns.Category'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.Category || 'Undefined'
			},
			valueOptions: () =>
				InvestorBudgetEntryCategory.map((el: any) => ({
					value: el.value,
					label: i18next.t(`project:InvestorBudgetTab.Columns.CategoryEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = InvestorBudgetEntryCategory.find((opt: any) => opt.value === value)
				if (option) {
					return i18next.t(`project:InvestorBudgetTab.Columns.CategoryEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},
		{
			field: 'Amount',
			headerName: i18next.t('project:InvestorBudgetTab.Columns.Amount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},
		{
			field: 'Price',
			headerName: i18next.t('project:InvestorBudgetTab.Columns.Price'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value, 4, 4)
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},
		{
			field: 'VatRateId',
			headerName: i18next.t('project:InvestorBudgetTab.Columns.VatRateId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.VatRateId || ''
			},
			valueOptions: vatRate,
			valueFormatter: ({ value }: any) => {
				const option = vatRate.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},

		{
			field: 'Budget',
			headerName: i18next.t('project:InvestorBudgetTab.Columns.Budget'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},
		{
			field: 'Execution',
			headerName: i18next.t('project:InvestorBudgetTab.Columns.Execution'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},
		{
			field: 'ExecutionStatusPerc',
			headerName: i18next.t('project:RealizationBudgetTab.Columns.ExecutionStatusPerc'),
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			renderCell: RenderProgress,
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.InvestorBudget" />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			pinnable: true,
			getActions: (params: GridRowParams) => {
				return [
					<Actions
						params={params}
						rowModesModel={actionsProps.rowModesModel}
						setRowModesModel={actionsProps.setRowModesModel}
						budgetData={actionsProps.budgetData}
						disabledAddButton={actionsProps.disabledAddButton}
						setDisabledAddButton={actionsProps.setDisabledAddButton}
						setBudgetData={actionsProps.setBudgetData}
						apiRef={actionsProps.apiRef}
						projectId={actionsProps.projectId}
						api={actionsProps.api}
						setIsFormDialogOpen={actionsProps.setIsFormDialogOpen}
						setFormDialogData={actionsProps.setFormDialogData}
						selectEnums={{
							measurementUnits: [...measurementUnits],
							vatRate: [...vatRate],
						}}
						setIsReorderDialogOpen={actionsProps.setIsReorderDialogOpen}
						setRowIdToChangeOrder={actionsProps.setRowIdToChangeOrder}
						setChangeHistoryDialogData={actionsProps.setChangeHistoryDialogData}
						setIsChangeHistoryDialogOpen={actionsProps.setIsChangeHistoryDialogOpen}
						setSelectedEntryId={actionsProps.setSelectedEntryId}
						translationPath="InvestorBudgetTab.Columns"
						type="investor-budget"
					/>,
				]
			},
		},
	]

	return columns
}

export default InvestorBudgetColumns
