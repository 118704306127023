import { useTranslation } from 'react-i18next'

import WarningAmberIcon from '@mui/icons-material/WarningAmber'

const NoAccess = () => {
	const { t } = useTranslation()

	return (
		<div className="h-[calc(100vh-150px)] w-full flex flex-col items-center justify-center text-3xl">
			<WarningAmberIcon color="primary" sx={{ fontSize: '150px' }} />
			<p className="text-center">{t('general.NoAccess')}</p>
		</div>
	)
}

export default NoAccess
