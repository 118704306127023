import { useTranslation } from 'react-i18next'

import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import {
	Box,
	Button,
	Divider,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { IStageFieldsPermission } from 'models'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import SinglePermission from './SinglePermission'

interface Props {
	fieldsPermissions: IStageFieldsPermission[]
	fieldsPermissionsToRender: IStageFieldsPermission[]
	handleDataChange: (e: any, fieldName: string) => void
	loading: boolean
	handleMarkFieldsPermissions: (marker: string) => void
	setFieldsPermissionsToRender: (value: IStageFieldsPermission[]) => void
	searchValue: string
	setSearchValue: (value: string) => void
}

const FieldsPermissions = ({
	fieldsPermissions,
	fieldsPermissionsToRender,
	handleDataChange,
	loading,
	handleMarkFieldsPermissions,
	setFieldsPermissionsToRender,
	searchValue,
	setSearchValue,
}: Props) => {
	const { t } = useTranslation(['administration', 'translation'])

	const theme = useTheme()
	const tabletView = useMediaQuery(theme.breakpoints.down('lg'))

	const handleSearchChange = (e: any) => {
		const searchedValue = e.target.value
		setSearchValue(searchedValue)

		const filteredItems = fieldsPermissions.filter((item: IStageFieldsPermission) => {
			const propertyNameLowerCase = item.ShowProperty?.toLowerCase()

			if (propertyNameLowerCase?.includes(searchedValue.toLowerCase())) return item
		})
		setFieldsPermissionsToRender(filteredItems)
	}

	const handleClearSearchText = () => {
		setSearchValue('')
		setFieldsPermissionsToRender(fieldsPermissions)
	}

	return (
		<Box sx={{ ...sxStyles.container, width: tabletView ? '100%' : '65%' }}>
			<Typography component={'div'} fontSize={'16px'} sx={sxStyles.title}>
				{t('Workflow.Permissions.RolePermissions.Stages.FieldsPermissions.Title')}
			</Typography>
			{loading ? (
				<Loading />
			) : fieldsPermissions.length > 0 ? (
				<Box sx={sxStyles.tableBox}>
					<TableContainer sx={sxStyles.tableContainer}>
						<Table sx={{ minWidth: 450 }} size="small" stickyHeader>
							<TableHead sx={{ height: '70px' }}>
								<TableRow>
									<TableCell>
										<TextField
											sx={{ mr: '10px' }}
											value={searchValue}
											name="searchField"
											placeholder={
												t('SearchInput.InputPlaceholder', {
													ns: 'translation',
												}) as string
											}
											onChange={handleSearchChange}
											size="small"
											InputProps={{
												style: {
													paddingRight: '0',
												},
												endAdornment: (
													<>
														<Tooltip
															title={
																<TooltipTextWrapper
																	title={t('SearchInput.ClearButton', {
																		ns: 'translation',
																	})}
																/>
															}
															placement="top"
															arrow>
															<IconButton
																onClick={handleClearSearchText}
																sx={{
																	visibility: searchValue ? 'visible' : 'hidden',
																}}>
																<ClearIcon />
															</IconButton>
														</Tooltip>
														<Divider orientation="vertical" variant="middle" flexItem />
														<Box
															sx={{
																padding: '0 10px',
																display: 'flex',
																alignItems: 'center',
															}}>
															<SearchIcon color="disabled" />
														</Box>
													</>
												),
											}}
										/>
									</TableCell>
									<TableCell align="center" sx={sxStyles.tableCell}>
										<Tooltip
											title={
												<TooltipTextWrapper
													title={`${t('general.MarkEverything', {
														ns: 'translation',
													})} ${t(`Workflow.Permissions.RolePermissions.Stages.FieldsPermissions.Options.None`)}`}
												/>
											}
											placement="top"
											arrow>
											<span>
												<Button variant="text" onClick={() => handleMarkFieldsPermissions('None')}>
													{t(`Workflow.Permissions.RolePermissions.Stages.FieldsPermissions.Options.None`)}
												</Button>
											</span>
										</Tooltip>
									</TableCell>
									<TableCell align="center" sx={sxStyles.tableCell}>
										<Tooltip
											title={
												<TooltipTextWrapper
													title={`${t('general.MarkEverything', {
														ns: 'translation',
													})} ${t(`Workflow.Permissions.RolePermissions.Stages.FieldsPermissions.Options.Read`)}`}
												/>
											}
											placement="top"
											arrow>
											<span>
												<Button variant="text" onClick={() => handleMarkFieldsPermissions('Read')}>
													{t(`Workflow.Permissions.RolePermissions.Stages.FieldsPermissions.Options.Read`)}
												</Button>
											</span>
										</Tooltip>
									</TableCell>
									<TableCell align="center" sx={sxStyles.tableCell}>
										<Tooltip
											title={
												<TooltipTextWrapper
													title={`${t('general.MarkEverything', {
														ns: 'translation',
													})} ${t(`Workflow.Permissions.RolePermissions.Stages.FieldsPermissions.Options.ReadWrite`)}`}
												/>
											}
											placement="top"
											arrow>
											<span>
												<Button variant="text" onClick={() => handleMarkFieldsPermissions('ReadWrite')}>
													{t(`Workflow.Permissions.RolePermissions.Stages.FieldsPermissions.Options.ReadWrite`)}
												</Button>
											</span>
										</Tooltip>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{fieldsPermissionsToRender.map((el, index) => {
									return (
										<SinglePermission key={index} permission={el} handleDataChange={handleDataChange} index={index} />
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			) : (
				<NoData />
			)}
		</Box>
	)
}

export default FieldsPermissions

const sxStyles = {
	container: {
		p: '15px',
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		textAlign: 'center',
		color: 'text.secondary',
	},
	tableBox: { pt: '15px', pr: '25px' },
	tableContainer: {
		maxHeight: '800px',
		border: '1px solid',
		borderColor: 'border.primary',
		borderRadius: '5px',
	},
	tableCell: {
		width: '70px',
	},
}
