import { useTranslation } from 'react-i18next'

import { Box, Switch, Typography } from '@mui/material'

import { IStageConfiguration } from 'models'

interface Props {
	permission: IStageConfiguration
	handleDataChange: (value: any) => void
}

const SinglePermission = ({ permission, handleDataChange }: Props) => {
	const { t } = useTranslation(['administration', 'translation'])

	const { Key, Value } = permission

	const isReadOnly = Key === 'CompleteEnabled' || Key === 'Start'

	return (
		<Box sx={sxStyles.container}>
			<Box sx={sxStyles.container}>
				<Typography
					sx={{
						...sxStyles.key,
						color: isReadOnly ? 'text.disabled' : 'inherit',
					}}>
					{t(`Workflow.Permissions.RolePermissions.Stages.ConfigurationPermissions.Fields.${Key}`, {
						defaultValue: Key,
					})}
				</Typography>
			</Box>
			<Switch
				disabled={isReadOnly}
				size="small"
				color="primary"
				checked={Value}
				name={Key}
				onChange={handleDataChange}
			/>
		</Box>
	)
}

export default SinglePermission

const sxStyles = {
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	key: {
		fontSize: '14px',
		pr: '10px',
	},
}
