import { ChangeEventHandler, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	TextField,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useAppSelector } from 'features'
import { IContract, IScheduleEntryPeriod } from 'models'
import { useScheduleService } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

type Props = {
	periods: IScheduleEntryPeriod[]
	period?: IScheduleEntryPeriod
	documentType: string
	documentId: string
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	refreshData: () => void
	actionType: 'adding' | 'editing'
}

const EditPeriodDialog = ({
	periods,
	period,
	documentType,
	documentId,
	isOpen,
	setIsOpen,
	refreshData,
	actionType,
}: Props) => {
	const [updatedPeriod, setUpdatedPeriod] = useState<IScheduleEntryPeriod>()
	const valueInputRef = useRef<HTMLInputElement | null>(null)

	const { t } = useTranslation()

	const { addEntryPeriod, updateEntryPeriod, showSuccessInfo } = useScheduleService()

	const { documentData } = useAppSelector(state => state.documentData)

	const contractData = documentData as IContract

	const closeDialog = () => {
		setIsOpen(false)
		setUpdatedPeriod({} as IScheduleEntryPeriod)
		refreshData()
	}

	const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
		const { name, value } = e.target

		setUpdatedPeriod(prev => ({ ...prev, [name]: value }) as IScheduleEntryPeriod)
	}

	const valueFieldInputFocus = () => {
		if (valueInputRef.current) {
			const timeout = setTimeout(() => {
				valueInputRef.current!.focus()
			}, 100)

			return () => {
				clearTimeout(timeout)
			}
		}
	}

	const handleCheckboxChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const { checked } = e.target as HTMLInputElement

		setUpdatedPeriod(prev => ({ ...prev, IsAuto: checked }) as IScheduleEntryPeriod)

		if (!checked && valueInputRef.current) {
			valueFieldInputFocus()
		}
	}

	const handleAddPeriod = async () => {
		if (updatedPeriod)
			try {
				await addEntryPeriod(
					documentType,
					documentId,
					updatedPeriod.DocumentScheduleEntryId as string,
					{
						PeriodFrom: updatedPeriod.PeriodFrom,
						PeriodTo: updatedPeriod.PeriodTo,
						IsAuto: updatedPeriod.IsAuto,
						PeriodValue: updatedPeriod.IsAuto ? 0 : updatedPeriod.PeriodValue,
					} as IScheduleEntryPeriod
				)

				closeDialog()
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
	}

	const handleSaveUpdatedPeriod = async () => {
		if (updatedPeriod)
			try {
				await updateEntryPeriod(documentType, documentId, updatedPeriod.Id, {
					PeriodFrom: updatedPeriod.PeriodFrom,
					PeriodTo: updatedPeriod.PeriodTo,
					IsAuto: updatedPeriod.IsAuto,
					PeriodValue: updatedPeriod.IsAuto ? 0 : updatedPeriod.PeriodValue,
				} as IScheduleEntryPeriod)

				closeDialog()
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
	}

	const getSmallerDate = (date1: string, date2: string) => {
		const [year1, month1, day1] = date1.split('-').map(Number)
		const [year2, month2, day2] = date2.split('-').map(Number)

		const dateObj1 = new Date(year1, month1 - 1, day1)
		const dateObj2 = new Date(year2, month2 - 1, day2)

		const result = dateObj1 > dateObj2 ? date2 : date1

		return formatDateTimeMUI(result)
	}

	const getDefaultStartingDate = () => {
		if (periods.length) {
			const date = new Date(periods[periods.length - 1].PeriodTo)

			date.setDate(date.getDate() + 1)

			const newDateISO = date.toISOString().split('T')[0]

			const newStartingDate = formatDateTimeMUI(newDateISO)

			return getSmallerDate(newStartingDate, contractData.ConsumptionDateTimeEnd as string)
		} else {
			return contractData.ConsumptionDateTimeStart as string
		}
	}

	useEffect(() => {
		if (actionType === 'adding' && period) {
			const defaultStartingDate = getDefaultStartingDate()

			setUpdatedPeriod({
				...period,
				PeriodFrom: defaultStartingDate,
				PeriodTo: contractData.ConsumptionDateTimeEnd as string,
			})
		} else {
			setUpdatedPeriod(period)
		}
	}, [period])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{actionType === 'editing'
					? t('ScheduleTab.EditPeriodDialog.Title')
					: t('ScheduleTab.EditPeriodDialog.AddPeriod')}
				<IconButton edge="start" color="inherit" onClick={() => setIsOpen(false)} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container rowSpacing={2} columnSpacing={2} sx={{ mt: '4px' }}>
					<Grid xs={6} sm={6} md={6} lg={6}>
						<TextField
							value={formatDateTimeMUI(updatedPeriod?.PeriodFrom) || ''}
							name="PeriodFrom"
							label={t('ScheduleTab.PeriodFrom')}
							InputLabelProps={{ shrink: true }}
							type="date"
							onChange={handleInputChange}
							size="small"
							fullWidth
							inputProps={{ min: contractData.ConsumptionDateTimeStart, max: contractData.ConsumptionDateTimeEnd }}
						/>
					</Grid>
					<Grid xs={6} sm={6} md={6} lg={6}>
						<TextField
							value={formatDateTimeMUI(updatedPeriod?.PeriodTo) || ''}
							name="PeriodTo"
							label={t('ScheduleTab.PeriodTo')}
							InputLabelProps={{ shrink: true }}
							type="date"
							onChange={handleInputChange}
							size="small"
							fullWidth
							inputProps={{ min: contractData.ConsumptionDateTimeStart, max: contractData.ConsumptionDateTimeEnd }}
						/>
					</Grid>
					<Grid xs={6} sm={6} md={6} lg={6}>
						<TextField
							disabled={updatedPeriod?.IsAuto}
							value={updatedPeriod?.PeriodValue || ''}
							name="PeriodValue"
							label={t('ScheduleTab.Value')}
							onChange={handleInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							size="small"
							fullWidth
							inputRef={valueInputRef}
						/>
					</Grid>
					<Grid xs={6} sm={6} md={6} lg={6}>
						<FormControlLabel
							control={
								<Checkbox onChange={handleCheckboxChange} checked={updatedPeriod?.IsAuto || false} name="IsAuto" />
							}
							label={t('ScheduleTab.IsAuto')}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button
					variant="contained"
					onClick={actionType === 'editing' ? handleSaveUpdatedPeriod : handleAddPeriod}
					color="success">
					{t('general.Save')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default EditPeriodDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
