import { Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { IPermission } from 'models'

import SinglePermission from './SinglePermission'

interface Props {
	permissions: IPermission[]
	roleId: string
}

const PermissionsList = ({ permissions, roleId }: Props) => {
	return (
		<Grid container rowSpacing={3} columnSpacing={2} sx={{ pb: '15px' }}>
			{permissions.map(el => {
				return (
					<Grid key={el.Name} xs={12} sm={12} md={6} lg={6}>
						<Box
							sx={{
								width: '95%',
								padding: '10px 0',
								borderBottom: '1px dotted',
								borderColor: 'action.disabled',
							}}>
							<SinglePermission permission={el} roleId={roleId} />
						</Box>
					</Grid>
				)
			})}
		</Grid>
	)
}

export default PermissionsList
