import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IInvestorSalesInvoice, IInvokeAction } from 'models'
import { INVESTOR_SALES_INVOICE_ENDPOINT } from 'services/data'

export interface getInvestorSalesInvoiceListProps {
	isCanceled: boolean
	isCompleted: boolean
	projectId?: string
	investorProcessingProtocolId?: string
	data?: GridInitialState
}

const useInvestorSalesInvoiceService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = INVESTOR_SALES_INVOICE_ENDPOINT

	const getFilteredSalesInvoices = useCallback(
		async ({
			isCanceled,
			isCompleted,
			projectId,
			investorProcessingProtocolId,
			data,
		}: getInvestorSalesInvoiceListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (investorProcessingProtocolId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&investorProcessingProtocolId=${investorProcessingProtocolId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getSalesInvoices = useCallback(
		async ({ isCanceled, isCompleted, projectId, investorProcessingProtocolId }: getInvestorSalesInvoiceListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (investorProcessingProtocolId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&investorProcessingProtocolId=${investorProcessingProtocolId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getSalesInvoice = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updateSalesInvoice = useCallback(
		async (id: string, data: IInvestorSalesInvoice) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getSalesInvoice,
		getSalesInvoices,
		updateSalesInvoice,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredSalesInvoices,
	}
}

export default useInvestorSalesInvoiceService
