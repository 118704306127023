import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import FolderIcon from '@mui/icons-material/Folder'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { IconButton, Tooltip } from '@mui/material'

import { IAttachment } from 'models/IAttachment'
import { useAttachmentsService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import AttachmentList from './AttachmentsList'
import styles from './Styles.module.css'
import AddAttachment from './add-attachments/AddAttachment'

interface Props {
	name: string
	subItems: IAttachment[]
	contextId: string
	itemId: string
	updateAttachments: React.DispatchWithoutAction
}

const SingleFolderAttachment = ({ name, subItems, contextId, itemId, updateAttachments }: Props) => {
	const [islistVisible, setIslistVisible] = useState(false)

	const { t } = useTranslation(['attachments', 'translation'])

	const { showSuccessInfo, deleteFolder } = useAttachmentsService()

	const toggleIsListVisible = () => {
		setIslistVisible(!islistVisible)
	}

	const handleDeleteAttachment = useCallback(async () => {
		try {
			await deleteFolder(itemId)
			updateAttachments()
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}, [contextId, itemId])

	return (
		<>
			<li className={styles.attachmentItem}>
				<div className={styles.folderContainer}>
					<IconButton sx={{ padding: '1px', margin: '0 2px' }} onClick={toggleIsListVisible}>
						{islistVisible ? (
							<KeyboardArrowDownIcon fontSize="small" color="action" sx={{ width: '16px', height: '16px' }} />
						) : (
							<KeyboardArrowRightIcon fontSize="small" color="action" sx={{ width: '16px', height: '16px' }} />
						)}
					</IconButton>
					<FolderIcon sx={{ margin: '0 5px 0 0', fontSize: '16px' }} />
					<p className={styles.attachmentName} onClick={toggleIsListVisible}>
						{name}
					</p>
				</div>
				<div className={styles.buttonsContainer}>
					<AddAttachment contextId={contextId} directoryId={itemId} updateAttachments={updateAttachments} />
					<Tooltip title={<TooltipTextWrapper title={t('DeleteFolder')} />} placement="top" arrow>
						<span>
							<IconButton sx={{ padding: '5px' }} color="primary" onClick={handleDeleteAttachment}>
								<DeleteIcon fontSize="small" />
							</IconButton>
						</span>
					</Tooltip>
				</div>
			</li>
			{islistVisible &&
				(subItems.length > 0 ? (
					<li className={styles.nestedList}>
						<AttachmentList contextId={contextId} array={subItems} updateAttachments={updateAttachments} />
					</li>
				) : (
					<li className={styles.nestedList}>
						<p className={styles.emptyFolderText}>{t('EmptyFolderInfo')}</p>
					</li>
				))}
		</>
	)
}

export default SingleFolderAttachment
