import { useHttp } from 'hooks/http.hook'

const useCommentsService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const getComments = async (discussionId: string | undefined) => {
		const response = await request(`/api/discussion/list/${discussionId}`)
		return response
	}

	const addComment = async (comment: any) => {
		const response = await request(`/api/discussion/comment`, 'POST', comment)
		return response
	}

	const getComment = async (commentId: string) => {
		const response = await request(`/api/discussion/comment/${commentId}`)
		return response.data
	}

	return {
		error,
		clearError,
		getComments,
		getComment,
		addComment,
		showSuccessInfo,
	}
}

export default useCommentsService
