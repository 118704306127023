import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import AddLinkIcon from '@mui/icons-material/AddLink'

import {
	DefaultAttachmentPreviewButton,
	DocumentFormSaveButton,
	FormSkeletonLoading,
	LinkEntryDialog,
	TooltipButton,
} from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import {
	useAppSelector,
	useDocumentDataActions,
	useDocumentFormSaveButtonActions,
	useLinkDocumentsActions,
} from 'features'
import { ICreditNote } from 'models'
import { enqueueSnackbar } from 'notistack'
import { useCreditNoteService } from 'services'
import prepareTitleString from 'utils/prepareTitleString'

import Form from './Form'

type Props = {
	noteId: string
}

const BasicInfo = ({ noteId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)
	const { copiedDocumentData, isLinkEntryDialogOpen } = useAppSelector(state => state.linkDocuments)

	const { setIsLinkEntryDialogOpen } = useLinkDocumentsActions()

	const { t } = useTranslation()

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { getCreditNote, updateCreditNote, linkToBudgetEntry, showSuccessInfo } = useCreditNoteService()

	const handlePrepareLinkToBudgetEntry = () => {
		const data = documentData as ICreditNote

		if (copiedDocumentData.id && copiedDocumentData.documentType === 'ProjectBudget') {
			setIsLinkEntryDialogOpen(true)
		} else {
			enqueueSnackbar(
				`${t('LinkDocumentDialog.CopyBudgetEntryDataFirst', { ns: 'translation' })} ${data.ProjectLabel || ''}`,
				{
					variant: 'warning',
					persist: true,
				}
			)
		}
	}

	const handleLinkToBudgetEntry = async () => {
		try {
			await linkToBudgetEntry(noteId, copiedDocumentData.id)
			showSuccessInfo('action')
			refreshDocumentData()
		} catch (err) {
			console.error(err)
		}
	}

	const refreshDocumentData = async () => {
		try {
			const response = await getCreditNote(noteId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [noteId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={10} tabs={2} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
					/>
				</div>
				<div>
					<TooltipButton
						title="general.LinkToBudget"
						onClick={handlePrepareLinkToBudgetEntry}
						IconComponent={AddLinkIcon}
						disabled={false}
					/>
					<DefaultAttachmentPreviewButton attachmentId={documentData?.DefaultAttachmentId} />
					<ChangeHistory
						contextId={noteId}
						translationFile="credit-note"
						title={`${t('CreditNote', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="Fields"
					/>
					<DocumentFormSaveButton
						documentId={noteId}
						data={documentData}
						updateDataFunction={updateCreditNote}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<Form formData={documentData as ICreditNote} noteId={noteId} />
			</div>
			{isLinkEntryDialogOpen && (
				<LinkEntryDialog
					isOpen={isLinkEntryDialogOpen}
					linkFunction={handleLinkToBudgetEntry}
					itemName={`${t('CreditNote', {
						ns: 'routes',
					})} ${prepareTitleString(documentData?.Number as string)}`}
				/>
			)}
		</>
	)
}

export default BasicInfo
