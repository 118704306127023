import { GridInitialState } from '@mui/x-data-grid-premium'

export const REALIZATION_BUDGET_INITIALSTATE: GridInitialState = {
	aggregation: {
		model: {
			Budget: 'sum',
			Incomes: 'sum',
			CostsSum: 'sum',
			SubcontractorsContractsValue: 'sum',
			SubcontractorsExecutionValue: 'sum',
			SubcontractorsCostsValue: 'sum',
			EquipmentContractsValue: 'sum',
			EquipmentCostsValue: 'sum',
			MaterialContractsValue: 'sum',
			MaterialCostsValue: 'sum',
			GeneralCostsValue: 'sum',
			GeneralContractsValue: 'sum',
			Plan: 'sum',
			Execution: 'sum',
		},
	},
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: ['__check__', '__tree_data_group__', 'Description'],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			__check__: true,
			__tree_data_group__: true,
			Description: true,
			Category: true,
			CostType: true,
			IndustryId: true,
			MeasurementUnitId: true,
			Amount: true,
			Price: true,
			Budget: true,
			RealizationDateStart: true,
			RealizationDateEnd: true,
			RealizationLengthDays: true,
			ExecutionStatusPerc: true,
			Plan: true,
			ExecutionPlan: true,
			DemandAndContracting: true,
			Incomes: true,
			CostsSum: true,
			SubcontractorsContractsValue: true,
			SubcontractorsExecutionValue: true,
			SubcontractorsCostsValue: true,
			EquipmentContractsValue: true,
			EquipmentCostsValue: true,
			MaterialContractsValue: true,
			MaterialCostsValue: true,
			GeneralCostsValue: true,
			GeneralContractsValue: true,
			actions: true,
		},
		orderedFields: [
			'__check__',
			'__tree_data_group__',
			'Description',
			'Category',
			'CostType',
			'IndustryId',
			'MeasurementUnitId',
			'Amount',
			'Price',
			'Budget',
			'RealizationDateStart',
			'RealizationDateEnd',
			'RealizationLengthDays',
			'ExecutionStatusPerc',
			'Plan',
			'ExecutionPlan',
			'DemandAndContracting',
			'Incomes',
			'CostsSum',
			'SubcontractorsContractsValue',
			'SubcontractorsExecutionValue',
			'SubcontractorsCostsValue',
			'EquipmentContractsValue',
			'EquipmentCostsValue',
			'MaterialContractsValue',
			'MaterialCostsValue',
			'GeneralCostsValue',
			'GeneralContractsValue',
			'actions',
		],
		dimensions: {
			__tree_data_group__: {
				maxWidth: -1,
				minWidth: 130,
				width: 300,
				flex: 0,
			},
			Description: {
				maxWidth: -1,
				minWidth: 50,
				width: 400,
				flex: 0,
			},
			Category: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			IndustryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Budget: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			RealizationDateStart: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			RealizationDateEnd: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			RealizationLengthDays: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ExecutionStatusPerc: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Plan: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ExecutionPlan: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			DemandAndContracting: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Incomes: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			CostsSum: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			SubcontractorsContractsValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			SubcontractorsExecutionValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			SubcontractorsCostsValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			EquipmentContractsValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			EquipmentCostsValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MaterialContractsValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MaterialCostsValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			GeneralCostsValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			GeneralContractsValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 80,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [
			{
				field: '__tree_data_group__',
				sort: 'asc',
			},
		],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const REALIZATION_BUDGET_CONTEXT_STATE_NAME = 'realizationBudgetDataGridState'
