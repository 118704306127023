import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useContactInfoService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const getPersons = useCallback(async () => {
		const response = await request(`/api/Person/userPersons`)
		return response
	}, [request])

	return {
		error,
		clearError,
		showSuccessInfo,
		getPersons,
	}
}

export default useContactInfoService
