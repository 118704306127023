import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { INotification } from 'models'

type InitialState = {
	allNotifications: INotification[]
	newNotifications: INotification[]
	notReadedNotifications: INotification[]
	notificationsCount: number
}

const initialState: InitialState = {
	allNotifications: [],
	newNotifications: [],
	notReadedNotifications: [],
	notificationsCount: 0,
}

export const notificationsSlice = createSlice({
	name: 'notificationsSlice',
	initialState,
	reducers: {
		setAllNotifications: (state, action: PayloadAction<INotification[]>) => {
			state.allNotifications = action.payload
		},
		setNewNotifications: (state, action: PayloadAction<INotification[]>) => {
			state.newNotifications = action.payload
		},
		setNotReadedNotifications: (state, action: PayloadAction<INotification[]>) => {
			state.notReadedNotifications = action.payload
		},
		setNotificationsCount: (state, action: PayloadAction<number>) => {
			state.notificationsCount = action.payload
		},
	},
})

export const notificationsActions = notificationsSlice.actions

export default notificationsSlice.reducer
