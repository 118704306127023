import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import ErrorIcon from '@mui/icons-material/Error'
import {
	AppBar,
	Dialog,
	DialogContent,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	errorsList: string[]
}

const ImportErrorsDialog = ({ isOpen, setIsOpen, errorsList }: Props) => {
	const { t } = useTranslation()

	const handleCloseDialog = () => {
		setIsOpen(false)
	}

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1, fontSize: '20px', fontWeight: '500' }} component="div">
						{t('general.ImportErrors')}
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '80px',
				}}>
				<List>
					{errorsList.map(el => (
						<ListItem
							key={el}
							sx={{
								bgcolor: 'background.secondary',
								p: '10px 15px',
								m: '5px 0',
								borderRadius: '3px',
							}}>
							<ListItemIcon sx={{ minWidth: '30px' }}>
								<ErrorIcon color="error" sx={{ fontSize: '18px' }} />
							</ListItemIcon>
							<ListItemText primary={el} />
						</ListItem>
					))}
				</List>
			</DialogContent>
		</Dialog>
	)
}

export default ImportErrorsDialog
