import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'

import { IEmailMarkup } from 'models'
import MUIRichTextEditor from 'mui-rte'

interface Props {
	defaultEmailText: string | null | undefined
	handleTextChange: (value: any) => void
	readOnly: boolean
	markups: IEmailMarkup[]
}

const RichTextEditor = ({ defaultEmailText, handleTextChange, readOnly, markups }: Props) => {
	const { t } = useTranslation()

	const onChange = (data: any) => {
		handleTextChange(data)
	}

	const tags = markups.map(el => {
		const key = el.Key.replace(/{|}/g, '') // Remove curly braces
		const value = el.Key
		const content = `${el.Key} - ${t(`PrintTemplates.MarkupsDialog.Descriptions.${el.Key}`, {
			ns: 'administration',
			defaultValue: `${el.Description}`,
		})}`

		return { keys: [key], value, content }
	})

	return (
		<Box
			sx={{
				minHeight: '200px',
				padding: '0 15px',
				pb: '20px',
				border: 1,
				borderColor: 'action.disabled',
				borderRadius: '4px',
				'& h2': {
					fontSize: '22px',
				},
			}}>
			<MUIRichTextEditor
				readOnly={readOnly}
				defaultValue={defaultEmailText}
				onChange={onChange}
				inlineToolbar={true}
				label={t('EmailParametersTab.EmailBody') as string}
				controls={[
					'title',
					'bold',
					'italic',
					'underline',
					'strikethrough',
					'undo',
					'redo',
					'numberList',
					'bulletList',
					'quote',
					'clear',
				]}
				autocomplete={{
					strategies: [
						{
							items: tags,
							triggerChar: '@',
						},
					],
				}}
			/>
		</Box>
	)
}

export default RichTextEditor
