import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { EndpointType } from 'services/data'

const useContactPersonsService = (path: EndpointType) => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	//contractors contact persons
	const getContractorsContactPersons = useCallback(
		async (documentId: string) => {
			const response = await request(`${path}/contractorContacts/${documentId}`)
			return response
		},
		[request]
	)

	const addContractorsContactPerson = useCallback(
		async (documentId: string, contactId: string) => {
			await request(`${path}/contractorContacts/${documentId}?contactId=${contactId}`, 'PUT')
		},
		[request]
	)

	const deleteContractorsContactPerson = useCallback(
		async (documentId: string, contactId: string) => {
			await request(`${path}/contractorContacts/${documentId}?contactId=${contactId}`, 'DELETE')
		},
		[request]
	)

	//users contact persons
	const getUsersContactPersons = useCallback(
		async (documentId: string) => {
			const response: string[] = await request(`${path}/contactPersons/${documentId}`)
			return response
		},
		[request]
	)

	const addUsersContactPerson = useCallback(
		async (documentId: string, personId: string) => {
			await request(`${path}/contactPersons/${documentId}?personId=${personId}`, 'PUT')
		},
		[request]
	)

	const deleteUsersContactPerson = useCallback(
		async (documentId: string, personId: string) => {
			await request(`${path}/contactPersons/${documentId}?personId=${personId}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		clearError,
		showSuccessInfo,
		getContractorsContactPersons,
		addContractorsContactPerson,
		deleteContractorsContactPerson,
		getUsersContactPersons,
		addUsersContactPerson,
		deleteUsersContactPerson,
	}
}

export default useContactPersonsService
