import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useMaterialInvoiceGRNListService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/materialInvoiceGrnList'

	const getGRNList = useCallback(
		async (invoiceId: string) => {
			const response = await request(`${path}?invoiceId=${invoiceId}`)
			return response
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getGRNList,
	}
}

export default useMaterialInvoiceGRNListService
