import { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'
import { GridApi } from '@mui/x-data-grid-premium'

import { handleCollapseAll, handleExpandAll } from './helpers/gridRowExpansion'

interface ExpandCollapseButtonsProps {
	apiRef: MutableRefObject<GridApi>
	isActionButtonDisabled?: boolean
}

const ExpandCollapseButtons = ({ apiRef, isActionButtonDisabled }: ExpandCollapseButtonsProps) => {
	const { t } = useTranslation()

	const expandAll = () => {
		handleExpandAll(apiRef)
	}
	const collapseAll = () => {
		handleCollapseAll(apiRef)
	}

	return (
		<>
			<Button variant="text" size="small" color="primary" onClick={expandAll} disabled={isActionButtonDisabled}>
				{t('general.ExpandAll')}
			</Button>

			<Button variant="text" size="small" color="primary" onClick={collapseAll} disabled={isActionButtonDisabled}>
				{t('general.CollapseAll')}
			</Button>
		</>
	)
}

export default ExpandCollapseButtons
