export const styles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	warningMessage: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		p: '10px',
		pt: '20px',
		color: 'warning.main',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
