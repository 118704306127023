import { useTranslation } from 'react-i18next'

import { LinearProgress, MenuItem } from '@mui/material'
import { Box, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { formatDateTimeMUI } from 'utils/formatDateTime'

const RealizationBudgetForm = ({ formData, editedRowFormData, setEditedRowFormData }: any) => {
	const { measurementUnits, costTypes, categories, industries } = formData.selectEnums

	const { t } = useTranslation('project')

	const theme = useTheme()
	const desktopView = useMediaQuery(theme.breakpoints.up('md'))

	const handleValueChange = (e: any, fieldName: string) => {
		setEditedRowFormData((prev: any) => {
			return { ...prev, [fieldName]: e.target.value }
		})
	}

	const getInputProps = (fieldName: string) => {
		const elementParams = formData.columns.find((el: any) => {
			return el.field === fieldName
		})

		if (editedRowFormData && elementParams) {
			if (
				(formData.row.HasChildren && fieldName === 'Category') ||
				(formData.row.HasChildren && fieldName === 'CostType') ||
				(formData.row.HasChildren && fieldName === 'MeasurementUnitId') ||
				(formData.row.HasChildren && fieldName === 'Amount') ||
				(formData.row.HasChildren && fieldName === 'Price') ||
				(formData.row.HasChildren && fieldName === 'IndustryId')
			) {
				return {
					readOnly: true,
					disabled: true,
					type: elementParams.type,
					onChange: (e: any) => handleValueChange(e, fieldName),
				}
			} else {
				return {
					readOnly: !elementParams?.editable,
					disabled: !elementParams?.editable,
					type: elementParams.type,
					onChange: (e: any) => handleValueChange(e, fieldName),
				}
			}
		}
	}

	if (!editedRowFormData) {
		return <LinearProgress color="primary" />
	}
	return (
		<Box sx={sxStyles.row}>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<TextField
						value={editedRowFormData.Description || ''}
						InputProps={getInputProps('Description')}
						id="Description"
						label={t('RealizationBudgetTab.Columns.Description')}
						fullWidth
						multiline
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Category || ''}
						select
						InputProps={getInputProps('Category')}
						id="Category"
						label={t('RealizationBudgetTab.Columns.Category')}
						fullWidth>
						{categories.map((option: any) => (
							<MenuItem key={option.value} value={option.value}>
								{t(`RealizationBudgetTab.Columns.CategoryEnums.${option.label}`)}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.CostType || ''}
						select
						InputProps={getInputProps('CostType')}
						id="CostType"
						label={t('RealizationBudgetTab.Columns.CostType')}
						fullWidth>
						{costTypes.map((option: any) => (
							<MenuItem key={option.value} value={option.value}>
								{t(`RealizationBudgetTab.Columns.CostTypeEnums.${option.label}`)}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.IndustryId || ''}
						select
						InputProps={getInputProps('IndustryId')}
						id="IndustryId"
						label={t('RealizationBudgetTab.Columns.IndustryId')}
						fullWidth>
						{industries.map((option: any) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Price || ''}
						InputProps={getInputProps('Price')}
						id="Price"
						label={t('RealizationBudgetTab.Columns.Price')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Amount || ''}
						InputProps={getInputProps('Amount')}
						id="Amount"
						label={t('RealizationBudgetTab.Columns.Amount')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.MeasurementUnitId || ''}
						select
						InputProps={getInputProps('MeasurementUnitId')}
						id="MeasurementUnitId"
						label={t('RealizationBudgetTab.Columns.MeasurementUnitId')}
						fullWidth>
						{measurementUnits.map((option: any) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={formatDateTimeMUI(editedRowFormData.RealizationDateStart)}
						InputProps={getInputProps('RealizationDateStart')}
						id="RealizationDateStart"
						label={t('RealizationBudgetTab.Columns.RealizationDateStart')}
						fullWidth
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={formatDateTimeMUI(editedRowFormData.RealizationDateEnd)}
						InputProps={getInputProps('RealizationDateEnd')}
						id="RealizationDateEnd"
						label={t('RealizationBudgetTab.Columns.RealizationDateEnd')}
						fullWidth
						InputLabelProps={{ shrink: true }}
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.RealizationLengthDays || ''}
						InputProps={getInputProps('RealizationLengthDays')}
						id="RealizationLengthDays"
						label={t('RealizationBudgetTab.Columns.RealizationLengthDays')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Plan || ''}
						InputProps={getInputProps('Plan')}
						id="Plan"
						label={t('RealizationBudgetTab.Columns.Plan')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.ExecutionPlan || ''}
						InputProps={getInputProps('ExecutionPlan')}
						id="ExecutionPlan"
						label={t('RealizationBudgetTab.Columns.ExecutionPlan')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.ExecutionStatusPerc || ''}
						InputProps={getInputProps('ExecutionStatusPerc')}
						id="ExecutionStatusPerc"
						label={t('RealizationBudgetTab.Columns.ExecutionStatusPerc')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Budget || ''}
						InputProps={getInputProps('Budget')}
						id="Budget"
						label={t('RealizationBudgetTab.Columns.Budget')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Incomes || ''}
						InputProps={getInputProps('Incomes')}
						id="Incomes"
						label={t('RealizationBudgetTab.Columns.Incomes')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.DemandAndContracting || ''}
						InputProps={getInputProps('DemandAndContracting')}
						id="DemandAndContracting"
						label={t('RealizationBudgetTab.Columns.DemandAndContracting')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.SubcontractorsContractsValue || ''}
						InputProps={getInputProps('SubcontractorsContractsValue')}
						id="SubcontractorsContractsValue"
						label={t('RealizationBudgetTab.Columns.SubcontractorsContractsValue')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.SubcontractorsExecutionValue || ''}
						InputProps={getInputProps('SubcontractorsExecutionValue')}
						id="SubcontractorsExecutionValue"
						label={t('RealizationBudgetTab.Columns.SubcontractorsExecutionValue')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.SubcontractorsCostsValue || ''}
						InputProps={getInputProps('SubcontractorsCostsValue')}
						id="SubcontractorsCostsValue"
						label={t('RealizationBudgetTab.Columns.SubcontractorsCostsValue')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.EquipmentContractsValue || ''}
						InputProps={getInputProps('EquipmentContractsValue')}
						id="EquipmentContractsValue"
						label={t('RealizationBudgetTab.Columns.EquipmentContractsValue')}
						fullWidth
					/>
				</Grid>
				{desktopView && (
					<Grid md={4} lg={4}>
						{/* Empty Item */}
					</Grid>
				)}
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.EquipmentCostsValue || ''}
						InputProps={getInputProps('EquipmentCostsValue')}
						id="EquipmentCostsValue"
						label={t('RealizationBudgetTab.Columns.EquipmentCostsValue')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.MaterialContractsValue || ''}
						InputProps={getInputProps('MaterialContractsValue')}
						id="MaterialContractsValue"
						label={t('RealizationBudgetTab.Columns.MaterialContractsValue')}
						fullWidth
					/>
				</Grid>
				{desktopView && (
					<Grid md={4} lg={4}>
						{/* Empty Item */}
					</Grid>
				)}
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.MaterialCostsValue || ''}
						InputProps={getInputProps('MaterialCostsValue')}
						id="MaterialCostsValue"
						label={t('RealizationBudgetTab.Columns.MaterialCostsValue')}
						fullWidth
					/>
				</Grid>
				{desktopView && (
					<>
						<Grid md={4} lg={4}>
							{/* Empty Item */}
						</Grid>
						<Grid md={4} lg={4}>
							{/* Empty Item */}
						</Grid>
					</>
				)}
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.GeneralCostsValue || ''}
						InputProps={getInputProps('GeneralCostsValue')}
						id="GeneralCostsValue"
						label={t('RealizationBudgetTab.Columns.GeneralCostsValue')}
						fullWidth
					/>
				</Grid>
				{desktopView && (
					<>
						<Grid md={4} lg={4}>
							{/* Empty Item */}
						</Grid>
						<Grid md={4} lg={4}>
							{/* Empty Item */}
						</Grid>
					</>
				)}
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.CostsSum || ''}
						InputProps={getInputProps('CostsSum')}
						id="CostsSum"
						label={t('RealizationBudgetTab.Columns.CostsSum')}
						fullWidth
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

export default RealizationBudgetForm

const sxStyles = {
	row: {
		width: '100%',
		pb: '15px',
	},
}
