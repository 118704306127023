import { useTranslation } from 'react-i18next'

import { LinearProgress, MenuItem } from '@mui/material'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'
import { Box } from '@mui/system'

const InvestorBudgetForm = ({ formData, editedRowFormData, setEditedRowFormData }: any) => {
	const { measurementUnits, vatRate } = formData.selectEnums

	const { t } = useTranslation('project')

	const handleValueChange = (e: any, fieldName: string) => {
		setEditedRowFormData((prev: any) => {
			return { ...prev, [fieldName]: e.target.value }
		})
	}

	const getInputProps = (fieldName: string) => {
		const elementParams = formData.columns.find((el: any) => {
			return el.field === fieldName
		})

		if (editedRowFormData && elementParams) {
			if (
				(formData.row.HasChildren && fieldName === 'MeasurementUnitId') ||
				(formData.row.HasChildren && fieldName === 'Amount') ||
				(formData.row.HasChildren && fieldName === 'Price')
			) {
				return {
					readOnly: true,
					disabled: true,
					type: elementParams.type,
					onChange: (e: any) => handleValueChange(e, fieldName),
				}
			} else {
				return {
					readOnly: !elementParams?.editable,
					disabled: !elementParams?.editable,
					type: elementParams.type,
					onChange: (e: any) => handleValueChange(e, fieldName),
				}
			}
		}
	}

	if (!editedRowFormData) {
		return <LinearProgress color="primary" />
	}
	return (
		<Box sx={sxStyles.row}>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<TextField
						value={editedRowFormData.Description || ''}
						InputProps={getInputProps('Description')}
						id="Description"
						label={t('InvestorBudgetTab.Columns.Description')}
						fullWidth
						multiline
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.MeasurementUnitId || ''}
						select
						InputProps={getInputProps('MeasurementUnitId')}
						id="MeasurementUnitId"
						label={t('InvestorBudgetTab.Columns.MeasurementUnitId')}
						fullWidth>
						{measurementUnits.map((option: any) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Price || ''}
						InputProps={getInputProps('Price')}
						id="Price"
						label={t('InvestorBudgetTab.Columns.Price')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Amount || ''}
						InputProps={getInputProps('Amount')}
						id="Amount"
						label={t('InvestorBudgetTab.Columns.Amount')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.VatRateId || ''}
						select
						InputProps={getInputProps('VatRateId')}
						id="VatRateId"
						label={t('InvestorBudgetTab.Columns.VatRateId')}
						fullWidth>
						{vatRate.map((option: any) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Budget || ''}
						InputProps={getInputProps('Budget')}
						id="Budget"
						label={t('InvestorBudgetTab.Columns.Budget')}
						fullWidth
					/>
				</Grid>
				<Grid xs={12} sm={6} md={4} lg={4}>
					<TextField
						value={editedRowFormData.Execution || ''}
						InputProps={getInputProps('Execution')}
						id="Execution"
						label={t('InvestorBudgetTab.Columns.Execution')}
						fullWidth
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

export default InvestorBudgetForm

const sxStyles = {
	row: {
		width: '100%',
		pb: '15px',
	},
}
