import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'
import { v4 as uuidv4 } from 'uuid'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	setIsActionButtonDisabled: (value: boolean) => void
	isActionButtonDisabled: boolean
	setRepresentativesData: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
	readOnly: boolean
}

const ContractorsRepresentativesToolbar = ({
	apiRef,
	isActionButtonDisabled,
	setIsActionButtonDisabled,
	setRepresentativesData,
	setRowModesModel,
	readOnly,
}: Props) => {
	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleAddRepresentative = () => {
		const Id = uuidv4()

		setIsActionButtonDisabled(true)

		setRepresentativesData((oldRows: any) => [
			...oldRows,
			{
				Id,
				isNew: true,
			},
		])

		setRowModesModel(oldModel => ({
			...oldModel,
			[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Role' },
		}))
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer className="justify-between m-1">
			<div>
				<ColumnsButton />
			</div>
			<div>
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton title="general.Filter" onClick={handleFilter} IconComponent={FilterListIcon} fontSize="medium" />
				{!readOnly && (
					<TooltipButton
						title="general.Add"
						onClick={handleAddRepresentative}
						IconComponent={AddIcon}
						fontSize="medium"
						disabled={isActionButtonDisabled}
					/>
				)}
			</div>
		</GridToolbarContainer>
	)
}

export default ContractorsRepresentativesToolbar
