import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, ExpandCollapseButtons, SwitchTreeData, TooltipButton } from 'components/shared'
import { useAppSelector } from 'features'
import useDataGridState from 'hooks/UseDataGridState'
import { ICustomCostInvoice } from 'models'
import { enqueueSnackbar } from 'notistack'
import { useCustomCostInvoiceSettlementService } from 'services'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	isActionButtonDisabled: boolean
	isTreeData: boolean
	setIsTreeData: (value: boolean) => void
	readOnly: boolean
	getData: () => void
}

const SettlementToolbar = ({ apiRef, isActionButtonDisabled, isTreeData, setIsTreeData, readOnly, getData }: Props) => {
	const { documentData } = useAppSelector(state => state.documentData)
	const { copiedDocumentData } = useAppSelector(state => state.linkDocuments)

	const { showSuccessInfo, addSettlement } = useCustomCostInvoiceSettlementService()

	const { handleSaveStateSnapshot } = useDataGridState()

	const { t } = useTranslation()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleAddEntry = async () => {
		const data = documentData as ICustomCostInvoice

		if (!copiedDocumentData.id || copiedDocumentData.documentType !== 'ProjectBudget') {
			enqueueSnackbar(`${t('LinkDocumentDialog.CopyBudgetEntryDataFirst')} ${data.ProjectLabel || ''}`, {
				variant: 'warning',
				persist: true,
			})
			return
		}

		try {
			await addSettlement(data.Id, copiedDocumentData.id)
			showSuccessInfo('saved')
			getData()
		} catch (err) {
			console.error(err)
		}
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer className="flex justify-between m-1">
			<div>
				<ColumnsButton />
				<ExpandCollapseButtons apiRef={apiRef} isActionButtonDisabled={isActionButtonDisabled} />
			</div>
			<div className="mr-1">
				<SwitchTreeData
					isActionButtonDisabled={isActionButtonDisabled}
					isTreeData={isTreeData}
					setIsTreeData={setIsTreeData}
				/>
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.Filter"
					onClick={handleFilter}
					IconComponent={FilterListIcon}
					disabled={isActionButtonDisabled}
					fontSize="medium"
				/>
				{!readOnly && (
					<TooltipButton
						title="general.Add"
						onClick={handleAddEntry}
						IconComponent={AddIcon}
						disabled={isActionButtonDisabled}
						fontSize="medium"
					/>
				)}
			</div>
		</GridToolbarContainer>
	)
}

export default SettlementToolbar
