import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Divider } from '@mui/material'

import clsx from 'clsx'
import { LinkCellRender, Loading, NoData } from 'components/shared'
import { BudgetEntryFieldType, IBudgetEntryFieldSource } from 'models'
import { useBudgetEntryFieldSource } from 'services'
import { formatDateTimeMUIGrid } from 'utils/formatDateTime'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

type Props = {
	id: string
	fieldName: BudgetEntryFieldType | null
}

const Source = ({ id, fieldName }: Props) => {
	const [entries, setEntries] = useState<IBudgetEntryFieldSource[]>([])
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation('project')

	const { getBudgetEntryFieldSource } = useBudgetEntryFieldSource()

	const getSource = async () => {
		try {
			if (fieldName) {
				setLoading(true)
				const response = await getBudgetEntryFieldSource(id, fieldName)
				const data = response.sort(
					(a: any, b: any) => new Date(b.DocumentDate).getTime() - new Date(a.DocumentDate).getTime()
				)
				setEntries(data)
			}
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getSource()
	}, [id, fieldName])

	return (
		<div
			className={clsx(
				'w-full h-[778px]',
				'border border-solid border-border-divider dark:border-border-darkDivider rounded'
			)}>
			<p className="text-center text-base leading-3">
				{fieldName ? t(`RealizationBudgetTab.Columns.${fieldName}`) : 'Wybierz pole'}
			</p>
			<Divider />
			{loading ? (
				<Loading />
			) : entries.length ? (
				<>
					<p className="w-full pl-3">
						<span className="inline-block pb-1 w-1/5">{t('BudgetTabGeneral.BudgetEntrySource.Date')}</span>
						<span className="inline-block pb-1 w-3/5">{t('BudgetTabGeneral.BudgetEntrySource.Document')}</span>
						<span className="inline-block pb-1 pr-3 w-1/5">{t('BudgetTabGeneral.BudgetEntrySource.Value')}</span>
					</p>
					<Divider />
					<ul className="list-none pl-0 pb-4 mt-0 max-h-[675px] overflow-auto">
						{entries.map((entry: IBudgetEntryFieldSource, index: number) => (
							<li
								className={clsx(
									'w-full my-0.5 pl-3',
									'border-0 border-b border-solid border-b-border-divider dark:border-b-border-darkDivider'
								)}
								key={index}>
								<span className="inline-block pb-1 w-1/5">{formatDateTimeMUIGrid(entry.DocumentDate)}</span>
								<span className="inline-block pb-1 w-3/5">
									<LinkCellRender
										path={`/${entry.DocumentRootingPath}`}
										id={entry.DocumentId}
										documentLabel={entry.DocumentIdLabel}
										celllabel={`${t(`${entry.WorkflowDataType}`, { ns: 'routes' })} ${entry.DocumentIdLabel || ''}`}
										labelMaxLength={45}
									/>
								</span>
								<span className="inline-block pb-1 pr-3 text-right w-1/5">{formatNumberDataGrid(entry.Value)}</span>
							</li>
						))}
					</ul>
				</>
			) : (
				<NoData />
			)}
		</div>
	)
}

export default Source
