import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import Contractors from 'components/pages/dictionaries/contractors/Contractors'
import { IContractorModel, IInquiriesContractor } from 'models'
import { useContractorsService, useInquiriesService } from 'services'
import { v4 as uuidv4 } from 'uuid'

import SelectContactDialog from './SelectContactDialog'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	demandId: string
	getData: () => void
}

const ContractorsListDialog = ({ isOpen, setIsOpen, demandId, getData }: Props) => {
	const [selectedContractor, setSelectedContractor] = useState<IContractorModel>({})
	const [contractorsContactInfoOptions, setContractorsContactInfoOptions] = useState<any[]>([])
	const [isSelectContactDialogOpen, setIsSelectContactDialogOpen] = useState(false)
	const [selectedContact, setSelectedContact] = useState<string>('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['demand', 'translation'])

	const { getContractorContactInfo, showSuccessInfo } = useContractorsService()
	const { addQuotationContractor } = useInquiriesService()

	const handleClose = () => {
		setIsOpen(false)
		getData()
		setContractorsContactInfoOptions([])
		setSelectedContractor({})
	}

	const handleAddQuotationContractor = async (contractorData: IContractorModel) => {
		try {
			setButtonLoading(true)
			const Id = uuidv4()
			await addQuotationContractor({
				Id,
				DemandId: demandId,
				ContractorId: contractorData.Id,
				ContractorContactInfoId: !selectedContact || selectedContact === 'NoContact' ? null : selectedContact,
			} as IInquiriesContractor)
			handleClose()
			showSuccessInfo('action')
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	const handleChooseContractor = async (contractorData: IContractorModel) => {
		setSelectedContractor(contractorData)
		try {
			const response = await getContractorContactInfo(contractorData.Id as string)
			if (response.length) {
				const contactSelectOptions = response.map((el: any) => {
					return {
						value: el.Id,
						label: `${el.Name || ''} ${el.Email || ''} ${el.PhoneNumber || ''} ${el.Comments || ''}`,
					}
				})
				setContractorsContactInfoOptions([
					{
						value: 'NoContact',
						label: t('Inquiries.ContractorsListDialog.NoContact'),
					},
					...contactSelectOptions,
				])
				setIsSelectContactDialogOpen(true)
			} else {
				handleAddQuotationContractor(contractorData)
			}
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						{t('Inquiries.ContractorsListDialog.Title')}
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '80px',
				}}>
				<Contractors inquiries inquiriesAddFunction={handleChooseContractor} />
			</DialogContent>
			{isSelectContactDialogOpen && (
				<SelectContactDialog
					isOpen={isSelectContactDialogOpen}
					setIsOpen={setIsSelectContactDialogOpen}
					selectedContact={selectedContact}
					setSelectedContact={setSelectedContact}
					contactOptions={contractorsContactInfoOptions}
					selectedContractor={selectedContractor}
					handleAddQuotationContractor={handleAddQuotationContractor}
					buttonLoading={buttonLoading}
				/>
			)}
		</Dialog>
	)
}

export default ContractorsListDialog
