import { useTranslation } from 'react-i18next'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { TableCell, TableRow, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { LinkCellRender } from 'components/shared'
import { ITask } from 'models'
import { formatDateTime } from 'utils/formatDateTime'
import { getCurrentTheme } from 'utils/themes'

import { getStatusColor, sxStyles } from '../utils'

interface TaskRowProps {
	task: ITask
	index: number
	tasks: ITask[]
	sessionTab?: boolean
	handleOpenDialog: (id: string) => void
}

const TaskRow = ({ task, index, tasks, sessionTab, handleOpenDialog }: TaskRowProps) => {
	const { t } = useTranslation(['synchronization', 'translation'])
	const theme = getCurrentTheme()

	return (
		<TableRow
			key={index}
			sx={{
				backgroundColor: index % 2 === 0 ? 'background.secondary' : 'background.paper',
				cursor: sessionTab ? 'pointer' : 'default',
				'&:hover': {
					backgroundColor: theme === 'light' ? grey[300] : grey[800],
				},
			}}
			onClick={() => handleOpenDialog(task.Id)}>
			<TableCell
				sx={{
					...sxStyles.cell,
					borderRadius: index === tasks.length - 1 ? '0 0 0 5px' : '0',
				}}>
				{formatDateTime(task.DateTimeStart as string)}
			</TableCell>
			{sessionTab && <TableCell sx={sxStyles.cell}>{formatDateTime(task.DateTimeEnd as string)}</TableCell>}
			<TableCell sx={{ ...sxStyles.cell, color: 'info.main' }}>
				{t(`Fields.TypeEnums.${task.Type}`, {
					defaultValue: `${task.Type}`,
				})}
			</TableCell>
			<TableCell
				sx={{
					...sxStyles.cell,
					display: 'flex',
					alignItems: 'center',
				}}>
				{task.Direction === 'Out' ? (
					<ArrowUpwardIcon fontSize="small" color="warning" />
				) : (
					<ArrowDownwardIcon fontSize="small" color="success" />
				)}
				<Typography
					component={'span'}
					sx={{
						color: 'text.secondary',
						pl: '8px',
					}}>
					{t(`Fields.DirectionEnums.${task.Direction}`)}
				</Typography>
			</TableCell>
			<TableCell sx={sxStyles.cell}>{task.EntryId || ''}</TableCell>
			<TableCell sx={sxStyles.cell}>
				<LinkCellRender
					path={`/${task.ContextRootingPath}`}
					id={task.ContextItemId}
					documentLabel={task.ContextItemIdLabel}
					celllabel={task.ContextItemIdLabel}
				/>
			</TableCell>
			<TableCell sx={sxStyles.cell}>{task.Description || ''}</TableCell>
			{sessionTab && (
				<TableCell sx={{ ...sxStyles.cell, color: getStatusColor(task.Status) }}>
					{t(`Fields.StatusEnums.${task.Status}`, {
						defaultValue: `${task.Status}`,
					})}
				</TableCell>
			)}
			{sessionTab && (
				<TableCell
					sx={{
						...sxStyles.cell,
						borderRadius: index === tasks.length - 1 ? '0 0 5px 0' : '0',
					}}>
					{task.ErrorInfo}
				</TableCell>
			)}
		</TableRow>
	)
}

export default TaskRow
