import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IInvokeAction, ISalesInvoice } from 'models'
import { SALES_INVOICE_ENDPOINT } from 'services/data'

export interface getSalesInvoiceListProps {
	isCanceled: boolean
	isCompleted: boolean
	contractId?: string
	processingProtocolId?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useSalesInvoiceService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = SALES_INVOICE_ENDPOINT

	const getFilteredSalesInvoices = useCallback(
		async ({
			isCanceled,
			isCompleted,
			contractId,
			processingProtocolId,
			budgetEntryId,
			data,
		}: getSalesInvoiceListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (processingProtocolId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&processingProtocolId=${processingProtocolId}`
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getSalesInvoices = useCallback(
		async ({ isCanceled, isCompleted, contractId, processingProtocolId }: getSalesInvoiceListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (processingProtocolId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&processingProtocolId=${processingProtocolId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getSalesInvoice = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updateSalesInvoice = useCallback(
		async (id: string, data: ISalesInvoice) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getSalesInvoice,
		getSalesInvoices,
		updateSalesInvoice,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredSalesInvoices,
	}
}

export default useSalesInvoiceService
