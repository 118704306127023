import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface RedirectProps {
	path: string
}

const Redirect = ({ path }: RedirectProps) => {
	const navigate = useNavigate()

	useEffect(() => {
		navigate(path)
	}, [navigate, path])

	return <></>
}

export { Redirect }
