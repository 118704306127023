import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'

interface Props {
	fontSize?: string
	alternativeText?: string
}

const NoData = ({ fontSize = '22px', alternativeText }: Props) => {
	const { t } = useTranslation()

	return (
		<Box component={'div'} sx={sxStyles.container}>
			<Typography fontSize={fontSize}>{alternativeText || t('general.NoData')}</Typography>
		</Box>
	)
}

export default NoData

const sxStyles = {
	container: {
		width: '100%',
		height: '100%',
		padding: '30px 0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}
