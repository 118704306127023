import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { ISchedule } from 'models'

type Props = {
	data: ISchedule
}

const Heading = ({ data }: Props) => {
	const { t } = useTranslation()

	return (
		<div className={clsx('inline-flex min-w-full', 'sticky top-0 left-0 right-0 z-10')}>
			<div
				className={clsx(
					'flex w-full h-12 py-2',
					'bg-background-highlighted dark:bg-background-darkSecondary',
					'border-0 border-b border-dashed dark:border-b-border-darkDivider border-b-border-divider'
				)}>
				<div
					className={clsx(
						'bg-background-highlighted dark:bg-background-darkSecondary',
						'flex w-[766px] sticky top-0 left-0 z-10'
					)}>
					<div className={clsx('w-9 flex items-center justify-center')} />
					<div
						className={clsx(
							'w-28 flex items-center justify-center',
							'border-0 border-r border-dashed dark:border-r-border-darkDivider border-r-border-divider'
						)}>
						{t('ScheduleTab.GroupingColumn')}
					</div>
					<div
						className={clsx(
							'w-[470px] flex items-center justify-center',
							'border-0 border-r border-dashed dark:border-r-border-darkDivider border-r-border-divider'
						)}>
						{t('ScheduleTab.Name')}
					</div>
					<div
						className={clsx(
							'w-36 flex items-center justify-center',
							'border-0 border-r border-dashed dark:border-r-border-darkDivider border-r-border-divider'
						)}>
						{t('ScheduleTab.Value')}
					</div>
				</div>
				<div className="flex-grow flex items-center justify-start px-2">
					{data.DynamicCols.map((el: any, index: number) => (
						<div
							key={index}
							className={clsx(
								'w-40 flex flex-grow items-center justify-center',
								'border-0 border-r border-dashed dark:border-r-border-darkDivider border-r-border-divider last:border-none'
							)}>
							{el}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default Heading
