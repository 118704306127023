import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'

import { IDemand, IProjectModel } from 'models'
import { useDemandService, useProjectsListService, useWorkflowService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
}

type Lookup = {
	value: string | undefined
	label: string | undefined
}

const CreateDemandDialog = ({ isOpen, setIsOpen }: Props) => {
	const [workflowId, setWorkflowId] = useState<string>('')
	const [workflow, setWorkflow] = useState([])
	const [demandFormData, setDemandFormData] = useState<IDemand>()
	const [projectsList, setProjectsList] = useState<Lookup[]>([])

	const [buttonLoading, setButtonLoading] = useState(false)

	const navigate = useNavigate()

	const { t } = useTranslation('demand')

	const { createDemand, showSuccessInfo } = useDemandService()
	const { getListOfProjects } = useProjectsListService()
	const { getWorkflowLookupList } = useWorkflowService()

	const onWorkflowChange = (value: string) => {
		setWorkflowId(value)
	}

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleCreateDemand = async () => {
		try {
			setButtonLoading(true)
			const response = await createDemand(workflowId, demandFormData as IDemand)
			if (response) {
				navigate(`/demand/${response.Id}`, {
					state: { headerPostFix: ` ${response.Number}` },
				})
			}
			showSuccessInfo('action')
			closeDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	const handleInputChange = (e: any) => {
		setDemandFormData((prev: any) => {
			return { ...prev, [e.target.name]: e.target.value }
		})
	}

	const getWorkflowData = useCallback(async () => {
		try {
			const response = await getWorkflowLookupList('demand')

			setWorkflow(response)
		} catch (err) {
			console.error(err)
		}
	}, [getWorkflowLookupList])

	const getProjectsData = useCallback(async () => {
		try {
			const response = await getListOfProjects()
			setProjectsList(
				response.map((el: IProjectModel) => {
					return {
						value: el.Id,
						label: `${el.ProjectNumber || ''} ${el.InvestmentName || ''}`,
					}
				})
			)
		} catch (err) {
			console.error(err)
		}
	}, [getListOfProjects])

	useEffect(() => {
		getWorkflowData()
		getProjectsData()
	}, [])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('CreateDemandDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={workflowId}
					select
					name="Workflow"
					label={t('CreateDemandDialog.WorkflowSelectLabel')}
					onChange={e => onWorkflowChange(e.target.value)}
					fullWidth
					sx={{ mt: '10px' }}>
					{workflow.map((el: any, index: number) => (
						<MenuItem key={el.label} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</TextField>
				{workflowId && (
					<TextField
						value={demandFormData?.Project || ''}
						select
						id="Project"
						name="Project"
						label={t('Fields.Project')}
						onChange={handleInputChange}
						fullWidth
						sx={{ mt: '20px' }}>
						{projectsList.map((option, index) => {
							return (
								<MenuItem key={index} value={option.value}>
									{option.label}
								</MenuItem>
							)
						})}
					</TextField>
				)}
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={!workflowId || !demandFormData}
					loading={buttonLoading}
					onClick={handleCreateDemand}>
					{t('general.Create', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default CreateDemandDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
