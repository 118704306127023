import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ArticleIcon from '@mui/icons-material/Article'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'

import { IEmailTemplate } from 'models'
import { useEmailTemplatesService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import SaveTemplateDialog from './SaveTemplateDialog'
import TemplatesDialog from './TemplatesDialog'

interface Props {
	isSaveContentButtonDisabled: boolean
	emailSubject: string
	emailText: string
	getDataFromHtml: (data: any) => string
	setEmailSubject: (value: string) => void
	setEmailText: (value: string) => void
	setDefaultEmailText: (value: string) => void
	setIsSaveContentButtonDisabled: (value: boolean) => void
	type?: string
}

const EmailTemplates = ({
	isSaveContentButtonDisabled,
	emailSubject,
	emailText,
	getDataFromHtml,
	setEmailSubject,
	setEmailText,
	setDefaultEmailText,
	setIsSaveContentButtonDisabled,
	type,
}: Props) => {
	const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false)
	const [isTemplatesDialogOpen, setIsTemplatesDialogOpen] = useState(false)
	const [templates, setTemplates] = useState<IEmailTemplate[]>([])
	const [templatesLoading, setTemplatesLoading] = useState(true)

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const openAddMenu = Boolean(anchorEl)

	const { t } = useTranslation()

	const { getTemplates } = useEmailTemplatesService()

	const handleTemplatesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleTemplatesMenuClose = () => {
		setAnchorEl(null)
	}

	const handleOpenAddTemplateDialog = () => {
		setIsSaveTemplateDialogOpen(true)
		handleTemplatesMenuClose()
	}

	const handleOpenChooseTemplateDialog = () => {
		setIsTemplatesDialogOpen(true)
		handleTemplatesMenuClose()
	}

	const getTemplatesData = useCallback(async () => {
		try {
			setTemplatesLoading(true)
			const response = await getTemplates(type)
			setTemplates(response)
		} catch (err) {
			console.error(err)
		}
		setTemplatesLoading(false)
	}, [getTemplates, type])

	useEffect(() => {
		if (isTemplatesDialogOpen) getTemplatesData()
	}, [isTemplatesDialogOpen])

	return (
		<>
			<Tooltip title={<TooltipTextWrapper title={t('EmailParametersTab.Templates')} />} placement="top" arrow>
				<span>
					<IconButton
						sx={{ padding: '7px' }}
						id="templates-menu-button"
						aria-controls={openAddMenu ? 'templates-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={openAddMenu ? 'true' : undefined}
						onClick={handleTemplatesMenuClick}>
						<ArticleIcon fontSize="medium" color={'primary'} />
					</IconButton>
				</span>
			</Tooltip>
			<Menu
				id="templates-menu"
				anchorEl={anchorEl}
				open={openAddMenu}
				onClose={handleTemplatesMenuClose}
				MenuListProps={{
					'aria-labelledby': 'menu-button',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<MenuItem onClick={handleOpenChooseTemplateDialog}>{t('general.ChooseTemplate')}</MenuItem>
				<Tooltip
					title={
						<TooltipTextWrapper
							title={
								!emailSubject
									? t('EmailParametersTab.NoEmailSubject')
									: !emailText
										? t('EmailParametersTab.NoEmailBody')
										: !isSaveContentButtonDisabled
											? t('EmailParametersTab.NoChangesSaved')
											: ''
							}
						/>
					}
					placement={'left'}
					disableHoverListener={emailSubject && emailText && isSaveContentButtonDisabled ? true : false}
					arrow>
					<span>
						<MenuItem
							disabled={!emailSubject || !emailText || !isSaveContentButtonDisabled}
							onClick={handleOpenAddTemplateDialog}>
							{t('general.SaveAsNewTemplate')}
						</MenuItem>
					</span>
				</Tooltip>
			</Menu>
			{isTemplatesDialogOpen && (
				<TemplatesDialog
					isOpen={isTemplatesDialogOpen}
					setIsOpen={setIsTemplatesDialogOpen}
					getDataFromHtml={getDataFromHtml}
					setEmailSubject={setEmailSubject}
					setEmailText={setEmailText}
					setDefaultEmailText={setDefaultEmailText}
					setIsSaveContentButtonDisabled={setIsSaveContentButtonDisabled}
					templates={templates}
					getTemplates={getTemplatesData}
					templatesLoading={templatesLoading}
				/>
			)}
			{isSaveTemplateDialogOpen && (
				<SaveTemplateDialog
					isOpen={isSaveTemplateDialogOpen}
					setIsOpen={setIsSaveTemplateDialogOpen}
					emailSubject={emailSubject}
					emailText={emailText}
					getTemplates={getTemplates}
					type={type}
				/>
			)}
		</>
	)
}

export default EmailTemplates
