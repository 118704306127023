import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'

import { IDocumentTab } from 'models'

interface Props {
	tabs: IDocumentTab[]
}

const DocumentsTab = ({ tabs }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const location = useLocation()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem(`documentsTabSelectedTab_${location.pathname}`, newValue)
	}

	useEffect(() => {
		const selectedTabIndex = localStorage.getItem(`documentsTabSelectedTab_${location.pathname}`)
		setSelectedTab(selectedTabIndex || '0')
	}, [location.pathname])

	return (
		<Box sx={sxStyles.row}>
			<Box sx={{ width: '100%' }}>
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) return <Tab key={el.label} label={el.label} value={index.toString()} />
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible)
							return (
								<TabPanel key={index} value={index.toString()} sx={sxStyles.tabPanel}>
									{el.renderValue}
								</TabPanel>
							)
					})}
				</TabContext>
			</Box>
		</Box>
	)
}

export default DocumentsTab

const sxStyles = {
	row: {
		width: '100%',
		pb: '15px',
	},
	tabPanel: {
		padding: '0',
	},
}
