import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import SecurityIcon from '@mui/icons-material/Security'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

import PageNavigation from 'components/shared/page-navigation/PageNavigation'

import WorkflowBasicInfo from './basic-info-tab/WorkflowBasicInfo'
import Permissions from './permissions/Permissions'

const WorkflowPage = () => {
	const { t } = useTranslation('administration')

	const params = useParams()

	const id = params.id as string

	const tabs = useMemo(() => {
		return [
			{
				id: 0,
				tabName: t('Workflow.Tabs.BasicInformation'),
				icon: <TextSnippetIcon />,
				component: <WorkflowBasicInfo workflowId={id} />,
				disabled: false,
			},
			{
				id: 1,
				tabName: t('Workflow.Tabs.Permissions'),
				icon: <SecurityIcon />,
				component: <Permissions workflowId={id} />,
				disabled: false,
			},
		]
	}, [id, t])

	return <PageNavigation tabs={tabs} actionsNode={[]} />
}

export default WorkflowPage
