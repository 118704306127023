import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const CostInvoiceIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<g>
				<line fill="currentColor" x1="16.559" y1="11.344" x2="9.049" y2="18.854" />
				<path
					fill="currentColor"
					d="M9.049,19.475c-0.159,0-0.317-0.061-0.439-0.182c-0.243-0.242-0.243-0.637,0-0.879l7.509-7.51
		c0.242-0.243,0.636-0.243,0.879,0c0.243,0.243,0.243,0.637,0,0.88l-7.509,7.509C9.367,19.414,9.208,19.475,9.049,19.475z"
				/>
			</g>
			<path
				fill="currentColor"
				d="M9.57,14.244c-1.313,0-2.38-1.068-2.38-2.381c0-1.312,1.067-2.379,2.38-2.379c1.312,0,2.38,1.067,2.38,2.379
	C11.95,13.176,10.882,14.244,9.57,14.244z M9.57,10.729c-0.626,0-1.136,0.509-1.136,1.135c0,0.626,0.51,1.135,1.136,1.135
	s1.136-0.509,1.136-1.135C10.706,11.238,10.196,10.729,9.57,10.729z"
			/>
			<path
				fill="currentColor"
				d="M16.036,20.711c-1.313,0-2.38-1.068-2.38-2.379c0-1.313,1.066-2.381,2.38-2.381
	c1.312,0,2.379,1.068,2.379,2.381C18.415,19.643,17.348,20.711,16.036,20.711z M16.036,17.195c-0.626,0-1.136,0.51-1.136,1.137
	c0,0.625,0.51,1.135,1.136,1.135c0.625,0,1.135-0.51,1.135-1.135C17.171,17.705,16.661,17.195,16.036,17.195z"
			/>
			<path
				fill="currentColor"
				d="M19.575,24H5.924c-1.061,0-1.923-0.861-1.923-1.922V1.923C4.001,0.862,4.863,0,5.924,0h8.651
	c0.872,0,2.042,0.484,2.659,1.102l3.16,3.162c0.619,0.617,1.104,1.786,1.104,2.66v15.154C21.498,23.139,20.637,24,19.575,24z
	 M5.924,1.245c-0.374,0-0.679,0.305-0.679,0.678v20.155c0,0.373,0.305,0.678,0.679,0.678h13.651c0.374,0,0.679-0.305,0.679-0.678
	V6.924c0-0.546-0.352-1.395-0.736-1.78l-3.162-3.162c-0.387-0.386-1.234-0.737-1.779-0.737H5.924z"
			/>
			<path
				fill="currentColor"
				d="M20.57,6.246h-4.694c-0.344,0-0.622-0.278-0.622-0.622v-4.34c0-0.344,0.278-0.623,0.622-0.623
	c0.343,0,0.621,0.279,0.621,0.623v3.718h4.073c0.345,0,0.623,0.278,0.623,0.623C21.193,5.967,20.915,6.246,20.57,6.246z"
			/>
			<path
				fill="currentColor"
				d="M12.751,6.246H6.523c-0.344,0-0.622-0.278-0.622-0.622c0-0.344,0.278-0.623,0.622-0.623h6.228
	c0.345,0,0.622,0.278,0.622,0.623C13.373,5.967,13.096,6.246,12.751,6.246z"
			/>
			<path
				fill="currentColor"
				d="M12.751,3.797H6.523c-0.344,0-0.622-0.279-0.622-0.623c0-0.344,0.279-0.622,0.622-0.622h6.228
	c0.345,0,0.622,0.279,0.622,0.623C13.373,3.519,13.096,3.797,12.751,3.797z"
			/>
		</SvgIcon>
	)
}

export default CostInvoiceIcon
