import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { FilterPanel, SettingsMenu, TooltipButton } from 'components/shared'

import CreateCreditNoteDialog from './CreateCreditNoteDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	getFilteredData: (reset?: boolean) => void
	apiRef: { current: any }
	showCanceled: boolean
	setShowCanceled: (value: boolean) => void
	showCompleted: boolean
	setShowCompleted: (value: boolean) => void
	handleFilter: () => void
}

const Toolbar = ({
	getFilteredData,
	apiRef,
	showCanceled,
	setShowCanceled,
	showCompleted,
	setShowCompleted,
	handleFilter,
}: Props) => {
	const [isCreateCreditNoteDialogOpened, setIsCreateCreditNoteDialogOpened] = useState(false)

	const handleUpdateList = () => getFilteredData()

	return (
		<GridToolbarContainer className="items-center justify-between m-1.5">
			<FilterPanel
				handleFilter={handleFilter}
				apiRef={apiRef}
				getFilteredData={getFilteredData}
				contextName={CONTEXT_STATE_NAME}
				showCanceled={showCanceled}
				setShowCanceled={setShowCanceled}
				showCompleted={showCompleted}
				setShowCompleted={setShowCompleted}
			/>
			<div>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				<TooltipButton
					title="general.Add"
					onClick={() => setIsCreateCreditNoteDialogOpened(true)}
					IconComponent={AddIcon}
					fontSize="medium"
				/>
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
				{isCreateCreditNoteDialogOpened && (
					<CreateCreditNoteDialog
						isOpen={isCreateCreditNoteDialogOpened}
						setIsOpen={setIsCreateCreditNoteDialogOpened}
					/>
				)}
			</div>
		</GridToolbarContainer>
	)
}

export default Toolbar
