import { useMemo } from 'react'

import CancelIcon from '@mui/icons-material/Cancel'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import ListItemIcon from '@mui/material/ListItemIcon'

const iconStyles = {
	fontSize: '16px',
}

type Props = {
	typeOfChange: string | undefined
}

const HistoryItemIcon = ({ typeOfChange }: Props) => {
	const itemIcon = useMemo(() => {
		switch (typeOfChange) {
			case 'Created':
				return <SaveAsIcon sx={iconStyles} color="primary" />
			case 'StageChanged':
				return <ChangeCircleIcon sx={iconStyles} color="info" />
			case 'OwnerChanged':
				return <PeopleAltIcon sx={iconStyles} color="warning" />
			case 'Canceled':
				return <CancelIcon sx={iconStyles} color="error" />
			default:
				return <FiberManualRecordIcon sx={iconStyles} color="action" />
		}
	}, [typeOfChange])

	return <ListItemIcon sx={{ minWidth: '36px' }}>{itemIcon}</ListItemIcon>
}

export default HistoryItemIcon
