import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { ISecurity } from 'models'

const useSecurityService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getSecurityItems = useCallback(async (projectId: string) => {
		const response = await request(`/api/ProjectSecurity?$filter=ProjectId eq ${projectId}`)
		return response
	}, [])

	const addSecurity = useCallback(async (body: ISecurity) => {
		const response = await request(`/odata/ProjectSecurity`, 'POST', body)
		return response
	}, [])

	const updateSecurity = useCallback(async (securityId: string, body: ISecurity) => {
		await request(`/odata/ProjectSecurity/${securityId}`, 'PATCH', body)
	}, [])

	const deleteSecurity = useCallback(async (securityId: string) => {
		const response = await request(`/api/ProjectSecurity/${securityId}`, 'DELETE')
		return response
	}, [])

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		addSecurity,
		updateSecurity,
		deleteSecurity,
		getSecurityItems,
	}
}

export default useSecurityService
