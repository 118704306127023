import React, { useEffect } from 'react'

import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { useAppSelector, useCalendarActions } from 'features'
import { ICalendarEvent } from 'models'
import { useCalendarService } from 'services'

import Day from './Day'

type Props = {
	currentMonth: Dayjs[][]
}

const Month = ({ currentMonth }: Props) => {
	const { calendarDataRange } = useAppSelector(state => state.calendar)

	const { getCalendarData } = useCalendarService()
	const { setCalendarEvents } = useCalendarActions()

	const getCalendarEntries = async () => {
		if (calendarDataRange.dateFrom && calendarDataRange.dateTo)
			try {
				const dateFrom = dayjs(calendarDataRange.dateFrom).format('YYYY-MM-DD')
				const dateTo = dayjs(calendarDataRange.dateTo).format('YYYY-MM-DD')

				const response = await getCalendarData(dateFrom, dateTo)

				const uniqueDates: string[] = Array.from(new Set(response.map((el: ICalendarEvent) => el.DateToDisplay)))

				const result = uniqueDates.reduce(
					(accumulator: { [key: string]: ICalendarEvent[] }, date: string) => {
						const elementsFilteredByDate = response.filter((el: ICalendarEvent) => el.DateToDisplay === date)
						accumulator[date] = elementsFilteredByDate
						return accumulator
					},
					{} as { [key: string]: ICalendarEvent[] }
				)

				setCalendarEvents(result)
			} catch (err) {
				console.error(err)
			}
	}

	useEffect(() => {
		getCalendarEntries()
	}, [calendarDataRange.dateFrom, calendarDataRange.dateTo])

	return (
		<div className={clsx(`h-full flex-1 grid grid-cols-7 auto-rows-fr`)}>
			{currentMonth.map((row: any, index) => (
				<React.Fragment key={index}>
					{row.map((day: any, dayIndex: number) => (
						<Day key={dayIndex} day={day} rowIndex={index} indexInRow={dayIndex} />
					))}
				</React.Fragment>
			))}
		</div>
	)
}

export default Month
