import { useTranslation } from 'react-i18next'

import { Box, Stack } from '@mui/material'

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton'

const Tags = () => {
	const { t } = useTranslation(['administration', 'translation'])

	return (
		<Box sx={sxStyles.helperTextContainer}>
			<Stack component="span" sx={sxStyles.tag}>
				{t('Workflow.Fields.NumeratorForm.Tags.Title')}
			</Stack>
			<Stack component="span" sx={sxStyles.tag}>
				<CopyToClipboardButton text={t('Workflow.Fields.NumeratorForm.Tags.@number')} />
				{` ${t('Workflow.Fields.NumeratorForm.Tags.NumberDescription')}`}
			</Stack>
			<Stack component="span" sx={sxStyles.tag}>
				<CopyToClipboardButton text={t('Workflow.Fields.NumeratorForm.Tags.@month')} />
				{` ${t('Workflow.Fields.NumeratorForm.Tags.MonthDescription')}`}
			</Stack>
			<Stack component="span" sx={sxStyles.tag}>
				<CopyToClipboardButton text={t('Workflow.Fields.NumeratorForm.Tags.@year')} />
				{` ${t('Workflow.Fields.NumeratorForm.Tags.YearDescription')}`}
			</Stack>
			<Stack component="span" sx={sxStyles.tag}>
				<CopyToClipboardButton text={t('Workflow.Fields.NumeratorForm.Tags.@project')} />
				{` ${t('Workflow.Fields.NumeratorForm.Tags.ProjectDescription')}`}
			</Stack>
			<Stack component="span" sx={sxStyles.tag}>
				<CopyToClipboardButton text={t('Workflow.Fields.NumeratorForm.Tags.@contract')} />
				{` ${t('Workflow.Fields.NumeratorForm.Tags.ContractDescription')}`}
			</Stack>
		</Box>
	)
}

export default Tags

const sxStyles = {
	helperTextContainer: {
		p: '5px',
		display: 'flex',
		flexDirection: 'column',
	},
	tag: {
		display: 'flex',
		flexDirection: 'row',
		p: '7px',
	},
}
