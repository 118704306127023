import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	MenuItem,
	TextField,
} from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-premium'

import { InputHint } from 'components/shared'
import { OfferStatus } from 'data/lookup-data-sources/OfferStatus'
import { useOfferEntriesService } from 'services'

type Props = {
	offerId: string
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	selectedEntries: GridSelectionModel
	updateData: () => void
}

const EntryStatusDialog = ({ isOpen, setIsOpen, selectedEntries, offerId, updateData }: Props) => {
	const [entryStatus, setEntryStatus] = useState('Unknown')

	const { t } = useTranslation('offer')

	const { setEntriesStatus } = useOfferEntriesService()

	const closeDialog = () => {
		setIsOpen(false)
		setEntryStatus('Unknown')
	}

	const handleSaveEntriesStatus = async () => {
		try {
			await setEntriesStatus(offerId, entryStatus, selectedEntries as string[])

			updateData()
			closeDialog()
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('OfferEntries.EntryStatusDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					select
					sx={{ mt: '10px' }}
					hidden={true}
					disabled={false}
					value={entryStatus}
					name="EntryStatus"
					label={t('OfferEntries.OfferStatus')}
					onChange={e => setEntryStatus(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<InputHint translationPath="Offer.Entries.OfferStatus" select />
							</InputAdornment>
						),
					}}
					fullWidth>
					{OfferStatus.map((el: any, index: number) => (
						<MenuItem key={index} value={el.value}>
							{t(`OfferEntries.OfferStatusEnums.${el.label}`)}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="contained" color="success" onClick={handleSaveEntriesStatus}>
					{t('general.Save', { ns: 'translation' })}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default EntryStatusDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
