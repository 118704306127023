import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { ICommonTask, IInvokeAction } from 'models'
import { COMMON_TASK_ENDPOINT } from 'services/data'

const useCommonTaskService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const getFilteredCommonTasks = useCallback(
		async (isCanceled: boolean, isCompleted: boolean, data: GridInitialState) => {
			const response = await request(
				`${COMMON_TASK_ENDPOINT}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`,
				'POST',
				data
			)
			return response
		},
		[request]
	)

	const getCommonTasks = useCallback(
		async (isCanceled: boolean, isCompleted: boolean) => {
			const response = await request(`${COMMON_TASK_ENDPOINT}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`)
			return response
		},
		[request]
	)

	const getCommonTask = useCallback(
		async (id: string) => {
			const response = await request(`${COMMON_TASK_ENDPOINT}/${id}`)
			return response
		},
		[request]
	)

	const addCommonTask = useCallback(
		async (workflowId: string) => {
			const response = await request(`${COMMON_TASK_ENDPOINT}?workflowId=${workflowId}`, 'POST')
			return response
		},
		[request]
	)

	const updateCommonTask = useCallback(
		async (id: string, data: ICommonTask) => {
			await request(`${COMMON_TASK_ENDPOINT}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${COMMON_TASK_ENDPOINT}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${COMMON_TASK_ENDPOINT}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${COMMON_TASK_ENDPOINT}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${COMMON_TASK_ENDPOINT}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		errorStatus,
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getCommonTasks,
		getCommonTask,
		addCommonTask,
		updateCommonTask,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredCommonTasks,
	}
}

export default useCommonTaskService
