import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CloudSyncIcon from '@mui/icons-material/CloudSync'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'

import PageNavigation from 'components/shared/page-navigation/PageNavigation'

import DataWaiting from './data-waiting-tab/DataWaiting'
import SessionsTab from './sessions-tab/SessionsTab'

const Synchronization = () => {
	const { t } = useTranslation(['synchronization', 'translation'])

	const tabs = useMemo(() => {
		return [
			{
				id: 0,
				tabName: t('Tabs.SyncSessions'),
				icon: <CloudSyncIcon />,
				component: <SessionsTab />,
				disabled: false,
			},
			{
				id: 1,
				tabName: t('Tabs.DataWaiting'),
				icon: <HourglassBottomIcon />,
				component: <DataWaiting />,
				disabled: false,
			},
		]
	}, [t])

	return (
		<>
			<PageNavigation tabs={tabs} />
		</>
	)
}

export default Synchronization
