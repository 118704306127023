import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IContractorModel } from 'models'

const useContractorsService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/contractor'
	const odataPath = '/odata/contractor'

	const getContractorsList = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const getContractorsLookup = useCallback(async () => {
		const response = await request(`${path}/lookup`)
		return response
	}, [request])

	const getContractor = useCallback(
		async (contractorId: string) => {
			const response = await request(`${path}/${contractorId}`)
			return response
		},
		[request]
	)

	const getContractorContactInfo = useCallback(
		async (contractorId: string) => {
			const response = await request(`/api/contractorContactInfo/${contractorId}`)
			return response
		},
		[request]
	)

	const addContractorContactInfo = useCallback(
		async (data: any) => {
			const response = await request(`/api/contractorContactInfo`, 'POST', data)
			return response
		},
		[request]
	)

	const updateContractorContactInfo = useCallback(
		async (contactInfoId: string, body: any) => {
			await request(`/api/contractorContactInfo/${contactInfoId}`, 'PATCH', body)
		},
		[request]
	)

	const deleteContractorContactInfo = useCallback(
		async (contactInfoId: string) => {
			await request(`/api/contractorContactInfo/${contactInfoId}`, 'DELETE')
		},
		[request]
	)

	const addContractor = useCallback(
		async (data: IContractorModel, quotationRequestContractorId?: string) => {
			const response = await request(
				quotationRequestContractorId
					? `${odataPath}?quotationRequestContractorId=${quotationRequestContractorId}`
					: `${odataPath}`,
				'POST',
				data
			)
			return response
		},
		[request]
	)

	const updateContractor = useCallback(
		async (id: string, body: IContractorModel) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deleteContractor = useCallback(
		async (contractorId: string) => {
			await request(`${path}/${contractorId}`, 'DELETE')
		},
		[request]
	)

	const importGus = useCallback(
		async (id: string, searchBy: string) => {
			await request(`${path}/importGUS/${id}?searchBy=${searchBy}`, 'PATCH')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getContractorsList,
		getContractor,
		getContractorContactInfo,
		updateContractorContactInfo,
		deleteContractorContactInfo,
		addContractor,
		updateContractor,
		deleteContractor,
		addContractorContactInfo,
		importGus,
		getContractorsLookup,
	}
}

export default useContractorsService
