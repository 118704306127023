import { useCallback, useEffect, useState } from 'react'

import { DataGridPremium, GridToolbar } from '@mui/x-data-grid-premium'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import useLanguage from 'hooks/UseLanguge'
import { IAttachmentPreviewFile } from 'models'
import readXlsxFile from 'read-excel-file'
import { useAttachmentsService } from 'services'

import { XLSXViewerStylesConst } from './styles'

interface Props {
	file: IAttachmentPreviewFile
}

const XLSXViewer = ({ file }: Props) => {
	const [data, setData] = useState<Array<Array<any>>>([])
	const [dataLoading, setDataLoading] = useState(true)

	const { dataGridLanguage } = useLanguage()

	const { getURL } = useAttachmentsService()

	const generateExcelAttachment = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getURL(file.id)

			if (response) {
				let reader = new FileReader()
				reader.onload = async e => {
					const buffer = e.target?.result as ArrayBuffer
					const rows = await readXlsxFile(buffer)
					setData(rows)
				}
				reader.readAsArrayBuffer(response.blob)
			}
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [file])

	useEffect(() => {
		generateExcelAttachment()
	}, [file.id])

	if (dataLoading) {
		return <Loading />
	} else if (data.length === 0) {
		return <NoData />
	}

	const firstRow = data[0]
	const columns = [
		{
			field: 'index',
			headerName: 'A',
			width: 40,
		},
		...firstRow.map((header, index) => {
			let columnName = ''
			let dividend = index + 2
			let modulo
			while (dividend > 0) {
				modulo = (dividend - 1) % 26
				columnName = String.fromCharCode(65 + modulo) + columnName
				dividend = Math.floor((dividend - modulo) / 26)
			}

			return {
				field: `column${index + 1}`,
				headerName: columnName,
				width: 150,
			}
		}),
	]

	const rows = data.slice(1).map((row, index) => {
		let rowObject: { [key: string]: any } = { id: index, index: index + 1 }
		row.forEach((cell, cellIndex) => {
			rowObject[`column${cellIndex + 1}`] = cell instanceof Date ? cell.toLocaleString() : cell
		})
		return rowObject
	})

	return (
		<div style={{ height: '100%', width: '100%' }}>
			<DataGridPremium
				loading={dataLoading}
				rows={rows}
				columns={columns}
				pageSize={500}
				sx={XLSXViewerStylesConst}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
				pagination
				components={{
					NoRowsOverlay: () => <NoData />,
					Toolbar: GridToolbar,
				}}
			/>
		</div>
	)
}

export default XLSXViewer
