import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector, useNotificationsActions } from 'features'
import { enqueueSnackbar } from 'notistack'
import { useNotificationService } from 'services'
import { getCurrentDateTime, getDateTimeBeforeNow } from 'utils'

import { usePrepareNotificationTranslation } from './utils'

const GlobalNotification = () => {
	const { newNotifications } = useAppSelector(state => state.notifications)

	const { setNewNotifications, setNotificationsCount } = useNotificationsActions()
	const { getNewNotifications, getNotificationCount } = useNotificationService()
	const { getNotificationTranslation } = usePrepareNotificationTranslation()

	const { t } = useTranslation('notifications')

	const getNotificationsData = async () => {
		const localStorageMarker = localStorage.getItem('NOTIFICATIONS_DATE_TIME_MARKER')?.toString()

		const currentDate = getCurrentDateTime()
		const dateFrom = getDateTimeBeforeNow(7)

		try {
			const response = await getNewNotifications(localStorageMarker || '')

			if (response.Marker) localStorage.setItem('NOTIFICATIONS_DATE_TIME_MARKER', response.Marker)
			if (response.Notifications) setNewNotifications(response.Notifications)

			const notificationsCount = await getNotificationCount(dateFrom, currentDate, false)
			setNotificationsCount(notificationsCount)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		const interval = setInterval(getNotificationsData, 60000)

		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		if (newNotifications.length > 2) {
			enqueueSnackbar(t(`Type.General`), { variant: 'info', autoHideDuration: 8000 })
		} else {
			newNotifications.forEach(notification => {
				const translation = getNotificationTranslation(notification)

				if (!notification.IsReaded) {
					enqueueSnackbar(translation, { variant: 'info', autoHideDuration: 8000 })
				}
			})
		}
	}, [newNotifications, enqueueSnackbar])

	return null
}

export default GlobalNotification
