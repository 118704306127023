import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import LinkIcon from '@mui/icons-material/Link'
import { IconButton, Tooltip } from '@mui/material'

import { DefaultAttachmentPreviewButton, DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { useLinkDocuments } from 'hooks'
import { IProcessingProtocol } from 'models'
import { useProcessingProtocolService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import ProcessingProtocolForm from './ProcessingProtocolForm'

interface Props {
	protocolId: string
}

const ProcessingProtocolBasicInfo = ({ protocolId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { t } = useTranslation()

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { handleCopyDocumentData } = useLinkDocuments()
	const { getProcessingProtocol, updateProcessingProtocol } = useProcessingProtocolService()

	const handleCopyDataForLinking = () => {
		handleCopyDocumentData({
			id: documentData?.Id as string,
			name: `${t('ProcessingProtocol', { ns: 'routes' })} ${documentData?.Number}`,
			documentType: 'ProcessingProtocol',
			otherData: documentData,
		})
	}

	const refreshDocumentData = async () => {
		try {
			const response = await getProcessingProtocol(protocolId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [protocolId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={8} tabs={4} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
					/>
				</div>
				<div>
					<DefaultAttachmentPreviewButton attachmentId={documentData?.DefaultAttachmentId} />
					<Tooltip title={<TooltipTextWrapper title={t('LinkDocumentClipboard.CopyId')} />} placement="top" arrow>
						<IconButton onClick={() => handleCopyDataForLinking()}>
							<LinkIcon color="primary" />
						</IconButton>
					</Tooltip>
					<ChangeHistory
						contextId={protocolId}
						translationFile="processing-protocol"
						title={`${t('ProcessingProtocol', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="Fields"
					/>
					<DocumentFormSaveButton
						documentId={protocolId}
						data={documentData}
						updateDataFunction={updateProcessingProtocol}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<ProcessingProtocolForm formData={documentData as IProcessingProtocol} protocolId={protocolId} />
			</div>
		</>
	)
}

export default ProcessingProtocolBasicInfo
