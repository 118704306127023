import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useGoodsReceivedNoteEntriesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/goodsReceivedNoteEntry'

	const getEntries = useCallback(
		async (grnId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&grnId=${grnId}`)
			return response
		},
		[request]
	)

	const addEntry = useCallback(
		async (grnId: string, orderEntryId: string) => {
			const response = await request(`${path}?grnId=${grnId}&orderEntryId=${orderEntryId}`, 'POST')
			return response
		},
		[request]
	)

	const updateEntry = useCallback(
		async (id: string, amount: number | string) => {
			await request(`${path}/${id}?amount=${amount}`, 'PATCH')
		},
		[request]
	)

	const deleteEntry = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getEntries,
		addEntry,
		updateEntry,
		deleteEntry,
	}
}

export default useGoodsReceivedNoteEntriesService
