import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from '@mui/material'

import { useAppSelector } from 'features'
import { Action } from 'models'

interface Props {
	handlePrepareAction: (action: Action) => void
}

const ActionsMenu = ({ handlePrepareAction }: Props) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const { actionsList } = useAppSelector(state => state.documentData)

	const { t } = useTranslation()

	const { formLoading } = useAppSelector(state => state.documentData)

	const open = Boolean(anchorEl)

	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const handleMenuItemCLick = (action: Action) => {
		handlePrepareAction(action)
		handleMenuClose()
	}
	if (!actionsList.length) {
		return null
	}

	return (
		<>
			<IconButton
				className="my-0 mx-1"
				id="menu-button"
				aria-controls={open ? 'actions-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				disabled={formLoading}
				onClick={handleMenuClick}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="actions-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleMenuClose}
				MenuListProps={{
					'aria-labelledby': 'menu-button',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				{actionsList?.map(action => (
					<MenuItem key={action.Name} disabled={!action.Enabled} onClick={() => handleMenuItemCLick(action)}>
						{t(`Actions.Names.${action.Name}`)}
					</MenuItem>
				))}
			</Menu>
		</>
	)
}

export default ActionsMenu
