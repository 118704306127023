import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Divider, MenuItem, Stack } from '@mui/material'

import { Loading, NoData } from 'components/shared'
import { useAppSelector, useNotificationsActions } from 'features'
import { INotification } from 'models'
import { useNotificationService } from 'services'
import { getCurrentDateTime, getDateTimeBeforeNow } from 'utils'

import SingleNotification from './SingleNotification'
import { styles } from './styles'

type Props = {
	closeMenu: () => void
}

const NotificationsList = ({ closeMenu }: Props) => {
	const [loading, setLoading] = useState(true)
	const { notReadedNotifications } = useAppSelector(state => state.notifications)

	const { setNotReadedNotifications, setNotificationsCount } = useNotificationsActions()

	const { getNotifications, getNotificationCount, updateNotification } = useNotificationService()

	const { t } = useTranslation('notifications')

	const saveUpdatedNotifications = async (notification: INotification) => {
		try {
			await updateNotification(notification.Id, notification)
		} catch (err) {
			console.error(err)
		}
	}

	const handleUpdateNotifications = () => {
		const updatedNotifications = notReadedNotifications.map(el => ({ ...el, IsReaded: true }))

		updatedNotifications.forEach((el: INotification) => saveUpdatedNotifications(el))
		getNotificationsData()
	}

	const getNotificationsData = async () => {
		const currentDate = getCurrentDateTime()
		const dateFrom = getDateTimeBeforeNow(7)

		try {
			setLoading(true)
			const response = await getNotifications(dateFrom, currentDate, false)

			if (response) {
				const notificationsSortedByDate = response.sort((a: INotification, b: INotification) => {
					return new Date(b.DateTime).valueOf() - new Date(a.DateTime).valueOf()
				})
				setNotReadedNotifications(notificationsSortedByDate)
			}

			const notificationsCount = await getNotificationCount(dateFrom, currentDate, false)
			setNotificationsCount(notificationsCount)
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getNotificationsData()
	}, [])

	return (
		<>
			<Box color="inherit" sx={styles.content}>
				{loading ? (
					<Loading />
				) : notReadedNotifications.length ? (
					notReadedNotifications.map(el => <SingleNotification key={el.Id} notification={el} closeMenu={closeMenu} />)
				) : (
					<NoData />
				)}
			</Box>
			<Divider />
			<MenuItem disabled={!notReadedNotifications.length} onClick={handleUpdateNotifications}>
				<Stack sx={styles.clearButton}>{t('MarkAllAsRead')}</Stack>
			</MenuItem>
		</>
	)
}

export default NotificationsList
