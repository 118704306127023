import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IInvokeAction, IMaterialInvoice } from 'models'
import { MATERIAL_INVOICE_ENDPOINT } from 'services/data'

export interface getMaterialInvoiceListProps {
	isCanceled?: boolean
	isCompleted?: boolean
	goodsReceivedNoteId?: string
	orderId?: string
	projectId?: string
	incomingLetterId?: string
	contractorId?: string
	foreignNumber?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useMaterialInvoiceService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = MATERIAL_INVOICE_ENDPOINT

	const getFilteredMaterialInvoices = useCallback(
		async ({
			isCanceled,
			isCompleted,
			goodsReceivedNoteId,
			orderId,
			projectId,
			incomingLetterId,
			contractorId,
			foreignNumber,
			budgetEntryId,
			data,
		}: getMaterialInvoiceListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (goodsReceivedNoteId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&goodsReceivedNoteId=${goodsReceivedNoteId}`
			}

			if (orderId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&orderId=${orderId}`
			}

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (incomingLetterId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			if (contractorId && foreignNumber) {
				getListPath = `${path}/filter?includeCanceled=${false}&contractorId=${contractorId}&foreignNumber=${foreignNumber}`
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getMaterialInvoices = useCallback(
		async ({
			isCanceled,
			isCompleted,
			goodsReceivedNoteId,
			orderId,
			projectId,
			incomingLetterId,
			contractorId,
			foreignNumber,
		}: getMaterialInvoiceListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (goodsReceivedNoteId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&goodsReceivedNoteId=${goodsReceivedNoteId}`
			}

			if (orderId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&orderId=${orderId}`
			}

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (incomingLetterId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&incomingLetterId=${incomingLetterId}`
			}

			if (contractorId && foreignNumber) {
				getListPath = `${path}?isCanceled=${false}&contractorId=${contractorId}&foreignNumber=${foreignNumber}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getMaterialInvoice = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const createMaterialInvoice = useCallback(
		async (workflowId: string) => {
			const response = await request(`${path}?workflowId=${workflowId}`, 'POST')
			return response
		},
		[request]
	)

	const updateMaterialInvoice = useCallback(
		async (id: string, data: IMaterialInvoice) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getMaterialInvoices,
		getMaterialInvoice,
		updateMaterialInvoice,
		createMaterialInvoice,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredMaterialInvoices,
	}
}

export default useMaterialInvoiceService
