import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	TextField,
} from '@mui/material'

import { useEmailTemplatesService } from 'services'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	emailSubject: string
	emailText: string
	getTemplates: () => void
	type?: string
}

const SaveTemplateDialog = ({ isOpen, setIsOpen, emailSubject, emailText, getTemplates, type }: Props) => {
	const [value, setValue] = useState<string | undefined>('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation()

	const { addTemplate, showSuccessInfo } = useEmailTemplatesService()

	const closeDialog = () => {
		setIsOpen(false)
		setValue('')
	}

	const handleValueChange = (e: any) => {
		setValue(e.target.value)
	}

	const handleAddTemplate = async () => {
		if (value && emailSubject && emailText) {
			const data = {
				Name: value,
				EmailSubject: emailSubject,
				EmailBody: emailText,
				Type: type ? type : 'QuotationRequest',
			}
			setButtonLoading(true)
			try {
				await addTemplate(data)
				showSuccessInfo('added')
				setButtonLoading(false)
				getTemplates()
				closeDialog()
			} catch (err) {
				console.error(err)
			}
		}
		setButtonLoading(false)
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('EmailParametersTab.SaveTemplateDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<FormControl fullWidth>
					<Box sx={{ width: '100%', margin: '15px 0' }}>
						<TextField
							value={value}
							fullWidth
							onChange={handleValueChange}
							label={t('EmailParametersTab.SaveTemplateDialog.InputLabel')}
						/>
					</Box>
				</FormControl>
			</DialogContent>
			<DialogActions sx={{ padding: '8px 26px 16px 8px' }}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.Cancel')}
				</Button>
				<LoadingButton
					variant="contained"
					onClick={handleAddTemplate}
					color="success"
					disabled={!value}
					loading={buttonLoading}>
					{t('general.Add')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default SaveTemplateDialog
