import { ApiBase } from './ApiBase'

interface IPermissionsApi {
	myPermissions(): Promise<any>
}

class PermissionsApi extends ApiBase implements IPermissionsApi {
	async myPermissions(): Promise<any> {
		const response = await super.CallT<any>('/api/permission/my')
		return response.data
	}
}

export const permissionsApi = new PermissionsApi()
export type { IPermissionsApi }
