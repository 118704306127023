import * as React from 'react'

import { GridRenderCellParams } from '@mui/x-data-grid-premium'

import clsx from 'clsx'

interface ProgressBarProps {
	value: number
}

const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
	const { value } = props
	const limitedValue = Math.min(value, 100)

	return (
		<div
			aria-hidden="true"
			className="relative overflow-hidden w-full h-6 border-1 border-solid border-border-divider dark:border-border-darkDivider rounded-lg">
			<div
				aria-hidden="true"
				className={clsx('absolute flex items-center justify-center w-full font-bold', {
					'text-white': value > 100,
				})}
				style={{ lineHeight: '25px' }}>
				{`${value.toLocaleString()} %`}
			</div>
			<div className={clsx('w-full h-full bg-gradient-to-r from-lime-300 via-lime-500 to-green-600')}>
				<div aria-hidden="true" className={clsx('flex flex-row-reverse h-full w-full bg-transparent')}>
					<div
						className={clsx('bg-background-default dark:bg-background-darkPaper', {
							'bg-transparent dark:bg-transparent bg-gradient-to-r from-orange-400 to-orange-500': value > 100,
						})}
						style={{ width: value <= 100 ? `${100 - limitedValue}%` : '100%' }}
					/>
				</div>
			</div>
		</div>
	)
})
export function RenderProgress(params: GridRenderCellParams<any, any, any>) {
	if (params.value == null) {
		return ''
	}

	return (
		<div className="h-full w-full flex items-center" aria-hidden="true">
			<ProgressBar value={params.value} />
		</div>
	)
}
