import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Loading from 'components/shared/loading/Loading'
import { IStageActionsPermission } from 'models'

import SinglePermission from './SinglePermission'

interface Props {
	actionsPermissions: IStageActionsPermission[]
	handleDataChange: (value: any) => void
	loading: boolean
}

const StageActionsPermissions = ({ actionsPermissions, handleDataChange, loading }: Props) => {
	const { t } = useTranslation(['administration', 'translation'])

	return (
		<Box sx={sxStyles.container}>
			<Typography component={'div'} fontSize={'16px'} sx={sxStyles.title}>
				{t('Workflow.Permissions.RolePermissions.Stages.ActionPermissions.Title')}
			</Typography>
			{loading ? (
				<Loading />
			) : (
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ m: '10px 0' }}>
					{actionsPermissions.map(el => {
						return (
							<Grid key={el.Key} xs={12} sm={12} md={6} lg={4}>
								<Box sx={sxStyles.permissionContainer}>
									<SinglePermission permission={el} handleDataChange={handleDataChange} />
								</Box>
							</Grid>
						)
					})}
				</Grid>
			)}
		</Box>
	)
}

export default StageActionsPermissions

const sxStyles = {
	container: {
		p: '15px',
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		textAlign: 'center',
		color: 'text.secondary',
	},
	permissionContainer: {
		width: '95%',
		padding: '5px 0',
		borderBottom: '1px dotted',
		borderColor: 'action.disabled',
	},
}
