import { useCallback } from 'react'

import { useAppSelector } from 'features'

import { usePermissions } from './UsePermissions'

const useCheckPermissions = () => {
	const { allowedRootingsList } = useAppSelector(state => state.routingList)

	const { isGranted } = usePermissions()

	const isPathAllowed = useCallback(
		(path: string) => {
			return allowedRootingsList.find(el => el.toLowerCase() === path.toLowerCase())
		},
		[allowedRootingsList]
	)

	const isAnyPathFromGroupAllowed = useCallback(
		(pathGroup: string[]) => {
			return allowedRootingsList.some(element => pathGroup.includes(element))
		},
		[allowedRootingsList]
	)

	const checkForPermissionToAccessDocument = useCallback(
		(path: string, documentId: string) => {
			const hasPermissionForRoute = isPathAllowed(`${path}/:id`)
			return hasPermissionForRoute && (path !== '/project' || isGranted('ProjectReader', documentId))
		},
		[isPathAllowed, isGranted]
	)

	return { checkForPermissionToAccessDocument, isPathAllowed, isAnyPathFromGroupAllowed }
}

export default useCheckPermissions
