import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { InputHint } from 'components/shared'
import { useFieldsPermissions } from 'hooks'
import { IProformaInvoice } from 'models'

interface Props {
	formData: IProformaInvoice
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const Notes = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	return (
		<div className="w-full pb-4">
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* SettlementCommentsFromContractor */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('SettlementCommentsFromContractor') && (
						<TextField
							disabled={true}
							value={formData.SettlementCommentsFromContractor || ''}
							name="SettlementCommentsFromContractor"
							label={t('ProformaInvoice.Fields.SettlementCommentsFromContractor')}
							error={fieldErrorsList.includes('SettlementCommentsFromContractor')}
							onChange={onInputChange}
							multiline
							minRows={3}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProformaInvoice.SettlementCommentsFromContractor" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
				{/* SettlementCommentsFromContract */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('SettlementCommentsFromContract') && (
						<TextField
							disabled={true}
							value={formData.SettlementCommentsFromContract || ''}
							name="SettlementCommentsFromContract"
							label={t('ProformaInvoice.Fields.SettlementCommentsFromContract')}
							error={fieldErrorsList.includes('SettlementCommentsFromContract')}
							onChange={onInputChange}
							multiline
							minRows={3}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProformaInvoice.SettlementCommentsFromContract" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
				{/* Comments */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('Comments') && (
						<TextField
							disabled={!isEditable('Comments')}
							value={formData.Comments || ''}
							name="Comments"
							label={t('ProformaInvoice.Fields.Comments')}
							error={fieldErrorsList.includes('Comments')}
							onChange={onInputChange}
							multiline
							minRows={3}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ProformaInvoice.Comments" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Notes
