export const getStatusColor = (status: string) => {
	switch (status) {
		case 'Warning':
			return 'warning.main'
		case 'Succes':
			return 'success.main'
		case 'Error':
			return 'error.main'
		case 'Waiting':
			return 'info.dark'
		case 'Canceled':
			return 'error.light'
		default:
			return 'text.secondary'
	}
}

export const sxStyles = {
	cell: {
		color: 'text.secondary',
		p: '7px 16px',
		border: 0,
	},
	headerCellLeft: {
		borderRadius: '5px 0 0 0',
	},
	headerCellRight: {
		borderRadius: '0 5px 0 0',
	},
}

export const sxSyncStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	content: { fontSize: '16px', textAlign: 'center' },
	itemName: { fontWeight: 500, padding: '0 5px' },
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
