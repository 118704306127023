import { ElementType, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton, Tooltip } from '@mui/material'

import clsx from 'clsx'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

type Props = {
	title: string
	onClick: MouseEventHandler<HTMLButtonElement>
	IconComponent: ElementType
	disabled?: boolean
	className?: string
	fontSize?: 'small' | 'inherit' | 'medium' | 'large'
	translationFile?: string
	visible?: 'visible' | 'hidden'
	color?: string
}

const TooltipButton: React.FC<Props> = ({
	title,
	onClick,
	IconComponent,
	disabled,
	className,
	fontSize = 'small',
	translationFile = 'translation',
	visible = 'visible',
	color = 'primary',
}) => {
	const { t } = useTranslation()

	return (
		<Tooltip title={<TooltipTextWrapper title={t(title, { ns: translationFile })} />} placement="top" arrow>
			<span>
				<IconButton
					className={clsx('p-1', className)}
					sx={{ p: '4px' }}
					disabled={disabled}
					onClick={onClick}
					style={{ visibility: visible }}>
					<IconComponent fontSize={fontSize} color={disabled ? 'disabled' : color} />
				</IconButton>
			</span>
		</Tooltip>
	)
}

export default TooltipButton
