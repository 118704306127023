import type { ITab } from 'models/ITab'
import { setBrowserTabTitle } from 'utils'

/**
 * This module is responsible for managing tabs and current selected tab and connect it to data source (Local Storage)
 */

let selectedIndex: number = 0
let tabs: ITab[] = []

function selectOrAddTab(tab: ITab) {
	setBrowserTabTitle(tab.name ? `AlfaByte - ${tab.name}` : 'AlfaByte')
	selectTabCore(tab, false)
}

function updateOrAddTab(tab: ITab) {
	selectTabCore(tab, true)
}

function removeTab(id: string) {
	const index = findIndex(id)

	if (tabs.length === 1) {
		tabs = []
		selectedIndex = -1 // Reset selectedIndex when there are no tabs
	} else {
		tabs.splice(index, 1)

		if (selectedIndex === index) {
			// If the removed tab was active, set the next tab as active
			selectedIndex = Math.min(index, tabs.length - 1)
		} else if (selectedIndex > index) {
			// Adjust selectedIndex if the removed tab was before the current selectedIndex
			selectedIndex -= 1
		}
	}

	saveTabs()
	saveSelectedIndex()
}

function reorderTab(fromIndex: number, toIndex: number) {
	const changeToFromIndex = selectedIndex === fromIndex
	const incrementIndex = toIndex <= selectedIndex && selectedIndex < fromIndex
	const decrementIndex = fromIndex < selectedIndex && selectedIndex <= toIndex

	tabs.splice(toIndex, 0, tabs.splice(fromIndex, 1)[0])

	if (changeToFromIndex === true) selectedIndex = toIndex
	else if (incrementIndex === true) selectedIndex += 1
	else if (decrementIndex === true) selectedIndex -= 1

	saveTabs()
	saveSelectedIndex()
}

function getTabs(): ITab[] {
	return tabs
}

function getSelectedTab(): ITab | null {
	if (tabs.length === 0) return null
	return { ...tabs[selectedIndex], index: selectedIndex }
}

function selectTabCore(tab: ITab, replaceExisting: boolean): void {
	const matchedTabs = tabs.filter(x => x.key === tab.key || x.url === tab.url)

	if (matchedTabs.length === 1) {
		selectedIndex = tabs.indexOf(matchedTabs[0])
		if (replaceExisting) tabs[selectedIndex] = tab
	} else if (matchedTabs.length > 1) {
		throw new Error(`There was more than two matches for tab ${JSON.stringify(tab)}`)
	} else {
		selectedIndex = tabs.push(tab) - 1
	}

	saveTabs()
	saveSelectedIndex()
}

function loadData() {
	const tabsSerialized = window.localStorage.getItem('tabs')
	const tabIndexSerialized = window.localStorage.getItem('selectedTabIndex')

	tabs = tabsSerialized ? JSON.parse(tabsSerialized) : []
	selectedIndex = tabIndexSerialized ? JSON.parse(tabIndexSerialized) : 0
}

function saveTabs() {
	window.localStorage.setItem('tabs', JSON.stringify(tabs))
}

function saveSelectedIndex() {
	window.localStorage.setItem('selectedTabIndex', JSON.stringify(selectedIndex))
}

function findIndex(id: string) {
	const tab = tabs.find(x => x.key === id)
	if (tab == null) throw new Error(`There is no tab with key ${id}`)
	return tabs.indexOf(tab)
}

loadData()
export const TabManager = {
	selectOrAddTab,
	updateOrAddTab,
	removeTab,
	reorderTab,
	getTabs,
	getSelectedTab,
}
