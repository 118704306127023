import Grid from '@mui/material/Unstable_Grid2'

import ButtonSkeleton from './ButtonSkeleton'
import InputSkeleton from './InputSkeleton'

type Props = {
	firstRowInputs?: number
	contractor?: boolean
	tabs?: number
}

const FormSkeletonLoading = ({ firstRowInputs = 11, contractor = true, tabs = 4 }: Props) => {
	return (
		<>
			<div className="w-full flex justify-end items-center p-4 pb-0">
				<ButtonSkeleton className="w-8 h-8 mr-3 rounded-full" />
				<ButtonSkeleton className="w-20" />
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<div className="w-full pb-4">
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						{Array.from({ length: firstRowInputs }).map((el, index) => (
							<Grid key={index} xs={12} sm={6} md={3} lg={3}>
								<InputSkeleton />
							</Grid>
						))}
					</Grid>
				</div>
				{contractor ? (
					<div className="w-full pb-4">
						<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
							{contractorSkeletonData.map((el, index) => {
								return (
									<Grid key={index} xs={el.xs} sm={el.sm} md={el.md} lg={el.lg}>
										{!el.empty ? <InputSkeleton /> : null}
									</Grid>
								)
							})}
						</Grid>
					</div>
				) : null}
				<div className="w-full mt-4">
					<div className="flex">
						{Array.from({ length: tabs }).map((el, index) => (
							<ButtonSkeleton key={index} className="mr-4" />
						))}
					</div>
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
						{tabsSkeletonData.map((el, index) => {
							return (
								<Grid key={index} xs={el.xs} sm={el.sm} md={el.md} lg={el.lg}>
									<InputSkeleton />
								</Grid>
							)
						})}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<InputSkeleton className="h-32" />
						</Grid>
					</Grid>
				</div>
			</div>
		</>
	)
}

export default FormSkeletonLoading

const contractorSkeletonData = [
	{ xs: 12, sm: 12, md: 6, lg: 6 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3, empty: true },
	{ xs: 12, sm: 3, md: 1.5, lg: 1.5 },
	{ xs: 12, sm: 3, md: 1.5, lg: 1.5 },
	{ xs: 12, sm: 3, md: 1.5, lg: 1.5 },
	{ xs: 12, sm: 3, md: 1.5, lg: 1.5, empty: true },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
]

const tabsSkeletonData = [
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
	{ xs: 12, sm: 6, md: 3, lg: 3 },
]
