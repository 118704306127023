import { useAppSelector } from 'features'

const useFieldsPermissions = () => {
	const { editableProperties, visibleProperties } = useAppSelector(state => state.documentData)

	const isVisible = (property: string) => visibleProperties.find(el => el === property) != null
	const isEditable = (property: string) => editableProperties.find(el => el === property) != null

	return { isVisible, isEditable }
}

export default useFieldsPermissions
