import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useFieldsPermissions } from 'hooks'
import { IContract } from 'models'
import { InputHint } from 'components/shared'

interface Props {
	formData: IContract
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const Details = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('contract')

	return (
		<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
			{/* DetailedContractDescription */}
			<Grid xs={12} sm={12} md={12} lg={12}>
				{isVisible('DetailedContractDescription') && (
					<TextField
						disabled={!isEditable('DetailedContractDescription')}
						value={formData.DetailedContractDescription || ''}
						id="contract-field-DetailedContractDescription"
						name="DetailedContractDescription"
						label={t('Fields.DetailedContractDescription')}
						error={fieldErrorsList.includes('DetailedContractDescription')}
						onChange={onInputChange}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Contract.DetailedContractDescription" />
								</InputAdornment>
							),
						}}
						multiline
						minRows={7}
						fullWidth
					/>
				)}
			</Grid>
		</Grid>
	)
}

export default Details
