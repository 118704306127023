import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridRowModes, GridRowModesModel, GridRowsProp, GridToolbarContainer } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'
import { v4 as uuidv4 } from 'uuid'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	setIsActionButtonDisabled: (value: boolean) => void
	isActionButtonDisabled: boolean
	setData: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
	setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

const PrintTemplatesToolbar = ({
	apiRef,
	setIsActionButtonDisabled,
	isActionButtonDisabled,
	setData,
	setRowModesModel,
}: Props) => {
	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleAddTemplate = () => {
		const Id = uuidv4()

		setIsActionButtonDisabled(true)

		setData((oldRows: any) => [
			...oldRows,
			{
				Id,
				Name: '',
				isNew: true,
			},
		])

		setRowModesModel(oldModel => ({
			...oldModel,
			[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Name' },
		}))
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer className="justify-end m-1">
			<TooltipButton
				title="general.ResetDataGridState"
				onClick={() => handleUpdateState(true)}
				IconComponent={RotateLeftIcon}
				fontSize="medium"
			/>
			<TooltipButton
				title="general.SaveDataGridState"
				onClick={() => handleUpdateState()}
				IconComponent={FactCheckOutlinedIcon}
				fontSize="medium"
			/>
			<TooltipButton title="general.Filter" onClick={handleFilter} IconComponent={FilterListIcon} fontSize="medium" />
			<TooltipButton
				title="general.Add"
				onClick={handleAddTemplate}
				IconComponent={AddIcon}
				disabled={isActionButtonDisabled}
				fontSize="medium"
			/>
		</GridToolbarContainer>
	)
}

export default PrintTemplatesToolbar
