import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

import { TooltipButton } from 'components/shared'
import { IScheduleEntryPeriod } from 'models'
import { useScheduleService } from 'services'
import { formatDateTimeMUIGrid } from 'utils/formatDateTime'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import { EditPeriodDialog } from './edit-period-dialog'

type Props = {
	periods: IScheduleEntryPeriod[]
	period: IScheduleEntryPeriod
	documentType: string
	documentId: string
	refreshData: () => void
	readOnly: boolean
}

const SinglePeriod = ({ periods, period, documentType, documentId, refreshData, readOnly }: Props) => {
	const [isEditPeriodDialogOpen, setIsEditPeriodDialogOpen] = useState(false)

	const { t } = useTranslation()

	const { deleteEntryPeriod, showSuccessInfo } = useScheduleService()

	const handleDeletePeriod = async () => {
		try {
			await deleteEntryPeriod(documentType, documentId, period.Id)

			refreshData()
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<>
			<tr>
				<td className="w-[230px] p-1 border border-dashed dark:border-border-darkDivider border-border-divider">
					{formatDateTimeMUIGrid(period?.PeriodFrom) || ''}
				</td>
				<td className="w-[230px] p-1 border border-dashed dark:border-border-darkDivider border-border-divider">
					{formatDateTimeMUIGrid(period?.PeriodTo) || ''}
				</td>
				<td className="w-[150px] p-1 border border-dashed dark:border-border-darkDivider border-border-divider">
					{formatNumberDataGrid(period?.PeriodValue) || ''}
				</td>
				<td className="p-1 border border-dashed dark:border-border-darkDivider border-border-divider">
					<div className="flex justify-center">
						<TooltipButton
							disabled={readOnly}
							title={t('general.Edit')}
							onClick={() => setIsEditPeriodDialogOpen(true)}
							IconComponent={EditIcon}
						/>
						<TooltipButton
							disabled={readOnly}
							title={t('general.Remove')}
							onClick={handleDeletePeriod}
							IconComponent={DeleteIcon}
						/>
					</div>
				</td>
			</tr>
			{isEditPeriodDialogOpen && (
				<EditPeriodDialog
					isOpen={isEditPeriodDialogOpen}
					setIsOpen={setIsEditPeriodDialogOpen}
					periods={periods}
					period={period}
					documentId={documentId}
					documentType={documentType}
					refreshData={refreshData}
					actionType="editing"
				/>
			)}
		</>
	)
}

export default SinglePeriod
