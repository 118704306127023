import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'

import { useDocumentActionsStateActions } from 'features'
import { Action } from 'models'

type Props = {
	isOpen: boolean
	action: Action
	invokeAction: (value: Action) => void
}
const DeleteDocumentDialog = ({ isOpen, action, invokeAction }: Props) => {
	const { setIsConfirmDeletingDialogOpen } = useDocumentActionsStateActions()

	const { t } = useTranslation()

	const handleConfirmDelete = () => {
		invokeAction(action)
		closeDialog()
	}

	const closeDialog = () => {
		setIsConfirmDeletingDialogOpen(false)
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Actions.ConfirmDeleteDocumentDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography sx={sxStyles.content}>{t('Actions.ConfirmDeleteDocumentDialog.Content')}</Typography>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.No')}
				</Button>
				<Button variant="contained" onClick={handleConfirmDelete} color="success">
					{t('general.Yes')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DeleteDocumentDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	content: { fontSize: '16px', margin: '10px', textAlign: 'center' },
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
