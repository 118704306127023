import { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import SaveIcon from '@mui/icons-material/Save'
import SettingsIcon from '@mui/icons-material/Settings'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { CircularProgress } from '@mui/material'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'
import { IAttachmentPreviewFile } from 'models'
import { useAttachmentsService } from 'services'

interface Props {
	file: IAttachmentPreviewFile
	handleZoomOut: () => void
	handleZoomIn: () => void
}

const ViewerTools = ({ file, handleZoomOut, handleZoomIn }: Props) => {
	const [filePreparingForDownload, setFilePreparingForDownload] = useState(false)

	const { t } = useTranslation('attachments')

	const { downloadFile } = useAttachmentsService()

	const { attachmentsPreviewSide } = useContext(AttachmentsPreviewContext)

	const downloadAttachment = useCallback(async () => {
		try {
			setFilePreparingForDownload(true)
			await downloadFile(file.fileName, file.id)
		} catch (err) {
			console.error(err)
		}
		setFilePreparingForDownload(false)
	}, [file])

	const actions = [
		{ icon: <ZoomOutIcon />, name: t('ZoomOut'), func: handleZoomOut },
		{ icon: <ZoomInIcon />, name: t('ZoomIn'), func: handleZoomIn },
		{
			icon: filePreparingForDownload ? <CircularProgress size={25} sx={{ padding: '5px' }} /> : <SaveIcon />,
			name: t('DownloadAttachment'),
			func: downloadAttachment,
		},
	]

	return (
		<SpeedDial
			ariaLabel="attachments preview settings button"
			sx={{
				position: 'absolute',
				bottom: 50,
				...(attachmentsPreviewSide === 'right' ? sxStyles.rightSide : sxStyles.leftSide),
			}}
			icon={<SettingsIcon />}>
			{actions.map(action => (
				<SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} onClick={action.func} />
			))}
		</SpeedDial>
	)
}

export default ViewerTools

const sxStyles = {
	leftSide: { left: 40 },
	rightSide: { right: 40 },
}
