import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { ICostInvoiceSettlement } from 'models'

const useCostInvoiceSettlementService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/CostInvoiceSettlement'

	const getSettlements = useCallback(
		async (invoiceId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&invoiceId=${invoiceId}`)
			return response
		},
		[request]
	)

	const addSettlement = useCallback(
		async (data: ICostInvoiceSettlement) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateSettlement = useCallback(
		async (settlementId: string, data: any) => {
			await request(`${path}/${settlementId}`, 'PATCH', data)
		},
		[request]
	)

	const deleteSettlement = useCallback(
		async (settlementId: string) => {
			const response = await request(`${path}/${settlementId}`, 'DELETE')
			return response
		},
		[request]
	)

	const linkToBudgetSettlement = useCallback(
		async (settlementId: string, budgetEntryNumber: string) => {
			await request(`${path}/linkToBudget/${settlementId}?budgetEntryNumber=${budgetEntryNumber}`, 'PATCH')
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getSettlements,
		addSettlement,
		updateSettlement,
		deleteSettlement,
		linkToBudgetSettlement,
	}
}

export default useCostInvoiceSettlementService
