import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IContractAdditionalCosts } from 'models'

const useContractAdditionalCostsService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const getContractAdditionalCosts = useCallback(
		async (contractId: string) => {
			const response = await request(`/api/contractAdditionalCost/${contractId}`)
			return response
		},
		[request]
	)

	const addContractAdditionalCostsItem = useCallback(
		async (data: IContractAdditionalCosts) => {
			const response = await request(`/api/contractAdditionalCost`, 'POST', data)
			return response
		},
		[request]
	)

	const linkToBudgetEntry = useCallback(
		async (entryId: string, budgetEntryId: string) => {
			await request(`/api/contractAdditionalCost/linkToBudget/${entryId}?budgetEntryId=${budgetEntryId}`, 'PATCH')
		},
		[request]
	)

	const updateContractAdditionalCostsItem = useCallback(
		async (itemId: string, body: IContractAdditionalCosts) => {
			await request(`/api/contractAdditionalCost/${itemId}`, 'PATCH', body)
		},
		[request]
	)

	const deleteContractAdditionalCostsItem = useCallback(
		async (itemId: string) => {
			const response = await request(`/api/contractAdditionalCost/${itemId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		error,
		clearError,
		showSuccessInfo,
		getContractAdditionalCosts,
		addContractAdditionalCostsItem,
		updateContractAdditionalCostsItem,
		deleteContractAdditionalCostsItem,
		linkToBudgetEntry,
	}
}

export default useContractAdditionalCostsService
