import { ChangeEventHandler, SyntheticEvent } from 'react'

import { AutocompleteInputOption } from 'components/shared'
import { useAppSelector } from 'features'
import { useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features/actions'
import { useHttp } from 'hooks/http.hook'

export const useFormDataChange = () => {
	const { documentData } = useAppSelector(state => state.documentData)
	const { isSaveButtonDisabled } = useAppSelector(state => state.documentFormSaveButton)

	const { clearError, fieldsError } = useHttp()
	const { updateDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()

	const handleSaveButtonStateChange = (fieldName: string, value: any) => {
		if (value && (documentData as any)[fieldName] != value && isSaveButtonDisabled) {
			setIsSaveButtonDisabled(false)
		}
	}

	const handleErrorStateChange = (fieldName: string) => {
		if (fieldsError.includes(fieldName)) {
			clearError(fieldName)
		}
	}

	const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
		const target = e.target as HTMLInputElement

		const value = target.value
		const fieldName = target.name

		updateDocumentData([{ fieldName, value }])

		handleSaveButtonStateChange(fieldName, value)
		handleErrorStateChange(fieldName)
	}

	const handleCheckboxChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement

		const value = target.checked
		const fieldName = target.name

		if (fieldsError.includes(fieldName)) {
			clearError(fieldName)
		}
		updateDocumentData([{ fieldName, value }])

		if ((documentData as any)[fieldName] != value && isSaveButtonDisabled) {
			setIsSaveButtonDisabled(false)
		}
	}

	const handleAutocompleteInputChange = (
		newValue: AutocompleteInputOption,
		valueFieldName: string,
		labelFieldName: string
	) => {
		updateDocumentData([
			{ fieldName: valueFieldName, value: newValue.value },
			{ fieldName: labelFieldName, value: newValue.label },
		])

		handleSaveButtonStateChange(valueFieldName, newValue.value)
	}

	return { handleInputChange, handleCheckboxChange, handleAutocompleteInputChange, fieldsError }
}
