import React, { useMemo } from 'react'

import { Tooltip } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

import clsx from 'clsx'
import { useCheckPermissions, useHandleNavigate } from 'hooks'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'
import prepareTitleString from 'utils/prepareTitleString'

type Props = {
	path: string
	id: string
	documentLabel: string
	celllabel: string
	state?: any
	params?: GridRenderCellParams<any>
	labelMaxLength?: number
}

const LinkCellRender = React.memo(
	({ path, id, documentLabel, celllabel, state = {}, params, labelMaxLength = 35 }: Props) => {
		const { checkForPermissionToAccessDocument } = useCheckPermissions()
		const { handleNavigateWithState } = useHandleNavigate()

		const disabled = useMemo(
			() => !checkForPermissionToAccessDocument(path, id),
			[checkForPermissionToAccessDocument, path, id]
		)

		const handleLinkClick = (e: React.MouseEvent) => {
			e.stopPropagation()
			if (disabled) return

			handleNavigateWithState(path, id, documentLabel, state)
		}

		return (
			<Tooltip
				disableHoverListener={celllabel?.length < labelMaxLength}
				title={<TooltipTextWrapper title={celllabel} />}
				placement="top"
				arrow>
				<span
					className={clsx('cursor-pointer text-primary-main hover:underline', {
						'cursor-default text-text-primary dark:text-text-darkPrimary no-underline': disabled,
					})}
					onClick={handleLinkClick}>
					{prepareTitleString(celllabel, labelMaxLength)}
				</span>
			</Tooltip>
		)
	}
)

export default LinkCellRender
