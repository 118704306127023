import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { AppBar, Box, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import Loading from 'components/shared/loading/Loading'
import { useHandleNavigate, useTabs } from 'hooks'
import { IContractorModel, IInquiriesContractor } from 'models'
import { useContractorsService } from 'services'

import ContractorForm from './ContractorForm'
import { useCheckContractor } from './hooks'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	getContractorsList: () => void
	quotationRequestContractorData?: IInquiriesContractor
	contractorsList: IContractorModel[]
}

const AddContractorDialog = ({
	isOpen,
	setIsOpen,
	getContractorsList,
	quotationRequestContractorData,
	contractorsList,
}: Props) => {
	const [formData, setFormData] = useState<IContractorModel>({})
	const [buttonLoading, setButtonLoading] = useState(false)
	const [contractorsContactInfo, setContractorsContactInfo] = useState<any[]>([])
	const [enableAdditionalFields, setEnableAdditionalFields] = useState(false)
	const [loading, setLoading] = useState(false)

	const { closeTab, selectedTab } = useTabs()
	const { handleNavigate } = useHandleNavigate()

	const { t } = useTranslation(['dictionaries', 'translation'])

	const { checkIfContractorAlreadyExists } = useCheckContractor()

	const {
		getContractorContactInfo,
		updateContractor,
		deleteContractor,
		showSuccessInfo,
		addContractor,
		getContractor,
		importGus,
	} = useContractorsService()

	const handleInputChange = (e: any) => {
		setFormData(prev => {
			return { ...prev, [e.target.name]: e.target.value }
		})
	}

	const handleClose = () => {
		setIsOpen(false)
		setEnableAdditionalFields(false)
		setFormData({})
	}

	const handleCloseWithQuotationContractor = () => {
		setIsOpen(false)
		setEnableAdditionalFields(false)
		setFormData({})
		closeTab(selectedTab?.key as string)
		handleNavigate(quotationRequestContractorData, '/offer', 'OfferNumber', 20, 'OfferId')
	}

	const handleImportDataFromGus = async (searchBy: string) => {
		const isNewContractor = !checkIfContractorAlreadyExists(contractorsList, formData.NIP as string)

		if (!isNewContractor) {
			return
		}

		let newContractorId
		if (formData) {
			try {
				setLoading(true)
				const response = await addContractor(
					formData,
					quotationRequestContractorData && quotationRequestContractorData.Id
				)
				newContractorId = response.Id
				await importGus(newContractorId, searchBy)
				const updatedContractor = await getContractor(newContractorId)
				setFormData(updatedContractor)
				setEnableAdditionalFields(true)
				getContractorsList()
			} catch (err) {
				console.error(err)
				await deleteContractor(newContractorId)
			}
			setLoading(false)
		}
	}

	const handleAddContractor = async () => {
		const isNewContractor = !checkIfContractorAlreadyExists(contractorsList, formData.NIP as string)

		if (!isNewContractor) {
			return
		}

		if (formData && !enableAdditionalFields) {
			try {
				setButtonLoading(true)
				const response = await addContractor(
					formData,
					quotationRequestContractorData && quotationRequestContractorData.Id
				)
				setFormData(response)
				setEnableAdditionalFields(true)
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
		} else if (formData) {
			try {
				setButtonLoading(true)
				await updateContractor(formData.Id as string, formData)
				getContractorsList()
				showSuccessInfo('saved')
				quotationRequestContractorData ? handleCloseWithQuotationContractor() : handleClose()
			} catch (err) {
				console.error(err)
			}
		}
		setButtonLoading(false)
	}

	const getContractorsContactInfo = useCallback(async () => {
		try {
			const response = await getContractorContactInfo(formData.Id as string)
			setContractorsContactInfo(response)
		} catch (err) {
			console.error(err)
		}
	}, [formData.Id, getContractorContactInfo])

	useEffect(() => {
		if (quotationRequestContractorData) {
			setFormData({
				Name: quotationRequestContractorData.Name,
				NIP: quotationRequestContractorData.NIP,
				EMail: quotationRequestContractorData.Email,
				PhoneNumber: quotationRequestContractorData.PhoneNumber,
			})
		}
	}, [quotationRequestContractorData])

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1, fontSize: '20px', fontWeight: '500' }} component="div">
						{enableAdditionalFields ? t('Contractor.AddDialog.AdditionalTitle') : t('Contractor.AddDialog.Title')}
					</Typography>
					<LoadingButton
						sx={{ mr: '25px' }}
						variant="contained"
						color="success"
						disabled={loading}
						onClick={handleAddContractor}
						loading={buttonLoading}>
						{enableAdditionalFields
							? t('general.Save', { ns: 'translation' })
							: t('general.Add', { ns: 'translation' })}
					</LoadingButton>
					<IconButton
						edge="start"
						color="inherit"
						onClick={quotationRequestContractorData ? handleCloseWithQuotationContractor : handleClose}
						aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '80px',
				}}>
				{loading ? (
					<Box
						sx={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<span>
							<Loading />
						</span>
						<Typography sx={{ mt: '15px' }}>{t('general.ImportRunning', { ns: 'translation' })}</Typography>
					</Box>
				) : (
					<ContractorForm
						formData={formData}
						handleInputChange={handleInputChange}
						isForEditDialog={enableAdditionalFields}
						contractorsContactInfo={contractorsContactInfo}
						getContractorsContactInfo={getContractorsContactInfo}
						setFormData={setFormData}
						handleImportDataFromGus={handleImportDataFromGus}
					/>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default AddContractorDialog
