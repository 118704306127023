import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import { DataGridPremium, GridRenderCellParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-premium'

import { LinkCellRender, TooltipButton } from 'components/shared'
import NoData from 'components/shared/no-data-message/NoData'
import { PATHS } from 'data'
import { useDataGridState, useHandleRowClick } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IMaterialInvoiceGRNList } from 'models'
import { useMaterialInvoiceGRNListService, useMaterialInvoiceService } from 'services'

import GRNListToolbar from './GRNListToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	invoiceId: string
	readOnly: boolean
}

const GRNList = ({ invoiceId, readOnly }: Props) => {
	const [data, setData] = useState<IMaterialInvoiceGRNList[]>([])

	const [dataLoading, setDataLoading] = useState(true)

	const { t } = useTranslation(['invoice', 'translation'])

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()
	const { dataGridLanguage } = useLanguage()
	const { getGRNList, showSuccessInfo } = useMaterialInvoiceGRNListService()
	const { invokeAction } = useMaterialInvoiceService()

	const apiRef = useGridApiRef()

	const { handleRowClick } = useHandleRowClick()

	const handleDeleteClick = (params: GridRowParams) => async () => {
		try {
			await invokeAction({
				DocumentId: invoiceId,
				Action: 'RemoveGRNFromMaterialInvoice',
				Parameters: {
					materialInvoiceGoodsReceivedNoteId: params.id as string,
				},
			})
			showSuccessInfo('deleted')
			getData()
		} catch (err) {
			console.error(err)
		}
	}

	const columns: any = [
		{
			field: 'GoodsReceivedNoteIdLabel',
			headerName: t('MaterialInvoice.GRNList.GoodsReceivedNoteId_Label'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => (
				<LinkCellRender
					path={PATHS.goodsReceivedNote}
					id={params.row.GoodsReceivedNoteId}
					documentLabel={params.value}
					celllabel={params.row.GoodsReceivedNoteIdLabel}
				/>
			),
		},
		{
			field: 'OrderIdLabel',
			headerName: t('MaterialInvoice.GRNList.OrderId_Label'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => (
				<LinkCellRender
					path={PATHS.order}
					id={params.row.OrderId}
					documentLabel={params.value}
					celllabel={params.row.OrderIdLabel}
				/>
			),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			width: 70,
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				return [
					<TooltipButton
						title="general.Remove"
						onClick={handleDeleteClick(params)}
						IconComponent={DeleteIcon}
						disabled={readOnly}
					/>,
				]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = useCallback(async () => {
		try {
			const response = await getGRNList(invoiceId)
			setData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getGRNList, invoiceId])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		getData()
	}, [invoiceId])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState, apiRef])

	return (
		<DataGridPremium
			apiRef={apiRef}
			columns={orderedColumns}
			rows={data}
			getRowId={row => row.Id}
			onRowClick={params =>
				handleRowClick(params, '/goodsReceivedNote', 'GoodsReceivedNoteId_Label', 20, 'GoodsReceivedNoteId')
			}
			loading={dataLoading || dataGridStateLoading}
			sx={{ height: 'calc(100vh - 150px)' }}
			components={{
				Toolbar: GRNListToolbar,
				NoRowsOverlay: () => <NoData />,
			}}
			componentsProps={{
				toolbar: {
					getData,
					setDataLoading,
					apiRef,
				},
			}}
			initialState={INITIALSTATE}
			rowHeight={35}
			localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
		/>
	)
}

export default GRNList
