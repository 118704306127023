import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IIndividualEmailConfig, IUpdateMySettingsRequest, IUser } from 'models'

const useUsersService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/User'

	const getUsers = useCallback(async () => {
		const response = await request(path)
		return response
	}, [request])

	const getUser = useCallback(
		async (userId: string) => {
			const response = await request(`${path}/${userId}`)
			return response
		},
		[request]
	)

	const addUser = useCallback(
		async (data: IUser) => {
			const response = await request(`${path}/register`, 'POST', data)
			return response
		},
		[request]
	)

	const updateUser = useCallback(
		async (userId: string, data: IUser) => {
			await request(`/odata/User/${userId}`, 'PATCH', data)
		},
		[request]
	)

	const updateMySettings = useCallback(
		async (data: IUpdateMySettingsRequest) => {
			await request(`${path}/mySettings`, 'PATCH', data)
		},
		[request]
	)

	const deleteUser = useCallback(
		async (userId: string) => {
			await request(`${path}/${userId}`, 'DELETE')
		},
		[request]
	)

	const changeUserPassword = useCallback(
		async (userId: string, password: string) => {
			await request(`${path}/changePassword`, 'POST', {
				Id: userId,
				Password: password,
			})
		},
		[request]
	)

	const getAppState = useCallback(
		async (stateContextName: string) => {
			const response = await request(`${path}/appState?stateContextName=${stateContextName}`)

			return response
		},
		[request]
	)

	const updateAppState = useCallback(
		async (StateContextName: string, StateValue: string) => {
			await request(`${path}/appState?stateContextName`, 'PATCH', {
				StateContextName,
				StateValue,
			})
		},
		[request]
	)

	const getIndividualEmailConfig = useCallback(
		async (userId: string) => {
			const response = await request(`/api/userConfigIndividualEmail/${userId}`)
			return response
		},
		[request]
	)

	const updateIndividualEmailConfig = useCallback(
		async (userId: string, data: IIndividualEmailConfig) => {
			const response = await request(`/api/userConfigIndividualEmail/${userId}`, 'PATCH', data)
			return response
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getUsers,
		getUser,
		addUser,
		updateUser,
		deleteUser,
		changeUserPassword,
		updateMySettings,
		getAppState,
		updateAppState,
		getIndividualEmailConfig,
		updateIndividualEmailConfig,
	}
}

export default useUsersService
