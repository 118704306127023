import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'

import clsx from 'clsx'
import { Loading, NoData } from 'components/shared'
import { IScheduleEntryPeriod, IScheduleRow } from 'models'
import { useScheduleService } from 'services'
import { v4 as uuidv4 } from 'uuid'

import SinglePeriod from './SinglePeriod'
import { EditPeriodDialog } from './edit-period-dialog'

type Props = {
	documentType: string
	documentId: string
	entryData: IScheduleRow
	setIsEditing: (value: boolean) => void
	refreshData: () => void
	sessionStorageKey: string
	readOnly: boolean
}

const EntryPeriods = ({
	documentType,
	documentId,
	entryData,
	setIsEditing,
	refreshData,
	sessionStorageKey,
	readOnly,
}: Props) => {
	const [isEditPeriodDialogOpen, setIsEditPeriodDialogOpen] = useState(false)
	const [newPeriod, setNewPeriod] = useState<IScheduleEntryPeriod>()
	const [periods, setPeriods] = useState<IScheduleEntryPeriod[]>([])
	const [loading, setLoading] = useState(true)

	const { t } = useTranslation()

	const { getEntryPeriods } = useScheduleService()

	const getPeriodsData = async () => {
		setLoading(true)
		try {
			const response = await getEntryPeriods(documentType, documentId, entryData.ScheduleEntryId)
			setPeriods(response)
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	const handleCloseAndRefresh = () => {
		setIsEditing(false)
		sessionStorage.setItem(sessionStorageKey, JSON.stringify(false))
		refreshData()
	}

	const handleAddPeriod = () => {
		const id = uuidv4()

		const newPeriod = {
			Id: id,
			DocumentScheduleEntryId: entryData.ScheduleEntryId,
			PeriodFrom: '',
			PeriodTo: '',
			IsAuto: true,
			PeriodValue: 0,
			isNew: true,
		}

		setNewPeriod(newPeriod)
		setIsEditPeriodDialogOpen(true)
	}

	useEffect(() => {
		getPeriodsData()
	}, [documentType, documentId, entryData.ScheduleEntryId])

	return (
		<div
			className={clsx(
				'flex sticky left-0 z-10 w-[calc(100vw-65px)]',
				'py-2 border-0 border-b border-dashed dark:border-b-border-darkDivider border-b-border-divider'
			)}>
			<div className={clsx('min-h-20 max-w-5xl p-4 w-full')}>
				{loading ? (
					<Loading />
				) : (
					<div className="w-[740px]">
						<div className={clsx('flex items-center justify-between px-1')}>
							<span className="text-lg pl-1">{t('ScheduleTab.Periods')}</span>
							<Button disabled={readOnly} onClick={handleAddPeriod}>
								{t('general.Add')}
								<AddIcon className="ml-1" fontSize="small" />
							</Button>
						</div>
						{!periods.length ? (
							<div>
								<NoData fontSize="16" />
							</div>
						) : (
							<>
								<table className={clsx('w-full my-2 table-auto', 'border-separate border-spacing-1')}>
									<thead className="bg-background-highlighted dark:bg-background-darkSecondary">
										<tr>
											<th className="w-[230px] p-1 border border-dashed dark:border-border-darkDivider border-border-divider">
												{t('ScheduleTab.PeriodFrom')}
											</th>
											<th className="w-[230px] p-1 border border-dashed dark:border-border-darkDivider border-border-divider">
												{t('ScheduleTab.PeriodTo')}
											</th>
											<th className="w-[150px] p-1 border border-dashed dark:border-border-darkDivider border-border-divider">
												{t('ScheduleTab.Value')}
											</th>
											<th className="p-1 border border-dashed dark:border-border-darkDivider border-border-divider"></th>
										</tr>
									</thead>
									<tbody>
										{periods.map(period => (
											<SinglePeriod
												key={period.Id}
												refreshData={getPeriodsData}
												periods={periods}
												period={period}
												documentType={documentType}
												documentId={documentId}
												readOnly={readOnly}
											/>
										))}
									</tbody>
								</table>
								<div className="flex justify-end">
									<Button disabled={readOnly} className="mr-1" variant="outlined" onClick={handleCloseAndRefresh}>
										{t('ScheduleTab.CloseAndRefresh')}
									</Button>
								</div>
							</>
						)}
					</div>
				)}
			</div>
			{isEditPeriodDialogOpen && (
				<EditPeriodDialog
					isOpen={isEditPeriodDialogOpen}
					setIsOpen={setIsEditPeriodDialogOpen}
					periods={periods}
					period={newPeriod}
					documentId={documentId}
					documentType={documentType}
					refreshData={getPeriodsData}
					actionType="adding"
				/>
			)}
		</div>
	)
}

export default EntryPeriods
