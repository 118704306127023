import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText } from '@mui/material'

import NoData from 'components/shared/no-data-message/NoData'
import { IContactPerson } from 'models'

type Props = {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	contactsList: IContactPerson[]
	refreshData: () => void
	addContactFunction: (contextId: string, personId: string) => void
	showSuccessInfo: (value: string) => void
	contextId: string
}

const AddUserContactDialog = ({
	isOpen,
	setIsOpen,
	contactsList,
	refreshData,
	addContactFunction,
	showSuccessInfo,
	contextId,
}: Props) => {
	const { t } = useTranslation()

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleAddContact = useCallback(
		async (contactId: string) => {
			try {
				await addContactFunction(contextId, contactId)
				refreshData()
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
		},
		[addContactFunction, contextId]
	)

	return (
		<Dialog open={isOpen} fullWidth maxWidth={false}>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('general.AddContactInfoPerson')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{contactsList.length ? (
					<List>
						{contactsList.map((el: any, index: number) => {
							return (
								<ListItem
									sx={{
										borderBottom: '1px solid',
										borderColor: 'action.disabled',
										'&:last-child': {
											border: 'none',
										},
									}}
									key={index}
									secondaryAction={
										<IconButton edge="end" onClick={() => handleAddContact(el.Id)}>
											<AddIcon sx={{ fontSize: '20px' }} color="primary" />
										</IconButton>
									}>
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.FirstName} />
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.LastName || ''}`} />
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.EMail || ''}`} />
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.Telephone || ''}`} />
								</ListItem>
							)
						})}
					</List>
				) : (
					<NoData fontSize="18px" />
				)}
			</DialogContent>
		</Dialog>
	)
}

export default AddUserContactDialog
