import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import { INumerator } from 'models'
import { useNumeratorService } from 'services'

import Tags from './Tags'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	numeratorId: string
	refreshNumeratorList: () => void
}

const EditNumeratorDialog = ({ isOpen, setIsOpen, numeratorId, refreshNumeratorList }: Props) => {
	const [numeratorData, setNumeratorData] = useState<INumerator>()
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
	const [dataLoading, setDataLoading] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { getNumerator, updateNumerator, fieldsError, showSuccessInfo, clearError } = useNumeratorService()

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleDataChange = useCallback(
		(e: any) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const value = e.target.value
			const fieldName = e.target.name

			if (fieldsError.includes(fieldName)) {
				clearError(fieldName)
			}
			setNumeratorData((prev: any) => {
				return { ...prev, [fieldName]: value }
			})
		},
		[fieldsError, clearError, isSaveButtonDisabled]
	)

	const handleDataSave = useCallback(async () => {
		try {
			setButtonLoading(true)
			await updateNumerator(numeratorId, numeratorData as INumerator)

			refreshNumeratorList()
			closeDialog()
			showSuccessInfo('saved')
			setIsSaveButtonDisabled(true)
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}, [updateNumerator, numeratorData])

	const getData = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getNumerator(numeratorId)

			setNumeratorData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getNumerator, numeratorId])

	useEffect(() => {
		getData()
	}, [numeratorId])

	return (
		<Dialog open={isOpen} fullWidth>
			{dataLoading ? (
				<Loading />
			) : (
				<>
					<DialogTitle sx={sxStyles.title} component="div">
						{t('Workflow.EditNumeratorDialog.Title')}
						<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<TextField
							sx={{ mt: '10px' }}
							disabled={false}
							value={numeratorData?.Name}
							name="Name"
							label={t('Workflow.Fields.NumeratorForm.Name')}
							error={fieldsError.includes('Name')}
							onChange={handleDataChange}
							fullWidth
						/>
						<TextField
							sx={{ mt: '20px' }}
							disabled={false}
							value={numeratorData?.Format}
							name="Format"
							label={t('Workflow.Fields.NumeratorForm.Format')}
							error={fieldsError.includes('Format')}
							onChange={handleDataChange}
							fullWidth
						/>
						<Tags />
					</DialogContent>
					<DialogActions sx={sxStyles.actionsContainer}>
						<LoadingButton
							variant="contained"
							color="success"
							disabled={isSaveButtonDisabled}
							loading={buttonLoading}
							onClick={handleDataSave}>
							{t('general.Save', { ns: 'translation' })}
						</LoadingButton>
					</DialogActions>
				</>
			)}
		</Dialog>
	)
}

export default EditNumeratorDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
