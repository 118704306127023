import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DefaultAttachmentPreviewButton, DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { useFormDataChange } from 'hooks'
import { IOffer } from 'models'
import { useOfferService } from 'services'

import OfferForm from './OfferForm'

interface Props {
	offerId: string
}

const OfferBasicInfo = ({ offerId }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { handleInputChange, fieldsError } = useFormDataChange()
	const { getOffer, updateOffer } = useOfferService()

	const { t } = useTranslation()

	const refreshDocumentData = async () => {
		try {
			const response = await getOffer(offerId)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [offerId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={8} tabs={2} />
	}

	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
					/>
				</div>
				<div>
					<DefaultAttachmentPreviewButton attachmentId={documentData?.DefaultAttachmentId} />
					<ChangeHistory
						contextId={offerId}
						translationFile="offer"
						title={`${t('Offer', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="Fields"
					/>
					<DocumentFormSaveButton
						documentId={offerId}
						data={documentData}
						updateDataFunction={updateOffer}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<OfferForm
					formData={documentData as IOffer}
					offerId={offerId}
					onInputChange={handleInputChange}
					reloadFormData={refreshDocumentData}
					fieldErrorsList={fieldsError}
				/>
			</div>
		</>
	)
}

export default OfferBasicInfo
