import { configureStore } from '@reduxjs/toolkit'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import {
	autocompleteInputSlice,
	budgetEntrySlice,
	businessDataSlice,
	calendarSlice,
	documentActionsStateSlice,
	documentContractorSlice,
	documentDataSlice,
	documentFormSaveButtonSlice,
	linkDocumentsSlice,
	notificationsSlice,
	previousFilterStateData,
	routingListSlice,
} from './slices'

export const store = configureStore({
	reducer: {
		autocompleteInput: autocompleteInputSlice,
		documentFormSaveButton: documentFormSaveButtonSlice,
		documentData: documentDataSlice,
		documentContractor: documentContractorSlice,
		businessData: businessDataSlice,
		documentActionsState: documentActionsStateSlice,
		linkDocuments: linkDocumentsSlice,
		notifications: notificationsSlice,
		routingList: routingListSlice,
		calendar: calendarSlice,
		budgetEntryData: budgetEntrySlice,
		previousFilterStateData: previousFilterStateData,
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware(),
	devTools: process.env.NODE_ENV !== 'production',
})

export type RootStateType = ReturnType<typeof store.getState>
export type AppDispatchType = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatchType>()
export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector
