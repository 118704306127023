import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import VisibilityIcon from '@mui/icons-material/Visibility'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'
import { getFileExtension, getFileType } from 'components/shared/attachments/utils'
import { useAttachmentsService } from 'services'

import { TooltipButton } from '../tooltip-button'

type Props = {
	attachmentId: string | undefined
}

const DefaultAttachmentPreviewButton = ({ attachmentId }: Props) => {
	const { updateWidthState, updateSelectedFile, updateIsOpen, addFile, files } = useContext(AttachmentsPreviewContext)

	const { getSingleAttachment } = useAttachmentsService()

	const { t } = useTranslation('attachments')

	const showAttachment = async () => {
		const isFileOpened = files.some(item => item.id === attachmentId)
		if (isFileOpened) {
			const index = files.findIndex((item: any) => item.id === attachmentId)
			updateSelectedFile(index)
			updateIsOpen(true)
			updateWidthState('fullWidth')
		} else {
			try {
				if (attachmentId) {
					const response = await getSingleAttachment(attachmentId)

					const fileExtension = getFileExtension(response.Name)

					addFile({
						id: attachmentId as string,
						fileName: response.Name,
						fileExtension: fileExtension,
						fileType: getFileType(fileExtension),
					})
					updateSelectedFile(files.length)
					updateIsOpen(true)
					updateWidthState('fullWidth')
				}
			} catch (error) {
				console.error(error)
			}
		}
	}

	const disabled = !attachmentId

	return (
		<TooltipButton
			className="p-2.5"
			title={disabled ? t('NoDefaultAttachment') : t('PreviewAttachment')}
			onClick={showAttachment}
			disabled={disabled}
			IconComponent={VisibilityIcon}
		/>
	)
}

export default DefaultAttachmentPreviewButton
