import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'

import { usePrintTemplatesService } from 'services'

type Props = {
	templateId: string
	setIsOpen: (value: boolean) => void
	isOpen: boolean
	getData: () => void
}

const AddTemplateDialog = ({ templateId, setIsOpen, isOpen, getData }: Props) => {
	const [selectedFile, setSelectedFile] = useState<any>('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { addFile, showSuccessInfo } = usePrintTemplatesService()

	const handleCloseDialog = () => {
		setIsOpen(false)
		setSelectedFile('')
	}

	const handleFileSelect = (e: any) => {
		setSelectedFile(e.target.files[0])
	}

	const handleFileSubmit = useCallback(async () => {
		const formData = new FormData()

		formData.append('file', selectedFile)
		formData.append('templateId', templateId)

		try {
			setButtonLoading(true)
			const response = await addFile(formData)
			if (response.status === 204) {
				handleCloseDialog()
				getData()
				showSuccessInfo('uploaded')
			}
		} catch (err) {
			setSelectedFile('')
			console.error(err)
		}
		setButtonLoading(false)
	}, [addFile, selectedFile, templateId])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('PrintTemplates.AddTemplateDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						width: '100%',
						margin: '25px 0 5px 0',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Button
						variant="contained"
						color="primary"
						component="label"
						endIcon={<CloudUploadIcon />}
						sx={{ mb: '8px' }}>
						{t('general.ChooseFile', { ns: 'translation' })}
						<input type="file" accept=".docx" name="file" hidden onChange={handleFileSelect} />
					</Button>
					{selectedFile && <span>{selectedFile.name}</span>}
				</Box>
			</DialogContent>
			<DialogActions sx={{ padding: '8px 26px 16px 8px' }}>
				<LoadingButton
					variant="contained"
					onClick={handleFileSubmit}
					color="success"
					disabled={selectedFile ? false : true}
					loading={buttonLoading}>
					{t('general.Add', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddTemplateDialog
