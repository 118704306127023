import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	aggregation: {
		model: {
			Value: 'sum',
			BudgetValue: 'sum',
			ValueRealized: 'sum',
			ValueAfterChanges: 'sum',
			ValueOfAnnex: 'sum',
			ValueOfParts: 'sum',
			ValueLeft: 'sum',
		},
	},
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: ['__check__'],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'__check__',
			'__tree_data_group__',
			'Name',
			'CostType',
			'MeasurementUnitId',
			'Amount',
			'Price',
			'Value',
			'BudgetValue',
			'ValueOfParts',
			'AmountRealized',
			'AmountLeft',
			'ValueRealized',
			'ValueLeft',
			'ValueOfAnnex',
			'Parameters',
			'Comments',
			'BudgetEntryId',
			'actions',
		],
		dimensions: {
			__tree_data_group__: { maxWidth: -1, minWidth: 130, width: 300, flex: 0 },
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 450,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Value: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			BudgetValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			ValueOfParts: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			AmountRealized: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			AmountLeft: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueRealized: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueLeft: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			ValueOfAnnex: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Parameters: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			Comments: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			BudgetEntryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 130,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'orderEntriesDataGridState'
