import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IOrderEntryAnnex } from 'models'

const useOrderEntryAnnexesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/orderEntryAnnex'

	const getEntryAnnexes = useCallback(
		async (orderEntryId: string) => {
			const response = await request(`${path}/${orderEntryId}`)
			return response
		},
		[request]
	)

	const addEntryAnnex = useCallback(
		async (data: IOrderEntryAnnex) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateEntryAnnex = useCallback(
		async (annexId: string, body: IOrderEntryAnnex) => {
			await request(`${path}/${annexId}`, 'PATCH', body)
		},
		[request]
	)

	const deleteEntryAnnex = useCallback(
		async (annexId: string) => {
			const response = await request(`${path}/${annexId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getEntryAnnexes,
		addEntryAnnex,
		updateEntryAnnex,
		deleteEntryAnnex,
	}
}

export default useOrderEntryAnnexesService
