import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, List, ListItem, ListItemText } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { IUser } from 'models'
import { useRolesService, useUsersService } from 'services'

import RoleGlobalAddUsersDialog from './RoleGlobalAddUsersDialog'

interface Props {
	roleId: string
}

const RoleGlobalUsers = ({ roleId }: Props) => {
	const [usersData, setUsersData] = useState<IUser[]>([])
	const [usersCanBeAddedToRole, setUsersCanBeAddedToRole] = useState<IUser[]>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false)

	const { t } = useTranslation('administration')

	const { getUsers } = useUsersService()
	const { getGlobalUsersForRole, deleteGlobalUsersForRole, showSuccessInfo } = useRolesService()

	const handleRemoveUser = useCallback(async (id: string) => {
		try {
			await deleteGlobalUsersForRole(id)
			getUsersData()
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [])

	const getUsersData = useCallback(async () => {
		try {
			const usersForRole = await getGlobalUsersForRole(roleId)
			const allUsers = await getUsers()

			const prepareUsersData = usersForRole.map((el: any) => {
				const findUser = allUsers.find((item: any) => item.Id === el.UserId)
				if (findUser) return { ...findUser, EntityId: el.Id }
			})

			const userIds = usersForRole.map((item: any) => item.UserId)
			const prepareUsersCanBeAddedData = allUsers.filter((item: any) => !userIds.includes(item.Id))

			setUsersCanBeAddedToRole(prepareUsersCanBeAddedData)
			setUsersData(prepareUsersData)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getGlobalUsersForRole, roleId])

	useEffect(() => {
		getUsersData()
	}, [roleId])

	return (
		<>
			<List
				sx={{
					width: '100%',
					padding: '0',
				}}>
				<ListItem
					sx={{
						borderBottom: 1,
						borderColor: 'divider',
						borderRadius: '4px 4px 0 0',
						color: 'text.secondary',
					}}
					secondaryAction={
						<IconButton edge="end" onClick={() => setIsAddUserDialogOpen(true)}>
							<AddIcon sx={{ fontSize: '20px' }} color="primary" />
						</IconButton>
					}>
					<ListItemText
						sx={{
							textAlign: 'left',
							width: '20%',
						}}
						primaryTypographyProps={{ fontSize: '14px' }}
						primary={t('Users.User.Login')}
					/>
					<ListItemText
						sx={{
							textAlign: 'left',
							width: '20%',
						}}
						primaryTypographyProps={{ fontSize: '14px' }}
						primary={t('Users.User.FirstName')}
					/>
					<ListItemText
						sx={{
							textAlign: 'left',
							width: '20%',
						}}
						primaryTypographyProps={{ fontSize: '14px' }}
						primary={t('Users.User.LastName')}
					/>
				</ListItem>
				{dataLoading ? (
					<Loading />
				) : usersData.length > 0 ? (
					usersData.map((el: any, index: number) => {
						return (
							<ListItem
								sx={{
									borderBottom: 1,
									borderColor: 'divider',
									borderRadius: '4px 4px 0 0',
									color: 'text.secondary',
								}}
								key={index}
								secondaryAction={
									<IconButton edge="end" onClick={() => handleRemoveUser(el.EntityId)}>
										<DeleteIcon sx={{ fontSize: '20px' }} color="primary" />
									</IconButton>
								}>
								<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.Login} />
								<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.FirstName || ''}`} />
								<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.LastName || ''}`} />
							</ListItem>
						)
					})
				) : (
					<ListItem>
						<NoData fontSize="18px" />
					</ListItem>
				)}
			</List>
			{isAddUserDialogOpen && (
				<RoleGlobalAddUsersDialog
					roleId={roleId}
					isOpen={isAddUserDialogOpen}
					setIsOpen={setIsAddUserDialogOpen}
					usersList={usersCanBeAddedToRole}
					getUsersData={getUsersData}
				/>
			)}
		</>
	)
}

export default RoleGlobalUsers
