import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IContractEntryAnnex } from 'models'

const useContractEntryAnnexesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const getContractEntryAnnexes = useCallback(
		async (contractEntryId: string) => {
			const response = await request(`/api/contractEntryAnnex/${contractEntryId}`)
			return response
		},
		[request]
	)

	const addContractEntryAnnex = useCallback(
		async (data: IContractEntryAnnex) => {
			const response = await request(`/api/contractEntryAnnex`, 'POST', data)
			return response
		},
		[request]
	)

	const updateContractEntryAnnex = useCallback(
		async (annexId: string, body: IContractEntryAnnex) => {
			await request(`/api/contractEntryAnnex/${annexId}`, 'PATCH', body)
		},
		[request]
	)

	const deleteContractEntryAnnex = useCallback(
		async (annexId: string) => {
			const response = await request(`/api/contractEntryAnnex/${annexId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getContractEntryAnnexes,
		addContractEntryAnnex,
		updateContractEntryAnnex,
		deleteContractEntryAnnex,
	}
}

export default useContractEntryAnnexesService
