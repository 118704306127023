import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, FormControlLabel, Switch, TextField, Typography } from '@mui/material'

import clsx from 'clsx'
import { useAppSelector, useNotificationsActions } from 'features'
import { INotification } from 'models'
import { useNotificationService } from 'services'
import { getCurrentDateTime, getDateTimeBeforeNow } from 'utils'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import { NoData } from '../no-data-message'
import NotificationDetails from './NotificationDetails'
import SingleNotification from './SingleNotification'

const Notifications = () => {
	const [showReaded, setShowReaded] = useState(true)
	const [selectedNotification, setSelectedNotification] = useState<INotification>()
	const [dateFrom, setDateFrom] = useState('')
	const [dateTo, setDateTo] = useState('')

	const { allNotifications } = useAppSelector(state => state.notifications)

	const { setAllNotifications, setNotificationsCount } = useNotificationsActions()
	const { getNotifications, getNotificationCount } = useNotificationService()

	const showReadedLocalStorage = localStorage.getItem('NOTIFICATIONS_SHOW_READED_FILTER')

	const { t } = useTranslation('notifications')

	const currentDate = getCurrentDateTime()

	const handleDateFromFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDateFrom(e.target.value)
	}

	const handleDateToFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDateTo(e.target.value)
	}

	const handleSwitchReaded = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.checked
		setShowReaded(value)
		localStorage.setItem('NOTIFICATIONS_SHOW_READED_FILTER', JSON.stringify(value))
	}

	const getNotificationsData = async () => {
		try {
			const response = await getNotifications(dateFrom, dateTo, showReaded)

			if (response) {
				const notificationsSortedByDate = response.sort((a: INotification, b: INotification) => {
					return new Date(b.DateTime).valueOf() - new Date(a.DateTime).valueOf()
				})
				setAllNotifications(notificationsSortedByDate)
			}

			const notificationsCount = await getNotificationCount(dateFrom, currentDate, false)
			setNotificationsCount(notificationsCount)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		setDateFrom(getDateTimeBeforeNow(7))
		setDateTo(currentDate)
		if (showReadedLocalStorage) setShowReaded(JSON.parse(showReadedLocalStorage))
	}, [])

	useEffect(() => {
		if (dateFrom && dateTo) getNotificationsData()
	}, [dateFrom, dateTo, showReaded])

	return (
		<div
			className={clsx(
				'flex flex-col h-[calc(100vh-160px)]',
				'border border-solid border-border-divider dark:border-border-darkDivider rounded'
			)}>
			<div className="h-18 flex justify-between items-center">
				<div className="text-lg p-4">{t('Notifications')}</div>
				<div className="flex items-center">
					<FormControlLabel
						className="mr-2"
						control={<Switch checked={showReaded} onChange={handleSwitchReaded} size="small" />}
						label={<Typography fontSize={'14px'}>{t('Filters.ShowReaded')}</Typography>}
						labelPlacement="start"
					/>
					<TextField
						value={formatDateTimeMUI(dateFrom) || ''}
						name="dateFrom"
						label={t('Filters.DateFrom')}
						InputLabelProps={{ shrink: true }}
						type="date"
						onChange={handleDateFromFilterChange}
						sx={{ width: 200, mr: '15px' }}
						size="small"
					/>
					<TextField
						value={formatDateTimeMUI(dateTo) || ''}
						name="dateTo"
						label={t('Filters.DateTo')}
						InputLabelProps={{ shrink: true }}
						type="date"
						onChange={handleDateToFilterChange}
						sx={{ width: 200, mr: '15px' }}
						size="small"
					/>
				</div>
			</div>
			<Divider />
			{allNotifications.length ? (
				<div className="flex flex-1">
					<div className="w-2/5 max-w-[600px] overflow-y-auto max-h-full relative">
						<div className="absolute inset-0 overflow-y-auto">
							{allNotifications.map(el => (
								<SingleNotification
									key={el.Id}
									notification={el}
									selectedNotification={selectedNotification}
									setSelectedNotification={setSelectedNotification}
									getNotificationsData={getNotificationsData}
								/>
							))}
						</div>
					</div>
					<div className="w-full">
						<NotificationDetails notification={selectedNotification} />
					</div>
				</div>
			) : (
				<NoData />
			)}
		</div>
	)
}

export default Notifications
