import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import { Box, Divider, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import { IAssignedWorkflowRole } from 'models'
import { useWorkflowService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import AddRoleDialog from './AddRoleDialog'
import DetachRoleDialog from './DetachRoleDialog'
import RolePermissions from './role-permissions/RolePermissions'

interface Props {
	workflowId: string
}

const Permissions = ({ workflowId }: Props) => {
	const [roles, setRoles] = useState<IAssignedWorkflowRole[]>([])
	const [selectedRoleId, setSelectedRoleId] = useState('')

	const [selectedIndex, setSelectedIndex] = useState(0)
	const [rolesDataLoading, setRolesDataLoading] = useState(true)

	const [isAddRoleDialogOpen, setIsAddRoleDialogOpen] = useState(false)
	const [isDetachRoleDialogOpen, setIsDetachDialogOpen] = useState(false)

	const { t } = useTranslation(['administration', 'translation'])

	const { getAssignedWorkflowRoles, detachRole, showSuccessInfo } = useWorkflowService()

	const handleRoleTabChange = (event: any, newValue: number) => {
		setSelectedRoleId(event.target.id)
		setSelectedIndex(newValue)
	}

	const handleDetachRole = async () => {
		try {
			await detachRole(workflowId, selectedRoleId)

			getAssignedRolesData()
			setSelectedIndex(0)
			showSuccessInfo('action')
		} catch (err) {
			console.error(err)
		}
	}

	const getAssignedRolesData = useCallback(
		async (selectnewlyAddedRole?: boolean) => {
			try {
				const response = await getAssignedWorkflowRoles(workflowId)

				setRoles(response)

				if (response.length > 0) {
					setSelectedRoleId(response[0].Id)
				}

				if (selectnewlyAddedRole) {
					setSelectedIndex(response.length - 1)
				}
			} catch (err) {
				console.error(err)
			}
			setRolesDataLoading(false)
		},
		[getAssignedWorkflowRoles, workflowId]
	)

	useEffect(() => {
		setSelectedIndex(0)
		getAssignedRolesData()
	}, [workflowId])

	return (
		<Box sx={sxStyles.container}>
			<Box sx={sxStyles.permissionsContainer}>
				<Box sx={sxStyles.roles}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							m: '15px 0 10px 0',
							p: '0 7px 0 15px',
						}}>
						<Typography component={'span'} fontSize={'16px'} sx={sxStyles.title}>
							{t('Workflow.Permissions.Roles')}
						</Typography>
						<Tooltip
							title={
								<TooltipTextWrapper
									title={t('general.Add', {
										ns: 'translation',
									})}
								/>
							}
							placement="top"
							arrow>
							<span>
								<IconButton
									sx={{
										padding: '3px',
									}}
									onClick={() => setIsAddRoleDialogOpen(true)}>
									<AddIcon fontSize="medium" color="primary" />
								</IconButton>
							</span>
						</Tooltip>
					</Box>
					<Divider sx={{ width: '95%' }} />
					<Tabs
						orientation="vertical"
						variant="scrollable"
						scrollButtons="auto"
						value={selectedIndex}
						onChange={handleRoleTabChange}
						sx={sxStyles.tabsContainer}>
						{rolesDataLoading ? (
							<Loading />
						) : (
							roles.map(el => {
								return <Tab key={el.Id} id={el.Id} sx={sxStyles.tab} label={el.Name} />
							})
						)}
					</Tabs>
				</Box>
				<Box sx={sxStyles.configuration}>
					{roles.length > 0 && (
						<RolePermissions
							workflowId={workflowId}
							roleId={selectedRoleId}
							setIsDetachDialogOpen={setIsDetachDialogOpen}
						/>
					)}
				</Box>
			</Box>
			{isAddRoleDialogOpen && (
				<AddRoleDialog
					isOpen={isAddRoleDialogOpen}
					setIsOpen={setIsAddRoleDialogOpen}
					refreshRoles={getAssignedRolesData}
					workflowId={workflowId}
					alreadyAssignedRoles={roles}
				/>
			)}
			{isDetachRoleDialogOpen && (
				<DetachRoleDialog
					isOpen={isDetachRoleDialogOpen}
					setIsOpen={setIsDetachDialogOpen}
					handleDetachRole={handleDetachRole}
					roleName={roles[selectedIndex].Name}
				/>
			)}
		</Box>
	)
}

export default Permissions

const sxStyles = {
	container: {
		minHeight: 'calc(100vh - 230px)',
		p: '5px 15px',
	},
	titleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		padding: '15px 5px',
		pb: '8px',
	},
	title: {
		display: 'inline-block',
	},
	tabsContainer: {
		width: '100%',
		minHeight: '200px',
		mt: '15px',
		'& .MuiTabs-indicator': {
			display: 'none',
		},
	},
	permissionsContainer: {
		mt: '10px',
		minHeight: 'calc(100vh - 265px)',
		display: 'flex',
	},
	roles: {
		borderRadius: '5px',
		backgroundColor: 'background.secondary',
		width: '15%',
		minWidth: '230px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	configuration: { width: '85%', p: '0 7px' },
	tab: { alignItems: 'flex-start', minHeight: '38px', lineHeight: '0' },
}
