import { useTranslation } from 'react-i18next'

import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import type { Field, ITaskDetails } from 'models/connector'

interface Props {
	taskDetails: ITaskDetails | null
}

interface TableProps {
	fields: Field[]
}

const RenderTable = ({ fields }: TableProps) => {
	const { t } = useTranslation(['synchronization', 'translation'])

	return (
		<Table stickyHeader size={'medium'}>
			<TableHead>
				<TableRow>
					{fields.map((field, index) => (
						<TableCell
							key={index}
							sx={{
								minWidth: '150px',
							}}>
							{t(`SyncDetailsTable.${field.Name}`)}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					{fields.map((field, index) => (
						<TableCell key={index}>
							{t(`SyncDetailsTable.${field.Value}`, {
								defaultValue: field.Value,
							})}
						</TableCell>
					))}
				</TableRow>
			</TableBody>
		</Table>
	)
}

const SyncTaskDetailsTable = ({ taskDetails }: Props) => {
	const { t } = useTranslation(['synchronization', 'translation'])

	if (!taskDetails) return null

	return (
		<>
			{taskDetails.Fields && <RenderTable fields={taskDetails.Fields} />}
			{taskDetails.SubTables &&
				taskDetails.SubTables.map((subTable, index) => (
					<Box
						key={index}
						sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', px: '25px', py: '10px' }}>
						<Typography sx={{ py: '15px' }}>{t(`SyncDetailsTable.${subTable.TableName}`)}</Typography>
						{subTable.Items && (
							<Table stickyHeader size={'medium'}>
								<TableHead>
									<TableRow>
										{subTable.Items[0].Fields.map((field, fieldIndex) => (
											<TableCell
												key={fieldIndex}
												sx={{
													minWidth: '150px',
												}}>
												{t(`SyncDetailsTable.${field.Name}`)}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{subTable.Items.map((item, itemIndex) => (
										<TableRow key={itemIndex}>
											{item.Fields.map((field, index) => (
												<TableCell key={index}>
													{t(`SyncDetailsTable.${field.Value}`, {
														defaultValue: field.Value,
													})}
												</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						)}
					</Box>
				))}
		</>
	)
}

export default SyncTaskDetailsTable
