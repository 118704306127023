import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'

import { useAppSelector, useDocumentDataActions } from 'features'
import { IDocumentData } from 'models'
import { enqueueSnackbar } from 'notistack'
import { useAttachmentsService } from 'services'

import styles from '../Styles.module.css'
import { checkIsFileAvailableForPreview } from '../utils'

interface Props {
	contextId: string
	directoryId?: string
	handleCloseDialog: () => void
	updateAttachments: React.DispatchWithoutAction
	setAsDefaultFile?: boolean
}

const AddFile = ({ contextId, directoryId, handleCloseDialog, updateAttachments, setAsDefaultFile }: Props) => {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([])
	const [buttonLoading, setButtonLoading] = useState(false)

	const { documentData } = useAppSelector(state => state.documentData)
	const { setDocumentData } = useDocumentDataActions()

	const { t } = useTranslation(['attachments', 'translation'])
	const { showSuccessInfo, addFile, setAsDefaultAttachment } = useAttachmentsService()

	const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files as FileList)
		const fileMaxSizeInBytes = 100 * 1024 * 1024 // 100 MB

		const validFiles = files.filter(file => file.size <= fileMaxSizeInBytes)
		if (validFiles.length !== files.length) {
			enqueueSnackbar(t('general.FileSizeError', { ns: 'translation' }), {
				variant: 'error',
				autoHideDuration: 8000,
			})
		}
		setSelectedFiles(validFiles)
	}

	const handleFileSubmit = useCallback(async () => {
		try {
			setButtonLoading(true)
			if (selectedFiles.length > 1) {
				for (const file of selectedFiles) {
					const formData = new FormData()
					formData.append('File', file)
					if (directoryId) {
						formData.append('directoryId', directoryId)
					}

					const response = await addFile(contextId, formData)
					if (response) {
						updateAttachments()
						handleCloseDialog()
					}
				}
			} else {
				const formData = new FormData()
				formData.append('File', selectedFiles[0])
				if (directoryId) {
					formData.append('directoryId', directoryId)
				}

				const response = await addFile(contextId, formData)
				if (response) {
					if (checkIsFileAvailableForPreview(selectedFiles[0].name) && setAsDefaultFile) {
						await setAsDefaultAttachment(contextId, documentData?.WorkflowId as string, response.Id)
						setDocumentData({ ...(documentData as IDocumentData), DefaultAttachmentId: response.Id })
					}
					updateAttachments()
					handleCloseDialog()
				}
			}
			showSuccessInfo('uploaded')
			setSelectedFiles([])
		} catch (err) {
			setSelectedFiles([])
			console.error(err)
		} finally {
			setButtonLoading(false)
		}
	}, [contextId, selectedFiles, directoryId])

	return (
		<div className={styles.uploadContainer}>
			<Button variant="contained" color="primary" component="label" endIcon={<CloudUploadIcon />} sx={{ mb: '8px' }}>
				{t('AddAttachmentDialog.ChooseFile')}
				<input type="file" name="file" hidden multiple onChange={handleFileSelect} />
			</Button>
			{selectedFiles.map((file, index) => (
				<span key={index}>{file.name}</span>
			))}
			<div className={styles.addButtonContainer}>
				<LoadingButton
					variant="contained"
					onClick={handleFileSubmit}
					color="success"
					disabled={selectedFiles.length === 0}
					loading={buttonLoading}>
					{t('general.Add', { ns: 'translation' })}
				</LoadingButton>
			</div>
		</div>
	)
}

export default AddFile
