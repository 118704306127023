import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'

import EmailTab from 'components/shared/email-tab/EmailTab'
import { useFieldsPermissions } from 'hooks'

import QuotationContractors from './quotation-contractors/QuotationContractors'

interface Props {
	demandId: string
	workflowId: string
	readonly: boolean
}

const Inquiries = ({ demandId, workflowId, readonly }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('demand')

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('documentDemandInquiriesSelectedTab', newValue)
	}

	const tabs = useMemo(() => {
		let tabsToRender = []

		if (isVisible('QuotationRequestContractors')) {
			tabsToRender.push({
				label: t('Inquiries.Tabs.Contractors'),
				renderValue: <QuotationContractors demandId={demandId} readOnly={!isEditable('QuotationRequestContractors')} />,
			})
		}

		tabsToRender.push({
			label: t('Inquiries.Tabs.Parameters'),
			renderValue: (
				<EmailTab contextId={demandId} workflowId={workflowId} readonly={readonly} documentType="QuotationRequest" />
			),
		})

		return tabsToRender
	}, [isEditable, isVisible, t])

	useEffect(() => {
		const savedTab = localStorage.getItem('documentDemandInquiriesSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<TabContext value={selectedTab}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabList onChange={handleChange}>
					{tabs.map((el, index) => {
						return <Tab key={index} label={el.label} value={index.toString()} />
					})}
				</TabList>
			</Box>
			{tabs.map((el, index) => {
				return (
					<TabPanel key={index} sx={{ p: 0 }} value={index.toString()}>
						{el.renderValue}
					</TabPanel>
				)
			})}
		</TabContext>
	)
}

export default Inquiries
