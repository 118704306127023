import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

interface IBudgetEntryReorder {
	entryId: string
	newIndex: string
}

const useRealizationBudgetService = () => {
	const { request, uploadFileRequest, downloadFileRequest, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getData = useCallback(
		async (projectId: string) => {
			const response = await request(`/api/budget?projectId=${projectId}`)
			return response
		},
		[request]
	)

	const getBudgetEntry = useCallback(
		async (budgetEntryId: string) => {
			const response = await request(`/api/budget/${budgetEntryId}`)
			return response
		},
		[request]
	)

	const getMeasurmentUnits = useCallback(async () => {
		const response = await request(`/api/MeasurementUnit/lookup`)
		return response
	}, [request])

	const addRow = useCallback(
		async (body: any) => {
			const response = await request(`/api/budget`, 'POST', body)
			return response
		},
		[request]
	)

	const updateRow = useCallback(
		async (rowId: string, body: any) => {
			await request(`/api/budget/${rowId}`, 'PATCH', body)
		},
		[request]
	)

	const deleteRow = useCallback(
		async (rowId: string) => {
			await request(`/api/budget/${rowId}`, 'DELETE')
		},
		[request]
	)

	const importBudgetStatus = useCallback(
		async (projectId: string) => {
			const response = await request(`/api/project/importBudgetStatus/${projectId}`)

			return response
		},
		[request]
	)

	const addFile = useCallback(
		async (projectId: string, formData: any) => {
			const response = await uploadFileRequest(`/api/project/importRealizationBudget/${projectId}`, 'PATCH', formData)
			return response
		},
		[request]
	)

	const downloadFile = useCallback(
		async (projectId: string) => {
			const response = await downloadFileRequest(`/api/project/exportRealizationBudget/${projectId}`)
			return response
		},
		[downloadFileRequest]
	)

	const reorder = useCallback(
		async (body: IBudgetEntryReorder) => {
			await request('/api/budget/reorder', 'POST', body)
		},
		[request]
	)

	const getIndustries = useCallback(async () => {
		const response = await request(`/api/industry`)
		return response
	}, [request])

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getData,
		getMeasurmentUnits,
		importBudgetStatus,
		updateRow,
		addFile,
		reorder,
		addRow,
		deleteRow,
		downloadFile,
		getIndustries,
		getBudgetEntry,
	}
}

export default useRealizationBudgetService
