import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

import clsx from 'clsx'
import { useAppSelector, useNotificationsActions } from 'features'
import { INotification } from 'models'
import { useNotificationService } from 'services'
import { formatDateTime } from 'utils/formatDateTime'
import prepareTitleString from 'utils/prepareTitleString'

import { usePrepareNotificationTranslation } from './utils'

type Props = {
	notification: INotification
	selectedNotification: INotification | undefined
	setSelectedNotification: (value: INotification) => void
	getNotificationsData: () => void
}

const SingleNotification = ({
	notification,
	selectedNotification,
	setSelectedNotification,
	getNotificationsData,
}: Props) => {
	const { allNotifications } = useAppSelector(state => state.notifications)

	const { getNotificationTranslation } = usePrepareNotificationTranslation()

	const translation = prepareTitleString(getNotificationTranslation(notification), 80)
	const date = formatDateTime(notification.DateTime.toLocaleString())

	const { setAllNotifications } = useNotificationsActions()
	const { updateNotification } = useNotificationService()

	const handleMarkAsRead = async () => {
		try {
			await updateNotification(notification.Id, { ...notification, IsReaded: true })

			const updatedNotifications = allNotifications.map(el => {
				if (el.Id === notification.Id) {
					return {
						...el,
						IsReaded: true,
					}
				}
				return el
			})

			setAllNotifications(updatedNotifications)
		} catch (err) {
			console.error(err)
		}
	}

	const handleNotificationClick = () => {
		setSelectedNotification(notification)
		if (!notification.IsReaded) handleMarkAsRead()
	}

	return (
		<div
			className={clsx(
				'flex min-h-16 min-w-[280px] m-2 py-1 px-2 cursor-pointer',
				'border border-solid border-border-divider dark:border-border-dividerDark rounded',
				'bg-background-secondary  hover:bg-background-highlighted',
				'dark:bg-background-darkHighlighted dark:hover:bg-background-darkSecondary',
				{ 'bg-background-highlighted dark:bg-background-darkSecondary': selectedNotification?.Id === notification.Id }
			)}
			onClick={handleNotificationClick}>
			<div className="w-8 flex items-center justify-center">
				{notification.IsReaded ? (
					<NotificationsNoneIcon fontSize="small" color="disabled" />
				) : (
					<NotificationsIcon fontSize="small" color="primary" />
				)}
			</div>
			<div
				className={clsx('w-full flex flex-col justify-between', {
					'text-text-secondary dark:text-text-darkDisabled': notification.IsReaded,
				})}>
				<div className="px-2 pt-2 mr-14">{translation}</div>
				<div className="px-2 text-[11px] text-right">{date}</div>
			</div>
		</div>
	)
}

export default SingleNotification
