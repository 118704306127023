import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import 'models'

const useSuperLogin = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const superLogin = useCallback(
		async (userId: string) => {
			const response = await request(`/api/authentication/superlogin/?id=${userId}`, 'POST')
			return response
		},
		[request]
	)

	return {
		request,
		showSuccessInfo,
		error,
		fieldsError,
		clearError,
		superLogin,
	}
}

export default useSuperLogin
