const prepareTitleString = (str: string, maxLength: number = 50) => {
	if (!str) return

	if (str.length > maxLength) {
		return str.slice(0, maxLength - 3) + '...'
	}
	return str
}

export default prepareTitleString
