export const styles = {
	title: { textAlign: 'center', p: '5px 15px' },
	content: {
		maxHeight: '500px',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		width: '500px',
		p: '8px',
	},
	singleNotification: {
		minWidth: '100%',
		minHeight: '80px',
		display: 'flex',
		my: '4px',
		border: '1px solid',
		borderColor: 'divider',
		borderRadius: '5px',
		backgroundColor: 'background.highlighted',
		'&:hover': { backgroundColor: 'background.secondary' },
		cursor: 'pointer',
		message: { width: '70%', p: '10px' },
		actionContainer: {
			width: '30%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'end',
			justifyContent: 'space-between',
			p: '3px',
		},
		date: { fontSize: '11px', textAlign: 'left', pr: '10px', pb: '3px', color: 'text.secondary' },
	},
	clearButton: { width: '100%', display: 'flex', alignItems: 'center', fontSize: '14px', py: '7px' },
}
