import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IDemand, IInvokeAction } from 'models'
import { DEMAND_ENDPOINT } from 'services/data'

const useDemandService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = DEMAND_ENDPOINT

	const getFilteredDemands = useCallback(
		async (isCanceled: boolean, isCompleted: boolean, data: GridInitialState) => {
			const response = await request(
				`${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`,
				'POST',
				data
			)
			return response
		},
		[request]
	)

	const getDemands = useCallback(
		async (isCanceled: boolean, isCompleted: boolean) => {
			const response = await request(`${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`)
			return response
		},
		[request]
	)

	const getDemand = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const createDemand = useCallback(
		async (workflowId: string, data: IDemand) => {
			const response = await request(`${path}?workflowId=${workflowId}`, 'POST', data)
			return response
		},
		[request]
	)

	const createDemandFromProject = useCallback(
		async (data: IDemand) => {
			await request(`${path}/fromProject`, 'POST', data)
		},
		[request]
	)

	const updateDemand = useCallback(
		async (id: string, data: IDemand) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (demandId: string) => {
			const response = await request(`${path}/allowedProperties/read/${demandId}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (demandId: string) => {
			const response = await request(`${path}/allowedProperties/write/${demandId}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getDemands,
		getDemand,
		createDemand,
		createDemandFromProject,
		updateDemand,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		getFilteredDemands,
	}
}

export default useDemandService
