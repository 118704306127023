import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText } from '@mui/material'

import NoData from 'components/shared/no-data-message/NoData'
import { IUser } from 'models'
import { useRolesService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	usersList: IUser[]
	getUsersData: () => void
	roleId: string
}

const RoleGlobalAddUsersDialog = ({ isOpen, setIsOpen, usersList, getUsersData, roleId }: Props) => {
	const { addGlobalUsersForRole, showSuccessInfo } = useRolesService()

	const { t } = useTranslation('administration')

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleAddUser = useCallback(
		async (userId: string) => {
			const data = {
				HeaderId: roleId,
				UserId: userId,
			}
			try {
				await addGlobalUsersForRole(data)
				getUsersData()
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
		},
		[roleId]
	)

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('Roles.Role.AddUserToRoleTitle')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{usersList.length ? (
					<List>
						{usersList.map((el: any, index: number) => {
							return (
								<ListItem
									sx={{
										borderBottom: '1px solid',
										borderColor: 'action.disabled',
										'&:last-child': {
											border: 'none',
										},
									}}
									key={index}
									secondaryAction={
										<IconButton edge="end" onClick={() => handleAddUser(el.Id)}>
											<AddIcon sx={{ fontSize: '20px' }} color="primary" />
										</IconButton>
									}>
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.Login} />
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.FirstName || ''}`} />
									<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={`${el.LastName || ''}`} />
								</ListItem>
							)
						})}
					</List>
				) : (
					<NoData fontSize="18px" />
				)}
			</DialogContent>
		</Dialog>
	)
}

export default RoleGlobalAddUsersDialog
