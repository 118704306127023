import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'Status',
			'Number',
			'Contractor',
			'DocumentCreatedDate',
			'ConclusionDate',
			'ConsumptionDateTimeStart',
			'ConsumptionDateTimeEnd',
			'Value',
			'AnnexesValue',
			'ValueWithAnnexes',
			'WorkflowIdLabel',
			'StageId',
			'SettlementType',
			'ProjectLabel',
			'OwnerIdLabel',
			'CreatorId',
			'OfferLabel',
			'IsCompleted',
			'IsCanceled',
			'actions',
		],
		dimensions: {
			Status: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
				flex: 0,
			},
			Number: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
				flex: 0,
			},
			Contractor: {
				maxWidth: -1,
				minWidth: 50,
				width: 350,
				flex: 0,
			},
			DocumentCreatedDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			ConclusionDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			ConsumptionDateTimeStart: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			ConsumptionDateTimeEnd: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			Value: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			AnnexesValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			ValueWithAnnexes: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
				flex: 0,
			},
			WorkflowIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
				flex: 0,
			},
			StageId: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
				flex: 0,
			},
			SettlementType: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
				flex: 0,
			},
			ProjectLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
				flex: 0,
			},
			OwnerIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
				flex: 0,
			},
			CreatorId: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
				flex: 0,
			},
			OfferLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			IsCompleted: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			IsCanceled: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
				flex: 0,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
	aggregation: {
		model: { Value: 'sum', AnnexesValue: 'sum', ValueWithAnnexes: 'sum' },
	},
}

export const CONTEXT_STATE_NAME = 'contractsDataGridState'
