export const COST_INVOICE_ENDPOINT = '/api/costInvoice'
export const MATERIAL_INVOICE_ENDPOINT = '/api/materialInvoice'
export const CORRECTION_INVOICE_ENDPOINT = '/api/correctionInvoice'
export const CUSTOM_COST_INVOICE_ENDPOINT = '/api/customCostInvoice'
export const ADVANCE_INVOICE_ENDPOINT = '/api/contractAdvanceInvoice'
export const PROFORMA_INVOICE_ENDPOINT = '/api/contractProformaInvoice'
export const SALES_INVOICE_ENDPOINT = '/api/contractSalesInvoice'
export const INVESTOR_SALES_INVOICE_ENDPOINT = '/api/salesInvoice'
export const DEBIT_NOTE_ENDPOINT = '/api/debitNote'
export const OFFER_ENDPOINT = '/api/offer'
export const INCOMING_LETTER_ENDPOINT = '/api/incomingLetter'
export const INVESTOR_PP_ENDPOINT = '/api/investorProcessingProtocol'
export const PROCESSING_PROTOCOL_ENDPOINT = '/api/processingProtocol'
export const GRN_ENDPOINT = '/api/goodsReceivedNote'
export const CONTRACT_ENDPOINT = '/api/contract'
export const ORDER_ENDPOINT = '/api/order'
export const DEMAND_ENDPOINT = '/api/demand'
export const BALANCING_DOCUMENT_ENDPOINT = '/api/balancingDocument'
export const COMMON_TASK_ENDPOINT = '/api/commonTask'
export const CREDIT_NOTE_ENDPOINT = '/api/creditNote'

export type EndpointType =
	| typeof COST_INVOICE_ENDPOINT
	| typeof MATERIAL_INVOICE_ENDPOINT
	| typeof CORRECTION_INVOICE_ENDPOINT
	| typeof CUSTOM_COST_INVOICE_ENDPOINT
	| typeof ADVANCE_INVOICE_ENDPOINT
	| typeof PROFORMA_INVOICE_ENDPOINT
	| typeof SALES_INVOICE_ENDPOINT
	| typeof INVESTOR_SALES_INVOICE_ENDPOINT
	| typeof DEBIT_NOTE_ENDPOINT
	| typeof OFFER_ENDPOINT
	| typeof INCOMING_LETTER_ENDPOINT
	| typeof INVESTOR_PP_ENDPOINT
	| typeof PROCESSING_PROTOCOL_ENDPOINT
	| typeof GRN_ENDPOINT
	| typeof CONTRACT_ENDPOINT
	| typeof ORDER_ENDPOINT
	| typeof DEMAND_ENDPOINT
	| typeof BALANCING_DOCUMENT_ENDPOINT
	| typeof COMMON_TASK_ENDPOINT
	| typeof CREDIT_NOTE_ENDPOINT
