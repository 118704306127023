import { useTranslation } from 'react-i18next'

import ClearIcon from '@mui/icons-material/Clear'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	TextField,
} from '@mui/material'

import { IContractorModel } from 'models'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	contactOptions: any[]
	selectedContact: string
	setSelectedContact: (value: string) => void
	selectedContractor: IContractorModel
	handleAddQuotationContractor: (value: IContractorModel) => void
	buttonLoading: boolean
}

const SelectContactDialog = ({
	isOpen,
	setIsOpen,
	contactOptions,
	selectedContact,
	setSelectedContact,
	selectedContractor,
	handleAddQuotationContractor,
	buttonLoading,
}: Props) => {
	const { t } = useTranslation(['demand', 'translation'])

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleContactChange = (e: any) => {
		setSelectedContact(e.target.value)
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					fontSize: '20px',
				}}
				component="div">
				{t('Inquiries.ContractorsListDialog.ChooseContact')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						width: '100%',
						margin: '25px 0 5px 0',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<TextField
						value={selectedContact}
						id="selectContactInfo"
						select
						label={t('Inquiries.ContractorsListDialog.ChooseContact')}
						onChange={handleContactChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<IconButton
									sx={{
										mr: '10px',
										visibility: !selectedContact || selectedContact === 'NoContact' ? 'hidden' : 'visible',
									}}
									onClick={() => setSelectedContact('NoContact')}>
									<ClearIcon />
								</IconButton>
							),
						}}>
						{contactOptions.map((el: any, index: number) => (
							<MenuItem key={index} value={el.value}>
								{el.label}
							</MenuItem>
						))}
					</TextField>
				</Box>
			</DialogContent>
			<DialogActions sx={{ padding: '8px 26px 16px 8px' }}>
				<Button variant="outlined" color="error" onClick={closeDialog}>
					{t('general.Cancel', { ns: 'translation' })}
				</Button>
				<LoadingButton
					variant="contained"
					onClick={() => handleAddQuotationContractor(selectedContractor)}
					color="success"
					disabled={!selectedContact}
					loading={buttonLoading}>
					{t('general.Add', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default SelectContactDialog
