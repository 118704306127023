import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	setDataLoading: (value: boolean) => void
	apiRef: { current: any }
	getData: () => void
}

const BankAccountsToolbar = ({ getData, apiRef, setDataLoading }: Props) => {
	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilterDemand = () => {
		apiRef.current.showFilterPanel()
	}

	const handleRefreshData = () => {
		setDataLoading(true)
		getData()
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end', m: '5px' }}>
			<TooltipButton
				title="general.ResetDataGridState"
				onClick={() => handleUpdateState(true)}
				IconComponent={RotateLeftIcon}
				fontSize="medium"
			/>
			<TooltipButton
				title="general.SaveDataGridState"
				onClick={() => handleUpdateState()}
				IconComponent={FactCheckOutlinedIcon}
				fontSize="medium"
			/>
			<TooltipButton
				title="general.Filter"
				onClick={handleFilterDemand}
				IconComponent={FilterListIcon}
				fontSize="medium"
			/>
			<TooltipButton title="general.Refresh" onClick={handleRefreshData} IconComponent={SyncIcon} fontSize="medium" />
		</GridToolbarContainer>
	)
}

export default BankAccountsToolbar
