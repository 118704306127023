import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, Box, Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material'

import { useAppSelector } from 'features'

import NotificationsList from './NotificationsList'
import { styles } from './styles'

const Notifications = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const { notificationsCount } = useAppSelector(state => state.notifications)

	const navigate = useNavigate()

	const { t } = useTranslation('notifications')

	const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleNavigateToNotifications = () => {
		navigate('/welcome')
		handleCloseMenu()
	}

	return (
		<>
			<Badge
				color="primary"
				variant="standard"
				sx={{
					'& .MuiBadge-badge': {
						right: 30,
						top: 11,
					},
				}}
				badgeContent={notificationsCount}
				invisible={!notificationsCount}>
				<IconButton className="p-1 md:p-2" onClick={handleOpenMenu} color="inherit">
					<NotificationsIcon />
				</IconButton>
			</Badge>
			<Menu
				id="notifications-menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}>
				<Box sx={styles.title}>{t('Notifications')}</Box>
				<Divider />
				<NotificationsList closeMenu={handleCloseMenu} />
				<MenuItem onClick={handleNavigateToNotifications}>
					<Stack sx={styles.clearButton}>{t('GoToNotifictions')}</Stack>
				</MenuItem>
			</Menu>
		</>
	)
}

export default Notifications
