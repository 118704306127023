import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IContractorsRepresentative } from 'models'

const useContractorsRepresentativesService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getContractorsRepresentatives = useCallback(async (projectId: string) => {
		const response = await request(`/api/ProjectExecutorParticipant?$filter=ProjectId eq ${projectId}`)
		return response
	}, [])

	const addContractorsRepresentative = useCallback(async (body: IContractorsRepresentative) => {
		const response = await request(`/odata/ProjectExecutorParticipant`, 'POST', body)
		return response
	}, [])

	const updateContractorsRepresentative = useCallback(
		async (representativeId: string, body: IContractorsRepresentative) => {
			await request(`/odata/ProjectExecutorParticipant/${representativeId}`, 'PATCH', body)
		},
		[]
	)

	const deleteContractorsRepresentative = useCallback(async (representativeId: string) => {
		const response = await request(`/api/ProjectExecutorParticipant/${representativeId}`, 'DELETE')
		return response
	}, [])

	const getUsersList = useCallback(async () => {
		const response = await request(`/api/Person/userPersons`)
		return response
	}, [])

	const GetRepresentativePermissions = useCallback(async () => {
		const response = await request(`/api/RoleHeader/GetCanBePerProject/`)
		return response
	}, [])

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getContractorsRepresentatives,
		addContractorsRepresentative,
		updateContractorsRepresentative,
		deleteContractorsRepresentative,
		getUsersList,
		GetRepresentativePermissions,
	}
}

export default useContractorsRepresentativesService
