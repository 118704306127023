import { useCallback, useContext, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'
import NoData from 'components/shared/no-data-message/NoData'
import { IAttachmentPreviewFile } from 'models'

import ImageViewer from './ImageViewer'
import PdfViewer from './PdfViewer'
import ViewerTools from './ViewerTools'
import XLSXViewer from './XLSXViewer'

interface Props {
	file: IAttachmentPreviewFile
}

const Viewer = ({ file }: Props) => {
	const [selectedPage, setSelectedPage] = useState<number>(1)
	const [fileScaling, setFileScaling] = useState(1)

	const { fileType } = file

	const { updateScaling } = useContext(AttachmentsPreviewContext)

	const handleZoomIn = useCallback(() => {
		if (fileScaling >= 5) return
		updateScaling(file.id, fileScaling + 0.2)
		setFileScaling(prev => prev + 0.2)
	}, [updateScaling, fileScaling, setFileScaling])

	const handleZoomOut = useCallback(() => {
		if (fileScaling <= 0.4) return
		updateScaling(file.id, fileScaling - 0.2)
		setFileScaling(prev => prev - 0.2)
	}, [updateScaling, fileScaling, setFileScaling])

	useEffect(() => {
		if (file.selectedPage) {
			setSelectedPage(file.selectedPage)
		} else setSelectedPage(1)

		if (file.scaling) {
			setFileScaling(file.scaling)
		} else setFileScaling(file.fileType === 'pdf' ? 1.2 : 1)
	}, [file])

	let ViewerComponent
	let viewerComponentProps = {
		file,
		selectedPage,
		fileScaling,
		setSelectedPage,
	}

	switch (fileType) {
		case 'image':
			ViewerComponent = ImageViewer
			break
		case 'pdf':
			ViewerComponent = PdfViewer
			break
		case 'xlsx':
			ViewerComponent = XLSXViewer
			break
		default:
			return <NoData />
	}

	return (
		<Box sx={sxStyles.container}>
			<ViewerComponent {...viewerComponentProps} />
			{fileType !== 'xlsx' && <ViewerTools file={file} handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} />}
		</Box>
	)
}

export default Viewer

const sxStyles = {
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '30px',
	},
}
