import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type AutocompleteInputState = {
	autocompleteInputLoading: { [instanceId: string]: boolean }
}

const initialState: AutocompleteInputState = {
	autocompleteInputLoading: {},
}

export const autocompleteInput = createSlice({
	name: 'autocompleteInput',
	initialState,
	reducers: {
		updateAutocompleteInputLoading: (state, action: PayloadAction<{ instanceId: string; loading: boolean }>) => {
			const { instanceId, loading } = action.payload
			state.autocompleteInputLoading[instanceId] = loading
		},
	},
})

export const autocompleteInputActions = autocompleteInput.actions

export default autocompleteInput.reducer
