export class FetchError extends Error {
	response: Response

	constructor(message: string, response: Response) {
		super(message)
		this.response = response
	}

	async readError() {
		return this.response.text()
	}
}
