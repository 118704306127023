import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InputAdornment, MenuItem, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput } from 'components/shared'
import { BudgetCostTypeDataSource, BudgetEntryCategoryDataSource } from 'data/lookup-data-sources'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useBudgetEntryDataChange } from 'hooks'
import { BudgetEntryFieldType, IIndustry, IMeasurementUnit, IRealizationBudgetEntry } from 'models'
import { useIndustriesService, useMeasurementUnitsService } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import ShowSourceButton from './ShowSourceButton'
import Source from './Source'

type Props = {
	formData: IRealizationBudgetEntry
}

const Form = ({ formData }: Props) => {
	const [industriesList, setIndustriesList] = useState<IIndustry[]>([])
	const [measurementUnitsList, setMeasurementUnitsList] = useState<IMeasurementUnit[]>([])
	const [selectedFieldName, setSelectedFieldName] = useState<BudgetEntryFieldType | null>(null)

	const sessionStorageName = `BUDGET_ENTRY_SOURCE_SELECTED_FIELD_${formData.Id}`

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()

	const { handleInputChange, handleAutocompleteInputChange, fieldsError } = useBudgetEntryDataChange()

	const { getIndustries } = useIndustriesService()
	const { getMeasurementUnitsLookup } = useMeasurementUnitsService()

	const { t } = useTranslation('project')

	const handleSelectFieldName = (fieldName: BudgetEntryFieldType) => {
		setSelectedFieldName(fieldName)
		sessionStorage.setItem(sessionStorageName, fieldName)
	}

	const getIndustriesData = async (instanceId: string) => {
		if (industriesList.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getIndustries()

				setIndustriesList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	const getMeasurementUnitsData = async (instanceId: string) => {
		if (measurementUnitsList.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMeasurementUnitsLookup()

				setMeasurementUnitsList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	const isDisabled = formData.HasChildren

	useEffect(() => {
		const savedFieldName = sessionStorage.getItem(sessionStorageName)

		if (savedFieldName) setSelectedFieldName(savedFieldName as BudgetEntryFieldType)
	}, [formData.Id])

	return (
		<>
			<div className="w-full pb-4">
				<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
					{/* Ord. No. */}
					<Grid xs={12} sm={3} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.NumberString || ''}
							name="NumberString"
							label={t('BudgetTabGeneral.GroupingColumn')}
							fullWidth
						/>
					</Grid>
					{/* Description */}
					<Grid xs={12} sm={9} md={9} lg={9}>
						<TextField
							disabled={isDisabled}
							value={formData.Description || ''}
							name="Description"
							label={t('RealizationBudgetTab.Columns.Description')}
							error={fieldsError.includes('Description')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					{/* Category */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={isDisabled}
							value={formData.Category || ''}
							label={t('RealizationBudgetTab.Columns.Category')}
							name="Category"
							error={fieldsError.includes('Category')}
							onChange={handleInputChange}
							select
							fullWidth>
							{BudgetEntryCategoryDataSource.map((option: any) => (
								<MenuItem key={option.value} value={option.value}>
									{t(`RealizationBudgetTab.Columns.CategoryEnums.${option.label}`)}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					{/* CostType */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={isDisabled}
							value={formData.CostType || ''}
							label={t('RealizationBudgetTab.Columns.CostType')}
							name="CostType"
							error={fieldsError.includes('CostType')}
							onChange={handleInputChange}
							select
							fullWidth>
							{BudgetCostTypeDataSource.map((option: any) => (
								<MenuItem key={option.value} value={option.value}>
									{t(`RealizationBudgetTab.Columns.CostTypeEnums.${option.label}`)}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					{/* Industries  */}
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						initialValue={{
							value: formData.IndustryId || '',
							label: formData.IndustryIdLabel || '',
						}}
						onOpen={() => getIndustriesData('RealizationBudgetTab.Columns.IndustryId')}
						onChange={handleAutocompleteInputChange}
						options={industriesList}
						readOnly={isDisabled}
						instanceId="RealizationBudgetTab.Columns.IndustryId"
						inputLabel={t('RealizationBudgetTab.Columns.IndustryId')}
						name="IndustryId"
						labelFieldName="IndustryIdLabel"
					/>
					{/* MeasurementUnitId  */}
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						initialValue={{
							value: formData.MeasurementUnitId || '',
							label: formData.MeasurementUnitIdLabel || '',
						}}
						onOpen={() => getMeasurementUnitsData('RealizationBudgetTab.Columns.MeasurementUnitId')}
						onChange={handleAutocompleteInputChange}
						options={measurementUnitsList}
						readOnly={isDisabled}
						instanceId="RealizationBudgetTab.Columns.MeasurementUnitId"
						inputLabel={t('RealizationBudgetTab.Columns.MeasurementUnitId')}
						optionValueFieldName={'value'}
						optionLabelFieldName={'label'}
						name="MeasurementUnitId"
						labelFieldName="MeasurementUnitIdLabel"
					/>
					{/* Amount */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={isDisabled}
							value={formData.Amount || ''}
							name="Amount"
							error={fieldsError.includes('Amount')}
							onChange={handleInputChange}
							label={t('RealizationBudgetTab.Columns.Amount')}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					</Grid>
					{/* Price */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={isDisabled}
							value={formData.Price || ''}
							name="Price"
							error={fieldsError.includes('Price')}
							onChange={handleInputChange}
							label={t('RealizationBudgetTab.Columns.Price')}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					</Grid>
					{/* Budget */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.Budget || ''}
							name="Budget"
							label={t('RealizationBudgetTab.Columns.Budget')}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					</Grid>
					{/* RealizationDateStart */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={isDisabled}
							value={formatDateTimeMUI(formData.RealizationDateStart) || ''}
							name="RealizationDateStart"
							error={fieldsError.includes('RealizationDateStart')}
							onChange={handleInputChange}
							label={t('RealizationBudgetTab.Columns.RealizationDateStart')}
							InputLabelProps={{ shrink: true }}
							type="date"
							fullWidth
						/>
					</Grid>
					{/* RealizationDateEnd */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={isDisabled}
							value={formatDateTimeMUI(formData.RealizationDateEnd) || ''}
							name="RealizationDateEnd"
							error={fieldsError.includes('RealizationDateEnd')}
							onChange={handleInputChange}
							label={t('RealizationBudgetTab.Columns.RealizationDateEnd')}
							InputLabelProps={{ shrink: true }}
							type="date"
							fullWidth
						/>
					</Grid>
					{/* RealizationLengthDays */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.RealizationLengthDays || ''}
							name="RealizationLengthDays"
							label={t('RealizationBudgetTab.Columns.RealizationLengthDays')}
							fullWidth
						/>
					</Grid>
					{/* ExecutionStatusPerc */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.ExecutionStatusPerc || ''}
							name="ExecutionStatusPerc"
							label={t('RealizationBudgetTab.Columns.ExecutionStatusPerc')}
							placeholder="0"
							type="number"
							InputLabelProps={{ shrink: true }}
							InputProps={{
								inputProps: { min: 0, max: 100 },
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							}}
							fullWidth
						/>
					</Grid>
					{/* Plan */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.Plan || ''}
							name="Plan"
							label={t('RealizationBudgetTab.Columns.Plan')}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					</Grid>
					{/* ExecutionPlan */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.ExecutionPlan || ''}
							name="ExecutionPlan"
							label={t('RealizationBudgetTab.Columns.ExecutionPlan')}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					</Grid>
					{/* DemandAndContracting */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.DemandAndContracting || ''}
							name="DemandAndContracting"
							label={t('RealizationBudgetTab.Columns.DemandAndContracting')}
							fullWidth
						/>
					</Grid>
					{/* CostsSum */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.CostsSum || ''}
							name="CostsSum"
							label={t('RealizationBudgetTab.Columns.CostsSum')}
							InputProps={{
								inputComponent: FormatNumberInput as any,
							}}
							fullWidth
						/>
					</Grid>
				</Grid>
			</div>
			<div className="w-full flex pb-4">
				<div className="w-1/2 pr-2">
					<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
						{/* Incomes */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.Incomes || ''}
								name="Incomes"
								label={t('RealizationBudgetTab.Columns.Incomes')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="Incomes"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* SubcontractorsContractsValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.SubcontractorsContractsValue || ''}
								name="SubcontractorsContractsValue"
								label={t('RealizationBudgetTab.Columns.SubcontractorsContractsValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="SubcontractorsContractsValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* SubcontractorsExecutionValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.SubcontractorsExecutionValue || ''}
								name="SubcontractorsExecutionValue"
								label={t('RealizationBudgetTab.Columns.SubcontractorsExecutionValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="SubcontractorsExecutionValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* SubcontractorsCostsValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.SubcontractorsCostsValue || ''}
								name="SubcontractorsCostsValue"
								label={t('RealizationBudgetTab.Columns.SubcontractorsCostsValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="SubcontractorsCostsValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* EquipmentContractsValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.EquipmentContractsValue || ''}
								name="EquipmentContractsValue"
								label={t('RealizationBudgetTab.Columns.EquipmentContractsValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="EquipmentContractsValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* EquipmentCostsValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.EquipmentCostsValue || ''}
								name="EquipmentCostsValue"
								label={t('RealizationBudgetTab.Columns.EquipmentCostsValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="EquipmentCostsValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* MaterialContractsValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.MaterialContractsValue || ''}
								name="MaterialContractsValue"
								label={t('RealizationBudgetTab.Columns.MaterialContractsValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="MaterialContractsValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* MaterialCostsValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.MaterialCostsValue || ''}
								name="MaterialCostsValue"
								label={t('RealizationBudgetTab.Columns.MaterialCostsValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="MaterialCostsValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* GeneralCostsValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.GeneralCostsValue || ''}
								name="GeneralCostsValue"
								label={t('RealizationBudgetTab.Columns.GeneralCostsValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="GeneralCostsValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
						{/* GeneralContractsValue */}
						<Grid xs={12} sm={12} md={12} lg={12}>
							<TextField
								disabled={true}
								value={formData.GeneralContractsValue || ''}
								name="GeneralContractsValue"
								label={t('RealizationBudgetTab.Columns.GeneralContractsValue')}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<ShowSourceButton
											selectedFieldName={selectedFieldName}
											setFieldName={handleSelectFieldName}
											fieldName="GeneralContractsValue"
										/>
									),
								}}
								fullWidth
							/>
						</Grid>
					</Grid>
				</div>
				<div className="w-1/2 pl-2 pt-4">
					<Source id={formData.Id} fieldName={selectedFieldName} />
				</div>
			</div>
		</>
	)
}

export default Form
