import React from 'react'

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'

import { useCheckPermissions, useHandleNavigate } from 'hooks'

import { TooltipButton } from '../tooltip-button'

type Props = {
	path: string
	documentId: string | null
	documentLabel: string | null
}

const InputLinkButton = React.memo(({ path, documentId, documentLabel }: Props) => {
	const { checkForPermissionToAccessDocument } = useCheckPermissions()
	const { handleDocumentLinkNavigate } = useHandleNavigate()

	if (!documentId || !documentLabel) return null

	const handleLinkClick = () => {
		handleDocumentLinkNavigate(path, documentId, documentLabel)
	}

	const disabled = !checkForPermissionToAccessDocument(path, documentId)

	return (
		<TooltipButton
			className="mr-0.5"
			title={disabled ? 'general.NoAccess' : 'general.GoToDocument'}
			IconComponent={ArrowOutwardIcon}
			onClick={handleLinkClick}
			disabled={disabled}
		/>
	)
})

export default InputLinkButton
