import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IContractorModel } from 'models'

export type ListOfContractors = {
	value: string
	label: string
	SearchItems: string
}

export type ListOfBankAccounts = {
	Id: string
	Name: string
}

type InitialState = {
	contractorId: string
	contractorData: IContractorModel | null
	listOfContractors: ListOfContractors[]
	listOfBankAccounts: ListOfBankAccounts[]
}

const initialState: InitialState = {
	contractorId: '',
	contractorData: null,
	listOfContractors: [],
	listOfBankAccounts: [],
}

export const documentContractor = createSlice({
	name: 'documentContractor',
	initialState,
	reducers: {
		setContractorId: (state, action: PayloadAction<string>) => {
			state.contractorId = action.payload
		},
		setContractorData: (state, action: PayloadAction<IContractorModel | null>) => {
			state.contractorData = action.payload
		},
		setlistOfContractors: (state, action: PayloadAction<any[]>) => {
			state.listOfContractors = action.payload
		},
		setlistOfBankAccounts: (state, action: PayloadAction<any[]>) => {
			state.listOfBankAccounts = action.payload
		},
	},
})

export const documentContractorActions = documentContractor.actions

export default documentContractor.reducer
