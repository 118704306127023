import { useEffect, useState } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Pagination, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material'

import { ITask, ITaskDetails } from 'models'
import { useConnectorService } from 'services'

import TaskRow from './sessions-tab/TaskRow'
import SyncDialogDetails from './sync-details/SyncDialogDetails'
import { sxStyles } from './utils'

interface Props {
	tasks: ITask[]
	sessionTab?: boolean
}

const SynchronizationTasks = ({ sessionTab, tasks }: Props) => {
	const [isOpen, setIsOpen] = useState(false)
	const [expandedTasks, setExpandedTasks] = useState<string[]>([])
	const [taskDetails, setTaskDetails] = useState<ITaskDetails | null>(null)
	const [page, setPage] = useState(0)

	const savedRowsPerPage = localStorage.getItem('SYNCHRONIZATION_TASKS_ROWS_PER_PAGE')

	const [rowsPerPage, setRowsPerPage] = useState(savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 10)

	const { getTaskData } = useConnectorService()

	const { t } = useTranslation(['synchronization', 'translation'])

	const handleOpenDialog = async (taskId: string) => {
		if (!sessionTab) return
		const details = await getTaskData(taskId)
		setTaskDetails(details)
		setIsOpen(true)
		setExpandedTasks(prevTasks => [...prevTasks, taskId])
	}

	const handleCloseDialog = () => {
		setIsOpen(false)
		setTaskDetails(null)
	}

	const handlePageChange = (e: any, newPage: number) => {
		setPage(newPage)
	}

	const handleSinglePageChange = (e: any, newPage: number) => {
		setPage(newPage - 1)
	}

	const handleRowsPerPageChange = (e: any) => {
		const newRowsPerPage = parseInt(e.target.value, 10)
		setRowsPerPage(newRowsPerPage)
		setPage(0)
		localStorage.setItem('SYNCHRONIZATION_TASKS_ROWS_PER_PAGE', newRowsPerPage.toString())
	}

	useEffect(() => {
		const fetchTaskDetails = async () => {
			const lastTaskId = expandedTasks[expandedTasks.length - 1]
			if (lastTaskId) {
				const details = await getTaskData(lastTaskId)
				setTaskDetails(details)
			}
		}

		fetchTaskDetails()
	}, [expandedTasks])

	const startIndex = page * rowsPerPage
	const endIndex = startIndex + rowsPerPage
	const paginatedTasks = tasks.slice(startIndex, endIndex)
	const pagesCount = Math.ceil(tasks.length / rowsPerPage)

	return (
		<>
			<Table
				sx={{
					minWidth: sessionTab ? '100%' : 650,
					borderRadius: '5px 5px 0 0',
					border: sessionTab ? '1px solid' : 'none',
					borderColor: 'border.primary',
				}}
				stickyHeader
				size={'medium'}>
				<TableHead>
					<TableRow>
						<TableCell sx={sxStyles.headerCellLeft}>{t('Fields.DateTimeStart')}</TableCell>
						{sessionTab && <TableCell>{t('Fields.DateTimeEnd')}</TableCell>}
						<TableCell>{t('Fields.Type')}</TableCell>
						<TableCell>{t('Fields.Direction')}</TableCell>
						<TableCell>{t('Fields.EntryId')}</TableCell>
						<TableCell>{t('Fields.Document')}</TableCell>
						<TableCell>{t('Fields.Description')}</TableCell>
						{sessionTab && <TableCell>{t('Fields.Status')}</TableCell>}
						{sessionTab && <TableCell sx={sxStyles.headerCellRight}>{t('Fields.ErrorInfo')}</TableCell>}
					</TableRow>
				</TableHead>
				<TableBody>
					{paginatedTasks.map((task, index) => (
						<React.Fragment key={task.Id}>
							<TaskRow
								task={task}
								tasks={tasks}
								index={index}
								sessionTab={sessionTab}
								handleOpenDialog={handleOpenDialog}
							/>
							{expandedTasks.includes(task.Id) && taskDetails && (
								<SyncDialogDetails isOpen={isOpen} handleCloseDialog={handleCloseDialog} taskDetails={taskDetails} />
							)}
						</React.Fragment>
					))}
				</TableBody>
			</Table>
			{sessionTab && (
				<div className="flex items-center border-solid border-border-primary dark:border-border-darkPrimary rounded-b border-t-0">
					<div className="w-1/2">
						{pagesCount > 1 && <Pagination count={pagesCount} page={page + 1} onChange={handleSinglePageChange} />}
					</div>
					<TablePagination
						component={'div'}
						className="w-1/2"
						count={tasks.length}
						page={page}
						onPageChange={handlePageChange}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleRowsPerPageChange}
						rowsPerPageOptions={[10, 20, 30, 50, 100, 250]}
						labelRowsPerPage={false}
					/>
				</div>
			)}
		</>
	)
}

export default SynchronizationTasks
