import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	DataGridPremium,
	GridCellParams,
	GridEventListener,
	GridInitialState,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import NoData from 'components/shared/no-data-message/NoData'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import _ from 'lodash'
import { IOfferDraftEntry } from 'models'
import { useMeasurementUnitsService, useQuotationRequestOfferDraft } from 'services'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import Toolbar from './Toolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	offerToken: string
	readOnly: boolean
	setIsEntriesSaved: (value: boolean) => void
}

const Entries = ({ offerToken, readOnly, setIsEntriesSaved }: Props) => {
	const [data, setData] = useState<IOfferDraftEntry[]>([])
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

	const [dataGridState, setDataGridState] = useState<GridInitialState>()

	const [dataLoading, setDataLoading] = useState(true)

	const [measurementUnits, setMeasurementUnits] = useState<any>([])

	const localStorageState = localStorage.getItem(CONTEXT_STATE_NAME)

	const { handleSortColumns } = useDataGridState()

	const { t } = useTranslation(['demand', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const handleDataGridStateChange = () => {
		const newState = apiRef.current.exportState()
		const previousState = localStorageState

		if (!_.isEqual(JSON.stringify(newState), previousState)) {
			localStorage.setItem(CONTEXT_STATE_NAME, JSON.stringify(newState))
			setDataGridState(newState)
		}
	}

	const handleResetState = () => {
		apiRef.current.restoreState(INITIALSTATE)
		localStorage.setItem(CONTEXT_STATE_NAME, JSON.stringify(INITIALSTATE))
	}

	const { getMeasurementUnitsLookup } = useMeasurementUnitsService()
	const { getEntries, updateEntry, showSuccessInfo } = useQuotationRequestOfferDraft()

	const apiRef = useGridApiRef()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
		setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } })
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		if (!readOnly) setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		const updatedRow = newRow
		try {
			await updateEntry(offerToken, newRow.Id, newRow as IOfferDraftEntry)
			setData(data.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
			showSuccessInfo('saved')
			getData()
		} catch (err) {
			console.error(err)
		}
		return updatedRow
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('QuotationRequestOfferDraft.Entries.Name'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: false,
		},
		{
			field: 'MeasurementUnitId',
			headerName: t('QuotationRequestOfferDraft.Entries.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.MeasurementUnitId || null
			},
			valueOptions: measurementUnits,
			valueFormatter: ({ value }: any) => {
				const option = measurementUnits.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'OfferAmount',
			headerName: t('QuotationRequestOfferDraft.Entries.OfferAmount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (!value) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'OfferPrice',
			headerName: t('QuotationRequestOfferDraft.Entries.OfferPrice'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (!value) {
					return ''
				}

				return formatNumberDataGrid(value, 4, 4)
			},
			editable: true,
			sortable: false,
		},
		{
			field: 'OfferSum',
			headerName: t('QuotationRequestOfferDraft.Entries.OfferSum'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (!value) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: false,
			editable: false,
		},
		{
			field: 'OfferParameters',
			headerName: t('QuotationRequestOfferDraft.Entries.OfferParameters'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
		{
			field: 'OfferComment',
			headerName: t('QuotationRequestOfferDraft.Entries.OfferComment'),
			headerAlign: 'center',
			align: 'left',
			sortable: false,
			editable: true,
		},
	]

	const getSortedColumns = (columns: any) => {
		return dataGridState?.columns?.orderedFields
			? handleSortColumns(columns, dataGridState?.columns?.orderedFields)
			: columns
	}

	const orderedColumns = getSortedColumns(columns)

	const getMeasurementUnitsList = useCallback(async () => {
		try {
			const response = await getMeasurementUnitsLookup()
			setMeasurementUnits(response)
		} catch (err) {
			console.error(err)
		}
	}, [getMeasurementUnitsLookup])

	const getData = useCallback(async () => {
		try {
			setDataLoading(true)
			const response = await getEntries(offerToken)
			setData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [offerToken, getEntries])

	useEffect(() => {
		getMeasurementUnitsList()
		getData()
	}, [offerToken])

	useEffect(() => {
		if (localStorageState) {
			const state = JSON.parse(localStorageState)

			apiRef.current.restoreState(state)
			setDataGridState(state)
		}
	}, [])

	useEffect(() => {
		const isSomeRowInEditMode = Object.values(rowModesModel).some(row => row.mode === 'edit')

		setIsEntriesSaved(!isSomeRowInEditMode)
	}, [rowModesModel])

	return (
		<DataGridPremium
			apiRef={apiRef}
			editMode="row"
			columns={orderedColumns}
			rows={data}
			getRowId={row => row.Id}
			loading={dataLoading}
			onCellDoubleClick={handleCellDoubleClick}
			onRowEditStart={handleRowEditStart}
			onRowEditStop={handleRowEditStop}
			processRowUpdate={processRowUpdate}
			onStateChange={handleDataGridStateChange}
			rowModesModel={rowModesModel}
			onRowModesModelChange={newModel => setRowModesModel(newModel)}
			sx={{ height: '450px' }}
			experimentalFeatures={{ newEditingApi: true, aggregation: true }}
			components={{
				Toolbar: Toolbar,
				NoRowsOverlay: () => <NoData />,
			}}
			componentsProps={{
				toolbar: {
					handleResetState,
				},
			}}
			initialState={INITIALSTATE}
			rowHeight={35}
			localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
		/>
	)
}

export default Entries
