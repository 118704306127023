import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPaymentMethod } from 'models'

const usePaymentMethodService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/paymentMethod'

	const getPaymentMethods = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const getPaymentMethod = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const addPaymentMethod = useCallback(
		async (data: IPaymentMethod) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updatePaymentMethod = useCallback(
		async (id: string, body: IPaymentMethod) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deletePaymentMethod = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPaymentMethods,
		getPaymentMethod,
		addPaymentMethod,
		updatePaymentMethod,
		deletePaymentMethod,
	}
}

export default usePaymentMethodService
