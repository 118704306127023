import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Box, Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js'
import { IEmailParameters } from 'models'
import { useEmailParametersService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import Loading from '../loading/Loading'
import AddAttachmentDialog from './AddAttachmentDialog'
import EmailForm from './EmailForm'
import EmailTemplates from './templates/EmailTemplates'

interface Props {
	contextId: string
	workflowId: string
	readonly: boolean
	documentType: string
}

const EmailTab = ({ contextId, workflowId, readonly, documentType }: Props) => {
	const [isAddAttachmentDialogOpen, setIsAddAttachmentDialogOpen] = useState(false)
	const [dataLoading, setDataLoading] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [isSaveContentButtonDisabled, setIsSaveContentButtonDisabled] = useState(true)

	const [emailAttachments, setEmailAttachments] = useState<any[]>([])
	const [sendToContactPersons, setSendToContactPersons] = useState(false)
	const [backendHtmlEmailBody, setBackendHtmlEmailBody] = useState('')
	const [defaultEmailText, setDefaultEmailText] = useState('')
	const [emailSubject, setEmailSubject] = useState('')
	const [emailText, setEmailText] = useState('')

	const { t } = useTranslation()

	const { getEmailData, getAttachments, updateEmailData, showSuccessInfo } = useEmailParametersService()

	const theme = useTheme()
	const tabletView = useMediaQuery(theme.breakpoints.down('md'))
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const handleChangeSentToContact = () => {
		setIsSaveContentButtonDisabled(false)
		setSendToContactPersons(prev => !prev)
	}

	const handleSaveContent = async () => {
		try {
			setButtonLoading(true)
			await updateEmailData(contextId, workflowId, {
				EmailSubject: emailSubject,
				EmailBody: emailText,
				SendCC: sendToContactPersons,
			} as IEmailParameters)

			showSuccessInfo('saved')
			setIsSaveContentButtonDisabled(true)
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	const getDataFromHtml = (data: any) => {
		if (data) {
			const html = convertFromHTML(data)

			const state = ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)

			const result = EditorState.createWithContent(state)

			return JSON.stringify(convertToRaw(result.getCurrentContent()))
		}
		return ''
	}

	const getData = async () => {
		try {
			setDataLoading(true)
			const response = await getEmailData(contextId, workflowId)

			const emailString = getDataFromHtml(response.EmailBody)

			setEmailSubject(response.EmailSubject || '')
			setEmailText(emailString || '')
			setBackendHtmlEmailBody(response.EmailBody || '<p><br></p>')
			setDefaultEmailText(emailString || '')
			setSendToContactPersons(response.SendCC)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}

	const getAttachmentsData = useCallback(async () => {
		try {
			const response = await getAttachments(contextId, workflowId)

			setEmailAttachments(response)
		} catch (err) {
			console.error(err)
		}
	}, [getAttachments, contextId, workflowId])

	useEffect(() => {
		getData()
		getAttachmentsData()
	}, [contextId, workflowId])

	if (dataLoading) {
		return <Loading />
	}

	return (
		<>
			{!readonly && (
				<Box sx={sxStyles.toolsContainer}>
					<Box>
						<FormControlLabel
							sx={{ fontSize: '10px' }}
							control={
								<Checkbox disabled={readonly} checked={sendToContactPersons} onChange={handleChangeSentToContact} />
							}
							label={
								<Typography variant="body2" color="textSecondary">
									{!tabletView
										? t('EmailParametersTab.SendToContactPersons')
										: t('EmailParametersTab.SendToContactPersonsShort')}
								</Typography>
							}
						/>
					</Box>
					<Box>
						<EmailTemplates
							isSaveContentButtonDisabled={isSaveContentButtonDisabled}
							emailSubject={emailSubject}
							emailText={emailText}
							getDataFromHtml={getDataFromHtml}
							setEmailSubject={setEmailSubject}
							setEmailText={setEmailText}
							setDefaultEmailText={setDefaultEmailText}
							setIsSaveContentButtonDisabled={setIsSaveContentButtonDisabled}
							type={documentType}
						/>
						<Tooltip title={<TooltipTextWrapper title={t('EmailParametersTab.AttachFiles')} />} placement="top" arrow>
							<span>
								<IconButton
									sx={{ padding: '7px' }}
									disabled={readonly}
									onClick={() => setIsAddAttachmentDialogOpen(true)}>
									<AttachFileIcon fontSize="medium" color={'primary'} />
								</IconButton>
							</span>
						</Tooltip>
						{!mobileView ? (
							<LoadingButton
								sx={{ maxHeight: '40px', ml: '8px' }}
								variant="contained"
								onClick={handleSaveContent}
								loading={buttonLoading}
								disabled={isSaveContentButtonDisabled}>
								{t('general.Save', { ns: 'translation' })}
							</LoadingButton>
						) : (
							<Tooltip
								title={<TooltipTextWrapper title={t('general.Save', { ns: 'translation' })} />}
								placement="top"
								arrow>
								<span>
									<IconButton
										sx={{ padding: '7px', margin: '0 5px 0 0' }}
										onClick={handleSaveContent}
										disabled={isSaveContentButtonDisabled}>
										<SaveIcon fontSize="medium" color={isSaveContentButtonDisabled ? 'disabled' : 'primary'} />
									</IconButton>
								</span>
							</Tooltip>
						)}
					</Box>
				</Box>
			)}
			<Box
				sx={{
					...sxStyles.formContainer,
					pt: readonly ? '15px' : '0',
				}}>
				<EmailForm
					emailSubject={emailSubject}
					defaultEmailText={defaultEmailText}
					setEmailSubject={setEmailSubject}
					setIsSaveContentButtonDisabled={setIsSaveContentButtonDisabled}
					backendHtmlEmailBody={backendHtmlEmailBody}
					setEmailText={setEmailText}
					attachments={emailAttachments}
					contextId={contextId}
					workflowId={workflowId}
					updateAttachments={getAttachmentsData}
					readonly={readonly}
					documentType={documentType}
				/>
			</Box>
			{isAddAttachmentDialogOpen && (
				<AddAttachmentDialog
					isOpen={isAddAttachmentDialogOpen}
					setIsOpen={setIsAddAttachmentDialogOpen}
					contextId={contextId}
					workflowId={workflowId}
					updateAttachments={getAttachmentsData}
				/>
			)}
		</>
	)
}

export default EmailTab

const sxStyles = {
	toolsContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '15px',
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		p: '15px',
	},
}
