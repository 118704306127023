import { createContext, useEffect, useState } from 'react'

import { IAttachmentPreviewFile } from 'models'

interface AttachmentsPreviewType {
	isAttachmentsPreviewOpen: boolean
	updateIsOpen: (value: boolean) => void
	attachmentsPreviewWidthState: string
	updateWidthState: (value: string) => void
	attachmentsPreviewFullWidth: number
	updateFullWidth: (value: number) => void
	attachmentsPreviewSide: string
	updateSide: (value: string) => void
	files: any[]
	addFile: (newFile: IAttachmentPreviewFile) => void
	removeFile: (fileId: string) => void
	selectedFile: number
	updateSelectedFile: (value: number) => void
	updateSelectedPage: (fileId: string, selectedPage: number) => void
	updateScaling: (fileId: string, scaling: number) => void
	clearFiles: () => void
}

const initialAttachmentsPreviewValue: AttachmentsPreviewType = {
	isAttachmentsPreviewOpen: false,
	updateIsOpen: () => {},
	attachmentsPreviewWidthState: 'fullWidth',
	updateWidthState: () => {},
	attachmentsPreviewFullWidth: 50,
	updateFullWidth: () => {},
	attachmentsPreviewSide: 'right',
	updateSide: () => {},
	files: [],
	addFile: () => {},
	removeFile: () => {},
	selectedFile: 0,
	updateSelectedFile: () => {},
	updateSelectedPage: () => {},
	updateScaling: () => {},
	clearFiles: () => {},
}

interface AttachmentsPreviewProviderProps {
	children: JSX.Element
}

const AttachmentsPreviewContext = createContext<AttachmentsPreviewType>(initialAttachmentsPreviewValue)

const AttachmentsPreviewProvider = ({ children }: AttachmentsPreviewProviderProps) => {
	const [isAttachmentsPreviewOpen, setIsAttachmentsPreviewOpen] = useState<boolean>(
		initialAttachmentsPreviewValue.isAttachmentsPreviewOpen
	)
	const [attachmentsPreviewWidthState, setAttachmentsPreviewWidthState] = useState<string>(
		initialAttachmentsPreviewValue.attachmentsPreviewWidthState
	)
	const [attachmentsPreviewFullWidth, setAttachmentsPreviewFullWidth] = useState<number>(
		initialAttachmentsPreviewValue.attachmentsPreviewFullWidth
	)
	const [attachmentsPreviewSide, setAttachmentsPreviewSide] = useState<string>(
		initialAttachmentsPreviewValue.attachmentsPreviewSide
	)
	const [files, setFiles] = useState<IAttachmentPreviewFile[]>(initialAttachmentsPreviewValue.files)
	const [selectedFile, setSelectedFile] = useState<number>(initialAttachmentsPreviewValue.selectedFile)

	const updateLocalStorageData = (name: string, data: any) => {
		// Save data to local storage when attachments preview data changes
		localStorage.setItem(name, JSON.stringify(data))
	}

	const updateSelectedPage = (fileId: string, selectedPage: number) => {
		const updatedFiles = files.map(file => {
			if (file.id === fileId) {
				return { ...file, selectedPage }
			}
			return file
		})
		setFiles(updatedFiles)
		updateLocalStorageData('attachmentsPreview_config_files', updatedFiles)
	}

	const updateScaling = (fileId: string, scaling: number) => {
		const updatedFiles = files.map(file => {
			if (file.id === fileId) {
				return { ...file, scaling }
			}
			return file
		})
		setFiles(updatedFiles)
		updateLocalStorageData('attachmentsPreview_config_files', updatedFiles)
	}

	const updateIsOpen = (value: boolean) => {
		setIsAttachmentsPreviewOpen(value)
		updateLocalStorageData('attachmentsPreview_config_isOpen', value)
	}

	const updateWidthState = (state: string) => {
		setAttachmentsPreviewWidthState(state)
		updateLocalStorageData('attachmentsPreview_config_width_state', state)
	}

	const updateFullWidth = (width: number) => {
		setAttachmentsPreviewFullWidth(width)
		updateLocalStorageData('attachmentsPreview_config_full_width', width)
	}

	const updateSide = (side: string) => {
		setAttachmentsPreviewSide(side)
		updateLocalStorageData('attachmentsPreview_config_side', side)
	}

	const updateSelectedFile = (selectedFileIndex: number) => {
		setSelectedFile(selectedFileIndex)
		updateLocalStorageData('attachmentsPreview_config_selectedFile', selectedFileIndex)
	}

	const addFile = (newFile: IAttachmentPreviewFile) => {
		const updatedFiles = [...files, newFile]
		setFiles(updatedFiles)
		updateLocalStorageData('attachmentsPreview_config_files', updatedFiles)
	}

	const removeFile = (fileId: string) => {
		const updatedFiles = files.filter((item: any) => item.id !== fileId)
		setFiles(updatedFiles)
		updateLocalStorageData('attachmentsPreview_config_files', updatedFiles)
	}

	const clearFiles = () => {
		setFiles([])
		updateLocalStorageData('attachmentsPreview_config_files', [])
		updateLocalStorageData('attachmentsPreview_config_selectedFile', 0)
	}

	useEffect(() => {
		// Retrieve data from local storage on component mount
		const isOpen = localStorage.getItem('attachmentsPreview_config_isOpen')
		const widthState = localStorage.getItem('attachmentsPreview_config_width_state')
		const fullWidth = localStorage.getItem('attachmentsPreview_config_full_width')
		const previewFiles = localStorage.getItem('attachmentsPreview_config_files')
		const previewSelectedFile = localStorage.getItem('attachmentsPreview_config_selectedFile')
		const side = localStorage.getItem('attachmentsPreview_config_side')

		if (isOpen) {
			const res = JSON.parse(isOpen)
			setIsAttachmentsPreviewOpen(res)
		}

		if (widthState) {
			const res = JSON.parse(widthState)
			setAttachmentsPreviewWidthState(res)
		}

		if (fullWidth) {
			const res = JSON.parse(fullWidth)
			setAttachmentsPreviewFullWidth(res)
		}

		if (side) {
			const res = JSON.parse(side)
			setAttachmentsPreviewSide(res)
		}

		if (previewFiles) {
			const res = JSON.parse(previewFiles)
			setFiles(res)
		}

		if (previewSelectedFile) {
			const res = JSON.parse(previewSelectedFile)
			setSelectedFile(res)
		}
	}, [])

	return (
		<AttachmentsPreviewContext.Provider
			value={{
				isAttachmentsPreviewOpen,
				updateIsOpen,
				attachmentsPreviewWidthState,
				updateWidthState,
				attachmentsPreviewFullWidth,
				updateFullWidth,
				attachmentsPreviewSide,
				updateSide,
				files,
				addFile,
				removeFile,
				selectedFile,
				updateSelectedFile,
				updateSelectedPage,
				updateScaling,
				clearFiles,
			}}>
			{children}
		</AttachmentsPreviewContext.Provider>
	)
}

export { AttachmentsPreviewContext, AttachmentsPreviewProvider }
