import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IWarranty } from 'models'

const useWarrantyService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getListOfWarranties = useCallback(async (projectId: string) => {
		const response = await request(`/api/ProjectWarranty?$filter=ProjectId eq ${projectId}`)
		return response
	}, [])

	const addWarranty = useCallback(async (body: IWarranty) => {
		const response = await request(`/odata/ProjectWarranty`, 'POST', body)
		return response
	}, [])

	const updateWarranty = useCallback(async (warrantyId: string, body: IWarranty) => {
		await request(`/odata/ProjectWarranty/${warrantyId}`, 'PATCH', body)
	}, [])

	const deleteWarranty = useCallback(async (warrantyId: string) => {
		const response = await request(`/api/ProjectWarranty/${warrantyId}`, 'DELETE')
		return response
	}, [])

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		addWarranty,
		updateWarranty,
		deleteWarranty,
		getListOfWarranties,
	}
}

export default useWarrantyService
