import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, MenuItem, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, ContactPersons, ContractorForm, InputHint, InputLinkButton } from 'components/shared'
import { BudgetCostTypeDataSource, PATHS } from 'data'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { ICreditNote } from 'models'
import { IPaymentMethod } from 'models'
import { CREDIT_NOTE_ENDPOINT, usePaymentMethodService } from 'services'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import { ErpData, Settlements } from './form-tabs'

type Props = {
	noteId: string
	formData: ICreditNote
}

const Form = ({ noteId, formData }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')
	const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('credit-note')

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { handleInputChange, fieldsError, handleAutocompleteInputChange } = useFormDataChange()
	const { getPaymentMethods } = usePaymentMethodService()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem('creditNoteFormSelectedTab', newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicInformationFormTabs.Settlements'),
				renderValue: <Settlements formData={formData} />,
				isVisible: true,
			},
			{
				label: t('BasicInformationFormTabs.Contact'),
				renderValue: (
					<ContactPersons
						documentId={noteId}
						contractorId={formData.Contractor || ''}
						endpoint={CREDIT_NOTE_ENDPOINT}
					/>
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
			{
				label: t('BasicInformationFormTabs.ERP'),
				renderValue: <ErpData formData={formData} />,
				isVisible: true,
			},
		],
		[t, formData]
	)

	const getPaymentMethodsData = async (instanceId: string) => {
		if (paymentMethods.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getPaymentMethods()

				setPaymentMethods(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	useEffect(() => {
		const savedTab = localStorage.getItem('creditNoteFormSelectedTab')
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="CreditNote.Number" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								name="DocumentDate"
								label={t('Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="CreditNote.DocumentDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="CreditNote.StageId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
										<InputHint translationPath="CreditNote.WorkflowId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
											<InputHint translationPath="CreditNote.Project" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* BudgetEntry */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('BudgetEntry') && (
							<TextField
								disabled={true}
								value={formData.BudgetEntryLabel || ''}
								name="BudgetEntry"
								label={t('Fields.BudgetEntry')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.budgetEntry}
												documentId={formData.BudgetEntry || ''}
												documentLabel={formData.BudgetEntryLabel}
											/>
											<InputHint translationPath="CreditNote.IncomingLetter" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* IncomingLetter */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('IncomingLetter') && (
							<TextField
								disabled={true}
								value={formData.IncomingLetterLabel || ''}
								name="IncomingLetter"
								label={t('Fields.IncomingLetter')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.incomingLetter}
												documentId={formData.IncomingLetter || ''}
												documentLabel={formData.IncomingLetterLabel}
											/>
											<InputHint translationPath="CreditNote.IncomingLetter" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ForeignNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ForeignNumber') && (
							<TextField
								disabled={!isEditable('ForeignNumber')}
								value={formData.ForeignNumber || ''}
								name="ForeignNumber"
								label={t('Fields.ForeignNumber')}
								error={fieldsError.includes('ForeignNumber')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="CreditNote.ForeignNumber" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* MasterNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('MasterNumber') && (
							<TextField
								disabled={true}
								value={formData.MasterNumber || ''}
								name="MasterNumber"
								label={t('Fields.MasterNumber')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="CreditNote.MasterNumber" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* CostType */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('CostType') && (
							<TextField
								disabled={!isEditable('CostType')}
								value={formData.CostType || ''}
								id="CostType"
								name="CostType"
								label={t('Fields.CostType')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="CreditNote.CostType" select />
										</InputAdornment>
									),
								}}
								select>
								{BudgetCostTypeDataSource.map((el: any, index: number) => (
									<MenuItem key={index} value={el.value}>
										{t(`Fields.CostTypeEnums.${el.label}`)}
									</MenuItem>
								))}
							</TextField>
						)}
					</Grid>
					{/* ReceiptDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ReceiptDate') && (
							<TextField
								disabled={!isEditable('ReceiptDate')}
								value={formatDateTimeMUI(formData.ReceiptDate) || ''}
								name="ReceiptDate"
								label={t('Fields.ReceiptDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="CreditNote.ReceiptDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* IssueDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('IssueDate') && (
							<TextField
								disabled={!isEditable('IssueDate')}
								value={formatDateTimeMUI(formData.IssueDate) || ''}
								name="IssueDate"
								label={t('Fields.IssueDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="CreditNote.IssueDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* PaymentDate */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('PaymentDate') && (
							<TextField
								disabled={!isEditable('PaymentDate')}
								value={formatDateTimeMUI(formData.PaymentDate) || ''}
								name="PaymentDate"
								label={t('Fields.PaymentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="CreditNote.PaymentDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* PaymentMethod */}
					{isVisible('PaymentMethod') && (
						<AutocompleteInput
							dimensions={{ xs: 12, sm: 12, md: 3, lg: 3 }}
							initialValue={{
								value: formData.PaymentMethod || '',
								label: formData.PaymentMethodLabel || '',
							}}
							onOpen={() => getPaymentMethodsData('Fields.PaymentMethod')}
							onChange={handleAutocompleteInputChange}
							options={paymentMethods}
							readOnly={!isEditable('PaymentMethod')}
							instanceId="Fields.PaymentMethod"
							inputLabel={t('Fields.PaymentMethod')}
							name="PaymentMethod"
							labelFieldName="PaymentMethodLabel"
							hintTranslationPath="CreditNote.PaymentMethod"
						/>
					)}
				</Grid>
			</div>
			<ContractorForm
				documentId={noteId}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
				showContractorBankAccount
				contractorBankAccountProps={{
					readOnly: !isEditable('ContractorBankAccount'),
					label: formData.ContractorBankAccountLabel,
					value: formData.ContractorBankAccount,
				}}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default Form
