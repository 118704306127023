import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText } from '@mui/material'

import NoData from 'components/shared/no-data-message/NoData'
import { IRoleHeader } from 'models'
import { useRolesService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	roles: IRoleHeader[]
	userId: string
	refreshData: () => void
}

const AddRoleDialog = ({ isOpen, setIsOpen, roles, userId, refreshData }: Props) => {
	const { t } = useTranslation('administration')

	const { addUserGlobalRole, showSuccessInfo } = useRolesService()

	const closeDialog = () => {
		setIsOpen(false)
	}

	const handleAddRole = useCallback(
		async (roleId: string) => {
			try {
				await addUserGlobalRole({ HeaderId: roleId, UserId: userId })
				refreshData()
				showSuccessInfo('saved')
			} catch (err) {
				console.error(err)
			}
		},
		[addUserGlobalRole, userId]
	)

	return (
		<Dialog open={isOpen} fullWidth maxWidth={false}>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Users.UserPage.AddRoleDialogTitle')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{roles.length ? (
					<List>
						{roles.map((el: any, index: number) => {
							return (
								<ListItem
									sx={sxStyles.listItem}
									key={index}
									secondaryAction={
										<IconButton edge="end" onClick={() => handleAddRole(el.Id)}>
											<AddIcon sx={{ fontSize: '20px' }} color="primary" />
										</IconButton>
									}>
									<ListItemText sx={sxStyles.listItemText} primary={el.Name} />
								</ListItem>
							)
						})}
					</List>
				) : (
					<NoData fontSize="18px" />
				)}
			</DialogContent>
		</Dialog>
	)
}

export default AddRoleDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	listItem: {
		borderBottom: '1px solid',
		borderColor: 'action.disabled',
		'&:last-child': {
			border: 'none',
		},
	},
	listItemText: { textAlign: 'left', width: '20%' },
}
