import { ElementType, MutableRefObject, MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import SaveIcon from '@mui/icons-material/Save'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'
import { IconButton } from '@mui/material'
import { GridInitialState } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import clsx from 'clsx'
import { useDataGridState, usePermissions } from 'hooks'

type ToolbarSettingsMenuButton = {
	title: string
	onClick: () => void
	IconComponent: ElementType
	translationFile?: string
	visible: boolean
}

type Props = {
	apiRef: MutableRefObject<GridApiPremium | any>
	initialState: GridInitialState
	contextName: string
}

const SettingsMenu = ({ apiRef, initialState, contextName }: Props) => {
	const [isMenuOpened, setIsMenuOpened] = useState(false)
	const menuRef = useRef<HTMLDivElement>(null)

	const { handleSaveStateSnapshot } = useDataGridState()

	const { isGranted } = usePermissions()

	const isAdmin = isGranted('Administrator')

	const { t } = useTranslation()

	const handleOpenMenu = (event: ReactMouseEvent<HTMLElement>) => {
		setIsMenuOpened(true)
	}

	const handleCloseMenu = () => {
		setIsMenuOpened(false)
	}

	const handleButtonClick = (callback: () => void) => {
		handleCloseMenu()
		callback()
	}

	const handleClickOutside = (event: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			handleCloseMenu()
		}
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, initialState, contextName)
	}

	const handleOpenColumnsMenu = () => {
		apiRef.current.showPreferences('columns')
	}

	const handleExportExcel = () => {
		apiRef.current.exportDataAsExcel()
	}

	const handleExportCvs = () => {
		apiRef.current.exportDataAsCsv()
	}

	const buttons: ToolbarSettingsMenuButton[] = [
		{
			title: 'general.SaveDataGridState',
			onClick: () => handleUpdateState(),
			IconComponent: SaveIcon,
			visible: true,
		},
		{
			title: 'general.Columns',
			onClick: handleOpenColumnsMenu,
			IconComponent: ViewWeekIcon,
			visible: true,
		},
		{
			title: 'general.ResetDataGridState',
			onClick: () => handleUpdateState(true),
			IconComponent: RotateLeftIcon,
			visible: true,
		},
		{
			title: 'general.ExportExcel',
			onClick: handleExportExcel,
			IconComponent: SaveAltIcon,
			visible: isAdmin,
		},
		{
			title: 'general.ExportCvs',
			onClick: handleExportCvs,
			IconComponent: SaveAltIcon,
			visible: isAdmin,
		},
	]

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<>
			<IconButton className="p-1" onClick={handleOpenMenu}>
				<MoreVertOutlinedIcon fontSize="medium" color="primary" />
			</IconButton>
			<div
				ref={menuRef}
				className={clsx(
					'flex flex-col py-2',
					'absolute right-2 top-12 z-20 w-[250px]',
					'border-solid border-border-divider dark:border-border-darkDivider',
					'rounded bg-background-default dark:bg-background-darkHighlighted',
					{
						hidden: !isMenuOpened,
					}
				)}>
				{buttons.map((button, index) =>
					button.visible ? (
						<div
							key={index}
							className={clsx('flex items-center cursor-pointer py-1.5 px-1', 'hover:bg-primary-main/10')}
							onClick={() => handleButtonClick(button.onClick)}>
							<button.IconComponent className="mx-2" color="primary" fontSize="small" />
							<span>
								{t(`${button.title}`, { ns: button.translationFile ? button.translationFile : 'translation' })}
							</span>
						</div>
					) : null
				)}
			</div>
		</>
	)
}

export default SettingsMenu
