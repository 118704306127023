import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack } from '@mui/material'

import { useTabs } from 'hooks'
import type { ITab } from 'models/ITab'

import { sxStylesTabHeader } from './styles'

interface Props {
	tab: ITab
	isActive?: boolean
	index: number
}

const TabHeader = ({ tab, isActive, index }: Props) => {
	const { closeTab } = useTabs()

	const navigate = useNavigate()

	const handeTabClick = (tab: ITab) => {
		const state = tab.state ? { headerPostFix: tab.state.headerPostFix } : null
		navigate(tab.url, { state })
	}

	const handleCloseTab = (e: React.MouseEvent<HTMLElement, MouseEvent>, tab: ITab) => {
		e.stopPropagation()
		closeTab(tab.key)
	}

	const tabStyles = isActive ? { ...sxStylesTabHeader.tab, ...sxStylesTabHeader.activeTab } : sxStylesTabHeader.tab

	return (
		<Box sx={tabStyles} onClick={() => handeTabClick(tab)}>
			{isActive && <Stack sx={sxStylesTabHeader.activeBottomElement}></Stack>}
			<Stack sx={sxStylesTabHeader.headerName}>{tab.name}</Stack>
			<IconButton sx={{ padding: '1px' }} onClick={e => handleCloseTab(e, tab)}>
				<CloseIcon sx={sxStylesTabHeader.closeButton} />
			</IconButton>
		</Box>
	)
}

export default TabHeader
