import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import { DataGridPremium, GridRowId, GridRowParams, useGridApiRef } from '@mui/x-data-grid-premium'

import AddContactInfoDialog from 'components/pages/dictionaries/contractors/AddContactInfoDialog'
import { TooltipButton } from 'components/shared/buttons'
import NoData from 'components/shared/no-data-message/NoData'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IContractorsContactInfo } from 'models'
import { useContractorsService } from 'services'

import AddContactDialog from './AddContactDialog'
import ContractorContactPersonsToolbar from './ContractorContactPersonsToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	contextId: string
	contractorId: string
	readOnly: boolean
	getContractorsContactPersons: (contextId: string) => Promise<any>
	addContractorsContactPerson: (contextId: string, contactId: string) => Promise<void>
	deleteContractorsContactPerson: (contextId: string, contactId: string) => Promise<void>
}

const ContractorContactPersons = ({
	contractorId,
	contextId,
	readOnly,
	getContractorsContactPersons,
	addContractorsContactPerson,
	deleteContractorsContactPerson,
}: Props) => {
	const [personsDataToRender, setPersonsDataToRender] = useState<IContractorsContactInfo[]>([])
	const [personsCanBeAdded, setPersonsCanBeAdded] = useState<IContractorsContactInfo[]>([])

	const [isAddContactInfoDialogOpen, setIsAddContactInfoDialogOpen] = useState(false)

	const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false)
	const [dataLoading, setDataLoading] = useState(true)

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation('dictionaries')

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { showSuccessInfo, getContractorContactInfo } = useContractorsService()

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteContractorsContactPerson(contextId, id as string)
			setPersonsDataToRender(personsDataToRender.filter((row: any) => row.Id !== id))
			getData()
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('Contractor.ContactInfoPerson.Name'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: true,
		},
		{
			field: 'PhoneNumber',
			headerName: t('Contractor.ContactInfoPerson.PhoneNumber'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: true,
		},
		{
			field: 'Email',
			headerName: t('Contractor.ContactInfoPerson.Email'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: true,
		},
		{
			field: 'Comments',
			headerName: t('Contractor.ContactInfoPerson.Comments'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => [
				<TooltipButton
					title="general.Remove"
					onClick={handleDeleteClick(params.id)}
					IconComponent={DeleteIcon}
					disabled={readOnly}
				/>,
			],
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = useCallback(async () => {
		try {
			const allContractorPersonsResponse = await getContractorContactInfo(contractorId)
			const contractorsContactPersonsIds = await getContractorsContactPersons(contextId)

			const alreadyAddedPersons = allContractorPersonsResponse.filter((el: any) =>
				contractorsContactPersonsIds.includes(el.Id)
			)
			const personsThatCanBeAdded = allContractorPersonsResponse.filter(
				(el: any) => !contractorsContactPersonsIds.includes(el.Id)
			)

			setPersonsDataToRender(alreadyAddedPersons)
			setPersonsCanBeAdded(personsThatCanBeAdded)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [contractorId, contextId, getContractorContactInfo, getContractorsContactPersons])

	useEffect(() => {
		if (contractorId && contextId) {
			getData()
		} else {
			setDataLoading(false)
		}
	}, [contractorId, contextId])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				autoHeight
				apiRef={apiRef}
				columns={orderedColumns}
				rows={personsDataToRender}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				components={{
					Toolbar: ContractorContactPersonsToolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						setIsAddContactDialogOpen,
						readOnly,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isAddContactInfoDialogOpen && (
				<AddContactInfoDialog
					isOpen={isAddContactInfoDialogOpen}
					setIsOpen={setIsAddContactInfoDialogOpen}
					getContacts={getData}
					contractorId={contractorId}
				/>
			)}
			{isAddContactDialogOpen && (
				<AddContactDialog
					addContactFunction={addContractorsContactPerson}
					setIsOpen={setIsAddContactDialogOpen}
					showSuccessInfo={showSuccessInfo}
					setIsAddContactInfoDialogOpen={setIsAddContactInfoDialogOpen}
					contactsList={personsCanBeAdded}
					isOpen={isAddContactDialogOpen}
					contextId={contextId}
					refreshData={getData}
				/>
			)}
		</>
	)
}

export default ContractorContactPersons
