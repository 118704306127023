import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'

import { AutocompleteInputOption } from 'components/shared'
import { useTabs } from 'hooks'
import { IProjectModel } from 'models'
import { useProjectsBasicInfoService } from 'services'
import formatPostalCode from 'utils/formatPostalCode'
import { v4 as uuidv4 } from 'uuid'

import ProjectForm from './ProjectBasicInfoForm'

const ProjectDraft = () => {
	const [projectData, setProjectData] = useState<IProjectModel | any>({})
	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation()

	const mainTabs = useTabs()
	const navigate = useNavigate()

	const { createProject, fieldsError, clearError } = useProjectsBasicInfoService()

	const handleProjectDataChange = useCallback(
		(e: any) => {
			isCreateButtonDisabled && setIsCreateButtonDisabled(false)

			const value = e.target.value
			const fieldName = e.target.name

			if (fieldsError.includes(fieldName)) {
				clearError(fieldName)
			}

			switch (fieldName) {
				case 'Address_PostalCode':
					setProjectData((prev: any) => {
						return { ...prev, [e.target.name]: formatPostalCode(value) }
					})
					break
				case 'WorkProgressPerc':
				case 'ProjectProgressPerc':
					if (value === '' || /^100(\.0*)?$|^(\d|[1-9]\d)(\.\d*)?$/.test(value)) {
						setProjectData((prev: any) => {
							return { ...prev, [fieldName]: value }
						})
					}
					break
				default:
					setProjectData((prev: any) => {
						return { ...prev, [fieldName]: value }
					})
			}
		},
		[fieldsError, clearError, isCreateButtonDisabled]
	)

	const handleAutocompleteInputChange = (
		newValue: AutocompleteInputOption,
		valueFieldName: string,
		labelFieldName: string
	) => {
		isCreateButtonDisabled && setIsCreateButtonDisabled(false)

		setProjectData((prev: any) => {
			return {
				...prev,
				[valueFieldName]: newValue.value,
				[labelFieldName]: newValue.label,
			}
		})
	}

	const handleCreateProject = useCallback(async () => {
		if (projectData) {
			setButtonLoading(true)
			const Id = uuidv4()
			try {
				const response = await createProject({ ...projectData, Id: Id })

				mainTabs.closeTab(mainTabs.selectedTab?.key as string)

				navigate(`/project/${response.Id}`, {
					state: { headerPostFix: response.ProjectNumber },
				})
			} catch (err) {
				console.error(err)
			}
			setButtonLoading(false)
		}
	}, [projectData, mainTabs])

	return (
		<>
			<Box sx={sxStyles.toolsContainer}>
				<LoadingButton
					variant="contained"
					disabled={isCreateButtonDisabled}
					onClick={handleCreateProject}
					loading={buttonLoading}>
					{t('general.Create')}
				</LoadingButton>
			</Box>
			<Box sx={sxStyles.formContainer}>
				<ProjectForm
					projectId="draft"
					formData={projectData}
					onInputChange={handleProjectDataChange}
					onAutocompleteChange={handleAutocompleteInputChange}
					fieldErrorsList={fieldsError}
					readOnly={false}
				/>
			</Box>
		</>
	)
}

export default ProjectDraft

const sxStyles = {
	toolsContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '15px',
		pb: '0',
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: '15px',
		pt: '0',
	},
}
