import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const ContractIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M11.333,20.266c-0.568,0-1.126-0.303-1.433-0.838c-0.447-0.789-0.184-1.787,0.587-2.229l2.972-1.685
		c0.29-0.168,0.654-0.063,0.816,0.226c0.164,0.288,0.063,0.653-0.226,0.817l-2.969,1.684c-0.197,0.113-0.26,0.38-0.139,0.595
		c0.122,0.212,0.381,0.295,0.581,0.181l7.275-4.142l1.584-2.341c0.187-0.274,0.558-0.348,0.833-0.161
		c0.273,0.187,0.348,0.56,0.161,0.833l-1.662,2.456c-0.052,0.075-0.12,0.14-0.2,0.187l-7.396,4.211
		C11.871,20.198,11.601,20.266,11.333,20.266z"
			/>
			<path
				fill="currentColor"
				d="M9.387,18.971c-0.144,0-0.288-0.02-0.43-0.059c-0.426-0.117-0.78-0.396-1-0.785
		c-0.448-0.783-0.187-1.784,0.586-2.229l3.346-1.799c0.294-0.158,0.655-0.048,0.813,0.243c0.157,0.292,0.048,0.655-0.244,0.813
		l-3.331,1.792C8.941,17.055,8.879,17.32,9,17.534c0.063,0.11,0.161,0.188,0.275,0.221c0.103,0.027,0.211,0.017,0.303-0.036
		l2.971-1.686c0.286-0.167,0.654-0.063,0.817,0.226s0.063,0.654-0.226,0.817l-2.971,1.686C9.929,18.9,9.66,18.971,9.387,18.971z"
			/>
			<path
				fill="currentColor"
				d="M7.323,17.695c-0.568,0-1.13-0.303-1.437-0.844c-0.221-0.385-0.278-0.832-0.161-1.258
		c0.113-0.414,0.381-0.759,0.751-0.969l3.508-2.005c0.288-0.165,0.655-0.063,0.818,0.223c0.165,0.288,0.065,0.654-0.223,0.818
		l-3.51,2.007c-0.093,0.053-0.159,0.139-0.188,0.243c-0.032,0.116-0.016,0.239,0.046,0.347c0.123,0.217,0.381,0.297,0.581,0.188
		l3.341-1.803c0.293-0.158,0.656-0.049,0.813,0.242c0.157,0.292,0.048,0.656-0.243,0.813l-3.33,1.796
		C7.85,17.632,7.586,17.695,7.323,17.695z"
			/>
			<path
				fill="currentColor"
				d="M7.203,15.339c-0.141,0-0.282-0.019-0.423-0.057c-0.427-0.115-0.784-0.394-1.007-0.782
		c-0.449-0.788-0.187-1.789,0.586-2.23l1.413-0.825c0.289-0.168,0.653-0.07,0.821,0.216c0.167,0.286,0.07,0.653-0.216,0.82
		L6.96,13.309c-0.204,0.116-0.267,0.383-0.145,0.598c0.063,0.109,0.161,0.188,0.279,0.219c0.073,0.021,0.183,0.029,0.296-0.036
		l3.512-2.007c0.288-0.166,0.654-0.064,0.818,0.223c0.165,0.288,0.065,0.654-0.223,0.818L7.986,15.13
		C7.746,15.27,7.477,15.339,7.203,15.339z"
			/>
			<path
				fill="currentColor"
				d="M8.752,10.965c-0.481,0-0.997-0.081-1.546-0.242c-0.318-0.093-0.5-0.427-0.406-0.745
		C6.893,9.66,7.223,9.476,7.544,9.571c0.971,0.284,1.77,0.253,2.377-0.094c0.792-0.452,1.028-1.31,1.03-1.319
		c0.081-0.32,0.404-0.516,0.729-0.435l3.027,0.762c0.104,0.027,0.211,0.01,0.303-0.045c0.104-0.062,0.178-0.163,0.208-0.286
		c0.031-0.122,0.015-0.244-0.046-0.347c-0.056-0.094-0.145-0.161-0.247-0.187c-2.067-0.536-5.457-1.403-5.756-1.453L9.064,6.16
		C8.852,6.141,8.603,6.119,8.237,6.119C7.983,6.124,6.697,6.456,5.652,6.76C5.329,6.852,5.001,6.669,4.908,6.351
		C4.815,6.033,4.999,5.7,5.316,5.607C6.029,5.4,7.739,4.918,8.237,4.918c0.412,0,0.692,0.025,0.935,0.046l0.126,0.011
		C9.559,5,12.664,5.796,15.225,6.459c0.412,0.105,0.76,0.367,0.979,0.737c0.226,0.379,0.287,0.824,0.177,1.254
		c-0.107,0.43-0.376,0.793-0.756,1.02c-0.367,0.221-0.799,0.285-1.212,0.179l-2.517-0.633c-0.223,0.454-0.644,1.083-1.381,1.503
		C9.996,10.817,9.406,10.965,8.752,10.965z"
			/>
			<path
				fill="currentColor"
				d="M3.271,13.991L3.271,13.991c-0.1,0-0.198-0.013-0.294-0.036l-1.763-0.439
		c-0.313-0.078-0.579-0.274-0.746-0.554c-0.167-0.278-0.215-0.604-0.137-0.918L1.913,5.71c0.16-0.638,0.841-1.041,1.471-0.882
		l1.763,0.441C5.796,5.432,6.192,6.092,6.03,6.741l-1.581,6.332C4.313,13.613,3.828,13.991,3.271,13.991z M3.093,5.992l-1.597,6.343
		l1.775,0.457c0.001,0,0.011-0.003,0.013-0.011l1.581-6.332L3.093,5.992z"
			/>
			<path
				fill="currentColor"
				d="M21.143,13.389L21.143,13.389c-0.442,0-0.851-0.24-1.063-0.628l-3.156-5.714
		c-0.156-0.283-0.191-0.611-0.104-0.922c0.091-0.312,0.296-0.569,0.578-0.726l1.596-0.88c0.568-0.313,1.335-0.087,1.646,0.476
		l3.155,5.715c0.323,0.585,0.108,1.325-0.476,1.648l-1.594,0.878C21.549,13.336,21.348,13.389,21.143,13.389z M19.59,5.575
		L17.979,6.45l3.164,5.739c0.002,0,0.004,0,0.006-0.001l1.593-0.88L19.59,5.575z"
			/>
			<path
				fill="currentColor"
				d="M4.702,14.709c-0.114,0-0.23-0.032-0.333-0.102l-1.105-0.741c-0.276-0.185-0.349-0.556-0.164-0.832
		c0.184-0.276,0.556-0.349,0.832-0.165l1.105,0.742c0.275,0.185,0.349,0.557,0.164,0.832C5.086,14.616,4.896,14.709,4.702,14.709z"
			/>
		</SvgIcon>
	)
}

export default ContractIcon
