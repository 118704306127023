import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'Name',
			'ShortName',
			'NIP',
			'NIP_Country',
			'AddressId',
			'PhoneNumber',
			'EMail',
			'Industries',
			'MasterId',
			'actions',
		],
		dimensions: {
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 500,
				flex: 0,
			},
			ShortName: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			NIP: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			NIP_Country: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			AddressId: {
				maxWidth: -1,
				minWidth: 50,
				width: 400,
			},
			PhoneNumber: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			EMail: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			Industries: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			MasterId: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 100,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'contractorsDictionaryDataGridState'
