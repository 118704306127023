import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const DemandIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M23.282,11.672l-3.005-4.677c-0.457-0.712-1.44-1.25-2.287-1.25h-2.346V4.232
	c0-0.975-0.793-1.767-1.768-1.767H1.767C0.793,2.466,0,3.258,0,4.232v13.54c0,0.975,0.793,1.768,1.767,1.768H3.59
	c0.279,1.143,1.313,1.994,2.542,1.994s2.264-0.852,2.543-1.994h4.634h0.569h2.847c0.28,1.143,1.314,1.994,2.545,1.994
	c1.229,0,2.263-0.852,2.541-1.994h0.424c0.974,0,1.767-0.793,1.767-1.768v-3.653C24,13.374,23.686,12.299,23.282,11.672z
	 M17.99,6.986c0.423,0,1.016,0.324,1.244,0.68l3.004,4.677c0.002,0.003,0.004,0.006,0.006,0.009h-6.6V6.986H17.99z M6.132,20.293
	c-0.758,0-1.375-0.617-1.375-1.375c0-0.76,0.617-1.377,1.375-1.377c0.76,0,1.377,0.617,1.377,1.377
	C7.509,19.676,6.892,20.293,6.132,20.293z M13.309,18.299H8.676c-0.279-1.146-1.313-2-2.544-2c-1.229,0-2.263,0.854-2.542,2H1.767
	c-0.29,0-0.525-0.236-0.525-0.527V4.232c0-0.29,0.235-0.526,0.525-0.526h12.111c0.29,0,0.526,0.236,0.526,0.526v13.54
	c0,0.291-0.236,0.526-0.526,0.526L13.309,18.299L13.309,18.299z M19.27,20.293c-0.761,0-1.377-0.617-1.377-1.375
	c0-0.76,0.616-1.377,1.377-1.377c0.758,0,1.375,0.617,1.375,1.377C20.645,19.676,20.025,20.293,19.27,20.293z M22.759,17.771
	c0,0.291-0.235,0.526-0.524,0.526h-0.424c-0.278-1.146-1.313-2-2.541-2c-1.23,0-2.266,0.854-2.545,2h-1.16
	c0.054-0.168,0.08-0.344,0.08-0.526v-4.18H22.7c0.038,0.19,0.06,0.372,0.06,0.525L22.759,17.771L22.759,17.771z"
			/>
		</SvgIcon>
	)
}

export default DemandIcon
