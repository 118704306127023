import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import Groups2Icon from '@mui/icons-material/Groups2'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PrintIcon from '@mui/icons-material/Print'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'

import PageNavigation from 'components/shared/page-navigation/PageNavigation'

import BusinessData from './business-data-tab/BusinessData'
import PrintTemplates from './print-templates-tab/PrintTemplates'
import Roles from './roles-tab/Roles'
import Users from './users-tab/Users'
import WorkflowTab from './workflow-tab/WorkflowTab'

const Administration = () => {
	const { t } = useTranslation('administration')

	const tabs = useMemo(
		() => [
			{
				id: 0,
				tabName: t('Tabs.UsersTab'),
				icon: <PeopleAltIcon />,
				component: <Users />,
				disabled: false,
			},
			{
				id: 1,
				tabName: t('Tabs.RolesTab'),
				icon: <Groups2Icon />,
				component: <Roles />,
				disabled: false,
			},
			{
				id: 2,
				tabName: t('Tabs.WorkflowTab'),
				icon: <SwapHorizIcon />,
				component: <WorkflowTab />,
				disabled: false,
			},
			{
				id: 3,
				tabName: t('Tabs.BusinessDataTab'),
				icon: <BusinessCenterIcon />,
				component: <BusinessData />,
				disabled: false,
			},
			{
				id: 4,
				tabName: t('Tabs.PrintTemplates'),
				icon: <PrintIcon />,
				component: <PrintTemplates />,
				disabled: false,
			},
		],
		[t]
	)
	return <PageNavigation tabs={tabs} />
}

export default Administration
