import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features/actions'
import { IAddress } from 'models'
import { useAddressService } from 'services'
import FormatAddress from 'utils/FormatAdress'

import { ADDRESS_FIELDS } from './data'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	updateList: (value: string) => void
	projectId: string
	addressFieldName: string
	addressLabelFieldName: string
}

const initialState = {
	City: '',
	PostalCode: '',
	Post: '',
	Street: '',
	StreetNumber: '',
	FlatNumber: '',
}

const AddAddressDialog = ({
	isOpen,
	setIsOpen,
	updateList,
	projectId,
	addressFieldName,
	addressLabelFieldName,
}: Props) => {
	const [newAddressData, setNewAddressData] = useState<IAddress>(initialState as IAddress)
	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { clearError, fieldsError, addAddress, showSuccessInfo } = useAddressService()
	const { updateDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()

	const { t } = useTranslation(['dictionaries', 'translation'])

	const handleCloseDialog = () => {
		setIsOpen(false)
		setNewAddressData(initialState as IAddress)
		setIsCreateButtonDisabled(true)
	}

	const handleAddressInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		isCreateButtonDisabled && setIsCreateButtonDisabled(false)

		const target = e.target

		const value = target.value
		const fieldName = target.name

		if (fieldsError.includes(fieldName)) {
			clearError(fieldName)
		}

		setNewAddressData((prev: any) => {
			return { ...prev, [fieldName]: value }
		})
	}

	const handleDataSave = async () => {
		if (newAddressData) {
			setButtonLoading(true)
			try {
				const response = await addAddress({
					...newAddressData,
					ProjectId: projectId,
				})
				if (response) {
					updateDocumentData([
						{ fieldName: addressFieldName, value: response.Id },
						{ fieldName: addressLabelFieldName, value: FormatAddress(response) },
					])
				}
				updateList('default')
				showSuccessInfo('saved')
				setIsCreateButtonDisabled(true)
				handleCloseDialog()
			} catch (err) {
				console.error(err)
			} finally {
				setButtonLoading(false)
				setIsSaveButtonDisabled(false)
			}
		}
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title}>
				{t('Addresses.AddAddressDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '2px' }}>
					{ADDRESS_FIELDS.map(el => (
						<Grid key={el} xs={12} sm={12} md={6} lg={6}>
							<TextField
								value={newAddressData[el as keyof IAddress]}
								name={el}
								label={t(`Addresses.${el}`)}
								error={fieldsError.includes(el)}
								onChange={handleAddressInputChange}
								fullWidth
							/>
						</Grid>
					))}
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							value={newAddressData?.PostalCode || ''}
							name="PostalCode"
							label={t('Addresses.PostalCode')}
							error={fieldsError.includes('PostalCode')}
							onChange={handleAddressInputChange}
							fullWidth
							inputProps={{ maxLength: 6 }}
							placeholder="00-000"
						/>
					</Grid>
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							value={newAddressData?.Post || ''}
							name="Post"
							label={t('Addresses.Post')}
							error={fieldsError.includes('Post')}
							onChange={handleAddressInputChange}
							fullWidth
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={handleCloseDialog}>
					{t('general.Cancel', { ns: 'translation' })}
				</Button>
				<LoadingButton
					variant="contained"
					disabled={isCreateButtonDisabled}
					color="success"
					onClick={handleDataSave}
					loading={buttonLoading}>
					<span>{t('general.Add', { ns: 'translation' })}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddAddressDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 25px 20px 25px',
	},
}
