import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { IEmailTemplate } from 'models'

import TemplatesList from './TemplatesList'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	setEmailSubject: (value: string) => void
	setDefaultEmailText: (value: string) => void
	setEmailText: (value: string) => void
	getDataFromHtml: (value: any) => string
	setIsSaveContentButtonDisabled: (value: boolean) => void
	templates: IEmailTemplate[]
	getTemplates: () => void
	templatesLoading: boolean
}

const TemplatesDialog = ({
	isOpen,
	setIsOpen,
	setEmailSubject,
	setDefaultEmailText,
	setEmailText,
	getDataFromHtml,
	setIsSaveContentButtonDisabled,
	templates,
	getTemplates,
	templatesLoading,
}: Props) => {
	const { t } = useTranslation()

	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
						{t('general.ChooseTemplate')}
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '80px',
				}}>
				{templatesLoading ? (
					<Loading fullScreen />
				) : templates.length ? (
					<TemplatesList
						templates={templates}
						setEmailSubject={setEmailSubject}
						setDefaultEmailText={setDefaultEmailText}
						setEmailText={setEmailText}
						getDataFromHtml={getDataFromHtml}
						setIsSaveContentButtonDisabled={setIsSaveContentButtonDisabled}
						handleClose={handleClose}
						getTemplates={getTemplates}
					/>
				) : (
					<NoData />
				)}
			</DialogContent>
		</Dialog>
	)
}

export default TemplatesDialog
