import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IOfferDraft, IOfferDraftEntry } from 'models'

const useQuotationRequestOfferDraft = () => {
	const { request, downloadFileRequest, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/quotationRequestOfferDraft'

	const getQuotationRequestOffer = useCallback(
		async (token: string) => {
			const response = await request(`${path}/${token}`, 'GET', null, { 'Content-Type': 'application/json' }, true)
			return response
		},
		[request]
	)

	const updateDeadlines = useCallback(
		async (token: string, data: IOfferDraft) => {
			const response = await request(`${path}/${token}`, 'PATCH', data)
			return response
		},
		[request]
	)

	const getEntries = useCallback(
		async (token: string) => {
			const response = await request(`${path}/entries/${token}`)
			return response
		},
		[request]
	)

	const updateEntry = useCallback(
		async (token: string, entryId: string, data: IOfferDraftEntry) => {
			const response = await request(`${path}/entries/${token}?entryId=${entryId}`, 'PATCH', data)
			return response
		},
		[request]
	)

	const getAttachments = useCallback(
		async (token: string) => {
			const response = await request(`${path}/attachments/${token}`)
			return response
		},
		[request]
	)

	const downloadAttachment = useCallback(
		async (token: string, attachmentId: string) => {
			const response = await downloadFileRequest(`${path}/attachment/${token}?attachmentId=${attachmentId}`, 'GET', {
				'Content-Type': 'application/octet-stream',
			})
			return response
		},
		[downloadFileRequest]
	)

	const submitOffer = useCallback(
		async (token: string, submitPass?: string) => {
			const submitPath = submitPass ? `${path}/${token}?submitPass=${submitPass}` : `${path}/${token}`

			const response = await request(submitPath, 'POST')
			return response
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getQuotationRequestOffer,
		updateDeadlines,
		getEntries,
		updateEntry,
		getAttachments,
		downloadAttachment,
		submitOffer,
	}
}

export default useQuotationRequestOfferDraft
