import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import HelpIcon from '@mui/icons-material/Help'
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { stateToHTML } from 'draft-js-export-html'
import { IEmailMarkup } from 'models'
import { useEmailParametersService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import Attachments from './Attachments'
import RichTextEditor from './RichTextEditor'

interface Props {
	emailSubject: string
	defaultEmailText: string
	setEmailSubject: (value: string) => void
	setIsSaveContentButtonDisabled: (value: boolean) => void
	backendHtmlEmailBody: string
	setEmailText: (value: string) => void
	attachments: any[]
	contextId: string
	workflowId: string
	updateAttachments: () => void
	readonly: boolean
	documentType: string
}

const EmailForm = ({
	emailSubject,
	defaultEmailText,
	setEmailSubject,
	setIsSaveContentButtonDisabled,
	backendHtmlEmailBody,
	setEmailText,
	attachments,
	contextId,
	workflowId,
	updateAttachments,
	readonly,
	documentType,
}: Props) => {
	const [markups, setMarkups] = useState<IEmailMarkup[]>([])

	const { t } = useTranslation()

	const { getEmailMarkups } = useEmailParametersService()

	const handleSubjectChange = (e: any) => {
		setEmailSubject(e.target.value)
		setIsSaveContentButtonDisabled(false)
	}

	const handleTextChange = (data: any) => {
		let html = stateToHTML(data.getCurrentContent())

		if (backendHtmlEmailBody && html) {
			const compareForChanges = html.localeCompare(backendHtmlEmailBody)
			if (compareForChanges) {
				setEmailText(html)
				setIsSaveContentButtonDisabled(false)
			}
		}
	}

	const getMarkupsData = useCallback(async () => {
		try {
			const response = await getEmailMarkups(documentType)

			setMarkups(response.Fields)
		} catch (err) {
			console.error(err)
		}
	}, [getEmailMarkups, documentType])

	useEffect(() => {
		getMarkupsData()
	}, [documentType])

	return (
		<Grid container rowSpacing={2} columnSpacing={2}>
			<Grid xs={12} sm={12} md={12} lg={12}>
				<TextField
					disabled={readonly}
					value={emailSubject || ''}
					label={t('EmailParametersTab.EmailSubject')}
					onChange={handleSubjectChange}
					fullWidth
				/>
			</Grid>
			<Grid xs={12} sm={12} md={12} lg={12}>
				<RichTextEditor
					readOnly={readonly}
					defaultEmailText={defaultEmailText}
					handleTextChange={handleTextChange}
					markups={markups}
				/>
			</Grid>
			{!readonly && (
				<Grid xs={12} sm={12} md={6} lg={6}>
					{markups.length > 0 && (
						<Box>
							<span>
								{t('EmailParametersTab.Tags.TagsUsage')}
								<Typography component="span" sx={{ paddingLeft: '5px', fontSize: '14px' }} color="primary">
									{t('EmailParametersTab.Tags.Tag')}
								</Typography>
								<Tooltip
									title={
										<img
											height="150px"
											width="280px"
											src={process.env.PUBLIC_URL + '/emailTextHelperImage.gif'}
											alt="use tags helper gif"
										/>
									}
									placement="top"
									arrow>
									<span>
										<IconButton sx={{ padding: '5px' }}>
											<HelpIcon fontSize="small" color="action" sx={{ width: '16px', height: '16px' }} />
										</IconButton>
									</span>
								</Tooltip>
							</span>
							<br />
							<span>
								{t('EmailParametersTab.Tags.Title')}
								<Tooltip
									title={<TooltipTextWrapper title={t('EmailParametersTab.Tags.TitleHelper')} />}
									placement="top"
									arrow>
									<span>
										<IconButton sx={{ padding: '5px' }}>
											<HelpIcon fontSize="small" color="action" sx={{ width: '16px', height: '16px' }} />
										</IconButton>
									</span>
								</Tooltip>
							</span>
							<ul>
								{markups.map((el: IEmailMarkup, index) => (
									<li key={index}>
										<span>
											{el.Key} -{' '}
											{t(`PrintTemplates.MarkupsDialog.Descriptions.${el.Key}`, {
												ns: 'administration',
												defaultValue: `${el.Description}`,
											})}
										</span>
									</li>
								))}
							</ul>
						</Box>
					)}
				</Grid>
			)}

			{attachments.length > 0 && (
				<Grid xs={12} sm={12} md={6} lg={6}>
					<Attachments
						attachments={attachments}
						contextId={contextId}
						workflowId={workflowId}
						updateAttachments={updateAttachments}
						readonly={readonly}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default EmailForm
