import { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { useAppSelector, useCalendarActions } from 'features'
import useLanguage from 'hooks/UseLanguge'
import { ICalendarEvent } from 'models'

import { EventPreview } from './events'

type Props = {
	day: Dayjs
	rowIndex: number
	indexInRow: number
}

const Day = ({ day, rowIndex, indexInRow }: Props) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const [numberOfEvents, setNumberOfEvents] = useState<number>(2)
	const { selectedDate, selectedMonthIndex, gridRowsCount, calendarEvents } = useAppSelector(state => state.calendar)

	const { setSelectedDate } = useCalendarActions()
	const { datePickerLanguage } = useLanguage()

	const formattedDay = day.format('DD-MM-YY')

	const shortMonthName = day.locale(datePickerLanguage).format('MMM')

	const formattedShortMonthName = shortMonthName.charAt(0).toUpperCase() + shortMonthName.slice(1)

	const isCurrentDay = formattedDay === dayjs().format('DD-MM-YY')

	const isInCurrentMonth = day.format('M') === dayjs().month(selectedMonthIndex).format('M')

	const isSelected = dayjs(selectedDate).format('DD-MM-YY') === formattedDay

	const isBeforeToday = dayjs().startOf('day').unix() < day.startOf('day').unix() + 1

	const handleSelectDay = () => {
		setSelectedDate(day.format())
	}

	const events = calendarEvents[day.format('YYYY-MM-DD')]

	useEffect(() => {
		const handleResize = () => {
			if (containerRef.current) {
				const divHeight = containerRef.current.clientHeight

				if (divHeight < 130 && events?.length === 2) {
					setNumberOfEvents(2)
				} else if (divHeight < 130) {
					setNumberOfEvents(1)
				} else if (divHeight < 150) {
					setNumberOfEvents(3)
				} else if (divHeight < 200) {
					setNumberOfEvents(4)
				} else {
					setNumberOfEvents(5)
				}
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [containerRef.current])

	return (
		<div
			ref={containerRef}
			className={clsx(
				'h-full flex flex-col cursor-pointer',
				'border-solid border-l-0 border-t-0 border-border-divider dark:border-border-darkDivider',
				{
					'border-r-0': indexInRow === 6,
					'border-b-0': rowIndex === gridRowsCount - 1,
					'bg-border-darkDivider/5 dark:bg-border-divider/5': !isBeforeToday && !isSelected,
					'bg-primary-main/10': isSelected,
				}
			)}
			onClick={handleSelectDay}>
			<div className={clsx('h-full flex flex-col items-center')}>
				{rowIndex === 0 && (
					<p className={clsx('text-sm m-1')}>{day.locale(datePickerLanguage).format('ddd').toUpperCase()}</p>
				)}
				<div
					className={clsx('w-full text-sm p-1.5 m-1 text-left', {
						'py-0': rowIndex === 0,
					})}>
					<div className={clsx('flex items-center', { 'text-gray-400 dark:text-gray-500': !isInCurrentMonth })}>
						<span
							className={clsx('flex justify-center items-center w-8 h-8 rounded-full', {
								'bg-primary-main text-text-darkPrimary': isCurrentDay,
								'bg-primary-main/50': !isInCurrentMonth && isCurrentDay,
								'w-16': day.format('D') === '1',
							})}>
							{day.format('D')}
						</span>
						<span className={clsx({ 'pl-1': isCurrentDay })}>{day.format('D') === '1' && formattedShortMonthName}</span>
					</div>
					{events ? (
						<div
							className={clsx('pt-2', {
								'text-gray-400 dark:text-gray-500': !isInCurrentMonth || !isBeforeToday,
							})}>
							{events.slice(0, numberOfEvents).map((event: ICalendarEvent, index) => (
								<EventPreview key={index} event={event} greyedOut={!isInCurrentMonth || !isBeforeToday} />
							))}
							{events.length > numberOfEvents + 1 ? (
								<p
									className={clsx(
										'flex justify-center items-center text-xs my-1 p-1 h-4 bg-primary-default/5 rounded-md'
									)}>
									<span>{`+ ${events.length - numberOfEvents}`}</span>
								</p>
							) : null}
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default Day
