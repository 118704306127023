import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { AppBar, Box, Dialog, DialogContent, IconButton, Tab, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import Loading from 'components/shared/loading/Loading'
import { IPermission } from 'models'
import { useRolesService } from 'services'

import { RoleDialogState } from '../Roles'
import RolePermissionGroups from './permissions/RolePermissionGroups'
import RoleGlobalUsers from './role-users/RoleGlobalUsers'
import RolePerProjectUsers from './role-users/RolePerProjectUsers'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	roleData: RoleDialogState
	setIsOpen: (value: RoleDialogState) => void
}

const RoleDialog = ({ roleData, setIsOpen }: Props) => {
	const [permissions, setPermissions] = useState<IPermission[]>([])
	const [groupNames, setGroupNames] = useState<string[]>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [selectedTab, setSelectedTab] = useState('0')

	const { isOpen, id, name, isPerProject, isAdmin } = roleData

	const { getRolePermissions } = useRolesService()

	const { t } = useTranslation(['administration', 'translation'])

	const handleCloseDialog = () => {
		setIsOpen({
			isOpen: false,
			id: '',
			name: '',
			isPerProject: false,
			isAdmin: false,
		})
	}

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('Roles.Role.Global'),
				renderValue: <RoleGlobalUsers roleId={id} />,
			},
			{
				label: t('Roles.Role.IsPerProject'),
				renderValue: <RolePerProjectUsers roleId={id} />,
			},
		],
		[t]
	)

	const getRolePermissionsList = useCallback(async () => {
		try {
			const response = await getRolePermissions(id)
			// filter permissions by type (per project or global), depending on IsPerProject value (if IsPerProject = true, type = per project, if false, type = global)
			const permissionsByType = response.filter((el: any) => {
				return el.IsPerProject === isPerProject
			})

			// Array of all groups that returned data contains
			const groups: string[] = []
			permissionsByType.map((el: any) => {
				return groups.push(el.Group) // Extracting of all groups that returned data contains
			})

			// Return array with unique group names
			const uniqueGroups = groups.filter((value: any, index: any, array: any) => array.indexOf(value) === index)

			setGroupNames(uniqueGroups)
			setPermissions(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [])

	useEffect(() => {
		getRolePermissionsList()
	}, [id])

	if (dataLoading) {
		return <Loading />
	}

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1, fontSize: '20px', fontWeight: '500' }} component="div">
						{name}
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '80px',
				}}>
				{!isAdmin && (
					<Box>
						<Typography
							sx={{
								mb: 2,
								ml: 2,
								flex: 1,
								fontSize: '18px',
								fontWeight: '500',
							}}
							component="div">
							{t('Roles.Role.Permissions')}
						</Typography>
						<RolePermissionGroups groupNames={groupNames} permissions={permissions} roleId={id} />
					</Box>
				)}
				<Box sx={{ width: '100%', mt: '25px' }}>
					<Typography
						sx={{
							mb: 2,
							ml: 2,
							flex: 1,
							fontSize: '18px',
							fontWeight: '500',
						}}
						component="div">
						{t('Roles.Role.Users')}
					</Typography>
					<TabContext value={selectedTab}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
								{tabs.map((el, index) => {
									return <Tab key={el.label} label={el.label} value={index.toString()} />
								})}
							</TabList>
						</Box>
						{tabs.map((el, index) => {
							return (
								<TabPanel sx={{ px: 0, py: '4px' }} key={index} value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						})}
					</TabContext>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default RoleDialog
