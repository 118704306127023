import { Dispatch, SetStateAction, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import InvestorBudgetForm from 'components/pages/investor-budget/InvestorBudgetForm'
import RealizationBudgetForm from 'components/pages/realization-budget/RealizationBudgetForm'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsFormDialogOpen: (value: boolean) => void
	data: any
	api: any
	rerender: Dispatch<SetStateAction<number>>
	budgetType: string
}

const BudgetFormDialog = ({ isOpen, setIsFormDialogOpen, data, api, rerender, budgetType }: Props) => {
	const [editedRowFormData, setEditedRowFormData] = useState<any>()
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['project', 'translation'])

	const saveChanges = async () => {
		try {
			setButtonLoading(true)
			await api.updateRow(data.id, editedRowFormData)
			api.showSuccessInfo('saved')
			rerender(prev => prev + 1)
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	useEffect(() => {
		setEditedRowFormData(data?.row)
	}, [data])

	if (data) {
		return (
			<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
				<AppBar color="default">
					<Toolbar>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							{t('BudgetTabGeneral.FormDialog.Title')} {data.row.NumberString}
						</Typography>
						<LoadingButton
							sx={{ mr: '25px' }}
							variant="contained"
							color="success"
							onClick={saveChanges}
							loading={buttonLoading}>
							{t('general.Save', { ns: 'translation' })}
						</LoadingButton>
						<IconButton edge="start" color="inherit" onClick={() => setIsFormDialogOpen(false)} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent
					sx={{
						bgcolor: 'background.default',
						pt: '80px',
					}}>
					{budgetType === 'realization' ? (
						<RealizationBudgetForm
							formData={data}
							editedRowFormData={editedRowFormData}
							setEditedRowFormData={setEditedRowFormData}
						/>
					) : (
						<InvestorBudgetForm
							formData={data}
							editedRowFormData={editedRowFormData}
							setEditedRowFormData={setEditedRowFormData}
						/>
					)}
				</DialogContent>
			</Dialog>
		)
	} else {
		return null
	}
}

export default BudgetFormDialog
