import {
	AddAdditionalCostDialog,
	CancelDocumentDialog,
	ChangeNumberDialog,
	ChangeStageDialog,
	CreateCorrectionInvoiceFromIncomingLetterDialog,
	CreateDocumentDialog,
	DeleteDocumentDialog,
	LinkDocumentDialog,
	PrintDocxDialog,
} from 'components/shared'
import { useAppSelector } from 'features'
import { useHandleDocumentActions } from 'hooks'
import { InvokeAction, InvokeActionResponse } from 'models'

import ActionsMenu from './ActionsMenu'

type Props = {
	invokeActionFunction: (data: InvokeAction) => Promise<InvokeActionResponse>
	getDataFunction: () => void
}

const DocumentActions = ({ invokeActionFunction, getDataFunction }: Props) => {
	const {
		selectedAction,
		isConfirmCancelDialogOpen,
		isChangeStageDialogOpen,
		isCreateDocumentDialogOpen,
		isLinkDocumentDialogOpen,
		isConfirmDeletingDialogOpen,
		isChangeNumberDialogOpen,
		isPrintDocxDialogOpen,
		isCreateCorrectionInvoiceDialogOpen,
		isAdditionalCostDialogOpen,
	} = useAppSelector(state => state.documentActionsState)

	const { handlePrepareAction, handleInvokeAction } = useHandleDocumentActions(invokeActionFunction, getDataFunction)

	return (
		<div className="min-w-12 flex justify-end items-center">
			<ActionsMenu handlePrepareAction={handlePrepareAction} />
			{isConfirmCancelDialogOpen && (
				<CancelDocumentDialog
					isOpen={isConfirmCancelDialogOpen}
					action={selectedAction}
					invokeAction={handleInvokeAction}
				/>
			)}
			{isChangeStageDialogOpen && (
				<ChangeStageDialog isOpen={isChangeStageDialogOpen} action={selectedAction} invokeAction={handleInvokeAction} />
			)}
			{isCreateDocumentDialogOpen && (
				<CreateDocumentDialog
					isOpen={isCreateDocumentDialogOpen}
					action={selectedAction}
					invokeAction={handleInvokeAction}
				/>
			)}
			{isLinkDocumentDialogOpen && (
				<LinkDocumentDialog
					isOpen={isLinkDocumentDialogOpen}
					action={selectedAction}
					invokeAction={handleInvokeAction}
				/>
			)}
			{isConfirmDeletingDialogOpen && (
				<DeleteDocumentDialog
					isOpen={isConfirmDeletingDialogOpen}
					action={selectedAction}
					invokeAction={handleInvokeAction}
				/>
			)}
			{isChangeNumberDialogOpen && (
				<ChangeNumberDialog
					isOpen={isChangeNumberDialogOpen}
					action={selectedAction}
					invokeAction={handleInvokeAction}
				/>
			)}
			{isPrintDocxDialogOpen && (
				<PrintDocxDialog isOpen={isPrintDocxDialogOpen} action={selectedAction} invokeAction={handleInvokeAction} />
			)}
			{isCreateCorrectionInvoiceDialogOpen && (
				<CreateCorrectionInvoiceFromIncomingLetterDialog
					isOpen={isCreateCorrectionInvoiceDialogOpen}
					action={selectedAction}
					invokeAction={handleInvokeAction}
				/>
			)}
			{isAdditionalCostDialogOpen && (
				<AddAdditionalCostDialog
					isOpen={isAdditionalCostDialogOpen}
					action={selectedAction}
					invokeAction={handleInvokeAction}
				/>
			)}
		</div>
	)
}

export default DocumentActions
