import { IComment } from 'models/IComment'
import { formatDateTime } from 'utils/formatDateTime'

const SingleComment = (props: IComment) => {
	return (
		<li className="mb-2 border-solid border-0 border-b border-border-primary dark:border-border-darkPrimary">
			<div>
				<span className="font-medium mr-2 text-primary-main">{props.UserFullName || ''}</span>
				<span className={props.UserFullName ? 'text-sm mr-2' : 'font-medium mr-2 text-primary-main'}>
					{props.UserLogin || ''}
				</span>
				<span className="text-xs text-text-secondary dark:text-text-darkSecondary">
					{formatDateTime(props.DateTime)}
				</span>
			</div>
			<div>
				<p>{props.Text}</p>
			</div>
		</li>
	)
}

export default SingleComment
