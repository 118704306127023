import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, TextField } from '@mui/material'

import { useAppSelector, useNotificationsActions } from 'features'
import { useHandleNavigate } from 'hooks'
import { INotification } from 'models'
import { useNotificationService } from 'services'
import { formatDateTime } from 'utils/formatDateTime'

import { usePrepareNotificationTranslation } from './utils'

type Props = {
	notification: INotification | undefined
}

const NotificationDetails = ({ notification }: Props) => {
	const [notes, setNotes] = useState('')

	const { allNotifications } = useAppSelector(state => state.notifications)

	const { getNotificationTranslation } = usePrepareNotificationTranslation()
	const { handleNavigate } = useHandleNavigate()
	const { updateNotification, showSuccessInfo } = useNotificationService()
	const { setAllNotifications } = useNotificationsActions()

	const { t } = useTranslation('notifications')

	const handleClickDocument = () => {
		if (notification?.ContextRootingPath)
			handleNavigate(notification, notification.ContextRootingPath, 'ContextItemIdLabel', 20, 'ContextItemId')
	}

	const handleUpdateNotification = async () => {
		if (notification)
			try {
				await updateNotification(notification.Id, { ...notification, Comment: notes })
				showSuccessInfo('saved')

				const updatedNotifications = allNotifications.map(el => {
					if (el.Id === notification.Id) {
						return {
							...el,
							Comment: notes,
						}
					}
					return el
				})

				setAllNotifications(updatedNotifications)
			} catch (err) {
				console.error(err)
			}
	}

	useEffect(() => {
		if (notification) setNotes(notification.Comment || '')
	}, [notification])

	if (!notification) return <div className="h-full flex items-center justify-center">{t('ChooseElementToRead')}</div>

	const translation = getNotificationTranslation(notification)
	const date = formatDateTime(notification.DateTime.toLocaleString())
	const contextName =
		notification.ContextRootingPath &&
		t(`${notification.ContextRootingPath.charAt(0).toUpperCase() + notification.ContextRootingPath.slice(1)}`, {
			ns: 'routes',
		}) +
			' ' +
			`${notification.ContextItemIdLabel || ''}`

	return (
		<div className="flex flex-col h-full p-6 bg-background-secondary dark:bg-background-darkDefault">
			<div className="flex w-full justify-between">
				<div className="w-3/4 text-xl">{translation}</div>
				<div className="w-1/4 text-right text-primary-main">{date}</div>
			</div>
			{notification.ContextRootingPath && (
				<div className="w-full py-6 text-base">
					{t('Document')}{' '}
					<span onClick={handleClickDocument} className="text-primary-main cursor-pointer hover:underline">
						{contextName}
					</span>
				</div>
			)}
			<div className="flex flex-col pt-4">
				<TextField
					value={notes}
					name="Comment"
					label={t('Comment')}
					onChange={e => setNotes(e.target.value)}
					multiline
					minRows={6}
					fullWidth
				/>
				<div className="flex justify-end mt-3">
					<Button variant="outlined" color="primary" onClick={handleUpdateNotification}>
						{t('general.Add', { ns: 'translation' })}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default NotificationDetails
