import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'Name',
			'NIP',
			'Email',
			'PhoneNumber',
			'SendDate',
			'OfferDate',
			'OfferDraftLastDate',
			'OfferNumber',
			'OfferDeadline',
			'OfferStatus',
			'OfferValue',
			'Comments',
			'actions',
		],
		dimensions: {
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 450,
			},
			NIP: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			Email: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			PhoneNumber: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			SendDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			OfferDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			OfferDraftLastDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			OfferNumber: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			OfferDeadline: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			OfferStatus: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			OfferValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Comments: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 270,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'demandQuotationContractorsEntriesDataGridState'
