import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IOrderEntry } from 'models'

const useOrderEntriesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/OrderEntry'

	const getOrderEntries = useCallback(
		async (orderId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&orderId=${orderId}`)
			return response
		},
		[request]
	)

	const addOrderEntry = useCallback(
		async (data: IOrderEntry) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateOrderEntry = useCallback(
		async (entryId: string, data: any) => {
			await request(`${path}/${entryId}`, 'PATCH', data)
		},
		[request]
	)

	const separateRemainig = useCallback(
		async (entryId: string, suffixOfName: string) => {
			await request(`${path}/separateRemaining/${entryId}?sufixOfName=${suffixOfName}`, 'PATCH')
		},
		[request]
	)

	const deleteOrderEntry = useCallback(
		async (entryId: string) => {
			const response = await request(`${path}/${entryId}`, 'DELETE')
			return response
		},
		[request]
	)

	const linkToBudgetOrderEntry = useCallback(
		async (entryId: string, budgetEntryNumber: string) => {
			await request(`${path}/${entryId}?budgetEntryNumber=${budgetEntryNumber}`, 'PATCH')
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getOrderEntries,
		addOrderEntry,
		deleteOrderEntry,
		updateOrderEntry,
		linkToBudgetOrderEntry,
		separateRemainig,
	}
}

export default useOrderEntriesService
