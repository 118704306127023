import { useTranslation } from 'react-i18next'

import { Stack, Tooltip } from '@mui/material'

import { enqueueSnackbar } from 'notistack'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

interface Props {
	text: string
	color?: string
	fontSize?: string
	tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
}

const CopyToClipboardButton = ({
	text,
	color = 'primary.main',
	fontSize = '14px',
	tooltipPlacement = 'top',
}: Props) => {
	const { t } = useTranslation()

	const handleCopyText = async () => {
		try {
			await navigator.clipboard.writeText(text)

			enqueueSnackbar(`${t('general.Copied')} ${text}`, {
				variant: 'info',
				autoHideDuration: 2000,
			})
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<Tooltip title={<TooltipTextWrapper title={t('general.Copy')} />} placement={tooltipPlacement} arrow>
			<Stack
				component="span"
				sx={{
					fontSize: fontSize,
					color: color,
					cursor: 'pointer',
					p: '0 5px',
					'&:hover': { textDecoration: 'underline' },
				}}
				onClick={handleCopyText}>
				{text}
			</Stack>
		</Tooltip>
	)
}

export default CopyToClipboardButton
