import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'Status',
			'Number',
			'CreatedDateTime',
			'ConsumptionDateTime',
			'ConsumptionDateTimeEnd',
			'DeliveryDateTime',
			'DemandCostType',
			'ProjectLabel',
			'StageId',
			'WorkflowIdLabel',
			'Notes',
			'OwnerIdLabel',
			'CreatorId',
			'IsCompleted',
			'IsCanceled',
			'actions',
		],
		dimensions: {
			Status: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
				flex: 0,
			},
			Number: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
				flex: 0,
			},
			CreatedDateTime: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			ConsumptionDateTime: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			ConsumptionDateTimeEnd: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			DeliveryDateTime: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			DemandCostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 190,
			},
			ProjectLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			StageId: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			WorkflowIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			Notes: {
				maxWidth: -1,
				minWidth: 50,
				width: 300,
			},
			OwnerIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			CreatorId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			IsCanceled: {
				maxWidth: -1,
				minWidth: 50,
				width: 180,
			},
			IsCompleted: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'demandsDataGridState'
