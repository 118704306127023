import { useEffect } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import CommentIcon from '@mui/icons-material/Comment'
import DescriptionIcon from '@mui/icons-material/Description'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'

import { Loading, ScheduleTab } from 'components/shared'
import Attachments from 'components/shared/attachments/Attachments'
import Discussion from 'components/shared/comments/Discussion'
import DocumentWorkflowTab from 'components/shared/document-workflow-tab/DocumentWorkflowTab'
import DocumentsTab from 'components/shared/documents-tab/DocumentsTab'
import PageNavigation from 'components/shared/page-navigation/PageNavigation'
import { useAppSelector, useDocumentDataActions } from 'features'
import { useFieldsPermissions } from 'hooks'
import { useContractService } from 'services'

import DebitNotes from '../debit-notes/DebitNotes'
import AdvanceInvoices from '../invoices/advance-invoices/AdvanceInvoices'
import CostInvoices from '../invoices/cost-invoices/CostInvoices'
import ProformaInvoices from '../invoices/proforma-invoices/ProformaInvoices'
import SalesInvoices from '../invoices/sales-invoices/SalesInvoices'
import ProcessingProtocols from '../processing-protocols/ProcessingProtocols'
import ContractBasicInfo from './basic-info-tab/ContractBasicInfo'
import CostParameters from './cost-parameters/CostParameters'
import ContractEntries from './entries/ContractEntries'

const Contract = () => {
	const { loading, documentData, visibleProperties, editableProperties } = useAppSelector(state => state.documentData)

	const { isEditable, isVisible } = useFieldsPermissions()

	const params = useParams()

	const {
		setDocumentId,
		setDocumentData,
		setActionsList,
		updateLoading,
		updateFormLoading,
		setEditableProperties,
		setVisibleProperties,
	} = useDocumentDataActions()

	const id = params.id as string

	const { getContract, getEditableProperties, getVisibleProperties, getActions, invokeAction, errorStatus } =
		useContractService()

	const { t } = useTranslation('contract')

	const getData = async () => {
		try {
			updateFormLoading(true)
			const data = await getContract(id)
			const actions = await getActions(id)
			const visiblePropertiesData = await getVisibleProperties(id)
			const editablePropertiesData = await getEditableProperties(id)

			setDocumentData(data)
			setActionsList(actions)
			setVisibleProperties(visiblePropertiesData)
			setEditableProperties(editablePropertiesData)
		} catch (err) {
			console.error(err)
		} finally {
			updateFormLoading(false)
			updateLoading(false)
		}
	}

	const documentTabData = [
		{
			label: t('ProcessingProtocols', { ns: 'navigation' }),
			renderValue: <ProcessingProtocols contractId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('CostInvoices', { ns: 'navigation' }),
			renderValue: <CostInvoices contractId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('AdvanceInvoices', { ns: 'navigation' }),
			renderValue: <AdvanceInvoices contractId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('ProformaInvoices', { ns: 'navigation' }),
			renderValue: <ProformaInvoices contractId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('DebitNotes', { ns: 'navigation' }),
			renderValue: <DebitNotes contractId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('SalesInvoices', { ns: 'navigation' }),
			renderValue: <SalesInvoices contractId={id} isTab />,
			isVisible: true,
		},
	]

	const tabs = useMemo(() => {
		if (documentData == null || editableProperties == null || visibleProperties == null) {
			return []
		}

		return [
			{
				id: 0,
				tabName: t('Tabs.BasicInformation'),
				icon: <TextSnippetIcon />,
				component: <ContractBasicInfo contractId={id} />,
				disabled: false,
			},
			{
				id: 1,
				tabName: t('Tabs.Positions'),
				icon: <WysiwygIcon />,
				component: <ContractEntries contractId={id} readOnly={!isEditable('Entries')} />,
				disabled: !isVisible('Entries'),
			},
			{
				id: 2,
				tabName: t('Tabs.CostParameters'),
				icon: <RequestQuoteIcon />,
				component: <CostParameters contractId={id} readOnly={!isEditable('CostParameters')} />,
				disabled: !isVisible('CostParameters'),
			},
			{
				id: 3,
				tabName: t('Tabs.Documents'),
				icon: <DescriptionIcon />,
				component: <DocumentsTab tabs={documentTabData} />,
				disabled: false,
			},
			{
				id: 4,
				tabName: t('Tabs.Schedule'),
				icon: <ViewTimelineOutlinedIcon />,
				component: <ScheduleTab documentType="contract" documentId={id} readOnly={!isEditable('Schedule')} />,
				disabled: !isVisible('Schedule'),
			},
			{
				id: 5,
				tabName: t('Tabs.Comments'),
				icon: <CommentIcon />,
				component: <Discussion discussionId={documentData?.DiscussionId} readOnly={!isEditable('DiscussionId')} />,
				disabled: !isVisible('DiscussionId'),
			},
			{
				id: 6,
				tabName: t('Tabs.Workflow'),
				icon: <PublishedWithChangesIcon />,
				component: (
					<DocumentWorkflowTab workflowId={documentData.WorkflowId as string} documentId={id} refreshData={getData} />
				),
				disabled: false,
			},
			{
				id: 7,
				tabName: t('Tabs.Attachments'),
				icon: <AttachFileIcon />,
				component: <Attachments contextId={id} />,
				disabled: false,
			},
		]
	}, [id, t, documentData])

	useEffect(() => {
		getData()
		setDocumentId(id)
	}, [id])

	if (loading) {
		return <Loading />
	}

	return (
		<PageNavigation
			tabs={tabs}
			invokeActionFunction={invokeAction}
			getDataFunction={getData}
			document
			errorStatus={errorStatus}
		/>
	)
}

export default Contract
