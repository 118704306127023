import { useNavigate } from 'react-router-dom'

import { Box, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { INavigationItem } from 'models'

interface Props {
	item: INavigationItem
}

const NavigationItem = ({ item }: Props) => {
	const { text, linkPath, icon } = item

	const navigate = useNavigate()

	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('lg'))

	const handleItemClick = () => {
		navigate(linkPath as string)
	}

	return (
		<Box sx={laptopViewAndUp ? sxStyles.itemContainer : sxStyles.itemTabletViewContainer}>
			<Typography component={'span'} onClick={handleItemClick} sx={sxStyles.item}>
				{icon} {laptopViewAndUp && text}
			</Typography>
		</Box>
	)
}

export default NavigationItem

const sxStyles = {
	item: {
		display: 'flex',
		alignItems: 'center',
		textTransform: 'uppercase',
		cursor: 'pointer',
		fontSize: '14px',
		fontWeight: '500',
		'&:hover': {
			color: 'primary.main',
		},
	},
	itemContainer: {
		padding: '0 20px',
	},
	itemTabletViewContainer: {
		padding: '0 3px 0 3px',
	},
}
