import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab'
import { Box, Divider, IconButton, Stack, Tab, Tabs, Tooltip } from '@mui/material'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import Viewer from './Viewer'

const AttachmentsPreview = () => {
	const { t } = useTranslation('attachments')

	const {
		attachmentsPreviewWidthState,
		attachmentsPreviewSide,
		updateSelectedFile,
		updateIsOpen,
		selectedFile,
		updateWidthState,
		removeFile,
		clearFiles,
		files,
	} = useContext(AttachmentsPreviewContext)

	const handleSelectFile = (event: React.SyntheticEvent, newValue: number) => {
		updateSelectedFile(newValue)
	}

	const handleChangeDrawerWidth = (size: string) => {
		if (size === 'preview') {
			updateWidthState('46px')
		} else if (size === 'full') {
			updateWidthState('fullWidth')
		}
	}

	const handleRemoveTab = (e: any, el: any) => {
		e.stopPropagation()
		const index = files.findIndex((item: any) => item.id === el.id)
		if (index === selectedFile && index > 0) {
			updateSelectedFile(index - 1)
		}

		if (index < selectedFile) {
			updateSelectedFile(selectedFile - 1)
		}

		removeFile(el.id)
	}

	return (
		<>
			{attachmentsPreviewWidthState === 'fullWidth' ? (
				<Box
					sx={{
						...sxStyles.mainContainer,
						...(attachmentsPreviewSide === 'right' ? sxStyles.rightSide : sxStyles.leftSide),
					}}>
					<Box
						sx={{
							...sxStyles.navigation,
							flexDirection: attachmentsPreviewSide === 'right' ? 'row' : 'row-reverse',
						}}>
						<Box
							sx={{
								...sxStyles.navigationButtonContainer,
								flexDirection: attachmentsPreviewSide === 'right' ? 'row' : 'row-reverse',
							}}>
							<Tooltip title={<TooltipTextWrapper title={t('Collapse')} />} placement="left" arrow>
								<Stack
									component="span"
									sx={{
										pl: attachmentsPreviewSide === 'left' ? '10px' : 0,
										pr: attachmentsPreviewSide === 'right' ? '10px' : 0,
									}}>
									<IconButton
										sx={sxStyles.navigationButton}
										color="inherit"
										onClick={() => handleChangeDrawerWidth('preview')}>
										<KeyboardTabIcon
											sx={{
												transform: attachmentsPreviewSide === 'right' ? 'none' : 'rotate(180deg)',
											}}
											fontSize="small"
										/>
									</IconButton>
								</Stack>
							</Tooltip>
							<Divider
								sx={{
									borderColor: 'border.primary',
								}}
								orientation="vertical"
								variant="middle"
								flexItem
							/>
						</Box>
						{files.length > 0 && (
							<Tabs
								value={selectedFile}
								onChange={handleSelectFile}
								variant="scrollable"
								scrollButtons="auto"
								allowScrollButtonsMobile
								aria-label="scrollable force tabs example">
								{files.map(el => {
									if (files.length > 1) {
										return (
											<Tab
												key={el.id}
												sx={sxStyles.tab}
												icon={
													<Stack component="span" onClick={e => handleRemoveTab(e, el)}>
														<CloseIcon sx={{ fontSize: '15px' }} />
													</Stack>
												}
												iconPosition="end"
												label={el.fileName}
											/>
										)
									} else {
										return <Tab key={el.id} sx={sxStyles.tab} label={el.fileName} />
									}
								})}
							</Tabs>
						)}
						<Box
							sx={{
								...sxStyles.navigationButtonContainer,
								flexDirection: attachmentsPreviewSide === 'right' ? 'row' : 'row-reverse',
							}}>
							<Divider
								sx={{
									borderColor: 'border.primary',
								}}
								orientation="vertical"
								variant="middle"
								flexItem
							/>
							<Tooltip title={<TooltipTextWrapper title={t('Close')} />} placement="left" arrow>
								<Stack
									component="span"
									sx={{
										pl: attachmentsPreviewSide === 'right' ? '10px' : 0,
										pr: attachmentsPreviewSide === 'left' ? '10px' : 0,
									}}>
									<IconButton
										sx={sxStyles.navigationButton}
										color="inherit"
										onClick={() => {
											updateIsOpen(false)
											clearFiles()
										}}>
										<CloseIcon fontSize="medium" />
									</IconButton>
								</Stack>
							</Tooltip>
						</Box>
					</Box>
					<Box sx={{ overflow: 'auto' }}>
						<Viewer file={files[selectedFile]} />
					</Box>
				</Box>
			) : (
				<Box onClick={() => handleChangeDrawerWidth('full')} sx={sxStyles.cropedViewContainer}>
					<AttachFileIcon fontSize="medium" />
				</Box>
			)}
		</>
	)
}

export default AttachmentsPreview

const sxStyles = {
	mainContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100vh - 49px)',
		backgroundColor: 'background.secondary',
	},
	leftSide: { borderRight: '1px solid', borderColor: 'border.primary' },
	rightSide: { borderLeft: '1px solid', borderColor: 'border.primary' },
	navigation: {
		width: '100%',
		minHeight: '47.5px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		p: '0 10px',
		borderBottom: '1px solid',
		borderColor: 'border.primary',
	},
	cropedViewContainer: {
		width: '46px',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderLeft: '1px solid',
		borderColor: 'border.primary',
		backgroundColor: 'background.secondary',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'background.default',
		},
	},
	navigationButtonContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	navigationButton: {
		padding: '8px',
	},
	tab: {
		minHeight: '47.5px',
		height: '47.5px',
		textTransform: 'none',
		margin: '0 3px',
		padding: '12px 5px',
	},
}
