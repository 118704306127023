import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IBalancingDocument, IInvokeAction } from 'models'
import { BALANCING_DOCUMENT_ENDPOINT } from 'services/data'

export interface getBalancingDocumentListProps {
	isCanceled: boolean
	isCompleted: boolean
	projectId?: string
	budgetEntryId?: string
	data?: GridInitialState
}

export interface createBalancingDocumentProps {
	BudgetEntries: string[]
}

const useBalancingDocumentService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const getFilteredBalancingDocuments = useCallback(
		async ({ isCanceled, isCompleted, projectId, budgetEntryId, data }: getBalancingDocumentListProps) => {
			let getListPath = `${BALANCING_DOCUMENT_ENDPOINT}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${BALANCING_DOCUMENT_ENDPOINT}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (budgetEntryId) {
				getListPath = `${BALANCING_DOCUMENT_ENDPOINT}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getBalancingDocuments = useCallback(
		async ({ isCanceled, isCompleted, projectId }: getBalancingDocumentListProps) => {
			let getListPath = `${BALANCING_DOCUMENT_ENDPOINT}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${BALANCING_DOCUMENT_ENDPOINT}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getBalancingDocument = useCallback(
		async (id: string) => {
			const response = await request(`${BALANCING_DOCUMENT_ENDPOINT}/${id}`)
			return response
		},
		[request]
	)

	const createBalancingDocument = useCallback(
		async (workflowId: string, projectId: string, budgetEntries: string[] = []) => {
			const response = await request(`${BALANCING_DOCUMENT_ENDPOINT}`, 'POST', {
				WorkflowId: workflowId,
				ProjectId: projectId,
				BudgetEntries: budgetEntries,
			})
			return response
		},
		[request]
	)

	const updateBalancingDocument = useCallback(
		async (id: string, data: IBalancingDocument) => {
			await request(`${BALANCING_DOCUMENT_ENDPOINT}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${BALANCING_DOCUMENT_ENDPOINT}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${BALANCING_DOCUMENT_ENDPOINT}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${BALANCING_DOCUMENT_ENDPOINT}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${BALANCING_DOCUMENT_ENDPOINT}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getFilteredBalancingDocuments,
		getBalancingDocuments,
		getBalancingDocument,
		createBalancingDocument,
		updateBalancingDocument,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
	}
}

export default useBalancingDocumentService
