export const costInvoiceArticleTypes: Array<{
	label: string
	value: string
}> = [
	{ value: 'Article', label: 'Article' },
	{ value: 'Other', label: 'Other' },
	{ value: 'Assets', label: 'Assets' },
	{ value: 'Services', label: 'Services' },
	{ value: 'Transport', label: 'Transport' },
	{ value: 'RealEstate', label: 'RealEstate' },
	{ value: 'Fuel', label: 'Fuel' },
]
