import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'

import NoData from 'components/shared/no-data-message/NoData'
import { ITaskDetails } from 'models'

import { sxSyncStyles } from '../utils'
import SyncTaskDetailsTable from './SyncTaskDetailsTable'

interface TaskDetailsDialogProps {
	isOpen: boolean
	handleCloseDialog: () => void
	taskDetails: ITaskDetails | null
}

const SyncDialogDetails = ({ isOpen, handleCloseDialog, taskDetails }: TaskDetailsDialogProps) => {
	const { t } = useTranslation(['synchronization', 'translation'])

	return (
		<Dialog
			open={isOpen}
			onClose={handleCloseDialog}
			fullWidth
			maxWidth="xl"
			PaperProps={{
				style: {
					maxHeight: '90vh',
				},
			}}>
			<DialogTitle sx={{ ...sxSyncStyles.title, bgcolor: 'background.secondary' }} component="div">
				{t('Other.TaskDetails')}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={{ ...sxSyncStyles.content, bgcolor: 'background.secondary' }}>
				{taskDetails ? <SyncTaskDetailsTable taskDetails={taskDetails} /> : <NoData />}
			</DialogContent>
			<DialogActions sx={{ ...sxSyncStyles.actionsContainer, bgcolor: 'background.secondary' }}>
				<Button variant="contained" color="success" onClick={handleCloseDialog}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SyncDialogDetails
