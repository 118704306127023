import { Skeleton } from '@mui/material'

import clsx from 'clsx'

import FormSkeletonLoading from './FormSkeletonLoading'

const DocumentSkeletonLoading = () => {
	return (
		<>
			<div className="w-full flex justify-between items-center">
				<div className="w-3/4 flex">
					{Array.from({ length: 5 }).map((el, index) => (
						<Skeleton
							key={index}
							className={clsx('h-12 w-44 rounded-2xl my-1 mr-4')}
							sx={{ bgcolor: 'background.secondary' }}
							variant="rounded"
							animation="wave"
						/>
					))}
				</div>
				<div className="w-1/4 flex justify-end mr-2">
					<Skeleton
						className={clsx('h-8 w-4 rounded-2xl')}
						sx={{ bgcolor: 'background.secondary' }}
						variant="rounded"
						animation="wave"
					/>
				</div>
			</div>
			<div
				className={clsx(
					'h-full min-h-[calc(100vh-245px)] my-2',
					'border border-border-primary dark:border-border-darkPrimary border-solid rounded'
				)}>
				<FormSkeletonLoading />
			</div>
		</>
	)
}

export default DocumentSkeletonLoading
