import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useCorrectionInvoiceSettlementService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	const path = '/api/correctionInvoiceSettlement'

	const getSettlements = useCallback(
		async (invoiceId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&invoiceId=${invoiceId}`)
			return response
		},
		[request]
	)

	const updateSettlement = useCallback(
		async (settlementId: string, data: any) => {
			await request(`${path}/${settlementId}`, 'PATCH', data)
		},
		[request]
	)

	return { request, error, clearError, showSuccessInfo, getSettlements, updateSettlement }
}

export default useCorrectionInvoiceSettlementService
