import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useRootingService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const getAllowedRootings = useCallback(async () => {
		const response = await request(`/api/allowedRootings`)
		return response
	}, [])

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getAllowedRootings,
	}
}

export default useRootingService
