import { useTranslation } from 'react-i18next'

import { Box, Switch, Typography } from '@mui/material'

import { IStageActionsPermission } from 'models'

interface Props {
	permission: IStageActionsPermission
	handleDataChange: (value: any) => void
}

const SinglePermission = ({ permission, handleDataChange }: Props) => {
	const { t } = useTranslation(['administration', 'translation'])

	const { Key, Value } = permission

	return (
		<Box sx={sxStyles.container}>
			<Box sx={sxStyles.container}>
				<Typography sx={sxStyles.key}>
					{t(`Workflow.Permissions.RolePermissions.Stages.ActionPermissions.Actions.${Key}`, { defaultValue: Key })}
				</Typography>
			</Box>
			<Switch size="small" color="primary" checked={Value} name={Key} onChange={handleDataChange} />
		</Box>
	)
}

export default SinglePermission

const sxStyles = {
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	key: {
		fontSize: '14px',
		pr: '10px',
	},
}
