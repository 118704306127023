import { useCallback, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'

import AddUser from './AddUser'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	isActionButtonDisabled: boolean
	getUsersList: () => void
}

const UsersToolbar = ({ apiRef, isActionButtonDisabled, getUsersList }: Props) => {
	const [isUserDialogVisible, setIsUserDialogVisible] = useState<boolean>(false)

	const { handleSaveStateSnapshot } = useDataGridState()

	const handleCloseDialog = useCallback(() => {
		setIsUserDialogVisible(false)
	}, [])

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer className="justify-end m-1">
			<TooltipButton
				title="general.ResetDataGridState"
				onClick={() => handleUpdateState(true)}
				IconComponent={RotateLeftIcon}
				fontSize="medium"
			/>
			<TooltipButton
				title="general.SaveDataGridState"
				onClick={() => handleUpdateState()}
				IconComponent={FactCheckOutlinedIcon}
				fontSize="medium"
			/>
			<TooltipButton title="general.Filter" onClick={handleFilter} IconComponent={FilterListIcon} fontSize="medium" />
			<TooltipButton
				title="general.Add"
				onClick={() => setIsUserDialogVisible(true)}
				IconComponent={AddIcon}
				disabled={isActionButtonDisabled}
				fontSize="medium"
			/>
			{isUserDialogVisible && (
				<AddUser isOpen={isUserDialogVisible} setIsOpen={handleCloseDialog} updateUsersList={getUsersList} />
			)}
		</GridToolbarContainer>
	)
}

export default UsersToolbar
