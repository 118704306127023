import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PasswordIcon from '@mui/icons-material/Password'
import SaveIcon from '@mui/icons-material/Save'
import IconButton from '@mui/material/IconButton'
import {
	DataGridPremium,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import NoData from 'components/shared/no-data-message/NoData'
import { useHandleRowClick } from 'hooks'
import useDataGridState from 'hooks/UseDataGridState'
import useLanguage from 'hooks/UseLanguge'
import { IUser } from 'models'
import { useSuperLogin, useUsersService } from 'services'

import UsersToolbar from './UsersToolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

const Users = () => {
	const [usersData, setUsersData] = useState<IUser[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [dataLoading, setDataLoading] = useState(true)

	const { t } = useTranslation(['administration', 'translation'])

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()
	const { dataGridLanguage } = useLanguage()
	const { getUsers, addUser, updateUser, deleteUser, showSuccessInfo } = useUsersService()
	const { superLogin } = useSuperLogin()

	const apiRef = useGridApiRef()

	const { handleRowClick } = useHandleRowClick()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleEditClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(true)
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = usersData.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setUsersData(usersData.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteUser(id as string)
			setUsersData(usersData.filter((row: any) => row.Id !== id))
			showSuccessInfo('userDeleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleSuperLogin = (id: GridRowId) => async () => {
		try {
			await superLogin(id as string)
		} catch (err) {
			console.error(err)
		}
	}

	const processRowUpdate = async (newRow: GridRowModel) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addUser({
					...newRow,
				} as IUser)
				createdRow = response
				setUsersData(usersData.map((row: any) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('userAdded')
				getUsersList()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updateUser(newRow.Id, newRow as IUser)
				setUsersData(usersData.map((row: any) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getUsersList()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Login',
			headerName: t('Users.User.Login'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'FirstName',
			headerName: t('Users.User.FirstName'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: true,
		},
		{
			field: 'LastName',
			headerName: t('Users.User.LastName'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: true,
		},
		{
			field: 'EMail',
			headerName: t('Users.User.EMail'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: true,
		},
		{
			field: 'Telephone',
			headerName: t('Users.User.Telephone'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			align: 'right',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="general.Edit"
							onClick={handleEditClick(params.id)}
							IconComponent={EditIcon}
							disabled={isActionButtonDisabled}
						/>,
						<TooltipButton
							title="general.Remove"
							onClick={handleDeleteClick(params.id)}
							IconComponent={DeleteIcon}
							disabled={isActionButtonDisabled}
						/>,
						<IconButton className="p-1" disabled={isActionButtonDisabled} onClick={handleSuperLogin(params.id)}>
							<PasswordIcon fontSize="small" color={'primary'} />
						</IconButton>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getUsersList = useCallback(async () => {
		try {
			const response = await getUsers()
			setUsersData(response)
		} catch (err) {
			console.error(err)
		}
		setDataLoading(false)
	}, [getUsers])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getUsersList()
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState, apiRef])

	return (
		<DataGridPremium
			apiRef={apiRef}
			editMode="row"
			columns={orderedColumns}
			rows={usersData}
			getRowId={row => row.Id}
			onRowClick={params => handleRowClick(params, '/user', 'Login')}
			loading={dataLoading || dataGridStateLoading}
			onRowEditStart={handleRowEditStart}
			onRowEditStop={handleRowEditStop}
			processRowUpdate={processRowUpdate}
			rowModesModel={rowModesModel}
			onRowModesModelChange={newModel => setRowModesModel(newModel)}
			experimentalFeatures={{ newEditingApi: true }}
			sx={{ minHeight: '500px', height: 'calc(100vh - 230px)', border: 'none' }}
			components={{
				Toolbar: UsersToolbar,
				NoRowsOverlay: () => <NoData />,
			}}
			componentsProps={{
				toolbar: {
					apiRef,
					isActionButtonDisabled,
					getUsersList,
				},
			}}
			initialState={INITIALSTATE}
			rowHeight={35}
			localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
		/>
	)
}

export default Users
