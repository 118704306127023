import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import { Box, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { IChangeHistory } from 'models'
import { formatDateTime } from 'utils/formatDateTime'

import ListItemDetails from './ListItemDetails'

interface Props {
	data: IChangeHistory[]
	translationFile: string
	translationPath: string
}

const ChangeHistoryList = ({ data, translationFile, translationPath }: Props) => {
	const [expanded, setExpanded] = useState<string | false>(false)

	const { t } = useTranslation(['translation', translationFile])

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false)
	}

	const getColorByChangeType = (changeType: string) => {
		switch (changeType) {
			case 'Created':
				return 'info.main'
			case 'Updated':
				return 'success.main'
			case 'Deleted':
				return 'error.main'
			default:
				return 'inherit'
		}
	}

	const getIconByChangeType = (changeType: string) => {
		switch (changeType) {
			case 'Created':
				return <SaveAsIcon sx={sxStyles.icon} color="info" />
			case 'Updated':
				return <ChangeCircleIcon sx={sxStyles.icon} color="success" />
			case 'Deleted':
				return <CancelIcon sx={sxStyles.icon} color="error" />
			default:
				return <FiberManualRecordIcon sx={sxStyles.icon} color="action" />
		}
	}

	return (
		<>
			{data.map((el, index) => {
				return (
					<Accordion key={index} expanded={expanded === `group-${index}`} onChange={handleChange(`group-${index}`)}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} id={`group-${index}-header`}>
							<Box sx={sxStyles.summaryContainer}>
								<Typography
									component={'span'}
									sx={{
										...sxStyles.typography,
										display: 'flex',
										alignItems: 'center',
									}}
									color={getColorByChangeType(el.ChangeType)}>
									{getIconByChangeType(el.ChangeType)}
									{t(`ChangeHistory.ChangeTypeEnums.${el.ChangeType}`)}
								</Typography>
								<span>/</span>
								<Typography component={'span'} sx={sxStyles.typography} color="primary">
									{el.UserName || t('general.System')}
								</Typography>
								<span>/</span>
								<Typography
									component={'span'}
									sx={{
										...sxStyles.typography,
										color: 'text.secondary',
										fontSize: '14px',
									}}
									color="inherit">
									{formatDateTime(el.DateTime)}
								</Typography>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<ListItemDetails data={el.Fields} translationFile={translationFile} translationPath={translationPath} />
						</AccordionDetails>
					</Accordion>
				)
			})}
		</>
	)
}

export default ChangeHistoryList

const sxStyles = {
	summaryContainer: {
		maxWidth: '1000px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		pr: '60px',
	},
	typography: {
		p: '0 10px',
		fontSize: '15px',
	},
	icon: {
		fontSize: '16px',
		mr: '5px',
	},
}
