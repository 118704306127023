import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { authApi as AuthApi, IAuthApi } from 'apis'
import Loading from 'components/shared/loading/Loading'
import { useBusinessDataActions } from 'features'
import { IUser } from 'models'
import { useBusinessDataService } from 'services'

interface IAuthContextData {
	user: IUser | null
	loading: boolean
	authenticate(userName: string, password: string, rememberMe: boolean): Promise<void>
	logout(): void
	refreshUser(): void
	error: boolean
	clearError: () => void
}

const AuthContext = createContext<IAuthContextData>({
	user: null,
	loading: true,
	authenticate: async () => {},
	logout: () => {},
	refreshUser: () => {},
	error: false,
	clearError: () => {},
})

const useAuth = () => useContext(AuthContext) as IAuthContextData

interface IAuthProviderProps {
	children: JSX.Element
}

const AuthProvider = ({ children }: IAuthProviderProps) => {
	const [authApi] = useState<IAuthApi>(AuthApi)
	const [user, setUser] = useState<IUser | null>(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(false)

	const { setBusinessData } = useBusinessDataActions()
	const { getBusinessData } = useBusinessDataService()

	const authenticate = useCallback(
		async (login: string, password: string, rememberMe: boolean): Promise<void> => {
			try {
				await authApi.Login(login, password, rememberMe)
				setUser(await authApi.Me())
			} catch {
				setError(true)
			}
		},
		[authApi]
	)

	const logout = useCallback(async () => {
		try {
			await authApi.Logout()
		} catch {
			console.error('Wystąpił błąd podczas połączenia', 'error', 3000)
		}
		setUser(null)
	}, [authApi])

	const refreshUser = useCallback(async () => {
		try {
			setUser(await authApi.Me())
		} catch {
			console.error('Wystąpił błąd podczas połączenia', 'error', 3000)
		}
	}, [authApi])

	const fetchAndSetBusinessData = useCallback(async () => {
		try {
			const data = await getBusinessData()
			setBusinessData(data)
		} catch (error) {
			console.error('Wystąpił błąd podczas pobierania danych biznesowych', error)
		}
	}, [getBusinessData, setBusinessData])

	const clearError = useCallback(() => {
		setError(false)
	}, [])

	useEffect(() => {
		refreshUser().then(() => {
			setLoading(false)
		})
	}, [refreshUser])

	useEffect(() => {
		fetchAndSetBusinessData()
	}, [fetchAndSetBusinessData])

	if (loading) return <Loading fullScreen />

	return (
		<AuthContext.Provider
			value={{
				user,
				loading,
				authenticate,
				logout,
				refreshUser,
				error,
				clearError,
			}}>
			{children}
		</AuthContext.Provider>
	)
}

export { AuthProvider, useAuth }
