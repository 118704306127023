import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IVatRate } from 'models'

const useVatRateService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/VatRate'
	const odataPath = '/odata/VatRate'

	const getVatRate = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const addVatRate = useCallback(
		async (data: IVatRate) => {
			const response = await request(`${odataPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const updateVatRate = useCallback(
		async (id: string, body: IVatRate) => {
			await request(`${odataPath}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deleteVatRate = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getVatRate,
		addVatRate,
		updateVatRate,
		deleteVatRate,
	}
}

export default useVatRateService
