import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, List, ListItem, ListItemText, Typography } from '@mui/material'

import { useContractorsService } from 'services'

import AddContactInfoDialog from './AddContactInfoDialog'
import EditContactInfoDialog from './EditContactInfoDialog'

interface Props {
	data: any
	contractorId: string
	getContacts?: () => void
}

const ContractorsContactInfo = ({ data, contractorId, getContacts }: Props) => {
	const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false)
	const [isEditContactDialogOpen, setIsEditContactDialogOpen] = useState(false)
	const [dataToEdit, setDataToEdit] = useState()

	const { t } = useTranslation(['dictionaries', 'translation'])

	const { deleteContractorContactInfo, showSuccessInfo } = useContractorsService()

	const handleEditContact = (el: any) => {
		setDataToEdit(el)
		setIsEditContactDialogOpen(true)
	}

	const handleDeleteContact = async (id: string) => {
		try {
			await deleteContractorContactInfo(id)
			getContacts && getContacts()
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<>
			<List
				sx={{
					position: 'relative',
					width: '100%',
					padding: '0',
				}}>
				<Typography
					component={'span'}
					fontSize={'12px'}
					sx={{
						marginLeft: '16px',
						padding: '0 5px',
						position: 'absolute',
						top: '-9px',
						backgroundColor: 'background.default',
						color: 'text.secondary',
						zIndex: '1',
					}}>
					{t('Contractor.ContactInfoPerson.Title')}
				</Typography>
				<ListItem
					sx={{
						border: 1,
						borderColor: 'action.disabled',
						borderRadius: '4px 4px 0 0',
						color: 'text.secondary',
					}}
					secondaryAction={
						<IconButton edge="end" size="small" aria-label="add" onClick={() => setIsAddContactDialogOpen(true)}>
							<AddIcon color="primary" />
						</IconButton>
					}>
					<ListItemText
						sx={{
							textAlign: 'left',
							width: '20%',
						}}
						primaryTypographyProps={{ fontSize: '14px' }}
						primary={t('Contractor.ContactInfoPerson.Name')}
					/>
					<ListItemText
						sx={{
							textAlign: 'left',
							width: '20%',
						}}
						primaryTypographyProps={{ fontSize: '14px' }}
						primary={t('Contractor.ContactInfoPerson.Email')}
					/>
					<ListItemText
						sx={{
							textAlign: 'left',
							width: '20%',
						}}
						primaryTypographyProps={{ fontSize: '14px' }}
						primary={t('Contractor.ContactInfoPerson.PhoneNumber')}
					/>
					<ListItemText
						sx={{
							textAlign: 'left',
							width: '20%',
						}}
						primaryTypographyProps={{ fontSize: '14px' }}
						primary={t('Contractor.ContactInfoPerson.Comments')}
					/>
				</ListItem>
				{data.length ? (
					data.map((el: any, index: number) => {
						return (
							<ListItem
								sx={{
									border: 1,
									borderTop: 'none',
									borderColor: 'action.disabled',
									'&:last-child': {
										borderRadius: '0 0 4px 4px',
									},
								}}
								key={index}
								secondaryAction={
									<>
										<IconButton edge="end" size="small" aria-label="edit" onClick={() => handleEditContact(el)}>
											<EditIcon color="primary" />
										</IconButton>
										<IconButton edge="end" size="small" aria-label="delete" onClick={() => handleDeleteContact(el.Id)}>
											<DeleteIcon color="primary" />
										</IconButton>
									</>
								}>
								<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.Name || ''} />
								<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.Email || ''} />
								<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.PhoneNumber || ''} />
								<ListItemText sx={{ textAlign: 'left', width: '20%' }} primary={el.Comments || ''} />
							</ListItem>
						)
					})
				) : (
					<ListItem
						sx={{
							border: '1px solid',
							borderTop: 'none',
							borderColor: 'action.disabled',
							borderRadius: '0 0 4px 4px',
						}}>
						<ListItemText sx={{ textAlign: 'center', width: '100%' }} primary={`Brak Danych`} />
					</ListItem>
				)}
			</List>
			<AddContactInfoDialog
				isOpen={isAddContactDialogOpen}
				setIsOpen={setIsAddContactDialogOpen}
				getContacts={getContacts}
				contractorId={contractorId}
			/>
			<EditContactInfoDialog
				isOpen={isEditContactDialogOpen}
				setIsOpen={setIsEditContactDialogOpen}
				getContacts={getContacts}
				data={dataToEdit}
			/>
		</>
	)
}

export default ContractorsContactInfo
