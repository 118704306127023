import { useTranslation } from 'react-i18next'

import { Box, InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { InputHint } from 'components/shared'
import { IProjectModel } from 'models'
import { formatDateTimeMUI } from 'utils/formatDateTime'

import Warranty from './Warranty'

interface Props {
	projectId: string
	formData?: IProjectModel | null
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
	readOnly: boolean
	reloadFormData?: () => void
	reloadWarrantyData?: number
}

const KeyTerms = ({
	projectId,
	formData,
	onInputChange,
	fieldErrorsList,
	readOnly,
	reloadFormData,
	reloadWarrantyData,
}: Props) => {
	const { t } = useTranslation('project')

	return (
		<Box>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* Realization Date Start */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 1, sm: 1, md: 1 }}>
					<TextField
						disabled={readOnly}
						value={formatDateTimeMUI(formData?.RealizationDateStart) || ''}
						id="project-field-RealizationDateStart"
						name="RealizationDateStart"
						label={t('ProjectFields.RealizationDateStart')}
						InputLabelProps={{ shrink: true }}
						type="date"
						error={fieldErrorsList.includes('RealizationDateStart')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.RealizationDateStart" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* Construction Site Taking Date */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 3, sm: 2, md: 2 }}>
					<TextField
						disabled={readOnly}
						value={formatDateTimeMUI(formData?.ConstructionSiteTakingDate) || ''}
						id="project-field-ConstructionSiteTakingDate"
						name="ConstructionSiteTakingDate"
						label={t('ProjectFields.ConstructionSiteTakingDate')}
						InputLabelProps={{ shrink: true }}
						type="date"
						error={fieldErrorsList.includes('ConstructionSiteTakingDate')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.ConstructionSiteTakingDate" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* Sales Invoice Payment Date */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 5, sm: 5, md: 3 }}>
					<TextField
						disabled={readOnly}
						value={formData?.SalesInvoicePaymentDays || ''}
						id="project-field-SalesInvoicePaymentDays"
						name="SalesInvoicePaymentDays"
						label={t('ProjectFields.SalesInvoicePaymentDays')}
						InputLabelProps={{ shrink: true }}
						error={fieldErrorsList.includes('SalesInvoicePaymentDays')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.SalesInvoicePaymentDays" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* Realization Date End */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 2, sm: 3, md: 4 }}>
					<TextField
						disabled={readOnly}
						value={formatDateTimeMUI(formData?.RealizationDateEnd) || ''}
						id="project-field-RealizationDateEnd"
						name="RealizationDateEnd"
						label={t('ProjectFields.RealizationDateEnd')}
						InputLabelProps={{ shrink: true }}
						type="date"
						error={fieldErrorsList.includes('RealizationDateEnd')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.RealizationDateEnd" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* Approving Date */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 4, sm: 4, md: 5 }}>
					<TextField
						disabled={readOnly}
						value={formatDateTimeMUI(formData?.ApprovingDate) || ''}
						id="project-field-ApprovingDate"
						name="ApprovingDate"
						label={t('ProjectFields.ApprovingDate')}
						InputLabelProps={{ shrink: true }}
						type="date"
						error={fieldErrorsList.includes('ApprovingDate')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.ApprovingDate" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* Realization Time (Months) */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 6, sm: 7, md: 6 }}>
					<TextField
						disabled={true}
						value={formData?.RealizationTimeMonths || ''}
						id="project-field-RealizationTimeMonths"
						name="RealizationTimeMonths"
						label={t('ProjectFields.RealizationTimeMonths')}
						InputLabelProps={{ shrink: true }}
						error={fieldErrorsList.includes('RealizationTimeMonths')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.RealizationTimeMonths" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* Execution Date */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 7, sm: 6, md: 7 }}>
					<TextField
						disabled={readOnly}
						value={formatDateTimeMUI(formData?.ExecutionDate) || ''}
						id="project-field-ExecutionDate"
						name="ExecutionDate"
						label={t('ProjectFields.ExecutionDate')}
						InputLabelProps={{ shrink: true }}
						type="date"
						error={fieldErrorsList.includes('ExecutionDate')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.ExecutionDate" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* Main Warranty Date End */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 8, sm: 8, md: 8 }}>
					<TextField
						disabled={true}
						value={formatDateTimeMUI(formData?.MainWarrantyDateEnd) || ''}
						id="project-field-MainWarrantyDateEnd"
						name="MainWarrantyDateEnd"
						label={t('ProjectFields.MainWarrantyDateEnd')}
						InputLabelProps={{ shrink: true }}
						type="date"
						error={fieldErrorsList.includes('MainWarrantyDateEnd')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.MainWarrantyDateEnd" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				{/* ContractDate */}
				<Grid xs={12} sm={6} md={4} lg={4} order={{ xs: 8, sm: 8, md: 8 }}>
					<TextField
						disabled={readOnly}
						value={formatDateTimeMUI(formData?.ContractDate) || ''}
						id="project-field-ContractDate"
						name="ContractDate"
						label={t('ProjectFields.ContractDate')}
						InputLabelProps={{ shrink: true }}
						type="date"
						error={fieldErrorsList.includes('ContractDate')}
						onChange={onInputChange}
						fullWidth
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="Project.ContractDate" />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			{projectId !== 'draft' && (
				<Box sx={{ mt: '15px' }}>
					<Warranty
						projectId={projectId}
						readOnly={readOnly}
						reloadFormData={reloadFormData}
						reloadWarrantyData={reloadWarrantyData}
					/>
				</Box>
			)}
		</Box>
	)
}

export default KeyTerms
