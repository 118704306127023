export const BudgetCostTypeDataSource: Array<{
	label: string
	value: string
}> = [
	{ label: 'Undefined', value: 'Undefined' },
	{ label: 'Subcontractor', value: 'Subcontractor' },
	{ label: 'Materials', value: 'Materials' },
	{ label: 'Equipment', value: 'Equipment' },
	{ label: 'Sale', value: 'Sale' },
	{ label: 'General', value: 'General' },
	{ label: 'Salary', value: 'Salary' },
]
