import { useContext } from 'react'

import { Box } from '@mui/material'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'

import AttachmentsPreview from './attachments-preview/AttachmentsPreview'
import Header from './header/Header'
import TabPanel from './tab-panel/TabPanel'

interface Props {
	children: JSX.Element
	setMuiTheme: (value: string) => void
}

const MainLayout = ({ children, setMuiTheme }: Props) => {
	const {
		isAttachmentsPreviewOpen,
		attachmentsPreviewWidthState,
		attachmentsPreviewFullWidth,
		attachmentsPreviewSide,
	} = useContext(AttachmentsPreviewContext)

	const contentContainerWidth = !isAttachmentsPreviewOpen
		? '100%'
		: attachmentsPreviewWidthState === '46px'
			? 'calc(100% - 46px)'
			: `${100 - attachmentsPreviewFullWidth}%`

	return (
		<>
			<Header setMuiTheme={setMuiTheme} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: attachmentsPreviewSide === 'right' ? 'row' : 'row-reverse',
				}}>
				<Box width={contentContainerWidth}>
					<TabPanel>{children}</TabPanel>
				</Box>
				{isAttachmentsPreviewOpen && (
					<Box
						width={
							attachmentsPreviewWidthState === '46px' ? attachmentsPreviewWidthState : `${attachmentsPreviewFullWidth}%`
						}>
						<AttachmentsPreview />
					</Box>
				)}
			</Box>
		</>
	)
}

export default MainLayout
