import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'

type Props = {
	handleResetState: () => void
}

const Toolbar = ({ handleResetState }: Props) => {
	return (
		<GridToolbarContainer className="justify-end m-1">
			<TooltipButton
				title="general.ResetDataGridState"
				onClick={handleResetState}
				IconComponent={RotateLeftIcon}
				fontSize="medium"
			/>
		</GridToolbarContainer>
	)
}

export default Toolbar
