import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import SearchIcon from '@mui/icons-material/Search'
import SyncIcon from '@mui/icons-material/Sync'
import { Divider, TextField } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'

import CreateWorkflowDialog from './CreateWorkflowDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	getData: () => void
	setDataLoading: (value: boolean) => void
	apiRef: { current: any }
	handleSearchChange: (value: any) => void
	handleClearSearchText: () => void
	searchValue: string
}

const WorkflowTabToolbar = ({
	getData,
	apiRef,
	setDataLoading,
	handleSearchChange,
	handleClearSearchText,
	searchValue,
}: Props) => {
	const [isCreateWorkflowDialogOpen, setIsCreateWorkflowDialogOpen] = useState(false)

	const { t } = useTranslation()

	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilterDemand = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateList = () => {
		setDataLoading(true)
		getData()
	}

	const handleAddWorkflow = () => {
		setIsCreateWorkflowDialogOpen(true)
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer className="justify-end m-1">
			<TextField
				className="mr-2"
				value={searchValue}
				name="searchField"
				placeholder={t('SearchInput.InputPlaceholder') as string}
				onChange={handleSearchChange}
				size="small"
				InputProps={{
					style: {
						paddingRight: '0',
					},
					endAdornment: (
						<>
							<TooltipButton
								title="SearchInput.ClearButton"
								onClick={handleClearSearchText}
								IconComponent={ClearIcon}
								visible={searchValue ? 'visible' : 'hidden'}
							/>
							<Divider orientation="vertical" variant="middle" flexItem />
							<div className="flex align-items-center px-2 py-0 my-2">
								<SearchIcon color="disabled" />
							</div>
						</>
					),
				}}
			/>
			<TooltipButton
				title="general.ResetDataGridState"
				onClick={() => handleUpdateState(true)}
				IconComponent={RotateLeftIcon}
				fontSize="medium"
			/>
			<TooltipButton
				title="general.SaveDataGridState"
				onClick={() => handleUpdateState()}
				IconComponent={FactCheckOutlinedIcon}
				fontSize="medium"
			/>
			<TooltipButton
				title="general.Filter"
				onClick={handleFilterDemand}
				IconComponent={FilterListIcon}
				fontSize="medium"
			/>
			<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
			<TooltipButton title="general.Add" onClick={handleAddWorkflow} IconComponent={AddIcon} fontSize="medium" />
			{isCreateWorkflowDialogOpen && (
				<CreateWorkflowDialog
					isOpen={isCreateWorkflowDialogOpen}
					setIsOpen={setIsCreateWorkflowDialogOpen}
					updateList={handleUpdateList}
				/>
			)}
		</GridToolbarContainer>
	)
}

export default WorkflowTabToolbar
