import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { Autocomplete, TextField } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'

import AddAddressDialog from './AddAddressDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	apiRef: { current: any }
	isActionButtonDisabled: boolean
	selectedProject: string
	setSelectedProject: (value: string) => void
	projectsList: any[]
	getData: () => void
}

const AddressesToolbar = ({
	isActionButtonDisabled,
	apiRef,
	selectedProject,
	setSelectedProject,
	projectsList,
	getData,
}: Props) => {
	const [isAddAddressDialogOpen, setIsAddAddressDialogOpen] = useState(false)

	const { t } = useTranslation()

	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleProjectChange = (e: any, newValue: any) => {
		if (newValue) {
			setSelectedProject(newValue.id)
			sessionStorage.setItem('addresses_selected_project', JSON.stringify(newValue.id))
		} else {
			setSelectedProject('')
		}
	}

	const selectedOption = projectsList.find(option => option.id === selectedProject)

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<>
			<GridToolbarContainer className="justify-between mx-1 my-2.5">
				<Autocomplete
					value={selectedOption || null}
					onChange={handleProjectChange}
					disablePortal
					options={projectsList}
					getOptionLabel={option => {
						return option.label
					}}
					sx={{ width: 300 }}
					renderInput={params => <TextField {...params} label={t('Addresses.ProjectId', { ns: 'dictionaries' })} />}
					noOptionsText={t('general.NoData')}
					size="small"
					autoHighlight
					clearOnEscape
					disableClearable
				/>
				<div>
					<TooltipButton
						title="general.ResetDataGridState"
						onClick={() => handleUpdateState(true)}
						IconComponent={RotateLeftIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.SaveDataGridState"
						onClick={() => handleUpdateState()}
						IconComponent={FactCheckOutlinedIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.Filter"
						onClick={handleFilter}
						IconComponent={FilterListIcon}
						disabled={isActionButtonDisabled}
						fontSize="medium"
					/>
					{selectedProject && (
						<TooltipButton
							title="general.Add"
							onClick={() => setIsAddAddressDialogOpen(true)}
							IconComponent={AddIcon}
							disabled={isActionButtonDisabled}
							fontSize="medium"
						/>
					)}
				</div>
			</GridToolbarContainer>
			{isAddAddressDialogOpen && (
				<AddAddressDialog
					isOpen={isAddAddressDialogOpen}
					setIsOpen={setIsAddAddressDialogOpen}
					updateList={getData}
					projectId={selectedProject}
				/>
			)}
		</>
	)
}

export default AddressesToolbar
