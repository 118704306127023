import RealizationBudgetColumns from 'components/pages/realization-budget/RealizationBudgetColumns'
import BudgetDataGrid from 'components/shared/budget-data-grid/BudgetDataGrid'
import { useRealizationBudgetService } from 'services'

interface Props {
	projectId: string
	readOnly: boolean
}

const RealizationBudgetTab = ({ projectId, readOnly }: Props) => {
	const realizationBudgetApi = useRealizationBudgetService()

	return (
		<BudgetDataGrid
			columns={RealizationBudgetColumns}
			projectId={projectId}
			api={realizationBudgetApi}
			readOnly={readOnly}
			budgetType={'realization'}
		/>
	)
}

export default RealizationBudgetTab
