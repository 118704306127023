export const getCurrentDateTime = () => {
	const currentDate = new Date()
	return currentDate.toISOString()
}

export const getDateTimeBeforeNow = (daysBefore: number) => {
	const currentDate = new Date()
	const pastDate = new Date(currentDate.getTime() - daysBefore * 24 * 60 * 60 * 1000)
	return pastDate.toISOString()
}
