import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, InputHint } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IDebitNote } from 'models'
import { IMasterCategory, IMasterDocumentNumerator, IMasterRegistry } from 'models'
import { useMasterCategoryService, useMasterDocumentNumeratorService, useMasterRegistryService } from 'services'

interface Props {
	formData: IDebitNote
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const ErpData = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const [masterCategories, setMasterCategories] = useState<IMasterCategory[]>([])
	const [masterRegistryList, setMasterRegistryList] = useState<IMasterRegistry[]>([])
	const [masterDocumentNumeratorList, setMasterDocumentNumeratorList] = useState<IMasterDocumentNumerator[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { handleAutocompleteInputChange } = useFormDataChange()
	const { getMasterCategoryList } = useMasterCategoryService()
	const { getMasterRegistryList } = useMasterRegistryService()
	const { getMasterDocumentNumeratorList } = useMasterDocumentNumeratorService()

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('debit-note')

	const getMasterCategoriesData = async (instanceId: string) => {
		if (masterCategories.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterCategoryList()

				setMasterCategories(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getMasterRegistryData = async (instanceId: string) => {
		if (masterRegistryList.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterRegistryList()

				setMasterRegistryList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getMasterDocumentNumeratorData = async (instanceId: string) => {
		if (masterDocumentNumeratorList.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getMasterDocumentNumeratorList()

				setMasterDocumentNumeratorList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	return (
		<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
			{/* MasterCategory */}
			<AutocompleteInput
				dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
				initialValue={{
					value: formData?.MasterCategory || '',
					label: formData?.MasterCategoryLabel || '',
				}}
				onOpen={() => getMasterCategoriesData('Fields.MasterCategory')}
				onChange={handleAutocompleteInputChange}
				options={masterCategories.filter(cat => cat.ForDebitNoteCategory)}
				readOnly={!isEditable('MasterCategory')}
				instanceId={'Fields.MasterCategory'}
				inputLabel={t('Fields.MasterCategory')}
				name="MasterCategory"
				labelFieldName="MasterCategoryLabel"
				hintTranslationPath="DebitNote.MasterCategory"
			/>
			{/* MasterRegistry */}
			<AutocompleteInput
				dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
				initialValue={{
					value: formData?.MasterRegistry || '',
					label: formData?.MasterRegistryLabel || '',
				}}
				onOpen={() => getMasterRegistryData('Fields.MasterRegistry')}
				onChange={handleAutocompleteInputChange}
				options={masterRegistryList}
				readOnly={!isEditable('MasterRegistry')}
				instanceId={'Fields.MasterRegistry'}
				inputLabel={t('Fields.MasterRegistry')}
				name="MasterRegistry"
				labelFieldName="MasterRegistryLabel"
				hintTranslationPath="DebitNote.MasterRegistry"
			/>
			{/* MasterDocumentSeries */}
			<Grid xs={12} sm={6} md={3} lg={3}>
				{isVisible('MasterDocumentSeries') && (
					<TextField
						disabled={!isEditable('MasterDocumentSeries')}
						value={formData?.MasterDocumentSeries || ''}
						name="MasterDocumentSeries"
						label={t('Fields.MasterDocumentSeries')}
						error={fieldErrorsList.includes('MasterDocumentSeries')}
						onChange={onInputChange}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<InputHint translationPath="DebitNote.MasterDocumentSeries" />
								</InputAdornment>
							),
						}}
						fullWidth
					/>
				)}
			</Grid>
			{/* MasterDocumentNumerator */}
			<AutocompleteInput
				dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
				initialValue={{
					value: formData?.MasterDocumentNumerator || '',
					label: formData?.MasterDocumentNumeratorLabel || '',
				}}
				onOpen={() => getMasterDocumentNumeratorData('Fields.MasterDocumentNumerator')}
				onChange={handleAutocompleteInputChange}
				options={masterDocumentNumeratorList.filter(num => num.ForDebitNote)}
				readOnly={!isEditable('MasterDocumentNumerator')}
				instanceId={'Fields.MasterDocumentNumerator'}
				inputLabel={t('Fields.MasterDocumentNumerator')}
				name="MasterDocumentNumerator"
				labelFieldName="MasterDocumentNumeratorLabel"
				hintTranslationPath="DebitNote.MasterDocumentNumerator"
			/>
		</Grid>
	)
}

export default ErpData
