import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { INavigationItem } from 'models'

import NavigationNestedMenu from './NavigationNestedMenu'

interface Props {
	item: INavigationItem
}

const NavigationMenuItem = ({ item }: Props) => {
	const { text, childItems, icon } = item

	const navigate = useNavigate()

	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('lg'))

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleItemClick = (link: string) => {
		navigate(link)
	}

	return (
		<>
			<Box sx={laptopViewAndUp ? sxStyles.itemContainer : sxStyles.itemTabletViewContainer}>
				<Typography component={'span'} onClick={handleOpenMenu} sx={sxStyles.item}>
					{icon} {laptopViewAndUp && text}
				</Typography>
			</Box>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}>
				{childItems
					? childItems.map(el => {
							return el.visible ? (
								el.childItems ? (
									<NavigationNestedMenu key={el.id} item={el} handleCloseMenu={handleCloseMenu} />
								) : (
									<MenuItem
										key={el.id}
										onClick={() => {
											handleCloseMenu()
											handleItemClick(el.linkPath as string)
										}}>
										{el.icon} {el.text}
									</MenuItem>
								)
							) : null
						})
					: null}
			</Menu>
		</>
	)
}

export default NavigationMenuItem

const sxStyles = {
	item: {
		display: 'flex',
		alignItems: 'center',
		textTransform: 'uppercase',
		cursor: 'pointer',
		fontSize: '14px',
		fontWeight: '500',
		'&:hover': {
			color: 'primary.main',
		},
	},
	itemContainer: {
		padding: '0 20px',
	},
	itemTabletViewContainer: {
		padding: '0 3px 0 3px',
	},
}
