export const connectorDataTypes: Array<{
	label: string
	value: string
}> = [
	{ label: 'All', value: 'All' },
	{ label: 'Contractor', value: 'Contractor' },
	{ label: 'ContractSalesInvoice', value: 'ContractSalesInvoice' },
	{ label: 'InvestorSalesInvoice', value: 'InvestorSalesInvoice' },
	{ label: 'CostInvoice', value: 'CostInvoice' },
	{ label: 'MaterialInvoice', value: 'MaterialInvoice' },
	{ label: 'PaidAmount', value: 'PaidAmount' },
	{ label: 'CustomCostInvoice', value: 'CustomCostInvoice' },
	{ label: 'CorrectionInvoice', value: 'CorrectionInvoice' },
	{ label: 'AdvanceInvoice', value: 'AdvanceInvoice' },
	{ label: 'DebitNote', value: 'DebitNote' },
	{ label: 'CreditNote', value: 'CreditNote' },
]
