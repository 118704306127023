import { Dispatch, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Box, Button, MobileStepper } from '@mui/material'

import { AttachmentsPreviewContext } from 'Contexts/AttachmentsPreviewContext'
import { IAttachmentPreviewFile } from 'models'

interface Props {
	file: IAttachmentPreviewFile
	selectedPage: number
	setSelectedPage: Dispatch<React.SetStateAction<number>>
	numPages: number
}

const PageSwitcher = ({ file, selectedPage, setSelectedPage, numPages }: Props) => {
	const [initialRender, setInitialRender] = useState(true)

	const { t } = useTranslation('attachments')

	const maxSteps = numPages

	const { updateSelectedPage, attachmentsPreviewSide, attachmentsPreviewFullWidth } =
		useContext(AttachmentsPreviewContext)

	const handleNext = () => {
		updateSelectedPage(file.id, selectedPage + 1)
		setSelectedPage((prev: number) => prev + 1)
	}

	const handleBack = () => {
		updateSelectedPage(file.id, selectedPage - 1)
		setSelectedPage(prev => prev - 1)
	}

	useEffect(() => {
		setInitialRender(false)
	}, [])

	return (
		<Box
			sx={{
				...sxStyles.toolsContainer,
				...(attachmentsPreviewSide === 'right' ? sxStyles.rightSide : sxStyles.leftSide),
				width: `${attachmentsPreviewFullWidth}%`,
			}}>
			<Box
				sx={{
					...sxStyles.stepperContainer,
					opacity: initialRender ? 1 : 0,
				}}>
				<MobileStepper
					sx={sxStyles.stepper}
					variant="text"
					steps={maxSteps}
					position="static"
					elevation={4}
					activeStep={selectedPage - 1}
					nextButton={
						<Button size="small" onClick={handleNext} disabled={selectedPage === maxSteps}>
							{t('Next')}
							<KeyboardArrowRight />
						</Button>
					}
					backButton={
						<Button size="small" onClick={handleBack} disabled={selectedPage === 1}>
							<KeyboardArrowLeft />
							{t('Back')}
						</Button>
					}
				/>
			</Box>
		</Box>
	)
}

export default PageSwitcher

const sxStyles = {
	toolsContainer: {
		display: 'flex',
		justifyContent: 'center',
		position: 'absolute',
		bottom: 54,
	},
	leftSide: { left: 0 },
	rightSide: { right: 0 },
	stepperContainer: {
		display: 'flex',
		width: '65%',
		height: '80px',
		justifyContent: 'center',
		alignItems: 'end',
		transition: 'opacity 0.7s',
		'&:hover': {
			opacity: 1,
		},
		'&:not(:hover)': {
			transitionDelay: '2.5s',
		},
	},
	stepper: {
		minWidth: '300px',
		height: '50px',
		borderRadius: '10px',
	},
}
