import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, InputHint } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { ICreditNote, ICurrency } from 'models'
import { useCurrencyService } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'

type Props = {
	formData: ICreditNote
}

const Settlements = ({ formData }: Props) => {
	const [currencyList, setCurrencyList] = useState<ICurrency[]>([])

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { isEditable, isVisible } = useFieldsPermissions()

	const { handleInputChange, fieldsError, handleAutocompleteInputChange } = useFormDataChange()

	const { t } = useTranslation('credit-note')

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { getCurrency } = useCurrencyService()

	const getCurrencyData = async (instanceId: string) => {
		if (currencyList.length === 0 && !autocompleteInputLoading[instanceId])
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getCurrency()
				setCurrencyList(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
	}

	return (
		<div>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* Value  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 0, lg: 0 }}>
					{isVisible('Value') && (
						<TextField
							disabled={!isEditable('Value')}
							value={formData.Value || ''}
							name="Value"
							label={t('Fields.Value')}
							error={fieldsError.includes('Value')}
							onChange={handleInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="CreditNote.Value" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ValuePLN  */}
				<Grid xs={12} sm={6} md={3} lg={3} order={{ md: 0, lg: 0 }}>
					{isVisible('ValuePLN') && (
						<TextField
							disabled={!isEditable('ValuePLN')}
							value={formData.ValuePLN || ''}
							name="ValuePLN"
							label={t('Fields.ValuePLN')}
							error={fieldsError.includes('ValuePLN')}
							onChange={handleInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="CreditNote.ValuePLN" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* Currency  */}
				{isVisible('Currency') && (
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						initialValue={{
							value: formData.Currency || '',
							label: formData.CurrencyLabel || '',
						}}
						onOpen={() => getCurrencyData('Fields.Currency')}
						onChange={handleAutocompleteInputChange}
						options={currencyList}
						readOnly={!isEditable('Currency')}
						instanceId="Fields.Currency"
						inputLabel={t('Fields.Currency')}
						name="Currency"
						labelFieldName="CurrencyLabel"
						hintTranslationPath="Currency"
					/>
				)}
				{/* ExchangeRate  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ExchangeRate') && (
						<TextField
							disabled={!isEditable('ExchangeRate')}
							value={formData.ExchangeRate || ''}
							name="ExchangeRate"
							label={t('Fields.ExchangeRate')}
							error={fieldsError.includes('ExchangeRate')}
							onChange={handleInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="CreditNote.ExchangeRate" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* ChargeReason */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					{isVisible('ChargeReason') && (
						<TextField
							disabled={!isEditable('ChargeReason')}
							value={formData.ChargeReason || ''}
							name="ChargeReason"
							label={t('Fields.ChargeReason')}
							error={fieldsError.includes('ChargeReason')}
							onChange={handleInputChange}
							multiline
							minRows={4}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="CreditNote.ChargeReason" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Settlements
