import { useTranslation } from 'react-i18next'

import { useLinkDocumentsActions } from 'features'
import { ILinkDocumentData } from 'models'
import { useSnackbar } from 'notistack'

const useLinkDocuments = () => {
	const { setLinkDocumentData } = useLinkDocumentsActions()

	const { t } = useTranslation()

	const { enqueueSnackbar } = useSnackbar()

	const handleCopyDocumentData = (documentData: ILinkDocumentData) => {
		setLinkDocumentData(documentData)
		enqueueSnackbar(`${t('LinkDocumentClipboard.CopiedData')} ${documentData.name}`, {
			variant: 'info',
			autoHideDuration: 8000,
		})
	}

	return { handleCopyDocumentData }
}

export default useLinkDocuments
