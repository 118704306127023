import { IUser } from 'models'

import { ApiBase } from './ApiBase'

interface IAuthApi {
	Login(userName: string, password: string, rememberMe: boolean): Promise<void>
	Logout(): Promise<string | null>
	Me(): Promise<IUser | null>
}

class AuthApi extends ApiBase implements IAuthApi {
	public Login = async (login: string, password: string, rememberMe: boolean): Promise<void> => {
		await super.Call('/api/authentication/login', 'POST', {
			login,
			password,
			rememberMe,
		})
	}

	public Logout = async (): Promise<string | null> => {
		const response = await super.CallT<string>('/api/authentication/logout', 'POST')
		return response.data
	}

	async Me(): Promise<IUser | null> {
		const response = await super.CallT<IUser>('/api/authentication/me')
		return response.data
	}
}

export const authApi = new AuthApi()
export type { IAuthApi }
