import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { INumerator } from 'models'

const useNumeratorService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/numerator'

	const getNumeratorList = useCallback(async () => {
		const response = await request(`${path}`)

		return response
	}, [request])

	const getNumerator = useCallback(
		async (numeratorId: string) => {
			const response = await request(`${path}/${numeratorId}`)

			return response
		},
		[request]
	)

	const updateNumerator = useCallback(
		async (numeratorId: string, data: INumerator) => {
			const response = await request(`${path}/${numeratorId}`, 'PATCH', data)

			return response
		},
		[request]
	)

	const addNumerator = useCallback(
		async (data: INumerator) => {
			const response = await request(`/odata/numerator`, 'POST', data)
			return response
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getNumeratorList,
		getNumerator,
		updateNumerator,
		addNumerator,
	}
}

export default useNumeratorService
