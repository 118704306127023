export const SimpleTextRegex = /^[\p{L}0-9\\/|\s\.\-„”""',]*$/u
export const SimpleTextErrorMessage =
	'Tekst zawiera niepoprawne znaki. Dozwolone są znaki alfanumeryczne oraz symbole \\ / | . - „ ” " \' ,'

export const AdvancedTextRegex = /^[\p{L}0-9\\/|\s\.\-„”""',;()%]*$/u
export const AdvancedTextErrorMessage =
	'Tekst zawiera niepoprawne znaki. Dozwolone są znaki alfanumeryczne oraz symbole \\ / | . - „ ” " \' , ; ( ) %'

export const BudgetNumberRegex = /^[0-9]+(\.[0-9]+)*$/u
export const BudgetNumberErrorMessage = 'Numer jest w niepoprawnym formacie (przykład: 1.2)'

export const PureTextRegex = /^[\p{L}0-9_]+$/u
export const PureTextErrorMessage =
	'Tekst zawiera niepoprawne znaki. Dozwolone są tylko znaki alfanumeryczne (bez polskich znaków oraz białych znaków) oraz symbol _'

export const PasswordRegex = /^(?=.*\p{Ll})(?=.*\p{Lu})(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/u
export const PasswordErrorMessage =
	"Hasło powinno składać się z minimum 8 znaków oraz przynajmniej jednej małej litery, jednej wielkiej litery, jednej cyfry oraz jednego ze znaków: '!@#$%^&*'"

export const NumeratorRegex = /^[\p{L}0-9@\\/|\s\.\-„”""',;()%]*$/u
export const NumeratorRegexMessage =
	'Tekst zawiera niepoprawne znaki. Dozwolone są tyleko znaki alfanumeryczne oraz symbole @ \\ / | . - „ ” " \' , ; ( ) %'

export const PhoneNumberRegex = /^[0-9\s+\-\(\)]*$/
export const PhoneNumberRegexMessage = 'Niepoprawny numer teleonu. Dozwolone są tyleko znaki numeryczne oraz symbol +'

export const EmailRegex =
	/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|""(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*"")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
export const EmailRegexMessage = 'Niepoprawny adres email'

export const PostalCodeRegex = /^[0-9]{2}-[0-9]{3}/
export const PostalCodeRegexMessage = 'Kod pocztowy powinien być zapisany w formacie 00-000'
