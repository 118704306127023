import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton } from '@mui/material'

import { BudgetEntryFieldType } from 'models'

type Props = {
	setFieldName: (fieldName: BudgetEntryFieldType) => void
	fieldName: BudgetEntryFieldType
	selectedFieldName: BudgetEntryFieldType | null
}

const ShowSourceButton = ({ setFieldName, fieldName, selectedFieldName }: Props) => {
	return (
		<IconButton color={selectedFieldName === fieldName ? 'primary' : 'inherit'} onClick={() => setFieldName(fieldName)}>
			<ArrowForwardIosIcon fontSize="small" />
		</IconButton>
	)
}

export default ShowSourceButton
