import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import SearchIcon from '@mui/icons-material/Search'
import SyncIcon from '@mui/icons-material/Sync'
import { Box, Divider, TextField } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, TooltipButton } from 'components/shared'
import useDataGridState from 'hooks/UseDataGridState'
import { IContractorModel, IInquiriesContractor } from 'models'

import AddContractorDialog from './AddContractorDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

interface Props {
	getData: () => void
	setDataLoading: (value: boolean) => void
	apiRef: { current: any }
	handleSearchChange: (value: any) => void
	handleClearSearchText: () => void
	searchValue: string
	contractorsList: IContractorModel[]
}

const ContractorsToolbar = ({
	getData,
	setDataLoading,
	apiRef,
	handleSearchChange,
	handleClearSearchText,
	searchValue,
	contractorsList,
}: Props) => {
	const [isAddContractorDialogVisible, setIsAddContractorDialogVisible] = useState(false)
	const [addContractorDialogData, setAddContractorDialogData] = useState<IInquiriesContractor>()

	const createFromDemandInquiriesProps = useLocation()

	const { t } = useTranslation()

	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilterDemand = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateList = () => {
		setDataLoading(true)
		getData()
	}

	useEffect(() => {
		if (createFromDemandInquiriesProps.state) {
			setIsAddContractorDialogVisible(createFromDemandInquiriesProps.state.openAddDialog)
			setAddContractorDialogData(createFromDemandInquiriesProps.state.params)
		}
	}, [createFromDemandInquiriesProps])

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', m: '4px', py: '4px' }}>
			<div>
				<ColumnsButton />
			</div>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<TextField
					sx={{ mr: '10px' }}
					value={searchValue}
					name="searchField"
					placeholder={t('SearchInput.InputPlaceholder') as string}
					onChange={handleSearchChange}
					size="small"
					InputProps={{
						style: {
							paddingRight: '0',
						},
						endAdornment: (
							<>
								<TooltipButton
									title="SearchInput.ClearButton"
									onClick={handleClearSearchText}
									IconComponent={ClearIcon}
									className={searchValue ? 'visible' : 'hidden'}
								/>
								<Divider orientation="vertical" variant="middle" flexItem />
								<Box sx={{ display: 'flex', alignItems: 'center', py: 0, px: '8px' }}>
									<SearchIcon color="disabled" />
								</Box>
							</>
						),
					}}
				/>
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.Filter"
					onClick={handleFilterDemand}
					IconComponent={FilterListIcon}
					fontSize="medium"
				/>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				<TooltipButton
					title="general.Add"
					onClick={() => setIsAddContractorDialogVisible(true)}
					IconComponent={AddIcon}
					fontSize="medium"
				/>
				{isAddContractorDialogVisible && (
					<AddContractorDialog
						isOpen={isAddContractorDialogVisible}
						setIsOpen={setIsAddContractorDialogVisible}
						getContractorsList={getData}
						quotationRequestContractorData={addContractorDialogData}
						contractorsList={contractorsList}
					/>
				)}
			</Box>
		</GridToolbarContainer>
	)
}

export default ContractorsToolbar
