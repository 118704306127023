import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, List, ListItem, ListItemText } from '@mui/material'

import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { IRoleHeader } from 'models'
import { useRolesService } from 'services'

import AddRoleDialog from './AddRoleDialog'

interface Props {
	userId: string
}

const Global = ({ userId }: Props) => {
	const [canBeAddedGlobalRoles, setCanBeAddedGlobalRoles] = useState<IRoleHeader[]>([])
	const [userGlobalRoles, setUserGlobalRoles] = useState<IRoleHeader[]>([])
	const [isAddRoleDialogOpen, setIsAddRoleDialogOpen] = useState(false)
	const [loading, setLoading] = useState(true)

	const { t } = useTranslation('administration')

	const { getRolesCanBeGlobal, getUserGlobalRoles, deleteUserGlobalRole, showSuccessInfo } = useRolesService()

	const handleDeleteRole = useCallback(
		async (roleId: string) => {
			try {
				await deleteUserGlobalRole(roleId)
				getData()
				showSuccessInfo('deleted')
			} catch (err) {
				console.error(err)
			}
		},
		[deleteUserGlobalRole]
	)

	const getData = useCallback(async () => {
		try {
			const rolesThatCanBeGlobal = await getRolesCanBeGlobal()
			const userRoles = await getUserGlobalRoles(userId)
			const userRolesIds = userRoles.map((el: any) => el.HeaderId)

			const dataToRender = userRoles.map((el: any) => {
				const header = rolesThatCanBeGlobal.filter((item: any) => {
					return item.Id === el.HeaderId
				})
				return { ...el, Name: header[0].Name }
			})

			const rolesThatCanBeAdded = rolesThatCanBeGlobal.filter((el: any) => !userRolesIds.includes(el.Id))

			setCanBeAddedGlobalRoles(rolesThatCanBeAdded)
			setUserGlobalRoles(dataToRender)
		} catch (err) {
			console.error(err)
		}
		setLoading(false)
	}, [userId, getRolesCanBeGlobal, getUserGlobalRoles])

	useEffect(() => {
		getData()
	}, [userId])

	return (
		<>
			<List sx={sxStyles.listContainer}>
				<ListItem
					sx={sxStyles.listItem}
					secondaryAction={
						<IconButton edge="end" onClick={() => setIsAddRoleDialogOpen(true)}>
							<AddIcon sx={{ fontSize: '20px' }} color="primary" />
						</IconButton>
					}>
					<ListItemText
						sx={sxStyles.listItemText}
						primaryTypographyProps={{ fontSize: '14px' }}
						primary={t('Users.UserPage.Role')}
					/>
				</ListItem>
				{loading ? (
					<Loading />
				) : userGlobalRoles.length ? (
					userGlobalRoles.map((el, index) => {
						return (
							<ListItem
								key={index}
								sx={sxStyles.listItem}
								secondaryAction={
									<IconButton edge="end" onClick={() => handleDeleteRole(el.Id)}>
										<DeleteIcon sx={{ fontSize: '20px' }} color="primary" />
									</IconButton>
								}>
								<ListItemText
									sx={sxStyles.listItemText}
									primaryTypographyProps={{ fontSize: '14px' }}
									primary={el.Name}
								/>
							</ListItem>
						)
					})
				) : (
					<ListItem>
						<NoData fontSize="18px" />
					</ListItem>
				)}
			</List>
			{isAddRoleDialogOpen && (
				<AddRoleDialog
					isOpen={isAddRoleDialogOpen}
					setIsOpen={setIsAddRoleDialogOpen}
					roles={canBeAddedGlobalRoles}
					refreshData={getData}
					userId={userId}
				/>
			)}
		</>
	)
}

export default Global

const sxStyles = {
	listContainer: {
		width: '100%',
		padding: '0',
	},
	listItem: {
		borderBottom: 1,
		borderColor: 'action.disabled',
		borderRadius: '4px 4px 0 0',
		color: 'text.secondary',
	},
	listItemText: {
		textAlign: 'left',
		width: '70%',
	},
}
