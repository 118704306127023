import { FC } from 'react'

import { SvgIcon, SvgIconProps } from '@mui/material'

const GoodsReceivedNoteIcon: FC<SvgIconProps> = props => {
	return (
		<SvgIcon {...props}>
			<path
				fill="currentColor"
				d="M21.333,20.508h-2.865c1.023-0.066,1.837-0.92,1.837-1.96V7.499c0-1.082-0.882-1.963-1.965-1.963h-3.854
	h-3.999H6.634c-0.259,0-0.507,0.051-0.733,0.142V1.964C5.9,0.881,5.036,0,3.976,0h-1.31v1.229h1.31c0.383,0,0.695,0.33,0.695,0.734
	v5.535v10.963c-1.044,0.432-1.78,1.463-1.78,2.661C2.891,22.71,4.181,24,5.767,24c1.377,0,2.531-0.971,2.813-2.264h12.753V20.508z
	 M10.606,6.766h3.762v4.142h-3.762V6.766z M5.767,22.771c-0.908,0-1.646-0.739-1.646-1.647c0-0.909,0.738-1.647,1.646-1.647
	c0.91,0,1.649,0.738,1.649,1.647C7.416,22.031,6.677,22.771,5.767,22.771z M7.979,19.282C7.477,18.68,6.734,18.286,5.9,18.248V7.499
	c0-0.404,0.329-0.733,0.733-0.733h2.743v4.261c0,0.612,0.498,1.11,1.111,1.11h3.998c0.613,0,1.111-0.498,1.111-1.11V6.766h2.742
	c0.404,0,0.732,0.329,0.732,0.733v11.049c0,0.404-0.328,0.734-0.732,0.734H7.979z"
			/>
		</SvgIcon>
	)
}

export default GoodsReceivedNoteIcon
