import type React from 'react'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import TabHeadersPanel from './TabHeadersPanel'

export interface ITabPanelProps {
	children: React.ReactNode
}

export default function TabPanel({ children }: ITabPanelProps) {
	const theme = useTheme()
	const laptopViewAndUp = useMediaQuery(theme.breakpoints.up('md'))

	return (
		<Box sx={{ height: 'calc(100vh - 49px)' }}>
			<TabHeadersPanel />
			<Box
				sx={{
					height: 'calc(100vh - 97px)',
					overflowX: 'auto',
				}}>
				<Box sx={{ padding: laptopViewAndUp ? '25px 30px' : '25px 10px' }}>{children}</Box>
			</Box>
		</Box>
	)
}
