import { useState } from 'react'

import { defaultPage, defaultPageSize } from './data'

type Pagination = {
	page: number
	pageSize: number
	rowCount: number
}

type Props = {
	contextName: string
	getFilteredData: () => void
}

const useDataGridPagination = ({ contextName, getFilteredData }: Props) => {
	const [page, setPage] = useState(defaultPage)
	const [pageSize, setPageSize] = useState(defaultPageSize)
	const [rowCount, setRowCount] = useState(0)

	const sessionStorageName = `${contextName}_PAGINATION`

	const handlePageChange = (newPage: number, updateData = true) => {
		const sessionStoragePagination = sessionStorage.getItem(sessionStorageName)
		const pageSizeValue = sessionStoragePagination ? JSON.parse(sessionStoragePagination).pageSize : pageSize

		setPage(newPage)
		sessionStorage.setItem(sessionStorageName, JSON.stringify({ page: newPage, pageSize: pageSizeValue }))
		if (updateData) getFilteredData()
	}

	const handlePageSizeChange = (newPageSize: number) => {
		setPageSize(newPageSize)
		sessionStorage.setItem(sessionStorageName, JSON.stringify({ page, pageSize: newPageSize }))
		getFilteredData()
	}

	const updatePaginationStates = (newValue: Pagination) => {
		setPage(newValue.page)
		setRowCount(newValue.rowCount)
		setPageSize(newValue.pageSize)
	}

	return {
		page,
		pageSize,
		handlePageSizeChange,
		updatePaginationStates,
		handlePageChange,
		rowCount,
	}
}

export default useDataGridPagination
