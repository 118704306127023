import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type InitialState = {
	isSaveButtonDisabled: boolean
	isSaveButtonLoading: boolean
}

const initialState: InitialState = {
	isSaveButtonDisabled: true,
	isSaveButtonLoading: false,
}

export const documentFormSaveButton = createSlice({
	name: 'documentFormSaveButton',
	initialState,
	reducers: {
		setIsSaveButtonDisabled: (state, action: PayloadAction<boolean>) => {
			state.isSaveButtonDisabled = action.payload
		},
		setIsSaveButtonLoading: (state, action: PayloadAction<boolean>) => {
			state.isSaveButtonLoading = action.payload
		},
	},
})

export const documentFormSaveButtonActions = documentFormSaveButton.actions

export default documentFormSaveButton.reducer
