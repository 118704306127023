export const projectExcecutor: Array<{
	label: string
	value: string
}> = [
	{ value: 'ProjectDirector', label: 'ProjectDirector' },
	{ value: 'ProjectManager', label: 'ProjectManager' },
	{ value: 'ConstructionManager', label: 'ConstructionManager' },
	{ value: 'ProjectEngineer', label: 'ProjectEngineer' },
	{ value: 'WorksManager', label: 'WorksManager' },
	{ value: 'ConstructionEngineer', label: 'ConstructionEngineer' },
	{ value: 'PurchaseSpecialist', label: 'PurchaseSpecialist' },
	{ value: 'ServiceManager', label: 'ServiceManager' },
	{ value: 'ServiceEngineer', label: 'ServiceEngineer' },
]
