import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'

import { workflowDocumentType } from 'data/lookup-data-sources/workflowDocumentType'
import { IWorkflow } from 'models'
import { useWorkflowService } from 'services'
import prepareTitleString from 'utils/prepareTitleString'

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	updateList: () => void
}

const CreateWorkflowDialog = ({ isOpen, setIsOpen, updateList }: Props) => {
	const [workflowName, setWorkflowName] = useState('')
	const [documentType, setDocumentType] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)

	const navigate = useNavigate()

	const { t } = useTranslation(['administration', 'translation'])

	const { createWorkflow, showSuccessInfo } = useWorkflowService()

	const closeDialog = () => {
		setIsOpen(false)
		setWorkflowName('')
		setDocumentType('')
	}

	const onWorkflowNameChange = async (value: string) => {
		setWorkflowName(value)
	}

	const onDocumentTypeChange = async (value: string) => {
		setDocumentType(value)
	}

	const handleCreateWorkflow = async () => {
		try {
			setButtonLoading(true)
			const response = await createWorkflow({
				Name: workflowName,
				DataType: documentType,
			} as IWorkflow)

			if (response) {
				navigate(`/workflow/${response.Id}`, {
					state: { headerPostFix: `${prepareTitleString(response.Name, 25)}` },
				})
			}

			updateList()
			showSuccessInfo('action')
			closeDialog()
		} catch (err) {
			console.error(err)
		}
		setButtonLoading(false)
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('Workflow.CreateWorkflowDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={workflowName}
					name="WorkflowName"
					label={t('Workflow.Fields.Name')}
					onChange={e => onWorkflowNameChange(e.target.value)}
					fullWidth
					sx={{ mt: '10px' }}
				/>
				<TextField
					value={documentType}
					select
					name="documentType"
					label={t('Workflow.Fields.DataType')}
					onChange={e => onDocumentTypeChange(e.target.value)}
					fullWidth
					sx={{ mt: '20px' }}>
					{workflowDocumentType.map((option, index) => {
						return (
							<MenuItem key={index} value={option.value}>
								{t(`Workflow.Fields.DataTypeEnums.${option.label}`)}
							</MenuItem>
						)
					})}
				</TextField>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					color="success"
					disabled={!workflowName || !documentType}
					loading={buttonLoading}
					onClick={handleCreateWorkflow}>
					{t('general.Create', { ns: 'translation' })}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default CreateWorkflowDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
