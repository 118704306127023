import { useNavigate } from 'react-router-dom'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { IconButton } from '@mui/material'

const CalendarLink = () => {
	const navigate = useNavigate()

	const handleIconClick = () => {
		navigate('/calendar')
	}

	return (
		<IconButton className="p-1 md:p-2" color="inherit" onClick={handleIconClick}>
			<CalendarMonthIcon fontSize="medium" />
		</IconButton>
	)
}

export default CalendarLink
