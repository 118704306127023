import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	TextField,
} from '@mui/material'

import { useAppSelector, useDocumentActionsStateActions } from 'features'
import { Action } from 'models'
import { useWorkflowService } from 'services'

type Props = {
	isOpen: boolean
	action: Action
	invokeAction: (value: Action, parameters?: any) => void
}

const CreateDocumentDialog = ({ isOpen, action, invokeAction }: Props) => {
	const [workflowId, setWorkflowId] = useState<string>('')
	const [workflow, setWorkflow] = useState([])
	const [isSaveButtonEnabled, setSaveButtonEnabled] = useState(false)

	const { copiedDocumentData } = useAppSelector(state => state.linkDocuments)
	const { selectedDocumentType } = useAppSelector(state => state.documentActionsState)
	const { setIsCreateDocumentDialogOpen } = useDocumentActionsStateActions()

	const { t } = useTranslation()

	const { getWorkflowLookupList } = useWorkflowService()

	const getDocumentTypeParams = () => {
		switch (selectedDocumentType) {
			case 'Contract':
				return 'contractWorkflowId'
			case 'Order':
				return 'orderWorkflowId'
			case 'ProcessingProtocol':
				return 'processingProtocolWorkflowId'
			case 'CostInvoice':
				return 'costInvoiceWorkflowId'
			case 'GoodsReceivedNote':
				return 'grnWorkflowId'
			case 'MaterialInvoice':
				return 'materialInvoiceWorkflowId'
			case 'ContractAdvanceInvoice':
				return 'contractAdvanceInvoiceWorkflowId'
			case 'ContractProformaInvoice':
				return 'contractProformaInvoiceWorkflowId'
			case 'DebitNote':
				return 'debitNoteWorkflowId'
			case 'ContractSalesInvoice':
				return 'contractSalesInvoiceWorkflowId'
			case 'SalesInvoice':
				return 'salesInvoiceWorkflowId'
			case 'CustomCostInvoice':
				return 'customCostInvoiceWorkflowId'
			case 'Demand':
				return 'newWorkflowId'
			case 'CorrectionInvoice':
				return 'correctionWorkflowId'
			case 'CreditNote':
				return 'creditNoteWorkflowId'
			default:
				return 'contractWorkflowId'
		}
	}

	const closeDialog = () => {
		setIsCreateDocumentDialogOpen(false)
	}

	const onWorkflowChange = (value: string) => {
		setWorkflowId(value)
		setSaveButtonEnabled(true)
	}

	const handleCreateDocument = async () => {
		const paramKey = getDocumentTypeParams()

		let parameters = {
			[paramKey]: workflowId,
		}

		if (action.Name === 'GenerateGoodsReceivedNoteFromIncomingLetter' && selectedDocumentType === 'GoodsReceivedNote') {
			parameters = { ...parameters, orderId: copiedDocumentData.id }
		}

		invokeAction(action, parameters)
		closeDialog()
	}

	const getWorkflowData = async () => {
		try {
			const response = await getWorkflowLookupList(selectedDocumentType)

			setWorkflow(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getWorkflowData()
	}, [])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				{t('CreateDocumentDialog.Title')}
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={workflowId}
					select
					name="Workflow"
					label={t('CreateDocumentDialog.WorkflowSelectLabel')}
					onChange={e => onWorkflowChange(e.target.value)}
					fullWidth
					sx={{ mt: '10px' }}>
					{workflow.map((el: any, index: number) => (
						<MenuItem key={el.label} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="contained" color="success" disabled={!isSaveButtonEnabled} onClick={handleCreateDocument}>
					{t('general.Create')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CreateDocumentDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '20px',
	},
	actionsContainer: { padding: '8px 26px 16px 8px' },
}
