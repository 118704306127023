const formatPostalCode = (value: string) => {
	// Remove any non-numeric characters from the input
	const numericValue = value.replace(/\D/g, '')
	// Format the value with a dash after the second digit
	const formattedValue = numericValue.replace(/(\d{2})(\d{1,3})/, '$1-$2')

	return formattedValue
}

export default formatPostalCode
