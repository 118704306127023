import { Divider } from '@mui/material'

import { NoData } from 'components/shared'
import dayjs from 'dayjs'
import { useAppSelector } from 'features'
import useLanguage from 'hooks/UseLanguge'
import { ICalendarEvent } from 'models'

import { Event } from './events'

const ListSection = () => {
	const { selectedDate, calendarEvents } = useAppSelector(state => state.calendar)

	const { datePickerLanguage } = useLanguage()

	const day = dayjs(selectedDate).locale(datePickerLanguage).format('dddd, D')
	const formattedDay = day.charAt(0).toUpperCase() + day.slice(1)

	const month = dayjs(selectedDate).locale(datePickerLanguage).format('MMMM')
	const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1)

	const events = calendarEvents[dayjs(selectedDate).format('YYYY-MM-DD')]

	return (
		<div>
			<p className="text-center font-medium">{`${formattedDay} ${formattedMonth}`}</p>
			<div className="flex justify-center pb-2">
				<Divider className="w-3/4" />
			</div>
			<div className="h-[calc(100vh-276px)] min-h-[504px] flex flex-col overflow-y-auto">
				{events ? (
					<div className="p-3">
						{events.map((event: ICalendarEvent, index) => (
							<Event key={index} event={event} />
						))}
					</div>
				) : (
					<NoData fontSize="18px" />
				)}
			</div>
		</div>
	)
}

export default ListSection
