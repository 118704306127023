import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { ICreditNote, IInvokeAction } from 'models'
import { CREDIT_NOTE_ENDPOINT } from 'services/data'

export interface getCreditNotesListProps {
	isCanceled: boolean
	isCompleted: boolean
	projectId?: string
	data?: GridInitialState
}

const useCreditNoteService = () => {
	const { request, error, errorStatus, clearError, fieldsError, showSuccessInfo } = useHttp()

	const path = CREDIT_NOTE_ENDPOINT

	const getFilteredCreditNotes = useCallback(
		async ({ isCanceled, isCompleted, projectId, data }: getCreditNotesListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getListOfCreditNotes = useCallback(
		async ({ isCanceled, isCompleted, projectId }: getCreditNotesListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getCreditNote = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const createCreditNote = useCallback(
		async (workflowId: string, projectId: string, budgetEntryId?: string) => {
			const response = await request(
				`${path}?workflowId=${workflowId}&projectId=${projectId}&budgetEntryId=${budgetEntryId || ''}`,
				'POST'
			)
			return response
		},
		[request]
	)

	const updateCreditNote = useCallback(
		async (id: string, data: ICreditNote) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const getActions = useCallback(
		async (id: string) => {
			const response = await request(`${path}/actions/${id}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response
		},
		[request]
	)

	const getPropertiesToRead = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/read/${id}`)
			return response
		},
		[request]
	)

	const getPropertiesToEdit = useCallback(
		async (id: string) => {
			const response = await request(`${path}/allowedProperties/write/${id}`)
			return response
		},
		[request]
	)

	const linkToBudgetEntry = useCallback(
		async (entryId: string, budgetEntryId: string) => {
			await request(`${path}/linkToBudget/${entryId}?budgetEntryId=${budgetEntryId}`, 'PATCH')
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getFilteredCreditNotes,
		getListOfCreditNotes,
		getCreditNote,
		createCreditNote,
		updateCreditNote,
		getActions,
		invokeAction,
		getPropertiesToRead,
		getPropertiesToEdit,
		linkToBudgetEntry,
	}
}

export default useCreditNoteService
