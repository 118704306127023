import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'

const useProjectsListService = () => {
	const { request, error, clearError } = useHttp()

	const getFilteredProjects = useCallback(
		async (data?: GridInitialState) => {
			const response = await request(`/api/Project/filter`, 'POST', data)

			return response
		},
		[request]
	)

	const getListOfProjects = useCallback(async () => {
		const response = await request(`/api/Project`)
		return response
	}, [request])

	const getListOfContractors = useCallback(async () => {
		const response = await request(`/api/contractor`)
		return response
	}, [request])

	return {
		error,
		clearError,
		getListOfProjects,
		getListOfContractors,
		getFilteredProjects,
	}
}

export default useProjectsListService
