import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { enUS, plPL, ukUA } from '@mui/x-data-grid'

const useLanguage = () => {
	const { i18n } = useTranslation()

	const [dataGridLanguage, setDataGridLanguage] = useState(plPL)
	const [datePickerLanguage, setDatePickerLanguage] = useState('pl')

	const getCurrentLanguage = useCallback(() => localStorage.getItem('language'), [])
	const handleLanguageChange = useCallback(
		(lang: string) => {
			i18n.changeLanguage(lang)
			localStorage.setItem(`language`, lang)
		},
		[i18n]
	)

	const setLang = useCallback((lang: string) => {
		switch (lang) {
			case 'pl':
				setDataGridLanguage(plPL)
				setDatePickerLanguage('pl')
				break
			case 'en':
				setDataGridLanguage(enUS)
				setDatePickerLanguage('en')
				break
			case 'uk':
				setDataGridLanguage(ukUA)
				setDatePickerLanguage('uk')
				break
			default:
				setDataGridLanguage(plPL)
				setDatePickerLanguage('pl')
		}
	}, [])

	useEffect(() => {
		const currentLanguage = i18n.language || 'pl'
		setLang(currentLanguage)

		const languageChangedHandler = (lang: string) => {
			setLang(lang)
		}

		i18n.on('languageChanged', languageChangedHandler)

		return () => {
			i18n.off('languageChanged', languageChangedHandler)
		}
	}, [i18n, i18n.language, setLang])

	return { handleLanguageChange, getCurrentLanguage, dataGridLanguage, datePickerLanguage }
}

export default useLanguage
