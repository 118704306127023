import { AppBar, Toolbar, useMediaQuery, useTheme } from '@mui/material'

import Navigation from '../navigation/Navigation'
import { CalendarLink } from './calendar'
import { Clipboard } from './clipboard'
import { Notifications } from './notifications'
import UserMenu from './userMenu/UserMenu'

interface Props {
	setMuiTheme: (value: string) => void
}

const Header = ({ setMuiTheme }: Props) => {
	const theme = useTheme()
	const mobileViewAndUp = useMediaQuery(theme.breakpoints.up('sm'))

	return (
		<AppBar position="static" sx={{ height: '48px', bgcolor: 'primary.headerBackground' }}>
			<Toolbar className="w-full h-12 flex justify-between" variant="dense">
				<div className="flex">
					<a href="/" className="flex flex-row items-center">
						<img
							src={mobileViewAndUp ? '/logo_alfa_byte.png' : '/mobile_logo.png'}
							alt="AlfaByte"
							className="w-auto h-7 mr-1"
						/>
					</a>
					<Navigation />
				</div>
				<div className="flex items-center">
					<Notifications />
					<CalendarLink />
					<Clipboard />
					<UserMenu setMuiTheme={setMuiTheme} />
				</div>
			</Toolbar>
		</AppBar>
	)
}

export default Header
