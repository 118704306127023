import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	aggregation: {
		model: { SettlementValue: 'sum' },
	},
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'__tree_data_group__',
			'Name',
			'CostType',
			'MeasurementUnitId',
			'Parameters',
			'SettlementValue',
			'CorrectionsValueExpected',
			'CorrectionsValueCalculated',
			'BudgetEntryId',
			'actions',
		],
		dimensions: {
			__tree_data_group__: { maxWidth: -1, minWidth: 130, width: 300, flex: 0 },
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 506,
				flex: 0,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Parameters: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			SettlementValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			CorrectionsValueExpected: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			CorrectionsValueCalculated: {
				maxWidth: -1,
				minWidth: 50,
				width: 220,
			},
			BudgetEntryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 100,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'materialInvoiceSettlementDataGridState'
