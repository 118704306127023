import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

import ChangePasswordForm from 'components/shared/change-password-form/ChangePasswordForm'
import { IUser } from 'models'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	userData: IUser
}

const ChangePasswordDialog = ({ isOpen, setIsOpen, userData }: Props) => {
	const { t } = useTranslation()

	const closeDialog = () => {
		setIsOpen(false)
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title} component="div">
				<p>
					{`${t('ChangePasswordPage.ChangePasswordTitle')} ${
						userData.FirstName || userData.Login
					} ${userData.LastName || ''}`}
				</p>
				<IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<ChangePasswordForm user={userData} setIsOpen={setIsOpen} />
			</DialogContent>
		</Dialog>
	)
}

export default ChangePasswordDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 0 25px 0',
	},
}
